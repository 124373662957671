// Firebase
import { getDatabase, ref, push, set } from "firebase/database";
// Services
import { isErrorResult, submitGetFeedback } from "services";
// Types
import { ValuesType } from "./types";

const createFormData = (values: ValuesType): FormData => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(values)) {
    formData.append(key, value);
  }
  return formData;
};

export const submitFeedback = async (formValues: ValuesType) => {
  try {
    const formDataSubmitRequest = createFormData(formValues);
    const feedbackResponse = await submitGetFeedback(formDataSubmitRequest);

    if (!isErrorResult(feedbackResponse)) {
      return true;
    }

    const db = getDatabase();
    const contactFormsRef = ref(db, "contactForms");
    const timestamp = new Date().toISOString();
    const formData = { ...formValues, timestamp };

    const newFormRef = push(contactFormsRef);
    await set(newFormRef, formData);

    if (!newFormRef.key) {
      console.error("Error adding data to Firebase");
      return false;
    }

    return true;
  } catch (error) {
    console.error("Error:", error);
    return false;
  }
};
