// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 29 28" width="29" height="28" {...props} color="transparent">
      <path
        d="M14.4987 2.33325C8.0587 2.33325 2.83203 7.55992 2.83203 13.9999C2.83203 20.4399 8.0587 25.6666 14.4987 25.6666C20.9387 25.6666 26.1654 20.4399 26.1654 13.9999C26.1654 7.55992 20.9387 2.33325 14.4987 2.33325ZM10.5904 17.2316C10.462 17.5816 10.1237 17.8033 9.7737 17.8033C9.6687 17.8033 9.57537 17.7916 9.47037 17.7449C8.52536 17.3949 7.73203 16.7066 7.23036 15.8083C6.0637 13.7083 6.78703 10.9666 8.8287 9.69492L11.5587 8.00325C12.562 7.38492 13.7404 7.19825 14.8604 7.48992C15.9804 7.78159 16.9254 8.51659 17.497 9.54325C18.6637 11.6433 17.9404 14.3849 15.8987 15.6566L15.5954 15.8783C15.1987 16.1583 14.6504 16.0649 14.3704 15.6799C14.0904 15.2833 14.1837 14.7349 14.5687 14.4549L14.9304 14.1983C16.237 13.3816 16.6804 11.6899 15.9687 10.3949C15.6304 9.78825 15.082 9.35659 14.4287 9.18159C13.7754 9.00659 13.087 9.11159 12.492 9.48492L9.7387 11.1883C8.4787 11.9699 8.03537 13.6616 8.74703 14.9683C9.0387 15.4933 9.50536 15.9016 10.0654 16.1116C10.5204 16.2749 10.7537 16.7766 10.5904 17.2316ZM20.2387 18.2583L17.5087 19.9499C16.8204 20.3816 16.0504 20.5916 15.2687 20.5916C14.9187 20.5916 14.557 20.5449 14.207 20.4516C13.087 20.1599 12.142 19.4249 11.582 18.3983C10.4154 16.2983 11.1387 13.5566 13.1804 12.2849L13.4837 12.0633C13.8804 11.7833 14.4287 11.8766 14.7087 12.2616C14.9887 12.6583 14.8954 13.2066 14.5104 13.4866L14.1487 13.7433C12.842 14.5599 12.3987 16.2516 13.1104 17.5466C13.4487 18.1533 13.997 18.5849 14.6504 18.7599C15.3037 18.9349 15.992 18.8299 16.587 18.4566L19.317 16.7649C20.577 15.9833 21.0204 14.2916 20.3087 12.9849C20.017 12.4599 19.5504 12.0516 18.9904 11.8416C18.5354 11.6783 18.302 11.1766 18.477 10.7216C18.6404 10.2666 19.1537 10.0333 19.597 10.2083C20.542 10.5583 21.3354 11.2466 21.837 12.1449C22.992 14.2449 22.2804 16.9866 20.2387 18.2583Z"
        fill={theme.colors[color]}
      />
    </Svg>
  );
};

export default Icon;
