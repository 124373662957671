import styled from "styled-components";
// Components
import { Heading } from "components";
// Animations
import { moveTickerAnimation } from "./animations";
// Types
import { StyledTickerAreaProps } from "./types";

export const StyledTickerArea = styled.div<StyledTickerAreaProps>`
  display: flex;
  white-space: nowrap;
  transform: ${({ move }) => `translateX(-${move}px)`};
  animation: ${moveTickerAnimation} ${({ time }) => time}s linear infinite
    ${({ toRight }) => (toRight ? " reverse" : "")};
`;

export const StyledTickerItem = styled(Heading)`
  display: inline;
`;
