// Components + styling
import { Text } from "components";
import { EmailContact } from "./components";
import { EmailIcon, LocationIcon, PhoneIcon } from "components/svg";
import { StyledFooterLink, StyledFooterLinksWrapper, StyledSectionTitle } from "components/footer/styled";
import {
  StyledFooterContactsFlex,
  StyledPhoneListContainer,
  StyledFooterContactsFlexGap,
  StyledFooterContactsContainer,
  StyledFooterContactsFlexColumn,
} from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Context
import { useTranslation } from "context";
// Constants
import { FOOTER_PHONE_LIST, FOOTER_EMAIL_CONTACTS } from "components/footer/constants";
import { AGREEMENTS_ADDRESS_LIST } from "components/agreements-contact-details/constants";

const FooterContacts: React.FC = () => {
  const { t } = useTranslation();

  const { isMobile } = useMatchBreakpoints();

  return (
    <StyledFooterContactsContainer>
      {isMobile && (
        <StyledSectionTitle color="white" textScale="p16Regular">
          {t("Contacts")}
        </StyledSectionTitle>
      )}

      <StyledFooterContactsFlexGap>
        <StyledFooterContactsFlex>
          <EmailIcon width="1.5rem" color="textFourthForDarkBg" />
          <EmailContact>
            <StyledFooterLinksWrapper>
              <StyledFooterLink
                href={`mailto:${FOOTER_EMAIL_CONTACTS.mailFooter}`}
                color={isMobile ? "textFourthForDarkBg" : "white"}
                underline
                external
                mx="0.5rem"
              >
                {FOOTER_EMAIL_CONTACTS.mailFooter}
              </StyledFooterLink>
            </StyledFooterLinksWrapper>
          </EmailContact>
        </StyledFooterContactsFlex>

        <StyledFooterContactsFlex>
          <div>
            <LocationIcon width="1.5rem" color="textFourthForDarkBg" />
          </div>

          <StyledFooterContactsFlexColumn>
            {AGREEMENTS_ADDRESS_LIST.map(({ address }) => (
              <Text
                key={address}
                textScale="p14Regular"
                color={isMobile ? "textFourthForDarkBg" : "white"}
                mx="0.5rem"
                maxWidth={{ _: "100%", tablet: "14.5rem" }}
              >
                {t(address)}
              </Text>
            ))}
          </StyledFooterContactsFlexColumn>
        </StyledFooterContactsFlex>

        <StyledFooterContactsFlex>
          <div>
            <PhoneIcon width="1.5rem" color="textFourthForDarkBg" />
          </div>

          <StyledPhoneListContainer>
            {FOOTER_PHONE_LIST.map(({ label, tel }) => (
              <StyledFooterLinksWrapper key={label}>
                <StyledFooterLink color={isMobile ? "textFourthForDarkBg" : "white"} href={`tel:${tel}`} external>
                  {label}
                </StyledFooterLink>
              </StyledFooterLinksWrapper>
            ))}
          </StyledPhoneListContainer>
        </StyledFooterContactsFlex>
      </StyledFooterContactsFlexGap>
    </StyledFooterContactsContainer>
  );
};

export default FooterContacts;
