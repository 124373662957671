import { useLocation } from "react-router-dom";
// Utils
import { scrollToElementById } from "utils/scroll-to-element-by-id";
// Constants
import { MOBILE_MENU_ANIMATION_DURATION } from "components/header/constants";

const useScrollEffect = () => {
  const { hash } = useLocation();

  React.useEffect(() => {
    if (hash) {
      setTimeout(() => {
        scrollToElementById(hash.replace("#", ""));
      }, MOBILE_MENU_ANIMATION_DURATION);
    }
  }, [hash]);
};

export default useScrollEffect;
