import styled from "styled-components";
import { motion } from "framer-motion";
import { clearfix } from "styles";
// Components
import { Button, Link } from "components";

export const StyledCookiesBar = styled(motion.div)`
  width: 100%;
  max-width: 21.4375rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: fixed;
  bottom: 1rem;
  left: 50%;

  z-index: ${({ theme }) => theme.zIndices.header - 1};

  transform: translateX(-50%);
  padding: 1.5rem 2rem;
  border: 1px solid ${({ theme }) => theme.colors.lineMain};
  border-radius: ${({ theme }) => theme.radii.semiLarge};
  background: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mediaQueries.laptop} {
    max-width: 30.625rem;
    height: 8rem;

    flex-direction: row;
    bottom: 4.375rem;
    left: 4.5rem;
    transform: translateX(0);
    padding: 1.5rem 2rem;
    border-radius: ${({ theme }) => theme.radii.cookies};
  }
`;

export const StyledCookiesTitle = styled.div`
  position: relative;

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding-left: 4.125rem;
    &::before {
      ${clearfix}
      width: 3.8125rem;
      height: 4.1875rem;
      position: absolute;
      top: -4.8125rem;
      left: 50%;
      transform: translateX(-50%);
      background: url("/images/img-cookie.webp") no-repeat 0 center;
      background-size: 3.8125rem 4.1875rem;

      ${({ theme }) => theme.mediaQueries.laptop} {
        width: 3.125rem;
        height: auto;
        top: 0;
        bottom: 0;
        left: 0;
        transform: translateX(0);
        background-size: 3.125rem 3.4375rem;
      }
    }
  }
`;

export const StyledCookiesButton = styled(Button)`
  width: 7.25rem;
  min-width: 7.25rem;
  height: 2rem;
  margin-top: 1rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    max-width: 5rem;
    min-width: 5rem;
    max-height: 5rem;
    min-height: 5rem;
    margin: 0 0 0 1rem;
  }
`;

export const StyledPrivacyPolicy = styled(Link)`
  display: inline;

  cursor: pointer;
`;
