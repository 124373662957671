import styled from "styled-components";
import { styles as textStyles } from "components/text/theme";

import { StyledContent } from "pages/single-blog/components/blog-content/styled";

export const StyledRelatedArticleItem = styled.div<{ isBigArticle: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  max-height: 100%;

  &:hover {
    a {
      text-decoration: underline;
      text-decoration-color: ${({ theme }) => theme.colors.textMain};
    }
  }

  img {
    width: 100%;
    height: -webkit-fill-available;
    object-fit: cover;
    border-radius: 1.25rem;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    flex-direction: ${({ isBigArticle }) => (isBigArticle ? "column" : "row")};
    max-height: ${({ isBigArticle }) => (isBigArticle ? "100%" : "10rem")};
  }
`;

export const StyledArticleDescription = styled(StyledContent)`
  p {
    ${textStyles.p18Regular}
    color: ${({ theme }) => theme.colors.textFourth};
    opacity: 0.7;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;
