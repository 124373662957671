import { useTranslation } from "context";
// Components + styling
import {
  FooterSocial,
  FooterContacts,
  FooterCopyrights,
  FooterDescription,
  FooterNavigationSection,
} from "./components";
import {
  StyledFooterWrapper,
  StyledFooterContainer,
  StyledFooterMainContent,
  StyledFooterInfo,
  StyledFooterNavigationWrapper,
} from "./styled";
import { NavigationMenuAccordion } from "components/header/components/mobile-menu/components";
import { NavItemGeneralSettings, StyledNavItem } from "components/header/components";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Configs
import { BLOCK_ID } from "configs";

import { NAV_LIST } from "components/header/constants";

const Footer: React.FC = () => {
  const [isMobileAccordionOpen, setIsMobileAccordionOpen] = React.useState(false);
  const { isMobile } = useMatchBreakpoints();
  const { t } = useTranslation();

  const handleCloseMobileMenu = () => {
    if (isMobile) {
      setIsMobileAccordionOpen(false);
    }
  };

  const navItemGeneralSettings: NavItemGeneralSettings = {
    textScale: "p24Regular",
    color: "white",
  };

  return (
    <StyledFooterWrapper>
      <StyledFooterContainer
        id={BLOCK_ID.footer}
        pt={{ _: "4.25rem", tablet: "5rem", laptop: "7rem" }}
        pb={{ _: "1.5rem", tablet: "2rem" }}
        px={{ _: "0", tablet: "2rem", laptopL: "4.5rem" }}
      >
        <StyledFooterMainContent>
          <StyledFooterInfo>
            <FooterDescription />

            {isMobile && <FooterSocial />}
          </StyledFooterInfo>

          <StyledFooterNavigationWrapper>
            {isMobile ? (
              <div>
                {NAV_LIST.map(({ label, DropdownComponent, link }) => {
                  return DropdownComponent ? (
                    <div key={label}>
                      <NavigationMenuAccordion
                        DropdownComponent={DropdownComponent}
                        label={label}
                        navItemGeneralSettings={navItemGeneralSettings}
                        handleCloseMobileMenu={handleCloseMobileMenu}
                        isMobileMenuOpen={isMobileAccordionOpen}
                      />
                    </div>
                  ) : (
                    <StyledNavItem
                      external={!link}
                      href={link}
                      color="white"
                      key={label}
                      textScale="p24Regular"
                      p="1rem"
                    >
                      {t(label)}
                    </StyledNavItem>
                  );
                })}
              </div>
            ) : (
              <FooterNavigationSection />
            )}
          </StyledFooterNavigationWrapper>

          {isMobile && <FooterContacts />}
        </StyledFooterMainContent>

        <FooterCopyrights />
      </StyledFooterContainer>
    </StyledFooterWrapper>
  );
};

export default Footer;
