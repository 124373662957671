import { WavingHandIcon } from "components/svg";

import { EXTERNAL_LINKS } from "configs";

import { BenefitsItemProps } from "pages/services/components/benefits/components/benefits-item/types";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { IndustryPageContent } from "../../industries/types";
import { ServicesListItemType } from "../../industries/components/our-services-fintech/types";
import { StagesBlocksItemProps } from "components/stages-blocks/components/stages-blocks-item/types";
import { AccordionContentItemType } from "../../industries/components/our-capabilities/types";

export const ML_CONSULTING_STAGES_BLOCK: StagesBlocksItemProps[] = [
  {
    title: "Industries Empowered",
    description:
      "Machine learning solutions may be helpful for various industries, including finance, healthcare, retail, manufacturing, etc. We customize our ML consultancy accordingly.",
    iconSrc: "/images/expertise/ml-consulting/stages-blocks/industries-empowered-icon.svg",
  },
  {
    title: "Startups to Enterprises",
    description:
      "Having tons of experience, we help to discover the potential of ML-empowered products for startups and enterprises equally.",
    iconSrc: "/images/expertise/ml-consulting/stages-blocks/start-up-for-enterprise-icon.svg",
  },
];

export const ML_CONSULTING_ACCORDION_CONTENT_LIST: AccordionContentItemType[] = [
  {
    title: "Data Strategy and Engineering",
    description:
      "At Idealogic, we believe data organization and management are essential foundations for every project. With our data strategy expertise, you can build the base for successful machine learning projects.",
  },
  {
    title: "Custom Model Development",
    description:
      "Regardless of the industry and your business’ size, we know what exact solutions will suit your needs. Our team creates and implements tailored machine-learning models that target your specific business challenges.",
  },
  {
    title: "AI Integration and Deployment",
    description:
      "Idealogic understands the challenge of integrating new elements into the existing workflow and ensures a smooth transition to AI-empowered systems. With our comprehensible deployment solutions, the process is seamless and quick.",
  },
  {
    title: "Ongoing Support and Optimization",
    description:
      "When we consult you on the most suitable solutions, we bear in mind the scalability of your business. With our continuous support and optimization services, you can be sure your machine-learning solutions evolve alongside your business needs.",
  },
];

export const ML_CONSULTING_ACCORDION_CONTENT_FREQUENTLY_ASK_QUESTIONSLIST: AccordionContentItemType[] = [
  {
    title: "How much does a machine learning project typically cost?",
    description:
      "he cost of a machine learning project varies significantly based on its uniqueness, encompassing factors such as the time invested, development and integration efforts, ongoing maintenance, and the nature of the deliverables. On average,  projects commence at a baseline of $15,000, with more complex initiatives potentially escalating to between $40,000 to $90,000 or beyond.",
  },
  {
    title: "Custom Model Development",
    description:
      "Regardless of the industry and your business’ size, we know what exact solutions will suit your needs. Our team creates and implements tailored machine-learning models that target your specific business challenges.",
  },
  {
    title: "AI Integration and Deployment",
    description:
      "Idealogic understands the challenge of integrating new elements into the existing workflow and ensures a smooth transition to AI-empowered systems. With our comprehensible deployment solutions, the process is seamless and quick.",
  },
  {
    title: "Ongoing Support and Optimization",
    description:
      "When we consult you on the most suitable solutions, we bear in mind the scalability of your business. With our continuous support and optimization services, you can be sure your machine-learning solutions evolve alongside your business needs.",
  },
];

export const ML_CONSULTING_BENEFITS_LIST: BenefitsItemProps[] = [
  {
    title: "Transformative Insights",
    iconSrc: "/images/expertise/ml-consulting/benefits/transformative-insight-icon.svg",
    description: "Derive actionable insights from your data for strategic decisions, securing a competitive advantage.",
  },
  {
    title: "Operational Efficiency",
    iconSrc: "/images/expertise/ml-consulting/benefits/operation-effenciecy-icon.svg",
    description: "Automate and refine business processes to cut costs and enhance services.",
  },
  {
    title: "Innovative Solutions",
    iconSrc: "/images/expertise/ml-consulting/benefits/innovation-solution-icon.svg",
    description:
      "Stay at the forefront of your industry with the latest machine learning innovations, custom-fit for your sector.",
  },
];

export const ML_CONSULTING_BOTTOM_SERVICES_LIST: ServicesListItemType[] = [
  {
    title: "Expert Team",
    description:
      "Boasting a seasoned team of machine learning engineers and data scientists, Idealogic brings depth of expertise and practical experience to every project.",
    imageSrc: "/images/expertise/ml-consulting/our-services/our-services-expert-team.webp",
    imageSrcMobile: "/images/expertise/ml-consulting/our-services/our-services-expert-team.webp",
    aspectRatio: 2.33,
    width: "55%",
    height: "20rem",
    isBlackText: true,
  },
  {
    title: "Ethical AI Use",
    description:
      "Idealogic is dedicated to ethical AI development, emphasizing transparency, fairness, and responsible technology use.",
    bgImageSrc: "/images/industries/fintech/our-services/our-services-fintech-legacy-software-transformation.webp",
    gradient: "buttonAccent",
    width: "55%",
    height: "20rem",
    backgroundSize: "100%",
    backgroundSizeMobile: "100% 12.5rem",
    isBlackText: true,
  },
  {
    title: "Proven Success",
    description:
      "Our track record of successful implementations across diverse sectors underscores our capability to effectively leverage machine learning.",
    imageSrc: "/images/expertise/ml-consulting/our-services/our-services-proven-success.webp",
    imageSrcMobile: "/images/expertise/ml-consulting/our-services/our-services-proven-success.webp",
    height: "41.5rem",
    width: "calc(41% - 0.6875rem)",
    aspectRatio: 0.79,
  },
];

const ML_CONSULTING_CONTENT: IndustryPageContent = {
  banner: {
    title: "Machine Learning Solutions Consulting Services",
    description:
      "Idealogic gives valuable insights about machine learning solutions implementation. We closely learn your business objectives to define which type of customized ML solutions suit you the best. The aim is enhance your decision making and open your business for innovation and sustainable growth.",
    imageSrc: "/images/expertise/ml-consulting/ml-consulting-banner-img.webp",
    imageRatio: 1.75,
  },
  stagesBlocks: ML_CONSULTING_STAGES_BLOCK,
  ourServicesFintech: {
    title: "Why Choose Idealogic",
    description:
      "Our team has established trust relationships with many partners and is proud of our achievements in AI & ML consultancy services.",
    isEstimationButton: true,
    topServicesList: [],
    bottomServicesList: ML_CONSULTING_BOTTOM_SERVICES_LIST,
  },
  ourCapabilities: {
    title: "Our Machine Learning Consulting Capabilities",
    description: "Idealogic excels in providing businesses with the following solutions",
    accordionContent: ML_CONSULTING_ACCORDION_CONTENT_LIST,
  },
  bookACallWithExpert: {
    bgSrc: "/images/expertise/blockchain/book-call-with-a-expert/bg.webp",
    bgMobileSrc: "/images/expertise/blockchain/book-call-with-a-expert/bg.webp",
    title: {
      beforeKeyWord: "",
      keyWord: "Our expert",
      afterKeyWord: "will be happy to discuss the details of your project!",
    },
    description: "",
    linkedin: {
      name: "Artem Zaitsev",
      href: EXTERNAL_LINKS.linkedInArtem,
    },
    jobTitle: "VP of engineering",
    buttonText: "Book a call",
    imageSrc: "/images/expertise/blockchain/book-call-with-a-expert/book-a-call-with-expert-artem-bg.webp",
    mobileImageSrc: "/images/expertise/blockchain/book-call-with-a-expert/book-a-call-with-expert-artem-mobile-bg.webp",
    isReverse: true,
    imageAspectRatio: 1.24,
  },
  benefits: {
    title: "How We Add Value",
    description:
      "We approach every project intending to transform it with innovative solutions to enhance its capabilities and growth.",
    benefitsList: ML_CONSULTING_BENEFITS_LIST,
    isNeedCurveLine: false,
    isShowCall: false,
    isBackgroundImageNeed: false,
  },
  boostBusiness: {
    title: "Embarking on Your Machine Learning Journey",
    description:
      "Book a consultation with us and discover all the benefits of machine learning for your business. Begin a new era of efficiency with our help.",
    buttonTitle: "Schedule a call",
    personImageSrc: "/images/expertise/ml-consulting/boost-businnes/boost-businnes-image.webp",
    personImageSrcMobile: "/images/expertise/ml-consulting/boost-businnes/boost-businness-mobile-img.webp",
    imageRatio: 1.02,
    isAbsolute: true,
    bgColor: "bgThird",
    isWhite: true,
    isHideButtonOnMobile: true,
    isMobileImageContain: true,
    imageMaxWidth: "36.375rem",
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.eplane, CaseStudiesSlugEnums.mindHorizon, CaseStudiesSlugEnums.aiBook],
    title: "AI/ML solutions case studies",
    TitleIcon: WavingHandIcon,
    description:
      "Our team is excited to adopt AI/ML solutions in our projects. Look at the completed works and wait till we present what’s coming soon.",
  },
};

export default ML_CONSULTING_CONTENT;
