// Constants
import { ROUTES, ROUTE_PARAMS } from "navigation/routes";
import {
  getMetaForIndustries,
  getMetaLabelForCaseStudies,
  getDefaultMeta,
  getMetaForServices,
  getMetaLabelForCareers,
  getBasePath,
} from "./";
// Types
import { TranslateFunction } from "context/language-context/types";

export const getCustomMeta = (path: string, t: TranslateFunction) => {
  const basePath = getBasePath(path);

  switch (basePath) {
    case `/:${ROUTE_PARAMS.industryType}`: {
      return getMetaForIndustries(path, t);
    }

    case `/${ROUTES.aboutUs}`: {
      return {
        ...getDefaultMeta(t),
        title: t("About Us"),
      };
    }

    case `/${ROUTES.calendly}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Book a call"),
      };
    }

    case `/${ROUTES.careers}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Careers"),
      };
    }

    case `/${ROUTES.findSolution}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Find Your Solution"),
      };
    }

    case `/${ROUTES.blog}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Stories by our team"),
        description: t(
          "Explore 'Stories by Our Team,' a blog where our team's heartbeats and brainwaves come to life. Dive into tales of innovation, challenges, and triumphs that define us. Each story offers a peek into the diverse experiences and inspirations driving our pursuit of excellence. Join us on this journey of creativity, insight, and personal growth as we share the essence of who we are, one story at a time.",
        ),
        keywords:
          "Team stories blog, Innovation tales, Creative insights blog, Professional growth stories, Behind-the-scenes team, Team challenges and triumphs, Inspirational team journeys, Diverse team experiences, Team-driven excellence, Personal growth insights",
      };
    }

    case `/${ROUTES.caseStudies}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Case Studies"),
      };
    }

    case `/:${ROUTES.caseStudies}`: {
      const meta = getMetaLabelForCaseStudies(path);

      return {
        ...getDefaultMeta(t),
        title: t(meta),
      };
    }

    case `/:${ROUTE_PARAMS.serviceType}`: {
      return getMetaForServices(path, t);
    }

    case `/:${ROUTE_PARAMS.careersType}`: {
      const metaLabel = getMetaLabelForCareers(path);

      return {
        ...getDefaultMeta(t),
        title: t(metaLabel),
      };
    }

    case `/${ROUTES.privacyPolicy}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Privacy Policy"),
      };
    }

    case "/404": {
      return {
        ...getDefaultMeta(t),
        title: t("404"),
      };
    }

    default:
      return {
        ...getDefaultMeta(t),
      };
  }
};
