import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
// Components + styling
import { ClickItem } from "components";
import { FullLogoIcon, LogoMobileIcon } from "components/svg";
// Animations
import { slideVariants } from "components/header/animations";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { ROUTES } from "navigation/routes";
import { MOBILE_MENU_ANIMATION_DURATION, headerThemes } from "components/header/constants";
// Types
import { HeaderThemeProps } from "./types";

export const HeaderLogo: React.FC<HeaderThemeProps> = ({ isMobileMenuOpen, headerTheme, setIsMenuOpen }) => {
  const navigate = useNavigate();
  const { pathname, hash } = useLocation();
  const { isMobileS, isLaptop, isDesktop, isLaptopL } = useMatchBreakpoints();

  const logoColor = headerTheme === headerThemes.light && !isMobileMenuOpen ? "bgDark" : "white";

  const handleClickLogo = () => {
    setIsMenuOpen(false);

    if (pathname === ROUTES.landing && !hash) {
      // Need to use timeout, because of mobiles menu. For correct logic working, we need to wait closing mobile menu. and then scroll to element.
      setTimeout(
        () => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        },
        isDesktop ? 0 : MOBILE_MENU_ANIMATION_DURATION,
      );
    } else {
      navigate(ROUTES.landing);
    }
  };

  return (
    <ClickItem maxHeight={{ _: "1.25rem", laptop: "2rem", laptopL: "1.75rem" }} onClick={handleClickLogo}>
      {isLaptopL ? (
        <FullLogoIcon color={logoColor} width="9.75rem" />
      ) : isLaptop ? (
        <LogoMobileIcon color={logoColor} width="2rem" height="2rem" />
      ) : (
        <>
          {isMobileS ? (
            <LogoMobileIcon color={logoColor} width="1.5rem" />
          ) : (
            <>
              <motion.div initial="hidden" animate={isMobileMenuOpen ? "visible" : "hidden"} variants={slideVariants}>
                <FullLogoIcon color={logoColor} width="6.875rem" />
              </motion.div>
              <motion.div initial="hidden" animate={!isMobileMenuOpen ? "visible" : "hidden"} variants={slideVariants}>
                <LogoMobileIcon color={logoColor} width="1.5rem" />
              </motion.div>
            </>
          )}
        </>
      )}
    </ClickItem>
  );
};
