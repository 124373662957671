// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textSecond", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 107 50" {...props} color="transparent">
      <svg width="107" height="50" viewBox="0 0 107 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.2175 40.8282C8.01694 36.5801 5.17852 30.7962 3.74981 26.2883M3.74981 26.2883C3.58502 25.769 7.67426 25.7396 7.86288 25.7541C12.0008 26.0698 15.9705 26.9172 19.9977 27.8678M3.74981 26.2883C20.4826 46.1861 46.2225 51.5137 61.9136 41.648C63.4688 40.6702 65.1604 39.6043 66.7499 38.3925M66.7499 38.3925C69.2812 36.4627 71.5534 34.1627 72.6026 31.2588C73.5708 28.5788 73.7031 24.6798 71.7593 22.4193C68.128 18.1967 61.2329 20.8458 60.0998 26.0925C59.1041 30.7031 62.3422 36.8603 66.7499 38.3925ZM66.7499 38.3925C66.8429 38.4248 66.9364 38.4551 67.0304 38.4833C89.7157 45.2764 105.484 22.3292 103.753 3.02873"
          stroke={theme.colors[color]}
          strokeWidth="6"
          strokeMiterlimit="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Svg>
  );
};

export default Icon;
