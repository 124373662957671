import { useTranslation } from "context";
import { useMatchBreakpoints } from "hooks";
// Components + styling
import { DropdownMenuItems } from "../dropdown-menu-items";
import { StyledDefaultDropdownList, StyledDefaultDropdownWrapper } from "components/header/styled";
import { StyledDropdownTitleContainer } from "../industries-dropdown/styled";
import { ServicesRocketIcon, ServicesSoftwareEngineeringIcon } from "components/svg";
import { Text } from "components";
// Constants
import { SERVICES_DROPDOWN_LIST, START_UP_AND_SCALING_LIST } from "./constants";
// Types
import { DropdownComponentProps } from "components/dropdown/types";

export const ServicesDropdown: React.FC<DropdownComponentProps> = ({ onDropdownMenuClick }) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <StyledDefaultDropdownWrapper>
      <StyledDropdownTitleContainer>
        <ServicesSoftwareEngineeringIcon width="1.8125rem" height="1.75rem" />

        <Text textScale="p20Bold" color={isDesktop ? "white" : "textFourth"}>
          {t("Software engineering")}
        </Text>
      </StyledDropdownTitleContainer>

      <StyledDefaultDropdownList mb={{ _: "2rem", laptop: "3.375rem" }}>
        <DropdownMenuItems onDropdownMenuClick={onDropdownMenuClick} navSections={SERVICES_DROPDOWN_LIST} />
      </StyledDefaultDropdownList>

      <StyledDropdownTitleContainer>
        <ServicesRocketIcon width="1.75rem" height="1.75rem" />

        <Text textScale="p20Bold" color={isDesktop ? "white" : "textFourth"}>
          {t("Startup & Scaling Companies")}
        </Text>
      </StyledDropdownTitleContainer>

      <StyledDefaultDropdownList>
        <DropdownMenuItems
          onDropdownMenuClick={onDropdownMenuClick}
          navSections={START_UP_AND_SCALING_LIST}
          maxHeight="6.875rem"
          gap="1rem 0"
        />
      </StyledDefaultDropdownList>
    </StyledDefaultDropdownWrapper>
  );
};
