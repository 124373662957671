// // Components + styling
import { HygraphImage } from "components";
import { ProgressCircleIcon } from "components/svg";
import { StyledDurationIconWrapper, StyledGifWrapper } from "./styled";
// Types
import { GifWithDurationProps } from "./types";

const GifWithDuration: React.FC<GifWithDurationProps> = ({ width, gifSrc, gifRatio, duration, handleClickGif }) => {
  return (
    <>
      <StyledGifWrapper
        imageWidth={width}
        onClick={e => {
          handleClickGif?.(e);
        }}
      >
        <HygraphImage src={gifSrc} width={width} variant="circle" cropToWidth={70} aspectRatio={gifRatio} />

        <StyledDurationIconWrapper>
          <ProgressCircleIcon width="100%" height="100%" animationDuration={duration} />
        </StyledDurationIconWrapper>
      </StyledGifWrapper>
    </>
  );
};

export default GifWithDuration;
