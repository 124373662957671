// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { EXTERNAL_LINKS } from "configs";
// Types
import { IndustryPageContent } from "../../industries/types";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { BenefitsItemProps } from "pages/services/components/benefits/components/benefits-item/types";
import { TickerLinkProps } from "components/ticker/components/ticker-link/types";
import { ServicesListItemType } from "../../industries/components/our-services-fintech/types";
import { StagesBlocksItemProps } from "components/stages-blocks/components/stages-blocks-item/types";

export const AI_MOBILE_DEVELOPMENT_CONTENT_BENEFITS_LIST: BenefitsItemProps[] = [
  {
    title: "Custom AI Mobile App Design & Development",
    iconSrc: "/images/expertise/ai-mobile-development/benefits/custom-ai-mobile-icon.svg",
    description: "We build AI-powered mobile apps tailored to your specific business needs and target audience.",
  },
  {
    title: "Integration of AI Features into Existing Mobile Apps",
    iconSrc: "/images/expertise/ai-mobile-development/benefits/integration-of-ai-feature-icon.svg",
    description:
      "Enhance your mobile apps with features like chatbots, voice recognition, or image recognition to streamline the processes.",
  },
  {
    title: "Machine Learning for Mobile Apps",
    iconSrc: "/images/expertise/ai-mobile-development/benefits/machine-learning-icon.svg",
    description:
      "We apply machine learning to personalize user experiences, predict user behavior, and optimize app performance.",
  },
  {
    title: "Data Analytics & Insights",
    iconSrc: "/images/expertise/ai-mobile-development/benefits/data-analytics-icon.svg",
    description:
      "Gather valuable data from your AI mobile app and extract actionable insights to improve user engagement and achieve business goals.",
  },
];

export const AI_MOBILE_DEVELOPMENT_TOP_SERVICES_LIST: ServicesListItemType[] = [
  {
    title: "Prediction Engine",
    description:
      "Bring recommendations to another level with our prediction engine feature that uses historical data and ML algorithms to forecast future behaviors and provide anticipatory responses.",
    bgImageSrc: "/images/industries/fintech/our-services/our-services-fintech-legacy-software-transformation.webp",
    gradient: "buttonAccent",
    width: "40%",
    isBlackText: true,
    backgroundSize: "100%",
    backgroundSizeMobile: "100% 12.5rem",
  },
  {
    title: "Personalized Engine",
    description:
      "Satisfy users' needs with our personalized engine that analyzes user behaviors to tailor content and recommendations.",
    imageSrc: "/images/expertise/ai-mobile-development/our-services/our-services-personalized-engine.webp",
    aspectRatio: 1.69,
    width: "60%",
  },
];

export const AI_MOBILE_DEVELOPMENT_BOTTOM_SERVICES_LIST: ServicesListItemType[] = [
  {
    title: "AI-Driven Visual Search",
    description:
      "Add this feature to allow users to identify products, places, or items within images or videos through AI analysis, instantly enhancing engagement and discovery experiences.",
    imageSrc: "/images/expertise/ai-mobile-development/our-services/our-services-ai-driven.webp",
    imageSrcMobile: "/images/expertise/ai-mobile-development/our-services/mobile/our-services-ai-driven-mobile.webp",
    aspectRatio: 2.07,
    mobileAspectRatio: 1.02,
    width: "calc(70% - 0.6875rem)",
  },
  {
    title: "Image Recognition",
    description:
      "Our image recognition feature enables apps to identify objects, places, people, or other elements within images, facilitating a wide range of applications from security to retail.",
    imageSrc: "/images/expertise/ai-mobile-development/our-services/our-services-image-recognation.webp",
    imageSrcMobile:
      "/images/expertise/ai-mobile-development/our-services/mobile/our-services-image-recognation-mobile.webp",
    mobileAspectRatio: 1.02,
    aspectRatio: 0.82,
    width: "calc(30% - 0.6875rem)",
  },
  {
    title: "Data Extraction",
    description:
      "With this versatile feature, users can automatically retrieve data from unstructured sources, such as text or images, simplifying data analysis and decision-making processes.",
    bgImageSrc: "/images/expertise/fintech/our-services/our-services-fintech-software-quality-assurance.webp",
    gradient: "grayGradient",
    backgroundSize: "20.0625rem",
    backgroundSizeMobile: "12.5rem 9.375rem",
    width: "calc(30% - 0.6875rem)",
  },
  {
    title: "Real-Time AI-Powered Video Analytics",
    description:
      "With this service, users can analyze video content in real-time using AI, recognizing patterns or anomalies and supporting decision-making or automated responses.",
    imageSrc: "/images/expertise/ai-mobile-development/our-services/our-services-real-time-video-analytics.webp",
    aspectRatio: 2.07,
    width: "calc(70% - 0.6875rem)",
    spaceProps: {
      marginLeft: { _: "0", tablet: "-40%" },
    },
  },
];

export const AI_MOBILE_DEVELOPMENT_STAGES_BLOCK: StagesBlocksItemProps[] = [
  {
    title: "Enhanced User Experience",
    description:
      "Get the opportunity to personalize user journeys, offer intelligent recommendations, and provide engaging interactions.",
    iconSrc: "/images/expertise/ai-software-development/stages-block/increased-efficiency-icon.svg",
  },
  {
    title: "Increased User Engagement",
    description:
      "Keep users coming back with features that anticipate their needs and provide a seamless, intuitive experience.",
    iconSrc: "/images/expertise/ai-mobile-development/stages-block/increase-user-engagement-icon.svg",
  },
  {
    title: "Improved Operational Efficiency",
    description:
      "With task automation, you can streamline workflows, reducing manual work for both users and businesses.",
    iconSrc: "/images/expertise/ai-mobile-development/stages-block/improved-operational-efficiency-icon.svg",
  },
  {
    title: "Data-Driven Decision Making",
    description:
      "Gain valuable data insights from user behavior to inform future strategies and optimize app features.",
    iconSrc: "/images/expertise/ai-mobile-development/stages-block/data-driven-icon.svg",
  },
];

export const AI_MOBILE_DEVELOPMENT_COVERED_INDUSTRIES_LIST: TickerLinkProps[] = [
  { label: "iOS Mobile App Development" },
  { label: "Android Mobile App Development" },
  { label: "Cross-Platform App Development " },
];

const AI_MOBILE_DEVELOPMENT_CONTENT: IndustryPageContent = {
  banner: {
    title: "Revolutionize Your Business with AI-enabled Apps",
    description:
      "Idealogic is an AI app development company focused on achieving significant results for your business sustainability.",
    imageSrc: "/images/expertise/ai-mobile-development/ai-mobile-development-banner.webp",
    imageRatio: 1.75,
  },
  benefits: {
    title: "How we can help",
    description:
      "Being an artificial intelligence mobile app development company, Idealogic provides various services for different industries.",
    benefitsList: AI_MOBILE_DEVELOPMENT_CONTENT_BENEFITS_LIST,
    isBackgroundImageNeed: false,
    isNeedCurveLine: false,
    buttonText: "Schedule a call",
    isButtonPositionStart: true,
  },
  ourServicesFintech: {
    title: "Artificial Intelligence for App Development",
    description:
      "Our team crafts solutions that power up your business and simultaneously are flexible and scalable enough to meet future needs.",
    isEstimationButton: true,
    topServicesList: AI_MOBILE_DEVELOPMENT_TOP_SERVICES_LIST,
    bottomServicesList: AI_MOBILE_DEVELOPMENT_BOTTOM_SERVICES_LIST,
  },
  stagesBlocks: AI_MOBILE_DEVELOPMENT_STAGES_BLOCK,
  bookACallWithExpert: {
    bgSrc: "/images/expertise/blockchain/book-call-with-a-expert/bg.webp",
    bgMobileSrc: "/images/expertise/blockchain/book-call-with-a-expert/bg.webp",
    title: {
      beforeKeyWord: "",
      keyWord: "Meet Our",
      afterKeyWord: "AI Mobile App Expert",
    },
    description:
      "With a proven track record of successful client projects and a commitment to continuous innovation, we deliver customized mobile apps that exceed expectations.",
    linkedin: {
      name: "Evgeniy Ochkalov",
      href: EXTERNAL_LINKS.linkedInEvgeniy,
    },
    jobTitle: "Head of Front-end department",
    buttonText: "Book a call",
    imageSrc:
      "/images/expertise/ai-mobile-development/book-a-call-with-expert/book-a-call-with-expert-evgeniy-ochkalov.webp",
    mobileImageSrc:
      "/images/expertise/ai-mobile-development/book-a-call-with-expert/book-a-call-with-expert-evgeniy-ochkalov.webp",
    isReverse: true,
    imageAspectRatio: 1.19,
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.secondFloor],
    title: "AI-Enabled Apps case studies",
    TitleIcon: WavingHandIcon,
    description:
      "Look at the cases we’ve delivered as a top AI application development company. There are even more coming soon.",
  },
  boostBusiness: {
    title: "Unlock the power of custom ChatGPT for your mobile app",
    description: "We will take your project and create something incredible for you",
    buttonTitle: "Schedule a call",
    personImageSrc: "/images/expertise/ai-mobile-development/white-label-ai-mobile-development.webp",
    imageRatio: 1.28,
    isAbsolute: true,
    bgColor: "bgThird",
    isWhite: true,
    isHideImageOnMobile: true,
  },
  coveredIndustries: {
    coveredIndustriesList: AI_MOBILE_DEVELOPMENT_COVERED_INDUSTRIES_LIST,
  },
  ourCustomerReview: {
    title: "Our customer review",
    description: "We've worked with startups and enterprises – look what they have to say about us!",
    bgImageSrc: "/images/expertise/ai-mobile-development/our-customer-review/our-customer-review-bg.webp",
    bgGradient: "customerReviewOscarWildeGradient",
    isTextClamp: false,
    owner: {
      name: "Oscar Wilde",
      logo: "/images/expertise/ai-mobile-development/our-customer-review/ai-book-logo.svg",
      logoAspectRatio: 3.66,
      logoSize: "10rem",
      jobTitle: "Co-founder of AIBook",
      image: "/images/expertise/ai-mobile-development/our-customer-review/our-customer-review-oscar-wilde.webp",
      imageAspectRatio: 0.96,
      reviewText:
        "“As the creator of AiBook, I'm thrilled with Idealogic's integration of image generation based on classic authors' quotes. Their innovative approach has enriched our platform, creating a vivid bridge between timeless literature and modern technology, and offering our users a unique, immersive experience.”",
    },
  },
};

export default AI_MOBILE_DEVELOPMENT_CONTENT;
