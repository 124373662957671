import { Text } from "components";

import { useTranslation } from "context";

import { FooterItemSectionInterface } from "components/footer/types";

const FooterItemSection: React.FC<FooterItemSectionInterface> = ({ title, children }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Text color="white" textScale="p18Medium">
        {t(title)}
      </Text>

      {children}
    </div>
  );
};

export default FooterItemSection;
