// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "white", animationType, ...props }) => {
  return (
    <Svg viewBox="0 0 107 107" width="107" height="107" animationType={animationType} {...props} color={color}>
      <g clipPath="url(#clip0_1_8221)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M62.7423 61.0518C63.4944 61.1613 64.2504 61.2644 64.8919 61.3149C76.3295 62.2754 88.8988 60.2005 98.9357 54.6221C99.2982 54.4168 99.4495 53.9665 99.2451 53.5994C99.0407 53.2322 98.5958 53.1115 98.2329 53.3173C88.3925 58.7143 76.1601 60.7348 65.0081 59.7959C64.6491 59.7717 64.2344 59.7185 63.8184 59.6674C64.2817 58.8632 64.4469 58.0561 64.4261 57.3024C64.3632 56.1633 63.8269 55.0592 62.9816 54.0799C61.7947 52.7144 60.0557 51.6135 58.2598 50.9336C56.8744 50.425 55.4553 50.1909 54.3734 50.3499C53.4743 50.4601 52.7791 50.8262 52.3222 51.3968C51.8438 51.9994 51.6288 52.8805 51.9301 54.1117C52.3997 56.2126 53.9166 57.7584 55.9386 58.8853C57.4154 59.7057 59.1374 60.2715 60.8218 60.6697C60.3847 60.9864 59.8731 61.302 59.2859 61.6188C54.7481 63.9141 47.4514 64.0543 39.671 63.0108C27.8351 61.4122 14.8684 57.0188 8.73413 52.9556C8.42218 52.749 7.9211 52.8248 7.68802 53.173C7.45494 53.5212 7.57761 54.0108 7.88956 54.2173C14.1796 58.3841 27.3784 62.8788 39.4826 64.5249C47.6106 65.6097 55.2618 65.3894 59.9651 62.9588C61.1319 62.3367 62.0731 61.7151 62.7423 61.0518ZM62.1894 59.4114C62.7161 58.7367 62.9433 58.0611 62.9348 57.4012C62.8862 56.577 62.4443 55.78 61.8565 55.0887C60.8418 53.9142 59.3052 52.9585 57.7485 52.3697C56.6656 51.9697 55.5455 51.7378 54.6644 51.821C54.1777 51.8756 53.779 52.0222 53.5066 52.3171C53.2815 52.6534 53.2601 53.1343 53.3717 53.7523C53.7981 55.4694 55.0901 56.6778 56.6767 57.5582C58.3187 58.4682 60.3831 59.0832 62.1894 59.4114Z"
          fill="url(#paint0_linear_1_8221)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.2119 53.3886C94.6381 55.9254 91.7052 59.7459 89.7609 63.6594C89.5814 64.0397 89.7278 64.4934 90.0679 64.6579C90.4594 64.8577 90.93 64.7158 91.1092 64.336C92.9724 60.6556 95.7064 57.0194 99.1029 54.6355C99.4244 54.3795 99.513 53.9106 99.2854 53.5781C99.0055 53.2115 98.5334 53.1325 98.2119 53.3886Z"
          fill="url(#paint1_linear_1_8221)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.3514 53.2212C94.3238 54.418 89.8051 52.9849 86.1546 51.1522C85.8145 50.9876 85.3439 51.1303 85.1644 51.5106C84.9852 51.8904 85.1322 52.3437 85.5241 52.543C89.4593 54.5108 94.4018 55.9822 98.8001 54.68C99.1877 54.5493 99.4018 54.1171 99.2905 53.7229C99.1795 53.3283 98.7387 53.091 98.3514 53.2212Z"
          fill="url(#paint2_linear_1_8221)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1_8221"
          x1="71.99"
          y1="90.55"
          x2="27.748"
          y2="14.8701"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#887EF0" />
          <stop offset="0.3" stopColor="#315FDA" />
          <stop offset="0.735" stopColor="#104C9F" />
          <stop offset="1" stopColor="#012B73" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_8221"
          x1="92.4715"
          y1="64.4342"
          x2="89.4233"
          y2="62.0757"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#887EF0" />
          <stop offset="0.3" stopColor="#315FDA" />
          <stop offset="0.735" stopColor="#104C9F" />
          <stop offset="1" stopColor="#012B73" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_8221"
          x1="95.8518"
          y1="58.5867"
          x2="90.048"
          y2="45.57"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#887EF0" />
          <stop offset="0.3" stopColor="#315FDA" />
          <stop offset="0.735" stopColor="#104C9F" />
          <stop offset="1" stopColor="#012B73" />
        </linearGradient>
        <clipPath id="clip0_1_8221">
          <rect width="76.8317" height="76.8317" fill="white" transform="translate(64.0938 107.004) rotate(-146.203)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
