import useMouse from "@react-hook/mouse-position";
// Hooks
import { useElementDimensions } from "hooks";
// Constants
import { BLOCK_ID } from "configs";

const useCustomCursor = () => {
  const root = document.getElementById(BLOCK_ID.root);

  const {
    elementDimensions: { offsetHeight },
  } = useElementDimensions({ id: BLOCK_ID.header });

  const ref = React.useRef<HTMLDivElement | null>(null);

  const mouse = useMouse(root, {
    enterDelay: 100,
    leaveDelay: 100,
  });

  const [cursorVariant, setCursorVariant] = React.useState("hidden");
  const [isCustomCursorVisible, setIsCustomCursorVisible] = React.useState(false);

  // TODO Resolve bug (DS-127) with a visible custom cursor out of userCase section when scrolling. (Below commented code is possible way to fix bug, but it's not finished. Maybe, after fix there is no need for code with IntersectionObserver)
  // React.useEffect(() => {
  //   window.addEventListener("wheel", handleWindowWheel, { passive: false });
  //   return () => {
  //     window.removeEventListener("wheel", handleWindowWheel);
  //   };
  // }, []);

  // const handleWindowWheel = (e: WheelEvent) => {
  //   handleMouseLeave();
  // };

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (!entry.isIntersecting) {
        handleMouseLeave();
      }
    });

    if (ref && ref?.current) {
      observer.observe(ref.current as Element);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  let mouseXPosition = 0;
  let mouseYPosition = 0;

  if (mouse.x !== null) {
    mouseXPosition = mouse.x;
  }

  if (mouse.y !== null) {
    mouseYPosition = mouse.y - offsetHeight;
  }

  const variants = {
    hidden: {
      opacity: 0,
      scale: 0,
      top: mouseYPosition,
      left: mouseXPosition,
    },
    visible: {
      opacity: 1,
      scale: 1,
      top: mouseYPosition,
      left: mouseXPosition,
    },
  };

  const transition = {
    type: "spring",
    stiffness: 1000,
    damping: 50,
    mass: 1,

    opacity: {
      ease: [0.75, 0.01, 0.3, 2.33],
      duration: 0.3,
    },
    scale: {
      ease: [0.75, 0.01, 0.3, 2.33],
      duration: 0.3,
    },
  };

  const handleMouseEnter = () => {
    setCursorVariant("visible");
    setIsCustomCursorVisible(true);
  };

  const handleMouseLeave = () => {
    setCursorVariant("hidden");
    setIsCustomCursorVisible(false);
  };

  return {
    ref,
    isCustomCursorVisible,
    cursorVariant,
    variants,
    transition,
    handleMouseEnter,
    handleMouseLeave,
  };
};

export default useCustomCursor;
