// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFifth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 284 78" {...props} animationType="landingBannerStarsSm" color="transparent">
      <path
        d="M261.376 0.5C261.376 5.04502 263.69 5.5133 266.032 5.45821 M261.873 9.56254C261.873 9.56254 262.12 9.39303 262.231 9.12181C262.6 8.2128 262.552 7.51165 262.837 7.13853C263.277 6.56008 264.379 5.76125 266.032 5.45825 M257.906 5.9277C257.906 5.9277 259.889 5.34926 260.881 5.95526C261.406 6.27614 261.571 7.20299 261.68 7.88344C261.834 8.84754 261.762 9.50863 261.762 9.50863 M257.906 5.89894C257.906 5.89894 260.074 5.59494 260.798 4.08092C261.101 3.44737 261.432 3.11684 261.459 1.98746C261.473 1.40852 261.377 0.5 261.377 0.5"
        stroke={theme.colors[color]}
        strokeWidth="0.881459"
        strokeLinecap="round"
      />
      <path
        d="M278.376 63.5C278.376 68.045 280.69 68.5133 283.032 68.4582 M278.873 72.5625C278.873 72.5625 279.12 72.393 279.231 72.1218C279.6 71.2128 279.552 70.5117 279.837 70.1385C280.277 69.5601 281.379 68.7613 283.032 68.4583 M274.906 68.9277C274.906 68.9277 276.889 68.3493 277.881 68.9553C278.406 69.2761 278.571 70.203 278.68 70.8834C278.834 71.8475 278.762 72.5086 278.762 72.5086 M274.906 68.8989C274.906 68.8989 277.074 68.5949 277.798 67.0809C278.101 66.4474 278.432 66.1168 278.459 64.9875C278.473 64.4085 278.377 63.5 278.377 63.5"
        stroke={theme.colors[color]}
        strokeWidth="0.881459"
        strokeLinecap="round"
      />
      <path
        d="M4.37646 68.5C4.37646 73.045 6.69029 73.5133 9.03168 73.4582 M4.87256 77.5625C4.87256 77.5625 5.12047 77.393 5.23065 77.1218C5.59993 76.2128 5.55237 75.5117 5.83666 75.1385C6.27738 74.5601 7.37921 73.7613 9.03195 73.4583 M0.905762 73.9277C0.905762 73.9277 2.88905 73.3493 3.88069 73.9553C4.40578 74.2761 4.57058 75.203 4.67951 75.8834C4.83385 76.8475 4.76215 77.5086 4.76215 77.5086 M0.905762 73.8989C0.905762 73.8989 3.07396 73.5949 3.79805 72.0809C4.10105 71.4474 4.4316 71.1168 4.45914 69.9875C4.47326 69.4085 4.37651 68.5 4.37651 68.5"
        stroke={theme.colors[color]}
        strokeWidth="0.881459"
        strokeLinecap="round"
      />
      <path
        d="M20.3765 0.5C20.3765 5.04502 22.6903 5.5133 25.0317 5.45821 M20.8726 9.56254C20.8726 9.56254 21.1205 9.39303 21.2306 9.12181C21.5999 8.2128 21.5524 7.51165 21.8367 7.13853C22.2774 6.56008 23.3792 5.76125 25.0319 5.45825 M16.9058 5.9277C16.9058 5.9277 18.8891 5.34926 19.8807 5.95526C20.4058 6.27614 20.5706 7.20299 20.6795 7.88344C20.8339 8.84754 20.7622 9.50863 20.7622 9.50863 M16.9058 5.89894C16.9058 5.89894 19.074 5.59494 19.7981 4.08092C20.1011 3.44737 20.4316 3.11684 20.4591 1.98746C20.4733 1.40852 20.3765 0.5 20.3765 0.5"
        stroke={theme.colors[color]}
        strokeWidth="0.881459"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default Icon;
