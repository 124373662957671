import React, { useContext, PropsWithChildren } from "react";
import { useQuery } from "@apollo/client";

import { BlogsContextData } from "./types";

import {
  GetPostsResponse,
  GetPostsVars,
  GetTagsResponse,
  POSTS_PER_PAGE,
  DEFAULT_WHERE_PARAMS,
  getPosts,
  getTags,
  Post,
} from "services";

export const BlogsContext = React.createContext<BlogsContextData | null>(null);

export const BlogsProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [page, setPage] = React.useState(1);
  const [filters, setFilters] = React.useState<string[]>([]);
  const [viewPosts, setViewPosts] = React.useState<Post[]>([]);

  const skipValue = page === 1 ? undefined : (page - 1) * POSTS_PER_PAGE + 1; // added + 1, as it's Main post,
  const whereParameter =
    filters.length === 0
      ? DEFAULT_WHERE_PARAMS
      : {
          ...DEFAULT_WHERE_PARAMS,
          tags_some: { id_in: filters },
        };

  const { data, loading } = useQuery<GetPostsResponse, GetPostsVars>(getPosts, {
    // if load first page, need POSTS_PER_PAGE + 1 (Main post) posts, for other pages need only POSTS_PER_PAGE
    variables: { skip: skipValue, first: page === 1 ? POSTS_PER_PAGE + 1 : POSTS_PER_PAGE, where: whereParameter },
  });

  const { posts = [], postsConnection } = data || {};

  const tagsData = useQuery<GetTagsResponse>(getTags);
  const { tags = [] } = tagsData.data || {};

  React.useEffect(() => {
    if (loading) {
      return;
    }

    if (page !== 1) {
      setViewPosts(prev => [...prev, ...posts]);
    } else {
      setViewPosts(posts);
    }
  }, [posts]);

  const handleFilterSelect = (id?: string) => {
    setPage(1);

    if (!id) {
      setFilters([]);
      return;
    }

    let updatedFilters: string[];

    if (filters.includes(id)) {
      updatedFilters = filters.filter(filterId => filterId !== id);
    } else {
      updatedFilters = [...filters, id];
    }

    if (tags.length && tags.length === updatedFilters.length) {
      setFilters([]);
    } else {
      setFilters(updatedFilters);
    }
  };

  const loadMorePosts = () => {
    setPage(page + 1);
  };

  return (
    <BlogsContext.Provider
      value={{ page, postsConnection, tags, filters, loading, posts, viewPosts, loadMorePosts, handleFilterSelect }}
    >
      {children}
    </BlogsContext.Provider>
  );
};

export const useBlogsContext = () => {
  const context = useContext(BlogsContext);

  if (!context) {
    throw new Error("Blogs context is not found");
  }

  return context;
};
