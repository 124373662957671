import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import { Box, Container } from "components/layout";
// Constants
import { headerThemes } from "./constants";
// Types
import { StyledHeaderContainerProps, StyledHeaderProps, StyledHeaderWrapperProps } from "./types";

export const StyledHeader = styled(Box)<StyledHeaderProps>`
  transition: ${({ theme }) => theme.transitions.default};
  background: ${({ isBgWhite, theme }) => (isBgWhite ? theme.colors.white : "transparent")};
`;

export const StyledHeaderWrapper = styled.div<StyledHeaderWrapperProps>`
  width: 100%;
  z-index: ${({ theme }) => theme.zIndices.header};
  min-height: unset;
  max-width: ${({ theme }) => theme.siteWidth}px;
  margin: 0 auto;
  background-color: ${({ headerTheme, theme, isTransparent }) =>
    isTransparent
      ? headerTheme === headerThemes.light || headerTheme === headerThemes.dark
        ? "transparent"
        : "inherit"
      : headerTheme === headerThemes.light
        ? theme.colors.white
        : "inherit"};
  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 0 3rem 0.25rem;

    #tooltip {
      max-width: 81.0625rem;
      width: 100%;
      transform: translate3d(0, 67px, 0) !important;
      left: 0 !important;
    }
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    #tooltip {
      left: calc(50% - 81.0625rem / 2) !important;
    }
  }
`;

export const StyledHeaderContainer = styled(Container)<StyledHeaderContainerProps>`
  width: 100%;
  height: 4.25rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  backdrop-filter: ${({ isTransparent }) => (isTransparent ? "blur(0)" : "blur(3px)")};
  background: ${({ headerTheme, theme, isTransparent }) =>
    (headerTheme === headerThemes.light && isTransparent) || headerTheme === headerThemes.transparent
      ? "transparent"
      : headerTheme === headerThemes.dark
        ? theme.colors.bgDark
        : theme.colors.white};
  max-width: unset;
  transition: ${({ theme }) => theme.transitions.default};

  ${({ theme }) => theme.mediaQueries.laptop} {
    height: 4.875rem;
    padding: 0 1.63rem;

    border-radius: ${({ theme }) => theme.radii.header};
  }
`;

export const StyledMotionHeader = styled(motion.div)`
  position: fixed;
  top: 0px;

  z-index: ${({ theme }) => theme.zIndices.header};
  width: 100%;

  transition: ${({ theme }) => theme.transitions.defaultMotion};
`;

export const StyledDefaultDropdownWrapper = styled.div`
  width: 100%;
  flex-direction: column;

  display: flex;

  ${({ theme }) => theme.mediaQueries.laptop} {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2rem;
    border-radius: ${({ theme }) => theme.radii.semiLarge};
    background: ${({ theme }) => `${theme.colors.bgDark}FA`}; /* FA used for 98% opacity in hex */
    max-height: calc(100vh - 8rem);
    overflow-y: scroll;
  }
`;

export const StyledDefaultDropdownList = styled(Box)`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding-bottom: 1rem;

  background-color: ${({ theme }) => theme.colors.bgDarkSecond};

  ${({ theme }) => theme.mediaQueries.laptop} {
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    padding-bottom: 0;

    background-color: initial;
  }
`;

export const StyledHeaderButtonWrapper = styled.div`
  height: auto;

  ${({ theme }) => theme.mediaQueries.laptop} {
    height: 3.125rem;
  }
`;
