// Components + styling
import { Heading, Link, Text } from "components";
import { StyledTickerArea, StyledTickerItem } from "./styled";
// Types
import { TickerLineProps } from "./types";

const TickerLine: React.FC<TickerLineProps> = ({
  list,
  link,
  time,
  divider,
  toRight,
  isExternal,
  dividerGap,
  containerOffsetWidth,
}) => {
  const [moveLeft, setMoveLeft] = React.useState(0);
  const [showList, setShowList] = React.useState(list);
  const [realTime, setRealTime] = React.useState(time);

  const tickerArea = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const containerWidth = Math.floor(containerOffsetWidth);
    const areaWidth = Math.floor(tickerArea?.current?.scrollWidth || 0);

    const copyTimes = Math.max(2, Math.ceil((containerWidth * 2) / areaWidth));

    const newMoveLeft = areaWidth * Math.floor(copyTimes / 2);

    const newRealTime = time * parseFloat((newMoveLeft / containerWidth).toFixed(2));

    setShowList(getFillList(list, copyTimes));
    setMoveLeft(newMoveLeft);
    setRealTime(newRealTime);
  }, [list]);

  const getFillList = (list: string[], copyTimes = 1) => {
    const fillList = [];

    for (let i = 0; i < copyTimes; i++) {
      fillList.push(...list);
    }

    return fillList;
  };

  return (
    <StyledTickerArea ref={tickerArea} move={moveLeft} time={realTime} toRight={toRight}>
      {showList.map((item, index) => {
        return (
          <div key={index}>
            {isExternal ? (
              <Link external href={link} color="white">
                <Heading scale="h4" as="h4">
                  {item}
                  <Text as="span" mx={dividerGap} color="white">
                    {divider}
                  </Text>
                </Heading>
              </Link>
            ) : (
              <StyledTickerItem scale="h4" as="h4" color="white">
                {item}

                <Text as="span" mx={dividerGap} color="white">
                  {divider}
                </Text>
              </StyledTickerItem>
            )}
          </div>
        );
      })}
    </StyledTickerArea>
  );
};

TickerLine.defaultProps = {
  list: [],
  time: 10,
  containerOffsetWidth: 300,
  toRight: false,
};

export default TickerLine;
