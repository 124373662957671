// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "bgDark", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg width="29" height="28" viewBox="0 0 29 28" {...props} color="transparent">
      <rect x="0.5" width="28" height="28" rx="14" fill={theme.colors.bgSecond} />
      <g clipPath="url(#clip0_12092_106336)">
        <mask
          id="mask0_12092_106336"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="8"
          y="7"
          width="13"
          height="13"
        >
          <path d="M8 7H21V20H8V7Z" fill="white" />
        </mask>
        <g mask="url(#mask0_12092_106336)">
          <path
            d="M18.2375 8H20.2311L15.8761 12.9901L21 19.7817H16.9886L13.8444 15.6635L10.2509 19.7817H8.25536L12.9131 14.4424L8 8.00093H12.1136L14.9513 11.7644L18.2375 8ZM17.5364 18.5857H18.6414L11.51 9.13379H10.3251L17.5364 18.5857Z"
            fill={theme.colors[color]}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_12092_106336">
          <rect width="13" height="13" fill="white" transform="translate(8 7)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
