import styled from "styled-components";

import Link from "components/link";
import { Svg } from "components/svg";

export const StyledDropDownsWrapper = styled.div<{ maxHeight?: string; gap?: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : "1rem")};

  flex-wrap: wrap;
  ${({ theme }) => theme.mediaQueries.laptop} {
    max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "40.875rem")};
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "28rem")};
  }
`;

export const StyledDropdownContainer = styled.div<{ itemsLength: number; isFixedWidth?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.laptop} {
    flex: ${({ itemsLength, isFixedWidth }) => isFixedWidth && `1 0 ${100 / itemsLength}%`};
  }
`;

export const StyledBlockTitleLink = styled(Link)`
  -webkit-text-fill-color: ${({ theme }) => theme.colors.white};

  ${Svg} {
    path {
      stroke: ${({ theme }) => theme.colors.white};
    }
  }

  &:hover {
    -webkit-text-fill-color: transparent;
    ${Svg} {
      path {
        stroke: url("#paint0_linear_12208_81157");
      }
    }
  }
`;
