// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "lineSecond", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 345 18" {...props} color="transparent">
      <path
        d="M1.45554 2.87558C1.94896 5.04231 2.92207 7.06963 4.13676 8.89253C15.6816 26.2161 39.7157 1.99353 54.9145 2.89702C68.039 3.67727 69.8506 16.5156 88.9438 17.0668C107.442 17.6011 114.824 7.22199 130.318 2.56327C142.83 -1.19899 148.744 12.6928 172.833 12.5374"
        stroke={theme.colors[color]}
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M343.544 2.87558C343.051 5.04231 342.078 7.06963 340.863 8.89253C329.318 26.2161 305.284 1.99353 290.086 2.89702C276.961 3.67727 275.149 16.5156 256.056 17.0668C237.558 17.6011 230.176 7.22199 214.682 2.56327C202.17 -1.19899 196.256 12.6928 172.167 12.5374"
        stroke={theme.colors[color]}
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
