import styled from "styled-components";
// Components
import { DefaultSlideImage } from "components";
import { StyledParallaxImageWrapper } from "components/contact-us/components/contact-us-parallax-img/styled";

export const StyledSlideImage1 = styled(DefaultSlideImage)`
  width: 0.625rem;
  position: absolute;
  top: -2rem;
  left: 1.25rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 1.25rem;
    top: 2.5rem;
    left: initial;
    right: 21.875rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 6.25rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    right: 33.125rem;
  }
`;

export const StyledSlideImage2 = styled(DefaultSlideImage)`
  width: 1.25rem;
  position: absolute;
  top: 1.875rem;
  right: 1.25rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 2.375rem;
    top: -2.25rem;
    right: 14.375rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: -5.375rem;
    right: 14.375rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: -4rem;
    right: 18.75rem;
  }
`;

export const StyledSlideImage3 = styled(DefaultSlideImage)`
  width: 0.75rem;
  position: absolute;
  top: -2.5rem;
  right: 5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 1.25rem;
    top: -0.875rem;
    right: 7.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: -4.375rem;
    right: 7.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: -2.75rem;
    right: 8.75rem;
  }
`;

export const StyledSlideImage4 = styled(DefaultSlideImage)`
  width: 1.25rem;
  position: absolute;
  top: -2.875rem;
  right: 11.875rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    display: none;
  }
`;

export const StyledSlideImage5 = styled(DefaultSlideImage)`
  width: 2rem;
  display: none;
  position: absolute;
  top: -1.25rem;
  right: 0.375rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    display: block;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 1.375rem;
    right: 1.25rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    right: 2.5rem;
  }
`;

export const StyledCoveredIndustriesParallaxImage = styled(StyledParallaxImageWrapper)``;
