// Components
import { WavingHandIcon } from "components/svg";
// Constants
import {
  BANKING_INDUSTRY_BENEFITS_LIST,
  BANKING_INDUSTRY_WHO_WE_SERVE_LIST,
  BANKING_INDUSTRY_ACCORDION_CONTENT_LIST,
} from "../components/industry-info/constants";
// Types
import { IndustryPageContent } from "../types";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";

export const HOW_START_BANKING_INDUSTRY_LIST = [
  {
    title: "Connect with Us",
    description: "Speak to our solutions expert to define business goals and clarify requirements.",
    iconSrc: "/images/fintech/1.svg",
  },
  {
    title: "Get a Free Estimation",
    description: "In just 2 working days, you'll receive a free estimate along with a plan for further work.",
    iconSrc: "/images/fintech/2.svg",
  },
  {
    title: "Discuss a Plan",
    description: "If we agree, we'll sign an NDA and commence work according to the plan.",
    iconSrc: "/images/fintech/3.svg",
  },
];

const BANKING_INDUSTRIES_CONTENT: IndustryPageContent = {
  banner: {
    title: "Idealogic's cutting-edge banking IT solutions",
    description:
      "Stay ahead of the fintech curve by boosting operational efficiency and delivering exceptional customer experiences tailored for the banking industry.",
    imageSrc: "/images/industries/banking-software-development/banking-software-development-banner-bg.webp",
    imageRatio: 1.75,
    buttonLabel: "Get a Free Estimation Today",
  },
  whoWeServe: {
    title: "Sectors We Serve",
    description:
      "We have extensive experience in delivering banking software solutions across various industries. Discover what we can do for you:",
    whoWeServeListLayoutProps: { maxHeight: { _: "100%", tablet: "11.1875rem" } },
    whoWeServeList: BANKING_INDUSTRY_WHO_WE_SERVE_LIST,
    isButton: true,
    buttonText: "Explore Your Options",
  },
  ourCapabilities: {
    title: "Banking Software Development Expertise Innovation Meets Expertise",
    description:
      "The Idealogic team has mastered the design and development of various banking solutions. Bring your idea to us, and we'll implement it immediately.",
    accordionContent: BANKING_INDUSTRY_ACCORDION_CONTENT_LIST,
    buttonText: "Talk To Us!",
    isAccordionHeading: true,
    // isActionButton: true,
    // actionButtonText: "Explore Mobile Banking Solutions",
    accordionHeadingText: "Our Specializations Include:",
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.inder],
    title: "Real-World Success Stories",
    TitleIcon: WavingHandIcon,
    actionButtonText: "See All Works",
    description:
      "Explore real-world examples of how our solutions have redefined traditional banking approaches, leveraging the power of technology.",
  },
  benefits: {
    title: "Comprehensive Financial Technology Consulting Services",
    description:
      "At Idealogic, we offer comprehensive financial technology consulting services to provide you with a deeper understanding of the development process and ensure the success of your project.",
    benefitsList: BANKING_INDUSTRY_BENEFITS_LIST,
    isBackgroundImageNeed: false,
    isNeedCurveLine: false,
    buttonText: "Schedule a call",
  },
  ourCustomerReview: {
    title: "Testimonials",
    description: "We've collaborated with startups and enterprises alike – see what they have to say about us!",
    bgImageSrc: "/images/industries/banking-software-development/our-customer-review/our-customer-review-bg.webp",
    linkWholeReview: "https://www.goodfirms.co/company/idealogic",
    bgGradient: "customerReviewRomanGradient",
    isTextClamp: true,
    buttonText: "Let’s Discuss Your Idea Now",
    owner: {
      name: "Roman Samoilyk",
      logo: "/images/industries/banking-software-development/our-customer-review/vp-bank-logo.svg",
      imageAspectRatio: 0.89,
      logoSize: "14.375rem",
      logoAspectRatio: 4.44,
      jobTitle: "Head of Collection at VPBank",
      image:
        "/images/industries/banking-software-development/our-customer-review/our-customer-review-roman-samoilyk.webp",
      reviewText:
        "Idealogic is a team that consists of experts in different areas. That’s why they offered to make the whole project from the beginning to the support stage that definitely worked for me because I didn’t have a technical team. I liked the way they took responsibility by offering a fixed-price contract and complying with it till the end despite the fact that we had some delays from both parties. Also, I was impressed when they started doing a free technical concept to describe all the functionality without any commitment. I like their attitude, willingness to polish the final result as much as possible and cost-efficiency",
    },
  },
  relatedArticlesFilterID: "cll6k3phwgm9m0bw36necx1rz",
};

export default BANKING_INDUSTRIES_CONTENT;
