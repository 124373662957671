import { ROUTES } from "navigation/routes";
import { EXTERNAL_LINKS } from "configs";

import { ServicesVariants } from "pages/services/types";
import { DropdownNavSection } from "../dropdown-menu-items/types";

export const SERVICES_DROPDOWN_LIST: DropdownNavSection[] = [
  {
    title: "UI/UX Design",
    titleLink: `/${ROUTES.services}/${ServicesVariants.uiUxDesign}`,
    navItems: [],
  },
  {
    title: "Web Development",
    titleLink: `/${ROUTES.services}/${ServicesVariants.webDevelopment}`,
    navItems: [
      {
        title: "React JS Development",
        link: "",
      },
      {
        title: "Python Development",
        link: "",
      },
      {
        title: "Full Stack Development",
        link: "",
      },
      {
        title: "JavaScript Development",
        link: "",
      },
      {
        title: "Angular Development",
        link: "",
      },
    ],
  },
  {
    title: "Mobile development",
    titleLink: `/${ROUTES.services}/${ServicesVariants.mobileDevelopment}`,
    navItems: [
      {
        title: "iOS Development",
        link: "",
      },
      {
        title: "Android Development",
        link: "",
      },
      {
        title: "Cross-platform App Development",
        link: "",
      },
    ],
  },
  {
    title: "AI/ML Solutions",
    titleLink: `/${ROUTES.services}/${ServicesVariants.aiMlSolutions}`,
    navItems: [
      {
        title: "AI Consulting",
        link: `/${ROUTES.services}/${ServicesVariants.aiConsulting}`,
      },
      {
        title: "AI Software development",
        link: `/${ROUTES.services}/${ServicesVariants.aiSoftwareDevelopment}`,
      },
      {
        title: "AI Mobile App Development",
        link: `/${ROUTES.services}/${ServicesVariants.aiMobileDevelopment}`,
      },
      {
        title: "Data Science Services",
        link: `/${ROUTES.services}/${ServicesVariants.dataScience}`,
      },
      {
        title: "Machine Learning Consulting",
        link: `/${ROUTES.services}/${ServicesVariants.mlConsulting}`,
      },
      {
        title: "Machine Learning Development",
        link: `/${ROUTES.services}/${ServicesVariants.mlDevelopment}`,
      },
      {
        title: "Customer Experience Consulting",
        link: `/${ROUTES.services}/${ServicesVariants.customerExperienceConsulting}`,
      },
    ],
  },
  {
    title: "Blockchain",
    titleLink: `/${ROUTES.services}/${ServicesVariants.blockchain}`,
    navItems: [
      {
        title: "Blockchain Development Services",
        link: "",
      },
      {
        title: "Custom Blockchain Solutions",
        link: "",
      },
      {
        title: "Smart Contract Development",
        link: "",
      },
      {
        title: "DApp Development",
        link: "",
      },
      {
        title: "Blockchain for Financial Services",
        link: "",
      },
      {
        title: "Cryptocurrency Wallet Development and Exchange",
        link: "",
      },
    ],
  },
  {
    title: "Oracle Development",
    titleLink: "",
    navItems: [
      {
        title: "Oracle Database Development Services",
        link: "",
      },
      {
        title: "Oracle Cloud Solutions",
        link: "",
      },
      {
        title: "Custom Oracle Application Development",
        link: "",
      },
      {
        title: "Oracle Integration Services",
        link: "",
      },
      {
        title: "Oracle ERP Development & Customization",
        link: "",
      },
      {
        title: "Oracle Business Intelligence Consulting",
        link: "",
      },
      {
        title: "Oracle Managed Services",
        link: "",
      },
      {
        title: "Oracle Software Upgrade and Migration Solutions",
        link: "",
      },
    ],
  },
  {
    title: "Big data",
    titleLink: `/${ROUTES.services}/${ServicesVariants.bigData}`,
    navItems: [],
  },
  {
    title: "Cloud solutions",
    titleLink: `/${ROUTES.services}/${ServicesVariants.cloudSolutions}`,
    navItems: [],
  },
];

export const START_UP_AND_SCALING_LIST: DropdownNavSection[] = [
  {
    title: "Product design",
    titleLink: EXTERNAL_LINKS.idealogicDesign,
    isExternal: true,
    navItems: [],
  },
  {
    title: "MVP Development",
    titleLink: `/${ROUTES.services}/${ServicesVariants.mvpDevelopment}`,
    navItems: [],
  },
  {
    title: "Dedicated team",
    titleLink: `/${ROUTES.services}/${ServicesVariants.dedicatedTeam}`,
    navItems: [],
  },
  {
    title: "Technical consulting",
    titleLink: `/${ROUTES.services}/${ServicesVariants.technicalConsulting}`,
    navItems: [],
  },
  {
    title: "Maintenance & Support",
    titleLink: `/${ROUTES.services}/${ServicesVariants.maintenanceSupport}`,
    navItems: [],
  },
];
