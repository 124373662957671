// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 60 60" {...props} color="transparent">
      <path
        d="M30 58.1249C29.85 58.1249 29.6813 58.0874 29.55 58.0124L25.8 55.9312C25.5 55.7624 25.3125 55.4437 25.3125 55.1062V50.5124C25.3125 50.1749 25.5 49.8562 25.8 49.6874L29.55 47.6062C29.8313 47.4562 30.1687 47.4562 30.4688 47.6062L34.2188 49.6874C34.5188 49.8562 34.7063 50.1749 34.7063 50.5124V55.1062C34.7063 55.4437 34.5188 55.7624 34.2188 55.9312L30.4688 58.0124C30.3187 58.0874 30.15 58.1249 30 58.1249ZM27.1875 54.5624L30 56.1187L32.8125 54.5624V51.0749L30 49.4999L27.1875 51.0562V54.5624Z"
        fill={theme.colors[color]}
      />
      <path
        d="M49.6875 47.0251C49.5375 47.0251 49.3688 46.9876 49.2375 46.9126L45.4875 44.8313C45.1875 44.6626 45 44.3438 45 44.0063V39.4313C45 39.0938 45.1875 38.7751 45.4875 38.6063L49.2375 36.5251C49.5188 36.3751 49.8562 36.3751 50.1562 36.5251L53.9062 38.6063C54.1875 38.7751 54.375 39.0938 54.375 39.4313V44.0063C54.375 44.3438 54.1875 44.6626 53.8875 44.8313L50.1375 46.9126C50.0062 46.9876 49.8375 47.0251 49.6875 47.0251ZM46.875 43.4626L49.6875 45.0188L52.5 43.4626V39.9938L49.6875 38.4376L46.875 39.9751V43.4626Z"
        fill={theme.colors[color]}
      />
      <path
        d="M10.3125 47.0251C10.1625 47.0251 9.99375 46.9876 9.8625 46.9126L6.1125 44.8313C5.8125 44.6626 5.625 44.3438 5.625 44.0063V39.4313C5.625 39.0938 5.8125 38.7751 6.1125 38.6063L9.8625 36.5251C10.1438 36.3751 10.4812 36.3751 10.7812 36.5251L14.5312 38.6063C14.8125 38.7751 15 39.0938 15 39.4313V44.0063C15 44.3438 14.8125 44.6626 14.5125 44.8313L10.7625 46.9126C10.6312 46.9876 10.4625 47.0251 10.3125 47.0251ZM7.5 43.4626L10.3125 45.0188L13.125 43.4626V39.9938L10.3125 38.4376L7.5 39.9751V43.4626Z"
        fill={theme.colors[color]}
      />
      <path
        d="M30 12.5063C29.85 12.5063 29.6813 12.4688 29.55 12.3938L25.8 10.3125C25.5 10.1438 25.3125 9.825 25.3125 9.4875V4.89375C25.3125 4.55625 25.5 4.2375 25.8 4.06875L29.55 1.9875C29.8313 1.8375 30.1687 1.8375 30.4688 1.9875L34.2188 4.06875C34.5188 4.2375 34.7063 4.55625 34.7063 4.89375V9.4875C34.7063 9.825 34.5188 10.1438 34.2188 10.3125L30.4688 12.3938C30.3187 12.4688 30.15 12.5063 30 12.5063ZM27.1875 8.94375L30 10.5L32.8125 8.94375V5.4375L30 3.88125L27.1875 5.4375V8.94375Z"
        fill={theme.colors[color]}
      />
      <path
        d="M49.6875 22.6501C49.5375 22.6501 49.3688 22.6126 49.2375 22.5376L45.4875 20.4563C45.1875 20.2876 45 19.9688 45 19.6313V15.0563C45 14.7188 45.1875 14.4001 45.4875 14.2313L49.2375 12.1501C49.5188 12.0001 49.8562 12.0001 50.1562 12.1501L53.9062 14.2313C54.1875 14.4001 54.375 14.7188 54.375 15.0563V19.6313C54.375 19.9688 54.1875 20.2876 53.8875 20.4563L50.1375 22.5376C50.0062 22.6126 49.8375 22.6501 49.6875 22.6501ZM46.875 19.0876L49.6875 20.6438L52.5 19.0876V15.6001L49.6875 14.0438L46.875 15.6001V19.0876Z"
        fill={theme.colors[color]}
      />
      <path
        d="M10.3125 22.6501C10.1625 22.6501 9.99375 22.6126 9.8625 22.5376L6.1125 20.4563C5.8125 20.2876 5.625 19.9688 5.625 19.6313V15.0563C5.625 14.7188 5.8125 14.4001 6.1125 14.2313L9.8625 12.1501C10.1438 12.0001 10.4812 12.0001 10.7812 12.1501L14.5312 14.2313C14.8125 14.4001 15 14.7188 15 15.0563V19.6313C15 19.9688 14.8125 20.2876 14.5125 20.4563L10.7625 22.5376C10.6312 22.6126 10.4625 22.6501 10.3125 22.6501ZM7.5 19.0876L10.3125 20.6438L13.125 19.0876V15.6001L10.3125 14.0438L7.5 15.6001V19.0876Z"
        fill={theme.colors[color]}
      />
      <path
        d="M49.6875 38.2874C49.1625 38.2874 48.75 37.8749 48.75 37.3499V21.7124C48.75 21.1874 49.1625 20.7749 49.6875 20.7749C50.2125 20.7749 50.625 21.1874 50.625 21.7124V37.3499C50.625 37.8749 50.2125 38.2874 49.6875 38.2874Z"
        fill={theme.colors[color]}
      />
      <path
        d="M33.7536 51.8247C33.4161 51.8247 33.0974 51.656 32.9286 51.3372C32.6849 50.8872 32.8349 50.306 33.2849 50.0622L45.5661 43.2372C46.0161 42.9935 46.5974 43.1435 46.8411 43.5935C47.0849 44.0435 46.9349 44.6247 46.4849 44.8685L34.2036 51.6935C34.0724 51.7872 33.9036 51.8247 33.7536 51.8247Z"
        fill={theme.colors[color]}
      />
      <path
        d="M26.251 51.825C26.101 51.825 25.9323 51.7875 25.801 51.7125L13.5198 44.8875C13.0698 44.6438 12.901 44.0625 13.1635 43.6125C13.4073 43.1625 13.9885 42.9938 14.4385 43.2563L26.7198 50.0813C27.1698 50.325 27.3385 50.9063 27.076 51.3563C26.9073 51.6563 26.5885 51.825 26.251 51.825Z"
        fill={theme.colors[color]}
      />
      <path
        d="M10.3125 38.2874C9.7875 38.2874 9.375 37.8749 9.375 37.3499V21.7124C9.375 21.1874 9.7875 20.7749 10.3125 20.7749C10.8375 20.7749 11.25 21.1874 11.25 21.7124V37.3499C11.25 37.8749 10.8375 38.2874 10.3125 38.2874Z"
        fill={theme.colors[color]}
      />
      <path
        d="M14.0617 16.8186C13.7242 16.8186 13.4054 16.6498 13.2367 16.3311C12.9929 15.8811 13.1617 15.3186 13.6117 15.0748L25.7992 8.30608C26.2492 8.04358 26.8117 8.21233 27.0742 8.66233C27.3179 9.11233 27.1679 9.69358 26.7179 9.93733L14.5304 16.7061C14.3804 16.7811 14.2117 16.8186 14.0617 16.8186Z"
        fill={theme.colors[color]}
      />
      <path
        d="M45.9385 16.8187C45.7885 16.8187 45.6198 16.7812 45.4885 16.7062L33.301 9.93746C32.851 9.69371 32.6823 9.11246 32.9448 8.66246C33.1885 8.21246 33.751 8.04371 34.201 8.28746L46.3885 15.0562C46.8385 15.3 47.0073 15.8812 46.7448 16.3312C46.5948 16.65 46.276 16.8187 45.9385 16.8187Z"
        fill={theme.colors[color]}
      />
      <path
        d="M38.4411 24.9373C38.2911 24.9373 38.1224 24.8998 37.9911 24.8248L30.0036 20.3811L22.0161 24.8248C21.5661 25.0686 20.9849 24.9186 20.7411 24.4686C20.4974 24.0186 20.6474 23.4373 21.0974 23.1936L29.5349 18.5061C29.8161 18.3561 30.1536 18.3561 30.4536 18.5061L38.8911 23.1936C39.3411 23.4373 39.5099 24.0186 39.2474 24.4686C39.0974 24.7686 38.7786 24.9373 38.4411 24.9373Z"
        fill={theme.colors[color]}
      />
      <path
        d="M30.0036 39.9375C29.6661 39.9375 29.3474 39.7688 29.1786 39.45C28.9349 39 29.0849 38.4188 29.5349 38.175L37.5036 33.7688V25.5937L30.4536 29.5125C30.0036 29.7562 29.4224 29.6062 29.1786 29.1562C28.9349 28.7062 29.0849 28.125 29.5349 27.8812L37.9724 23.1937C38.2536 23.025 38.6099 23.0437 38.9099 23.2125C39.2099 23.3812 39.3786 23.6625 39.3786 24V34.3125C39.3786 34.65 39.1911 34.9687 38.8911 35.1375L30.4536 39.825C30.3224 39.9 30.1536 39.9375 30.0036 39.9375Z"
        fill={theme.colors[color]}
      />
      <path
        d="M30 39.9377C29.85 39.9377 29.6813 39.9002 29.55 39.8252L21.1125 35.1377C20.8125 34.969 20.625 34.6502 20.625 34.3127V24.0002C20.625 23.6627 20.7938 23.3627 21.0938 23.194C21.375 23.0252 21.7313 23.0252 22.0312 23.1752L30.4688 27.8627C30.7688 28.0315 30.9563 28.3502 30.9563 28.6877V39.0002C30.9563 39.3377 30.7875 39.6377 30.4875 39.8064C30.3375 39.9002 30.1687 39.9377 30 39.9377ZM22.5 33.769L29.0625 37.4065V29.2315L22.5 25.594V33.769Z"
        fill={theme.colors[color]}
      />
      <path
        d="M30 49.0688C29.475 49.0688 29.0625 48.6562 29.0625 48.1313V42.1875C29.0625 41.6625 29.475 41.25 30 41.25C30.525 41.25 30.9375 41.6625 30.9375 42.1875V48.1313C30.9375 48.6375 30.525 49.0688 30 49.0688Z"
        fill={theme.colors[color]}
      />
      <path
        d="M41.2478 23.4939C40.929 23.4939 40.629 23.3439 40.4603 23.0626C40.179 22.6314 40.3103 22.0501 40.7603 21.7689L45.3728 18.8626C45.804 18.5814 46.3853 18.7126 46.6665 19.1626C46.9478 19.5939 46.8165 20.1751 46.3665 20.4564L41.754 23.3626C41.5853 23.4376 41.4165 23.4939 41.2478 23.4939Z"
        fill={theme.colors[color]}
      />
      <path
        d="M18.6787 23.4939C18.5099 23.4939 18.3412 23.4377 18.1724 23.3439L13.5599 20.4377C13.1287 20.1564 12.9974 19.5752 13.2599 19.1439C13.5412 18.7127 14.1224 18.5814 14.5537 18.8439L19.1662 21.7502C19.5974 22.0314 19.7287 22.6127 19.4662 23.0439C19.2974 23.3439 18.9974 23.4939 18.6787 23.4939Z"
        fill={theme.colors[color]}
      />
      <path
        d="M45.8662 40.3689C45.6974 40.3689 45.5287 40.3127 45.3599 40.2189L40.7474 37.3127C40.3162 37.0314 40.1849 36.4502 40.4474 36.0189C40.7287 35.5877 41.3099 35.4564 41.7412 35.7189L46.3537 38.6252C46.7849 38.9064 46.9162 39.4877 46.6537 39.9189C46.4849 40.2189 46.1849 40.3689 45.8662 40.3689Z"
        fill={theme.colors[color]}
      />
      <path
        d="M14.0611 40.3691C13.7424 40.3691 13.4424 40.2191 13.2736 39.9378C12.9924 39.4878 13.1236 38.9066 13.5549 38.6253L18.1674 35.7191C18.5986 35.4378 19.1799 35.5691 19.4611 36.0191C19.7424 36.4503 19.6111 37.0316 19.1611 37.3128L14.5486 40.2191C14.3986 40.3128 14.2299 40.3691 14.0611 40.3691Z"
        fill={theme.colors[color]}
      />
      <path
        d="M30 16.875C29.475 16.875 29.0625 16.4625 29.0625 15.9375V12.1875C29.0625 11.6625 29.475 11.25 30 11.25C30.525 11.25 30.9375 11.6625 30.9375 12.1875V15.9375C30.9375 16.4625 30.525 16.875 30 16.875Z"
        fill={theme.colors[color]}
      />
    </Svg>
  );
};

export default Icon;
