// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 40 40" {...props} color="transparent">
      <rect width="40" height="40" rx="20" fill={theme.colors.bgSecond} />
      <path
        d="M18.5775 17.6267C18.5775 17.6267 17.6891 17.5383 17.6891 18.3299V21.8461H23.5558V22.9013C23.5558 22.9013 23.5733 23.4286 23.0225 23.4286H17.6891V28H24.0891C24.0891 28 27.9642 27.4019 28 23.4286V17.6267H18.5775ZM27.8217 12H16.2667C12.0884 12.9844 12 16.413 12 16.413V28H16.445V17.4507C16.445 16.5539 17.3333 16.3962 17.3333 16.3962H27.8225L27.8217 12Z"
        fill={theme.colors[color]}
      />
    </Svg>
  );
};

export default Icon;
