// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 28 28" width="28" height="28" {...props} color="transparent">
      <path
        d="M8.47059 24.5914C9.06266 23.9837 9.39155 23.1673 9.38606 22.3189C9.38056 21.4705 9.04111 20.6584 8.44121 20.0584C7.84131 19.4585 7.02924 19.1189 6.18083 19.1134C5.33241 19.1078 4.51595 19.4366 3.9082 20.0286C3.01045 20.9263 2.65718 22.3179 2.54496 23.5747C2.4812 24.2874 2.48526 25.0044 2.55708 25.7163C2.57638 25.8959 2.74786 25.9349 2.77748 25.9408H2.78601C2.85828 25.9434 3.88935 25.9636 4.20715 24.9595C4.26385 24.7719 4.34763 24.5936 4.45583 24.4303C4.66366 24.1237 5.14261 24.3082 5.08965 24.6749C5.03809 25.0572 5.11718 25.4457 5.31409 25.7774C5.33589 25.8141 5.36818 25.8435 5.40682 25.8617C5.44546 25.88 5.48867 25.8862 5.53089 25.8797C6.60865 25.7172 7.71423 25.3478 8.47059 24.5914Z"
        fill={theme.colors[color]}
      />
      <path
        d="M18.925 20.9303V18.1495C19.2584 17.8631 19.5844 17.5611 19.9031 17.2436L20.7865 16.3616C24.092 13.0543 25.7057 8.81598 25.4791 5.0086C25.4122 3.88865 24.6118 3.08786 23.4914 3.02098C19.6863 2.79429 15.4449 4.40801 12.1384 7.71488L11.2564 8.59693C10.9386 8.91473 10.6366 9.24091 10.3506 9.57548H7.56976C6.97949 9.59792 6.45745 9.93862 6.16209 10.4382L4.52728 13.7087C4.48423 13.8262 4.45413 13.9479 4.4375 14.0719C4.44006 14.2819 4.52464 14.4826 4.67317 14.6312C4.8217 14.7797 5.02242 14.8643 5.23246 14.8668H7.2973C7.02797 15.7691 7.20752 16.6561 7.78118 17.2288L11.2703 20.7184C11.8431 21.2916 12.7301 21.4703 13.6323 21.2023V23.2671C13.6349 23.4772 13.7194 23.6779 13.868 23.8264C14.0165 23.9749 14.2172 24.0595 14.4273 24.0621C14.5512 24.0455 14.673 24.0154 14.7904 23.9723L18.06 22.3375C18.5614 22.043 18.9012 21.5192 18.925 20.9303ZM17.007 11.4931C16.5458 11.0319 16.2318 10.4444 16.1045 9.80473C15.9773 9.16509 16.0426 8.50209 16.2922 7.89957C16.5418 7.29704 16.9644 6.78206 17.5067 6.41973C18.049 6.05741 18.6865 5.86402 19.3386 5.86402C19.9908 5.86402 20.6283 6.05741 21.1706 6.41973C21.7129 6.78206 22.1355 7.29704 22.3851 7.89957C22.6347 8.50209 22.7 9.16509 22.5727 9.80473C22.4455 10.4444 22.1315 11.0319 21.6703 11.4931C21.3641 11.7993 21.0006 12.0422 20.6006 12.2079C20.2005 12.3736 19.7717 12.4589 19.3386 12.4589C18.9056 12.4589 18.4768 12.3736 18.0767 12.2079C17.6767 12.0422 17.3132 11.7993 17.007 11.4931Z"
        fill={theme.colors[color]}
      />
    </Svg>
  );
};

export default Icon;
