import { useThemeContext } from "context";
// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 189 23" {...props} color={color}>
      <g clipPath="url(#clip0_7484_109924)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.14593 0.5H20.4491C21.5126 0.5 22.3522 1.18041 22.2962 2.03093V20.9691C22.2962 21.8196 21.4566 22.5 20.4491 22.5H2.14593C1.13842 22.5 0.298828 21.8196 0.298828 20.9691V2.03093C0.298828 1.18041 1.13842 0.5 2.14593 0.5ZM13.4453 3.66873C16.6814 3.96013 18.2635 5.99991 18.2635 5.99991C22.4169 11.3427 19.0155 16.0732 18.4758 16.8238C18.4268 16.8919 18.4013 16.9273 18.4073 16.9273C17.9039 17.7286 16.9691 18.3114 16.178 18.6757C14.8116 19.2585 13.3734 19.2585 13.3734 19.2585H10.4249V15.616L13.8049 15.5431C15.1712 15.3975 15.8184 14.159 15.8184 14.159C17.1848 11.245 15.8184 8.76817 15.8184 8.76817C15.1712 7.45689 13.4453 7.23834 13.4453 7.23834H9.20238V19.3313H5.1033L5.17521 7.31119H2.29866V3.66873H13.4453Z"
          fill={theme.colors[color]}
        />
        <path
          d="M30.2988 1.70508H42.4127V3.74428H37.3267V18.6676H35.1998V3.74428H30.2988V1.70508Z"
          fill={theme.colors[color]}
        />
        <path
          d="M46.1079 6.24683C50.3616 6.24683 51.6562 7.82258 51.6562 12.7352C51.6562 17.2771 50.3616 18.8528 46.1079 18.8528C41.8542 18.8528 40.5596 17.1844 40.5596 12.7352C40.5596 7.63719 41.9467 6.24683 46.1079 6.24683ZM46.1079 16.8136C48.4197 16.8136 49.5294 16.5355 49.5294 12.6425C49.5294 8.47141 48.6971 8.19334 46.1079 8.19334C43.7036 8.19334 42.6864 8.5641 42.6864 12.6425C42.6864 16.5355 43.7961 16.8136 46.1079 16.8136Z"
          fill={theme.colors[color]}
        />
        <path
          d="M54.2468 6.33944H55.9113L56.3736 7.63711C57.0209 7.17366 59.2403 6.15405 60.7198 6.15405C64.0488 6.15405 64.7886 9.30555 64.7886 12.3644C64.7886 15.6086 64.2338 18.76 60.7198 18.76C59.7026 18.76 57.7607 18.482 56.2812 17.8331V24.785H54.1543V6.33944H54.2468ZM60.5349 16.9062C61.922 16.9062 62.6617 16.072 62.6617 12.4571C62.6617 9.49093 61.922 8.19326 60.5349 8.19326C58.8704 8.19326 57.4833 8.65671 56.3736 9.21286V16.3501C57.7607 16.7208 58.8704 16.9062 60.5349 16.9062Z"
          fill={theme.colors[color]}
        />
        <path
          d="M67.9336 1.70508H74.5916C79.77 1.70508 80.6947 6.61771 80.6947 10.2327C80.6947 14.4038 79.0302 18.6676 74.5916 18.6676H67.9336V1.70508ZM74.4991 16.5357C77.3657 16.5357 78.4754 13.6622 78.4754 10.14C78.4754 5.32003 76.9959 3.65159 74.4991 3.65159H70.0604V16.5357H74.4991Z"
          fill={theme.colors[color]}
        />
        <path
          d="M87.9073 6.24683C91.0513 6.24683 92.8083 6.5249 92.8083 10.6033C92.8083 12.4571 91.7911 13.4767 89.4793 13.4767H84.6707C84.6707 16.1648 85.318 16.7209 87.075 16.7209C89.2019 16.7209 90.8664 16.7209 92.6233 16.4429V18.0186C90.7739 18.5747 88.5546 18.6674 86.6127 18.6674C83.4686 18.6674 82.5439 17.0917 82.5439 12.3644C82.4514 6.98836 84.0234 6.24683 87.9073 6.24683ZM89.2943 11.7156C90.2191 11.7156 90.6814 11.3448 90.6814 10.6033C90.6814 8.37872 90.3115 8.19334 87.9997 8.19334C85.5955 8.19334 84.6707 8.19334 84.6707 11.7156H89.2943Z"
          fill={theme.colors[color]}
        />
        <path
          d="M96.5071 6.3396L100.114 15.7941L103.72 6.3396H106.032L101.131 18.6675H98.9114L94.1953 6.3396H96.5071Z"
          fill={theme.colors[color]}
        />
        <path
          d="M112.78 6.24683C115.924 6.24683 117.681 6.5249 117.681 10.6033C117.681 12.4571 116.664 13.4767 114.352 13.4767H109.451C109.451 16.1648 110.098 16.7209 111.855 16.7209C113.982 16.7209 115.647 16.7209 117.404 16.4429V18.0186C115.554 18.5747 113.335 18.6674 111.393 18.6674C108.249 18.6674 107.324 17.0917 107.324 12.3644C107.324 6.98836 108.896 6.24683 112.78 6.24683ZM114.167 11.7156C115.092 11.7156 115.554 11.3448 115.554 10.6033C115.554 8.37872 115.184 8.19334 112.873 8.19334C110.468 8.19334 109.544 8.19334 109.544 11.7156H114.167Z"
          fill={theme.colors[color]}
        />
        <path
          d="M120.088 0.5H122.215V14.1256C122.215 16.0721 122.77 16.5356 123.509 16.721C123.509 16.721 125.266 17.1844 125.451 17.1844V18.6675H123.232C121.382 18.6675 120.088 17.5552 120.088 14.311V0.5Z"
          fill={theme.colors[color]}
        />
        <path
          d="M131.83 6.24683C136.083 6.24683 137.378 7.82258 137.378 12.7352C137.378 17.2771 136.083 18.8528 131.83 18.8528C127.576 18.8528 126.281 17.1844 126.281 12.7352C126.281 7.63719 127.761 6.24683 131.83 6.24683ZM131.83 16.8136C134.141 16.8136 135.251 16.5355 135.251 12.6425C135.251 8.47141 134.419 8.19334 131.83 8.19334C129.425 8.19334 128.408 8.5641 128.408 12.6425C128.501 16.5355 129.61 16.8136 131.83 16.8136Z"
          fill={theme.colors[color]}
        />
        <path
          d="M140.061 6.33944H141.726L142.188 7.63711C142.835 7.17366 145.055 6.15405 146.534 6.15405C149.863 6.15405 150.603 9.30555 150.603 12.3644C150.603 15.6086 150.048 18.76 146.534 18.76C145.517 18.76 143.575 18.482 142.096 17.8331V24.785H139.969V6.33944H140.061ZM146.257 16.9062C147.644 16.9062 148.384 16.072 148.384 12.4571C148.384 9.49093 147.644 8.19326 146.257 8.19326C144.592 8.19326 143.205 8.65671 142.096 9.21286V16.3501C143.575 16.7208 144.685 16.9062 146.257 16.9062Z"
          fill={theme.colors[color]}
        />
        <path
          d="M158.278 6.24683C161.422 6.24683 163.179 6.5249 163.179 10.6033C163.179 12.4571 162.162 13.4767 159.85 13.4767H154.949C154.949 16.1648 155.596 16.7209 157.353 16.7209C159.48 16.7209 161.145 16.7209 162.902 16.4429V18.0186C161.052 18.5747 158.833 18.6674 156.891 18.6674C153.747 18.6674 152.822 17.0917 152.822 12.3644C152.822 6.98836 154.394 6.24683 158.278 6.24683ZM159.665 11.7156C160.59 11.7156 161.052 11.3448 161.052 10.6033C161.052 8.37872 160.682 8.19334 158.371 8.19334C155.966 8.19334 155.042 8.19334 155.042 11.7156H159.665Z"
          fill={theme.colors[color]}
        />
        <path
          d="M165.585 6.33952H167.249L167.804 7.91527C169.099 7.08105 170.301 6.24683 172.15 6.24683C172.798 6.24683 173.538 6.24683 173.815 6.43221V8.56411C173.538 8.47141 172.705 8.47141 172.058 8.47141C170.301 8.47141 169.376 8.84218 167.804 9.86178V18.8528H165.677V6.33952H165.585Z"
          fill={theme.colors[color]}
        />
        <path
          d="M175.292 9.39833C175.292 7.45181 176.124 6.24683 178.529 6.24683C180.101 6.24683 183.614 6.43221 184.817 6.71028V8.19334H178.806C177.511 8.19334 177.419 8.6568 177.419 9.39833V10.3252C177.419 11.4375 177.974 11.5302 178.806 11.5302H182.32C184.447 11.5302 185.464 12.8279 185.464 14.589V15.794C185.464 18.2967 183.892 18.8528 182.412 18.8528C180.933 18.8528 176.679 18.5748 175.847 18.3894V16.9063H182.135C182.227 16.9063 183.337 16.999 183.337 15.794V14.7744C183.337 14.0329 183.06 13.6621 182.135 13.6621H178.621C176.402 13.6621 175.292 12.9206 175.292 10.5106V9.39833Z"
          fill={theme.colors[color]}
        />
      </g>
      <defs>
        <clipPath id="clip0_7484_109924">
          <rect width="189" height="22" fill={theme.colors.white} transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
