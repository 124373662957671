import { SpringOptions, useMotionValue, useSpring, useTransform } from "framer-motion";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Theme
import { transitions } from "theme/base";

const useMagneticCursor = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  customDistance?: number[],
  transition?: SpringOptions,
) => {
  const { isLaptopL } = useMatchBreakpoints();
  const refRect = ref.current?.getBoundingClientRect();

  const selectedTransition = transition ?? transitions.defaultParallaxMotion;
  const initialRectValue = isLaptopL ? 350 : 280;

  const mouseX = useMotionValue((refRect?.width || initialRectValue) / 2);
  const mouseY = useMotionValue((refRect?.height || initialRectValue) / 2);

  const distance = customDistance || [-60, 60];
  const labelDistance = isLaptopL ? [10, -10] : [6, -6];

  const transformX = useTransform(mouseX, [0, refRect?.width || initialRectValue], distance);
  const transformY = useTransform(mouseY, [0, refRect?.height || initialRectValue], distance);
  const labelTransformX = useTransform(mouseX, [0, refRect?.width || initialRectValue], labelDistance);
  const labelTransformY = useTransform(mouseY, [0, refRect?.height || initialRectValue], labelDistance);

  const x = useSpring(transformX, selectedTransition);
  const y = useSpring(transformY, selectedTransition);
  const labelX = useSpring(labelTransformX, selectedTransition);
  const labelY = useSpring(labelTransformY, selectedTransition);

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (ref && ref.current) {
      const rect = ref.current.getBoundingClientRect();

      mouseX.set(event.clientX - rect.left);
      mouseY.set(event.clientY - rect.top);
    }
  };

  const resetPosition = () => {
    mouseX.set((refRect?.width || initialRectValue) / 2);
    mouseY.set((refRect?.height || initialRectValue) / 2);
  };

  return { x, y, labelX, labelY, handleMouseMove, resetPosition };
};

export default useMagneticCursor;
