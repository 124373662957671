// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 29 28" width="29" height="28" {...props} fill="none" color="transparent">
      <path
        d="M20.3341 25.6663H8.66748C8.18915 25.6663 7.79248 25.2696 7.79248 24.7913C7.79248 24.3129 8.18915 23.9163 8.66748 23.9163H20.3341C20.8125 23.9163 21.2091 24.3129 21.2091 24.7913C21.2091 25.2696 20.8125 25.6663 20.3341 25.6663Z"
        fill={theme.colors[color]}
      />
      <path
        d="M24.2429 6.43964L19.5762 9.77631C18.9579 10.2196 18.0712 9.95131 17.8029 9.23964L15.5979 3.35964C15.2246 2.34464 13.7896 2.34464 13.4162 3.35964L11.1996 9.22797C10.9312 9.95131 10.0562 10.2196 9.4379 9.76464L4.77123 6.42797C3.8379 5.77464 2.60123 6.69631 2.98623 7.78131L7.83957 21.373C8.0029 21.8396 8.44623 22.143 8.93623 22.143H20.0546C20.5446 22.143 20.9879 21.828 21.1512 21.373L26.0046 7.78131C26.4012 6.69631 25.1646 5.77464 24.2429 6.43964ZM17.4179 17.208H11.5846C11.1062 17.208 10.7096 16.8113 10.7096 16.333C10.7096 15.8546 11.1062 15.458 11.5846 15.458H17.4179C17.8962 15.458 18.2929 15.8546 18.2929 16.333C18.2929 16.8113 17.8962 17.208 17.4179 17.208Z"
        fill={theme.colors[color]}
      />
    </Svg>
  );
};

export default Icon;
