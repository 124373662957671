// Styling
import { StyledBurgerMenu } from "./styled";
// Types
import { BurgerMenuProps } from "./types";

export const BurgerMenu: React.FC<BurgerMenuProps> = ({ color, isOpen, setIsOpen }) => {
  const handlerClickBurger = () => {
    setIsOpen(!isOpen);
  };

  return <StyledBurgerMenu onClick={handlerClickBurger} isOpen={isOpen} color={color} />;
};
