// Types
import { TechStackTabItem } from "./types";

export const TECH_STACK_TABS_LIST: TechStackTabItem[] = [
  {
    title: "Front-end Frameworks and Libraries",
    techList: [
      { iconSrc: "/images/tech-stack-angular-logo.svg", label: "Angular" },
      { iconSrc: "/images/tech-stack-react-logo.svg", label: "React.js" },
    ],
  },
  {
    title: "Back-end Frameworks and Libraries",
    techList: [
      { iconSrc: "/images/tech-stack-nest-js-logo.svg", label: "Nest.js (Node.js)" },
      { iconSrc: "/images/tech-stack-koa-logo.svg", label: "Koa (Node.js)" },
      { iconSrc: "/images/tech-stack-fast-api-logo.svg", label: "Fast API (Python)" },
      { iconSrc: "/images/tech-stack-django-logo.svg", label: "Django (Python)" },
    ],
  },
  {
    title: "Mobile Development",
    techList: [
      { iconSrc: "/images/tech-stack-react-logo.svg", label: "ReactNative" },
      { iconSrc: "/images/tech-stack-cordova-logo.svg", label: "Cordova" },
    ],
  },
  {
    title: "Databases",
    techList: [
      { iconSrc: "/images/tech-stack-mongo-db-logo.svg", label: "MongoDB" },
      { iconSrc: "/images/tech-stack-postgre-sql-logo.svg", label: "PostgreSQL" },
      { iconSrc: "/images/tech-stack-mysql-logo.svg", label: "MySQL" },
      { iconSrc: "/images/tech-stack-oracle-logo.svg", label: "Oracle" },
      { iconSrc: "/images/tech-stack-cassandra-logo.svg", label: "Cassandra" },
    ],
  },
  {
    title: "Cloud Computing Services",
    techList: [
      { iconSrc: "/images/tech-stack-aws-logo.svg", label: "AWS" },
      { iconSrc: "/images/tech-stack-azure-logo.svg", label: "Azure" },
      { iconSrc: "/images/tech-stack-google-cloud-platform-logo.svg", label: "Google Cloud Platform" },
      { iconSrc: "/images/tech-stack-ibm-cloud-logo.svg", label: "IBM Cloud" },
      { iconSrc: "/images/tech-stack-oracle-cloud-logo.svg", label: "Oracle Cloud" },
    ],
  },
  {
    title: "DevOps and CI/CD",
    techList: [
      { iconSrc: "/images/tech-stack-docker-logo.svg", label: "Docker" },
      { iconSrc: "/images/tech-stack-kubernetes-logo.svg", label: "Kubernetes" },
      { iconSrc: "/images/tech-stack-jenkins-logo.svg", label: "Jenkins" },
      { iconSrc: "/images/tech-stack-gitlab-ci-cd-logo.svg", label: "GitLab CI/CD" },
      { iconSrc: "/images/tech-stack-travis-ci-logo.svg", label: "Travis CI" },
    ],
  },
];
