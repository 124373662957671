import { useNavigate } from "react-router-dom";
// Components + Styling
import { Heading } from "components";
import { StaticFeedbackBlock } from "./components";
import PortfolioItemLabel from "../portfolio-item-label";
import PortfolioTestimonialLabel from "../portfolio-testimonial-label";
import {
  StyledPortfolioOverlay,
  StyledPortfolioItemLogo,
  StyledPortfolioItemImage,
  StyledPortfolioItemRelative,
  StyledPortfolioImageWrapper,
  StyledPortfolioMagneticMotion,
  StyledPortfolioComingSoonText,
  StyledPortfolioMagneticWrapper,
  StyledPortfolioTestimonialLabel,
  StyledPortfolioItemImageWrapper,
  StyledPortfolioImageOuterWrapper,
} from "./styled";
// Animations
import { slideTestimonialLabelVariants } from "./animations";
// Hooks
import { useMagneticCursor, useMatchBreakpoints } from "hooks";
// Context
import { useTranslation } from "context";
// Types
import { PortfolioItemProps } from "./types";
import { ROUTES } from "navigation/routes";

const PortfolioItem: React.FC<PortfolioItemProps> = ({
  logo,
  slug,
  title,
  shortTitle,
  subtitle,
  videoInfo,
  isComingSoon,
  portfolioImage,
  redirectedLink,
  handleClickTestimonialGif,
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { isDesktop, isMobile, isLaptopL } = useMatchBreakpoints();

  const [isClicked, setIsClicked] = React.useState(false);
  const [isImageHovered, setIsImageHovered] = React.useState(false);

  const imageRef = React.useRef<HTMLDivElement | null>(null);

  const magneticDistance = isLaptopL ? [-30, 30] : [-8, 8];
  const { x, y, handleMouseMove, resetPosition } = useMagneticCursor(imageRef, magneticDistance);

  const handleClick = () => {
    if (redirectedLink) {
      navigate(`/${ROUTES.caseStudies}/${redirectedLink}`);
    } else {
      if (!isComingSoon) {
        navigate(`/${ROUTES.caseStudies}/${slug}`);
      } else {
        setIsClicked(!isClicked);
      }
    }
  };
  const { logoSrc, aspectRatio } = logo;

  return (
    <StyledPortfolioItemRelative>
      {isMobile && (
        <PortfolioItemLabel
          redirectedLink={redirectedLink}
          handleClick={handleClick}
          title={shortTitle ?? title}
          subtitle={subtitle}
          mobileTitle={shortTitle ?? title}
        />
      )}

      <StyledPortfolioMagneticWrapper
        ref={imageRef}
        onMouseEnter={() => setIsImageHovered(true)}
        onMouseLeave={() => {
          setIsImageHovered(false);
          resetPosition();
        }}
        onMouseMove={handleMouseMove}
      >
        <StyledPortfolioMagneticMotion style={{ x, y }}>
          <StyledPortfolioImageOuterWrapper>
            <StyledPortfolioImageWrapper onClick={handleClick}>
              {!(videoInfo && !isLaptopL) && (
                <StyledPortfolioItemRelative>
                  <StyledPortfolioItemLogo
                    width={`${(isDesktop ? 3.125 : 2.5) * aspectRatio}rem`} // 3.125 & 2.5 - its logo height in rem
                    src={logoSrc.url}
                    aspectRatio={aspectRatio}
                    hygraphImage={portfolioImage}
                  />
                </StyledPortfolioItemRelative>
              )}

              {videoInfo && videoInfo.videoSources.length > 0 && (
                <StyledPortfolioTestimonialLabel
                  initial="hidden"
                  animate={isImageHovered ? "visible" : "hidden"}
                  variants={slideTestimonialLabelVariants}
                >
                  <PortfolioTestimonialLabel
                    isHovered={isImageHovered}
                    onClickGif={() => handleClickTestimonialGif()}
                    {...videoInfo}
                  />
                </StyledPortfolioTestimonialLabel>
              )}

              {isClicked && !redirectedLink && (
                <StyledPortfolioOverlay onClick={handleClick}>
                  <Heading as="h1" scale="h1" color="white">
                    {isComingSoon && t("Coming soon")}
                  </Heading>
                </StyledPortfolioOverlay>
              )}

              <StyledPortfolioItemImageWrapper>
                <StyledPortfolioItemImage
                  cropToWidth={isMobile ? 392 : isLaptopL ? 688 : 720}
                  width="100%"
                  src={portfolioImage.url}
                  aspectRatio={portfolioImage.width / portfolioImage.height}
                  hygraphImage={portfolioImage}
                />
                {videoInfo && videoInfo.videoSources.length === 0 && (
                  <StaticFeedbackBlock
                    personName={videoInfo.name}
                    position={videoInfo.position}
                    imgUrl={videoInfo.gifSrc.url}
                    linkedInProfile={videoInfo.linkedInProfile!}
                  />
                )}
              </StyledPortfolioItemImageWrapper>
            </StyledPortfolioImageWrapper>
          </StyledPortfolioImageOuterWrapper>
        </StyledPortfolioMagneticMotion>
      </StyledPortfolioMagneticWrapper>

      {!isMobile && (
        <PortfolioItemLabel
          handleClick={handleClick}
          redirectedLink={redirectedLink}
          title={shortTitle ?? title}
          isComingSoon={isComingSoon}
          subtitle={subtitle}
          mobileTitle={shortTitle ?? title}
        />
      )}

      {isMobile && isComingSoon && !redirectedLink && (
        <StyledPortfolioComingSoonText color="white" mt="1rem" textScale="p16Regular">
          {t("Coming soon")}
        </StyledPortfolioComingSoonText>
      )}
    </StyledPortfolioItemRelative>
  );
};

export default PortfolioItem;
