// Components
import { Flex } from "components";
import { SpinnerIcon } from "components/svg";
// Types
import { FlexProps } from "components/layout/components/types";

const Loader: React.FC<FlexProps> = props => {
  return (
    <Flex justifyContent="center" alignItems="center" height="100vh" {...props}>
      <SpinnerIcon width="2.75rem" color="textAccent" />
    </Flex>
  );
};

export default Loader;
