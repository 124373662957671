import { blogArticleLinkedDataLayout } from "./constants";

import type { BlogArticleLinkedDataParams } from "./types";

export const defaultLinkedData = {
  "@context": "http://schema.org/",
  "@type": "CreativeWorkSeries",
  aggregateRating: {
    "@type": "AggregateRating",
    ratingValue: "5",
    ratingCount: "44",
  },
  description: "Idealogic custom software development company",
  image: "https://idealogic.dev/logo512.webp",
  name: "Custom Software Solutions for Startups and Scaling Companies | Idealogic",
  url: "https://idealogic.dev",
};

export const getBlogArticleLinkedData = (params: BlogArticleLinkedDataParams) => {
  const blogArticleLinkedData = {
    ...(blogArticleLinkedDataLayout || ""),
    headline: params.headline || "",
    image: params.image || "",
    datePublished: params.datePublished || "",
    description: params.description || "",
  };

  blogArticleLinkedData.author.name = params.author?.name || "";

  return blogArticleLinkedData;
};
