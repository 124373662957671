import styled, { css } from "styled-components";
import { space } from "styled-system";
// Components
import { Text } from "components/text";
import Link from "components/link";

import { linesMovingFromDifferentDirectionsAnimation } from "theme/animations";

const navItemGeneralStyles = css`
  background-clip: none;
  -webkit-background-clip: none;
  -webkit-text-fill-color: unset;
  padding: 0.75rem 0;
  cursor: pointer;

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 0;
  }
`;

export const StyledNavList = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: space-between;

  margin: 1rem 0;
  overflow-y: scroll;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: initial;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0;
    gap: 2rem;
    overflow-y: initial;
  }
`;

export const StyledNavItem = styled(Link)<{ isUnderline?: boolean; isHeaderThemeLight?: boolean }>`
  ${navItemGeneralStyles}
  /* Need to inherit bg, because Chrome change Contrast value for Case Studies menu item, so it look lighter, then other menu items */
  background: inherit;
  &::before {
    content: "";
    clear: both;
    width: 100%;
    height: 0.5px;
    position: absolute;
    top: 100%;
    left: 0;
    transition:
      clip-path 0.3s,
      transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
    clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%);
  }

  &:hover {
    &::before {
      background: ${({ theme, isHeaderThemeLight }) =>
        isHeaderThemeLight ? theme.colors.bgDark : theme.gradients.buttonAccentHover};
      clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 50% 100%, 0 100%, 100% 100%, 100% 0%);
      ${linesMovingFromDifferentDirectionsAnimation}
    }

    width: initial;
    background-image: ${({ theme }) => theme.gradients.blogShowMoreGradient};
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;

    padding: 0;

    transition: ${({ theme }) => theme.transitions.cubic_bezier_0_3s};
    transform: translate3d(0, 0, 0);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    &::before {
      content: "";
      clear: both;
      width: 100%;
      height: 0.5px;
      position: absolute;
      top: 100%;
      left: 0;
      transition:
        clip-path 0.3s,
        transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
      clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%);
    }

    &:hover {
      &::before {
        background: ${({ theme, isHeaderThemeLight }) =>
          isHeaderThemeLight ? theme.colors.bgDark : theme.gradients.buttonAccentHover};
        clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 50% 100%, 0 100%, 100% 100%, 100% 0%);
        ${linesMovingFromDifferentDirectionsAnimation}
      }

      width: initial;
      background-image: ${({ theme }) => theme.gradients.blogShowMoreGradient};
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;

      padding: 0;

      transition: ${({ theme }) => theme.transitions.cubic_bezier_0_3s};
      transform: translate3d(0, 0, 0);
    }
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    &::before {
      content: "";
      clear: both;
      width: 100%;
      height: 0.5px;
      position: absolute;
      top: 100%;
      left: 0;
      transition:
        clip-path 0.3s,
        transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
      clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%);
    }

    &:hover {
      &::before {
        background: ${({ theme, isHeaderThemeLight }) =>
          isHeaderThemeLight ? theme.colors.bgDark : theme.gradients.buttonAccentHover};
        clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 50% 100%, 0 100%, 100% 100%, 100% 0%);
        ${linesMovingFromDifferentDirectionsAnimation}
      }

      width: initial;
      background-image: ${({ theme }) => theme.gradients.blogShowMoreGradient};
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;

      padding: 0;

      transition: ${({ theme }) => theme.transitions.cubic_bezier_0_3s};
      transform: translate3d(0, 0, 0);
    }
  }

  ${space}
`;

export const StyledDropdownTitle = styled(Text)`
  ${navItemGeneralStyles}

  width: 100%;
  height: 1.875rem;

  padding: 0;

  align-content: center;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: initial;
    height: 4.875rem;
  }
`;
