import styled from "styled-components";
import { clearfix } from "styles";

export const StyledGifWrapper = styled.div<{ imageWidth: string }>`
  width: fit-content;
  position: relative;
  padding: ${({ imageWidth }) => `calc(${imageWidth} / 10)`};
  cursor: pointer;

  &::after {
    ${clearfix};
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    background-image: url("/images/play-icon.svg");
    background-repeat: no-repeat;
    background-size: cover;
    transform: translate(-50%, -50%);
    transition: ${({ theme }) => theme.transitions.opacity_easeInOut_0_5s};
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }
`;

export const StyledDurationIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;
