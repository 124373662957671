import { WavingHandIcon } from "components/svg";

import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { IndustryPageContent } from "../types";
import {
  CRYPTO_CURRENCY_INDUSTRY_BENEFITS_LIST,
  CRYPTO_CURRENCY_INDUSTRY_DEVELOPMENT_SOLUTIONS_LIST,
  CRYPTO_CURRENCY_INDUSTRY_WHO_WE_SERVE_LIST,
} from "../components/industry-info/constants";
import { EXTERNAL_LINKS } from "configs";

const CRYPTO_CURRENCY_INDUSTRIES_CONTENT: IndustryPageContent = {
  banner: {
    title: "Cryptocurrency Development Solutions",
    description:
      "Join the revolution in finance with Idealogic's cutting-edge cryptocurrency development services. Our tailored blockchain solutions and comprehensive crypto consulting services provide expert guidance to navigate the dynamic digital currency landscape.",
    imageSrc: "/images/industries/crypto-currency-development/crypto-currency-development-banner-bg.webp",
    imageRatio: 1.75,
    maxWidthForButtonRem: 23.8125,
    buttonLabel: "Get a Free Estimation",
  },
  whoWeServe: {
    title: "What We Offer",
    description: "Unlock a diverse range of blockchain and cryptocurrency solutions tailored to your needs:",
    whoWeServeListLayoutProps: {
      maxHeight: { _: "100%", laptop: "46.1875rem", laptopL: "34.1875rem" },
    },
    whoWeServeList: CRYPTO_CURRENCY_INDUSTRY_WHO_WE_SERVE_LIST,
  },
  bookACallWithExpert: {
    bgSrc: "/images/industries/crypto-currency-development/book-call-with-a-expert/bg.webp",
    bgMobileSrc: "/images/industries/crypto-currency-development/book-call-with-a-expert/bg.webp",
    title: {
      beforeKeyWord: "Why Choose Idealogic for Your",
      keyWord: "Cryptocurrency Development",
    },
    description: "",
    linkedin: {
      name: "Arsenii Ovsianykov",
      href: EXTERNAL_LINKS.linkedInArsenii,
    },
    jobTitle: "Head of R&D team",
    buttonText: "Talk To Our Experts",
    imageSrc:
      "/images/industries/crypto-currency-development/book-call-with-a-expert/book-a-call-with-expert-arsenyi-bg.webp",
    mobileImageSrc:
      "/images/industries/crypto-currency-development/book-call-with-a-expert/book-a-call-with-expert-arsenyi-bg.webp",
    isReverse: true,
    imageAspectRatio: 1.27,
    isShowLinkedInTitle: false,
  },
  stagesBlocks: [
    {
      title: "Exchange Platform Development",
      description:
        "Attract and retain users with our visually appealing and user-friendly exchange platforms, designed to enhance the trading experience.",
      iconSrc: "/images/industries/crypto-currency-development/white-label-solution/exchange.svg",
    },
    {
      title: "Cryptocurrency Wallet Development",
      description:
        "Optimize finance management with our cutting-edge wallet development solutions, leveraging the latest technological advancements.",
      iconSrc: "/images/industries/crypto-currency-development/white-label-solution/wallet.svg",
    },
    {
      title: "Hardware Wallet Solutions",
      description:
        "Ensure maximum security for your crypto assets with our robust hardware wallet solutions, offering unparalleled protection and management capabilities.",
      iconSrc: "/images/industries/crypto-currency-development/white-label-solution/hard-wallet.svg",
    },
  ],
  developmentSolution: {
    title: "Cryptocurrency & Altcoin development Solutions",
    description:
      "Explore the innovative blockchain solutions we offer to our partners, paving the way for a new era in financial technology. Dive into the possibilities of",
    whoWeServeListLayoutProps: {
      maxHeight: { _: "100%", laptop: "17.1875rem" },
    },
    isBorderRadius: false,
    whoWeServeList: CRYPTO_CURRENCY_INDUSTRY_DEVELOPMENT_SOLUTIONS_LIST,
    itemIcon: "/images/industries/crypto-currency-development/development-solution-icon.svg",
  },
  portfolio: {
    portfolioList: [
      CaseStudiesSlugEnums.glue,
      CaseStudiesSlugEnums.allCryptoMechanics,
      CaseStudiesSlugEnums.bitholder,
      CaseStudiesSlugEnums.everse,
    ],
    title: "Our cases",
    TitleIcon: WavingHandIcon,
    description:
      "Discover how our cryptocurrency platforms have made a significant impact on our clients and their users. Explore our case studies and witness the results firsthand.",
    actionButtonText: "See All Of Our Work",
  },
  benefits: {
    title: "Our cryptocurrency development process",
    description:
      "During our continued work on cryptocurrency projects, we’ve mastered an excellent step-by-step plan to complete projects of any complexity.",
    benefitsList: CRYPTO_CURRENCY_INDUSTRY_BENEFITS_LIST,
    isBackgroundImageNeed: false,
    isNeedCurveLine: true,
    buttonText: "Schedule a call",
    isWhite: false,
    isSubsidiary: true,
    isButtonPositionStart: true,
  },
  ourCustomerReview: {
    title: "Hear From Our Customers",
    description: "Explore feedback from startups and enterprises who have partnered with us.",
    bgImageSrc: "/images/industries/crypto-currency-development/our-customer-review/our-customer-review-bg.webp",
    topRightImageSrc: "/images/industries/fintech/our-customer-review/our-customer-review-top-right-image.webp",
    bgGradient: "customerReviewAlexGradient",
    isTextClamp: false,
    owner: {
      name: "Alexander Maslo",
      logo: "/images/industries/fintech/portfolio/acm-logo.svg",
      logoSize: "11rem",
      imageAspectRatio: 0.92,
      logoAspectRatio: 2.85,
      jobTitle: "CTO at ACM",
      image: "/images/industries/crypto-currency-development/our-customer-review/our-customer-review-alex-maslo.webp",
      reviewText:
        "“Idealogic delivered on-time updates on the app's progress weekly and provided prompt solutions for new requirements. The team showed impressive commitment to the project's success and was helpful in aspects beyond technical. They were deeply interested in the product from the beginning.”",
    },
  },
};

export default CRYPTO_CURRENCY_INDUSTRIES_CONTENT;
