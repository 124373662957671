import styled, { css } from "styled-components";
import { space, typography, layout, opacity, flexbox } from "styled-system";
// Theme
import { styles } from "components/text/theme";
import { linesMovingFromDifferentDirectionsAnimation } from "theme/animations";
// Types
import { Colors, Gradients } from "theme/types";
import { ThemedProps, TextProps } from "components/text/types";

export const getEllipsis = ({ ellipsis }: ThemedProps) => {
  if (ellipsis) {
    return css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `;
  }
};

export const getFontSettings = ({ theme, textScale, $fontWeight }: ThemedProps) => {
  return css`
    ${textScale && styles[textScale]}

    font-weight: ${$fontWeight ? theme.fontWeight[$fontWeight] : ""};
  `;
};

export const fillTextWithGradient = (bgGradient: keyof Gradients, color?: keyof Colors) => {
  return !color
    ? css`
        background: ${({ theme }) => theme.gradients[bgGradient]};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      `
    : css`
        color: ${({ theme }) => theme.colors[color]};
      `;
};

export const getUnderlineStyles = ({ theme, color, underline }: ThemedProps) => {
  if (underline) {
    return css`
      &::before {
        display: "inline-block";
        content: "";
        clear: both;
        width: 100%;
        height: 0.5px;
        position: absolute;
        top: 100%;
        left: 0;
        background: ${color ? theme.colors[color] : theme.gradients.buttonAccent};
        transform-origin: 50% 100%;
        transition:
          clip-path 0.3s,
          transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
        clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%);
        pointer-events: none;
      }

      &:hover,
      &:focus {
        &::before {
          clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 50% 100%, 0 100%, 100% 100%, 100% 0%);
          ${linesMovingFromDifferentDirectionsAnimation}
        }
      }
    `;
  }
};

export const Text = styled.p<TextProps>`
  ${getFontSettings}

  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.textMain)};
  white-space: ${({ whiteSpace }) => whiteSpace};

  ${getEllipsis}
  ${getUnderlineStyles}

  ${space}
  ${typography}
  ${layout}
  ${opacity}
  ${flexbox}
`;
