// Utils
import { languageChecker } from "utils";
// Configs
import { LOCAL_STORAGE_KEYS, REGEX } from "configs";

// now translations used by weglot and locales used to have all translations in one place
export const fetchLocale = async (locale: string) => {
  try {
    const response = await fetch(`/locales/locales.json`);

    const data = (await response.json()) as Promise<object>;

    return data;
  } catch (e) {
    console.error(`Failed to fetch locale ${locale}`, e);

    return null;
  }
};

export const getLanguageCodeFromLS = () => {
  const subdomainlanguage = languageChecker("locale");

  const languageFromLS = localStorage.getItem(LOCAL_STORAGE_KEYS.language);

  if (languageFromLS === subdomainlanguage) {
    return languageFromLS;
  } else {
    return subdomainlanguage;
  }
};

export const translatedTextIncludesVariable = (translatedText: string) => {
  return !!translatedText?.match(REGEX.includesVariableRegex);
};
