import styled, { css } from "styled-components";

import Link from "components/link";
import { Container } from "components/layout";

import { Colors } from "theme/types";

export const StyledArticlesColumn = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  ${({ theme }) => theme.mediaQueries.laptopL} {
    justify-content: space-between;
    width: 50%;
  }
`;

export const StyledArticlesHeadingContainer = styled(Link)`
  -webkit-text-fill-color: ${({ theme }) => theme.colors.textMain};
`;

export const StyledArticlesContainer = styled(Container)<{ blur?: keyof Colors }>`
  ${({ theme }) => theme.mediaQueries.laptopL} {
    ${({ blur }) =>
      blur &&
      css`
        &::before {
          content: "";
          position: absolute;
          top: 5%;
          right: 5%;
          z-index: ${({ theme }) => -theme.zIndices.buttonGradient};
          width: 15%;
          height: 15%;
          box-shadow: ${({ theme }) => theme.colors.blueWhiteBlur} ${({ theme }) => theme.shadows.boostBusiness};
          background-color: ${({ theme }) => theme.colors.blueWhiteBlur};
          border-radius: ${({ theme }) => theme.radii.circle};
          opacity: 0.2;
          filter: blur(12px);
        }
      `}
  }
`;
