import { useLocation } from "react-router-dom";

import { useTranslation } from "context";

import { Accordion } from "components";
import { ArrowLeftIcon } from "components/svg";
import { StyledAccordionContentWrapper } from "./styled";

import { NavigationMenuAccordionType } from "./types";
import { StyledDropdownTitle } from "components/header/components";

const NavigationMenuAccordion: React.FC<NavigationMenuAccordionType> = ({
  DropdownComponent,
  navItemGeneralSettings,
  label,
  handleCloseMobileMenu,
  isMobileMenuOpen,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { t } = useTranslation();
  const { pathname, hash } = useLocation();

  React.useEffect(() => {
    if (!isMobileMenuOpen) {
      setIsOpen(false);
    }
  }, [isMobileMenuOpen]);

  React.useEffect(() => {
    setIsOpen(false);
  }, [pathname, hash]);

  return (
    <Accordion
      isOpenFromParent={isOpen}
      setIsOpenFromParent={setIsOpen}
      bgWhenOpen="bgDarkSecond"
      p={{ _: "0 1rem", laptopL: "0" }}
      rotateIcon={<ArrowLeftIcon width="2rem" color="white" />}
      iconRotateInitialDeg={270}
      iconRotateOpenDeg={90}
      title={
        <StyledDropdownTitle key={label} {...navItemGeneralSettings}>
          {t(label)}
        </StyledDropdownTitle>
      }
    >
      <StyledAccordionContentWrapper>
        <DropdownComponent onDropdownMenuClick={handleCloseMobileMenu} />
      </StyledAccordionContentWrapper>
    </Accordion>
  );
};

export default NavigationMenuAccordion;
