// Components + styling
import { StyledTag, StyledTagContent, StyledTagText } from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { TagProps } from "./types";

const Tag: React.FC<TagProps> = ({ selected, isShowMore = false, children, ...rest }) => {
  const { isDesktop } = useMatchBreakpoints();

  return (
    <StyledTag
      selected={selected}
      $backgroundColor={isShowMore ? "bgFourth" : "lineMain"}
      padding="1px"
      overflow="hidden"
      {...rest}
    >
      <StyledTagContent selected={selected} onClick={rest.onClick}>
        <StyledTagText textScale={isDesktop ? "p16Medium" : "p12Medium"} isShowMore={isShowMore}>
          {children}
        </StyledTagText>
      </StyledTagContent>
    </StyledTag>
  );
};
export default Tag;
