import styled from "styled-components";
// Components
import { CurlyArrowIcon, OurCapabilitiesArrowIcon } from "components/svg";

export const StyledCurlyArrowIcon = styled(CurlyArrowIcon)<{ rotate?: string }>`
  transform: rotate(90deg);
`;

export const StyledArrowIcon = styled(OurCapabilitiesArrowIcon)`
  transform: rotate(180deg);
`;

export const StyledScheduleCallContainer = styled.div<{ isButtonPositionStart?: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  justify-content: flex-start;
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.buttonGradient};

  ${({ theme }) => theme.mediaQueries.tablet} {
    justify-content: ${({ isButtonPositionStart }) => (isButtonPositionStart ? "start" : "center")};
    margin-top: 0;
  }
`;
