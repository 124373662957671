// Components + styling
import { Accordion, Container, Text } from "components";
import { EmailContact } from "components/footer/components/footer-contacts/components";
import { StyledAgreementsDetails, StyledAgreementsWrapper, StyledContactLink, StyledInnerWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { AGREEMENTS_COMPANY_NAME, AGREEMENTS_ADDRESS_LIST } from "./constants";
import { FOOTER_EMAIL_CONTACTS, FOOTER_PHONE_LIST } from "components/footer/constants";

const AgreementsContactDetails: React.FC<{ isShowDescription?: boolean }> = ({ isShowDescription }) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <StyledAgreementsWrapper>
      <StyledInnerWrapper>
        <Container pb={{ _: "3.75rem", laptop: "5rem" }}>
          <Accordion title={t("Contact details")} $backgroundColor="white">
            {isShowDescription && (
              <Text textScale={isDesktop ? "p18Regular" : "p14Regular"} color="textFifth" mb="1.5rem">
                {t(
                  "It is important that the personal information IDEALOGIC holds about you is accurate and current. Please keep IDEALOGIC informed if your personal information changes during your relationship with IDEALOGIC. Additionally, if you have any questions about this Privacy Policy, the practices of IDEALOGIC WEBSITE or your interaction with IDEALOGIC via IDEALOGIC WEBSITE, please contact:",
                )}
              </Text>
            )}
            <StyledAgreementsDetails>
              <Text textScale={isDesktop ? "p18Bold" : "p14Bold"} color="textMain">
                {t(AGREEMENTS_COMPANY_NAME.dev)}
              </Text>
              <Text my="0.5rem" textScale={isDesktop ? "p18Bold" : "p14Bold"} color="textMain">
                {t(AGREEMENTS_ADDRESS_LIST[0].address)}
              </Text>
            </StyledAgreementsDetails>

            {FOOTER_PHONE_LIST.map(({ label, tel }) => (
              <StyledContactLink key={label} href={`tel:${tel}`} textScale={isDesktop ? "p18Bold" : "p14Bold"}>
                {label.includes("UK") ? label.replace("UK", "United Kingdom") : label}
              </StyledContactLink>
            ))}
            <EmailContact
              mt="1.5rem"
              mb="0.25rem"
              mx="0"
              color="textMain"
              textScale={isDesktop ? "p18Bold" : "p14Bold"}
            >
              <StyledContactLink
                href={`mailto:${FOOTER_EMAIL_CONTACTS.mailContact}`}
                mt="1.5rem"
                mb="0.25rem"
                textScale={isDesktop ? "p18Bold" : "p14Bold"}
              >
                {FOOTER_EMAIL_CONTACTS.mailContact}
              </StyledContactLink>
            </EmailContact>
          </Accordion>
        </Container>
      </StyledInnerWrapper>
    </StyledAgreementsWrapper>
  );
};

export default AgreementsContactDetails;
