export const siteWidth = 1680; //px

export const breakpointMap = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
} as const;

export const breakpointsArray = ["320px", "375px", "425px", "768px", "1024px", "1440px"];

export const breakpoints = Object.assign(breakpointsArray, {
  mobileS: breakpointsArray[0],
  mobileM: breakpointsArray[1],
  mobileL: breakpointsArray[2],
  tablet: breakpointsArray[3],
  laptop: breakpointsArray[4],
  laptopL: breakpointsArray[5],
});

export const mediaQueries = {
  mobileS: `@media screen and (min-width: ${breakpointsArray[0]})`,
  mobileM: `@media screen and (min-width: ${breakpointsArray[1]})`,
  mobileL: `@media screen and (min-width: ${breakpointsArray[2]})`,
  tablet: `@media screen and (min-width: ${breakpointsArray[3]})`,
  tabletLaptopHeight: `@media screen and (min-width: ${breakpointsArray[3]}) and (min-height: ${breakpointsArray[4]})`,
  laptopSquareHalfHeight: `@media screen and (min-width: ${breakpointsArray[4]}) and (min-height: 512px)`, // 1024px / 2 = 512px
  laptop: `@media screen and (min-width: ${breakpointsArray[4]})`,
  laptopL: `@media screen and (min-width: ${breakpointsArray[5]})`,
  mediumHeight: `@media screen and (min-height: 580px)`,
  largeHeight: `@media screen and (min-height: ${siteWidth + 1}px)`,
} as const;

export const shadows = {
  card: "0px 6px 44px 0px rgba(78, 94, 106, 0.16)",
  beauty:
    "0px 0px 0px 0px rgba(94, 94, 94, 0.05), 0px 11px 25px 0px rgba(94, 94, 94, 0.05), 0px 46px 46px 0px rgba(94, 94, 94, 0.04), 0px 103px 62px 0px rgba(94, 94, 94, 0.03), 0px 183px 73px 0px rgba(94, 94, 94, 0.01), 0px 286px 80px 0px rgba(94, 94, 94, 0.00)",
  colored: "0px 4px 114px 0px rgba(130, 67, 227, 0.15)",
  tooltip: "0px 40px 64px -12px rgba(0, 0, 0, 0.02), 0px 32px 48px -8px rgba(0, 0, 0, 0.08)",
  button: "0px 0px 15px 0px rgba(255, 255, 255, 0.50) inset",
  ourApproachItemHover: "0 6px 44px rgba(78,94,106,.16)",
  headerDropdown: "0px 44px 130px 0px rgba(255, 187, 27, 0.20)",
  howWorkItemHover:
    "0px 1.85185px 3.14815px 0px rgba(0, 0, 0, 0.00), 0px 8.14815px 6.51852px 0px rgba(0, 0, 0, 0.01), 0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 38.51853px 25.48149px 0px rgba(0, 0, 0, 0.01), 0px 64.81483px 46.85186px 0px rgba(0, 0, 0, 0.02), 0px 100.00002px 80.00002px 0px rgba(0, 0, 0, 0.02)",
  reachingGoal:
    "0px 1.85185px 3.14815px 0px rgba(0, 0, 0, 0), 0px 8.14815px 6.51852px 0px rgba(0, 0, 0, 0.01), 0px 20px 13px 0px rgba(0, 0, 0, 0.01), 0px 38.51853px 25.48149px 0px rgba(0, 0, 0, 0.01), 0px 64.81483px 46.85186px 0px rgba(0, 0, 0, 0.02), 0px 200px 80.00002px 0px rgba(0, 0, 0, 0.02), 0px -64px 80px 0px rgba(0, 0, 0, 0.05)",
  chat: "0px 0px 14px 0px rgba(0, 0, 0, 0.13)",
  caseStudiesListInputs: "0px 44px 130px 0px rgba(51, 51, 51, 0.40)",
  boostBusiness: "0px 0px 100px 80px",
  stagesBlockDarkVariantShadow: "0px 4px 24px 0px #FFC532",
  howCanHelpHover: "0px 4px 24px 0px #5a3708",
  benefitsRealEstateHover:
    "0px 1.85px 3.15px 0px #00000001, 0px 8.15px 6.52px 0px #00000002, 0px 20px 13px 0px #00000003, 0px 38.52px 25.48px 0px #00000003, 0px 64.81px 46.85px 0px #00000004, 0px 100px 80px 0px #00000005",
} as const;

export const gradients = {
  blackGradient: "linear-gradient(45deg, rgb(0, 0, 0), rgb(0, 0, 0))",
  textAccent: "linear-gradient(266.13deg, #A85AE7 36.68%, #6537E5 75.39%)",

  corporateGradient:
    "linear-gradient(180deg, rgba(255, 197, 111, 0.46) 24.8%, rgba(255, 212, 91, 0.00) 24.81%, rgba(255, 144, 13, 0.90) 87.47%)",

  buttonLightFill: "linear-gradient(194.94deg, #FFFFFF 0.69%, #E0E0E0 89.47%)",
  buttonLightStroke: "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)",
  buttonDarkStroke: "linear-gradient(180deg, rgba(51, 51, 51, 0.2) 0%, rgba(51, 51, 51, 0) 100%)",

  buttonAccent: "linear-gradient(234.11deg, #FFD21C 0%, #FF951C 100%)",
  buttonAccentHover: "linear-gradient(234.11deg, #FFDC4C 0%, #FFAC4C 100%)",
  buttonAccentActive: "linear-gradient(234.11deg, #E6B800 0%, #E67A00 100%)",

  decorForDarkBg: "linear-gradient(135.15deg, #FACE1B 1.17%, #DC83C3 31.88%, #FFD137 65.46%)",

  bannerSlider: "linear-gradient(90deg, #0D0D0D 31.11%, rgba(0, 0, 0, 0) 90.37%)",

  transparent: "transparent",

  bannerTextGradient:
    "linear-gradient(90deg, #FFC61B -108.76%, #FE8E1F -80.4%, #F8682A -8.55%, #FC7F23 57.98%, #FFC61B 99.96%)",
  aboutUsTextGradient:
    "linear-gradient(15deg, #FFC61B -108.76%, #FE8E1F -80.4%, #F8682A -8.55%, #FC7F23 57.98%, #FFC61B 99.96%)",

  blogMainPost: "linear-gradient(180deg, rgba(187, 187, 187, 0) 0%, rgba(0, 0, 0, 0.8) 100%)",

  servicesPipelineGradientSm:
    "linear-gradient(0deg, rgba(231, 232, 233, 0.00) 0%, rgba(231, 232, 233, 0.90) 10.42%, #E7E8E9 88.02%, rgba(231, 232, 233, 0.00) 100%)",
  servicesPipelineGradient:
    "linear-gradient(90deg, rgba(231, 232, 233, 0.00) 0%, rgba(231, 232, 233, 0.90) 10.42%, #E7E8E9 88.02%, rgba(231, 232, 233, 0.00) 100%)",

  notFoundPageGradient: "radial-gradient(52.53% 52.53% at 50% 64%, rgba(0, 0, 0, 0.00) 0%, #000 100%)",

  timelineLineGradient: "linear-gradient(234deg, #ffd21c 0%, #ff951c 100%)",

  blogShowMoreGradient: "linear-gradient(90deg, #e57a00, #e5b800)",

  categoryLight: "linear-gradient(179.98deg, #32C4E7 -78.24%, rgba(50, 196, 231, 0) 139.23%)",

  bigPortfolioItemGradient: "linear-gradient(140.24deg, #14002d -144.94%, rgba(20, 0, 45, 0) 235.87%)",

  bgTabPanel: "linear-gradient(234.11deg, rgba(255, 210, 28, 0.2) 0%, rgba(255, 149, 28, 0.2) 100%)",
  bgSemiPermanent: "linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0)",

  customerReviewAngelGradient: "linear-gradient(140.08deg, #363D4C 15.01%, #070C16 82.46%)",
  customerReviewAlexGradient: "linear-gradient(157.17deg, #2F007C -88.72%, #0D0D0D 58.98%)",
  customerReviewDanGradient: "linear-gradient(167.48deg, #00536D -146.78%, #0D0D0D 93.53%)",
  customerReviewRomanGradient: "linear-gradient(328.94deg, #0D0D0D 10.5%, #02401D 133.31%)",
  customerReviewOscarWildeGradient: "linear-gradient(148.5deg, #DFD180 -194.35%, #0D0D0D 83.48%)",
  customerReviewDenisMerphyGradient: "linear-gradient(162.84deg, #43478E -74.34%, #070C16 94%)",

  ourServicesCardGradient1: "linear-gradient(122.5deg, #443FA0 32.27%, #5E82D7 84.06%)",
  ourServicesCardGradient2: "linear-gradient(152.47deg, #5263BE 14.74%, #6AA1ED 82.87%)",
  ourServicesCardGradient3: "linear-gradient(159.63deg, #5469C2 8.82%, #6DAAF2 90.4%)",
  ourServicesCardGradient4: "linear-gradient(151.25deg, #5D81D7 10.6%, #75BDFD 91.91%)",
  ourServicesCardGradient5: "linear-gradient(143.14deg, #6596E7 12.52%, #7BCCFF 95.57%)",

  grayGradient: "linear-gradient(32.01deg, #333333 0.72%, #555555 98.95%)",
  bannerBlueGradient: "linear-gradient(324.86deg, #4DB3FB 4.56%, #2F68CF 51.68%, #08027C 98.79%)",
  bannerRedGradient: "linear-gradient(300deg,#e8751a,#c51350,#8a1253)",
  bannerYellowGradient: "linear-gradient(98.71deg, #3B3B3B 2.18%, #483219 40.63%, #543912 73.77%, #5C3400 97.63%)",
  bannerYellowWhiteGradient:
    "linear-gradient(360deg, #FFFFFF 43.48%, rgba(255, 255, 255, 0) 114.51%), linear-gradient(98.71deg, #3B3B3B 2.18%, #483219 40.63%, #543912 73.77%, #5C3400 97.63%)",
  bannerGreenGradient: "linear-gradient(98.71deg, #104C2E 2.18%, #16895D 49.91%, #2FAA77 73.77%, #62D8A3 97.63%)",
} as const;

export const transitions = {
  default: "all 0.3s ease",
  defaultWithPause_0_5s: "all 0.3s 0.5s ease-in-out",
  easeIn_0_2s: "all 0.2s ease-in",
  easeInOut_0_5s: "all 0.5s ease-in-out",
  maxWidth_easeInOut_0_5s: "max-width 0.5s ease-in-out",
  opacity_easeInOut_0_5s: "opacity 0.5s ease-in-out",
  easeInOut_0_8s: "all 0.8s ease-in-out",
  linear_0_2s: "all 0.2s linear",
  cubic_bezier_0_3s: "all .3s cubic-bezier(.2,1,.8,1)",
  defaultMotion: {
    duration: 0.15,
    type: "easyInOut",
  },
  defaultParallaxMotion: {
    type: "spring",
    stiffness: 300,
    damping: 50,
    mass: 1,
    restDelta: 0.001,
    duration: 0.5,
  },
  linearMotion_0_2s: {
    type: "linear",
    duration: 0.2,
  },
  bounceEffect: {
    type: "spring",
    bounce: 0.6,
    duration: 0.8,
  },
  bannerTitleMotion: {
    type: "linear",
    duration: 0.85,
    delay: 0.2,
  },
} as const;

export const radii = {
  semiSmall: "4px",
  small: "6px",
  semiMedium: "8px",
  medium: "16px",
  semiLarge: "20px",
  large: "24px",
  xl: "32px",
  xxl: "40px",
  contactForm: "60px",
  header: "70px",
  videoTestimonialDecorLabel: "100px",
  cookies: "150px",
  circle: "50%",
} as const;

export const zIndices = {
  buttonGradient: 1,
  buttonContent: 2,
  mediumPriority: 8,
  dropdown: 10,
  header: 50,
  tooltip: 101,
} as const;

export const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
} as const;

export const fonts = {
  ubuntu: "'Ubuntu', sans-serif",
  kalam: "'Kalam', cursive",
} as const;

export default {
  siteWidth,
  breakpoints,
  mediaQueries,
  shadows,
  radii,
  zIndices,
  fonts,
  fontWeight,
  gradients,
  transitions,
};
