// Constants
import { ROUTES } from "navigation/routes";
import { IndustriesVariants } from "pages/industries/types";
import { ServicesVariants } from "pages/services/types";
import { CareersVariants } from "pages/position/types";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";

export const headerThemes = {
  light: "light",
  dark: "dark",
  transparent: "transparent",
} as const;

export const headerAnimationType = {
  static: "static",
  initial: "initial",
  hidden: "hidden",
  fixed: "fixed",
  drop: "drop",
} as const;

export type headerThemesType = keyof typeof headerThemes;

export const LIGHT_HEADER_THEME_PATHNAME_LIST = [
  `/${ROUTES.blog}`,
  `/${ROUTES.caseStudies}`,
  `/${ROUTES.privacyPolicy}`,
  `/${ROUTES.industries}/${IndustriesVariants.other}`,
  `/${ROUTES.services}/${ServicesVariants.dedicatedTeam}`,
  `/${ROUTES.industries}/${IndustriesVariants.realEstate}`,
  `/${ROUTES.industries}/${IndustriesVariants.propertyManagementSolutions}`,
  `/${ROUTES.services}/${ServicesVariants.mlDevelopment}`,

  `/${ROUTES.caseStudies}/${CaseStudiesSlugEnums.eState}`,
  `/${ROUTES.caseStudies}/${CaseStudiesSlugEnums.cBank}`,
  `/${ROUTES.caseStudies}/${CaseStudiesSlugEnums.inder}`,
  `/${ROUTES.caseStudies}/${CaseStudiesSlugEnums.eplane}`,
  `/${ROUTES.caseStudies}/${CaseStudiesSlugEnums.glue}`,
  ...Object.values(CareersVariants).map(careerVariant => `/${ROUTES.careers}/${careerVariant}`),
];

export const TRANSPARENT_HEADER_THEME_PATHNAME_LIST = [
  `/${ROUTES.caseStudies}/${ROUTES.healthLife}`,
  `/${ROUTES.caseStudies}/${ROUTES.nextparq}`,
];
