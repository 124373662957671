// Components + styling
import { Button, Container, Heading, Text } from "components";
import { StagesBlocksItem } from "./components";
import {
  StyledButtonContainer,
  StyledMobileButtonContainer,
  StyledParallaxImagesContainer,
  StyledStagesBlockContainer,
  StyledStagesBlockInfoContainer,
  StyledStagesBlocksList,
} from "./styled";
import { IndustryInfoParallaxImage } from "pages/industries/components/industry-info/components";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Utils
import { scrollToElementById } from "utils";
// Constants
import { BLOCK_ID } from "configs";
import { HOW_START_LIST } from "./constants";
// Types
import { buttonThemeTypes, variants } from "components/button/types";
import { StagesBlocksProps } from "./types";

const StagesBlocks: React.FC<StagesBlocksProps> = ({
  title,
  description,
  buttonText = "Get Your Free Estimation",
  buttonVariant = variants.PRIMARY,
  buttonTheme = buttonThemeTypes.LIGHT,
  list = [],
  hoverBorderColor,
  blur,
  laptopLDescriptionWidth,
  spaceProps,
  isBgDark = false,
  isNeedCurveLine = true,
  isNeedParallaxImage = false,
  isMainPageVariation = false,
  isNeedFreeEstimation = true,
  isDarkVariant = false,
}) => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useMatchBreakpoints();

  const defaultItemCount = 3;

  const isMoreItemsThenDefaultCount = list.length > defaultItemCount;

  return (
    <StyledStagesBlockContainer isBgDark={isBgDark}>
      <Container
        py={{ _: "4rem", laptop: "6.875rem" }}
        px={{ _: "1rem", tablet: "2rem", laptopL: "4.5rem" }}
        {...spaceProps}
      >
        <StyledStagesBlockInfoContainer>
          <div>
            <Heading color={isBgDark ? "white" : "textMain"} scale="h2" as="h3" mb={{ _: "0.25rem", laptop: "1rem" }}>
              {t(title ?? "How to Start")}
            </Heading>

            <Text
              textScale={isDesktop ? "p22Regular" : "p16Regular"}
              color={isBgDark ? "textThird" : "textFourth"}
              maxWidth={{
                _: "100%",
                tablet: "80%",
                laptop: isMoreItemsThenDefaultCount ? "57.5rem" : isNeedParallaxImage ? "50%" : "90%",
                laptopL: laptopLDescriptionWidth ?? "42.625rem",
              }}
            >
              {t(
                description ??
                  "Before you start working with us, let's explain how we approach each project. We try to anticipate all possible challenges and ensure a high-quality user experience. Here's how our collaboration works.",
              )}
            </Text>
          </div>

          {!isMobile && isNeedFreeEstimation ? (
            <StyledButtonContainer>
              <Button
                width="20.25rem"
                variant={buttonVariant}
                buttonTheme={buttonTheme}
                hoverLinesPosition="bottomLeft"
                onClick={() => scrollToElementById(BLOCK_ID.contactUs)}
                bgColor={isMainPageVariation ? "bgDarkSecond" : undefined}
                color={isMainPageVariation ? "white" : "bgDark"}
                height={{ _: "3.125rem", laptop: "3.75rem" }}
              >
                {t(buttonText)}
              </Button>
            </StyledButtonContainer>
          ) : null}

          {isNeedParallaxImage && isDesktop && (
            <StyledParallaxImagesContainer>
              <IndustryInfoParallaxImage />
            </StyledParallaxImagesContainer>
          )}
        </StyledStagesBlockInfoContainer>

        <StyledStagesBlocksList
          isNeedCurveLine={isNeedCurveLine}
          isNeedFreeEstimation={isNeedFreeEstimation}
          isMoreItemsThenDefaultCount={isMoreItemsThenDefaultCount}
          blur={blur}
        >
          {list.length
            ? list.map((item, index) => (
                <StagesBlocksItem
                  key={index}
                  {...item}
                  itemsLength={list.length}
                  isDarkVariant={isDarkVariant}
                  hoverBorderColor={hoverBorderColor}
                />
              ))
            : HOW_START_LIST.map(item => (
                <StagesBlocksItem
                  key={item.title}
                  {...item}
                  isDarkVariant={isDarkVariant}
                  hoverBorderColor={hoverBorderColor}
                />
              ))}
        </StyledStagesBlocksList>

        {isMobile && isNeedFreeEstimation ? (
          <StyledMobileButtonContainer>
            <Button
              width="100%"
              variant={buttonVariant}
              buttonTheme={buttonTheme}
              hoverLinesPosition="bottomLeft"
              onClick={() => scrollToElementById(BLOCK_ID.contactUs)}
              height="3.125rem"
            >
              {t(buttonText)}
            </Button>
          </StyledMobileButtonContainer>
        ) : null}
      </Container>
    </StyledStagesBlockContainer>
  );
};

export default StagesBlocks;
