import { useThemeContext } from "context";
// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 28 28" width="28" height="28" {...props} color="transparent">
      <path
        d="M24.4893 11.3287C24.0926 11.1654 23.5676 11.0837 22.9143 11.0837H16.7543C14.7243 11.0837 14.001 11.8071 14.001 13.8604V22.8904C14.001 23.5671 14.0826 24.0921 14.2576 24.5004C14.6193 25.3404 15.3893 25.6671 16.7543 25.6671H22.9143C24.9443 25.6671 25.6676 24.9321 25.6676 22.8904V13.8604C25.6676 12.4721 25.341 11.6904 24.4893 11.3287ZM21.001 23.0421H18.6676C18.6443 23.0421 18.6093 23.0421 18.586 23.0304C18.411 23.0187 18.2593 22.9604 18.131 22.8437C17.921 22.6921 17.7926 22.4471 17.7926 22.1671C17.7926 21.6887 18.1893 21.2921 18.6676 21.2921H21.001C21.4793 21.2921 21.876 21.6887 21.876 22.1671C21.876 22.6454 21.4793 23.0421 21.001 23.0421Z"
        fill={theme.colors[color]}
      />
      <path
        d="M24.489 7.22207V8.16707C24.489 8.80874 23.964 9.33374 23.3224 9.33374H16.754C13.7674 9.33374 12.2507 10.8621 12.2507 13.8604V24.5004C12.2507 25.1421 11.7257 25.6671 11.084 25.6671H8.80904C8.34237 25.6671 7.96904 25.2937 7.96904 24.8387C7.96904 24.3721 8.34237 24.0104 8.80904 24.0104H11.084V19.5421H7.0007C4.4107 19.4137 2.3457 17.2787 2.3457 14.6537V7.22207C2.3457 4.52707 4.53904 2.33374 7.2457 2.33374H19.6007C22.2957 2.33374 24.489 4.52707 24.489 7.22207Z"
        fill={theme.colors[color]}
      />
    </Svg>
  );
};

export default Icon;
