// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { ROUTES } from "navigation/routes";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { LOGISTICS_INDUSTRY_TABS_LIST } from "../components/industry-info/constants";
// Types
import { IndustriesVariants, IndustryPageContent } from "../types";

const LOGISTICS_CONTENT: IndustryPageContent = {
  banner: {
    title: "Logistics software development",
    description:
      "Enhance supply chain, transportation operations, and tracking capabilities with custom-made logistics software development by Idealogic.",
    imageSrc: "/images/industries-banner-logistics.webp",
    imageRatio: 1.24,
  },
  industryInfo: {
    title: "Logistics tech advancement",
    description: "We deliver logistics solutions tailored to help you optimize business processes.",
    industryInfoTabsList: LOGISTICS_INDUSTRY_TABS_LIST,
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.conveya, CaseStudiesSlugEnums.haulBreeze],
    title: "Logistics case studies",
    TitleIcon: WavingHandIcon,
    description:
      "Discover real-world success stories showcasing the transformative impact of our logistics solutions. From warehouse automation to last-mile delivery optimization.",
  },
  coveredIndustries: {
    coveredIndustriesList: [
      { label: "Fintech", link: `/${ROUTES.industries}/${IndustriesVariants.fintech}` },
      { label: "Real Estate", link: `/${ROUTES.industries}/${IndustriesVariants.realEstate}` },
      { label: "Aviation", link: `/${ROUTES.industries}/${IndustriesVariants.aviation}` },
      { label: "Media", link: `/${ROUTES.industries}/${IndustriesVariants.media}` },
      { label: "Other industries", link: `/${ROUTES.industries}/${IndustriesVariants.other}` },
    ],
  },
};

export default LOGISTICS_CONTENT;
