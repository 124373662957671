import { Suspense } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "styled-components";
import { ApolloProvider } from "@apollo/client";
// Styles
import { GlobalStyle } from "styles";
// Components
import { ErrorBoundary, Loader, ErrorBoundaryFallback } from "components";
// Context
import {
  BlogsProvider,
  useThemeContext,
  CaseStudyProvider,
  BlurContextProvider,
  ThemeContextProvider,
  SocketContextProvider,
  LanguageContextProvider,
} from "context";
// Hooks
import { useCheckRouter } from "hooks";
// Navigation
import Navigation from "navigation";
// Services
import { apolloClient } from "services";

import * as serviceWorkerRegistration from "./service-worker-registration";

const ThemedApp: React.FC = () => {
  const { theme } = useThemeContext();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <Suspense fallback={<Loader />}>
        <ErrorBoundary fallbackComponent={ErrorBoundaryFallback}>
          <LanguageContextProvider fallback={<Loader />}>
            <CaseStudyProvider>
              <BlogsProvider>
                <BlurContextProvider>
                  <SocketContextProvider>
                    <Navigation />
                  </SocketContextProvider>
                </BlurContextProvider>
              </BlogsProvider>
            </CaseStudyProvider>
          </LanguageContextProvider>
        </ErrorBoundary>
      </Suspense>
    </ThemeProvider>
  );
};

const Root: React.FC = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <HelmetProvider>
        <ThemeContextProvider>
          <ThemedApp />
        </ThemeContextProvider>
      </HelmetProvider>
    </ApolloProvider>
  );
};

const router = createBrowserRouter([{ path: "*", Component: Root }]);

const App: React.FC = () => {
  useCheckRouter(router);

  return <RouterProvider router={router} />;
};

serviceWorkerRegistration.register();

export default App;
