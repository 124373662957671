// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { EXTERNAL_LINKS } from "configs";
// Types
import { IndustryPageContent } from "../../industries/types";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { BenefitsItemProps } from "pages/services/components/benefits/components/benefits-item/types";
import { ServicesListItemType } from "../../industries/components/our-services-fintech/types";
import { WhoWeServeItemType } from "../../industries/components/who-we-serve/types";
import { IndustriesItem } from "pages/landing/components/industries/components/industries-item/types";

export const DATA_SCIENCE_BENEFITS_LIST: BenefitsItemProps[] = [
  {
    title: "Data Collection & Acquisition",
    iconSrc: "/images/expertise/data-science/benefits/data-collection-icon.svg",
    description: "We help you gather data from various sources, ensuring data quality and accuracy.",
  },
  {
    title: "Data Cleaning & Preprocessing",
    iconSrc: "/images/expertise/data-science/benefits/data-cleaning-icon.svg",
    description:
      "Clean and prepare your raw data for analysis, handling missing values, inconsistencies, and outliers.",
  },
  {
    title: "Exploratory Data Analysis (EDA)",
    iconSrc: "/images/expertise/data-science/benefits/exploratory-data-icon.svg",
    description: "Gain initial insights from your data through statistical analysis and visualization techniques.",
  },
  {
    title: "Advanced Data Analytics",
    iconSrc: "/images/expertise/data-science/benefits/advanced-data-icon.svg",
    description:
      "Employ advanced techniques like machine learning, deep learning, and statistical modeling to uncover hidden patterns and trends.",
  },
  {
    title: "Data Visualization",
    iconSrc: "/images/expertise/data-science/benefits/data-visualization-icon.svg",
    description:
      "Create compelling data visualizations that effectively communicate insights to both technical and non-technical audiences.",
  },
  {
    title: "Data Science Consulting",
    iconSrc: "/images/expertise/data-science/benefits/data-science-icon.svg",
    description:
      "Partner with us to define your data analysis goals, choose the right methods, and translate insights into actionable strategies.",
  },
];

export const DATA_SCIENCE_TOP_SERVICES_LIST: ServicesListItemType[] = [
  {
    title: "Neural Networks and Deep Learning",
    description:
      "Our expert team uses the latest technologies to deliver data science models for accurate forecasting, fraud detection, speech recognition solutions, and more.",
    imageSrc: "/images/expertise/data-science/our-services/our-services-neutal-networks.webp",
    aspectRatio: 1.69,
    width: "60%",
  },
  {
    title: "Data Transformation",
    description:
      "Restructure your data effortlessly and draw insights from it, using the tools enhanced with our artificial intelligence solutions to operate at high capacity.",
    bgImageSrc: "/images/industries/fintech/our-services/our-services-fintech-legacy-software-transformation.webp",
    gradient: "buttonAccent",
    width: "40%",
    isBlackText: true,
    backgroundSize: "100%",
    backgroundSizeMobile: "100% 12.5rem",
  },
];

export const DATA_SCIENCE_BOTTOM_SERVICES_LIST: ServicesListItemType[] = [
  {
    title: "BI & Visualizations",
    description: "We provide you with customized tools to enable you to analyze your data with ease and convenience.",
    imageSrc: "/images/expertise/data-science/our-services/our-services-vizualisation.webp",
    aspectRatio: 0.82,
    width: "calc(30% - 0.6875rem)",
    isBlackText: true,
  },
  {
    title: "Cloud Environments",
    description:
      "Our cloud-based data science solutions are a game changer for your business, enabling scalability, innovation, safety, and cost-effectiveness.",
    imageSrc: "/images/expertise/data-science/our-services/our-services-cloud-enviroments.webp",
    aspectRatio: 2.07,
    width: "calc(70% - 0.6875rem)",
  },
  {
    title: "Machine Learning",
    description:
      "Our team can consult you on best-suited ML solutions for your business needs and deliver data science solutions enabled by AI to simplify your data management.",
    imageSrc: "/images/expertise/data-science/our-services/our-services-machine-learning.webp",
    aspectRatio: 2.07,
    width: "calc(70% - 0.6875rem)",
    spaceProps: {
      marginLeft: { _: "0", tablet: "-40%" },
    },
  },
  {
    title: "Natural Language Processing",
    description: "Recognize and process customers’ input, no matter the format or complexity.",
    bgImageSrc: "/images/industries/fintech/our-services/our-services-fintech-software-quality-assurance.webp",
    gradient: "grayGradient",
    backgroundSize: "20.0625rem",
    backgroundSizeMobile: "12.5rem 9.375rem",
    width: "calc(30% - 0.6875rem)",
  },
];

export const DATA_SCIENCE_WHO_WE_SERVE_LIST: WhoWeServeItemType[] = [
  { text: "AI Language Models" },
  { text: "Recommendation Systems" },
  { text: "Predictive Analytics" },
  { text: "Customer Analytics and Personalization" },
  { text: "Embedded Operational Intelligence" },
  { text: "Optical Character Recognition" },
];

export const DATA_SCIENCE_STRETCHING_CARD: IndustriesItem[] = [
  {
    title: "Strategy and Consulting",
    description:
      "Idealogic’s team consists of experienced members who can advise you on data science services and tech stack best suited for your business needs, reshape your strategy to meet market demands, or estimate your project for you to plan the budget accordingly",
    pointsList: [
      "Data science consulting",
      "Machine learning consulting",
      "Business case analysis",
      "Project planning",
    ],
    iconSrc: "/images/expertise/data-science/stretching-card/strategy-and-consulting-icon.svg",
    bgImageSrc: "/images/expertise/data-science/stretching-card/strategy-and-consulting-image.webp",
    isDarkColorDescription: false,
    customBackgroundPosition: "35% center",
  },
  {
    title: "Data Engineering",
    description:
      "We help you establish high-performance infrastructure or update your existing one to ensure informed decision-making. Our team works with your initial processes to define the patterns for model development and ensure a smooth transition to advanced data management",
    pointsList: [
      "Optimization of database and data warehouse platforms",
      "Platform migration",
      "Data discovery and augmentation",
      "ETL pipeline development",
    ],
    iconSrc: "/images/expertise/data-science/stretching-card/data-engineering-icon.svg",
    bgImageSrc: "/images/expertise/data-science/stretching-card/data-engineering-image.webp",
    isDarkColorDescription: false,
    customBackgroundPosition: "57% center",
  },
  {
    title: "Solution Development",
    description:
      "With our experienced machine learning engineers, you get trained and validated AI-based models they deploy in a tailored app designed to meet your objectives. Following the ModelOps approach, we initiate a continuous operational cycle leading to high-quality model production.",
    pointsList: [
      "Collection and exploration",
      "Custom model development",
      "Model training and evaluation",
      "Data science app development",
    ],
    iconSrc: "/images/expertise/data-science/stretching-card/solutions-development-icon.svg",
    bgImageSrc: "/images/expertise/data-science/stretching-card/solutions-development-image.webp",
    isDarkColorDescription: false,
    customBackgroundPosition: "86% center",
  },
  {
    title: "Maintenance and Optimization",
    description:
      "We aim to establish the models that would work in the long-term perspective. Our team monitors their performance to detect both bugs and room for improvement. We value your investment in data analytics solutions and build models to address evolving challenges.",
    pointsList: [
      "Model performance monitoring",
      "Data consistency analysis",
      "Model enrichment",
      "Stability monitoring and drift prevention",
    ],
    iconSrc: "/images/expertise/data-science/stretching-card/maintence-and-optimazation-icon.svg",
    bgImageSrc: "/images/expertise/data-science/stretching-card/maintence-and-optimazation-image.webp",
    isDarkColorDescription: false,
    customBackgroundPosition: "86% center",
  },
];

const DATA_SCIENCE_CONTENT: IndustryPageContent = {
  banner: {
    title: "Expert Data Science Services to Drive Business Growth",
    description:
      "Idealogic implements advanced artificial intelligence solutions and provides data science consulting to endorse your data-driven decision-making, helping you with strategic goals.",
    imageSrc: "/images/expertise/data-science/data-science-banner-bg.webp",
    imageRatio: 1.75,
  },
  titleWithBackground: {
    title: "What is AI Software Development?",
    description:
      "We build digital solutions and software applications that leverage the power of machine learning and intelligent algorithms. AI software can automate tasks, analyze vast amounts of data, and learn and adapt over time, bringing significant advantages to businesses across industries.",
  },
  benefits: {
    title: "How we can help",
    description:
      "Our machine learning development for data science services includes various types of data processing that streamline operations and allow your team to concentrate on strategic tasks.",
    benefitsList: DATA_SCIENCE_BENEFITS_LIST,
    isBackgroundImageNeed: false,
    isNeedCurveLine: false,
    buttonText: "Schedule a call",
    isButtonPositionStart: true,
  },
  ourServicesFintech: {
    title: "Solid Data Foundation for Your Business Model",
    description:
      "We offer you to look behind the curtains and learn more about our dedication to delivering you the best solutions for data management.",
    isEstimationButton: true,
    topServicesList: DATA_SCIENCE_TOP_SERVICES_LIST,
    bottomServicesList: DATA_SCIENCE_BOTTOM_SERVICES_LIST,
  },
  whoWeServe: {
    title: "Delivering Data Science Solutions Across Business Cases",
    description:
      "Discover the variety and complexity of our data science solutions, or surprise us with your unique idea. Anyway, we are ready to start working with you.",
    whoWeServeListLayoutProps: {
      maxHeight: { _: "100%", tablet: "29.1875rem", laptop: "12.1875rem" },
    },
    whoWeServeList: DATA_SCIENCE_WHO_WE_SERVE_LIST,
  },
  bookACallWithExpert: {
    bgSrc: "/images/expertise/blockchain/book-call-with-a-expert/bg.webp",
    bgMobileSrc: "/images/expertise/blockchain/book-call-with-a-expert/bg.webp",
    title: {
      beforeKeyWord: "",
      keyWord: "Our expert",
      afterKeyWord: "will be happy to discuss the details of your project!",
    },
    description: "",
    linkedin: {
      name: "Alex Sotnikov",
      href: EXTERNAL_LINKS.linkedInAlexSotnikov,
    },
    jobTitle: "Head of Back-end department",
    buttonText: "Book a call",
    imageSrc: "/images/expertise/data-science/book-a-call-with-expert/book-a-call-with-expert-alex-sotnikov.webp",
    mobileImageSrc: "/images/expertise/data-science/book-a-call-with-expert/book-a-call-with-expert-alex-sotnikov.webp",
    isReverse: true,
    imageAspectRatio: 1.18,
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.inder],
    title: "Success Stories of Data Science Projects",
    TitleIcon: WavingHandIcon,
    description:
      "Please look at the results of our data science services and go for AI & ML-enabled solutions for your business. Let’s work together.",
  },
  stretchingCard: {
    title: "Benefits of Data Science Services",
    description:
      "Dive in and learn more about the advantages of implementing data science solutions into your business model.",
    hoveredSize: "56%",
    notHoveredSize: "14%",
    isNeedButton: true,
    industriesList: DATA_SCIENCE_STRETCHING_CARD,
  },
  boostBusiness: {
    title: "Unique data science solutions for your wildest ideas",
    description: "We will help you to understand how to choose a data science service provider.",
    buttonTitle: "Schedule a call",
    personImageSrc: "/images/expertise/data-science/white-label-data-science.webp",
    imageRatio: 1.04,
    isAbsolute: true,
    bgColor: "bgThird",
    isWhite: true,
    isHideImageOnMobile: true,
  },
  ourCustomerReview: {
    title: "Our customer review",
    description: "We've worked with startups and enterprises – look what they have to say about us!",
    bgImageSrc: "/images/expertise/data-science/our-customer-review/our-customer-review-bg.webp",
    linkedInLink: EXTERNAL_LINKS.linkedInDenisMerphy,
    bgGradient: "customerReviewDenisMerphyGradient",
    isTextClamp: false,
    owner: {
      name: "Denis Murphy",
      logo: "/images/expertise/data-science/our-customer-review/brand-nudge-logo.svg",
      logoAspectRatio: 2.03,
      logoSize: "7.5rem",
      logoSizeMobile: "3rem",
      jobTitle: "CEO at Brand Nudge",
      image: "/images/expertise/data-science/our-customer-review/our-customer-review-denis-merphy.webp",
      imageAspectRatio: 1,
      reviewText:
        "“As the co-founder of our platform, I'm immensely proud of our data science project's success in mapping data across diverse companies and retailers. This initiative has not only enhanced our understanding of market dynamics but also empowered our partners with actionable insights, driving both innovation and customer satisfaction to new heights.”",
    },
  },
};

export default DATA_SCIENCE_CONTENT;
