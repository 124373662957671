import React from "react";
import { motion } from "framer-motion";
// Components + Styling
import { StyledCoveredIndustriesParallaxImage } from "./styled";
// Hooks
import { useParallaxEffect } from "hooks";
// Constants
import { PARALLAX_IMAGES_LIST } from "./constants";
// Types
import { UseScrollOffsetOptions } from "types/styled-components-types";

const CoveredIndustriesParallaxImage = React.forwardRef<HTMLDivElement | null, {}>((_, ref) => {
  const parallaxOffset: UseScrollOffsetOptions[] = ["start end", "end start"];

  const parallaxImagesEffects = [
    useParallaxEffect(ref, 20, { offset: parallaxOffset }),
    useParallaxEffect(ref, 60, { offset: parallaxOffset }),
    useParallaxEffect(ref, 25, { offset: parallaxOffset }),
    useParallaxEffect(ref, 35, { offset: parallaxOffset }),
    useParallaxEffect(ref, 50, { offset: parallaxOffset }),
  ];

  return (
    <StyledCoveredIndustriesParallaxImage>
      {PARALLAX_IMAGES_LIST.map(({ StyledComponent, imageSrc, zIndex, ratio }, index) => {
        const { y, selectedTransition } = parallaxImagesEffects[index];

        return (
          <motion.div key={imageSrc + index} style={{ width: "100%", zIndex, y }} transition={selectedTransition}>
            <StyledComponent src={imageSrc} aspectRatio={ratio} />
          </motion.div>
        );
      })}
    </StyledCoveredIndustriesParallaxImage>
  );
});

CoveredIndustriesParallaxImage.displayName = "CoveredIndustriesParallaxImage";

export default CoveredIndustriesParallaxImage;
