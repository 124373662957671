import { StagesBlocksItemProps } from "./components/stages-blocks-item/types";

export const HOW_START_LIST: StagesBlocksItemProps[] = [
  {
    title: "Connect with Us",
    description: "Connect with our solutions experts to align your business goals and clarify project requirements.",
    iconSrc: "/images/fintech/1.svg",
  },
  {
    title: "Get a Free Estimation",
    description:
      "Obtain a complimentary estimate accompanied by a comprehensive plan for further collaboration within 2 working days.",
    iconSrc: "/images/fintech/2.svg",
  },
  {
    title: "Discuss a Plan",
    description:
      "Upon agreement, we'll initiate the project by signing an NDA and commencing work according to the agreed-upon plan.",
    iconSrc: "/images/fintech/3.svg",
  },
];
