import styled from "styled-components";
// Components
import { Box } from "components";
// Animations
import { scaleAnimation } from "theme/animations";

const ClickItem = styled(Box)`
  width: fit-content;
  ${scaleAnimation}
  cursor: pointer;
`;

export default ClickItem;
