import styled from "styled-components";

export const StyledOurCompanyList = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding-bottom: 1rem;

  background-color: ${({ theme }) => theme.colors.bgDarkSecond};

  ${({ theme }) => theme.mediaQueries.laptop} {
    max-width: 75rem;
    padding-bottom: 0;
    background-color: ${({ theme }) => theme.colors.bgDark};
    flex-direction: column;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    justify-content: space-between;
  }
`;

export const StyledMemberItem = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  padding: 2.5rem 2rem;
  border-radius: ${({ theme }) => theme.radii.semiLarge};
  background-color: ${({ theme }) => theme.colors.bgDark};

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: calc(50% - 0.5rem);
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 19.375rem;
    padding: 1.5rem 1rem;
    margin: 0 0.75rem;
    background-color: ${({ theme }) => theme.colors.bgDarkSecond};

    &:nth-last-of-type(1) {
      margin-right: 0;
    }
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    padding: 2.5rem 2rem;
    width: 21.375rem;
  }
`;

export const StyledLinkedInWrapper = styled.div`
  margin-left: 0.5rem;
  border-left: 1px solid ${({ theme }) => theme.colors.textSixth};
`;
