// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { EXTERNAL_LINKS } from "configs";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
// Types
import { buttonThemeTypes, variants } from "components/button/types";
import { IndustryPageContent } from "../types";

const CUSTOM_REAL_ESTATE_CONTENT: IndustryPageContent = {
  banner: {
    title: "Custom Real Estate Software Solutions",
    description:
      "Elevate your real estate business with tailored software solutions crafted by Idealogic. Our innovative approach streamlines workflows, enhances operational efficiency, and delivers unparalleled results to propel your business forward.",
    buttonLabel: "Get a Free Estimation Today",
    maxWidthForButtonRem: 18.25,
    gradient: {
      color: "bannerYellowGradient",
      backgroundSize: "cover",
    },
    imageRatio: 1.39,
  },
  ourApproach: {
    title: "Our Approach to Property Management Projects",
    description:
      "Explore our comprehensive range of services designed to revolutionize your property management workflows, accounting processes, and client interactions for both commercial and residential sectors.",
    imageSrc: "/images/industries/custom-real-estate-software-development/custom-real-estate-approach-img.webp",
    imageRatio: 1.22,
    pointsList: [
      {
        title: "Commercial Real Estate Sector",
        pointsBgGradient: "bannerYellowGradient",
        points: [
          "Experience dynamic property management solutions tailored for commercial real estate",
          "Enabling efficient tenant oversight",
          "Lease management",
          "Lead generation",
        ],
      },
      {
        title: "Residential Real Estate Sector",
        pointsBgGradient: "bannerYellowGradient",
        points: [
          "Simplify property management and maintenance tasks with our residential real estate solutions",
          "Featuring application-based scheduling",
          "Enhanced security measures",
          "Streamlined communication channels",
        ],
      },
    ],
  },
  howCanHelp: {
    title: "How We Can Help",
    description:
      "Explore how Idealogic can empower various stakeholders within the real estate industry, offering tailored software solutions to address specific needs and challenges.",
    buttonLabel: "I Want a Free Estimation",
    howCanHelpList: [
      {
        title: "Architectural and Design Firms",
        description:
          "Architectural and design firms can leverage Idealogic's advanced 3D modeling and simulation tools to not only meet industry standards but also integrate cutting-edge innovations seamlessly into their projects. Our solutions empower firms to visualize designs with precision, optimize workflows, and impress clients with immersive presentations.",
        iconSrc: "/images/industries/custom-real-estate-software-development/custom-real-estate-how-help-icon-1.svg",
      },
      {
        title: "Property Managers",
        description:
          "Property managers can streamline their operations, enhance tenant relations, and boost overall efficiency with our comprehensive property management software tools. From lease management to maintenance scheduling, our solutions offer a centralized platform to efficiently manage properties, improve communication, and ensure tenant satisfaction.",
        iconSrc: "/images/industries/custom-real-estate-software-development/custom-real-estate-how-help-icon-2.svg",
      },
      {
        title: "Real Estate Agents & Brokers",
        description:
          "Real estate agents and brokers can maximize their productivity and effectiveness with Idealogic's custom solutions tailored specifically for their needs. Our software enhances property listings, streamlines client management processes, and optimizes sales processes, empowering agents to close deals faster and serve clients more efficiently.",
        iconSrc: "/images/industries/custom-real-estate-software-development/custom-real-estate-how-help-icon-3.svg",
      },
      {
        title: "Real Estate Investors",
        description:
          "Real estate investors can gain valuable insights and tools to make informed decisions and increase their returns with Idealogic's real estate investment solutions. Our market analysis tools provide deep insights into market trends and opportunities, while our portfolio management and investment optimization tools help investors strategically allocate their resources for maximum returns.",
        iconSrc: "/images/industries/custom-real-estate-software-development/custom-real-estate-how-help-icon-4.svg",
      },
      {
        title: "Homebuyers & Renters",
        description:
          "Homebuyers and renters can discover properties effortlessly with Idealogic's personalized search tools, virtual tours, and direct communication channels. Our solutions make the property search process seamless and convenient, allowing users to find their dream home or rental property with ease.",
        iconSrc: "/images/industries/custom-real-estate-software-development/custom-real-estate-how-help-icon-5.svg",
      },
    ],
  },
  unleashingInnovation: {
    title: "Unleashing Innovation in the Real Estate",
    description:
      "Explore how Idealogic incorporates the latest trends such as AR/VR, AI, and IoT to revolutionize the real estate industry, enhancing operational efficiency and customer experiences.",
    unleashingInnovationList: [
      {
        title: "Augmented and Virtual Reality",
        description:
          "Experience immersive property exploration with virtual tours, 3D visualizations, and interactive floor plans, transforming how agents showcase properties to clients.",
      },
      {
        title: "Artificial Intelligence and Machine Learning",
        description:
          "Harness the power of AI and ML for lead management, property assessments, chatbot support, and data-driven insights, enhancing operational automation and efficiency.",
      },
      {
        title: "The Internet of Things",
        description:
          "Optimize commercial property management with IoT applications for real-time monitoring, system failure management, and energy efficiency improvements.",
      },
    ],
  },
  benefits: {
    title: "Why Idealogic’s Real Estate Software Solutions",
    description:
      "Experience the unique advantages of partnering with Idealogic for your real estate software needs, including innovation, scalability, and enhanced user experiences.",
    buttonText: "Let's Elevate Your Real Estate Business",
    benefitsList: [
      {
        title: "Tailored Innovation and Scalability",
        description:
          "Harness the power of personalized and scalable solutions that combine AR/VR, AI, and IoT technologies to drive innovation and operational efficiency in your real estate business. Our solutions evolve with your needs, ensuring you stay ahead of the competition and achieve your strategic objectives.",
        iconSrc: "/images/industries/custom-real-estate-software-development/custom-real-estate-benefits-icon-1.svg",
      },
      {
        title: "Data-Driven Insights and Enhanced User Experience",
        description:
          "Empower decision-making with advanced analytics and intuitive UX/UI designs that enhance user satisfaction and engagement. Idealogic's solutions provide actionable insights and seamless user experiences, ensuring your customers remain engaged and satisfied throughout their journey.",
        iconSrc: "/images/industries/custom-real-estate-software-development/custom-real-estate-benefits-icon-2.svg",
      },
      {
        title: "Expertise and Customized Development",
        description:
          "Leverage our industry expertise to address specific challenges and objectives within your real estate business. Our custom-developed solutions are tailored to your unique needs, ensuring they align perfectly with your strategic goals and deliver measurable results.",
        iconSrc: "/images/industries/custom-real-estate-software-development/custom-real-estate-benefits-icon-3.svg",
      },
      {
        title: "Seamless Integration and Ongoing Support",
        description:
          "Integrate Idealogic's solutions effortlessly with your current systems and enjoy continuous support and maintenance for optimal performance and minimal disruption. Our dedicated team ensures seamless integration and provides ongoing support to maximize the value of your investment and drive long-term success.",
        iconSrc: "/images/industries/custom-real-estate-software-development/custom-real-estate-benefits-icon-4.svg",
      },
      {
        title: "Cost Efficiency and High ROI",
        description:
          "Achieve cost-effective solutions without compromising quality, unlocking new revenue opportunities, and streamlining operations for maximum ROI. Idealogic's solutions deliver tangible results, helping you reduce costs, increase efficiency, and drive business growth.",
        iconSrc: "/images/industries/custom-real-estate-software-development/custom-real-estate-benefits-icon-5.svg",
      },
      {
        title: "Reputation for Excellence",
        description:
          "Enhance your market position with Idealogic, a leader known for excellence in real estate software. Partnering with us strengthens your brand as an innovative and forward-thinking company, positioning you as a trusted industry leader committed to delivering exceptional value to your customers.",
        iconSrc: "/images/industries/custom-real-estate-software-development/custom-real-estate-benefits-icon-6.svg",
      },
    ],
  },
  bookACallWithExpert: {
    title: {
      beforeKeyWord: "Our Experts Are Happy To Discuss Your Goals & Swiftly Move Towards Execution",
      keyWord: "",
    },
    description: "",
    buttonText: "Book a call",
    buttonVariant: variants.SECONDARY,
    buttonTheme: buttonThemeTypes.DARK,
    linkedin: {
      name: "Oleh Henaliuk",
      href: EXTERNAL_LINKS.linkedInOleh,
    },
    jobTitle: "Deputy CEO",
    imageSrc: "/images/industries/custom-real-estate-software-development/custom-real-estate-expert.webp",
    mobileImageSrc: "/images/industries/custom-real-estate-software-development/custom-real-estate-expert-sm.webp",
    imageAspectRatio: 1.16,
    bgSrc: "/images/industries/aviation/book-a-call-with-expert/book-a-call-with-expert-bg.webp",
    bgMobileSrc: "/images/industries/aviation/book-a-call-with-expert-bg-mobile.webp",
    isShowLinkedInTitle: false,
    isBoxShadow: true,
  },
  ourProcess: {
    title: "Our Process",
    description:
      "Explore our streamlined workflow for real estate software solutions, designed to ensure transparency, efficiency, and exceptional results at every stage of development.",
    benefitsList: [
      {
        title: "Discover & Estimate",
        description:
          "We start by delving into your business requirements, gaining insights into your unique needs, and defining the technical solutions that best align with your goals. You'll receive a detailed plan outlining the scope of work, ensuring clarity and alignment from the outset.",
      },
      {
        title: "Design and Prototype",
        description:
          "Next, we present you with a visual prototype of your future product, showcasing its basic features and functionality. This stage allows for feedback and adjustments, ensuring the final product meets your exact specifications and vision.",
      },
      {
        title: "Development",
        description:
          "Our team of industry specialists utilizes cutting-edge technologies to bring your vision to life. With expertise in real estate software development, we ensure the technical implementation of your project is executed flawlessly, meeting the highest standards of quality and performance.",
      },
      {
        title: "Testing",
        description:
          "Quality assurance is paramount to us. We conduct rigorous testing, both automated and manual, to ensure every feature works seamlessly. By identifying and resolving any issues early on, we optimize the product's performance and functionality.",
      },
      {
        title: "Deployment",
        description:
          "Once we're satisfied with the product's usability, security compliance, and reliability, we're ready to release it to market. Our deployment process is meticulous, ensuring a smooth transition and seamless integration with your existing systems.",
      },
      {
        title: "Maintenance",
        description:
          "Our commitment to your success doesn't end at launch. We continue to monitor and maintain the product's performance post-launch, proactively addressing any technical issues that may arise. With ongoing support and maintenance, we ensure your solution remains robust, reliable, and future-proof.",
      },
    ],
    buttonText: "Schedule a Call",
    isBackgroundImageNeed: false,
    isNeedCurveLine: false,
    isBlackStyleForButton: true,
    isButtonPositionStart: true,
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.eState, CaseStudiesSlugEnums.secondFloor],
    title: "Real Estate Case Studies",
    TitleIcon: WavingHandIcon,
    description:
      "Explore how our innovative solutions have transformed the real estate industry, from mobile rental apps to tokenized platforms.",
  },
  technologiesWeUse: {
    title: "Technologies We Use",
    description:
      "Empower your real estate business with cutting-edge technologies carefully selected to drive innovation and efficiency.",
    buttonVariant: variants.SECONDARY,
    buttonBgColor: "bgDark",
  },
  ourCustomerReview: {
    title: "Our customer review",
    description: "We've worked with startups and enterprises – look what they have to say about us!",
    bgImageSrc: "/images/industries/aviation/our-customer-review/our-customer-review-bg.webp",
    linkedInLink: EXTERNAL_LINKS.linkedInMatthew,
    bgGradient: "bannerYellowGradient",
    isTextClamp: true,
    owner: {
      name: "Matthew Schneider",
      jobTitle: "CEO, Co-founder of e-States",
      reviewText:
        "“We’ve been working with Idealogic for about five contracts now over the course of 6 to 8 months, and we are absolutely satisfied, above satisfied — we are thrilled to have a team that is this competent and communicative and just a joy to work with for multiple reasons…”",
      videoSrc: EXTERNAL_LINKS.youtubeMatthewReview,
      videoInfoImg: "/images/testimonial/full-matthew-schneider-hovered.webp",
      logo: "/images/industries/custom-real-estate-software-development/e-states-logo.svg",
      logoAspectRatio: 3.22,
      logoSize: "10.25rem",
    },
  },
};

export default CUSTOM_REAL_ESTATE_CONTENT;
