import styled from "styled-components";

export const StyledTabPanelWrapper = styled.div`
  display: flex;
  gap: 1rem;

  width: 100%;
  min-height: 5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    min-height: 9rem;
    margin-bottom: 1.5rem;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;
