import { makeApiRequest } from "./make-api-request";
// Constants
import { ENDPOINTS } from "./endpoints";
// Types
import { GetArticlePayload, GetArticleResponse, GetSearchResultResponse } from "./types";

export const submitGetFeedback = async (data: FormData) => {
  const result = await makeApiRequest<FormData>({
    method: "POST",
    url: ENDPOINTS.submit,
    data,
    timeout: 600000,
  });

  return result;
};

export const getSearchResult = async (searchValue: string) => {
  const result = await makeApiRequest<GetSearchResultResponse>({
    method: "POST",
    url: ENDPOINTS.getSearchResult,
    data: {
      user_request: searchValue,
      source: "development",
    },
    timeout: 40000,
  });

  return result;
};

export const getArticle = async (data: GetArticlePayload) => {
  const result = await makeApiRequest<GetArticleResponse>({
    method: "POST",
    url: data.user_choice ? ENDPOINTS.getArticle : ENDPOINTS.getArticleQuick,
    data: {
      ...data,
      source: "development",
    },
    timeout: 40000,
  });

  return result;
};
