import React, { createContext, useState, useContext, PropsWithChildren } from "react";

import { VideoPlayerContextData } from "./types";

export const VideoPlayerContext = createContext<VideoPlayerContextData | null>(null);

export const VideoPlayerProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [isVideoPlayerOpen, setIsVideoPlayerOpen] = React.useState(false);
  const [videoSource, setVideoSource] = useState<string[]>([]);

  const showVideoPlayer = (videoSources: string[]) => {
    if (videoSources.length) {
      setIsVideoPlayerOpen(prevOpen => !prevOpen);
      setVideoSource(videoSources);
    }
  };

  return (
    <VideoPlayerContext.Provider value={{ isVideoPlayerOpen, videoSource, showVideoPlayer }}>
      {children}
    </VideoPlayerContext.Provider>
  );
};

export const useVideoPlayerContext = () => {
  const context = useContext(VideoPlayerContext);

  if (!context) {
    throw new Error("Video player context is not found");
  }

  return context;
};
