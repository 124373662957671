// Components + styling
import { FlexGap, Text } from "components";
import { StyledFooterLink } from "components/footer/styled";
import { StyledCopyrightsWrapper, StyledLinkWrapper } from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Context
import { useTranslation } from "context";
// Constants
import { ROUTES } from "navigation/routes";
// TODO: uncomment LanguageSelector when we will used translations
// import { LanguageSelector } from "components/header/components";

const FooterCopyrights: React.FC = () => {
  const { t } = useTranslation();

  const { isMobile } = useMatchBreakpoints();

  const year = new Date().getFullYear();

  return (
    <StyledCopyrightsWrapper>
      <StyledLinkWrapper>
        <FlexGap gap={isMobile ? "1rem" : "2.5rem"}>
          <StyledFooterLink color="textFourthForDarkBg" href={`/${ROUTES.privacyPolicy}`} mx="0.5rem">
            {t("Privacy Policy")}
          </StyledFooterLink>

          {/* TODO: uncomment LanguageSelector when we will used translations */}
          {/* <LanguageSelector tooltipOffsetPosition={[0, 20]} color={"textFourthForDarkBg"} /> */}

          <StyledFooterLink color="textFourthForDarkBg" href={`/${ROUTES.sitemap}`} mx="0.5rem">
            {t("Sitemap")}
          </StyledFooterLink>

          {/* TODO: uncomment terms when Terms & Conditions page will */}
          {/* <StyledFooterLink color="textFourthForDarkBg" href={`/${ROUTES.termsAndConditions}`} mx="0.5rem">
            {t("Terms & Conditions")}
          </StyledFooterLink> */}
        </FlexGap>
      </StyledLinkWrapper>
      <Text textScale="p12Regular" color="textFourthForDarkBg">
        {t("© %year% Idealogic All rights reserved.", { year })}
      </Text>
    </StyledCopyrightsWrapper>
  );
};

export default FooterCopyrights;
