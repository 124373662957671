import { useTranslation } from "context";

import FooterItemSection from "../footer-item-section";
import FooterContentItem from "../footer-content-item";
import { Text } from "components";
import { SmallArrowRight } from "components/svg";
import { StyledOurCompanyFooterLink, StyledOurCompanyLinksContainer } from "./styled";

import {
  FOOTER_INDUSTRIES_LIST,
  FOOTER_EXPERTISES_LIST,
  FOOTER_OUR_COMPANY_LIST,
  FOOTER_SERVICES_LIST,
} from "../../constants";
import { ROUTES } from "navigation/routes";

const FooterNavigationSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <FooterItemSection title="Industries">
        <FooterContentItem navList={FOOTER_INDUSTRIES_LIST} />
      </FooterItemSection>

      <FooterItemSection title="Services">
        <>
          <FooterContentItem label="Software engineering" navList={FOOTER_EXPERTISES_LIST} />

          <FooterContentItem label="Startup & Scaling Companies" navList={FOOTER_SERVICES_LIST} />
        </>
      </FooterItemSection>

      <FooterItemSection title="Our company">
        <>
          <FooterContentItem navList={FOOTER_OUR_COMPANY_LIST} />

          <StyledOurCompanyLinksContainer>
            <StyledOurCompanyFooterLink href={`/${ROUTES.caseStudies}`} mb="2rem" external>
              <Text textScale="p18Medium" color="white">
                {t("Case studies")}
              </Text>

              <SmallArrowRight width="1.0625rem" height="1rem" mx="0.25rem" mt="0.5rem" />
            </StyledOurCompanyFooterLink>

            <StyledOurCompanyFooterLink href={`/${ROUTES.blog}`} external>
              <Text textScale="p18Medium" color="white">
                {t("Blog")}
              </Text>

              <SmallArrowRight width="1.0625rem" height="1rem" mx="0.25rem" mt="0.5rem" />
            </StyledOurCompanyFooterLink>
          </StyledOurCompanyLinksContainer>
        </>
      </FooterItemSection>
    </>
  );
};

export default FooterNavigationSection;
