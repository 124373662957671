import styled from "styled-components";
import Slider from "react-slick";
import { Container } from "components/layout";

export const StyledOurTeamSlider = styled(Slider)``;

export const StyledOurTeamContainer = styled(Container)`
  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  ${({ theme }) => theme.mediaQueries.laptopL} {
    padding-left: 3rem;
    padding-right: 3rem;

    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 1600px) {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`;
