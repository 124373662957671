import styled, { css } from "styled-components";
import { layout } from "styled-system";

import { Text } from "components/text";

import { linesMovingFromDifferentDirectionsAnimation } from "theme/animations";
// Constants

export const StyledDropdownItem = styled.div<{ isDisabled: boolean }>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  padding: 0 1.5rem;

  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      position: relative;

      p {
        &::before {
          content: "";
          clear: both;
          width: 100%;
          height: 0.5px;
          position: absolute;
          top: 100%;
          left: 0;
          transition:
            clip-path 0.3s,
            transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
          clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%);
        }
      }

      &:hover {
        p {
          background-color: ${({ theme }) => theme.colors.accentYellow};
          background-image: ${({ theme }) => theme.gradients.buttonAccentHover};
          background-size: 100%;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: transparent;
          -moz-text-fill-color: transparent;

          &::before {
            background: ${({ theme }) => theme.gradients.buttonAccentHover};
            clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 50% 100%, 0 100%, 100% 100%, 100% 0%);
            ${linesMovingFromDifferentDirectionsAnimation}
          }

          width: initial;
          background-image: ${({ theme }) => theme.gradients.blogShowMoreGradient};

          padding: 0;

          transition: ${({ theme }) => theme.transitions.cubic_bezier_0_3s};
          transform: translate3d(0, 0, 0);
        }
      }
    `}
  ${({ theme }) => theme.mediaQueries.laptopL} {
    padding: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    padding: 0 1.5rem;
  }

  ${layout}
`;

export const StyledComingSoonText = styled(Text)`
  padding: 0.125rem 0.625rem;
  border-radius: ${({ theme }) => theme.radii.semiLarge};
  border: 1px solid ${({ theme }) => theme.colors.disabledTextDark};
`;

export const StyledComingSoonContainer = styled.div`
  max-height: 1.5rem;
  min-width: 3.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.textFourth};
  border-radius: 20px;
  margin: 0 0.5rem;
`;
