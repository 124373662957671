import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useMotionValueEvent, useScroll } from "framer-motion";
// Utils
import { getPathnameFromPath, removeTrailingSlashIfExists } from "utils";
// Constants
import {
  LIGHT_HEADER_THEME_PATHNAME_LIST,
  TRANSPARENT_HEADER_THEME_PATHNAME_LIST,
  headerThemes,
  headerThemesType,
} from "../constants";
import { ROUTES } from "navigation/routes";
// Base
import { breakpointMap } from "theme/base";

export const useHeaderTheme = () => {
  const { pathname } = useLocation();
  const { positionId, slug, caseStudy } = useParams();
  const [headerTheme, setHeaderTheme] = React.useState<headerThemesType>(headerThemes.dark);

  const { scrollY } = useScroll();

  const isPathInLightThemePathList = LIGHT_HEADER_THEME_PATHNAME_LIST.includes(removeTrailingSlashIfExists(pathname));
  const isUserOnIdPage = !!slug || !!positionId;
  const isLanding = pathname === "/";
  const pathType = getPathnameFromPath(pathname);

  const isTransparent =
    !!pathType ||
    [...TRANSPARENT_HEADER_THEME_PATHNAME_LIST, `/${ROUTES.caseStudies}/${caseStudy}`].includes(
      removeTrailingSlashIfExists(pathname),
    ) ||
    isLanding;

  const handleChangeTheme = (latest: number) => {
    if (window.innerWidth < breakpointMap.laptop) {
      setHeaderTheme(headerThemes.dark);
      return;
    }

    if (isTransparent || isLanding) {
      if (latest) {
        setHeaderTheme(headerThemes.dark);
      } else {
        setHeaderTheme(headerThemes.transparent);
      }
    }

    if (isPathInLightThemePathList || isUserOnIdPage) {
      if (latest) {
        setHeaderTheme(headerThemes.dark);
      } else {
        setHeaderTheme(headerThemes.light);
      }
    }
  };

  useMotionValueEvent(scrollY, "change", handleChangeTheme);

  React.useEffect(() => {
    if (window.innerWidth < breakpointMap.laptop) {
      setHeaderTheme(headerThemes.dark);
      return;
    }

    if (isPathInLightThemePathList || isUserOnIdPage) {
      setHeaderTheme(headerThemes.light);
    } else if (isTransparent) {
      setHeaderTheme(headerThemes.transparent);
    } else {
      setHeaderTheme(headerThemes.dark);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [pathname]);

  const handleResize = () => {
    handleChangeTheme(window.scrollY);
  };

  return { headerTheme, isPathInLightThemePathList, isUserOnIdPage, isTransparent };
};
