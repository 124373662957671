import React from "react";
// Components + styling
import { Heading, Text, TickerLink } from "components";
import { CoveredIndustriesParallaxImage } from "./components";
import { StyledCoveredIndustriesContainer } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { CoveredIndustriesProps } from "./types";

const CoveredIndustries: React.FC<CoveredIndustriesProps> = ({
  title = "We are open to various industries",
  subTitle,
  coveredIndustriesList,
  isNeedParallaxImage,
  blur,
}) => {
  const { t } = useTranslation();

  const { isDesktop } = useMatchBreakpoints();

  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <div ref={wrapperRef}>
      <StyledCoveredIndustriesContainer
        px={{ _: "1rem", tablet: "2rem", laptopL: "4.5rem" }}
        pt={{ _: "2rem", laptop: "6.875rem" }}
        pb={{ _: "4rem", laptop: "4.625rem" }}
        blur={blur}
      >
        {isDesktop && isNeedParallaxImage && <CoveredIndustriesParallaxImage ref={wrapperRef} />}

        <div>
          <Heading scale="h2" as="h2" mb={{ _: "1.5rem", laptop: subTitle ? "1rem" : "4.5rem" }}>
            {t(title)}
          </Heading>
          <Text
            maxWidth="45.625rem"
            mb={{ _: "1.5rem", tablet: "4rem" }}
            textScale={isDesktop ? "p22Regular" : "p16Regular"}
          >
            {subTitle}
          </Text>
        </div>

        {coveredIndustriesList.map(industry => (
          <TickerLink key={industry.label} {...industry} />
        ))}
      </StyledCoveredIndustriesContainer>
    </div>
  );
};

export default CoveredIndustries;
