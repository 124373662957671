export const ROUTES = {
  aboutUs: "about-us",
  blog: "blog",
  notFoundPage: "/404",
  careers: "careers",
  caseStudies: "case-studies",
  expertise: "expertise",
  findSolution: "find-solution",
  healthLife: "health-life",
  industries: "industries",
  landing: "/",
  calendly: "calendly",
  nextparq: "next-parq",
  pikkUp: "pikk-up",
  privacyPolicy: "privacy-policy",
  services: "services",
  sitemap: "sitemap",
  ubeat: "ubeat",
  // industries routes
  aiBasedAviation: "ai-based-aviation",
  fintech: "fintech",
  banking: "banking",
  cryptoCurrency: "crypto-currency",
  blockchainBasedAviation: "blockchain-based-aviation",
  digitalBanking: "digital-banking",
  intelligentInventoryManagement: "intelligent-inventory-management",
  logistics: "logistics",
  aviation: "aviation",
  realEstate: "realEstate",
  customRealEstate: "custom-real-estate",
  propertyManagementSolutions: "property-management-solutions",
  agentBrokersSolutions: "agent-brokers-solutions",
  sparePartsMarketplace: "spare-parts-marketplace",
  media: "media",
  other: "other",
  // services routes
  technicalConsulting: "technical-consulting",
  mvpDevelopment: "mvp-development",
  maintenanceSupport: "maintenance-support",
  dedicatedTeam: "dedicated-team",
  webDevelopment: "web-development",
  mobileDevelopment: "mobile-development",
  uiUxDesign: "ui-ux-design",
  aiMlSolutions: "ai-ml-solutions",
  blockchain: "blockchain",
  bigData: "big-data",
  cloudSolutions: "cloud-solutions",
  mlDevelopment: "machine-learning-development",
  aiSoftwareDevelopment: "ai-software-development",
  dataScience: "data-science",
  aiMobileDevelopment: "ai-mobile-development",
  aiConsulting: "ai-consulting",
  mlConsulting: "machine-learning-consulting",
  customerExperienceConsulting: "customer-experience-consulting",
};

export const ROUTE_PARAMS = {
  careersType: "careersType",
  industryType: "industryType",
  serviceType: "serviceType",
  slug: "slug",
  caseStudy: "caseStudy",
};

export const QUERY_PARAMS = {
  page: "page",
};
