import styled from "styled-components";
// Components
import { Link } from "components";

export const StyledCompanyRateItemWrapper = styled(Link)<{ isWhite?: boolean }>`
  width: 100%;
  position: relative;
  justify-content: center;
  padding: 0.75rem;
  max-height: 3.125rem;
  margin: 0 0 1rem;

  border-radius: ${({ theme }) => theme.radii.semiLarge};

  -webkit-text-fill-color: ${({ theme, isWhite }) => (isWhite ? `${theme.colors.textMain}` : `${theme.colors.white}`)};

  ${({ theme }) => theme.mediaQueries.mobileL} {
    width: calc(50% - 0.25rem);
    margin: 0;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: auto;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    border: none;
    background: none;
    max-height: 100%;
    margin: 0 4rem;
    &:nth-of-type(1) {
      margin: 0;
    }

    &::before {
      display: none;
    }
  }
`;
