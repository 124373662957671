import { ROUTES } from "navigation/routes";
// Types
import { IndustriesVariants } from "pages/industries/types";
import { DropdownNavSection } from "../components/dropdown-menu-items/types";

export const INDUSTRIES_DROPDOWN_LIST: DropdownNavSection[] = [
  {
    title: "Finance",
    titleLink: `/${ROUTES.industries}/${IndustriesVariants.fintech}`,
    navItems: [
      {
        title: "Financial software development",
        link: `/${ROUTES.industries}/${IndustriesVariants.fintech}`,
      },
      {
        title: "Banking software development",
        link: `/${ROUTES.industries}/${IndustriesVariants.banking}`,
      },
      {
        title: "Digital banking software development",
        link: `/${ROUTES.industries}/${IndustriesVariants.digitalBanking}`,
      },
      {
        title: "Cryptocurrency development",
        link: `/${ROUTES.industries}/${IndustriesVariants.cryptoCurrency}`,
      },
    ],
  },
  {
    title: "Logistics",
    titleLink: `/${ROUTES.industries}/${IndustriesVariants.logistics}`,
    navItems: [
      {
        title: "AI-powered Supply Chain development",
        link: "",
      },
      { title: "Supply Chain Management", link: "" },
      { title: "Warehouse Automation", link: "" },
      { title: "Tracking Solutions", link: "" },
      { title: "Analytics & Reporting Tools", link: "" },
    ],
  },
  { title: "Healthcare", titleLink: "", navItems: [] },
  {
    title: "Aviation",
    titleLink: `/${ROUTES.industries}/${IndustriesVariants.aviation}`,
    navItems: [
      {
        title: "Spare parts marketplace",
        link: `/${ROUTES.industries}/${IndustriesVariants.sparePartsMarketplace}`,
      },
      {
        title: "Blockchain-based aviation supply chain",
        link: `/${ROUTES.industries}/${IndustriesVariants.blockchainBasedAviation}`,
      },
      {
        title: "Intelligent Inventory Management Solutions",
        link: `/${ROUTES.industries}/${IndustriesVariants.intelligentInventoryManagement}`,
      },
      {
        title: "AI-based Customer Support and Engagement Solutions",
        link: `/${ROUTES.industries}/${IndustriesVariants.aiBasedAviation}`,
      },
    ],
  },
  {
    title: "Real estate",
    titleLink: `/${ROUTES.industries}/${IndustriesVariants.realEstate}`,
    navItems: [
      {
        title: "Real Estate Software Development",
        link: `/${ROUTES.industries}/${IndustriesVariants.customRealEstate}`,
      },
      {
        title: "Property Management Software Development",
        link: `/${ROUTES.industries}/${IndustriesVariants.propertyManagementSolutions}`,
      },
      {
        title: "Agents & Brokers Tech Solutions",
        link: `/${ROUTES.industries}/${IndustriesVariants.agentBrokersSolutions}`,
      },
      {
        title: "Real Estate Investment Solutions",
        link: "",
      },
      {
        title: "Software for Homebuyers & Renters",
        link: "",
      },
    ],
  },
  { title: "Sport & Fitness", titleLink: "", navItems: [] },
  { title: "Gaming", titleLink: "", navItems: [] },
  {
    title: "Media",
    titleLink: `/${ROUTES.industries}/${IndustriesVariants.media}`,
    navItems: [
      {
        title: "Streaming Platform Solutions",
        link: "",
      },
      {
        title: "Content Management System",
        link: "",
      },
      {
        title: "Ticketing Software Development",
        link: "",
      },
      {
        title: "Social Network Development",
        link: "",
      },
      {
        title: "AI-powered Media Solutions",
        link: "",
      },
    ],
  },
  { title: "Other", titleLink: `/${ROUTES.industries}/${IndustriesVariants.other}`, navItems: [] },
];
