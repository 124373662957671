// Utils
import { lazyLoad } from "utils";

export { HealthLifePage, NextparqPage, UbeatPage, PikkUpPage } from "./case-pages/components";

export const LandingPage = lazyLoad(
  () => import("./landing"),
  module => module.default,
);

export const SingleCaseStudy = lazyLoad(
  () => import("./single-case-study"),
  module => module.default,
);

export const PrivacyPolicyPage = lazyLoad(
  () => import("./privacy-policy"),
  module => module.default,
);

export const AboutUsPage = lazyLoad(
  () => import("./about-us"),
  module => module.default,
);

export const BlogsPage = lazyLoad(
  () => import("./blogs"),
  module => module.default,
);

export const CaseStudiesPage = lazyLoad(
  () => import("./case-studies"),
  module => module.default,
);

export const CareersPage = lazyLoad(
  () => import("./careers"),
  module => module.default,
);

export const IndustriesOutlet = lazyLoad(
  () => import("./industries"),
  module => module.default,
);

export const ServicesOutlet = lazyLoad(
  () => import("./services"),
  module => module.default,
);

export const SingleBlogPage = lazyLoad(
  () => import("./single-blog"),
  module => module.default,
);

export const SitemapPage = lazyLoad(
  () => import("./sitemap"),
  module => module.default,
);

export const FindSolutionPage = lazyLoad(
  () => import("./find-solution"),
  module => module.default,
);

export const CasePages = lazyLoad(
  () => import("./case-pages"),
  module => module.default,
);

export const PositionPage = lazyLoad(
  () => import("./position"),
  module => module.default,
);

export const CalendlyPage = lazyLoad(
  () => import("./calendly"),
  module => module.default,
);
