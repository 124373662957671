// Components + styling
import { GifWithDuration, Image, RatingStars, Text } from "components";
import {
  StyledRatingStars,
  StyledRatingCompanyWrapper,
  StyledTestimonialItemWrapper,
  StyledTestimonialTextWrapper,
  StyledTestimonialImageWrapper,
} from "./styled";
// Context
import { useTranslation, useVideoPlayerContext } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { TestimonialItemProps } from "./types";

const TestimonialItem: React.FC<TestimonialItemProps> = ({
  name,
  review,
  rating,
  position,
  avatarSrc,
  gifDuration,
  videoSources = [],
  RatingCompanyIcon,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const { showVideoPlayer } = useVideoPlayerContext();

  return (
    <StyledTestimonialItemWrapper>
      <div>
        <StyledTestimonialImageWrapper>
          {gifDuration ? (
            <>
              <GifWithDuration
                width={isDesktop ? "3.75rem" : "3.125rem"}
                gifSrc={avatarSrc}
                gifRatio={1}
                duration={gifDuration}
                handleClickGif={() => showVideoPlayer(videoSources)}
              />
            </>
          ) : (
            <Image width={isDesktop ? "3.75rem" : "3.125rem"} src={avatarSrc} aspectRatio={1} variant="circle" />
          )}
          <StyledTestimonialTextWrapper>
            <Text textScale={isDesktop ? "p20Bold" : "p16Bold"}>{t(name)}</Text>
            <Text textScale={isDesktop ? "p14Regular" : "p12Regular"}>{t(position)}</Text>
          </StyledTestimonialTextWrapper>
        </StyledTestimonialImageWrapper>
        <Text textScale={isDesktop ? "p16Medium" : "p14Regular"} mb={{ _: "1rem", laptop: "1.5rem" }}>
          {t(review)}
        </Text>
      </div>
      <StyledRatingCompanyWrapper>
        <RatingCompanyIcon width="50%" height="1.375rem" color="textFourth" />
        <StyledRatingStars>
          <RatingStars rating={rating} width="1.25rem" />
        </StyledRatingStars>
      </StyledRatingCompanyWrapper>
    </StyledTestimonialItemWrapper>
  );
};

export default TestimonialItem;
