// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { ROUTES } from "navigation/routes";
import { EXTERNAL_LINKS } from "configs";
import {
  INTELLIGENT_INVENTORY_MANAGEMENT_ACCORDION_CONTENT_LIST,
  INTELLIGENT_INVENTORY_MANAGEMENT_BOTTOM_SERVICES_LIST,
} from "../components/industry-info/constants";
// Types
import { ServicesVariants } from "pages/services/types";
import { IndustryPageContent } from "../types";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";

export const INTELLIGENT_INVENTORY_MANAGEMENT_BENEFITS_LIST = [
  {
    title: "Blockchain Software Services",
    description:
      "Enhance security and transparency in inventory management with blockchain solutions. Leverage distributed ledger technology to track and trace inventory in real time, ensuring data integrity and trust across your supply chain.",
    linkForButton: `/${ROUTES.services}/${ServicesVariants.blockchain}`,
  },
  {
    title: "AI Software Development",
    description:
      "Harness the power of AI-driven software solutions to optimize inventory management processes. From demand forecasting to predictive analytics, our AI software development services will empower you to make smarter decisions and drive business growth.",
    linkForButton: `/${ROUTES.services}/${ServicesVariants.aiSoftwareDevelopment}`,
  },
  {
    title: "Machine Learning Development",
    description:
      "Utilize machine learning algorithms to gain valuable insights from your inventory data. Predict future demand, identify trends, and optimize inventory levels with precision, ensuring maximum efficiency and profitability.",
    linkForButton: `/${ROUTES.services}/${ServicesVariants.mlDevelopment}`,
  },
];

const INTELLIGENT_INVENTORY_MANAGEMENT_CONTENT: IndustryPageContent = {
  banner: {
    title: "Intelligent Inventory Management Solutions",
    description:
      "Revolutionize your inventory management with Idealogic's cutting-edge solutions, harnessing smart algorithms for warehouse and PAR-level optimization. Our tailored approach allows you to configure minimum stock thresholds, automate replenishment requests through online marketplaces, and visualize data with bespoke BI tools. Make informed decisions in minutes, not weeks, and stay ahead of the curve in today's dynamic business landscape.",
    imageSrc: "/images/industries/intelligent-inventory-management/intelligent-inventory-management-banner-img.webp",
    imageRatio: 1.75,
    buttonLabel: "Get Your Free Estimation Today",
    maxWidthForButtonRem: 20.75,
  },
  stagesBlocks: [
    {
      title: "Aviation Companies/Airlines",
      description:
        "Optimize your operations, streamline inventory management, and drive down operational costs with our advanced analytics and automation solutions. From optimizing flight schedules to managing spare parts inventory, our tailored approach ensures maximum efficiency and cost savings.",
      iconSrc: "/images/industries/intelligent-inventory-management/stages-block/aviation-company-icon.svg",
    },
    {
      title: "Online Aviation Marketplaces",
      description:
        "Gain unparalleled visibility and control over your inventory to enhance customer satisfaction and retention. Our real-time analytics and predictive stock management tools empower you to anticipate demand, minimize stockouts, and maximize revenue opportunities.",
      iconSrc: "/images/industries/intelligent-inventory-management/stages-block/online-aviation.svg",
    },
    {
      title: "Spare Parts Trading Companies",
      description:
        "Automate procurement processes, ensure accuracy in stock levels, and expedite transactions with our tailored solutions. Whether you're managing a large inventory of spare parts or facilitating transactions between buyers and sellers, Idealogic has you covered.",
      iconSrc: "/images/industries/intelligent-inventory-management/stages-block/spare-parts.svg",
    },
  ],
  ourCapabilities: {
    title: "Our Capabilities",
    description:
      "Explore Idealogic's comprehensive suite of inventory management solutions designed to optimize your operations and drive business growth.",
    buttonText: "Talk To Our Experts",
    accordionContent: INTELLIGENT_INVENTORY_MANAGEMENT_ACCORDION_CONTENT_LIST,
  },
  ourServicesFintech: {
    title: "Our Services for Aviation",
    description:
      "Unlock the full potential of your business with Idealogic's range of advanced software solutions tailored specifically for the aviation industry.",
    topServicesList: [],
    isEstimationButton: true,
    buttonLabel: "Learn More",
    bottomServicesList: INTELLIGENT_INVENTORY_MANAGEMENT_BOTTOM_SERVICES_LIST,
  },
  bookACallWithExpert: {
    bgSrc: "/images/industries/aviation/book-a-call-with-expert/book-a-call-with-expert-bg.webp",
    imageAspectRatio: 1.15,
    bgMobileSrc: "/images/industries/aviation/book-a-call-with-expert-bg-mobile.webp",
    title: {
      beforeKeyWord: "",
      keyWord: "Our Experts",
      afterKeyWord: "Are Happy To Discuss Your Goals & Swiftly Move Towards Execution",
    },
    description: "",
    linkedin: {
      name: "Oleh Henaliuk",
      href: EXTERNAL_LINKS.linkedInOleh,
    },
    jobTitle: "Deputy CEO",
    buttonText: "Book a call",
    imageSrc: "/images/industries/aviation/book-a-call-with-expert/book-a-call-with-expert-oleh-bg.webp",
    mobileImageSrc: "/images/industries/aviation/book-a-call-with-expert/book-a-call-with-expert-oleh-mobile-bg.webp",
    isBoxShadow: true,
    isShowLinkedInTitle: false,
  },
  boostBusiness: {
    title: "White-Label Business Intelligence Solution",
    description:
      "Make data-driven decisions with confidence using our white-label business intelligence solution. Access ready-to-go data visualization tools tailored to your business, empowering you to unlock valuable insights and drive strategic growth.",
    buttonTitle: "Talk To Us",
    personImageSrc: "/images/industries/intelligent-inventory-management/white-label/white-label-image.webp",
    personImageSrcMobile:
      "/images/industries/intelligent-inventory-management/white-label/white-label-image-mobile.webp",
    imageRatio: 1.06,
    bgColor: "bgThird",
    isWhite: true,
    imageBlurColor: "blueBlur",
    titleWidth: "60%",
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.airpartChain, CaseStudiesSlugEnums.eplane],
    title: "Aviation Case Studies",
    TitleIcon: WavingHandIcon,
    description:
      "Explore our impactful case studies showcasing innovative aviation software solutions tailored for aircraft maintenance and aerospace trading",
  },
  benefits: {
    title: "New Technologies in Inventory Management",
    description:
      "Stay ahead of the competition with Idealogic's adoption of the latest industry trends and technologies in inventory management.",
    benefitsList: INTELLIGENT_INVENTORY_MANAGEMENT_BENEFITS_LIST,
    isBackgroundImageNeed: false,
    isNeedCurveLine: false,
    buttonText: "Let’s Talk",
    isButtonPositionStart: true,
  },
  ourCustomerReview: {
    title: "Hear From Our Customers",
    description: "Explore feedback from startups and enterprises who have partnered with us.",
    bgImageSrc: "/images/industries/aviation/our-customer-review/our-customer-review-bg.webp",
    linkedInLink: EXTERNAL_LINKS.linkedInAngel,
    bgGradient: "customerReviewAngelGradient",
    isTextClamp: false,
    owner: {
      name: "Angel Marinov",
      logo: "/images/industries/aviation/our-customer-review/eplane-ai-logo.svg",
      logoAspectRatio: 6.25,
      logoSize: "11.625rem",
      jobTitle: "Head of Innovation at ePlaneAI",
      image: "/images/industries/aviation/our-customer-review/our-customer-review-angel-marinov.webp",
      imageAspectRatio: 1,
      reviewText:
        "“Idealogic delivered on-time updates on the app's progress weekly and provided prompt solutions for new requirements. The team showed impressive commitment to the project's success and was helpful in aspects beyond technical. They were deeply interested in the product from the beginning.”",
    },
  },
};

export default INTELLIGENT_INVENTORY_MANAGEMENT_CONTENT;
