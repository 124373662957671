// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 40 40" {...props} color="transparent">
      <rect width="40" height="40" rx="20" fill={theme.colors.bgSecond} />
      <path
        d="M12 16.6667H15.3333V28H12V16.6667ZM13.6567 15.3333H13.638C12.6433 15.3333 12 14.592 12 13.666C12 12.72 12.6633 12 13.676 12C14.69 12 15.3147 12.72 15.3333 13.666C15.3333 14.5913 14.69 15.3333 13.6567 15.3333ZM28 28H24.6667V21.934C24.6667 20.4687 23.85 19.4687 22.5387 19.4687C21.538 19.4687 20.9967 20.1433 20.734 20.7953C20.638 21.0287 20.6667 21.674 20.6667 22V28H17.3333V16.6667H20.6667V18.4107C21.1473 17.6667 21.9 16.6667 23.8253 16.6667C26.2107 16.6667 27.9993 18.1667 27.9993 21.516L28 28Z"
        fill={theme.colors[color]}
      />
      <defs>
        <linearGradient id="hoveredAboutUs" x1="40" y1="0" x2="-5.25074" y2="32.7436" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFDC4C" />
          <stop offset="1" stopColor="#FFAC4C" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
