import styled, { css } from "styled-components";
// Components
import Link from "components/link";
import { Svg } from "components/svg";

export const StyledSocialsItem = styled(Link)`
  -webkit-text-fill-color: ${({ theme }) => theme.colors.white};

  width: 8.1875rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: fit-content;

    &:hover {
      -webkit-text-fill-color: transparent;

      ${Svg} {
        ${css`
          rect {
            fill: url("#hoveredAboutUs");
          }
        `}
      }
    }
  }
`;
