// Constants
import { breakpointMap } from "theme/base";
// Types
import { Settings } from "react-slick";

export const sliderSettings: Settings = {
  dots: false,
  arrows: false,
  swipeToSlide: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: breakpointMap.laptop - 1, // Screen width 1024px and below
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: breakpointMap.tablet - 1, // Screen width 768px and below
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
