// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { EXTERNAL_LINKS } from "configs";
// Types
import { ServicesPageContent } from "../types";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { NeedsItemProps } from "../components/needs/components/needs-item/types";
import { ServicesListItemType } from "pages/industries/components/our-services-fintech/types";
import { BenefitsItemProps } from "../components/benefits/components/benefits-item/types";

const NEEDS_LIST_MVP_DEVELOPMENT_CONTENT: NeedsItemProps[] = [
  {
    label: "Try ideas with less risk",
    description:
      "We help you assess your idea’s technical feasibility and complexity so you don’t spend time and resources before you know it’ll work. Plus, you can gather feedback from your target audience to make your product engaging and profitable if you go full custom development.",
    iconSrc: "/images/mvp-development-needs-icon-3.svg",
  },
  {
    label: "Get your application to market faster",
    description:
      "You’ll win the time for refinements and updates if you gain factual data proof that your idea works. A few distinctive features and essential functionalities will help you validate your concept and then continue with product development, all before anyone comes up with the same idea.",
    iconSrc: "/images/mvp-development-needs-icon-1.svg",
  },
  {
    label: "Outrun competitors",
    description:
      "Make your product special and unique in your niche with MVP development. It entails an iterative approach so you can adapt your project to the customers’ preferences, ensuring their loyalty. Customer satisfaction gives the business an advantage over competitors and sustained growth.",
    iconSrc: "/images/mvp-development-needs-icon-2.svg",
  },
];

export const MVP_DEVELOPMENT_TOP_SERVICES_LIST: ServicesListItemType[] = [
  {
    title: "Empathize and Define",
    description:
      "For the comprehensive MVP development services we provide, we need to start with market research and competitor analysis to define the target audience's actual needs and address them with our custom solution accordingly.",
    gradient: "ourServicesCardGradient1",
    width: "50%",
    height: "17.875rem",
  },
  {
    title: "Custom Hyperledger Development",
    description:
      "Leverage the robustness of open-source blockchain with our custom Hyperledger development services. Our developers craft secure, standardized distributed ledgers with open protocols tailored to your specific business needs.",
    gradient: "ourServicesCardGradient2",
    width: "50%",
    height: "17.875rem",
  },
];

export const MVP_DEVELOPMENT_BOTTOM_SERVICES_LIST: ServicesListItemType[] = [
  {
    title: "Building the Prototype",
    description:
      "Our approach is rapid product prototyping to showcase the MVP’s essential functions. Having the prototype allows for initial testing and experience of the product's core capabilities and feedback from stakeholders and users.",
    gradient: "ourServicesCardGradient3",
    width: "calc(33% - 0.5rem)",
    height: "22.875rem",
  },
  {
    title: "Developing the MVP",
    description:
      "Employing the agile MVP creation process, we build your MVP with an emphasis on flexibility and rapid iteration. This approach allows for changes based on early user feedback and market responses, ensuring the product remains relevant and user-focused.",
    gradient: "ourServicesCardGradient4",
    width: "calc(33% - 0.5rem)",
    height: "22.875rem",
  },
  {
    title: "Enhancing to Full Product",
    description:
      "If you decide to continue, we may enhance the product into a full-featured version ready for wider market release. This would include scaling up functionalities and improving user interfaces and overall performance to meet the demands of a larger audience.",
    gradient: "ourServicesCardGradient5",
    width: "calc(33% - 0.5rem)",
    height: "22.875rem",
  },
];

export const MVP_DEVELOPMENT_BENEFITS_LIST: BenefitsItemProps[] = [
  {
    title: "Cutting-edge tech",
    description:
      "The latest tech solutions for improving your efficiency and overall performance. Headless CMS, low-code/no-code, React.js, React Native, and more — we make sure our solutions are scalable and easily integrated into existing systems.",
  },
  {
    title: "Reliable team",
    description:
      "All professionals at Idealogic are ready to take a challenge and deliver the implementation of even the most complex and crazy ideas. We love to experiment and always support your aspirations for something unique. ",
  },

  {
    title: "Seamless workflow",
    description:
      "On-time delivery, security, peak performance, and clear communication about requirements, deadlines, and feedback — we streamline our processes to match your expectations about product development.",
  },
  {
    title: "Personal experts",
    description:
      "At Idealogic, you get direct CTO expertise and personalized attention to ensure your vision aligns with our implementation. We keep you posted about every update and issue and help you through the process with minimum inconvenience.",
  },
];

export const MVP_DEVELOPMENT_CONTENT: ServicesPageContent = {
  banner: {
    title: "MVP Development Services",
    description:
      "Idealogic is ready to embark on your startup MVP development so you can test the concept and present it to the stakeholders and investors, as it is crucial to get an MVP for market validation.",
    gradient: {
      color: "bannerBlueGradient",
      backgroundSize: "180% 180%",
    },
    imageRatio: 1.75,
  },
  needs: {
    title: "Why you might need MVP development?",
    description: "Unlock your idea’s potential and test its performance in the real world.",
    isBlackVariant: true,
    needsList: NEEDS_LIST_MVP_DEVELOPMENT_CONTENT,
    blur: "blueWhiteBlur",
  },
  ourServicesFintech: {
    title: "Workflow of MVP Development",
    description:
      "Learn about our agile MVP creation process to provide startup MVP development solutions that help validate the idea and create an engaging final product.",
    isEstimationButton: true,
    topServicesList: MVP_DEVELOPMENT_TOP_SERVICES_LIST,
    isWrap: false,
    isWithoutImages: true,
    bottomServicesList: MVP_DEVELOPMENT_BOTTOM_SERVICES_LIST,
  },
  benefits: {
    title: "What will you get?",
    description:
      "Learn more about our approach to MVP development and discover the benefits of choosing Idealogic as your partner.",
    isBlackStyleForButton: true,
    benefitsList: MVP_DEVELOPMENT_BENEFITS_LIST,
    isBackgroundImageNeed: false,
    isButtonPositionStart: true,
    blur: "blueWhiteBlur",
  },
  bookACallWithExpert: {
    bgMobileSrc: "/images/industries/aviation/book-a-call-with-expert-bg.webp",
    title: {
      beforeKeyWord: "",
      keyWord: "Flexible CTO",
      afterKeyWord: "Advisory Services",
    },
    description: "",
    linkedin: {
      name: "Artem Zaitsev",
      href: EXTERNAL_LINKS.linkedInArtem,
    },
    jobTitle: "VP of engineering",
    buttonText: "Book a call",
    imageSrc: "/images/services/mvp-development/book-a-call-with-expert/book-a-call-with-expert-artem-bg.webp",
    mobileImageSrc:
      "/images/services/mvp-development/book-a-call-with-expert/book-a-call-with-expert-artem-bg-mobile.webp",
    imageAspectRatio: 1.24,
    gradientColor: "bannerBlueGradient",
    isReverse: true,
    isMainPageVariation: true,
    isBoxShadow: true,
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.ugreator, CaseStudiesSlugEnums.iGames],
    title: "MVP development case studies",
    TitleIcon: WavingHandIcon,
    description: "We started from scratch and evolved our MVP solutions to make them fabulous. Check for yourselves.",
  },
  relatedArticlesFilterID: "clvpe74f3414f08usdqp3itrr",
};
export default MVP_DEVELOPMENT_CONTENT;
