import styled from "styled-components";

export const StyledFooterDescriptionFlex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  flex-direction: column;
  margin-bottom: 1.5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    flex-direction: row;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

export const StyledFooterDescription = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding-left: 0;
    padding-right: 0;
  }
`;
