import { useNavigate } from "react-router-dom";
// Components + styling
import { Heading } from "components";
import { LinkArrowShortIcon } from "components/svg";
import { TickerLine } from "..";
import { StyledTickerLinkWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { TickerLinkProps } from "./types";

const TickerLink: React.FC<TickerLinkProps> = ({ label, link, isExternal }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const [isHovered, setIsHovered] = React.useState(false);

  const itemWrapperRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <StyledTickerLinkWrapper
      ref={itemWrapperRef}
      onClick={() => {
        if (link && !isExternal) {
          navigate(link);
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      link={link}
    >
      {isHovered ? (
        <TickerLine
          list={[`${t(label)}`]}
          divider="/"
          dividerGap="2rem"
          time={15}
          isExternal={isExternal}
          link={link}
          containerOffsetWidth={itemWrapperRef.current?.offsetWidth || 0}
        />
      ) : (
        <Heading scale="h4" as="h3">
          {t(label)}
        </Heading>
      )}
      {!isHovered && link && <LinkArrowShortIcon width={isDesktop ? "3.5rem" : "2rem"} />}
    </StyledTickerLinkWrapper>
  );
};

export default TickerLink;
