// Components + styling
import { BannerCenterContent, FlexibleBannerContent } from "./components";
// Types
import { buttonThemeTypes, variants } from "components/button/types";
import { BannerDefaultProps } from "./types";

const BannerDefault: React.FC<BannerDefaultProps> = ({
  title,
  subTitle,
  isWhite,
  imageSrc,
  bgPosition,
  imageRatio,
  description,
  imageMobileSrc,
  buttonLabel = "Get a free estimation today",
  buttonBgColor,
  buttonVariant = variants.PRIMARY,
  buttonTheme = buttonThemeTypes.LIGHT,
  isColumn = false,
  isShowRateItem = true,
  isImageAbsolute = false,
  isContentCenter = false,
  isImageFullContainer = false,
  maxWidthForButtonRem = 18.75,
  isImageFullContainerMobile = false,
  gradient,
  minHeightStyledBoxRem,
  widthForBannerContentProps,
  titleWidth,
}) => {
  return (
    <div>
      {!isShowRateItem ? (
        <BannerCenterContent
          title={title}
          subTitle={subTitle}
          isWhite={isWhite}
          imageSrc={imageSrc}
          imageRatio={imageRatio}
          buttonLabel={buttonLabel}
          buttonVariant={buttonVariant}
          buttonBgColor={buttonBgColor}
          buttonTheme={buttonTheme}
          description={description}
          isImageAbsolute={isImageAbsolute}
          isFullContainer={isImageFullContainer}
          isImageFullContainerMobile={isImageFullContainerMobile}
        />
      ) : (
        <FlexibleBannerContent
          title={title}
          subTitle={subTitle}
          isWhite={isWhite}
          imageSrc={imageSrc}
          isColumn={isColumn}
          gradient={gradient}
          imageRatio={imageRatio}
          bgPosition={bgPosition}
          description={description}
          buttonLabel={buttonLabel}
          buttonVariant={buttonVariant}
          buttonBgColor={buttonBgColor}
          buttonTheme={buttonTheme}
          imageMobileSrc={imageMobileSrc}
          isImageAbsolute={isImageAbsolute}
          isFullContainer={isImageFullContainer}
          isContentCenter={isContentCenter}
          isShowRateItem={isShowRateItem}
          minHeightStyledBoxRem={minHeightStyledBoxRem}
          maxWidthForButtonRem={maxWidthForButtonRem}
          isImageFullContainerMobile={isImageFullContainerMobile}
          widthForBannerContentProps={widthForBannerContentProps}
          titleWidth={titleWidth}
        />
      )}
    </div>
  );
};

export default BannerDefault;
