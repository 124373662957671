import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
// Components + styling
import { LinkArrowIcon } from "components/svg";
import { BigPortfolioItem, PortfolioItem } from "./components";
import { Button, Column, Container, Heading, Text, VideoPlayerModal } from "components";
import {
  StyledPortfolioLink,
  StyledPortfolioTitle,
  StyledPortfolioWrapper,
  StyledPortfolioItemWrapper,
  StyledPortfolioTitleWrapper,
  StyledPortfolioTitleIconWrapper,
} from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useGetCaseStudiesBySlugs } from "./hooks";
import { useMatchBreakpoints, useParallaxEffect } from "hooks";
// Routes
import { ROUTES } from "navigation/routes";
// Types
import { PortfolioProps } from "./types";
import { UseScrollOffsetOptions } from "types/styled-components-types";

const Portfolio: React.FC<PortfolioProps> = ({
  TitleIcon,
  portfolioList,
  componentOnTop,
  componentOnBottom,
  title = "Explore what we have done",
  description = "Explore our successful projects in web and mobile app development, blockchain implementation, and artificial intelligence. Browse our case studies to discover our expertise in various industries.",
  actionButtonText = "See all works",
  bigPortfolioItem,
}) => {
  const [isVideoPlayerOpen, setIsVideoPlayerOpen] = React.useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isDesktop, isTablet, isMobile } = useMatchBreakpoints();

  const { caseStudies } = useGetCaseStudiesBySlugs(portfolioList);

  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  const parallaxOffset: UseScrollOffsetOptions[] = ["start end", "end start"];

  const parallaxImagesEffects = [
    useParallaxEffect(wrapperRef, isMobile ? 40 : isTablet ? 70 : 110, { offset: parallaxOffset }),
    useParallaxEffect(wrapperRef, isMobile ? 90 : isTablet ? 70 : 110, { offset: parallaxOffset, reverse: !isMobile }),
    useParallaxEffect(wrapperRef, isMobile ? 40 : isTablet ? 70 : 110, { offset: parallaxOffset }),
    useParallaxEffect(wrapperRef, isMobile ? 90 : isTablet ? 70 : 110, { offset: parallaxOffset, reverse: !isMobile }),
    useParallaxEffect(wrapperRef, isMobile ? 40 : isTablet ? 70 : 110, { offset: parallaxOffset }),
    useParallaxEffect(wrapperRef, isMobile ? 90 : isTablet ? 70 : 110, { offset: parallaxOffset, reverse: !isMobile }),
    useParallaxEffect(wrapperRef, isMobile ? 40 : isTablet ? 70 : 110, { offset: parallaxOffset }),
  ];

  const handleVideoPlayerToggle = (videoSources: string[]) => {
    if (videoSources.length) {
      setIsVideoPlayerOpen(prevOpen => !prevOpen);
    }
  };

  return (
    <StyledPortfolioWrapper ref={wrapperRef} componentOnTop={componentOnTop}>
      <Container
        px={{ _: "1rem", laptopL: "4.5rem" }}
        pt={{ _: "2rem", laptop: "6.875rem" }}
        pb={{ _: "2rem", tablet: "6.875rem", laptop: "9.375rem" }}
      >
        {React.isValidElement(componentOnTop) && componentOnTop}

        <Column
          flexDirection={{ tablet: "row" }}
          justifyContent={{ _: "flex-start", tablet: "space-between" }}
          alignItems={{ _: "flex-start", tablet: "flex-end" }}
          mb={{ _: "1.5rem", laptop: "4rem" }}
        >
          <StyledPortfolioTitleWrapper>
            <StyledPortfolioTitle>
              <Heading scale="h2" as="h3" color="white" mb={{ _: "0.25rem", laptop: "1rem" }}>
                {t(title)}
              </Heading>
              {TitleIcon && (
                <StyledPortfolioTitleIconWrapper>
                  <TitleIcon width={isDesktop ? "3.375rem" : "2rem"} height={isDesktop ? "3.375rem" : "2rem"} />
                </StyledPortfolioTitleIconWrapper>
              )}
            </StyledPortfolioTitle>
            <Text
              width={{ _: "100%", laptopL: "80%" }}
              textScale={isDesktop ? "p22Regular" : "p16Regular"}
              color="textThird"
            >
              {t(description)}
            </Text>
          </StyledPortfolioTitleWrapper>
          {isMobile ? (
            <Button
              width="100%"
              mx="auto"
              color="ratingYellow"
              filledType="bordered"
              buttonTheme="dark"
              hoverLinesPosition="mobileTopLeft"
              onClick={() => navigate(`/${ROUTES.caseStudies}`)}
            >
              {t(actionButtonText)}
            </Button>
          ) : (
            <StyledPortfolioLink href={`/${ROUTES.caseStudies}`} textScale={isDesktop ? "p24Bold" : "p18Medium"}>
              {t(actionButtonText)}
              <LinkArrowIcon mx="0.5rem" />
            </StyledPortfolioLink>
          )}
        </Column>

        {bigPortfolioItem && <BigPortfolioItem {...bigPortfolioItem} />}

        <Column
          flexDirection={{ tablet: "row" }}
          flexWrap={{ tablet: "wrap" }}
          justifyContent={{ tablet: "space-between" }}
          mt={{ _: "2rem", tablet: "3.75rem" }}
        >
          {caseStudies.map((item, index) => {
            const { y, selectedTransition } = parallaxImagesEffects[index];
            const videoTestimonialInfo = item?.videoInfo;

            return (
              <StyledPortfolioItemWrapper key={item.title}>
                <motion.div
                  style={{ y: !isMobile ? y : undefined }}
                  transition={!isMobile ? selectedTransition : undefined}
                >
                  <PortfolioItem
                    {...item}
                    handleClickTestimonialGif={() => {
                      handleVideoPlayerToggle(videoTestimonialInfo?.videoSources || []);
                    }}
                  />
                </motion.div>
                {videoTestimonialInfo && videoTestimonialInfo.videoSources && (
                  <>
                    <VideoPlayerModal isOpen={isVideoPlayerOpen} videoSources={videoTestimonialInfo.videoSources} />
                  </>
                )}
              </StyledPortfolioItemWrapper>
            );
          })}
        </Column>

        {React.isValidElement(componentOnBottom) && componentOnBottom}
      </Container>
    </StyledPortfolioWrapper>
  );
};

export default Portfolio;
