import { useNavigate } from "react-router-dom";
import { Box } from "components/layout";
// Components + styling
import {
  StyledMainPostBox,
  StyledMainPostInfoWrapper,
  StyledPostImageWrapper,
  StyledPostPreviewImage,
  StyledPostPreviewWrapper,
} from "./styled";
import { PostInfo } from "./components";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { ROUTES } from "navigation/routes";
import { formateDate } from "utils";
// Types
import { PostPreviewProps } from "./types";
import { PostInfoData } from "./components/post-info/types";


const PostPreview: React.FC<PostPreviewProps> = ({ post, isMainPost = false, ...rest }) => {
  const { isTablet, isDesktop } = useMatchBreakpoints();
  const navigate = useNavigate();

  if (!post) {
    return null;
  }

  const postInfo: PostInfoData = {
    author: post.author?.name || "",
    date: formateDate(post.date),
    title: post.title,
  };

  const blogClickHandler = () => {
    navigate(`/${ROUTES.blog}/${post.slug}`);
  };

  if ((isTablet || isDesktop) && isMainPost) {
    return (
      <StyledMainPostBox
        src={post.bannerImage?.url || ""}
        aspectRatio={(post.bannerImage?.width || 1) / (post.bannerImage?.height || 1)}
        onClick={blogClickHandler}
      >
        <StyledMainPostInfoWrapper>
          <PostInfo postInfo={postInfo} isMainPost />
        </StyledMainPostInfoWrapper>
      </StyledMainPostBox>
    );
  }

  return (
    <Box {...rest}>
      <StyledPostPreviewWrapper onClick={blogClickHandler}>
        <StyledPostImageWrapper>
          <StyledPostPreviewImage width="100%" src={post.coverImage?.url || ""} aspectRatio={1.51} />
        </StyledPostImageWrapper>
        <PostInfo postInfo={postInfo} />
      </StyledPostPreviewWrapper>
    </Box>
  );
};

export default PostPreview;
