import styled from "styled-components";
// Components
import { Text } from "components";
import { StyledTabPanelWrapper } from "components/tabs/components/tabs-panel/styled";

export const StyledTabsPanelWrapper = styled.div`
  ${StyledTabPanelWrapper} {
    max-width: 100%;
    min-height: 8rem;
    margin-bottom: 0;

    ${Text} {
      width: max-content;
    }
  }
`;

export const StyledTab = styled.div<{ isActive: boolean }>`
  width: 100%;

  display: flex;
  flex-flow: row wrap;
  gap: 1.5rem;

  position: absolute;
  top: 0;
  left: 0;

  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  z-index: ${({ theme, isActive }) => (isActive ? theme.zIndices.dropdown - 1 : "initial")};
  transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};

  ${({ theme }) => theme.mediaQueries.tablet} {
    gap: 4.625rem;
  }
`;

export const StyledTabsWrapper = styled.div`
  position: relative;
  min-height: 13.75rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    min-height: 6.125rem;
  }
  ${({ theme }) => theme.mediaQueries.laptop} {
    min-height: 11rem;
  }
`;

export const StyledTechStackImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-bottom: 2rem;
  }
`;
