import { transitions } from "theme/base";

export const slideTestimonialDecorLabelVariants = {
  hidden: {
    x: "100%",
    y: "-50%",
    transition: transitions.defaultMotion,
  },
  visible: {
    x: "-25%",
    y: "-50%",
    transition: { ...transitions.defaultMotion, delay: 0.75 },
  },
};
