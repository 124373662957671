// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { ROUTES } from "navigation/routes";
// Types
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { ServicesPageContent, ServicesVariants } from "pages/services/types";

const MOBILE_DEVELOPMENT_CONTENT: ServicesPageContent = {
  banner: {
    title: "Mobile Development",
    description:
      "Idealogic delivers customized mobile development solutions for various industries. We value your vision and innovativeness and are ready to experiment.",
    imageSrc: "/images/expertise/expertise-banner-mobile-development.webp",
    imageMobileSrc: "/images/expertise/expertise-banner-mobile-development-mobile.webp",
    imageRatio: 1.69,
  },
  customizedApplications: {
    title: "Rely on our mobile development expertise to get the best products",
    description: "Discover the range of the most popular mobile development solutions we provide for our clients.",
    customizedApplicationsList: [
      {
        label: "Android Development",
        description:
          "Idealogic team creates mobile applications, considering the specifics of Android and ensuring a high-quality experience for the end-users.",
        iconSrc: "/images/expertise/mobile-development/mobile-development-android-development-icon.svg",
      },
      {
        label: "iOS Development",
        description:
          "We present mobile applications for iOS devices smoothly integrated into the Apple ecosystem to guarantee familiarity and usability for the users.",
        iconSrc: "/images/expertise/mobile-development/mobile-development-ios-development-icon.svg",
      },
      {
        label: "React Native Mobile Development",
        description:
          "Using the React Native cross-platform framework, our team delivers solutions that can be deployed both on iOS and Android, streamlining the efficiency.",
        iconSrc: "/images/expertise/mobile-development/mobile-development-react-native-icon.svg",
      },
      {
        label: "Hybrid App Development",
        description:
          "Our team combines web technologies with a native wrapper to create applications for multiple platforms, balancing cost-effectiveness and native-like performance.",
        iconSrc: "/images/expertise/mobile-development/mobile-development-hybrid-development-icon.svg",
      },
      {
        label: "AR and VR App Development",
        description:
          "We develop applications that easily integrate digital content into AR or VR to enhance user engagement, especially in the gaming or education industries.",
        iconSrc: "/images/expertise/mobile-development/mobile-development-ar-and-vr-icon.svg",
      },
      {
        label: "TV Apps Development",
        description:
          "We ensure user-friendly apps for smart TVs and other connected TV platforms, whether for streaming content, interactive experiences, or utility purposes.",
        iconSrc: "/images/expertise/mobile-development/mobile-development-tv-apps-icon.svg",
      },
    ],
  },
  chooseOurExperience: {
    title: "Why choose our expertise of web development?",
    description:
      "We focus on continuous improvement and learning. That, combined with years of experience, makes our services stand out.",
    imageSrc: "/images/expertise/expertise-banner-mobile-development-choose-our-experience.webp",
    imageRatio: 1.46,
    chooseOurExperienceList: [
      {
        label: "Adaptability and Innovation",
        description:
          "Our team follows the market trends to incorporate the best features and technologies into our projects.",
        iconSrc: "/images/expertise/choose-out-experience/web-development-lightbulb-icon.svg",
      },
      {
        label: "Training and Certifications",
        description:
          "We value education and encourage our employees to take the necessary courses by providing compensation.",
        iconSrc: "/images/expertise/choose-out-experience/web-development-square-academic-cap-icon.svg",
      },
      {
        label: "Various Complexity",
        description:
          "Our team does not hesitate to take on projects that require a unique and complex approach and specific knowledge.",
        iconSrc: "/images/expertise/choose-out-experience/web-development-fire-icon.svg",
      },
      {
        label: "Security and Data Protection",
        description:
          "At Idealogic, we make a great deal of complying with all the necessary security requirements and guidelines.",
        iconSrc: "/images/expertise/choose-out-experience/web-development-shield-icon.svg",
      },
    ],
  },

  digitalExcellence: {
    title: "Crafting Digital Excellence",
    description:
      "With every completed project, we strive to give an unforgettable experience with our unique features and appealing designs.",
    digitalExcellenceList: {
      digitalExcellenceListFirst: [
        {
          label: "50+",
          description: "Projects Completed",
          isBigBorder: false,
        },
        {
          label: "99.9%",
          description: "Security Protocols",
          isBigBorder: true,
        },
      ],
      digitalExcellenceListSecond: [
        {
          label: "90%+",
          description: "Code Quality Metrics",
          isBigBorder: true,
        },

        {
          label: "5+",
          description: "Experience years",
          isBigBorder: false,
        },
      ],
    },
  },
  portfolio: {
    portfolioList: [
      CaseStudiesSlugEnums.ugreator,
      CaseStudiesSlugEnums.addmed,
      CaseStudiesSlugEnums.swissy,
      CaseStudiesSlugEnums.kansoWallet,
    ],
    title: "Mobile development case studies",
    TitleIcon: WavingHandIcon,
    description:
      "Be it finance, healthcare, or entertainment, our team has examples of successful and appealing apps. Check them out.",
  },
  developmentProcess: {
    title: "Our mobile development process",
    description:
      "We’ve enhanced our workflow with the years passing. Learn more about our step-by-step approach to mobile development.",
    benefitsList: [
      {
        title: "Discover & Estimate",
        description:
          "We start by closely learning your business requirements, defining what technical solutions suit you best, and providing you with a detailed plan of work scope.",
      },
      {
        title: "Design and Prototype",
        description:
          "We present you with a vision of your future product with basic features so that you can make adjustments and define the course of future development.",
      },
      {
        title: "Development",
        description:
          "Our team of relevant specialists works on the technical implementation of the project, using cutting-edge technologies.",
      },
      {
        title: "Testing",
        description:
          "We do automation and manual testing to ensure every feature works properly and the product can perform to its full potential.",
      },
      {
        title: "Deployment",
        description:
          "We are ready to release the product only after we are satisfied with its usability, security compliance, and reliability.",
      },
      {
        title: "Maintenance",
        description:
          "Our team is keeping up with the product’s performance after the launch to forecast and prevent any technical issues and urgently react if something goes wrong.",
      },
    ],
  },
  boostBusiness: {
    title: "Our expert will be happy to discuss the details of your project!",
    description: "We will take your project and create something incredible for you.",
    buttonTitle: "Let’s talk!",
    personName: "Evgeniy Ochkalov",
    personPosition: "Head of front end department",
    personImageSrc: "/images/expertise/expertise-banner-web-development-evgen-ochkalov.webp",
    imageRatio: 1,
    bgColor: "bgDark",
  },
  coveredIndustries: {
    coveredIndustriesList: [
      { label: "Web Development", link: `/${ROUTES.services}/${ServicesVariants.webDevelopment}` },
      { label: "UI/UX Design", link: `/${ROUTES.services}/${ServicesVariants.uiUxDesign}` },
      { label: "AI/ML Solutions", link: `/${ROUTES.services}/${ServicesVariants.aiMlSolutions}` },
      { label: "Blockchain", link: `/${ROUTES.services}/${ServicesVariants.blockchain}` },
      { label: "Big Data", link: `/${ROUTES.services}/${ServicesVariants.bigData}` },
      { label: "Cloud Solutions", link: `/${ROUTES.services}/${ServicesVariants.cloudSolutions}` },
    ],
  },
};

export default MOBILE_DEVELOPMENT_CONTENT;
