// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "ratingYellow", ...props }) => {
  return (
    <Svg viewBox="0 0 22 23" {...props} color={color}>
      <path d="M11.645 3.2126C11.2962 2.61143 10.4281 2.61142 10.0792 3.2126L7.62253 7.44637C7.45021 7.74334 7.15237 7.946 6.81287 7.99728L2.20888 8.69265C1.43561 8.80945 1.16436 9.78578 1.76658 10.2847L5.19636 13.1262C5.51384 13.3893 5.66312 13.8042 5.586 14.2092L4.68882 18.9207C4.547 19.6655 5.32887 20.2442 5.99974 19.891L10.335 17.6083C10.6649 17.4346 11.0593 17.4346 11.3893 17.6083L15.7245 19.891C16.3954 20.2442 17.1773 19.6655 17.0354 18.9207L16.1383 14.2092C16.0611 13.8042 16.2104 13.3893 16.5279 13.1262L19.9577 10.2847C20.5599 9.78578 20.2887 8.80945 19.5154 8.69265L14.9114 7.99728C14.5719 7.946 14.2741 7.74334 14.1017 7.44637L11.645 3.2126Z" />
    </Svg>
  );
};

export default Icon;
