import { Link, Svg } from "components";
import styled from "styled-components";

export const StyledOurCompanyFooterLink = styled(Link)`
  -webkit-text-fill-color: ${({ theme }) => theme.colors.white};

  ${Svg} {
    path {
      stroke: ${({ theme }) => theme.colors.white};
    }
  }

  &:hover {
    -webkit-text-fill-color: transparent;
    ${Svg} {
      path {
        stroke: url("#paint0_linear_12208_81157");
      }
    }
  }
`;

export const StyledOurCompanyLinksContainer = styled.div`
   margin-top: 2rem;
`
