import styled, { css, keyframes } from "styled-components";
// Components
import { Image } from "components";
// Types
import { BannerBoxProps } from "./types";

const gradientAnimation = keyframes`
 0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const StyledBox = styled.div<BannerBoxProps>`
  background-image: ${({ isFullContainer, imageSrc }) => isFullContainer && `url(${imageSrc})`};

  background-size: ${({ imageMobileSrc }) => (imageMobileSrc ? "cover" : "100vw 100%")};
  background-position-y: ${({ imageMobileSrc }) => imageMobileSrc && "50%"};

  background-repeat: no-repeat;
  position: relative;
  z-index: 0;

  overflow: hidden;

  min-height: ${({ isFullContainer, imageSrc }) => (isFullContainer ? "28.5rem" : !imageSrc ? "100%" : "34.5rem")};

  background-color: ${({ theme, isWhite }) => (isWhite ? theme.colors.white : theme.colors.bgDark)};
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  ${({ gradient, theme }) =>
    gradient &&
    css`
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${theme.gradients[gradient.color]};
        background-size: ${gradient.backgroundSize};
        animation: ${gradientAnimation} 16s ease infinite;
      }
    `}

  ${({ isFullContainer, isWhite, isShowRateItem, theme, gradient }) =>
    isFullContainer &&
    isShowRateItem &&
    !gradient &&
    css`
      ::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${isWhite
          ? `${theme.colors.white}E6`
          : `${theme.colors.bgDark}B3`}; /* E6 used for 90% and B3 for 70% opacity in hex */
        z-index: 1;
        bottom: -2rem;
      }
    `}

  ${({ theme }) => theme.mediaQueries.tablet} {
    min-height: ${({ imageSrc, gradient }) => (imageSrc || gradient ? "40.44rem" : "100%")};
    background-size: 100% 100%;
    ::before {
      display: none;
    }
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    min-height: ${({ imageSrc, gradient }) => (imageSrc || gradient ? "39.875rem" : "100%")};
    padding-top: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    min-height: ${({ imageSrc, gradient }) => (imageSrc || gradient ? "calc(100vh + 6rem)" : "100%")};

    margin-top: ${({ isFullContainer }) => isFullContainer && "-5rem"};
    padding-top: ${({ isFullContainer }) => isFullContainer && "6rem"};
    background-size: ${({ isFullContainer }) => (!isFullContainer ? "cover" : "100vw calc(100vh + 6rem)")};
  }

  @media screen and (min-width: 2300px) {
    background-position: ${({ bgPosition }) => bgPosition};
  }
`;

export const StyledImageAbsolute = styled(Image)`
  width: 100%;
  position: relative;
  bottom: 1.5rem;
  right: 0;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 50%;
    max-width: 48rem;
    position: absolute;
    bottom: 2.5rem;
  }
`;
