import styled from "styled-components";
// Components
import { Heading } from "components";

export const StyledTickerLinkWrapper = styled.div<{ link?: string }>`
  min-height: 3.5625rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0.75rem;

  border-bottom: 1px solid ${({ theme }) => theme.colors.lineMain};

  background: inherit;

  transition: ${({ theme }) => theme.transitions.default};
  cursor: ${({ link }) => (link ? "pointer" : "default")};
  overflow: hidden;

  ${Heading} {
    color: inherit;
    transition: ${({ theme }) => theme.transitions.default};
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    min-height: 6.5625rem;
    padding: 1.5rem 0;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.bgDark};

    ${Heading} {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;
