import React from "react";
import { Suspense } from "react";
// Constants
import { BLOCK_ID } from "configs";
// Types
import { VideoPlayerModalProps } from "./types";

const FsLightbox = React.lazy(() => import("fslightbox-react"));

const VideoPlayerModal: React.FC<VideoPlayerModalProps> = ({ isOpen, videoSources = [] }) => {
  const hideHeader = () => {
    const header = document.getElementById(BLOCK_ID.header);
    if (header) {
      header.style.display = "none";
    }
  };

  const showHeader = () => {
    const header = document.getElementById(BLOCK_ID.header);
    if (header) {
      header.style.display = "flex";
    }
  };

  return (
    <Suspense>
      <FsLightbox toggler={isOpen} sources={videoSources} onOpen={hideHeader} onClose={showHeader} />
    </Suspense>
  );
};

export default VideoPlayerModal;
