const isProductionEnv = process.env.REACT_APP_ENV === "prod";

const registerSW = (swUrl: string) => {
  navigator.serviceWorker.register(swUrl).catch(error => {
    console.error("Error during service worker registration:", error);
  });
};

export function register() {
  if (isProductionEnv && "serviceWorker" in navigator) {
    const publicUrl = new URL(process.env.REACT_APP_URL as string, window.location.href);

    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    window.addEventListener("load", () => {
      const swUrl = "/service-worker.js";

      navigator.serviceWorker
        .getRegistrations()
        .then(registrations => {
          // Register self destructing service worker only if previous workers was registered
          if (registrations.length) {
            registerSW(swUrl);
          }
        })
        .catch(error => {
          console.error("Error getting service worker registrations:", error);
        });
    });
  }
}
