import styled from "styled-components";
// Utils
import { calculateElementsWidthByGap } from "utils";
// Constants
import { TESTIMONIALS_LIST_GAP, TESTIMONIAL_ITEMS_PER_ROW_DEFAULT } from "../../constants";

export const StyledTestimonialItemWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 1rem;

  border-radius: ${({ theme }) => theme.radii.semiLarge};
  background-color: ${({ theme }) => theme.colors.bgSecond};

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: ${calculateElementsWidthByGap(2, TESTIMONIALS_LIST_GAP)};
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: ${calculateElementsWidthByGap(TESTIMONIAL_ITEMS_PER_ROW_DEFAULT, TESTIMONIALS_LIST_GAP)};
    padding: 2.5rem;
  }
`;

export const StyledTestimonialImageWrapper = styled.div`
  display: flex;
  margin-bottom: 0.75rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-bottom: 1.5rem;
  }
`;

export const StyledTestimonialTextWrapper = styled.div`
  margin-right: 0.75rem;
  margin-left: 0.75rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
`;

export const StyledRatingCompanyWrapper = styled.div`
  width: fit-content;

  display: flex;
  align-items: center;
`;

export const StyledRatingStars = styled.div`
  margin-right: 1rem;
  margin-left: 1rem;
`;
