import { EN } from "configs";
import { removeTrailingSlashIfExists } from "utils";

export const generateLanguageLink = (
  code: string,
  locale: string,
  correctPath: string,
  linkRel: string,
): JSX.Element => {
  const languageCode = code === EN.code ? "" : `${code}.`;
  const appUrl = process.env.REACT_APP_URL;

  const href = `${appUrl
    ?.split("//")
    .reduce(
      (acc, curr, index) => (index === 1 ? acc + "//" + languageCode + curr : acc + curr),
      "",
    )}${removeTrailingSlashIfExists(correctPath)}`;

  return <link key={locale} rel={linkRel} href={href} hrefLang={locale} />;
};
