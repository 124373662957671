// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { ROUTES } from "navigation/routes";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { MEDIA_INDUSTRY_TABS_LIST } from "../../industries/components/industry-info/constants";
import { EXTERNAL_LINKS } from "configs";
// Types
import { IndustriesVariants, IndustryPageContent } from "../../industries/types";
import { BenefitsItemProps } from "pages/services/components/benefits/components/benefits-item/types";
import { WhoWeServeItemType } from "../../industries/components/who-we-serve/types";
import { AccordionContentItemType } from "../../industries/components/our-capabilities/types";

export const ML_DEVELOPMENT_BENEFITS_LIST: BenefitsItemProps[] = [
  {
    title: "Free Consultation",
    description:
      "We offer a free consultation for you to share your main business needs. Our team dives deeply into it to identify the core aspects of your project and decide the next steps with you.",
  },
  {
    title: "Data Preparation",
    description:
      "We carefully clean and validate your data to confirm its relevance, completeness, and the anonymization of sensitive information, ensuring it's ready to use in machine learning models.",
  },
  {
    title: "Model Building",
    description:
      "We develop an ML model tailored to your specific requirements or refine an existing open-source model, ensuring it perfectly aligns with your data and business goals.",
  },
  {
    title: "Testing",
    description:
      "We check our ML models to detect any bugs early and fix them as soon as possible to ensure stable performance in the future, aimed at delivering your business objectives.",
  },
];

export const ML_DEVELOPMENT_WHO_WE_SERVE_LIST: WhoWeServeItemType[] = [
  {
    text: "Open New Opportunities",
    subText:
      "Dive into a world of possibilities with tailored machine learning applications, from powerful recommender systems  to AI-driven decision-making tools that streamline business  processes and strategic growth.",
  },
  {
    text: "Elevate Customer Engagement",
    subText:
      "Improve customer experiences significantly with AI-powered chatbots and virtual assistants, boosting satisfaction rates and conversion through engaging, personalized interactions.",
  },
  {
    text: "Optimize Operations and Production",
    subText:
      "Leverage machine learning to identify market trends and customer behaviors to enhance product efficiency and visibility and develop products that perfectly meet market demands.",
  },
  {
    text: "Strengthen Security Measures",
    subText:
      "Boost your security protocols with machine learning, predicting and preventing malicious activities like malware and phishing attacks and safeguarding against authentication threats.",
  },
  {
    text: "Intelligent Solutions Reduce Human Error",
    subText:
      "Utilize intelligent transport systems and machine learning algorithms to minimize human error, enhance analysis accuracy, and achieve peak performance efficiently.",
  },
  {
    text: "Boost Sales and Employee",
    subText:
      "Enhance sales support with AI chatbots that anticipate and respond to customer needs in real time while automating routine tasks with ML algorithms to free up human resources for more complex and creative work.",
  },
];

export const ML_DEVELOPMENT_ACCORDION_CONTENT_LIST: AccordionContentItemType[] = [
  {
    title: "Initial Discovery",
    description:
      "Schedule a free consultation with us to detail your business specifics, objectives, and challenges. This initial discussion allows us to understand how ML can be leveraged and outline a tailored approach crafted by our dedicated ML developers.",
  },
  {
    title: "ML Model Preparation",
    description:
      "The next essential steps are data collection and preparation, data mining, engineering, model training, and preliminary verification to prepare an efficient ML model and ensure its readiness for further evaluation.",
  },
  {
    title: "Model Evaluation",
    description:
      "Here, we assess the efficacy of the ML model against your business cases to determine its potential value to your organization. This critical evaluation helps us decide which models are promising enough to advance to deployment.",
  },
  {
    title: "Model Deployment",
    description:
      "Our team constructs automated workflows to scale and seamlessly integrate your ML application into production environments. If necessary, we adjust algorithms to align with your benchmarks and performance goals",
  },
  {
    title: "Ongoing Monitoring & Maintenance",
    description:
      "We value our partnership and continue to support you by monitoring the system’s performance and making necessary updates or modifications to ensure its environment remains optimal and relevant to your evolving needs.",
  },
];

const ML_DEVELOPMENT_CONTENT: IndustryPageContent = {
  banner: {
    title: "Machine Learning Development Services",
    description:
      "Idealogic, a machine learning development company, offers services and solutions to innovate businesses, increase operational efficiency, and build success.",
    imageSrc: "",
    buttonLabel: "Get a free estimation today",
    imageRatio: 0,
    isColumn: true,
    isWhite: true,
    isImageFullContainer: false,
    isImageFullContainerMobile: false,
    titleWidth: "50%",
  },
  benefits: {
    title: "Our Approach to ML Development Services at Idealogic",
    description:
      "As an experienced machine learning solutions company, we’ve perfected our approach to machine learning development to achieve maximum benefits for our customers and establish a convenient workflow for our team.",
    benefitsList: ML_DEVELOPMENT_BENEFITS_LIST,
    buttonText: "Schedule a call",
    isNeedCurveLine: false,
    isBackgroundImageNeed: false,
    isButtonPositionStart: true,
  },
  whoWeServe: {
    title: "Deploy Machine Learning Models for Unlimited Potential",
    description:
      "Unlock a world of possibilities with tailored machine learning application development tailored to your needs",
    whoWeServeListLayoutProps: {
      maxHeight: { _: "100%", laptop: "39.375rem", laptopL: "30.375rem" },
    },
    whoWeServeList: ML_DEVELOPMENT_WHO_WE_SERVE_LIST,
  },
  bookACallWithExpert: {
    bgSrc: "/images/expertise/blockchain/book-call-with-a-expert/bg.webp",
    bgMobileSrc: "/images/expertise/blockchain/book-call-with-a-expert/bg.webp",
    title: {
      beforeKeyWord: "",
      keyWord: "Our expert",
      afterKeyWord: "will be happy to discuss the details of your project!",
    },
    description: "",
    linkedin: {
      name: "Artem Zaitsev",
      href: EXTERNAL_LINKS.linkedInArtem,
    },
    jobTitle: "VP of engineering",
    buttonText: "Book a call",
    imageSrc: "/images/expertise/blockchain/book-call-with-a-expert/book-a-call-with-expert-artem-bg.webp",
    mobileImageSrc: "/images/expertise/blockchain/book-call-with-a-expert/book-a-call-with-expert-artem-mobile-bg.webp",
    isReverse: true,
    imageAspectRatio: 1.24,
  },
  ourCapabilities: {
    title: "Machine Learning Model Development Lifecycle at Idealogic",
    description:
      "Discover our detailed approach to building a machine-learning model tailored to your business objectives. Our team set up the necessary steps and established communication between all the parties involved to enhance your market presence and simplify routine processes.",
    accordionContent: ML_DEVELOPMENT_ACCORDION_CONTENT_LIST,
    isContactAction: true,
  },
  industryInfo: {
    title: "Creative tech solutions for media",
    description: "We provide people in the media industry with sufficient technical support, enhancing their workflow.",
    industryInfoTabsList: MEDIA_INDUSTRY_TABS_LIST,
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.ugreator, CaseStudiesSlugEnums.inder],
    title: "Media case studies",
    TitleIcon: WavingHandIcon,
    description:
      "Be it a talent monetization or a blog CRM platform — our solutions are already impacting the media industry. Check for yourself.",
  },
  coveredIndustries: {
    coveredIndustriesList: [
      { label: "Fintech", link: `/${ROUTES.industries}/${IndustriesVariants.fintech}` },
      { label: "Logistics", link: `/${ROUTES.industries}/${IndustriesVariants.logistics}` },
      { label: "Real Estate", link: `/${ROUTES.industries}/${IndustriesVariants.realEstate}` },
      { label: "Aviation", link: `/${ROUTES.industries}/${IndustriesVariants.aviation}` },
      { label: "Other industries", link: `/${ROUTES.industries}/${IndustriesVariants.other}` },
    ],
  },
};

export default ML_DEVELOPMENT_CONTENT;
