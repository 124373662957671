// Components + Styling;
import { Text } from "components";
import { StyledCustomCursor } from "./styled";
// Context
import { useTranslation } from "context";
// Configs
import { BLOCK_ID } from "configs";
// Types
import { CustomCursorProps } from "./types";

const CustomCursor: React.FC<CustomCursorProps> = ({ label, cursorVariant, variants, transition }) => {
  const { t } = useTranslation();

  return (
    <StyledCustomCursor id={BLOCK_ID.customCursor} variants={variants} animate={cursorVariant} transition={transition}>
      <Text width="4.375rem" textScale="p18Medium">
        {t(label)}
      </Text>
    </StyledCustomCursor>
  );
};

export default CustomCursor;
