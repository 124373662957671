import {
  AVIATION_CONTENT,
  AI_BASED_AVIATION_CONTENT,
  BANKING_INDUSTRIES_CONTENT,
  DIGITAL_BANKING_CONTENT,
  FINTECH_CONTENT,
  LOGISTICS_CONTENT,
  MEDIA_CONTENT,
  OTHER_INDUSTRIES_CONTENT,
  REAL_ESTATE_CONTENT,
  CRYPTO_CURRENCY_INDUSTRIES_CONTENT,
  INTELLIGENT_INVENTORY_MANAGEMENT_CONTENT,
  SPARE_PARTS_MARKETPLACE_CONTENT,
  BLOCKCHAIN_BASED_AVIATION,
  CUSTOM_REAL_ESTATE_CONTENT,
  PROPERTY_MANAGEMENT_SOLUTIONS_CONTENT,
  AGENT_BROKERS_SOLUTIONS_CONTENT,
} from ".";
// Types
import { ServiceVariantContent } from "../types";

const IndustryContentMapper: ServiceVariantContent = {
  aviation: AVIATION_CONTENT,
  aiBasedAviation: AI_BASED_AVIATION_CONTENT,
  banking: BANKING_INDUSTRIES_CONTENT,
  cryptoCurrency: CRYPTO_CURRENCY_INDUSTRIES_CONTENT,
  blockchainBasedAviation: BLOCKCHAIN_BASED_AVIATION,
  digitalBanking: DIGITAL_BANKING_CONTENT,
  fintech: FINTECH_CONTENT,
  logistics: LOGISTICS_CONTENT,
  media: MEDIA_CONTENT,
  other: OTHER_INDUSTRIES_CONTENT,
  intelligentInventoryManagement: INTELLIGENT_INVENTORY_MANAGEMENT_CONTENT,
  realEstate: REAL_ESTATE_CONTENT,
  customRealEstate: CUSTOM_REAL_ESTATE_CONTENT,
  propertyManagementSolutions: PROPERTY_MANAGEMENT_SOLUTIONS_CONTENT,
  agentBrokersSolutions: AGENT_BROKERS_SOLUTIONS_CONTENT,
  sparePartsMarketplace: SPARE_PARTS_MARKETPLACE_CONTENT,
};

export default IndustryContentMapper;
