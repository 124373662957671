import { Variants } from "framer-motion";
import { transitions } from "theme/base";
// Constants
import { headerAnimationType } from "./constants";

export const slideVariants = {
  hidden: {
    y: -150,
    height: 0,
    opacity: 0,
    transition: transitions.defaultMotion,
  },
  visible: {
    y: 0,
    opacity: 1,
    height: "initial",
    transition: transitions.defaultMotion,
  },
};

export const headerShow = (isLaptop: boolean) => ({
  hidden: {
    y: -118,
    transition: transitions.defaultMotion,
  },
  visible: {
    y: 0,
    transition: isLaptop ? transitions.bounceEffect : transitions.defaultMotion,
  },
});

export const getHeaderDropVariants = (isLaptop: boolean): Variants => ({
  [headerAnimationType.initial]: {
    top: -2,
    position: "fixed",
    transition: {
      ...transitions.defaultMotion,
      duration: 0.15,
    },
  },
  [headerAnimationType.hidden]: {
    position: "fixed",
    top: -118,
    transition: {
      ...transitions.defaultMotion,
      duration: 0.15,
    },
  },
  [headerAnimationType.fixed]: {
    position: "fixed",
    top: -118,
  },
  [headerAnimationType.drop]: {
    top: isLaptop ? 16 : -2,
    transition: isLaptop ? transitions.bounceEffect : transitions.defaultMotion,
    position: "fixed",
  },
});

export const slideHeaderContentVariants = {
  hidden: {
    height: 0,
    opacity: 0,
    transition: transitions.linearMotion_0_2s,
  },
  visible: {
    opacity: 1,
    height: "initial",
    transition: transitions.linearMotion_0_2s,
  },
};
