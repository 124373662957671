import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import { Image } from "components";

export const StyledTeamMemberItem = styled(motion.div)`
  margin: 0 0.75rem;
`;

export const StyledTeamMemberImage = styled(Image)`
  border-radius: ${({ theme }) => theme.radii.large};
  filter: grayscale(1);
  transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};

  div {
    justify-content: start;
  }

  img {
    border-radius: ${({ theme }) => theme.radii.large};
  }

  &:hover {
    filter: grayscale(0);
  }
`;
