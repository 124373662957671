const REGEX = {
  includesVariableRegex: new RegExp(/%\S+?%/, "gm"),
  emailRegex:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

  removeJavaScriptWrapperRegex: /^```javascript\s*|\s*```$/g,
  chunkErrorPattern: /Loading chunk .+ failed\./,
};

export default REGEX;
