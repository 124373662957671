// Components + Styling
import { Button, Flex, Box, Heading, Image, Text } from "components";
import { StyledHeadingWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { ThankForRequestProps } from "./types";

const ThankForRequest: React.FC<ThankForRequestProps> = ({
  textColor,
  isHideBgImage,
  isHideButton,
  setIsShowThankYou,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <Flex flexDirection={isDesktop ? "row" : "column"} alignItems="center">
      <Box width="100%">
        <StyledHeadingWrapper>
          <Heading scale="h2" as="h2" textAlign={isDesktop ? "left" : "center"} color={textColor ?? "textMain"}>
            {t("Thank you!")}
          </Heading>
        </StyledHeadingWrapper>

        <Text
          textScale={isDesktop ? "p22Regular" : "p16Regular"}
          mb={{ _: "1.5rem", laptop: "2rem" }}
          textAlign={isDesktop ? "left" : "center"}
          color={textColor ?? "textMain"}
        >
          {t("We will respond to your inquiry promptly.")}
          <br />
          {t("Thank you for reaching out to us.")}
        </Text>

        {!isDesktop && !isHideBgImage && <Image width="100%" src="/images/thank-you-banner.webp" aspectRatio={1.11} />}

        {!isHideButton && (
          <Flex
            width="100%"
            justifyContent={{ _: "center", laptop: "flex-start" }}
            mx={isDesktop ? "0" : "auto"}
            mt="1.5rem"
          >
            <Button
              width="100%"
              maxWidth="21.375rem"
              height={{ _: "3.125rem", laptop: "3.75rem" }}
              scale={isDesktop ? "lg" : "md"}
              hoverLinesPosition="bottomLeft"
              onClick={() => setIsShowThankYou(false)}
            >
              {t("Ok. I’m waiting")}
            </Button>
          </Flex>
        )}
      </Box>

      {isDesktop && !isHideBgImage && <Image width="100%" src="/images/thank-you-banner.webp" aspectRatio={1.11} />}
    </Flex>
  );
};

export default ThankForRequest;
