// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 29 28" width="29" height="28" {...props} color="transparent">
      <path
        d="M22.0475 19.6344L22.5025 23.3211C22.6191 24.2894 21.5808 24.9661 20.7525 24.4644L16.7158 22.0611C16.4358 21.8978 16.3658 21.5478 16.5175 21.2678C17.1008 20.1944 17.4158 18.9811 17.4158 17.7678C17.4158 13.4978 13.7525 10.0211 9.24914 10.0211C8.32748 10.0211 7.42914 10.1611 6.58914 10.4411C6.15748 10.5811 5.73748 10.1844 5.84248 9.7411C6.90414 5.49443 10.9875 2.33276 15.8641 2.33276C21.5575 2.33276 26.1658 6.63776 26.1658 11.9461C26.1658 15.0961 24.5441 17.8844 22.0475 19.6344Z"
        fill={theme.colors[color]}
      />
      <path
        d="M15.6654 17.7679C15.6654 19.1562 15.152 20.4396 14.2887 21.4546C13.1337 22.8546 11.302 23.7529 9.2487 23.7529L6.2037 25.5612C5.69036 25.8762 5.03703 25.4446 5.10703 24.8496L5.3987 22.5512C3.83536 21.4662 2.83203 19.7279 2.83203 17.7679C2.83203 15.7146 3.9287 13.9062 5.6087 12.8329C6.64703 12.1562 7.89536 11.7712 9.2487 11.7712C12.7954 11.7712 15.6654 14.4546 15.6654 17.7679Z"
        fill={theme.colors[color]}
      />
    </Svg>
  );
};

export default Icon;
