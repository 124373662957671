import { createGlobalStyle } from "styled-components";

import { breakpointMap } from "theme/base";

const GlobalStyle = createGlobalStyle`

html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
    font-size: 16px;
    overflow-x: hidden;
    overflow-y: scroll !important;
    overscroll-behavior-y: none ;
    background-color: ${({ theme }) => theme.colors.bgDark};
  }
  ol,
  ul {
    list-style: disc;
    list-style-position: inside;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  [role="button"] {
    cursor: pointer;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* Number */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  html {
    scroll-behavior: smooth;
  }

  /* Scrollbar */
  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    display: none;
  }

  /* Slider */ 
  input[type=range] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
  }
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  input[type=range]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }
  input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
    /* Hides the slider so custom styles can be added */
    background: transparent; 
    border-color: transparent;
    color: transparent;
  }
  
  body {
    overflow-x: hidden;
    min-width: 100vw;
    min-height: 100vh;
    margin: 0;
    font-family: ${({ theme }) => theme.fonts.ubuntu};
    font-size: 16px;
    line-height: 1.5;

    img {
      height: auto;
      max-width: 100%;
    }
  }

  html,
  body {
    /* Font size formula explanation */
    /* 16px: base font-size; */
    /* (18 - 16): max font size for media query - base font size (let's call it font size coefficient) */
    /* (100vw - {breakpointMap.mobileM}px): current screen width difference from current media query value (let's call it current screen difference)*/
    /* {breakpointMap.tablet - breakpointMap.mobileM}: max difference from next media query to current media query value (let's call it max screen difference)*/
    /* (100vw - {breakpointMap.mobileM}px) / {breakpointMap.tablet - breakpointMap.mobileM}: 
      ratio of current screen difference and max screen difference (let's call it screen ratio) 
    */
    /* Then we multiply font size coefficient to screen ratio, and the result added to base font size */


    font-size: ${`calc(16px + (18 - 16) * ((100vw - ${breakpointMap.mobileM}px) / ${
      breakpointMap.tablet - breakpointMap.mobileM
    }))`};

    ${({ theme }) => theme.mediaQueries.tablet} {
      font-size: ${`calc(16px + (18 - 16) * ((100vw - ${breakpointMap.tablet}px) / ${
        breakpointMap.laptop - breakpointMap.tablet
      }))`};
    }

    ${({ theme }) => theme.mediaQueries.laptop} {
      font-size: ${`calc(16px + (18 - 16) * ((100vw - ${breakpointMap.laptop}px) / ${
        breakpointMap.laptopL - breakpointMap.laptop
      }))`};
    }

    ${({ theme }) => theme.mediaQueries.laptopL} {
      /* For laptopL font size is must be base */
      font-size: 16px;
    }
    scroll-behavior: smooth;
    background-color: ${({ theme }) => theme.colors.bgDark};
  }

  
  /* Set body bg color in storybook */
  .sb-show-main {
    background-color: ${({ theme }) => theme.colors.white};
  }

  #root {
    height: 100%;
    min-height: 100vh;
  }
`;

export default GlobalStyle;
