import { gql } from "@apollo/client";
// Constants
import { POSTS_PER_PAGE } from "./constants";

const STAGE = process.env.REACT_APP_ENV;

export const getPosts = gql`
  query GetPosts($stage: Stage! = PUBLISHED, $where: PostWhereInput, $skip: Int, $first: Int = ${POSTS_PER_PAGE}) {
    posts(stage: $stage, where: $where, first: $first, skip: $skip, orderBy: date_DESC) {
      title
      date
      id
      slug
      author {
        name
      }
      coverImage {
        url
        width
        height
      }
      bannerImage {
        url
        width
        height
      }
      content {
        html
      }
      tags {
        title
        id
      }
      seo {
        description
        title
        keywords
        image {
          url
        }
      }
    }
    postsConnection(stage: $stage, where: $where, first: $first, skip: $skip, orderBy: date_DESC) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      aggregate {
        count
      }
    }
  }
`;

export const getPostBySlug = gql`
  query GetPostBySlug($slug: String!) {
    post(where: { slug: $slug }) {
      id
      title
      date
      slug
      coverImage {
        url
        width
        height
      }
      bannerImage {
        url
        width
        height
      }
      content {
        html
      }
      author {
        name
      }
      tags {
        title
        id
      }
      seo {
        description
        title
        keywords
        image {
          url
        }
      }
    }
  }
`;

export const getTags = gql`
  query GetTags($customStage: String = "${STAGE}") {
    tags(
      where: {
        posts_some: {
          Post: { customStages_some: { _search: $customStage }, availableSourcesList_some: { _search: "development" } }
        }
      }
    ) {
      id
      title
    }
  }
`;
