import { lazyLoad } from "utils";

export const NextparqPage = lazyLoad(
  () => import("./nextparq"),
  module => module.default,
);

export const HealthLifePage = lazyLoad(
  () => import("./health-life"),
  module => module.default,
);

export const UbeatPage = lazyLoad(
  () => import("./ubeat"),
  module => module.default,
);

export const PikkUpPage = lazyLoad(
  () => import("./pikkup"),
  module => module.default,
);
