// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 60 60" {...props} color="transparent">
      <path
        d="M29.9975 60C22.8678 60 17.2812 46.8216 17.2812 30C17.2812 13.1775 22.8678 0 29.9975 0C37.1272 0 42.7128 13.1775 42.7128 30C42.7119 46.8216 37.1272 60 29.9975 60ZM29.9975 1.875C24.1212 1.875 19.1562 14.7544 19.1562 30C19.1562 45.2456 24.1222 58.125 29.9975 58.125C35.8728 58.125 40.8378 45.2456 40.8378 30C40.8378 14.7544 35.8737 1.875 29.9975 1.875Z"
        fill={theme.colors[color]}
      />
      <path
        d="M12.7466 49.0791C8.66849 49.0791 5.77537 47.865 4.38224 45.5672C0.683807 39.4659 9.03693 27.8484 23.4013 19.1194C31.8482 13.9856 40.7647 10.9219 47.2541 10.9219C51.3322 10.9219 54.2235 12.1359 55.6166 14.4328C59.3169 20.535 50.9619 32.1516 36.5994 40.8816C28.1526 46.0144 19.236 49.0791 12.7466 49.0791ZM47.2541 12.7959C41.0929 12.7959 32.5401 15.7584 24.3754 20.7206C11.3563 28.6312 2.93568 39.5644 5.98631 44.5941C7.29412 46.7513 10.3841 47.2031 12.7466 47.2031C18.906 47.2031 27.4588 44.2406 35.6254 39.2784C48.6426 31.3678 57.0632 20.4338 54.0126 15.4041C52.7057 13.2488 49.6166 12.7959 47.2541 12.7959Z"
        fill={theme.colors[color]}
      />
      <path
        d="M47.2008 48.6995C40.7648 48.6995 31.957 45.8289 23.6405 41.0233C9.08859 32.612 0.481404 21.182 4.04578 15.0002C5.44265 12.5795 8.46797 11.3008 12.7983 11.3008C19.2342 11.3008 28.042 14.1705 36.3567 18.977C50.9095 27.3883 59.5158 38.8192 55.9505 45.0002C54.5555 47.4198 51.5292 48.6995 47.2008 48.6995ZM12.7983 13.1758C10.2736 13.1758 6.98672 13.6548 5.66953 15.9367C2.73047 21.033 11.3902 31.7767 24.577 39.3995C32.6208 44.0486 41.0789 46.8245 47.1998 46.8245C49.7245 46.8245 53.0095 46.3445 54.3267 44.0627C57.2658 38.9664 48.6061 28.2227 35.4192 20.5998C27.3755 15.9508 18.9202 13.1758 12.7983 13.1758Z"
        fill={theme.colors[color]}
      />
      <path
        d="M29.9975 34.9399C27.2769 34.9399 25.0625 32.7246 25.0625 30.0002C25.0625 27.2759 27.2759 25.0605 29.9975 25.0605C32.7191 25.0605 34.9325 27.2759 34.9325 30.0002C34.9325 32.7246 32.7181 34.9399 29.9975 34.9399ZM29.9975 26.9355C28.3091 26.9355 26.9375 28.3099 26.9375 30.0002C26.9375 31.6896 28.3091 33.0649 29.9975 33.0649C31.6841 33.0649 33.0575 31.6896 33.0575 30.0002C33.0575 28.3099 31.6841 26.9355 29.9975 26.9355Z"
        fill={theme.colors[color]}
      />
    </Svg>
  );
};

export default Icon;
