// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { ROUTES } from "navigation/routes";
import { EXTERNAL_LINKS } from "configs";
// Types
import { ServicesPageContent, ServicesVariants } from "../types";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";

export const MAINTENANCE_SUPPORT_CONTENT: ServicesPageContent = {
  banner: {
    title: "Maintenance & Support",
    description:
      "Idealogic observes and maintains IT products of various complexity, such as websites, e-commerce stores, SaaS products, internal software, CRMs, DeFi projects, crypto exchanges, etc. We want to form a reliable partnership with you, so we have a team that ensures everything in your systems works fine.",
    imageSrc: "/images/services-banner-maintenance.webp",
    imageMobileSrc: "/images/services/meintenance-and-support/meintenance-and-support-bg-mobile.webp",
    imageRatio: 1.75,
  },
  needs: {
    title: "Why you might need maintenance & support?",
    description:
      "We oversee the possibility of any issues with your product before they cause any damage to ensure a high-end experience.",
    needsList: [
      {
        label: "Avoid unexpected expenses",
        description:
          "Under Idealogic’s surveillance, you are less likely to have urgent problems as we provide regular updates on your product’s performance, anticipate possible difficulties, and fix them at once. It is a more cost-efficient approach than reacting to issues that are already critical.",
        iconSrc: "/images/mvp-development-needs-wallet-icon.svg",
      },
      {
        label: "Upgrade the security measures",
        description:
          "With our maintenance & support package, you get all the benefits from checking and updating security measures, ensuring they stand to vulnerabilities and comply with the latest regulations in your industry.",
        iconSrc: "/images/mvp-development-needs-shield-up-icon.svg",
      },
      {
        label: "Be in touch with professionals",
        description:
          "Our maintenance & support team always follows the latest industry updates to integrate them into their projects. When you choose Idealogic, you get the guarantee that your product is well-observed and performs to its best.",
        iconSrc: "/images/mvp-development-needs-call-chat-rounded-icon.svg",
      },
    ],
  },
  benefits: {
    description:
      "Find out some of the main pros of choosing Idealogic as your provider for maintenance & support services.",
    benefitsList: [
      {
        title: "Immediate troubleshooting",
        description:
          "Idealogic’s team promptly identifies and resolves the issues arising in your software. We fix bugs and errors and address any technical problems in real-time to guarantee stable and uninterrupted performance.",
      },
      {
        title: "Additional modules installation",
        description:
          "We swiftly integrate new modules or features, such as plugins, extensions, or other modules, into your software to enhance functionality. We aim to add new capabilities to your websites or applications and use every known technology for this purpose.",
      },
      {
        title: "Timely updates",
        description:
          "We may regularly update your software to incorporate the latest features, security patches, and improvements to help you keep your apps up-to-date and aligned with industry standards. With our holistic approach, you will exceed customer expectations.",
      },
      {
        title: "Malicious code check",
        description:
          "Our team continuously monitors, detects, and addresses any potential security threats or malicious code. Having years of experience in industries like blockchain, fintech, logistics, aviation, real estate, and media, we define security as our number one priority.",
      },
    ],
    isButtonPositionStart: true,
  },
  pipeline: {
    description: "In our MVP Development workflow, we use design thinking methodology",
    pipelineList: [
      {
        label: "Empathize/Define",
        description:
          "It is the crucial initial phase in the design thinking process. It involves understanding and connecting with the end users' needs, feelings, and challenges to define the problem statement. This step sets the foundation for innovative and user-centric design solutions by fostering empathy and uncovering the core issues that need solving.",
        iconSrc: "/images/services-pipeline-empathize-icon.svg",
        deliverablesList: [
          { label: "Figma Layout", iconSrc: "/images/services-pipeline-deliverables-figma-icon.svg" },
          { label: "Interactive Prototype", iconSrc: "/images/services-pipeline-deliverables-interactive-icon.svg" },
          { label: "Deep Research", iconSrc: "/images/services-pipeline-deliverables-research-icon.svg" },
          { label: "Other", iconSrc: "/images/services-pipeline-deliverables-other-icon.svg" },
        ],
      },
      {
        label: "Analyse/Ideate",
        description:
          "Analyze/Ideate represents the creative heart of the design thinking process. During this phase, designers delve into the insights gathered from empathy and define stages to brainstorm, explore, and generate a wide range of ideas and concepts.",
        iconSrc: "/images/services-pipeline-empathize-icon.svg",
        deliverablesList: [
          { label: "Figma Layout", iconSrc: "/images/services-pipeline-deliverables-figma-icon.svg" },
          { label: "Interactive Prototype", iconSrc: "/images/services-pipeline-deliverables-interactive-icon.svg" },
          { label: "Deep Research", iconSrc: "/images/services-pipeline-deliverables-research-icon.svg" },
          { label: "Other", iconSrc: "/images/services-pipeline-deliverables-other-icon.svg" },
        ],
      },
      {
        label: "Design/Prototype",
        description:
          "This is tangible or digital representation of a product, service, or concept created during the design and development process. It serves as a visual and often interactive model that helps designers and stakeholders test and define designs.",
        iconSrc: "/images/services-pipeline-empathize-icon.svg",
        deliverablesList: [
          { label: "Figma Layout", iconSrc: "/images/services-pipeline-deliverables-figma-icon.svg" },
          { label: "Interactive Prototype", iconSrc: "/images/services-pipeline-deliverables-interactive-icon.svg" },
          { label: "Deep Research", iconSrc: "/images/services-pipeline-deliverables-research-icon.svg" },
          { label: "Other", iconSrc: "/images/services-pipeline-deliverables-other-icon.svg" },
        ],
      },
      {
        label: "Test",
        description:
          "It's the critical phase where prototypes or designs are evaluated for functionality, usability, and overall effectiveness. It involves gathering feedback from users, stakeholders, or target audiences to identify strengths and weaknesses. Testing helps designers refine their work, ensuring it aligns with user needs and expectations",
        iconSrc: "/images/services-pipeline-empathize-icon.svg",
        deliverablesList: [
          { label: "Figma Layout", iconSrc: "/images/services-pipeline-deliverables-figma-icon.svg" },
          { label: "Interactive Prototype", iconSrc: "/images/services-pipeline-deliverables-interactive-icon.svg" },
          { label: "Deep Research", iconSrc: "/images/services-pipeline-deliverables-research-icon.svg" },
          { label: "Other", iconSrc: "/images/services-pipeline-deliverables-other-icon.svg" },
        ],
      },
    ],
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.planetcoin, CaseStudiesSlugEnums.glue],
    title: "Maintenance & Support case studies",
    TitleIcon: WavingHandIcon,
    description:
      "We believe in blockchain technology versatility and prove that with our completed projects. Aviation, gaming, finance, and more — check for yourself.",
  },
  coveredIndustries: {
    coveredIndustriesList: [
      { label: "Product design", link: EXTERNAL_LINKS.idealogicDesign, isExternal: true },
      { label: "Technical consulting", link: `/${ROUTES.services}/${ServicesVariants.technicalConsulting}` },
      { label: "MVP Development", link: `/${ROUTES.services}/${ServicesVariants.mvpDevelopment}` },
      { label: "Dedicated team", link: `/${ROUTES.services}/${ServicesVariants.dedicatedTeam}` },
    ],
  },
  stagesBlocks: [
    {
      title: "Receiving a request",
      description:
        "We receive customer requests via various channels, including e-mail, phone, web form, chat, etc. We register every application and gather all the information provided by the customer to understand the issue that arose better.",
      iconSrc: "/images/keys-stages/receiving-a-request-icon.svg",
    },
    {
      title: "Prioritizing a request",
      description:
        "All received requests are processed and classified by types, such as service requests, problem reports, change requests, etc. Our specialists define the priority of the issue considering its urgency and business impact.",
      iconSrc: "/images/keys-stages/prioritizing-a-request-icon.svg",
    },
    {
      title: "Assigning a specialist",
      description:
        "We assign a specialist from our IT department based on the priority and type of request. Usually, they handle it promptly. However, in rare cases of complex problems detected, we delegate the task to a specific team or senior management.",
      iconSrc: "/images/keys-stages/assigning-a-specialist-icon.svg",
    },
    {
      title: "Analysis and issue resolution",
      description:
        "At this point, we deliberately work on the problem and provide a straightforward solution, using diagnostics, turning to our knowledge base, intervening in the system to detect the mistake, programming, etc.",
      iconSrc: "/images/keys-stages/analysis-and-issue-resolution-icon.svg",
    },
    {
      title: "Testing",
      description:
        "We always test our problem resolutions to ensure everything is fixed and working accurately. In some cases, the process also involves users to see whether the system is working fine from their point of view.",
      iconSrc: "/images/keys-stages/testing-icon.svg",
    },
    {
      title: "Ticket closure",
      description:
        "We communicate with a customer and inform them about the problem resolution. Our goal is to make sure they are satisfied with the final result. Only after that is definite, the support team may close the ticket.",
      iconSrc: "/images/keys-stages/ticket-closure-icon.svg",
    },
    {
      title: "Gathering feedback",
      description:
        "We strive to satisfy our customers with support services; therefore, we prioritize their feedback in our performance analysis. If there are critical remarks about our work or communication style, we work on fixing them.",
      iconSrc: "/images/keys-stages/gathering-feedback-icon.svg",
    },
    {
      title: "Documenting our activities",
      description:
        "We save the details of every request to analyze them further and avoid similar issues in the future. Our team adds every problem resolution to compose our knowledge base for quick access if something similar occurs again.",
      iconSrc: "/images/keys-stages/documenting-our-activities-icon.svg",
    },
  ],
};

export default MAINTENANCE_SUPPORT_CONTENT;
