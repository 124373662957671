// Components + styling
import { Box, Column, Flex, Text, Link, Image } from "components";
import { StyledDefaultDropdownWrapper } from "components/header/styled";
import { DropdownMenuItems } from "../dropdown-menu-items";
import { CompanyDropdownDescription } from "./components";
import { StyledLinkedInWrapper, StyledMemberItem, StyledOurCompanyList } from "./styled";
import { LinkedInIcon, ServicesInsightIcon } from "components/svg";
import { StyledDropdownTitleContainer } from "../industries-dropdown/styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { OUR_COMPANY_DROPDOWN_LIST, OUR_COMPANY_MEMBERS_LIST } from "./constants";
// Types
import { DropdownComponentProps } from "components/dropdown/types";

export const OurCompanyDropdown: React.FC<DropdownComponentProps> = ({ onDropdownMenuClick }) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <StyledDefaultDropdownWrapper>
      <StyledOurCompanyList>
        <Column
          flexDirection={{ laptop: "row" }}
          justifyContent={{ laptop: "space-between" }}
          mb={{ _: "1.5rem", laptopL: "0" }}
          width="100%"
        >
          <Column
            width={{ _: "100%", laptop: "12.5rem" }}
            mr={{ _: "0", laptop: "1rem" }}
            mb={{ _: "2rem", laptop: "0" }}
          >
            <StyledDropdownTitleContainer>
              <ServicesInsightIcon width="1.8125rem" height="1.75rem" />

              <Text textScale="p20Bold" color="white">
                {t("Insights")}
              </Text>
            </StyledDropdownTitleContainer>

            <DropdownMenuItems
              onDropdownMenuClick={onDropdownMenuClick}
              navSections={OUR_COMPANY_DROPDOWN_LIST}
              wrapperProps={{ mt: "0" }}
              width={{ _: "100%", laptop: "12.5rem" }}
            />

            {!isDesktop && <CompanyDropdownDescription />}
          </Column>

          <Column
            flexDirection={{ tablet: "row" }}
            justifyContent={{ tablet: "space-between" }}
            onClick={e => e.stopPropagation()}
          >
            {OUR_COMPANY_MEMBERS_LIST.map(({ imageSrc, name, position, description, linkedinLink }) => (
              <StyledMemberItem key={name}>
                <Flex mb={{ _: "0.5rem", laptop: "0.75rem" }}>
                  <Image width={isDesktop ? "3.75rem" : "3rem"} src={imageSrc} aspectRatio={1} variant="circle" />
                  <Box mx={{ _: "0.5rem", laptop: "1.2rem" }}>
                    <Flex alignItems="center">
                      <Text textScale={isDesktop ? "p20Bold" : "p16Bold"} color="white">
                        {t(name)}
                      </Text>
                      <StyledLinkedInWrapper>
                        <Link pl="0.5rem" external href={linkedinLink}>
                          <LinkedInIcon color="linkedInLinkHover" />
                        </Link>
                      </StyledLinkedInWrapper>
                    </Flex>
                    <Text textScale={isDesktop ? "p14Regular" : "p12Regular"} color="textFourthForDarkBg">
                      {t(position)}
                    </Text>
                  </Box>
                </Flex>
                <Text textScale={isDesktop ? "p16Regular" : "p14Regular"} color="textSecond">
                  {t(description)}
                </Text>
              </StyledMemberItem>
            ))}
          </Column>
        </Column>

        {isDesktop && <CompanyDropdownDescription />}
      </StyledOurCompanyList>
    </StyledDefaultDropdownWrapper>
  );
};
