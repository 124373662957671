import styled from "styled-components";

export const StyledTestimonialsSliderWrapper = styled.div`
  position: relative;

  .slick-slide {
    transition: ${({ theme }) => theme.transitions.easeInOut_0_8s};
    transform: scale(0.7, 0.7);
  }

  .slick-slide[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"],
  .slick-slide.slick-current.slick-active {
    transition: ${({ theme }) => theme.transitions.easeInOut_0_8s};
    transform: scale(1);
  }

  .slick-slide,
  .slick-slide[aria-hidden="true"]:not(.slick-cloned) ~ .slick-cloned[aria-hidden="true"] {
    transition: ${({ theme }) => theme.transitions.easeInOut_0_8s};
  }

  /* slide when active/center */
  .slick-center,
  .slick-slide[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"] {
    transition: ${({ theme }) => theme.transitions.easeInOut_0_8s};
    transform: scale(1);
  }
`;

export const SliderNavButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin: 1rem auto;
`;
