import { useQuery } from "@apollo/client";

import { GetCaseStudiesBySlugsResponse, GetCaseStudiesVars, getCaseStudiesBySlugs } from "services";

const useGetCaseStudiesBySlugs = (portfolioList: string[]) => {
  const whereParameter = { slug_in: portfolioList };

  const { data } = useQuery<GetCaseStudiesBySlugsResponse, GetCaseStudiesVars>(getCaseStudiesBySlugs, {
    variables: { where: whereParameter },
  });

  const { caseStudies = [] } = data || {};

  return { caseStudies };
};

export default useGetCaseStudiesBySlugs;
