import styled from "styled-components";
// Components
import { DefaultSlideImage } from "components";

export const StyledSlideImage1 = styled(DefaultSlideImage)`
  width: 1.625rem;
  position: absolute;
  top: -0.625rem;
  right: 14.375rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 1.25rem;
    right: 16.875rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: -1.25rem;
    right: 19.375rem;
  }
`;

export const StyledSlideImage2 = styled(DefaultSlideImage)`
  width: 2.875rem;
  position: absolute;
  top: 3.125rem;
  right: 9rem;
  transform: rotate(10deg);

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 5rem;
    right: 10rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: 3.75rem;
    right: 11.25rem;
  }
`;

export const StyledSlideImage3 = styled(DefaultSlideImage)`
  width: 0.75rem;
  position: absolute;
  top: 0.375rem;
  right: 6.25rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 1.25rem;
    right: 6.875rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: 0.625rem;
    right: 6.25rem;
  }
`;

export const StyledSlideImage4 = styled(DefaultSlideImage)`
  width: 3.5rem;
  position: absolute;
  top: 2.5rem;
  right: 0;

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 5.625rem;
    right: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: 4.375rem;
    right: -1.875rem;
  }
`;
