// Types
import { PortfolioProps } from "components/portfolio/types";
import { BannerDefaultProps } from "components/banner-default/types";
import { CoveredIndustriesProps } from "components/covered-industries/types";
import { IndustryInfoProps } from "./components/industry-info/types";
import { WhoWeServeType } from "./components/who-we-serve/types";
import { BookACallWithExpertType } from "./components/book-a-call-with-expert/types";
import { OurCapabilitiesType } from "./components/our-capabilities/types";
import { OurServicesFintechType } from "./components/our-services-fintech/types";
import { BenefitsProps } from "pages/services/components/benefits/types";
import { OurCustomerReviewType } from "./components/our-customer-review/types";
import { StagesBlocksItemProps } from "components/stages-blocks/components/stages-blocks-item/types";
import { BoostExpertiseProps } from "pages/services/components/boost-expertise/types";
import { OurExpertiseProps } from "pages/services/components/our-expertise/types";
import { CustomizedApplicationsProps } from "pages/services/components/customized-applications/types";
import { IndustriesType } from "pages/landing/components/industries/types";
import { TitleWithBackgroundType } from "pages/services/components/page-structure/ai-software-development-page/component/title-with-background/types";
import { OurApproachProps } from "./components/page-structure/custom-real-estate-page/components/our-approach/types";
import { HowCanHelpProps } from "./components/page-structure/custom-real-estate-page/components/how-can-help/types";
import { UnleashingInnovationProps } from "./components/page-structure/custom-real-estate-page/components/unleashing-innovation/types";
import { TechnologiesType } from "pages/about-us/components/technologies/types";
import { OptimizeOperationPointsProps } from "./components/page-structure/property-management-solutions-page/components/optimize-operations-points/types";

export const IndustriesVariants = {
  aiBasedAviation: "ai-based-aviation",
  fintech: "fintech",
  banking: "banking",
  cryptoCurrency: "crypto-currency",
  intelligentInventoryManagement: "intelligent-inventory-management",
  logistics: "logistics",
  aviation: "aviation",
  realEstate: "realEstate",
  media: "media",
  other: "other",
  digitalBanking: "digital-banking",
  sparePartsMarketplace: "spare-parts-marketplace",
  blockchainBasedAviation: "blockchain-based-aviation",
  customRealEstate: "custom-real-estate",
  propertyManagementSolutions: "property-management-solutions",
  agentBrokersSolutions: "agent-brokers-solutions",
} as const;

export type IndustriesOutletProps = {
  content: IndustryPageContent;
};

export type ServiceVariantContent = {
  [key in keyof typeof IndustriesVariants]: IndustryPageContent;
};

export type IndustriesVariantsType = keyof typeof IndustriesVariants;

export type IndustryPageContent = {
  banner: BannerDefaultProps;
  industryInfo?: IndustryInfoProps;
  portfolio?: PortfolioProps;
  coveredIndustries?: CoveredIndustriesProps;
  whoWeServe?: WhoWeServeType;
  bookACallWithExpert?: BookACallWithExpertType;
  ourCapabilities?: OurCapabilitiesType;
  ourServicesFintech?: OurServicesFintechType; //TODO: remove ? after all pages will done
  benefits?: BenefitsProps;
  additionalBenefits?: BenefitsProps;
  ourCustomerReview?: OurCustomerReviewType;
  chooseOurExperience?: OurExpertiseProps;
  stagesBlocks?: StagesBlocksItemProps[];
  developmentSolution?: WhoWeServeType;
  boostBusiness?: BoostExpertiseProps;
  customizedApplications?: CustomizedApplicationsProps;
  relatedArticlesFilterID?: string; // it`s id for take articles for page topic from HighGraph
  titleWithBackground?: TitleWithBackgroundType;
  stretchingCard?: IndustriesType;
  ourApproach?: OurApproachProps;
  howCanHelp?: HowCanHelpProps;
  unleashingInnovation?: UnleashingInnovationProps;
  ourProcess?: BenefitsProps;
  technologiesWeUse?: TechnologiesType;
  optimizeOperations?: OptimizeOperationPointsProps;
};
