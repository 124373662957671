import { Text } from "components";
import {
  StyledFooterLink,
  StyledSectionTitle,
  StyledContentWrapper,
  StyledFooterLinksWrapper,
  StyledDisableLinkContainer,
  StyledFooterContentContainer,
} from "components/footer/styled";
import { FooterAccordion } from "..";
import { StyledComingSoonContainer } from "components/header/components/dropdown-menu-items/components/dropdown-item/styled";
import { StyledFooterContentItemFlexGap } from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Context
import { useTranslation } from "context";
// Types
import { FooterContentItemProps } from "./types";

const FooterContentItem: React.FC<FooterContentItemProps> = ({ label, navList }) => {
  const { t } = useTranslation();

  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  const { isMobile } = useMatchBreakpoints();

  return (
    <StyledContentWrapper ref={wrapperRef}>
      {label && (
        <StyledSectionTitle textScale="p14Medium" color="textFourth">
          {t(label)}
        </StyledSectionTitle>
      )}

      <StyledFooterContentItemFlexGap>
        {navList.map(({ title, link, navItems, isExternal }, index) => {
          return (
            <div key={index}>
              {navItems ? (
                <StyledFooterContentContainer isHaveLabel={!label}>
                  <FooterAccordion title={title} navItems={navItems} />
                </StyledFooterContentContainer>
              ) : (
                <>
                  {link ? (
                    <StyledFooterContentContainer isHaveLabel={!label}>
                      <StyledFooterLinksWrapper>
                        <StyledFooterLink
                          href={link}
                          external={isExternal}
                          color={isMobile ? "textFourthForDarkBg" : "white"}
                        >
                          {title}
                        </StyledFooterLink>
                      </StyledFooterLinksWrapper>
                    </StyledFooterContentContainer>
                  ) : (
                    <StyledFooterContentContainer isHaveLabel={!label}>
                      <StyledDisableLinkContainer>
                        <Text color="textFourth" textScale="p14Regular" whiteSpace="nowrap">
                          {title}
                        </Text>

                        <StyledComingSoonContainer>
                          <Text textScale="p14Regular" color="disabledLight">
                            {t("Soon")}
                          </Text>
                        </StyledComingSoonContainer>
                      </StyledDisableLinkContainer>
                    </StyledFooterContentContainer>
                  )}
                </>
              )}
            </div>
          );
        })}
      </StyledFooterContentItemFlexGap>
    </StyledContentWrapper>
  );
};

export default FooterContentItem;
