import { useNavigate } from "react-router-dom";

import { useBlur, useTranslation } from "context";

import { Link, Flex, Text } from "components";
import { StyledComingSoonContainer, StyledDropdownItem } from "./styled";

import { DropdownItemProps } from "./types";

const DropdownItem: React.FC<DropdownItemProps> = ({ title, isExternal, link, onDropdownMenuClick, ...props }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsVisible } = useBlur();

  const RenderWrapper = isExternal ? Link : Flex;
  const isDisabled = !link;

  return (
    <StyledDropdownItem
      isDisabled={isDisabled}
      onClick={() => {
        if (!isDisabled) {
          onDropdownMenuClick?.();
          !isExternal && navigate(link);
          setIsVisible(false);
        }
      }}
      {...props}
    >
      <RenderWrapper width="100%" external href={link} alignItems="center">
        <Text textScale="p14Regular" color={isDisabled ? "textFourth" : "navItemFourthForDarkBg"}>
          {t(title)}
        </Text>

        {isDisabled && (
          <StyledComingSoonContainer>
            <Text textScale="p14Regular" color="disabledLight">
              {t("Soon")}
            </Text>
          </StyledComingSoonContainer>
        )}
      </RenderWrapper>
    </StyledDropdownItem>
  );
};

export default DropdownItem;
