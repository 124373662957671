import { BenefitsItemProps } from "pages/services/components/benefits/components/benefits-item/types";
import { WhoWeServeItemType } from "../../industries/components/who-we-serve/types";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { WavingHandIcon } from "components/svg";
import { IndustryPageContent } from "../../industries/types";
import { EXTERNAL_LINKS } from "configs";

export const BLOCKCHAIN_INDUSTRY_BENEFITS_LIST: BenefitsItemProps[] = [
  {
    title: "Discover & Estimate",
    description:
      "We start by closely learning your business requirements, defining what technical solutions suit you best, and providing you with a detailed plan of work scope.",
  },
  {
    title: "Design and Prototype",
    description:
      "We present you with a vision of your future product with basic features so that you can make adjustments and define the course of future development.",
  },
  {
    title: "Development",
    description:
      "Our team of relevant specialists works on the technical implementation of the project, using cutting-edge technologies.",
  },
  {
    title: "Testing",
    description:
      "We do automation and manual testing to ensure every feature works properly and the product can perform to its full potential.",
  },
  {
    title: "Deployment",
    description:
      "We are ready to release the product only after we are satisfied with its usability, security compliance, and reliability.",
  },
  {
    title: "Maintenance",
    description:
      "Our team is keeping up with the product’s performance after the launch to forecast and prevent any technical issues and urgently react if something goes wrong.",
  },
];

export const BLOCKCHAIN_WHO_WE_SERVE_LIST: WhoWeServeItemType[] = [
  {
    text: "Private & Public Blockchains",
    subText:
      "Leverage the power of private and public blockchains tailored to your business needs for secure and transparent data management.",
  },
  {
    text: "CEX & DEX Development",
    subText:
      "Enhance your trading platforms with our expertise in developing Centralized (CEX) and Decentralized (DEX) exchanges for seamless and secure transactions.",
  },
  {
    text: "DeFi & GameFi Solutions",
    subText:
      "Enter the world of decentralized finance (DeFi) and blockchain-based gaming (GameFi) with our innovative solutions for financial and gaming applications.",
  },
  {
    text: "Smart Contracts Development",
    subText:
      "Automate and streamline your business processes with our smart contract development services, ensuring secure and trustless execution of agreements.",
  },
  {
    text: "Tokenization Solutions",
    subText:
      "Transform real-world assets into digital tokens with our tokenization solutions, unlocking new possibilities for investment and liquidity.",
  },
  {
    text: "Apps Development",
    subText:
      "Create custom blockchain-based applications tailored to your business requirements, driving innovation and efficiency in your operations.",
  },
];

const BLOCKCHAIN_CONTENT: IndustryPageContent = {
  banner: {
    title: "Blockchain Solutions for Your Business",
    description:
      "Empower your business with advanced blockchain technology solutions for enhanced security, transparency, and efficiency.",
    imageSrc: "/images/expertise/blockchain/blockchain-banner.webp",
    imageRatio: 1.76,
    buttonLabel: "Get a Free Estimate Today",
  },
  whoWeServe: {
    title: "Explore Our Blockchain Expertise",
    description:
      "Discover how Idealogic implements blockchain technology across various industries. Check out some of the most widely used examples below:",
    whoWeServeListLayoutProps: {
      maxHeight: { _: "100%", laptop: "16.1875rem", laptopL: "14.1875rem" },
    },
    isSubText: true,
    whoWeServeList: BLOCKCHAIN_WHO_WE_SERVE_LIST,
  },
  bookACallWithExpert: {
    bgSrc: "/images/expertise/blockchain/book-call-with-a-expert/bg.webp",
    bgMobileSrc: "/images/expertise/blockchain/book-call-with-a-expert/bg.webp",
    title: {
      beforeKeyWord: "Our experts are eager to dive in and revolutionize your",
      keyWord: "blockchain journey",
      afterKeyWord: "with our tailored solutions.",
    },
    isShowLinkedInTitle: false,
    description: "",
    linkedin: {
      name: "Artem Zaitsev",
      href: "https://www.linkedin.com/in/artem-zaitsev-b65825137/",
    },
    jobTitle: "VP of engineering",
    buttonText: "Book a call",
    imageSrc: "/images/expertise/blockchain/book-call-with-a-expert/book-a-call-with-expert-artem-bg.webp",
    mobileImageSrc: "/images/expertise/blockchain/book-call-with-a-expert/book-a-call-with-expert-artem-mobile-bg.webp",
    isReverse: true,
    imageAspectRatio: 1.24,
  },
  benefits: {
    title: "Our Blockchain Development Process",
    description:
      "Learn about our step-by-step approach to blockchain development, ensuring a seamless and successful project delivery.",
    isBackgroundImageNeed: false,
    isShowCall: false,
    benefitsList: BLOCKCHAIN_INDUSTRY_BENEFITS_LIST,
  },
  chooseOurExperience: {
    title: "Why Choose Idealogic for Blockchain Expertise?",
    description: "Discover the advantages of partnering with Idealogic for your blockchain needs.",
    imageSrc: "/images/expertise/expertise-banner-blockchain-choose-our-experience.webp",
    imageRatio: 1.46,
    chooseOurExperienceList: [
      {
        label: "Adaptability and Innovation",
        description:
          "At Idealogic, we prioritize staying ahead of the curve by continuously learning and integrating the latest market trends and technologies into our solutions. Our team's commitment to adaptability and innovation ensures that your blockchain projects are future-proof and cutting-edge.",
        iconSrc: "/images/expertise/blockchain/choose-our-experience/web-development-lightbulb-icon.svg",
      },
      {
        label: "Training and Certifications",
        description:
          "Benefit from our unwavering commitment to education and skill development. We ensure that our team members are equipped with the latest certifications and expertise in blockchain technology. By investing in ongoing training, we guarantee that our solutions are developed using the most up-to-date industry standards and practices.",
        iconSrc: "/images/expertise/blockchain/choose-our-experience/web-development-square-academic-cap-icon.svg",
      },
      {
        label: "Handling Various Complexity",
        description:
          "Trust Idealogic to handle even the most unique and complex blockchain projects with ease. Our team's specialized knowledge and adaptable approach enable us to tackle challenges of any scale or complexity. Whether your project requires simple solutions or intricate implementations, we have the expertise to deliver results that exceed your expectations.",
        iconSrc: "/images/expertise/blockchain/choose-our-experience/web-development-fire-icon.svg",
      },
      {
        label: "Security and Data Protection",
        description:
          "Rest assured that your data is in safe hands with Idealogic. We prioritize compliance with all security requirements and guidelines to safeguard your sensitive information. Our stringent security measures ensure that your blockchain solutions are built upon a foundation of trust and integrity, giving you peace of mind throughout the development process and beyond.",
        iconSrc: "/images/expertise/blockchain/choose-our-experience/web-development-shield-icon.svg",
      },
    ],
  },
  portfolio: {
    portfolioList: [
      CaseStudiesSlugEnums.allCryptoMechanics,
      CaseStudiesSlugEnums.zert,
      CaseStudiesSlugEnums.revolve,
      CaseStudiesSlugEnums.eplane,
    ],
    title: "Blockchain Case Studies",
    TitleIcon: WavingHandIcon,
    actionButtonText: "See More of Our Work",
    description:
      "Discover the versatility of blockchain technology through our completed projects spanning various industries.",
  },
  customizedApplications: {
    title: "White-label Blockchain Solutions",
    isParallaxVisible: false,
    isEstimationButtonVisible: true,
    isShowImposition: false,
    buttonText: "Request Your Free Estimation",
    description:
      "Unlock new revenue streams and enhance operational efficiency in the digital asset ecosystem with Idealogic's white-label blockchain solutions. Our ready-to-use infrastructure empowers you to quickly launch innovative blockchain-based products and services, saving time and resources while maximizing your market impact.",
    customizedApplicationsList: [
      {
        label: "CEX & DEX Exchange Development",
        description:
          "Elevate trading experiences with our CEX (Centralized Exchange) and DEX (Decentralized Exchange) solutions, ensuring smooth and secure transactions.",
        iconSrc: "/images/expertise/blockchain/solutions/exchange.svg",
      },
      {
        label: "Mobile Crypto Wallet Development",
        description:
          "Offer anytime, anywhere asset access with our mobile crypto wallets, prioritizing user-friendly interfaces and top-notch security.",
        iconSrc: "/images/expertise/blockchain/solutions/mobile-crypto-wallet.svg",
      },
      {
        label: "Assets Tokenization Solutions",
        description:
          "Expand investment opportunities and enhance liquidity with our innovative asset tokenization solutions, digitizing real-world assets for seamless trading.",
        iconSrc: "/images/expertise/blockchain/solutions/assets-tokenization.svg",
      },
      {
        label: "Escrow System Solutions",
        description:
          "Build trust and security into transactions with our reliable escrow systems, providing transparency and fairness for all parties involved.",
        iconSrc: "/images/expertise/blockchain/solutions/escrow-system.svg",
      },
      {
        label: "Launchpad Services",
        description:
          "Facilitate successful project launches and community engagement with our comprehensive launchpad services, offering strategic guidance and technical support.",
        iconSrc: "/images/expertise/blockchain/solutions/launchpad.svg",
      },
      {
        label: "NFT Store Development",
        description:
          "We provide the creative economy with our NFT store platform, enabling easy creation, sale, and purchase of digital collectibles.",
        iconSrc: "/images/expertise/blockchain/solutions/nft-store.svg",
      },
    ],
  },
  ourCustomerReview: {
    title: "Hear From Our Customers",
    description:
      "Explore the feedback from startups and enterprises we've partnered with. Listen to their glowing testimonials!",
    bgImageSrc: "/images/expertise/blockchain/our-customer-review/our-customer-review-bg.webp",
    bgGradient: "customerReviewDanGradient",
    isTextClamp: false,
    owner: {
      name: "Dan Jensen",
      logo: "/images/expertise/blockchain/our-customer-review/revolve-logo.svg",
      logoSize: "11.625rem",
      logoAspectRatio: 3.875,
      jobTitle: "CEO, Co-founder of Revolve Games",
      videoSrc: EXTERNAL_LINKS.youtubeDanReview,
      videoInfoImg: "/images/testimonial/full-dan-jensen.webp",
      reviewText:
        "We have been working in partnership with Idealogic for a few months. They have so far been instrumental in turning our vision into a reality. Have been impressed with the quality of development and the team are great to work with.",
    },
  },
};

export default BLOCKCHAIN_CONTENT;
