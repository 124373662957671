import styled from "styled-components";
// Components
import { Container } from "components";

export const StyledSliderTitle = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1.5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding-right: 1.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-bottom: 3rem;
    padding-left: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    padding-right: 4.5rem;
    padding-left: 3.5rem;
  }

  @media screen and (min-width: 1600px) {
    padding-left: 4.5rem;
  }
`;
