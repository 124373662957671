// Components + styling
import { Column, Container, Heading, Image, TabsPanel, Text } from "components";
import { TechStackParallaxImage } from "./components";
import { StyledTab, StyledTabsPanelWrapper, StyledTabsWrapper, StyledTechStackImageWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { TECH_STACK_TABS_LIST } from "./constants";

const TechStack: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useMatchBreakpoints();

  const [activeTechStackList, setActiveTechStackList] = React.useState(0);

  const titlesList = TECH_STACK_TABS_LIST.map(({ title }) => t(title));

  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <div ref={wrapperRef}>
      <Container
        position="relative"
        pt={{ _: "4rem", laptop: "6.875rem" }}
        pb={{ _: "0.5rem", laptop: "1.5rem" }}
        px={{ _: "1rem", tablet: "2rem", laptopL: "4.5rem" }}
      >
        {!isMobile && <TechStackParallaxImage ref={wrapperRef} />}

        <Heading scale="h2" as="h2" mb={{ _: "0.25rem", laptop: "1rem" }}>
          {t("Our tech stack")}
        </Heading>

        <Text textScale={isDesktop ? "p22Regular" : "p16Regular"} color="textFifth">
          {t("We are using cutting-edge technologies for ensuring scalability and performance")}
        </Text>
      </Container>

      <Container pb={{ _: "2rem", laptop: "4.625rem" }} px={{ _: "1rem", tablet: "2rem", laptopL: "4.5rem" }}>
        <StyledTabsPanelWrapper>
          <TabsPanel
            titles={titlesList}
            activeTab={activeTechStackList}
            onTabClick={setActiveTechStackList}
            textScale="p18Regular"
            activeTextScale="p20Bold"
            defaultTextColor="textFifth"
          />
        </StyledTabsPanelWrapper>

        <StyledTabsWrapper>
          {TECH_STACK_TABS_LIST.map((item, index) => (
            <StyledTab key={index} isActive={activeTechStackList === index}>
              {item.techList.map(({ iconSrc, label }) => (
                <Column key={label} justifyContent="center">
                  <StyledTechStackImageWrapper>
                    <Image width={isDesktop ? "7.5rem" : "4rem"} src={iconSrc} aspectRatio={1} />
                  </StyledTechStackImageWrapper>

                  <Text textScale={isDesktop ? "p16Medium" : "p12Medium"} textAlign="center" color="textFourth">
                    {label}
                  </Text>
                </Column>
              ))}
            </StyledTab>
          ))}
        </StyledTabsWrapper>
      </Container>
    </div>
  );
};

export default TechStack;
