import { useLocation, useNavigate } from "react-router-dom";
// Components + styling
import { Button, FlexGap } from "components";
// TODO: uncomment LanguageSelector when we will used translations
import {
  BurgerMenu,
  HeaderLogo,
  MobileMenu,
  NavigationMenu,
  //  LanguageSelector
} from "./components";
import {
  StyledHeader,
  StyledMotionHeader,
  StyledHeaderWrapper,
  StyledHeaderContainer,
  StyledHeaderButtonWrapper,
} from "./styled";
// Context
import { useBlur, useTranslation } from "context";
// Hooks
import { useElementDimensions, useMatchBreakpoints } from "hooks";
import { useHeaderTheme, useMotionAnimations } from "./hooks";
// Utils
import { removeTrailingSlashIfExists, scrollToElementById } from "utils";
// Constants
import { BLOCK_ID } from "configs";
import {
  headerThemes,
  headerAnimationType,
  CONTACT_US_OUT_PATHNAME_LIST,
  MOBILE_MENU_ANIMATION_DURATION,
} from "./constants";

// Routes
import { ROUTES } from "navigation/routes";
// Types
const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const navigate = useNavigate();

  const {
    elementDimensions: { offsetHeight },
  } = useElementDimensions({ id: BLOCK_ID.header });
  const { t } = useTranslation();
  const { isVisible } = useBlur();
  const { isDesktop } = useMatchBreakpoints();
  const { pathname, hash } = useLocation();
  const { headerAnimationsVariants, headerAnimation } = useMotionAnimations();
  const { headerTheme, isPathInLightThemePathList, isUserOnIdPage, isTransparent } = useHeaderTheme();

  const isMobileMenuOpen = isMenuOpen && !isDesktop;
  const isCalendlyPage = pathname.startsWith(`/${ROUTES.calendly}`);

  React.useEffect(() => {
    if (isMobileMenuOpen) {
      setIsMenuOpen(false);
    }
  }, [pathname, hash]);

  const handleClickLetsTalk = () => {
    const basePathname = removeTrailingSlashIfExists(pathname);
    if (CONTACT_US_OUT_PATHNAME_LIST.includes(basePathname)) {
      navigate(`${ROUTES.landing}#${BLOCK_ID.contactUs}`);
    } else {
      // Need to use timeout, because of mobiles menu. For correct logic working, we need to wait closing mobile menu. and then scroll to element.
      setTimeout(
        () => {
          scrollToElementById(BLOCK_ID.contactUs);
        },
        isDesktop ? 0 : MOBILE_MENU_ANIMATION_DURATION,
      );
    }
  };

  return (
    <StyledHeader
      pb={offsetHeight}
      isBgWhite={isPathInLightThemePathList || isUserOnIdPage}
      isMobileMenuOpen={isMobileMenuOpen}
    >
      <StyledMotionHeader initial={false} animate={headerAnimation} variants={headerAnimationsVariants}>
        <StyledHeaderWrapper
          isMobileMenuOpen={isMobileMenuOpen}
          isTransparent={isTransparent}
          headerTheme={headerTheme}
          isVisible={isVisible}
        >
          <StyledHeaderContainer isTransparent={isTransparent} headerTheme={headerTheme} id={BLOCK_ID.header}>
            <HeaderLogo headerTheme={headerTheme} isMobileMenuOpen={isMobileMenuOpen} setIsMenuOpen={setIsMenuOpen} />

            {isDesktop && <NavigationMenu headerTheme={headerTheme} />}

            <FlexGap gap="1rem" alignItems="center">
              {/* TODO: uncomment LanguageSelector when we will used translations */}
              {/* {isDesktop && <LanguageSelector />} */}

              <StyledHeaderButtonWrapper>
                {!isCalendlyPage && !isMobileMenuOpen && (
                  // The button will be hidden on the ‘route calendly’ page or if the menu on the mobile device is open
                  <Button
                    width={isDesktop ? "9.75rem" : "inherit"}
                    ml="1.5rem"
                    scale={isDesktop ? "md" : "sm"}
                    buttonTheme={headerTheme !== headerThemes.light ? "light" : "dark"}
                    hoverLinesPosition="bottomLeft"
                    isShowHoverLines={headerAnimation === headerAnimationType.static}
                    ariaLabel="lets-talk"
                    onClick={handleClickLetsTalk}
                  >
                    {t("Contact us")}
                  </Button>
                )}
              </StyledHeaderButtonWrapper>

              {!isDesktop ? (
                <BurgerMenu
                  color={headerTheme === headerThemes.light && !isMobileMenuOpen ? "bgDark" : "white"}
                  isOpen={isMenuOpen}
                  setIsOpen={setIsMenuOpen}
                />
              ) : null}
            </FlexGap>
          </StyledHeaderContainer>

          {isMobileMenuOpen && (
            <MobileMenu
              isMenuOpen={isMobileMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
              onClickLetsTalk={handleClickLetsTalk}
            />
          )}
        </StyledHeaderWrapper>
      </StyledMotionHeader>
    </StyledHeader>
  );
};

export default Header;
