import styled, { css } from "styled-components";
// Components
import { Text } from "components";
// Types
import { Colors } from "theme/types";

const mobileTabStyles = css`
  background: ${({ theme }) => theme.gradients.buttonAccent};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const mobileBeforeTabStyles = css`
  bottom: 1.25rem;
  width: 100%;
  height: 0.0875rem;
  background: ${({ theme }) => theme.gradients.buttonAccent};
`;

export const StyledTabWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

export const StyledDivWrapper = styled.div<{ isActive?: boolean }>`
  background: transparent;

  ${({ theme }) => theme.mediaQueries.tablet} {
    border: 1px solid ${({ theme }) => theme.colors.tabsBorderColor};
    border-radius: ${({ theme }) => theme.radii.videoTestimonialDecorLabel};
    background: ${({ theme }) => theme.colors.bgSecond};

    ${({ isActive }) =>
      isActive &&
      css`
        border: none;
        background: ${({ theme }) => theme.gradients.bgTabPanel};
      `}
  }
`;

export const StyledTab = styled(Text)<{ isActive?: boolean; defaultTextColor?: keyof Colors; isMobile: boolean }>`
  width: max-content;
  height: 3.25rem;

  position: relative;

  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: 1rem;

  color: ${({ theme }) => theme.colors.textSixth};

  transition: ${({ theme }) => theme.transitions.default};

  cursor: pointer;

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 0.875rem 1.875rem;
    font-size: 1.125rem;
  }

  ${({ isActive, isMobile }) =>
    isActive &&
    css`
      position: relative;
      display: inline-block;

      ${isMobile && mobileTabStyles}

      ${({ theme }) => theme.mediaQueries.tablet} {
        color: ${({ theme }) => theme.colors.textMain};
      }

      &::before {
        content: "";
        position: absolute;
        height: 3.25rem;

        ${isMobile && mobileBeforeTabStyles}

        ${({ theme }) => theme.mediaQueries.tablet} {
          inset: -0.0625rem;
          padding: 0.0625rem;

          border-radius: ${({ theme }) => theme.radii.videoTestimonialDecorLabel};

          background: ${({ theme }) => theme.gradients.buttonAccent};
          -webkit-mask: ${({ theme }) => theme.gradients.bgSemiPermanent};
          -webkit-mask-composite: xor;
          mask-composite: exclude;
        }
      }
    `}
`;
