// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { ROUTES } from "navigation/routes";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { MEDIA_INDUSTRY_TABS_LIST } from "../../industries/components/industry-info/constants";
import { EXTERNAL_LINKS } from "configs";
// Types
import { IndustriesVariants, IndustryPageContent } from "../../industries/types";
import { BenefitsItemProps } from "pages/services/components/benefits/components/benefits-item/types";
import { WhoWeServeItemType } from "../../industries/components/who-we-serve/types";
import { StagesBlocksItemProps } from "components/stages-blocks/components/stages-blocks-item/types";
import { ServicesListItemType } from "pages/industries/components/our-services-fintech/types";

export const CUSTOMER_EXPERIENCE_CONSULTING_STAGES_BLOCK: StagesBlocksItemProps[] = [
  {
    title: "Productivity Surge",
    description:
      "AI has the power both to streamline operations by dealing with mundane tasks instead of team members and analyze patterns in customer behavior, transforming them into understandable graphs and charts and enforcing informed decision-making.",
    iconSrc: "/images/expertise/customer-experience-consulting/stages-blocks/productivity-surge-icon.svg",
  },
  {
    title: "Smart Decision-Making Rise",
    description:
      "AI helps to understand the nature of customer interactions, analyze them, and make them a base for future approaches to customer engagement. It’s easier to build customer loyalty and retention, adding more personalization and attention.",
    iconSrc: "/images/expertise/customer-experience-consulting/stages-blocks/smart-decision-rise-icon.svg",
  },
  {
    title: "Addressing Service Costs",
    description:
      "Human error is a big part of customer dissatisfaction after dealing with support. With tools enhanced by AI in customer support, people get more fast and proactive help, reducing frustration and increasing satisfaction.",
    iconSrc: "/images/expertise/customer-experience-consulting/stages-blocks/addresing-service-costs-icon.svg",
  },
];

export const CUSTOMER_EXPERIENCE_CONSULTING_WHO_WE_SERVE_LIST: WhoWeServeItemType[] = [
  {
    text: "Churn Prediction & Customer Retention",
    subText: "Utilize AI to anticipate customer behavior and enhance retention strategies.",
  },
  {
    text: "Optimized Customer Targeting & Advertising",
    subText: "Leverage AI for efficient customer targeting and ad optimization.",
  },
  {
    text: "Engaging Customer Solutions Development",
    subText: "Develop solutions that boost customer engagement and loyalty.",
  },
  {
    text: "Audience Segmentation Based on Behavior",
    subText: "Segment your audience more accurately for targeted marketing efforts.",
  },
  {
    text: "In-depth Customer Service and Journey Analysis",
    subText: "Analyze and improve the entire customer journey with AI insights.",
  },
  {
    text: "Insightful Customer Review Analytics",
    subText: "Gain valuable insights from customer feedback on products or services.",
  },
  {
    text: "AI-Powered Recommendation Systems",
    subText: "Personalize customer experiences with intelligent recommendation engines.",
  },
  {
    text: "Predictive Personalization Strategies",
    subText: "Craft highly personalized customer experiences based on predictive analytics.",
  },
];

export const CUSTOMER_EXPERIENCE_CONSULTING_BOTTOM_SERVICES_LIST: ServicesListItemType[] = [
  {
    title: "Predict and Personalize",
    description:
      "Use AI to anticipate customer needs and tailor interactions, making every customer feel understood and valued.",
    imageSrc: "/images/expertise/customer-experience-consulting/our-services/our-services-predict-and-personalize.webp",
    imageSrcMobile:
      "/images/expertise/customer-experience-consulting/our-services/our-services-predict-and-personalize.webp",
    aspectRatio: 2.33,
    width: "55%",
    height: "20rem",
    isBlackText: true,
  },
  {
    title: "Analyze and Optimize",
    description:
      "Leverage AI-driven analytics to continuously improve service strategies, ensuring your business stays ahead of customer expectations and competitors.",
    bgImageSrc: "/images/industries/fintech/our-services/our-services-fintech-legacy-software-transformation.webp",
    gradient: "buttonAccent",
    width: "55%",
    height: "20rem",
    backgroundSize: "100%",
    backgroundSizeMobile: "100% 12.5rem",
    isBlackText: true,
  },
  {
    title: "Automate and Innovate",
    description:
      "Streamline operations with AI automation, freeing up resources to focus on creative solutions for complex CX challenges.",
    imageSrc: "/images/expertise/ml-consulting/our-services/our-services-proven-success.webp",
    imageSrcMobile: "/images/expertise/ml-consulting/our-services/our-services-proven-success.webp",
    height: "41.5rem",
    width: "calc(41% - 0.6875rem)",
    aspectRatio: 0.79,
  },
];

export const CUSTOMER_EXPERIENCE_CONSULTING_BENEFITS_LIST: BenefitsItemProps[] = [
  {
    title: "Deep Customer Insights",
    description:
      "AI's data analytical capabilities allow for a profound understanding of customer behavior, enabling the delivery of relevant products and services.",
    iconSrc: "/images/expertise/customer-experience-consulting/benefits/deep-customer-insights-icon.svg",
  },
  {
    title: "Personalization at Scale",
    description:
      "AI-driven data utilization creates unique, personalized experiences for every customer, based on their historical data and behaviors.",
    iconSrc: "/images/expertise/customer-experience-consulting/benefits/personalization-at-scale-icon.svg",
  },
  {
    title: "Enhanced Customer Interactions",
    description:
      "Transform every interaction into a rich data source, enabling data-driven strategies to propel your business forward.",
    iconSrc: "/images/expertise/customer-experience-consulting/benefits/echanced-customer-interactions-icon.svg",
  },
  {
    title: "Efficient Human-Centric Operations",
    description:
      "AI frees your team from routine tasks, allowing them to focus on creating meaningful, customer-focused interactions.",
    iconSrc: "/images/expertise/customer-experience-consulting/benefits/efficient-human-centric-operations.svg",
  },
];

const CUSTOMER_EXPERIENCE_CONSULTING_CONTENT: IndustryPageContent = {
  banner: {
    title: "Customer Experience Consulting Services",
    description:
      "Idealogic is among companies using AI for customer service, enriching predictive analytics and sentiment analysis to drive business success. Our experts are ready to share their insights about the benefits of AI in customer support and more.",
    imageSrc: "",
    buttonLabel: "Get a free estimation today",
    imageRatio: 0,
    isColumn: true,
    isWhite: true,
    isImageFullContainer: false,
    isImageFullContainerMobile: false,
  },
  stagesBlocks: CUSTOMER_EXPERIENCE_CONSULTING_STAGES_BLOCK,
  whoWeServe: {
    title: "Embracing AI in Customer Service Consulting",
    description:
      "Discover the variety of services we consult your business on. Idealogic is eager to share our expertise to help you strive in the evolving marketplace.",
    whoWeServeListLayoutProps: {
      maxHeight: { _: "100%", laptop: "43.375rem", laptopL: "31.375rem" },
    },
    whoWeServeList: CUSTOMER_EXPERIENCE_CONSULTING_WHO_WE_SERVE_LIST,
  },
  ourServicesFintech: {
    title: "AI-Driven Customer Experience Consulting Services",
    description:
      "Our innovative AI-driven customer experience solutions set new standards for customer relationship management. Learn more about what benefits for your business you can get working with us.",
    isEstimationButton: true,
    topServicesList: [],
    bottomServicesList: CUSTOMER_EXPERIENCE_CONSULTING_BOTTOM_SERVICES_LIST,
  },
  boostBusiness: {
    title: "Join Us in Shaping the Future of Customer Experience",
    description:
      "Ready to elevate your customer experience with AI? Contact Idealogic today, and let's embark on a journey to redefine what's possible in CX together.",
    buttonTitle: "Schedule a call",
    personImageSrc: "/images/expertise/customer-experience-consulting/boost-expertise/boost-expertise-img.webp",
    personImageSrcMobile: "/images/expertise/customer-experience-consulting/boost-expertise/boost-expertise-img.webp",
    imageRatio: 1.04,
    isAbsolute: true,
    bgColor: "bgThird",
    isWhite: true,
    isOverflowHidden: true,
    isHideImageOnMobile: true,
    textWidth: "52.8125rem",
    titleWidth: "52.8125rem",
    imageMaxWidth: "36.375rem",
    imageBlurColor: "blueWhiteBlur",
    blurProps: {
      width: "70%",
      height: "70%",
      blur: "20px",
      bottom: "0",
      right: "0",
    },
  },
  bookACallWithExpert: {
    bgSrc: "/images/expertise/blockchain/book-call-with-a-expert/bg.webp",
    bgMobileSrc: "/images/expertise/blockchain/book-call-with-a-expert/bg.webp",
    title: {
      beforeKeyWord: "",
      keyWord: "Ready to revolutionize",
      afterKeyWord: "your customer experience with the power of AI?",
    },
    description: "",
    linkedin: {
      name: "Artem Zaitsev",
      href: EXTERNAL_LINKS.linkedInArtem,
    },
    jobTitle: "VP of engineering",
    buttonText: "Book a call",
    imageSrc: "/images/expertise/blockchain/book-call-with-a-expert/book-a-call-with-expert-artem-bg.webp",
    mobileImageSrc: "/images/expertise/blockchain/book-call-with-a-expert/book-a-call-with-expert-artem-mobile-bg.webp",
    isReverse: true,
    imageAspectRatio: 1.24,
  },
  benefits: {
    title: "Why AI is Essential for Exceptional CX",
    description:
      "We highlighted only a few of the numerous benefits of AI in customer support and other customer experience services. Learn more about it.",
    benefitsList: CUSTOMER_EXPERIENCE_CONSULTING_BENEFITS_LIST,
    buttonText: "Schedule a call",
    isNeedCurveLine: false,
    isBackgroundImageNeed: false,
    isButtonPositionStart: true,
  },
  industryInfo: {
    title: "Creative tech solutions for media",
    description: "We provide people in the media industry with sufficient technical support, enhancing their workflow.",
    industryInfoTabsList: MEDIA_INDUSTRY_TABS_LIST,
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.ugreator, CaseStudiesSlugEnums.inder],
    title: "Media case studies",
    TitleIcon: WavingHandIcon,
    description:
      "Be it a talent monetization or a blog CRM platform — our solutions are already impacting the media industry. Check for yourself.",
  },
  coveredIndustries: {
    coveredIndustriesList: [
      { label: "Fintech", link: `/${ROUTES.industries}/${IndustriesVariants.fintech}` },
      { label: "Logistics", link: `/${ROUTES.industries}/${IndustriesVariants.logistics}` },
      { label: "Real Estate", link: `/${ROUTES.industries}/${IndustriesVariants.realEstate}` },
      { label: "Aviation", link: `/${ROUTES.industries}/${IndustriesVariants.aviation}` },
      { label: "Other industries", link: `/${ROUTES.industries}/${IndustriesVariants.other}` },
    ],
  },
};

export default CUSTOMER_EXPERIENCE_CONSULTING_CONTENT;
