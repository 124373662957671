// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { ROUTES } from "navigation/routes";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { MEDIA_INDUSTRY_TABS_LIST } from "../components/industry-info/constants";
// Types
import { IndustriesVariants, IndustryPageContent } from "../types";

const MEDIA_CONTENT: IndustryPageContent = {
  banner: {
    title: "Media software development",
    description:
      "Idealogic delivers software applications and tools for the media and entertainment industry, changing the content creation and distribution. We present video editing software, content management systems, and streaming platforms.",
    imageSrc: "/images/industries-banner-media.webp",
    imageMobileSrc: "/images/industries/media-software-development-bg-mobile.webp",
    imageRatio: 1.24,
  },
  industryInfo: {
    title: "Creative tech solutions for media",
    description: "We provide people in the media industry with sufficient technical support, enhancing their workflow.",
    industryInfoTabsList: MEDIA_INDUSTRY_TABS_LIST,
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.ugreator, CaseStudiesSlugEnums.inder],
    title: "Media case studies",
    TitleIcon: WavingHandIcon,
    description:
      "Be it a talent monetization or a blog CRM platform — our solutions are already impacting the media industry. Check for yourself.",
  },
  coveredIndustries: {
    coveredIndustriesList: [
      { label: "Fintech", link: `/${ROUTES.industries}/${IndustriesVariants.fintech}` },
      { label: "Logistics", link: `/${ROUTES.industries}/${IndustriesVariants.logistics}` },
      { label: "Real Estate", link: `/${ROUTES.industries}/${IndustriesVariants.realEstate}` },
      { label: "Aviation", link: `/${ROUTES.industries}/${IndustriesVariants.aviation}` },
      { label: "Other industries", link: `/${ROUTES.industries}/${IndustriesVariants.other}` },
    ],
  },
};

export default MEDIA_CONTENT;
