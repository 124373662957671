import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// Components
import { Container } from "../container";
// Context
import { useTranslation } from "context";
// Constants
import { defaultLinkedData } from "configs/meta";
import { cutLocaleFromRoute } from "utils";
import { getCustomMeta, languageList } from "configs";
import { generateHref, generateLanguageLink } from "./utils";
// Types
import { PageMetaProps, PageProps } from "../types";

export const PageMeta: React.FC<PageMetaProps> = ({
  customTitle,
  customDescription,
  customImg,
  customLinkedData,
  keywords,
  isErrorPage,
}) => {
  const { pathname } = useLocation();
  const { t, currentLanguage } = useTranslation();

  const { title, description, image, keywords: pageKeywords } = getCustomMeta(pathname, t);
  const appUrl = process.env.REACT_APP_URL;
  const isLandingPage = pathname.startsWith("/");

  const correctPath = cutLocaleFromRoute(pathname);
  const pageTitle = isLandingPage ? title || t("Idealogic") : `${title} | ${t("Idealogic")}`;
  const preparedKeywords = keywords ? keywords.join(", ") : pageKeywords;

  return (
    <Helmet>
      <meta name="description" content={customDescription || description} />
      {!isErrorPage && <meta name="keywords" content={preparedKeywords} />}
      <meta property="twitter:title" content={customTitle || pageTitle} />
      <meta property="twitter:description" content={customDescription || description} />
      <meta property="twitter:image" content={customImg || image} />
      <meta property="og:title" content={customTitle || pageTitle} />
      <meta property="og:description" content={customDescription || description} />
      <meta property="og:image" content={customImg || image} />
      <meta property="og:locale" content={currentLanguage.locale} />

      {isErrorPage && <meta name="errorpage" content="true" />}
      {isErrorPage && <meta name="errortype" content="404 - Not Found" />}
      {isErrorPage && <meta name="prerender-status-code" content="404" />}

      <script type="application/ld+json">{JSON.stringify(customLinkedData || defaultLinkedData)}</script>

      <link rel="canonical" href={generateHref(pathname, appUrl)} />
      <link rel="alternate" href={generateHref(pathname, appUrl)} hrefLang="x-default" />

      {languageList.map(({ code, locale }) => {
        return generateLanguageLink(code, locale, correctPath, "alternate");
      })}
      <title>{customTitle || pageTitle}</title>
    </Helmet>
  );
};

export const Page: React.FC<PageProps> = ({
  children,
  customImg,
  keywords,
  customDescription,
  customTitle,
  customLinkedData,
  isErrorPage,
  ...props
}) => {
  return (
    <>
      <PageMeta
        customImg={customImg}
        keywords={keywords}
        customDescription={customDescription}
        customTitle={customTitle}
        customLinkedData={customLinkedData}
        isErrorPage={isErrorPage}
      />
      <Container {...props}>{children}</Container>
    </>
  );
};
