// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { ROUTES } from "navigation/routes";
import { EXTERNAL_LINKS } from "configs";
// Types
import { ServicesPageContent, ServicesVariants } from "../types";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";

export const TECHNICAL_CONSULTING_CONTENT: ServicesPageContent = {
  banner: {
    title: "Technical Consulting",
    description:
      "We offer Technical Consulting services to assist you in understanding market changes, discovering the latest technological advancements, and integrating them into your business effortlessly. We are ready to share our insights from blockchain, fintech, logistics, aviation, real estate, and other industries. Idealogic experts help you visualize your future product.",
    imageSrc: "/images/services-banner-technical-consulting.webp",
    imageMobileSrc: "/images/services/tech-consulting/tech-consulting-bg-mobile.webp",
    imageRatio: 1.75,
  },
  needs: {
    title: "Why you might need technical consulting?",
    description:
      "Use the power of our knowledge and expertise to leverage the best technical solutions for your business.",
    needsList: [
      {
        label: "Plan strategically",
        description:
          "We help you turn your business objectives into a clear plan and provide all the necessary tools and services to realize it. Using our expertise in various industries, we help you avoid common beginner miscalculations.",
        iconSrc: "/images/mvp-development-needs-statistic-icon.svg",
      },
      {
        label: "Mitigate the risks",
        description:
          "We provide you with valuable insights so you can make informed decisions. It’s better to consider possible risks and ways of averting them before the start of costly processes like development and release.",
        iconSrc: "/images/mvp-development-needs-minimalistic-icon.svg",
      },
      {
        label: "Optimize your budget",
        description:
          "A thorough approach to product development helps you cut unnecessary expenses. We advise you on the most suitable type of cooperation, help to refine product requirements, offer the most relevant suite of tools, and more.",
        iconSrc: "/images/mvp-development-needs-money-icon.svg",
      },
    ],
  },
  solutionsForSystems: {
    title: "Solutions for System Overloads",
    text: "Being an enterprise, you may need to deal with regular system overloads. Our approach to addressing this issue includes various techniques.",
    flexDirectionForItemsContainer: "row",
    flexDirectionForList: "column",
    corporateTopList: [
      {
        title: "Performance Monitoring",
        description:
          "Using our monitoring tools, we proactively observe the system in real time to detect and deal with possible issues before they impact overall performance. Our team follows and implements the latest practices to adjust the system to challenges and optimize its productivity.",
        imageSrc: "/images/services/solution-for-systems-overloads/perfomance-monitoring.webp",
        mobileImageSrc: "/images/services/solution-for-systems-overloads/perfomance-monitoring-icon.svg",
        imageWidth: "17.875rem",
        imageBackgroundColor: "corporateValueBg",
        aspectRatio: 0.89,
        isCardBig: true,
        cardPadding: "3.1875rem 1.5rem",
        backgroundColor: "corporateValueBg",
        wrapperProps: {
          maxHeight: { _: "initial", laptop: "24.6875rem" },
        },
      },
      {
        title: "Scalability Strategies",
        description:
          "In our approach, we either propose to add more servers to distribute workload or upgrade hardware specifications, such as CPU or RAM, to boost the single server’s capacity. With cloud computing, you can scale resources on demand and save costs during lower activity.",
        imageSrc: "/images/services/solution-for-systems-overloads/scalabitily-strategies-icon.svg",
        mobileImageSrc: "/images/services/solution-for-systems-overloads/scalability-strategies-mobile-icon.svg",
        imageWidth: "3.125rem",
        imageBackgroundColor: "bgSecond",
        aspectRatio: 1,
        isCardBig: false,
        cardPadding: "2rem 1.5rem",
        backgroundColor: "bgSecond",
        wrapperProps: {
          maxWidth: "100%",
          minHeight: { _: "initial", laptop: "19.75rem" },
        },
      },
    ],
    corporateBottomList: [
      {
        title: "Load-Balancing Techniques",
        description:
          "We use load-balancing solutions to distribute incoming traffic evenly across multiple servers, considering geolocation to choose the nearest one. In such a way, we reduce the latency period, improve response time, and enhance the system’s reliability.",
        imageSrc: "/images/services/solution-for-systems-overloads/load-balancing-icon.svg",
        mobileImageSrc: "/images/services/solution-for-systems-overloads/load-balancing-mobile-icon.svg",
        imageWidth: "3.125rem",
        imageBackgroundColor: "corporateValueSmallBg",
        aspectRatio: 1,
        isCardBig: false,
        cardPadding: "2rem 1.5rem",
        backgroundColor: "corporateValueSmallBg",
        wrapperProps: {
          maxWidth: "100%",
          minHeight: { _: "initial", laptop: "19.75rem" },
        },
      },
      {
        title: "Sharding Strategies",
        description:
          "We distribute data across multiple shards, each of which can operate independently so requests can be processed simultaneously, and you can manage data more efficiently. We also deploy distributed database systems to mitigate the risks of system failure and support scalability.",
        imageSrc: "/images/services/solution-for-systems-overloads/sharding-strategy.webp",
        mobileImageSrc: "/images/services/solution-for-systems-overloads/sharding-strategies-mobile-icon.svg",
        imageWidth: "17.875rem",
        imageBackgroundColor: "corporateValueBg",
        aspectRatio: 1,
        isCardBig: true,
        cardPadding: "3.1875rem 1.5rem",
        backgroundColor: "corporateValueBg",
        wrapperProps: {
          maxHeight: { _: "initial", laptop: "24.6875rem" },
        },
      },
    ],
  },
  benefits: {
    description:
      "Check out the most helpful benefits of Idealogic’s knowledge and expertise for your business projects.",
    benefitsList: [
      {
        title: "Market insights",
        description:
          "Our specialists use cross-functional expertise to follow all the market fluctuations, analyze the available data, and help you make informed decisions to mitigate the risks.",
      },
      {
        title: "Comprehensive plan",
        description:
          "With Idealogic’s team, you can visualize your idea and understand how much time and resources it needs to be implemented. We provide a step-by-step plan for your convenience.",
      },
      {
        title: "New technologies adoption",
        description:
          "Our team introduces you to the latest technological advancements and helps you integrate them seamlessly into your systems. You don’t need to be an expert on everything; we've got your back.",
      },
      {
        title: "Competitive advantage",
        description:
          "With our support, you make decisions and adjustments that lead to the product’s success. We ensure technical implementation, helping you save material resources for marketing or other strategic goals.",
      },
    ],
  },
  pipeline: {
    description: "In our MVP Development workflow, we use design thinking methodology",
    pipelineList: [
      {
        label: "Empathize/Define",
        description:
          "It is the crucial initial phase in the design thinking process. It involves understanding and connecting with the end users' needs, feelings, and challenges to define the problem statement. This step sets the foundation for innovative and user-centric design solutions by fostering empathy and uncovering the core issues that need solving.",
        iconSrc: "/images/services-pipeline-empathize-icon.svg",
        deliverablesList: [
          { label: "Figma Layout", iconSrc: "/images/services-pipeline-deliverables-figma-icon.svg" },
          { label: "Interactive Prototype", iconSrc: "/images/services-pipeline-deliverables-interactive-icon.svg" },
          { label: "Deep Research", iconSrc: "/images/services-pipeline-deliverables-research-icon.svg" },
          { label: "Other", iconSrc: "/images/services-pipeline-deliverables-other-icon.svg" },
        ],
      },
      {
        label: "Analyse/Ideate",
        description:
          "Analyze/Ideate represents the creative heart of the design thinking process. During this phase, designers delve into the insights gathered from empathy and define stages to brainstorm, explore, and generate a wide range of ideas and concepts.",
        iconSrc: "/images/services-pipeline-empathize-icon.svg",
        deliverablesList: [
          { label: "Figma Layout", iconSrc: "/images/services-pipeline-deliverables-figma-icon.svg" },
          { label: "Interactive Prototype", iconSrc: "/images/services-pipeline-deliverables-interactive-icon.svg" },
          { label: "Deep Research", iconSrc: "/images/services-pipeline-deliverables-research-icon.svg" },
          { label: "Other", iconSrc: "/images/services-pipeline-deliverables-other-icon.svg" },
        ],
      },
      {
        label: "Design/Prototype",
        description:
          "This is tangible or digital representation of a product, service, or concept created during the design and development process. It serves as a visual and often interactive model that helps designers and stakeholders test and define designs.",
        iconSrc: "/images/services-pipeline-empathize-icon.svg",
        deliverablesList: [
          { label: "Figma Layout", iconSrc: "/images/services-pipeline-deliverables-figma-icon.svg" },
          { label: "Interactive Prototype", iconSrc: "/images/services-pipeline-deliverables-interactive-icon.svg" },
          { label: "Deep Research", iconSrc: "/images/services-pipeline-deliverables-research-icon.svg" },
          { label: "Other", iconSrc: "/images/services-pipeline-deliverables-other-icon.svg" },
        ],
      },
      {
        label: "Test",
        description:
          "It's the critical phase where prototypes or designs are evaluated for functionality, usability, and overall effectiveness. It involves gathering feedback from users, stakeholders, or target audiences to identify strengths and weaknesses. Testing helps designers refine their work, ensuring it aligns with user needs and expectations",
        iconSrc: "/images/services-pipeline-empathize-icon.svg",
        deliverablesList: [
          { label: "Figma Layout", iconSrc: "/images/services-pipeline-deliverables-figma-icon.svg" },
          { label: "Interactive Prototype", iconSrc: "/images/services-pipeline-deliverables-interactive-icon.svg" },
          { label: "Deep Research", iconSrc: "/images/services-pipeline-deliverables-research-icon.svg" },
          { label: "Other", iconSrc: "/images/services-pipeline-deliverables-other-icon.svg" },
        ],
      },
    ],
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.planetcoin, CaseStudiesSlugEnums.glue],
    title: "Technical consulting case studies",
    TitleIcon: WavingHandIcon,
    description:
      "We believe in blockchain technology versatility and prove that with our completed projects. Aviation, gaming, finance, and more — check for yourself.",
  },
  coveredIndustries: {
    coveredIndustriesList: [
      { label: "Product design", link: EXTERNAL_LINKS.idealogicDesign, isExternal: true },
      { label: "MVP Development", link: `/${ROUTES.services}/${ServicesVariants.mvpDevelopment}` },
      { label: "Maintenance & Support", link: `/${ROUTES.services}/${ServicesVariants.maintenanceSupport}` },
      { label: "Dedicated team", link: `/${ROUTES.services}/${ServicesVariants.dedicatedTeam}` },
    ],
  },
  stagesBlocks: [
    {
      title: "Overall analysis",
      description:
        "We look closely at your business idea and the workflow in your company, assess the level of your technology-related processes and security measures, and see what we can do to improve them. Our team uses expertise in your field and presents their vision of further progress for you.",
      iconSrc: "/images/keys-stages/analysis-icon.svg",
    },
    {
      title: "Consistent strategy",
      description:
        "We ensure that our technological solutions are well-aligned with your business objectives. At this stage, our consultants help outline the development plan, considering all your requirements. Our goal is to establish a solid base for our collaborative partnership.",
      iconSrc: "/images/keys-stages/consistent-strategy-icon.svg",
    },
    {
      title: "Implementation",
      description:
        "When the development begins, our technical consulting team won’t abandon you. We monitor the whole process to guarantee the successful integration of our solutions. We are always in touch with you to discuss possible adjustments.",
      iconSrc: "/images/keys-stages/implementation-icon.svg",
    },
  ],
};

export default TECHNICAL_CONSULTING_CONTENT;
