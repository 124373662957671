// Constants
import { EXTERNAL_LINKS } from "configs";
import { ROUTES } from "navigation/routes";
// Types
import { FooterNavList } from "./types";
import { ServicesVariants } from "pages/services/types";
import { IndustriesVariants } from "pages/industries/types";

export const FOOTER_INDUSTRIES_LIST: FooterNavList[] = [
  {
    title: "Finance",
    navItems: [
      {
        title: "Financial software development",
        link: `/${ROUTES.industries}/${IndustriesVariants.fintech}`,
      },
      {
        title: "Banking software development",
        link: `/${ROUTES.industries}/${IndustriesVariants.banking}`,
      },
      {
        title: "Digital banking software development",
        link: `/${ROUTES.industries}/${IndustriesVariants.digitalBanking}`,
      },
      {
        title: "Cryptocurrency development",
        link: `/${ROUTES.industries}/${IndustriesVariants.cryptoCurrency}`,
      },
    ],
  },
  {
    title: "Logistics",
    navItems: [
      {
        title: "AI-powered Supply Chain development",
        link: "",
      },
      { title: "Supply Chain Management", link: "" },
      { title: "Warehouse Automation", link: "" },
      { title: "Tracking Solutions", link: "" },
      { title: "Analytics & Reporting Tools", link: "" },
    ],
  },
  {
    title: "Healthcare",
    link: "",
  },
  {
    title: "Aviation",
    navItems: [
      {
        title: "Spare parts marketplace",
        link: `/${ROUTES.industries}/${IndustriesVariants.sparePartsMarketplace}`,
      },
      {
        title: "Blockchain-based aviation supply chain",
        link: `/${ROUTES.industries}/${IndustriesVariants.blockchainBasedAviation}`,
      },
      {
        title: "Intelligent Inventory Management Solutions",
        link: `/${ROUTES.industries}/${IndustriesVariants.intelligentInventoryManagement}`,
      },
      {
        title: "AI-based Customer Support and Engagement Solutions",
        link: `/${ROUTES.industries}/${IndustriesVariants.aiBasedAviation}`,
      },
    ],
  },
  {
    title: "Real estate",
    navItems: [
      {
        title: "Real Estate Software Development",
        link: `/${ROUTES.industries}/${IndustriesVariants.customRealEstate}`,
      },
      {
        title: "Property Management Software Development",
        link: `/${ROUTES.industries}/${IndustriesVariants.propertyManagementSolutions}`,
      },
      {
        title: "Agents & Brokers Tech Solutions",
        link: `/${ROUTES.industries}/${IndustriesVariants.agentBrokersSolutions}`,
      },
      {
        title: "Real Estate Investment Solutions",
        link: "",
      },
      {
        title: "Software for Homebuyers & Renters",
        link: "",
      },
    ],
  },
  {
    title: "Sport and fitness",
    link: "",
  },
  {
    title: "Gaming",
    link: "",
  },
  {
    title: "Media",
    navItems: [
      {
        title: "Streaming Platform Solutions",
        link: "",
      },
      {
        title: "Content Management System",
        link: "",
      },
      {
        title: "Ticketing Software Development",
        link: "",
      },
      {
        title: "Social Network Development",
        link: "",
      },
      {
        title: "AI-powered Media Solutions",
        link: "",
      },
    ],
  },
];

export const FOOTER_SERVICES_LIST: FooterNavList[] = [
  { title: "MVP development", link: `/${ROUTES.services}/${ServicesVariants.mvpDevelopment}` },
  { title: "Dedicated team", link: `/${ROUTES.services}/${ServicesVariants.dedicatedTeam}` },
  { title: "Product design", link: EXTERNAL_LINKS.idealogicDesign, isExternal: true },
  { title: "Technical consulting", link: `/${ROUTES.services}/${ServicesVariants.technicalConsulting}` },
  { title: "Maintenance & Support", link: `/${ROUTES.services}/${ServicesVariants.maintenanceSupport}` },
];

export const FOOTER_EXPERTISES_LIST: FooterNavList[] = [
  {
    title: "UI/UX Design",
    link: `/${ROUTES.services}/${ServicesVariants.uiUxDesign}`,
  },
  {
    title: "Web Development",
    navItems: [
      {
        title: "React JS Development",
        link: "",
      },
      {
        title: "Python Development",
        link: "",
      },
      {
        title: "Full Stack Development",
        link: "",
      },
      {
        title: "JavaScript Development",
        link: "",
      },
      {
        title: "Angular Development",
        link: "",
      },
    ],
  },
  {
    title: "Mobile development",
    navItems: [
      {
        title: "iOS Development",
        link: "",
      },
      {
        title: "Android Development",
        link: "",
      },
      {
        title: "Cross-platform App Development",
        link: "",
      },
    ],
  },
  {
    title: "AI/ML Solutions",
    navItems: [
      {
        title: "AI Consulting",
        link: `/${ROUTES.services}/${ServicesVariants.aiConsulting}`,
      },
      {
        title: "AI Software development",
        link: `/${ROUTES.services}/${ServicesVariants.aiSoftwareDevelopment}`,
      },
      {
        title: "AI Mobile App Development",
        link: `/${ROUTES.services}/${ServicesVariants.aiMobileDevelopment}`,
      },
      {
        title: "Data Science Services",
        link: `/${ROUTES.services}/${ServicesVariants.dataScience}`,
      },
      {
        title: "Machine Learning Consulting",
        link: `/${ROUTES.services}/${ServicesVariants.mlConsulting}`,
      },
      {
        title: "Machine Learning Development",
        link: `/${ROUTES.services}/${ServicesVariants.mlDevelopment}`,
      },
      {
        title: "Customer Experience Consulting",
        link: `/${ROUTES.services}/${ServicesVariants.customerExperienceConsulting}`,
      },
    ],
  },
  {
    title: "Blockchain",
    navItems: [
      {
        title: "Blockchain Development Services",
        link: "",
      },
      {
        title: "Custom Blockchain Solutions",
        link: "",
      },
      {
        title: "Smart Contract Development",
        link: "",
      },
      {
        title: "DApp Development",
        link: "",
      },
      {
        title: "Blockchain for Financial Services",
        link: "",
      },
      {
        title: "Cryptocurrency Wallet Development and Exchange",
        link: "",
      },
    ],
  },
  {
    title: "Oracle Development",
    navItems: [
      {
        title: "Oracle Database Development Services",
        link: "",
      },
      {
        title: "Oracle Cloud Solutions",
        link: "",
      },
      {
        title: "Custom Oracle Application Development",
        link: "",
      },
      {
        title: "Oracle Integration Services",
        link: "",
      },
      {
        title: "Oracle ERP Development & Customization",
        link: "",
      },
      {
        title: "Oracle Business Intelligence Consulting",
        link: "",
      },
      {
        title: "Oracle Managed Services",
        link: "",
      },
      {
        title: "Oracle Software Upgrade and Migration Solutions",
        link: "",
      },
    ],
  },
  {
    title: "Big data",
    link: `/${ROUTES.services}/${ServicesVariants.bigData}`,
  },
  {
    title: "Cloud solutions",
    link: `/${ROUTES.services}/${ServicesVariants.cloudSolutions}`,
  },
];

export const FOOTER_OUR_COMPANY_LIST: FooterNavList[] = [
  {
    title: "About us",
    link: `/${ROUTES.aboutUs}`,
  },
  {
    title: "Partnership",
    link: "",
  },
  {
    title: "Investing for Startups",
    link: "",
  },
  {
    title: "Careers",
    link: `/${ROUTES.careers}`,
  },
  {
    title: "Support Ukraine",
    isExternal: true,
    link: EXTERNAL_LINKS.supportUkraine,
  },
];

export const FOOTER_PHONE_LIST = [
  { label: "+1 929 560 3730 (USA)", tel: "+19295603730" },
  { label: "+44 2045 771515 (UK)", tel: "+442045771515" },
  { label: "+372 603 92 65 (Estonia)", tel: "+3726039265" },
];

export const FOOTER_EMAIL_CONTACTS = {
  mailContact:
    "\u006c\u0065\u0067\u0061\u006c\u0040\u0069\u0064\u0065\u0061\u006c\u006f\u0067\u0069\u0063\u002e\u0064\u0065\u0076",
  mailFooter:
    "\u0063\u006f\u006e\u0074\u0061\u0063\u0074\u0040\u0069\u0064\u0065\u0061\u006c\u006f\u0067\u0069\u0063\u002e\u0064\u0065\u0076",
};
