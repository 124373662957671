// Configs
import { EN, languageList } from "configs";

type ENKeys = keyof typeof EN;

type LanguageListKeys = keyof (typeof languageList)[number];

export const languageChecker = (languageInfo: ENKeys | LanguageListKeys) => {
  const subdomain = window.location.hostname.split(".")[0];
  const language = languageList.find(lang => lang.locale.split("-")[0] === subdomain);

  return language ? language[languageInfo] : EN[languageInfo];
};
