import styled, { css } from "styled-components";
import { clearfix } from "styles";
// Types
import { BurgerMenuProps } from "./types";

const pseudoStyles = css`
  ${clearfix}
  width: 1.5rem;
  /* Need to use height in px, because on devices with DPR more than 1, height of lines looks different, but value of height is the same (iPhone, iPad) */
  height: 2px;
  position: absolute;
  left: (50% - 2rem);
  background: ${({ theme }) => theme.colors.white};
  transition: ${({ theme }) => theme.transitions.default};
`;

export const StyledBurgerMenu = styled.div<{ isOpen: BurgerMenuProps["isOpen"]; color: BurgerMenuProps["color"] }>`
  width: 2.5rem;
  height: 2.5rem;

  display: flex;
  justify-content: center;

  position: relative;

  z-index: ${({ theme }) => theme.zIndices.header};
  transition: ${({ theme }) => theme.transitions.default};
  cursor: pointer;

  &::before {
    ${pseudoStyles}
    top: ${({ isOpen }) => (isOpen ? "1.125rem" : "1.5rem")};
    transform: ${({ isOpen }) => (isOpen ? `rotate(45deg)` : `rotate(0deg)`)};
  }
  &::after {
    ${pseudoStyles}
    top: ${({ isOpen }) => (isOpen ? "1.125rem" : "0.875rem")};
    transform: ${({ isOpen }) => (isOpen ? `rotate(-45deg)` : `rotate(0deg)`)};
  }

  &::before,
  &::after {
    background: ${({ theme, color }) => (color ? theme.colors[color] : "inherit")};
  }
`;
