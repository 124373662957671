import styled from "styled-components";
import { clearfix } from "styles";

export const StyledHeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 0.25rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    justify-content: flex-start;
    margin-bottom: 1rem;
  }

  ::after {
    ${clearfix}
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0.5rem;
    background-image: url("/images/like.svg");
    background-repeat: no-repeat;
    background-size: contain;

    ${({ theme }) => theme.mediaQueries.laptop} {
      width: 3.25rem;
      height: 3.25rem;
      margin: 0 0.75rem;
    }
  }
`;
