import React from "react";
// Components + Styling
import { Container, HashLayoutWrapper, Box, ThankForRequest, Image } from "components";
import { ContactUsForm, ContactUsTitle, ContactUsParallaxImage } from "./components";
import { StyledContactUs, StyledContactUsContainer, StyledContainerWrapper } from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { BLOCK_ID } from "configs";
// Types
import { ContactUsProps } from "./types";

const ContactUs: React.FC<ContactUsProps> = ({
  theme = "light",
  isShowParallax,
  isShowCommentInput = false,
  title,
  isHideTitleIcon = true,
  description,
  ...props
}) => {
  const [isShowThankYou, setIsShowThankYou] = React.useState(false);

  const { isDesktop, isLaptopL } = useMatchBreakpoints();

  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <StyledContactUs id={BLOCK_ID.contactUs}>
      <StyledContainerWrapper contactUsTheme={isShowThankYou ? "light" : theme}>
        <Container px={{ _: "1rem", tablet: "2rem", laptopL: "4.5rem" }}>
          <StyledContactUsContainer ref={wrapperRef} isShowCommentInput={isShowCommentInput}>
            <HashLayoutWrapper
              width="100%"
              maxWidth={{ _: "100%", laptop: isShowCommentInput || isShowThankYou ? "100%" : "38.75rem" }}
              pt="0"
            >
              <Box {...props}>
                <>
                  {isShowThankYou ? (
                    <ThankForRequest setIsShowThankYou={setIsShowThankYou} />
                  ) : (
                    <>
                      <ContactUsTitle
                        theme={theme}
                        title={title}
                        isHideTitleIcon={isHideTitleIcon}
                        description={description}
                      />

                      <ContactUsForm
                        theme={theme}
                        setIsShowThankYou={setIsShowThankYou}
                        isShowCommentInput={isShowCommentInput}
                      />
                    </>
                  )}
                </>
              </Box>
            </HashLayoutWrapper>

            {isDesktop && !isShowThankYou && (
              <>
                {isShowParallax ? (
                  <ContactUsParallaxImage ref={wrapperRef} />
                ) : (
                  <Image
                    width={isLaptopL ? "80%" : "50%"}
                    src={
                      isLaptopL
                        ? "/images/contact-us/dashboard-contact-us-form.webp"
                        : "/images/contact-us/dashboard-contact-us-form-to-right.webp"
                    }
                    aspectRatio={isLaptopL ? 1.75 : 1.04}
                    marginRight={isLaptopL ? 0 : "-1rem"}
                  />
                )}
              </>
            )}
          </StyledContactUsContainer>
        </Container>
      </StyledContainerWrapper>
    </StyledContactUs>
  );
};

export default ContactUs;
