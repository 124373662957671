import {
  CluthAboutUsIcon,
  FacebookSocialIcon,
  GoodFirmsAboutUsIcon,
  LinkedinAboutUsIcon,
  TwitterSocialIcon,
  UpWorksSocialIcon,
} from "components/svg";

import { EXTERNAL_LINKS } from "configs";

export const SOCIAL_LINKS_LIST = [
  { label: "Facebook", link: EXTERNAL_LINKS.idealogicFacebook, Icon: FacebookSocialIcon },
  { label: "Twitter/X", link: EXTERNAL_LINKS.idealogicTwitter, Icon: TwitterSocialIcon },
  { label: "Linkedin", link: EXTERNAL_LINKS.idealogicLinkedIn, Icon: LinkedinAboutUsIcon },
  { label: "Upwork", link: EXTERNAL_LINKS.idealogicUpwork, Icon: UpWorksSocialIcon },
  { label: "GoodFirms", link: EXTERNAL_LINKS.idealogicGoodfirms, Icon: GoodFirmsAboutUsIcon },
  { label: "Clutch", link: EXTERNAL_LINKS.idealogicClutch, Icon: CluthAboutUsIcon },
];
