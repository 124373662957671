// Components
import { IndustriesDropdown, OurCompanyDropdown, ServicesDropdown } from "../components";
// Constants
import { ROUTES } from "navigation/routes";

export {
  headerAnimationType,
  headerThemes,
  LIGHT_HEADER_THEME_PATHNAME_LIST,
  TRANSPARENT_HEADER_THEME_PATHNAME_LIST,
} from "./header-theme";
export type { headerThemesType } from "./header-theme";

export const MOBILE_MENU_ANIMATION_DURATION = 1000;
export const CONTACT_US_OUT_PATHNAME_LIST = [`/${ROUTES.findSolution}`, `/${ROUTES.privacyPolicy}`];

export const NAV_LIST = [
  { label: "Industries", link: "", DropdownComponent: IndustriesDropdown },
  { label: "Services", link: "", DropdownComponent: ServicesDropdown },
  { label: "Case studies", link: `/${ROUTES.caseStudies}` },
  { label: "Our company", link: "", DropdownComponent: OurCompanyDropdown },
  { label: "Blog", link: `/${ROUTES.blog}` },
];
