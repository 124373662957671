import { TranslateFunction } from "context/language-context/types";
import { ServicesVariants } from "pages/services/types";

import { capitalizeFirstLetter } from "utils/capitalize-first-letter";
import { getDefaultMeta } from "./";

export const getMetaForServices = (path: string, t: TranslateFunction) => {
  const pathSplit = path.split("/");
  const serviceType = pathSplit[pathSplit.length - 1];

  switch (serviceType) {
    case ServicesVariants.technicalConsulting:
      return {
        ...getDefaultMeta(t),
        title: t("Technical Consulting"),
        description: t(
          "We offer Technical Consulting services to assist you in understanding market changes, discovering the latest technological advancements, and integrating them into your business effortlessly. We are ready to share our insights from blockchain, fintech, logistics, aviation, real estate, and other industries. Idealogic experts help you visualize your future product.",
        ),
        keywords:
          "Technical consulting services, Market trend analysis consulting, Technological advancement consulting, Business technology integration, Blockchain consulting services, Fintech consulting experts, Logistics technology consulting, Aviation technology advisors, Real estate technology consulting, Idealogic industry insights",
      };
    case ServicesVariants.mvpDevelopment:
      return {
        ...getDefaultMeta(t),
        title: t("MVP Development Services"),
        description: t(
          "Idealogic is ready to embark on your startup MVP development so you can test the concept and present it to the stakeholders and investors, as it is crucial to get an MVP for market validation.",
        ),
        keywords:
          "MVP development services, Startup MVP development, Minimum Viable Product development, Concept testing software, Budget-friendly software development, Fast market entry solutions, MVP to full product evolution, Idealogic MVP solutions, Startup product testing, MVP for investors and stakeholders",
      };
    case ServicesVariants.maintenanceSupport:
      return {
        ...getDefaultMeta(t),
        title: t("Maintenance & Support"),
        description: t(
          "Idealogic observes and maintains IT products of various complexity, such as websites, e-commerce stores, SaaS products, internal software, CRMs, DeFi projects, crypto exchanges, etc. We want to form a reliable partnership with you, so we have a team that ensures everything in your systems works fine.",
        ),
        keywords:
          "IT maintenance services, Software support and maintenance, E-commerce support services, SaaS product maintenance, Internal software support, CRM maintenance services, DeFi project support, Crypto exchange maintenance, Idealogic IT partnership, System reliability services",
      };
    case ServicesVariants.dedicatedTeam:
      return {
        ...getDefaultMeta(t),
        title: t("Dedicated Team"),
        description: t(
          "Dedicated team services if you need experienced developers or designers fully immersed in your project. Our specialists are ready to fulfill your product’s requirements in the shortest possible terms to help you release faster. A dedicated team focuses solely on your project to ensure high-quality end results.",
        ),
        keywords:
          "Dedicated team services, Experienced developers for hire, Professional design team, Product development team, Quick project fulfillment, High-quality software development, Idealogic dedicated specialists, Custom development team, Project-focused development team, Dedicated team for startups",
      };

    case ServicesVariants.webDevelopment:
      return {
        ...getDefaultMeta(t),
        title: t("Web Development"),
        description: t(
          "We put all our efforts to create mobile apps, services, and sites that meet your expectations and satisfy the business needs of your company.",
        ),
        keywords:
          "Web development services, Custom mobile app development, Business website development, Professional web services, Custom web solutions, Mobile-friendly website design, User-centric web development, Business needs web development, Responsive web design, Digital service creation",
      };
    case ServicesVariants.mobileDevelopment:
      return {
        ...getDefaultMeta(t),
        title: t("Mobile Development"),
        description: t(
          "We put all our efforts to create mobile apps, services, and sites that meet your expectations and satisfy the business needs of your company.",
        ),
        keywords:
          "Mobile app development services, Custom mobile applications, Business mobile solutions, Professional app development, Mobile-friendly services, User-centric mobile apps, Mobile site development, Responsive mobile design, Custom app solutions for businesses, Enterprise mobile app development",
      };
    case ServicesVariants.aiMlSolutions:
      return {
        ...getDefaultMeta(t),
        title: t("AI/ML Solutions"),
        description: t(
          "We provide cutting-edge technology solutions to optimize your business and open new horizons of opportunity.",
        ),
        keywords:
          "AI technology solutions, Machine learning services, Business optimization AI, ML business solutions, Artificial intelligence consulting, Custom AI/ML development, Advanced technology solutions, AI for business innovation, Machine learning applications, AI-driven business opportunities",
      };
    case ServicesVariants.bigData:
      return {
        ...getDefaultMeta(t),
        title: t("Big Data"),
        description: t(
          "We provide advanced solutions based on big data processing and analysis technologies to help your business make informed decisions and unlock the potential of data.",
        ),
        keywords:
          "Big data processing services, Advanced data analysis solutions, Data-driven decision making, Big data technology solutions, Business intelligence big data, Custom big data applications, Big data analytics services, Data optimization solutions, Big data for businesses, Big data insights and analytics",
      };

    case ServicesVariants.cloudSolutions:
      return {
        ...getDefaultMeta(t),
        title: t("Cloud Solutions"),
        description: t(
          "We provide advanced technology solutions based on cloud platforms to optimize your business and ensure efficient use of resources.",
        ),
        keywords:
          "Cloud technology solutions, Business cloud optimization, Efficient cloud services, Advanced cloud platforms, Cloud resource management, Custom cloud solutions, Cloud computing services, Cloud infrastructure solutions, Cloud-based business applications, Enterprise cloud services",
      };
    case ServicesVariants.uiUxDesign:
      return {
        ...getDefaultMeta(t),
        title: t("UI/UX Design Services"),
        description: t(
          "We put all our efforts to create mobile apps, services, and sites that meet your expectations and satisfy the business needs of your company.",
        ),
        keywords:
          "UI/UX design services, Custom UI design, User experience design, Mobile app UI/UX, Web service UX design, Business website UI design, Professional UX/UI solutions, User-centric design services, Responsive UI/UX design, Enterprise UI/UX development",
      };
    case ServicesVariants.blockchain:
      return {
        ...getDefaultMeta(t),
        title: t("Blockchain"),
        description: t(
          "We provide advanced technology solutions using blockchain technology to ensure security, transparency and efficiency in your business processes.",
        ),
        keywords:
          "Blockchain technology solutions, Secure blockchain services, Business process transparency, Efficiency blockchain applications, Blockchain for businesses, Advanced blockchain consulting, Blockchain security solutions, Transparent business operations, Blockchain efficiency improvements, Custom blockchain development",
      };

    default: {
      return {
        ...getDefaultMeta(t),
        title: t(`${capitalizeFirstLetter(serviceType)}`),
      };
    }
  }
};
