export const blogArticleLinkedDataLayout = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  headline: "",
  image: "",
  author: {
    "@type": "Person",
    name: "",
  },
  publisher: {
    "@type": "Organization",
    name: "Idealogic",
    logo: {
      "@type": "ImageObject",
      url: "https://idealogic.dev/logo512.webp",
    },
  },
  datePublished: "",
  description: "",
};
