// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textAccent", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 32 31" {...props} animationType="titleStraightLines">
      <path
        d="M22.7734 2.20312L27.3683 14.5686"
        stroke={theme.colors[color]}
        strokeMiterlimit="1.5"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M2.90625 3.78027L19.9592 19.883"
        stroke={theme.colors[color]}
        strokeMiterlimit="1.5"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M2.19371 22.4728L14.6121 26.1237"
        stroke={theme.colors[color]}
        strokeMiterlimit="1.5"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default Icon;
