// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFifth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 582 96" {...props} animationType="landingBannerStars" color="transparent">
      <path
        d="M464.002 0.800049C464.002 8.07209 467.705 8.82133 471.451 8.73319 M464.796 15.3C464.796 15.3 465.193 15.0288 465.369 14.5949C465.96 13.1404 465.884 12.0186 466.338 11.4216C467.044 10.4961 468.807 9.21795 471.451 8.73315 M458.449 9.48428C458.449 9.48428 461.622 8.55876 463.209 9.52836C464.049 10.0418 464.313 11.5247 464.487 12.6135C464.734 14.156 464.619 15.2138 464.619 15.2138 M458.449 9.43835C458.449 9.43835 461.918 8.95195 463.077 6.52953C463.562 5.51585 464.091 4.98699 464.135 3.17999C464.157 2.25369 464.002 0.800049 464.002 0.800049"
        stroke={theme.colors[color]}
        strokeWidth="1.41033"
        strokeLinecap="round"
      />
      <path
        d="M573.002 80.8C573.002 88.0721 576.705 88.8213 580.451 88.7332 M573.796 95.3C573.796 95.3 574.193 95.0288 574.369 94.5949C574.96 93.1404 574.884 92.0186 575.338 91.4216C576.044 90.4961 577.807 89.218 580.451 88.7332 M567.449 89.4843C567.449 89.4843 570.622 88.5588 572.209 89.5284C573.049 90.0418 573.313 91.5247 573.487 92.6135C573.734 94.156 573.619 95.2138 573.619 95.2138 M567.449 89.4383C567.449 89.4383 570.918 88.9519 572.077 86.5295C572.562 85.5158 573.091 84.987 573.135 83.18C573.157 82.2537 573.002 80.8 573.002 80.8"
        stroke={theme.colors[color]}
        strokeWidth="1.41033"
        strokeLinecap="round"
      />
      <path
        d="M7.00244 77.8C7.00244 85.0721 10.7046 85.8213 14.4508 85.7332 M7.7959 92.3C7.7959 92.3 8.19256 92.0288 8.36884 91.5949C8.9597 90.1404 8.8836 89.0186 9.33846 88.4216C10.0436 87.4961 11.8065 86.218 14.4509 85.7332 M1.44922 86.4843C1.44922 86.4843 4.62248 85.5588 6.2091 86.5284C7.04924 87.0418 7.31292 88.5247 7.48722 89.6135C7.73416 91.156 7.61944 92.2138 7.61944 92.2138 M1.44922 86.4383C1.44922 86.4383 4.91834 85.9519 6.07688 83.5295C6.56168 82.5158 7.09056 81.987 7.13462 80.18C7.15722 79.2537 7.00242 77.8 7.00242 77.8"
        stroke={theme.colors[color]}
        strokeWidth="1.41033"
        strokeLinecap="round"
      />
      <path
        d="M106.002 10.8C106.002 18.0721 109.705 18.8213 113.451 18.7332 M106.796 25.3C106.796 25.3 107.193 25.0288 107.369 24.5949C107.96 23.1404 107.884 22.0186 108.338 21.4216C109.044 20.4961 110.807 19.218 113.451 18.7332 M100.449 19.4843C100.449 19.4843 103.622 18.5588 105.209 19.5284C106.049 20.0418 106.313 21.5247 106.487 22.6135C106.734 24.156 106.619 25.2138 106.619 25.2138 M100.449 19.4383C100.449 19.4383 103.918 18.9519 105.077 16.5295C105.562 15.5158 106.091 14.987 106.135 13.18C106.157 12.2537 106.002 10.8 106.002 10.8"
        stroke={theme.colors[color]}
        strokeWidth="1.41033"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default Icon;
