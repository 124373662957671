// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 80 80" {...props} color="transparent">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.1305 69.1812C26.1892 69.1548 2.27753 44.0728 4.30928 27.4129C5.73977 15.689 16.2699 8.41518 26.1811 12.4368C31.5108 14.5997 34.3826 20.2382 37.182 24.974C40.4602 19.85 44.4696 13.1164 50.4634 11.1029C61.2729 7.47156 71.2593 15.7318 72.3509 28.3376C73.7462 44.4517 47.3401 69.2062 37.1305 69.1812Z"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.8438 11.6112C62.8493 10.6695 63.8644 9.72968 64.9453 8.875"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.1094 16.0014C32.5081 15.0281 33.812 13.9459 35.154 12.9004"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3203 11.7924C25.7183 10.8197 27.0223 9.73751 28.3643 8.69141"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.1484 19.9584C71.5465 18.9851 72.8504 17.9035 74.1924 16.8574"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.1719 32.4014C73.5706 31.4282 74.8745 30.3469 76.2165 29.3008"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.5469 45.5478C68.1118 44.8736 69.6053 44.0738 71.129 43.3164"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.5156 58.405C57.0821 57.5427 66.108 52.9774 67.623 52.0332"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.7266 71.4403C46.2768 70.5503 55.2201 65.8248 56.7182 64.8535"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
