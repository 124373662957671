import { useBlur, useTranslation } from "context";

import { Text } from "components";
import { StyledBlockTitleLink } from "../../styled";
import { SmallArrowRight } from "components/svg";
import { StyledSectionTitleContainer } from "./styled";
import { StyledComingSoonContainer } from "../dropdown-item/styled";

import { SectionTitleType } from "./types";

const SectionTitle: React.FC<SectionTitleType> = ({ titleLink, title, isExternal = false, onDropdownMenuClick }) => {
  const { t } = useTranslation();
  const { setIsVisible } = useBlur();

  const isDisable = !titleLink;

  return (
    <>
      {isDisable ? (
        <StyledSectionTitleContainer>
          <Text textScale="p16Medium" color={isDisable ? "textFourth" : "white"} whiteSpace="nowrap">
            {t(title)}
          </Text>

          <StyledComingSoonContainer>
            <Text textScale="p14Regular" color="disabledLight">
              {t("Soon")}
            </Text>
          </StyledComingSoonContainer>
        </StyledSectionTitleContainer>
      ) : (
        <StyledBlockTitleLink
          external={isExternal}
          href={titleLink}
          onClick={() => {
            setIsVisible(false);
            onDropdownMenuClick?.();
          }}
        >
          <Text textScale="p16Medium" color="white" whiteSpace="nowrap">
            {t(title)}
          </Text>

          <SmallArrowRight width="1.0625rem" height="1rem" mx="0.25rem" mt="0.25rem" />
        </StyledBlockTitleLink>
      )}
    </>
  );
};

export default SectionTitle;
