// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { EXTERNAL_LINKS } from "configs";
import { ROUTES } from "navigation/routes";
import {
  BLOCKCHAIN_BASED_AVIATION_BENEFITS_LIST,
  BLOCKCHAIN_BASED_AVIATION_ACCORDION_CONTENT_LIST,
} from "../components/industry-info/constants";
// Types
import { ServicesVariants } from "pages/services/types";
import { IndustryPageContent } from "../types";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";

export const BLOCKCHAIN_BASED_AVIATION_ADDITIONAL_BENEFITS_LIST = [
  {
    title: "Blockchain Software Services",
    description:
      "Elevate the security and transparency of your aviation supply chain with our cutting-edge blockchain software services. Optimize processes, and unlock new opportunities for growth and innovation with our bespoke blockchain solutions.",
    linkForButton: `/${ROUTES.services}/${ServicesVariants.blockchain}`,
  },
  {
    title: "AI Software Development",
    description:
      "Transform your aviation operations with AI-driven solutions designed to revolutionize the way you do business. From predictive maintenance to intelligent decision-making, unlock the full potential of AI to drive efficiency and innovation across your organization.",
    linkForButton: `/${ROUTES.services}/${ServicesVariants.aiSoftwareDevelopment}`,
  },
  {
    title: "Machine Learning Development",
    description:
      "Utilize the power of machine learning algorithms to gain invaluable insights and drive informed decision-making in your aviation business. From predictive analytics to anomaly detection, leverage the capabilities of machine learning to stay ahead of the competition and maximize operational efficiency.",
    linkForButton: `/${ROUTES.services}/${ServicesVariants.mlDevelopment}`,
  },
];

const BLOCKCHAIN_BASED_AVIATION: IndustryPageContent = {
  banner: {
    title: "Blockchain-based Aviation Supply Chain Solutions",
    subTitle: "Empower Your Aviation Operations with Blockchain Technology",
    description:
      "Elevate your aviation supply chain traceability and transparency with innovative blockchain solutions. Stay ahead of the curve in the aviation industry by incorporating cutting-edge blockchain technology into your operations. Companies like Boeing and Honeywell are already reaping the benefits – it's time for you to join them.",
    imageSrc: "/images/industries/blockchain-based-aviation/blockchain-based-aviation-banner-bg.webp",
    imageRatio: 1.75,
    buttonLabel: "Get a Free Estimation Today",
    minHeightStyledBoxRem: 46,
  },
  customizedApplications: {
    title: "Who We Serve",
    isParallaxVisible: false,
    description:
      "At Idealogic, we specialize in providing tailored blockchain solutions for various sectors within the aviation industry.",
    customizedApplicationsList: [
      {
        label: "Aviation Companies/Airlines",
        description:
          "Our custom blockchain solutions streamline flight operations, elevate customer service standards, and drive overall efficiency. By leveraging blockchain technology, airlines can enhance transparency, traceability, and data security throughout their operations, leading to improved safety and compliance.",
        iconSrc: "/images/industries/aviation/spare-parts-marketplace-hangar-icon.svg",
      },
      {
        label: "Online Aviation Marketplaces",
        description:
          "We specialize in developing secure and scalable digital platforms tailored for online aviation marketplaces. Our solutions are designed to enhance the user experience, facilitate seamless transactions, and foster trust among buyers and sellers. With advanced features such as smart contract integration and real-time tracking, we empower marketplaces to thrive in the digital age.",
        iconSrc: "/images/industries/aviation/spare-parts-marketplace-shopping-icon.svg",
      },
      {
        label: "MROs, OEMs, Brokers, Distributors",
        description:
          "Our customized blockchain solutions are crafted to meet the unique needs of Maintenance, Repair, and Overhaul (MRO) providers, Original Equipment Manufacturers (OEMs), brokers, and distributors in the aviation industry. From optimizing supply chain management to ensuring regulatory compliance, our solutions drive operational efficiency, market responsiveness, and sustainable growth for all stakeholders.",
        iconSrc: "/images/industries/blockchain-based-aviation/blockchain-based-aviation-tag-icon.svg",
      },
    ],
  },
  ourCapabilities: {
    title: "Our Capabilities",
    description:
      "Explore Idealogic's diverse range of blockchain solutions tailored specifically for the aviation industry.",
    accordionContent: BLOCKCHAIN_BASED_AVIATION_ACCORDION_CONTENT_LIST,
  },
  boostBusiness: {
    title: "White-label Blockchain Solution for Aviation",
    description:
      "Accelerate the deployment of innovative blockchain features with our Blockchain as a Service (BaaS) solution. Reduce time-to-market and enhance your competitive edge in the aviation industry.",
    buttonTitle: "Talk To Us!",
    personImageSrc:
      "/images/industries/blockchain-based-aviation/white-label/white-label-banner-blockchain-based-aviation.webp",
    imageRatio: 1.04,
    isAbsolute: true,
    isWhite: true,
    bgColor: "bgThird",
  },
  benefits: {
    title: "Blockchain Services for Aviation",
    description:
      "Discover Idealogic's advanced multi-purpose software solutions tailored specifically for the aviation industry.",
    benefitsList: BLOCKCHAIN_BASED_AVIATION_BENEFITS_LIST,
    isBackgroundImageNeed: false,
    isWhite: false,
    isNeedCurveLine: false,
    buttonText: "Schedule a Call",
    isSubsidiary: true,
    isButtonPositionStart: true,
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.eplane, CaseStudiesSlugEnums.airpartChain],
    title:
      "Explore our successful examples of innovative aviation software solutions for aircraft maintenance and aerospace trading.",
    TitleIcon: WavingHandIcon,
    actionButtonText: "See More of Our Work",
    description:
      "Check out our successful examples of innovative aviation software solutions for aircraft maintenance and aerospace trading.",
  },
  additionalBenefits: {
    title: "New Technologies in the Aviation Industry",
    description:
      "Embrace the future of aviation by embracing the latest technological innovations and industry trends. Stay ahead of the curve and position your business for sustained success in the dynamic aviation landscape.",
    benefitsList: BLOCKCHAIN_BASED_AVIATION_ADDITIONAL_BENEFITS_LIST,
    isNeedCurveLine: false,
    isBackgroundImageNeed: false,
    isButtonPositionStart: true,
    buttonText: "Let`s talk!",
  },
  bookACallWithExpert: {
    bgSrc: "/images/industries/blockchain-based-aviation/book-a-call-with-expert/book-a-call-with-expert-bg.webp",
    bgMobileSrc:
      "/images/industries/blockchain-based-aviation/book-a-call-with-expert/book-a-call-with-expert-bg-mobile.webp",
    title: {
      beforeKeyWord: "Unlock Your Aviation Potential With Our",
      keyWord: "Blockchain Based Solutions",
      afterKeyWord: "",
    },
    description: "",
    linkedin: {
      name: "Arsenii Ovsianykov",
      href: EXTERNAL_LINKS.linkedInArsenii,
    },
    jobTitle: "Head of R&D team",
    buttonText: "Reserve Your Slot",
    imageSrc:
      "/images/industries/blockchain-based-aviation/book-a-call-with-expert/book-a-call-with-expert-arsenyi-bg.webp",
    mobileImageSrc:
      "/images/industries/blockchain-based-aviation/book-a-call-with-expert/book-a-call-with-expert-arsenyi-bg.webp",
    imageAspectRatio: 1.27,
    isReverse: true,
    isShowLinkedInTitle: false,
  },
  ourCustomerReview: {
    title: "Our customer review",
    description: "We've worked with startups and enterprises – look what they have to say about us!",
    bgImageSrc: "/images/industries/aviation/our-customer-review/our-customer-review-bg.webp",
    linkedInLink: EXTERNAL_LINKS.linkedInAngel,
    bgGradient: "customerReviewAngelGradient",
    isTextClamp: false,
    owner: {
      name: "Angel Marinov",
      logo: "/images/industries/aviation/our-customer-review/eplane-ai-logo.svg",
      logoAspectRatio: 6.25,
      logoSize: "11.625rem",
      jobTitle: "Head of Innovation at ePlaneAI",
      image: "/images/industries/aviation/our-customer-review/our-customer-review-angel-marinov.webp",
      imageAspectRatio: 1,
      reviewText:
        "“Idealogic delivered on-time updates on the app's progress weekly and provided prompt solutions for new requirements. The team showed impressive commitment to the project's success and was helpful in aspects beyond technical. They were deeply interested in the product from the beginning.”",
    },
  },
};

export default BLOCKCHAIN_BASED_AVIATION;
