// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "white", ...props }) => {
  return (
    <Svg viewBox="0 0 48 48" {...props} color={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 24C40 32.8366 32.8366 40 24 40C21.4405 40 19.0214 39.399 16.876 38.3305C16.3059 38.0465 15.6543 37.952 15.039 38.1166L11.4774 39.0696C9.93122 39.4833 8.51673 38.0688 8.93042 36.5226L9.88339 32.961C10.048 32.3457 9.9535 31.6941 9.66954 31.124C8.60099 28.9786 8 26.5595 8 24C8 15.1634 15.1634 8 24 8C32.8366 8 40 15.1634 40 24ZM24 16.4C24.6627 16.4 25.2 16.9373 25.2 17.6V30.4C25.2 31.0627 24.6627 31.6 24 31.6C23.3373 31.6 22.8 31.0627 22.8 30.4V17.6C22.8 16.9373 23.3373 16.4 24 16.4ZM18.8 20.8C18.8 20.1373 18.2627 19.6 17.6 19.6C16.9373 19.6 16.4 20.1373 16.4 20.8V27.2C16.4 27.8627 16.9373 28.4 17.6 28.4C18.2627 28.4 18.8 27.8627 18.8 27.2V20.8ZM30.4 19.6C31.0627 19.6 31.6 20.1373 31.6 20.8V27.2C31.6 27.8627 31.0627 28.4 30.4 28.4C29.7373 28.4 29.2 27.8627 29.2 27.2V20.8C29.2 20.1373 29.7373 19.6 30.4 19.6Z"
      />
    </Svg>
  );
};

export default Icon;
