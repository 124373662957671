import { SpringOptions, useScroll } from "framer-motion";
// Hooks
import useParallax from "./use-parallax";
// Theme
import { transitions } from "theme/base";
// Types
import { UseScrollOffsetOptions } from "types/styled-components-types";

const useParallaxEffect = (
  ref: React.MutableRefObject<HTMLDivElement | null> | React.ForwardedRef<HTMLDivElement | null>,
  distance: number,
  options: {
    offset?: UseScrollOffsetOptions[];
    transition?: SpringOptions;
    reverse?: boolean;
  },
) => {
  const { offset, transition, reverse } = options;
  const selectedTransition = transition ?? transitions.defaultParallaxMotion;

  const { scrollYProgress } = useScroll({
    target: ref as React.MutableRefObject<HTMLDivElement | null>,
    offset: offset ?? ["start end", "start start"],
    layoutEffect: false,
  });

  const y = useParallax(scrollYProgress, distance, reverse);

  return { y, selectedTransition };
};

export default useParallaxEffect;
