import { getFileNameFromSrc } from "utils";
import Image from ".";
// Types
import { HygraphImageProps } from "./types";
import { HygraphAssetImage } from "services";

const HygraphImage: React.FC<HygraphImageProps> = ({ src, cropToWidth, hygraphImage, ...props }) => {
  const containsMediaDomain = src.includes("media.graphassets.com");
  const defaultAlt = "Hygraph image";

  const getAltText = (hygraphImage: HygraphAssetImage | undefined) => {
    if (!hygraphImage) {
      return defaultAlt;
    }

    const { altText, fileName } = hygraphImage;

    if (altText) {
      return altText;
    }

    if (fileName) {
      return getFileNameFromSrc(fileName);
    }
  };

  const finalAlt = getAltText(hygraphImage);

  const modifiedSrc =
    cropToWidth && containsMediaDomain
      ? `https://media.graphassets.com/resize=w:${cropToWidth},fit:crop/${src.split("/").pop()}`
      : src;

  return <Image src={modifiedSrc} {...props} alt={finalAlt} />;
};

export default HygraphImage;
