// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "bgDark", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg width="29" height="28" viewBox="0 0 29 28" {...props} color="transparent">
      <rect x="0.5" width="28" height="28" rx="14" fill={theme.colors.bgSecond} />
      <path
        d="M15.5 15.125H17.375L18.125 12.125H15.5V10.625C15.5 9.8525 15.5 9.125 17 9.125H18.125V6.605C17.8805 6.57275 16.9572 6.5 15.9822 6.5C13.946 6.5 12.5 7.74275 12.5 10.025V12.125H10.25V15.125H12.5V21.5H15.5V15.125Z"
        fill={theme.colors[color]}
      />
    </Svg>
  );
};

export default Icon;
