// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { ROUTES } from "navigation/routes";
// Types
import { ServicesPageContent, ServicesVariants } from "pages/services/types";

const CLOUD_SOLUTIONS_CONTENT: ServicesPageContent = {
  banner: {
    title: "Cloud solutions",
    description:
      "We provide advanced technology solutions based on cloud platforms to optimise your business and ensure efficient use of resources.",
    imageSrc: "/images/expertise/expertise-banner-cloud-solutions.webp",
    imageMobileSrc: "/images/expertise/expertise-banner-cloud-solutions-mobile.webp",
    imageRatio: 1.5,
  },
  customizedApplications: {
    title: "Foster innovations with our cloud solutions expertise",
    description:
      "Discover the versatile nature of Idealogic's cloud solutions, and don’t hesitate to adopt them for your business.",
    customizedApplicationsList: [
      {
        label: "Software as a Service",
        description:
          "We deliver SaaS solutions with automatic updates and scalable subscription models, ensuring our clients can access various applications without the need to manage local installations.",
        iconSrc: "/images/expertise/cloud/cloud-software-as-a-service-icon.svg",
      },
      {
        label: "Platform as a Service",
        description:
          "We accelerate the development process by allowing developers to build, deploy, and manage applications effortlessly, as we provide tailored tools and services.",
        iconSrc: "/images/expertise/cloud/cloud-platform-as-a-service-icon.svg",
      },
      {
        label: "Cloud Computing",
        description:
          "We provide cloud solutions, offering on-demand access to computing resources and ensuring scalability, flexibility, and cost-effectiveness compared to traditional on-premises setups.",
        iconSrc: "/images/expertise/cloud/cloud-cloud-computing-icon.svg",
      },
      {
        label: "Cloud Migration",
        description:
          "Our Cloud Migration services guarantee a seamless transition of applications, data, and infrastructure from on-premises to cloud environments.",
        iconSrc: "/images/expertise/cloud/cloud-cloud-migration-icon.svg",
      },
      {
        label: "Cloud Storage Solutions",
        description:
          "Our tailored solutions empower organizations to efficiently store, retrieve, and manage data, supporting diverse needs from object storage to file and database storage.",
        iconSrc: "/images/expertise/cloud/cloud-cloud-storage-solutions-icon.svg",
      },
      {
        label: "Cloud Modernization",
        description:
          "We optimize existing applications, infrastructure, and processes to use the full potential of cloud computing and enhance agility and cost-effectiveness.",
        iconSrc: "/images/expertise/cloud/cloud-cloud-modernization-icon.svg",
      },
    ],
    additionalRowWithImageCustomizedApplications: {
      label: "Embracing AWS and Google Cloud",
      description:
        "We provide solutions of various complexity, using AWS and Google Cloud infrastructure, to ensure products’ scalability and resistance to system failures.",
      iconSrc: "/images/expertise/cloud/cloud-solutions-icon.svg",
      bgImageSrc: "/images/expertise/cloud/cloud-solutions-bg.webp",
    },
  },
  chooseOurExperience: {
    title: "Why choose our expertise of cloud solutions?",
    description:
      "We focus on continuous improvement and learning. That, combined with years of experience, makes our services stand out.",
    imageSrc: "/images/expertise/expertise-banner-cloud-solutions-choose-our-experience.webp",
    imageRatio: 1.46,
    chooseOurExperienceList: [
      {
        label: "Adaptability and Innovation",
        description:
          "Our team follows the market trends to incorporate the best features and technologies into our projects.",
        iconSrc: "/images/expertise/choose-out-experience/web-development-lightbulb-icon.svg",
      },
      {
        label: "Training and Certifications",
        description:
          "We value education and encourage our employees to take the necessary courses by providing compensation.",
        iconSrc: "/images/expertise/choose-out-experience/web-development-square-academic-cap-icon.svg",
      },
      {
        label: "Various Complexity",
        description:
          "Our team does not hesitate to take on projects that require a unique and complex approach and specific knowledge.",
        iconSrc: "/images/expertise/choose-out-experience/web-development-fire-icon.svg",
      },
      {
        label: "Security and Data Protection",
        description:
          "At Idealogic, we make a great deal of complying with all the necessary security requirements and guidelines.",
        iconSrc: "/images/expertise/choose-out-experience/web-development-shield-icon.svg",
      },
    ],
  },
  digitalExcellence: {
    title: "Crafting Digital Excellence",
    description:
      "With every completed project, we strive to give an unforgettable experience with our unique features and appealing designs.",

    digitalExcellenceList: {
      digitalExcellenceListFirst: [
        {
          label: "50+",
          description: "Projects Completed",
          isBigBorder: false,
        },
        {
          label: "99.9%",
          description: "Security Protocols",
          isBigBorder: true,
        },
      ],
      digitalExcellenceListSecond: [
        {
          label: "90%+",
          description: "Code Quality Metrics",
          isBigBorder: true,
        },

        {
          label: "5+",
          description: "Experience years",
          isBigBorder: false,
        },
      ],
    },
  },
  portfolio: {
    portfolioList: [],
    title: "",
    TitleIcon: WavingHandIcon,
    description: "",
  },
  developmentProcess: {
    title: "Our cloud solutions process",
    description:
      "We’ve enhanced our workflow with the years passing. Learn more about our step-by-step approach to cloud solutions implementation.",
    benefitsList: [
      {
        title: "Discover & Estimate",
        description:
          "We start by closely learning your business requirements, defining what technical solutions suit you best, and providing you with a detailed plan of work scope.",
      },
      {
        title: "Design and Prototype",
        description:
          "We present you with a vision of your future product with basic features so that you can make adjustments and define the course of future development.",
      },
      {
        title: "Development",
        description:
          "Our team of relevant specialists works on the technical implementation of the project, using cutting-edge technologies.",
      },
      {
        title: "Testing",
        description:
          "We do automation and manual testing to ensure every feature works properly and the product can perform to its full potential.",
      },
      {
        title: "Deployment",
        description:
          "We are ready to release the product only after we are satisfied with its usability, security compliance, and reliability.",
      },
      {
        title: "Maintenance",
        description:
          "Our team is keeping up with the product’s performance after the launch to forecast and prevent any technical issues and urgently react if something goes wrong.",
      },
    ],
  },
  boostBusiness: {
    title: "Our expert will be happy to discuss the details of your project!",
    description: "We will take your idea and create something incredible for you.",
    buttonTitle: "Let’s talk!",
    personName: "Serhii Soltys",
    personPosition: "Lead of DevOps",
    personImageSrc: "/images/expertise/expertise-banner-cloud-solutions-serhii-soltys.webp",
    imageRatio: 1.3,
    bgColor: "bgDark",
  },
  coveredIndustries: {
    coveredIndustriesList: [
      { label: "Web Development", link: `/${ROUTES.services}/${ServicesVariants.webDevelopment}` },
      { label: "Mobile Development", link: `/${ROUTES.services}/${ServicesVariants.mobileDevelopment}` },
      { label: "UI/UX Design", link: `/${ROUTES.services}/${ServicesVariants.uiUxDesign}` },
      { label: "Blockchain", link: `/${ROUTES.services}/${ServicesVariants.blockchain}` },
      { label: "AI/ML Solutions", link: `/${ROUTES.services}/${ServicesVariants.aiMlSolutions}` },
      { label: "Big Data", link: `/${ROUTES.services}/${ServicesVariants.bigData}` },
    ],
  },
};

export default CLOUD_SOLUTIONS_CONTENT;
