// Components + styling
import { StyledDivWrapper, StyledTab, StyledTabWrapper } from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { TabProps } from "./types";

const Tab: React.FC<TabProps> = ({ title, onClick, isActive, textScale, activeTextScale, defaultTextColor }) => {
  const { isMobile, isDesktop } = useMatchBreakpoints();

  const selectedTextScale = textScale ? textScale : isDesktop ? "p24Bold" : "p16Medium";

  return (
    <StyledTabWrapper>
      <StyledDivWrapper isActive={isActive}>
        <StyledTab
          as="h3"
          isMobile={isMobile}
          textScale={!!isActive && activeTextScale ? activeTextScale : selectedTextScale}
          isActive={isActive}
          defaultTextColor={defaultTextColor}
          onClick={onClick}
        >
          {title}
        </StyledTab>
      </StyledDivWrapper>
    </StyledTabWrapper>
  );
};

export default Tab;
