import styled from "styled-components";
// Components
import { Image } from "components";

export const StyledSlideImage1 = styled(Image)`
  width: 1.125rem;
  position: absolute;

  ${({ theme }) => theme.mediaQueries.tablet} {
    top: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 2.375rem;
    top: -17.3rem;
    left: auto;
    right: 22.9325rem;
  }
`;

export const StyledSlideImage2 = styled(Image)`
  position: absolute;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 1.685rem;
    top: -19.185rem;
    right: 13.6125rem;
  }
`;

export const StyledSlideImage3 = styled(Image)`
  width: 0.845rem;
  position: absolute;
  display: none;

  ${({ theme }) => theme.mediaQueries.laptop} {
    display: block;
    top: -13.5rem;
    right: 17.785rem;
  }
`;

export const StyledSlideImage4 = styled(Image)`
  position: absolute;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: 2rem;
    top: -13.185rem;
    right: 6.985rem;
  }
`;

export const StyledParallaxImageWrapper = styled.div`
  position: relative;
`;
