// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { ROUTES } from "navigation/routes";
import { EXTERNAL_LINKS } from "configs";
// Types
import { ServicesVariants } from "pages/services/types";
import { BenefitsItemProps } from "pages/services/components/benefits/components/benefits-item/types";
import { IndustryPageContent } from "../types";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { AccordionContentItemType } from "../components/our-capabilities/types";

export const AI_BASED_AVIATION_ACCORDION_CONTENT_LIST: AccordionContentItemType[] = [
  {
    title: "Liveperson Chatbot or Custom Chatbot Integration",
    description:
      "Elevate customer service with live person chatbot or custom integrations, providing instant, 24/7 support across various channels. Our AI-driven chatbot integration offers real-time assistance, handling inquiries with precision and personalization.",
  },
  {
    title: "Multiple Channels Integration",
    description:
      "Enable seamless communication across Viber, WhatsApp, SMS, and more, ensuring consistent and personalized customer interactions. Our omnichannel approach enhances reach and improves customer satisfaction by offering convenience and flexibility in communication.",
  },
  {
    title: "Knowledge-base with Predefined Answers",
    description:
      "Accelerate response times and ensure accuracy with a comprehensive knowledge base, reducing the workload on support teams. Empower your support system with our customizable knowledge base, facilitating quick solutions to customer inquiries.",
  },
  {
    title: "Conversation Intents Configuration",
    description:
      "Optimize chatbot interactions for more meaningful conversations, directing queries to appropriate responses or support agents. Our sophisticated AI-driven features allow for more contextually relevant conversations, ensuring customer queries are directed to the appropriate responses or support agents, thereby enhancing the overall customer service experience.",
  },
  {
    title: "Branding Design Tool",
    description:
      "Customize chatbot appearance to align with brand identity, enhancing brand consistency and customer recognition. Our design team provides you with the opportunity to create a cohesive and branded customer experience across all support channels.",
  },
  {
    title: "Voice Recognition",
    description:
      "Effortless and intuitive customer support with voice recognition capabilities, improving overall user experience and accessibility. Our voice recognition capability understands and processes spoken requests, enabling hands-free support and enhancing accessibility.",
  },
  {
    title: "Multilanguage Support",
    description:
      "Break language barriers and broaden global reach with multi language support, providing personalized assistance to diverse audiences. Our feature enables your customer support system to interact with customers in their native language, advocating for inclusivity and accessibility.",
  },
  {
    title: "Agent Portal",
    description:
      "Streamline workflows and empower support teams with access to customer interaction histories, analytics, and resources. Our sophisticated solution includes access to customer interaction histories, analytics, and support resources, enabling agents to respond more effectively and efficiently to customer needs.",
  },
];

export const AI_BASED_AVIATION_BENEFITS_LIST: BenefitsItemProps[] = [
  {
    title: "Liveperson or Custom Chatbot Integration",
    iconSrc: "/images/industries/ai-based-aviation/ai-based-aviation-robot-icon.svg",
    description:
      "Looking for someone who can help you integrate Liveperson into your system, or build a custom chatbot? Just drop us a message, and we are ready to start. Integrate Liveperson or custom chatbots to meet specific business needs.",
  },
  {
    title: "Chatbot API Development for Interactive Conversations",
    iconSrc: "/images/industries/ai-based-aviation/ai-based-aviation-code-icon.svg",
    description:
      "Development of chatbot API for interactive conversations, providing valuable assistance beyond standard answers. We are ready to help you develop a chatbot that can do more than just give standard answers from the articles in a knowledge base.",
  },
  {
    title: "Legacy Modernization & Migrations",
    iconSrc: "/images/industries/ai-based-aviation/ai-based-aviation-settings-up-icon.svg",
    description:
      "Your legacy systems are preventing you from leveraging new tech opportunities, and you are unsure how to transition to something new? If you lack the right skills in your team to handle an exact chatbot integration, our team will be happy to share our experience from similar projects and help you get into the future of aviation.",
  },
];

export const AI_BASED_AVIATION_ADDITIONAL_BENEFITS_LIST = [
  {
    title: "Blockchain Software Services",
    description:
      "Enhance security and increase transparency with blockchain solutions tailored to business needs. Integrate blockchain technology into your customer support services to ensure data integrity and security, offering a robust and transparent system for your customers.",
    linkForButton: `/${ROUTES.services}/${ServicesVariants.blockchain}`,
  },
  {
    title: "AI Software Development",
    description:
      "Implement AI-driven software solutions to realize unique ideas. Utilize AI-driven software to transform your customer support processes, providing instant and personalized responses to customer queries, leading to increased efficiency and customer satisfaction.",
    linkForButton: `/${ROUTES.services}/${ServicesVariants.aiSoftwareDevelopment}`,
  },
  {
    title: "Machine Learning Development",
    description:
      "Leverage machine learning algorithms for insightful decision-making. Implement machine learning algorithms to analyze customer interactions, predict customer behavior, and offer tailored solutions, resulting in improved decision-making and enhanced customer experience.",
    linkForButton: `/${ROUTES.services}/${ServicesVariants.mlDevelopment}`,
  },
];

const AI_BASED_AVIATION_CONTENT: IndustryPageContent = {
  banner: {
    title: "AI-based Customer Support and Engagement Solutions",
    description:
      "Leverage the transformative capabilities of AI to elevate your customer service and operational efficiency. Deliver instant responses to customer queries round the clock across various channels such as WhatsApp, Viber, Apple Business Messaging, Google Business Messaging, and more. Whether it's providing standard answers-based knowledge or interactive information, tailored AI tools enable seamless integration with your warehouse or online store, ensuring exceptional customer experiences at every touchpoint.",
    imageSrc: "/images/industries/ai-based-aviation/ai-based-aviation-banner-bg.webp",
    imageRatio: 1.75,
    buttonLabel: "Get Your Free Estimation Today",
    maxWidthForButtonRem: 20.75,
  },
  customizedApplications: {
    title: "Who We Serve",
    isParallaxVisible: false,
    description:
      "At Idealogic, we empower businesses across the aviation industry with our AI-driven solutions tailored to their needs.",
    customizedApplicationsList: [
      {
        label: "Aviation Companies/Airlines",
        description:
          "Enhance passenger experience with 24/7 automated assistance solutions, providing personalized, real-time interaction across multiple channels, including WhatsApp, Viber, Apple Business Messaging, Google Business Messaging, and more.",
        iconSrc: "/images/industries/aviation/spare-parts-marketplace-hangar-icon.svg",
      },
      {
        label: "Online Aviation Marketplaces",
        description:
          "Foster trust and loyalty among users with AI-driven customer engagement tools, facilitating efficient communication and interaction through real-time analytics and predictive stock management.",
        iconSrc: "/images/industries/aviation/spare-parts-marketplace-shopping-icon.svg",
      },
      {
        label: "Spare Parts Trading Companies",
        description:
          "Streamline queries and transactions with intelligent automation and multilingual capabilities, improving operational efficiency and customer satisfaction by leveraging our AI-based support solutions.",
        iconSrc: "/images/industries/aviation/spare-parts-marketplace-needs-notes-icon.svg",
      },
    ],
  },
  ourCapabilities: {
    title: "Our Capabilities",
    description: "Customized solutions to streamline operational capacity and transform customer service automation.",
    accordionContent: AI_BASED_AVIATION_ACCORDION_CONTENT_LIST,
  },
  boostBusiness: {
    title: "White-label AI-based Chatbot",
    description:
      "Seamlessly integrate AI-based chatbots into customer relationship processes with Liveperson integration. Our AI-driven chatbot integration offers real-time assistance, handling inquiries with precision and personalization.",
    buttonTitle: "Learn More",
    personImageSrc: "/images/industries/ai-based-aviation/white-label/white-label-banner-ai-based-aviation.webp",
    imageRatio: 0.94,
    isAbsolute: true,
    isWhite: true,
    bgColor: "bgThird",
    isWhiteLabel: true,
  },
  benefits: {
    title: "Our Services for Aviation",
    description:
      "Ideation and implementation of technological solutions to enhance digital presence and operational efficiency.",
    benefitsList: AI_BASED_AVIATION_BENEFITS_LIST,
    buttonText: "Let’s Talk",
    isWhite: false,
    isNeedCurveLine: false,
    isBackgroundImageNeed: false,
    isSubsidiary: true,
    isButtonPositionStart: true,
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.eplane, CaseStudiesSlugEnums.airpartChain],
    title: "Aviation Case Studies",
    TitleIcon: WavingHandIcon,
    description:
      "Explore successful examples of innovative aviation software solutions for aircraft maintenance and aerospace trading.",
    actionButtonText: "See More of Our Work",
  },
  additionalBenefits: {
    title: "New Technologies in Customer Support",
    buttonText: "Book a Meeting!",
    description:
      "We are committed to adopting the latest industry trends to ensure maximum efficiency in customer support.",
    benefitsList: AI_BASED_AVIATION_ADDITIONAL_BENEFITS_LIST,
    isNeedCurveLine: false,
    isButtonPositionStart: true,
    isBackgroundImageNeed: false,
  },
  bookACallWithExpert: {
    bgSrc: "/images/industries/aviation/book-a-call-with-expert/book-a-call-with-expert-bg.webp",
    imageAspectRatio: 1.15,
    bgMobileSrc: "/images/industries/aviation/book-a-call-with-expert-bg-mobile.webp",
    isReverse: true,
    title: {
      beforeKeyWord: "",
      keyWord: "Our Experts",
      afterKeyWord: "Are Happy To Discuss Your Goals & Swiftly Move Towards Execution",
    },
    description: "",
    linkedin: {
      name: "Oleh Henaliuk",
      href: EXTERNAL_LINKS.linkedInOleh,
    },
    jobTitle: "Deputy CEO",
    buttonText: "Book a call",
    imageSrc: "/images/industries/aviation/book-a-call-with-expert/book-a-call-with-expert-oleh-bg.webp",
    mobileImageSrc: "/images/industries/aviation/book-a-call-with-expert/book-a-call-with-expert-oleh-mobile-bg.webp",
    isBoxShadow: true,
    isShowLinkedInTitle: false,
  },
  ourCustomerReview: {
    title: "Hear From Our Customers",
    description: "Explore feedback from startups and enterprises who have partnered with us.",
    bgImageSrc: "/images/industries/aviation/our-customer-review/our-customer-review-bg.webp",
    linkedInLink: EXTERNAL_LINKS.linkedInAngel,
    bgGradient: "customerReviewAngelGradient",
    isTextClamp: false,
    owner: {
      name: "Angel Marinov",
      logo: "/images/industries/aviation/our-customer-review/eplane-ai-logo.svg",
      logoAspectRatio: 6.25,
      logoSize: "11.625rem",
      jobTitle: "Head of Innovation at ePlaneAI",
      image: "/images/industries/aviation/our-customer-review/our-customer-review-angel-marinov.webp",
      imageAspectRatio: 1,
      reviewText:
        "“Idealogic delivered on-time updates on the app's progress weekly and provided prompt solutions for new requirements. The team showed impressive commitment to the project's success and was helpful in aspects beyond technical. They were deeply interested in the product from the beginning.”",
    },
  },
};

export default AI_BASED_AVIATION_CONTENT;
