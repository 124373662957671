// Components + styling
import { Button, Container, Heading, Text } from "components";
import { StyledCryptoCurrencyExpertise, StyledCryptoCurrencyTextBlock } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Utils
import { scrollToElementById } from "utils";
// Constants
import { BLOCK_ID } from "configs";
// Types
import { buttonThemeTypes, variants } from "components/button/types";
import { CryptoCurrencyExpertiseType } from "./types";

const CryptoCurrencyExpertise: React.FC<CryptoCurrencyExpertiseType> = ({
  title,
  description,
  secondDescription,
  componentFromParent,
  spaceProps,
  buttonLabel = "Schedule a call",
  buttonVariant = variants.PRIMARY,
  buttonTheme = buttonThemeTypes.LIGHT,
  isScheduleACall = false,
  isReversePositions = false,
}) => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useMatchBreakpoints();

  const handleButtonClick = () => {
    scrollToElementById(BLOCK_ID.contactUs);
  };

  return (
    <Container pb={{ _: "4rem", tablet: "6.875rem" }} {...spaceProps}>
      <StyledCryptoCurrencyExpertise isScheduleACall={isScheduleACall} isReversePositions={isReversePositions}>
        <StyledCryptoCurrencyTextBlock>
          <Heading as="h2" scale="h2" color="textMain" pb={{ _: "0.5rem", tablet: "1rem" }}>
            {t(title)}
          </Heading>

          <Text textScale={isMobile ? "p16Regular" : "p22Regular"} color="textFourth">
            {t(description)}
          </Text>

          {secondDescription && (
            <Text textScale={isMobile ? "p16Regular" : "p22Regular"} color="textFourth" pt="2rem">
              {t(secondDescription)}
            </Text>
          )}

          {isScheduleACall && (
            <Button
              scale={isDesktop ? "lg" : "md"}
              mt="2.5rem"
              minWidth={{ _: "100%", tablet: "14.625rem" }}
              height={{ _: "3.125rem", laptop: "3.75rem" }}
              variant={buttonVariant}
              buttonTheme={buttonTheme}
              onClick={handleButtonClick}
            >
              {t(buttonLabel)}
            </Button>
          )}
        </StyledCryptoCurrencyTextBlock>

        {componentFromParent}
      </StyledCryptoCurrencyExpertise>
    </Container>
  );
};

export default CryptoCurrencyExpertise;
