// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color, ...props }) => {
  return (
    <Svg viewBox="0 0 32 32" {...props} color={color}>
      <rect x="7" y="24" width="24" height="2" rx="1" transform="rotate(-45 7 24)" />
      <rect x="8" y="7" width="24" height="2" rx="1" transform="rotate(45 8 7)" />
    </Svg>
  );
};

export default Icon;
