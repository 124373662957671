// Styled components
import { StyledSlideImage1, StyledSlideImage2, StyledSlideImage3, StyledSlideImage4 } from "./styled";
// Theme
import { zIndices } from "theme/base";

export const PARALLAX_IMAGES_LIST = [
  {
    StyledComponent: StyledSlideImage1,
    imageSrc: "/images/decor-empty-ellipse.svg",
    zIndex: zIndices.dropdown,
    ratio: 1, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage2,
    imageSrc: "/images/decor-empty-circle.svg",
    zIndex: zIndices.dropdown,
    ratio: 1, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage3,
    imageSrc: "/images/decor-filled-circle.svg",
    zIndex: zIndices.dropdown,
    ratio: 1, // ratio of image width/height
  },
  {
    StyledComponent: StyledSlideImage4,
    imageSrc: "/images/decor-empty-cross.svg",
    zIndex: zIndices.dropdown,
    ratio: 1, // ratio of image width/height
  },
];
