// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import {
  DIGITAL_BANKING_INDUSTRY_TOP_SERVICES_LIST,
  DIGITAL_BANKING_INDUSTRY_WHO_WE_SERVE_LIST,
  DIGITAL_BANKING_INDUSTRY_BOTTOM_SERVICES_LIST,
  DIGITAL_BANKING_INDUSTRY_ACCORDION_CONTENT_LIST,
  DIGITAL_BANKING_INDUSTRY_ADDITIONAL_SERVICES_LIST,
} from "../components/industry-info/constants";
// Types
import { IndustryPageContent } from "../types";

const DIGITAL_BANKING_CONTENT: IndustryPageContent = {
  banner: {
    title: "Digital Banking software development",
    description:
      "Elevate your banking services with our digital banking solutions. Idealogic specializes in guiding your digital transformation journey, enhancing security, and delivering exceptional user experiences.",
    imageSrc: "/images/industries/digital-banking/digital-banking-banner-bg.webp",
    imageRatio: 1.24,
    buttonLabel: "Get a Free Estimation Today",
  },
  ourServicesFintech: {
    title: "Our Services",
    description:
      "We offer a wide range of digital banking software development services designed to power up your banking business and ensure future scalability:",
    topServicesList: DIGITAL_BANKING_INDUSTRY_TOP_SERVICES_LIST,
    bottomServicesList: DIGITAL_BANKING_INDUSTRY_BOTTOM_SERVICES_LIST,
    additionalServicesList: DIGITAL_BANKING_INDUSTRY_ADDITIONAL_SERVICES_LIST,
    isWrap: false,
  },
  bookACallWithExpert: {
    bgSrc: "/images/industries/fintech/book-a-call-with-expert/Bg-light.webp",
    bgMobileSrc: "/images/industries/fintech/book-a-call-with-expert/Bg-light-mobile.webp",
    isReverse: false,
    title: {
      beforeKeyWord: "Revolutionize",
      keyWord: "Financial Services",
      afterKeyWord: "",
    },
    description:
      "Our digital banking solutions revolutionize online banking platforms, enhancing customer service and operational efficiency. With a focus on security and compliance, we provide easy access to accounts while safeguarding user data.",
    linkedin: {
      name: "Alex Saiko",
      href: "https://www.linkedin.com/in/alexander-saiko/",
    },
    jobTitle: "Co-founder and visionary at Idealogic",
    buttonText: "Talk To Us!",
    imageSrc: "/images/industries/fintech/book-a-call-with-expert/book-a-call-with-expert-alex-bg.webp",
    mobileImageSrc: "/images/industries/fintech/book-a-call-with-expert/book-a-call-with-expert-alex-bg.webp",
    imageAspectRatio: 1.08,
    isShowLinkedInTitle: false,
  },
  coveredIndustries: {
    coveredIndustriesList: [
      { label: "Custom Online Banking Platforms", link: "" },
      { label: "Neo Banks", link: "" },
      { label: "Payment Gateways", link: "" },
      { label: "KYC & AML Solutions", link: "" },
      { label: "Integration Services", link: "" },
    ],
  },
  boostBusiness: {
    title: "White-Label Neobanking Solution",
    description:
      "Our white-label neobanking solution offers a secure and scalable infrastructure that can be customized to meet your institution's branding and feature requirements. With our white-label solution, you can quickly launch your own branded neobanking platform and provide customers with a modern and intuitive banking experience.",
    buttonTitle: "Talk To Us!",
    personImageSrc: "/images/industries/digital-banking/white-label/white-label-banner-digital-banking.webp",
    personImageSrcMobile:
      "/images/industries/digital-banking/white-label/white-label-banner-digital-banking-mobile.webp",
    imageRatio: 0.89,
    bgColor: "bgDark",
    imageBlurColor: "yellowBlur",
  },
  whoWeServe: {
    title: "Digital Banking Features We Provide",
    description:
      "Our expertise encompasses fintech consulting, white-label neobanking, payment integration, and more, ensuring impeccable feature delivery and robust digital banking solutions.",
    whoWeServeListLayoutProps: {
      maxHeight: { _: "100%", tablet: "12.1875rem" },
    },
    whoWeServeList: DIGITAL_BANKING_INDUSTRY_WHO_WE_SERVE_LIST,
  },
  portfolio: {
    portfolioList: [
      CaseStudiesSlugEnums.allCryptoMechanics,
      CaseStudiesSlugEnums.cBank,
      CaseStudiesSlugEnums.kansoWallet,
      CaseStudiesSlugEnums.fese,
    ],
    title: "Our Cases",
    TitleIcon: WavingHandIcon,
    actionButtonText: "See All of Our Work",
    description:
      "Explore the diverse range of our digital banking solutions, showcasing our expertise and technical skills.",
  },
  ourCapabilities: {
    title: "Frequently Ask Questions",
    description: "Get answers to your questions about our digital banking solutions:",
    imageForAccordionSrc: "/images/industries/digital-banking/our-capabilities/digital-banking-our-capabilities.webp",
    accordionContent: DIGITAL_BANKING_INDUSTRY_ACCORDION_CONTENT_LIST,
  },
};

export default DIGITAL_BANKING_CONTENT;
