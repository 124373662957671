// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { ROUTES } from "navigation/routes";
import { EXTERNAL_LINKS } from "configs";
// Types

import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { WhoWeServeItemType } from "pages/industries/components/who-we-serve/types";
import { ServicesPageContent, ServicesVariants } from "pages/services/types";

export const AI_ML_SOLUTIONS_CUSTOMIZED_APPLICATIONS_LIST = [
  {
    label: "Neural Networks",
    description:
      "We develop neural networks to process complex data and make decisions. They excel in pattern recognition, natural language processing, and image analysis.",
    iconSrc: "/images/expertise/aml/aml-neutal-networks-icon.svg",
  },
  {
    label: "Voice and Speech Recognition",
    description:
      "We integrate such solutions in our projects, as their ability to interpret spoken language enables hands-free control of devices, transcription services, and personalized user interactions.",
    iconSrc: "/images/expertise/aml/aml-voice-and-speech-icon.svg",
  },
  {
    label: "Computer Vision",
    description:
      "We specialize in integrating our AI algorithms that span image and video recognition to object detection, empowering machines to perceive and comprehend the visual context effectively.",
    iconSrc: "/images/expertise/aml/aml-computer-vision-icon.svg",
  },
  {
    label: "Recommendation System",
    description:
      "We integrate recommendation systems into your project to analyze user preferences and behaviors and enhance overall user experience and engagement.",
    iconSrc: "/images/expertise/aml/aml-recomendation-system-icon.svg",
  },
  {
    label: "Generative Models",
    description:
      "We may incorporate various generative models in your project to create new data that mimics the patterns of the original data and foster creativity in AI-enhanced applications.",
    iconSrc: "/images/expertise/aml/aml-generative-models-icon.svg",
  },
  {
    label: "Predictive Analysis",
    description:
      "Integrating such a solution allows your business to win, using statistical algorithms and ML techniques to analyze historical data for strategic decision-making and risk assessment.",
    iconSrc: "/images/expertise/aml/aml-predective-analysis-icon.svg",
  },
];

export const AI_ML_SOLUTIONS_CHOOSE_OUR_EXPERTISE_LIST = [
  {
    label: "Adaptability and Innovation",
    description:
      "Our team follows the market trends to incorporate the best features and technologies into our projects.",
    iconSrc: "/images/expertise/choose-out-experience/web-development-lightbulb-icon.svg",
  },
  {
    label: "Training and Certifications",
    description:
      "We value education and encourage our employees to take the necessary courses by providing compensation.",
    iconSrc: "/images/expertise/choose-out-experience/web-development-square-academic-cap-icon.svg",
  },
  {
    label: "Various Complexity",
    description:
      "Our team does not hesitate to take on projects that require a unique and complex approach and specific knowledge.",
    iconSrc: "/images/expertise/choose-out-experience/web-development-square-academic-cap-icon.svg",
  },
  {
    label: "Security and Data Protection",
    description:
      "At Idealogic, we make a great deal of complying with all the necessary security requirements and guidelines.",
    iconSrc: "/images/expertise/choose-out-experience/web-development-fire-icon.svg",
  },
];

export const AI_ML_SOLUTIONS_DIGITAL_EXELLENCE_FIRST_LIST = [
  {
    label: "50+",
    description: "Projects Completed",
    isBigBorder: false,
  },
  {
    label: "99.9%",
    description: "Security Protocols",
    isBigBorder: true,
  },
];

export const AI_ML_SOLUTIONS_DIGITAL_EXELLENCE_SECOND_LIST = [
  {
    label: "50+",
    description: "Projects Completed",
    isBigBorder: false,
  },
  {
    label: "99.9%",
    description: "Security Protocols",
    isBigBorder: true,
  },
];

export const AI_ML_SOLUTIONS_WHO_WE_SERVE_LIST: WhoWeServeItemType[] = [
  {
    text: "Drive Decision-Making",
    subText: "Leverage AI to enhance decision-making processes across all levels of your organization.",
  },
  {
    text: "Risk Mitigation",
    subText: "Implement AI tools to mitigate business risks effectively and safeguard your operations.",
  },
  {
    text: "Simplify Data Handling",
    subText: "Transform complex data sets into manageable insights, simplifying accessibility.",
  },
  {
    text: "Strategic Enhancements",
    subText: "Enhance your planning with AI-powered insights to ensure future-focused business moves.",
  },
  {
    text: "Enhance Customer Experience",
    subText: "Deliver a personalized customer experience and boost satisfaction with advanced analytics.",
  },
  {
    text: "Trend Forecasting",
    subText: "Use AI to forecast industry trends and stay ahead of market shifts for future success.",
  },
  {
    text: "Revenue Growth",
    subText: "Harness the power of AI to identify new revenue opportunities and optimize pricing strategies.",
  },
  {
    text: "Churn Prediction",
    subText: "Employ predictive models to anticipate customer churn, enabling proactive retention strategies.",
  },
  {
    text: "Boost Efficiency",
    subText:
      "Streamline operations and increase work efficiency with AI-driven process automation and data management solutions.",
  },
  {
    text: "Cost Reduction",
    subText: "Reduce operational costs through efficient resource management and process automation.",
  },
];

export const AI_ML_SOLUTIONS_BENEFITS_LIST = [
  {
    title: "Discover & Estimate",
    description:
      "We start by closely learning your business requirements, defining what technical solutions suit you best, and providing you with a detailed plan of work scope.",
  },
  {
    title: "Design and Prototype",
    description:
      "We present you with a vision of your future product with basic features so that you can make adjustments and define the course of future development.",
  },
  {
    title: "Development",
    description:
      "Our team of relevant specialists works on the technical implementation of the project, using cutting-edge technologies.",
  },
  {
    title: "Testing",
    description:
      "We do automation and manual testing to ensure every feature works properly and the product can perform to its full potential.",
  },
  {
    title: "Deployment",
    description:
      "We are ready to release the product only after we are satisfied with its usability, security compliance, and reliability.",
  },
  {
    title: "Maintenance",
    description:
      "Our team is keeping up with the product’s performance after the launch to forecast and prevent any technical issues and urgently react if something goes wrong.",
  },
];

export const AI_ML_SOLUTIONS_COVERED_INDUSTRIES_LIST = [
  { label: "UI/UX Design", link: `/${ROUTES.services}/${ServicesVariants.uiUxDesign}` },
  { label: "Mobile Development", link: `/${ROUTES.services}/${ServicesVariants.mobileDevelopment}` },
  { label: "Web Development", link: `/${ROUTES.services}/${ServicesVariants.webDevelopment}` },
  { label: "Blockchain Development", link: `/${ROUTES.services}/${ServicesVariants.blockchain}` },
  { label: "Cloud Solutions", link: `/${ROUTES.services}/${ServicesVariants.cloudSolutions}` },
  { label: "Big Data", link: `/${ROUTES.services}/${ServicesVariants.bigData}` },
];

const AI_ML_SOLUTIONS_CONTENT: ServicesPageContent = {
  banner: {
    title: "AI/ML Solutions",
    description:
      "Idealogic embraces new technologies and delivers AI/ML products, optimizing your business efficiency and opening new market opportunities.",
    gradient: {
      color: "bannerRedGradient",
      backgroundSize: "180% 180%",
    },
    imageRatio: 1.69,
  },
  customizedApplications: {
    title: "Discover AI/ML solutions with our expertise",
    description:
      "Idealogic’s AI/ML services cover the most popular requests for different businesses. Find out more about how you can use AI/ML solutions.",
    customizedApplicationsList: AI_ML_SOLUTIONS_CUSTOMIZED_APPLICATIONS_LIST,
    blur: "redBlur",
    additionalRowWithImageCustomizedApplications: {
      label: "AI integrations",
      description:
        "We learned to work with ChatGPT, Google Gemini, Galaxy AI, etc., and may seamlessly integrate them into your solutions to optimize efficiency and productivity.",
      iconSrc: "/images/expertise/aml/aml-ai-integrations-icon.svg",
      bgImageSrc: "/images/expertise/aml/aml-ai-integrations-bg.webp",
    },
  },
  chooseOurExperience: {
    title: "Why choose our expertise of AI/ML solutions?",
    description:
      "We focus on continuous improvement and learning. That, combined with years of experience, makes our services stand out.",
    imageSrc: "/images/expertise/expertise-banner-ai-ml-solutions-choose-our-experience.webp",
    imageRatio: 1.46,
    chooseOurExperienceList: AI_ML_SOLUTIONS_CHOOSE_OUR_EXPERTISE_LIST,
    isWhiteVariant: true,
  },
  digitalExcellence: {
    title: "Crafting Digital Excellence",
    description:
      "With every completed project, we strive to give an unforgettable experience with our unique features and appealing designs.",

    digitalExcellenceList: {
      digitalExcellenceListFirst: AI_ML_SOLUTIONS_DIGITAL_EXELLENCE_FIRST_LIST,
      digitalExcellenceListSecond: AI_ML_SOLUTIONS_DIGITAL_EXELLENCE_SECOND_LIST,
    },
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.aiBook, CaseStudiesSlugEnums.mindHorizon],
    title: "AI/ML solutions case studies",
    TitleIcon: WavingHandIcon,
    description:
      "Our team is excited to adopt AI/ML solutions in our projects. Look at the completed and wait till we present what’s coming soon.",
  },
  whoWeServe: {
    title: "AI Services Implementation: Transforming Your Business Operations",
    description:
      "Discover the benefits of AI-empowered services for your business' optimized performance. Embrace innovations and flexibility to stay ahead of the competition.",
    whoWeServeListLayoutProps: {
      maxHeight: { _: "100%", laptop: "46.1875rem", laptopL: "35.1875rem" },
    },
    whoWeServeList: AI_ML_SOLUTIONS_WHO_WE_SERVE_LIST,
    beforeGradientColor: "bannerRedGradient",
  },
  bookACallWithExpert: {
    bgSrc: "images/expertise/aml/book-a-call-with-expert/book-a-call-with-expert-bg.webp",
    bgMobileSrc: "images/expertise/aml/book-a-call-with-expert/book-a-call-with-expert-bg.webp",
    title: {
      beforeKeyWord: "",
      keyWord: "Our expert",
      afterKeyWord: "will be happy to discuss the details of your project",
    },
    description: "We will take your idea and create something incredible for you.",
    linkedin: {
      name: "Arsenii Ovsianykov",
      href: EXTERNAL_LINKS.linkedInArsenii,
    },
    jobTitle: "Head of R&D team",
    buttonText: "Book a call",
    imageSrc: "/images/expertise/aml/book-a-call-with-expert/book-a-call-with-expert-arsenyi-bg.webp",
    mobileImageSrc: "/images/expertise/aml/book-a-call-with-expert/book-a-call-with-expert-arsenyi-bg.webp",
    imageAspectRatio: 1.27,
    isReverse: true,
    isMainPageVariation: true,
    isBoxShadow: true,
    gradientColor: "bannerRedGradient",
  },
  benefits: {
    title: "Our AI/ML solutions process",
    description:
      "We’ve enhanced our workflow with the years passing. Learn more about our step-by-step approach to AI/ML solutions integration.",
    benefitsList: AI_ML_SOLUTIONS_BENEFITS_LIST,
    isBackgroundImageNeed: false,
    blur: "redBlur",
  },
  boostBusiness: {
    title: "Our expert will be happy to discuss the details of your project!",
    description: "We will take your project and create something incredible for you.",
    buttonTitle: "Let’s talk!",
    personName: "Arsenii Ovsianykov",
    personPosition: "Head of blockchain",
    personImageSrc: "/images/expertise/expertise-banner-ai-ml-solutions-arsenii-ovsianykov.webp",
    imageRatio: 1.1,
    bgColor: "bgDark",
  },
  coveredIndustries: {
    title: "Services We Cover",
    subTitle:
      "Having specialists with all kinds of backgrounds, Idealogic shares expertise and offers the following custom services for various industries.",
    coveredIndustriesList: AI_ML_SOLUTIONS_COVERED_INDUSTRIES_LIST,
    blur: "redBlur",
  },
  ourCustomerReview: {
    title: "Our customer review",
    description: "We've worked with startups and enterprises – look what they have to say about us!",
    bgImageSrc: "/images/expertise/data-science/our-customer-review/our-customer-review-bg.webp",
    linkedInLink: EXTERNAL_LINKS.linkedInDenisMerphy,
    bgGradient: "customerReviewDenisMerphyGradient",
    isTextClamp: false,
    owner: {
      name: "Denis Murphy",
      logo: "/images/expertise/data-science/our-customer-review/brand-nudge-logo.svg",
      logoAspectRatio: 2.03,
      logoSize: "7.5rem",
      logoSizeMobile: "3rem",
      jobTitle: "CEO at Brand Nudge",
      image: "/images/expertise/data-science/our-customer-review/our-customer-review-denis-merphy.webp",
      imageAspectRatio: 1,
      reviewText:
        "“As the co-founder of our platform, I'm immensely proud of our data science project's success in mapping data across diverse companies and retailers. This initiative has not only enhanced our understanding of market dynamics but also empowered our partners with actionable insights, driving both innovation and customer satisfaction to new heights.”",
    },
  },
  relatedArticlesFilterID: "cls36y2sevk0p09uo6cngx8d8",
};

export default AI_ML_SOLUTIONS_CONTENT;
