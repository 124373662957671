import { css } from "styled-components";
// Constants
import { scales } from "./types";

export const styles = {
  [scales.h0]: css`
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  `,
  [scales.h1]: css`
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  `,
  [scales.h2]: css`
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  `,
  [scales.h3]: css`
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  `,
  [scales.h4]: css`
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  `,
  [scales.h5]: css`
    font-weight: ${({ theme }) => theme.fontWeight.medium};

    ${({ theme }) => theme.mediaQueries.laptop} {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    }
  `,
  [scales.h6]: css`
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  `,
};

export const fontSizes = {
  [scales.h0]: {
    mobile: "2.5rem",
    laptop: "5rem",
  },
  [scales.h1]: {
    mobile: "2rem",
    laptop: "3.75rem",
  },
  [scales.h2]: {
    mobile: "1.5rem",
    laptop: "3.125rem",
  },
  [scales.h3]: {
    mobile: "1.25rem",
    laptop: "2.5rem",
  },
  [scales.h4]: {
    mobile: "1.125rem",
    laptop: "2.125rem",
  },
  [scales.h5]: {
    mobile: "1rem",
    laptop: "1.5rem",
  },
  [scales.h6]: {
    mobile: "0.875rem",
    laptop: "1.25rem",
  },
};

export const lineHeights = {
  [scales.h0]: {
    mobile: 1.3,
    laptop: 1.1,
  },
  [scales.h1]: {
    mobile: 1.5,
    laptop: 1.1,
  },
  [scales.h2]: {
    mobile: 1.3,
    laptop: 1.2,
  },
  [scales.h3]: {
    mobile: 1.3,
    laptop: 1.2,
  },
  [scales.h4]: {
    mobile: 1.5,
    laptop: 1.2,
  },
  [scales.h5]: {
    mobile: 1.5,
    laptop: 1.3,
  },
  [scales.h6]: {
    mobile: 1.5,
    laptop: 1.3,
  },
};
