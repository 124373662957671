// Components + Styling
import { Box, Flex, Text } from "components";
import { StyledPortfolioItemLabelHeading, StyledPortfolioItemLabelText } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { PortfolioLabelProps } from "./types";

const PortfolioItemLabel: React.FC<PortfolioLabelProps> = ({
  title,
  mobileTitle,
  subtitle,
  isComingSoon,
  redirectedLink,
  handleClick,
}) => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useMatchBreakpoints();

  return (
    <Box mt={{ tablet: "2.5rem" }} mb={{ _: "1rem", tablet: "0" }} mx={{ _: "0", tablet: "1rem" }}>
      <Flex mb={{ _: "0", laptop: "0.5rem" }} alignItems="center">
        <StyledPortfolioItemLabelHeading
          onClick={handleClick}
          scale={isDesktop ? "h5" : "h3"}
          as="h3"
          mb={{ _: "0", tablet: "0.25rem" }}
          color="white"
        >
          {t(mobileTitle && isMobile ? `${mobileTitle} - ` : isMobile ? `${title} - ` : title)}
          {isMobile && (
            <Text as="span" textScale="p20Regular" color="white">
              {t(subtitle)}
            </Text>
          )}
        </StyledPortfolioItemLabelHeading>
        {isComingSoon && !redirectedLink && (
          <StyledPortfolioItemLabelText
            color="disabledLight"
            mx="0.75rem"
            textScale={isDesktop ? "p16Regular" : "p14Regular"}
          >
            {t("Coming soon")}
          </StyledPortfolioItemLabelText>
        )}
      </Flex>

      {!isMobile && (
        <Text textScale="p24Regular" color="white">
          {t(subtitle)}
        </Text>
      )}
    </Box>
  );
};

export default PortfolioItemLabel;
