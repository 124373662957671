import { WavingHandIcon } from "components/svg";

import { EXTERNAL_LINKS } from "configs";

import { BenefitsItemProps } from "pages/services/components/benefits/components/benefits-item/types";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { IndustryPageContent } from "../../industries/types";
import { ServicesListItemType } from "../../industries/components/our-services-fintech/types";

export const AI_CONSULTING_BENEFITS_LIST: BenefitsItemProps[] = [
  {
    title: "AI Strategy & Roadmap Development",
    iconSrc: "/images/expertise/ai-consulting/benefits/ai-strategy-icon.svg",
    description:
      "We collaborate with your team to present the use cases for successful AI solutions within your business.",
  },
  {
    title: "AI Technology Evaluation & Selection",
    iconSrc: "/images/expertise/ai-consulting/benefits/ai-technology-icon.svg",
    description:
      "Our tech-savvy team finds the best AI technologies to approach your business and achieve its objectives.",
  },
  {
    title: "Data Strategy & Management",
    iconSrc: "/images/expertise/ai-consulting/benefits/data-strategy-icon.svg",
    description:
      "We can advise you on the best-fitted AI models for data collection and processing so you can extract the most advantages.",
  },
  {
    title: "AI Model Development & Deployment",
    iconSrc: "/images/expertise/ai-consulting/benefits/ai-model-icon.svg",
    description:
      "We collaborate with your team to develop, test, and deploy custom AI models for your specific applications.",
  },
  {
    title: "Change Management & Adoption",
    iconSrc: "/images/expertise/ai-consulting/benefits/change-managment-icon.svg",
    description: "We ensure smooth and seamless AI integration and adoption for your business to succeed.",
  },
];

export const AI_CONSULTING_TOP_SERVICES_LIST: ServicesListItemType[] = [
  {
    title: "Identify New Opportunities",
    description: "Uncover hidden insights from data and discover new avenues for growth with AI.",
    bgImageSrc: "/images/industries/fintech/our-services/our-services-fintech-legacy-software-transformation.webp",
    gradient: "buttonAccent",
    width: "40%",
    isBlackText: true,
    backgroundSize: "100%",
    backgroundSizeMobile: "100% 12.5rem",
  },
  {
    title: "Enhance Customer Experience",
    description: "Personalize customer interactions and offer improved support with AI-powered tools.",
    imageSrc: "/images/expertise/ai-consulting/our-services/enchance-customer.webp",
    aspectRatio: 1.69,
    width: "60%",
  },
];

export const AI_CONSULTING_BOTTOM_SERVICES_LIST: ServicesListItemType[] = [
  {
    title: "Optimize Processes & Increase Efficiency",
    description: "Automate tasks, streamline workflows, and improve operational efficiency through AI solutions.",
    imageSrc: "/images/expertise/ai-consulting/our-services/optimize-processes.webp",
    aspectRatio: 0.94,
    width: "calc(33% - 0.6875rem)",
  },
  {
    title: "Make Data-Driven Decisions",
    description: "Leverage AI-powered analytics to gain deeper insights and make informed business decisions.",
    imageSrc: "/images/expertise/ai-consulting/our-services/make-data-driven.webp",
    isBlackText: true,
    aspectRatio: 0.94,
    width: "calc(33% - 0.6875rem)",
  },
  {
    title: "Gain a Competitive Advantage",
    description: "Stay ahead of the curve by leveraging AI to innovate and differentiate your business in the market",
    bgImageSrc: "/images/industries/fintech/our-services/our-services-fintech-software-quality-assurance.webp",
    gradient: "grayGradient",
    backgroundSize: "20.0625rem",
    backgroundSizeMobile: "12.5rem 9.375rem",
    width: "calc(33% - 0.6875rem)",
  },
];

const AI_CONSULTING_CONTENT: IndustryPageContent = {
  banner: {
    title: "AI Consulting",
    description:
      "Our experts always follow the latest technology advancements to satisfy your curiosity about the new trends and provide valuable advice on whether it’s worth implementing them. With AI development, we feel there is a demand for specialists who could help to understand its intricacies and provide balanced solutions.",
    imageSrc: "/images/expertise/ai-consulting/ai-consulting-banner.webp",
    imageRatio: 1.75,
  },
  titleWithBackground: {
    title: "What is AI Consulting?",
    description:
      "Our specialists with a relevant background in implementing AI solutions for various industries help you define how AI can boost your business capabilities. Whether you want to optimize your budget or free your team from mundane tasks for more strategic decisions, AI has the power to help with these and many other goals.",
  },
  benefits: {
    title: "How We Can Help",
    description:
      "Idealogic embraces the power of AI for many industries and helps you manage its complexities for sustainable business growth.",
    benefitsList: AI_CONSULTING_BENEFITS_LIST,
    buttonText: "Schedule a call",
    isNeedCurveLine: false,
    isBackgroundImageNeed: false,
  },
  ourServicesFintech: {
    title: "Benefits of AI Consulting",
    description:
      "Discover the endless possibilities to benefit from our AI consulting services and enjoy the advantages of AI once you implement it.",
    isWrap: false,
    topServicesList: AI_CONSULTING_TOP_SERVICES_LIST,
    bottomServicesList: AI_CONSULTING_BOTTOM_SERVICES_LIST,
  },
  boostBusiness: {
    title: "AI-powered White Label Solutions",
    description:
      "Idealogic provides a wide range of AI-powered white-label solutions for different industries and purposes. You can brand them and adapt them according to your business needs. Book a call, and we can discuss the specifics.",
    buttonTitle: "Book a call",
    personImageSrc: "/images/expertise/ai-consulting/white-label-waves.webp",
    imageRatio: 1.7,
    isAbsolute: true,
    isWhite: true,
    bgColor: "bgThird",
    imageBlurColor: "blueWhiteBlur",
    isOverflowHidden: true,
    isHideImageOnMobile: true,
    blurProps: {
      width: "70%",
      height: "70%",
      blur: "20px",
      bottom: "0",
      right: "0",
    },
    textWidth: "52.8125rem",
    titleWidth: "52.8125rem",
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.eplane, CaseStudiesSlugEnums.mindHorizon, CaseStudiesSlugEnums.aiBook],
    title: "AI/ML solutions case studies",
    TitleIcon: WavingHandIcon,
    description:
      "Our team is excited to adopt AI/ML solutions in our projects. Look at the completed works and wait till we present what’s coming soon.",
  },
  bookACallWithExpert: {
    bgSrc: "/images/expertise/blockchain/book-call-with-a-expert/bg.webp",
    bgMobileSrc: "/images/expertise/blockchain/book-call-with-a-expert/bg.webp",
    title: {
      beforeKeyWord: "",
      keyWord: "Our expert",
      afterKeyWord: "will be happy to discuss the details of your project!",
    },
    description: "",
    linkedin: {
      name: "Artem Zaitsev",
      href: EXTERNAL_LINKS.linkedInArtem,
    },
    jobTitle: "VP of engineering",
    buttonText: "Book a call",
    imageSrc: "/images/expertise/blockchain/book-call-with-a-expert/book-a-call-with-expert-artem-bg.webp",
    mobileImageSrc: "/images/expertise/blockchain/book-call-with-a-expert/book-a-call-with-expert-artem-mobile-bg.webp",
    isReverse: true,
    imageAspectRatio: 1.24,
  },
};

export default AI_CONSULTING_CONTENT;
