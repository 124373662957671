export const AGREEMENTS_ADDRESS_LIST = [
  {
    address: "Harju maakond, Tallinn, Lasnamäe linnaosa, Katusepapi tn 6-502, 11412, Estonia",
  },
  {
    address: "Księcia Witolda 48/1, Wroclaw, Lower Silesia 50-203, Poland",
  },
];

export const AGREEMENTS_COMPANY_NAME = {
  dev: "IDEALOGIC DEVELOPMENT OÜ",
};
