import { TranslateFunction } from "context/language-context/types";

const URL = process.env.REACT_APP_URL;

export const getDefaultMeta = (t: TranslateFunction) => {
  return {
    title: t("Custom Software Solutions for Startups and Scaling Companies"),
    description: t(
      "Discover our comprehensive tech services to empower your startup or scaling company. Our comprehensive tech services drive your business forward, from design to cutting-edge software.",
    ),
    image: `${URL}/logo512.webp`,
    keywords:
      "tech services for startups, tech services for scaling companies, software development, startups and scaling companies, Product Design, Technical Consulting, Software Development Expertise, Web Development, Mobile Development, UI/UX Design, AI/ML Solutions, Cloud Solutions",
  };
};
