import { ROUTES } from "navigation/routes";

export const getMetaLabelForCaseStudies = (path: string) => {
  const [_empty, _caseStudies, caseStudy] = path.split("/");

  switch (caseStudy) {
    case ROUTES.nextparq:
      return "Nextparq";
    case ROUTES.healthLife:
      return "Health Life";
    case ROUTES.ubeat:
      return "Ubeat";
    case ROUTES.pikkUp:
      return "Pikkup App";
    default:
      return "";
  }
};
