const EXTERNAL_LINKS = {
  youtubeMatthewReview: "https://www.youtube.com/watch?v=EiTZ6nQ8qUk",
  youtubeDanReview: "https://www.youtube.com/embed/yLtZshCH7gg?si=Wa_dUlhLwEwSDolI",
  youtubePhilippReview: "https://www.youtube.com/watch?v=nh4sI1GG1uU",

  linkedInMatthew: "https://www.linkedin.com/in/realmattschneider/",
  linkedInDan: "https://www.linkedin.com/in/dan-jensen-b66b56118/",
  linkedInPhilipp: "https://www.linkedin.com/in/philipp-tanglmayer-5a778a236/",
  linkedInOleh: "https://www.linkedin.com/in/genaliuk/",
  linkedInAlexander: "https://www.linkedin.com/in/alexander-saiko/",
  linkedInAngel: "https://www.linkedin.com/in/aimarinov/",
  linkedInAlexMaslo: "https://www.linkedin.com/in/alexandermaslo/",
  linkedInArsenii: "https://www.linkedin.com/in/arsenii-ovsianykov-b6971320b/",
  linkedInArtem: "https://www.linkedin.com/in/artem-zaitsev-b65825137",
  linkedInEvgeniy: "https://www.linkedin.com/in/evgeniy-ochkalov-9358bb1a4/",
  linkedInAlexSotnikov: "https://www.linkedin.com/in/oleksiy-sotnikov/",
  linkedInDenisMerphy: "https://www.linkedin.com/in/denismurphydryft/",

  eStates: "https://e-states.com/",
  amorsSecret: "https://amorssecret.com/",
  revolveGames: "https://revolvegames.io/",

  idealogicGoodfirms: "https://www.goodfirms.co/company/idealogic",
  idealogicClutch: "https://clutch.co/profile/idealogic#highlights",
  idealogicLinkedIn: "https://www.linkedin.com/company/idealogic-lv",
  idealogicFacebook: "https://www.facebook.com/IdealogicInc",
  idealogicTwitter: "https://twitter.com/idealogic_dev",
  idealogicUpwork: "https://www.upwork.com/agencies/idealogicdev/",
  idealogicDesign: "https://idealogic.design",

  supportUkraine: "https://u24.gov.ua/uk",

  calendly: "https://calendly.com/idealogic/30min",
};

export default EXTERNAL_LINKS;
