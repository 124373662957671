// Components + Styling
import { Box, GifWithDuration, Image, Text } from "components";
import { StyledDecorLabelWrapper, StyledTestimonialLabelWrapper } from "./styled";
// Animations
import { slideTestimonialDecorLabelVariants } from "./animations";
// Context
import { useTranslation } from "context";
// Types
import { PortfolioVideoTestimonialProps } from "./types";

const PortfolioTestimonialLabel: React.FC<PortfolioVideoTestimonialProps> = ({
  name,
  gifSrc,
  position,
  isHovered,
  gifDuration,
  onClickGif,
}) => {
  const { t } = useTranslation();

  const handleGifClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    onClickGif();
  };

  return (
    <StyledTestimonialLabelWrapper>
      <GifWithDuration
        width="4.375rem"
        gifSrc={gifSrc.url}
        gifRatio={1}
        duration={gifDuration}
        handleClickGif={handleGifClick}
      />
      <Box mx="1.25rem">
        <Text textScale="decorative16Bold">{t(name)}</Text>
        <Text textScale="decorative14Light" color="textFifth">
          {t(position)}
        </Text>
      </Box>
      <Box onClick={handleGifClick}>
        <StyledDecorLabelWrapper
          initial="hidden"
          animate={isHovered ? "visible" : "hidden"}
          variants={slideTestimonialDecorLabelVariants}
        >
          <Image
            width="1rem"
            src="/images/testimonial-label-video-icon.svg"
            aspectRatio={1}
            alt="Testimonial label video icon"
          />
          <Text textScale="p12Medium" ml="0.625rem" color="white">
            {t("Video testimonial")}
          </Text>
        </StyledDecorLabelWrapper>
      </Box>
    </StyledTestimonialLabelWrapper>
  );
};

export default PortfolioTestimonialLabel;
