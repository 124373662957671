import { breakpointMap } from "theme/base";
// Constants
import { BLOCK_ID } from "configs";

export const scrollToElementById = (id: string) => {
  const element = document.getElementById(id);
  const header = document.getElementById(BLOCK_ID.header);
  const isLaptopL = window.innerWidth >= breakpointMap.laptopL;

  if (element && header) {
    const top = element.offsetTop;
    const headerRect = header.getBoundingClientRect();

    const mobileOffset = headerRect.height / 2;
    const desktopOffset = -headerRect.height;
    const extraTopOffset = isLaptopL ? desktopOffset : mobileOffset;

    window.scrollTo({
      top: top + extraTopOffset,
      behavior: "smooth",
    });
  }
};
