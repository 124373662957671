import styled, { css } from "styled-components";
// Types
import { Colors } from "theme/types";

export const getStagesBlocksItemSize = (itemsLength: number, isTwoColumn: boolean) => {
  if (isTwoColumn) {
    return css`
      padding: 1.5rem;
      width: calc(50% - 0.75rem);
    `;
  }
  if (itemsLength > 3) {
    return css`
      padding: 2.375rem 1.5rem;
      width: calc(25% - 1.75rem);
    `;
  } else if (itemsLength < 3) {
    return css`
      padding: 2.5rem 3.375rem;
      width: calc(50% - 1rem);
    `;
  } else {
    return css`
      padding: 2.125rem 3rem;
      width: calc(33% - 0.75rem);
    `;
  }
};

export const getStagesBlocksTabletItemSize = (itemsCount: number, isTwoColumn: boolean) => {
  if (isTwoColumn) {
    return css`
      width: calc(50% - 0.75rem);
    `;
  } else if (itemsCount > 3) {
    return css`
      width: calc(50% - 0.5rem);
    `;
  } else if (itemsCount < 3) {
    return css`
      width: calc(50% - 1rem);
    `;
  } else {
    return css`
      width: calc(33% - 0.75rem);
    `;
  }
};

export const StyledStagesBlocksItemWrapper = styled.div<{
  isBgDark?: boolean;
  itemsLength: number;
  isTwoColumn: boolean;
  isDarkVariant?: boolean;
  hoverBorderColor?: keyof Colors;
}>`
  width: 100%;
  margin-bottom: 0.75rem;
  padding: 1.5rem 2rem;
  border: 2px solid;
  border-color: ${({ theme, isDarkVariant }) => (isDarkVariant ? theme.colors.lineDark : theme.colors.lineSecond)};
  border-radius: ${({ theme }) => theme.radii.semiLarge};
  transition: ${({ theme }) => theme.transitions.default};
  background: ${({ theme, isDarkVariant }) => (isDarkVariant ? theme.colors.transparent : theme.colors.white)};

  cursor: pointer;

  &:hover {
    border-color: ${({ theme, hoverBorderColor }) =>
      hoverBorderColor ? theme.colors[hoverBorderColor] : theme.colors.textAccent};
    box-shadow: ${({ theme, isDarkVariant }) =>
      isDarkVariant ? theme.shadows.stagesBlockDarkVariantShadow : theme.shadows.howWorkItemHover};
  }

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    ${({ itemsLength, isTwoColumn }) => getStagesBlocksTabletItemSize(itemsLength, isTwoColumn)};
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: ${({ itemsLength, isTwoColumn }) =>
      itemsLength < 3 ? "calc(50% - 1rem)" : isTwoColumn ? "calc(50% - 0.75rem)" : "calc(33% - 0.75rem)"};
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    ${({ itemsLength, isTwoColumn }) => getStagesBlocksItemSize(itemsLength, isTwoColumn)};
  }
`;

export const StyledStagesBlockTechnologiesListContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 1.25rem;
  margin-top: 1rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin-top: 1.5rem;
  }
`;
