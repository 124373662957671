import React from "react";
// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "bgDark", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg width="29" height="28" viewBox="0 0 29 28" {...props}>
      <rect x="0.5" width="28" height="28" rx="14" fill={theme.colors.bgSecond} />
      <g clipPath="url(#clip0_12092_106348)">
        <path
          d="M18.8696 11.0268C18.0021 11.0423 17.1662 11.3621 16.5026 11.9323C15.8391 12.5026 15.3882 13.2885 15.2259 14.1579C14.4112 12.8664 13.7731 11.4675 13.3291 10H11.449V15.0913C11.449 15.573 11.2614 16.0348 10.9276 16.3754C10.5937 16.7159 10.1409 16.9073 9.66872 16.9073C9.19656 16.9073 8.74374 16.7159 8.40987 16.3754C8.076 16.0348 7.88844 15.573 7.88844 15.0913V10L6 10.0424V15.1338C6.00552 16.124 6.39645 17.0714 7.08681 17.7677C7.77717 18.4639 8.7104 18.8518 9.6812 18.8462C10.652 18.8406 11.5808 18.4418 12.2634 17.7376C12.946 17.0335 13.3263 16.0816 13.3208 15.0913V14.2428C13.7199 15.0413 14.1789 15.8073 14.6935 16.5339L13.5454 22.1429H15.4755L16.3074 18.0613C17.0784 18.5667 17.9782 18.8294 18.8946 18.8165C19.9029 18.8165 20.8699 18.4079 21.5829 17.6807C22.2959 16.9535 22.6964 15.9671 22.6964 14.9386C22.6964 13.9101 22.2959 12.9238 21.5829 12.1965C20.8699 11.4693 19.9029 11.0607 18.8946 11.0607L18.8696 11.0268ZM18.8696 16.8224C18.0879 16.7967 17.3388 16.497 16.7483 15.9738L16.9396 15.2017V15.1592C17.0727 14.3107 17.4887 12.953 18.8946 12.953C19.1411 12.9507 19.3856 12.9982 19.6141 13.0926C19.8426 13.187 20.0505 13.3265 20.226 13.5032C20.4015 13.6798 20.541 13.89 20.6366 14.1218C20.7322 14.3536 20.7819 14.6023 20.783 14.8537C20.7652 15.3629 20.5617 15.8468 20.2124 16.211C19.8632 16.5751 19.3932 16.7931 18.8946 16.8224H18.8696Z"
          fill={theme.colors[color]}
        />
      </g>
      <defs>
        <clipPath id="clip0_12092_106348">
          <rect width="17" height="17" fill="white" transform="translate(6 5)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
