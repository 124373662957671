import styled from "styled-components";

export const StyledTestimonialsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.bgDark};

  ${({ theme }) => theme.mediaQueries.tablet} {
    background-image: url("/images/world-map-bg.webp");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 80% auto;
  }
`;

export const StyledClientsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  margin-top: 1.5rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-top: 3.875rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    flex-wrap: nowrap;
  }
`;

export const StyledClientLogoWrapper = styled.div`
  width: calc(50% - 0.75rem);

  display: flex;
  justify-content: center;

  margin-bottom: 1.25rem;

  &:nth-last-of-type(-n + 2) {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: calc(33% - 0.75rem);

    &:nth-last-of-type(3) {
      margin-bottom: 0;
    }
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: auto;
    margin-bottom: 0;
  }
`;
