export type ValuesType = {
  name: string;
  email: string;
  comment: string;
};

export enum UtmSetUp {
  GCLID = "gclid",
  REFERRER = "referrer",
  UTM_TERM = "utm_term",
  UTM_SOURCE = "utm_source",
  UTM_MEDIUM = "utm_medium",
  UTM_CONTENT = "utm_content",
  UTM_CAMPAIGN = "utm_campaign",
}
