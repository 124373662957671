import styled from "styled-components";

export const StyledCryptoCurrencyExpertise = styled.div<{ isScheduleACall?: boolean; isReversePositions?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    flex-direction: ${({ isScheduleACall, isReversePositions }) =>
      isScheduleACall ? (isReversePositions ? "row-reverse" : "row") : "column"};
  }

  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.laptopL} {
    flex-direction: ${({ isReversePositions }) => (isReversePositions ? "row-reverse" : "row")};
  }
`;

export const StyledCryptoCurrencyTextBlock = styled.div`
  max-width: 100%;

  ${({ theme }) => theme.mediaQueries.tablet} {
    max-width: 39.75rem;
  }
`;
