// Components + styling
import { Link } from "components";
import { StyledFooterLink, StyledSectionTitle } from "components/footer/styled";
import { StyledFooterSocialContainer, StyledFooterSocialItem, StyledFooterSocialWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { SOCIAL_LINKS_LIST } from "components/header/components/our-company-dropdown/components/company-dropdown-description/constants";

const FooterSocial: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useMatchBreakpoints();

  return (
    <StyledFooterSocialContainer>
      {isMobile && <StyledSectionTitle color="textFourthForDarkBg">{t("Social Links")}</StyledSectionTitle>}

      <StyledFooterSocialWrapper>
        {SOCIAL_LINKS_LIST.map(({ Icon, label, link }) => (
          <StyledFooterSocialItem key={label}>
            <Link href={link} external>
              <Icon width={isMobile ? "1.75rem" : "2.5rem"} height={isMobile ? "1.75rem" : "2.5rem"} />
            </Link>
            {isMobile && (
              <StyledFooterLink color="white" href={link} external mx="0.5rem">
                {t(label)}
              </StyledFooterLink>
            )}
          </StyledFooterSocialItem>
        ))}
      </StyledFooterSocialWrapper>
    </StyledFooterSocialContainer>
  );
};

export default FooterSocial;
