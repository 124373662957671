import { LayoutProps, SpaceProps } from "styled-system";
import { DefaultTheme } from "styled-components";
// Constants
import { scales as textScale } from "components/text/types";
// Types
import { Colors, Gradients } from "theme/types";
import { SvgProps } from "components/svg/types";
import { PolymorphicComponentProps } from "types";

export const variants = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TRANSPARENT: "transparent",
} as const;

export const filledTypes = {
  FILLED: "filled",
  BORDERED: "bordered",
} as const;

export const scales = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
} as const;

export const buttonThemeTypes = {
  LIGHT: "light",
  DARK: "dark",
} as const;

export const scalesToTextScale = {
  [scales.XS]: textScale.buttonS,
  [scales.SM]: textScale.buttonS,
  [scales.MD]: textScale.buttonM,
  [scales.LG]: textScale.buttonL,
};

export const buttonHoverLinesPositions = {
  topRight: "topRight",
  topRightMobile: "topRightMobile",
  bottomLeft: "bottomLeft",
  bottomLeftMobile: "bottomLeftMobile",
  mobileTopLeft: "mobileTopLeft",
} as const;

export type Scale = (typeof scales)[keyof typeof scales];
export type FilledType = (typeof filledTypes)[keyof typeof filledTypes];
export type Variant = (typeof variants)[keyof typeof variants];
export type ButtonThemeType = (typeof buttonThemeTypes)[keyof typeof buttonThemeTypes];
export type ButtonHoverLinesPosition = (typeof buttonHoverLinesPositions)[keyof typeof buttonHoverLinesPositions];

export interface BaseButtonProps
  extends LayoutProps,
    SpaceProps,
    React.PropsWithChildren<{
      as?: "a" | "button" | React.ElementType;
      external?: boolean;
      isLoading?: boolean;
      disabled?: boolean;
      buttonTheme?: ButtonThemeType;
      scale?: Scale;
      variant?: Variant;
      filledType?: FilledType;
      color?: keyof Colors;
      bgColor?: keyof Colors;
      bg?: keyof Gradients;
      hoverBg?: keyof Gradients;
      contentBg?: keyof Gradients;
      hoverContentBg?: keyof Gradients;
      hoverLinesPosition?: ButtonHoverLinesPosition;
      isShowHoverLines?: boolean;
      startIcon?: React.ReactNode & SvgProps;
      endIcon?: React.ReactNode & SvgProps;
      ariaLabel?: string;
    }> {}

export type ButtonProps<P extends React.ElementType = "button"> = PolymorphicComponentProps<P, BaseButtonProps>;

export interface ThemedProps extends ButtonProps {
  theme: DefaultTheme;
}

export type StyledButtonHoverLinesProps = {
  hoverLinesPosition?: ButtonHoverLinesPosition;
  scale?: Scale;
};
