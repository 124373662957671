// Styling
import { StyledListPoint } from "./styled";
// Types
import { ListPointProps } from "./types";

const ListPoint: React.FC<React.PropsWithChildren<ListPointProps>> = ({ children, ...props }) => {
  return (
    <StyledListPoint as="li" {...props}>
      {children}
    </StyledListPoint>
  );
};

ListPoint.defaultProps = {
  color: "textFifth",
  p: "0 0.5rem",
  m: "0 2rem",
};

export default ListPoint;
