import styled from "styled-components";

import { Text } from "../text";

import { Gradients } from "theme/types";

export const StyledKeyWordText = styled(Text)<{ gradient?: keyof Gradients }>`
  color: ${({ theme }) => theme.colors.textAccentSecond};
  background-color: ${({ theme, gradient }) => (gradient ? theme.colors.blueStopColor : theme.colors.showMore)};
  background-image: ${({ theme, gradient }) => (gradient ? theme.gradients[gradient] : theme.gradients.buttonAccent)};
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  box-decoration-break: clone;
  background-clip: text;
  max-width: fit-content;
`;
