import { useMotionValueEvent, useScroll } from "framer-motion";
// Components + styling
import { getHeaderDropVariants } from "components/header/animations";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { BLOCK_ID } from "configs";
import { headerAnimationType } from "../constants";

export const useMotionAnimations = () => {
  const { scrollY } = useScroll();
  const { isDesktop } = useMatchBreakpoints();

  const [headerAnimation, setHeaderAnimation] = React.useState<keyof typeof headerAnimationType>(
    headerAnimationType.static,
  );

  const infoSection = document.getElementById(BLOCK_ID.info);
  const header = document.getElementById(BLOCK_ID.header);

  const headerAnimationsVariants = getHeaderDropVariants(isDesktop);

  useMotionValueEvent(scrollY, "change", handleHeaderAnimation);

  function handleHeaderAnimation(latest: number) {
    if (infoSection && header) {
      updateHeaderAnimation(latest, infoSection, header);
    } else {
      toggleHeaderAfterScroll(latest);
    }
  }

  function updateHeaderAnimation(latest: number, infoSection: HTMLElement, header: HTMLElement) {
    const rect = infoSection.getBoundingClientRect();
    const headerRect = header.getBoundingClientRect();

    if (latest === 0) {
      setHeaderAnimation(headerAnimationType.initial);
    }

    if (latest > 0) {
      setHeaderAnimation(headerAnimationType.hidden);
    }

    if (latest > rect.height / 1.5) {
      setHeaderAnimation(headerAnimationType.fixed);
    }

    if (latest > rect.top && +rect.bottom.toFixed() < headerRect.height) {
      setHeaderAnimation(headerAnimationType.drop);
    }
  }

  function toggleHeaderAfterScroll(latestScrollVal: number) {
    if (latestScrollVal > 0) {
      setHeaderAnimation(headerAnimationType.drop);
    } else {
      setHeaderAnimation(headerAnimationType.initial);
    }
  }

  return {
    headerAnimation,
    headerAnimationsVariants,
  };
};
