// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textAccent", animationDuration = 21, ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg
      viewBox="0 0 100 100"
      {...props}
      animationWithDurationType="circleProgress"
      animationDuration={animationDuration}
      color="transparent"
    >
      <circle
        cx="50"
        cy="50"
        r="46"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{ rotate: "90deg", transform: "translate(100%, -100%) rotateY(-180deg)" }}
      />
    </Svg>
  );
};

export default Icon;
