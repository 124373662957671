import React from "react";
// Components + styling
import { Text } from "components";
import { StyledEmailContactWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { EmailContactProps } from "./types";

const EmailContact: React.FC<EmailContactProps> = ({ children, ...props }) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchBreakpoints();

  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  return (
    <StyledEmailContactWrapper>
      {isHovered ? (
        <>{children}</>
      ) : (
        <Text
          mx="0.5rem"
          textScale="p14Regular"
          color={isMobile ? "textFourthForDarkBg" : "white"}
          onMouseEnter={handleMouseEnter}
          {...props}
        >
          {t("Show email")}
        </Text>
      )}
    </StyledEmailContactWrapper>
  );
};

export default EmailContact;
