// Styled
import { StyledAnimatedCharacterText, StyledAnimatedCharacterTextWrapper } from "./styled";
// Animations
import { textContainerVariants, textCharacterVariants } from "./animations";
// Types
import { AnimatedCharacterTextProps } from "./types";

const AnimatedCharacterText: React.FC<AnimatedCharacterTextProps> = ({ text, delay = 0, ...props }) => {
  const letters = Array.from(text);

  return (
    <StyledAnimatedCharacterTextWrapper variants={textContainerVariants(delay)} initial="hidden" {...props}>
      {letters.map((letter, index) => (
        <StyledAnimatedCharacterText variants={textCharacterVariants} key={index}>
          {letter}
        </StyledAnimatedCharacterText>
      ))}
    </StyledAnimatedCharacterTextWrapper>
  );
};

export default AnimatedCharacterText;
