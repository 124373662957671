import styled from "styled-components";
// Components
import { Link } from "components";

export const StyledAgreementsWrapper = styled.div`
  background: ${({ theme }) => theme.colors.bgDark};
`;

export const StyledInnerWrapper = styled.div`
  border-radius: 0 0 ${({ theme }) => theme.radii.contactForm} ${({ theme }) => theme.radii.contactForm};
  background: ${({ theme }) => theme.colors.white};
`;

export const StyledContactLink = styled(Link).attrs({ color: "textMain", external: true, underline: true })``;

export const StyledAgreementsDetails = styled.div`
  margin-bottom: 1.5rem;
`;
