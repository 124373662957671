// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ ...props }) => {
  return (
    <Svg viewBox="0 0 18 18" {...props}>
      <path
        d="M5.20343 15.0809C5.18994 15.0809 5.17523 15.0784 5.16052 15.0747C3.68443 14.6248 2.08574 13.0114 1.73878 11.6224C1.71916 11.5439 1.76698 11.4654 1.84421 11.4458C1.92268 11.4262 2.00114 11.474 2.02076 11.5512C2.34074 12.8324 3.87813 14.3784 5.24511 14.7964C5.32235 14.8197 5.36526 14.9007 5.34197 14.9779C5.32358 15.0404 5.26596 15.0809 5.20343 15.0809Z"
        fill="#975500"
      />
      <path
        d="M5.9071 16.9111C5.89607 16.9111 5.88503 16.9099 5.874 16.9075C4.65291 16.623 3.23321 15.7109 1.98025 14.404C0.808203 13.1829 0.0493145 11.8834 0.000274857 11.0129C-0.00462911 10.9332 0.0566704 10.8633 0.137586 10.8596C0.217275 10.8547 0.287157 10.916 0.290835 10.997C0.33497 11.789 1.08037 13.0468 2.18989 14.2029C3.40485 15.4706 4.77183 16.3521 5.9402 16.6243C6.01866 16.6426 6.0677 16.7211 6.04931 16.7996C6.03338 16.8658 5.9733 16.9111 5.9071 16.9111Z"
        fill="#975500"
      />
      <path
        d="M12.1659 4.17201C12.1009 4.17201 12.042 4.1291 12.0249 4.06412C11.8201 3.28684 10.8896 2.17119 10.0425 1.95297C9.96521 1.93335 9.9174 1.85366 9.93824 1.7752C9.95908 1.69673 10.0375 1.65015 10.116 1.67099C11.0698 1.91741 12.0776 3.12011 12.3069 3.99056C12.3277 4.0678 12.2811 4.14749 12.2026 4.16833C12.1904 4.17078 12.1781 4.17201 12.1659 4.17201Z"
        fill="#975500"
      />
      <path
        d="M14.0011 4.43042C13.9361 4.43042 13.8773 4.38629 13.8601 4.32131C13.3146 2.17337 11.2745 0.497443 10.1098 0.289024C10.0301 0.274312 9.97865 0.199527 9.99213 0.119837C10.0068 0.040148 10.0816 -0.0113437 10.1613 0.00214225C11.4302 0.230177 13.5585 1.9576 14.1421 4.24898C14.1617 4.32744 14.1151 4.4059 14.0367 4.42552C14.0244 4.4292 14.0134 4.43042 14.0011 4.43042Z"
        fill="#975500"
      />
      <path
        d="M10.3781 8.24728C10.6122 8.50596 10.8231 8.84556 10.7336 8.93506C10.6441 9.02456 10.3695 8.86272 10.1599 8.65185C9.95021 8.44221 9.44878 10.6735 9.39974 10.7863C9.36296 10.8709 8.77939 11.5403 8.47412 12.1766C8.37236 12.3887 8.38708 13.0286 8.34907 13.0666C8.19582 13.2199 8.00089 13.204 7.58037 12.8325C7.15986 12.461 6.94163 14.6739 7.44306 15.0233C8.30003 15.6216 10.014 17.9988 12.2784 17.9988C14.8983 17.9988 17.3245 15.2661 17.3245 13.2604C17.3245 11.2546 16.7361 10.4945 16.7753 8.81369C16.8072 7.39031 17.8431 6.71111 17.8431 6.00004C17.8431 5.40175 16.9543 5.25586 16.6625 5.25586C16.1451 5.25586 14.8186 5.91912 14.8186 7.66493C14.8186 8.34413 14.6249 8.95835 13.9298 8.95835C13.2346 8.95835 12.4586 8.00453 11.989 7.26035"
        fill="url(#paint0_linear_2596_5958)"
      />
      <path
        d="M17.842 6.00001C17.842 5.67512 17.5785 5.48386 17.2916 5.37598C17.6361 6.51737 15.889 6.56641 15.889 10.4553C15.889 14.3441 13.6246 15.4757 12.6867 15.6706C11.7489 15.8643 10.0962 15.9207 8.83836 14.3772C8.45218 13.9027 7.92868 13.3878 7.34633 12.9011C7.09991 13.3118 7.04719 14.7475 7.44318 15.0233C8.30015 15.6216 10.0141 17.9988 12.2785 17.9988C14.8984 17.9988 17.3247 15.266 17.3247 13.2603C17.3247 11.2546 16.7362 10.4945 16.7754 8.81366C16.8073 7.39028 17.842 6.71108 17.842 6.00001Z"
        fill="url(#paint1_linear_2596_5958)"
      />
      <path
        d="M7.58057 12.8328C7.16006 12.4613 6.16578 11.4021 5.51846 10.8835C4.87113 10.3661 4.30595 10.3722 3.91731 10.9436C3.52867 11.5149 4.66149 12.5177 4.92017 12.7764C5.31617 13.1724 6.94183 14.6742 7.44326 15.0249"
        fill="url(#paint2_linear_2596_5958)"
      />
      <path
        d="M9.39985 10.7863C9.35081 10.899 9.02102 10.9775 8.36511 10.264C7.86368 9.71842 6.52122 8.15038 5.72923 7.4393C4.93724 6.72823 4.32179 6.43644 3.80442 6.80914C3.28706 7.18185 3.58129 7.95667 4.15996 8.60399C4.87594 9.40457 5.69981 10.2211 6.48812 11.0057C7.10479 11.6187 8.5012 12.9146 8.34795 13.0678"
        fill="url(#paint3_linear_2596_5958)"
      />
      <path
        d="M6.48932 11.0048C7.07167 11.5834 8.3467 12.769 8.36018 13.0301C8.54408 12.7432 8.20816 11.8899 7.85262 11.5908C5.79786 9.86091 3.81298 7.07178 3.56043 7.15515C3.45254 7.56095 3.72594 8.11755 4.15994 8.60427C4.87592 9.40361 5.69978 10.2214 6.48932 11.0048Z"
        fill="url(#paint4_linear_2596_5958)"
      />
      <path
        d="M11.9879 7.26176C11.5183 6.51758 9.59353 4.12444 9.1252 3.44525C8.65564 2.76605 8.02549 2.76605 7.58903 3.07377C7.15258 3.38149 7.02263 3.99572 8.00587 5.3014C8.62622 6.12526 9.14114 6.88292 10.3794 8.24868L11.8187 8.14815L11.9879 7.26176Z"
        fill="url(#paint5_linear_2596_5958)"
      />
      <path
        d="M10.16 8.65221C9.95034 8.44257 8.09051 6.23823 7.67 5.765C6.88168 4.87984 5.8825 2.98323 4.69084 3.82794C4.06436 4.27175 4.40396 4.91539 4.58172 5.17407C4.75949 5.43276 7.07171 8.10174 7.29852 8.32732C7.52533 8.55413 9.44891 10.6726 9.4011 10.7854"
        fill="url(#paint6_linear_2596_5958)"
      />
      <path
        d="M10.3782 8.24703C10.6037 8.49591 10.806 8.81834 10.7423 8.92255C11.4926 8.31936 9.90616 6.54658 9.0075 5.48242C8.31237 4.65855 8.12234 3.61033 7.62459 3.04883C7.61233 3.05618 7.60129 3.06477 7.58903 3.07212C7.15258 3.37985 7.02263 3.99407 8.00587 5.29975C8.625 6.12361 9.14114 6.8825 10.3782 8.24703Z"
        fill="url(#paint7_linear_2596_5958)"
      />
      <path
        d="M7.29731 8.32798C7.52412 8.55478 9.4477 10.6733 9.39989 10.7861C9.99449 9.98552 7.68963 7.63284 6.57398 6.44363C5.60667 5.41257 5.44116 4.51024 4.49715 4.00391C4.14651 4.4281 4.42481 4.94792 4.58051 5.17473C4.75828 5.43341 7.07173 8.10239 7.29731 8.32798Z"
        fill="url(#paint8_linear_2596_5958)"
      />
      <path
        d="M9.35704 10.8343C9.28961 10.876 9.15107 10.8797 8.93652 10.7559C9.0959 10.8846 9.24302 10.9336 9.35704 10.8343Z"
        fill="url(#paint9_linear_2596_5958)"
      />
      <path
        d="M16.7987 5.49861C17.2928 5.41033 17.3798 5.46673 17.2916 5.37601C17.0513 5.28651 16.7938 5.25586 16.6614 5.25586C16.144 5.25586 14.8175 5.91912 14.8175 7.66493C14.8175 8.34413 14.6238 8.95835 13.9274 8.95835C13.6528 8.95835 13.3672 8.81001 13.0913 8.58443C14.3124 9.93669 15.2699 8.66289 15.4158 7.78998C15.5617 6.91708 15.3496 5.75852 16.7987 5.49861Z"
        fill="url(#paint10_radial_2596_5958)"
      />
      <path
        d="M7.43951 3.20508C7.13792 3.54713 7.14773 4.16013 8.00469 5.3003C8.62504 6.12416 9.13996 6.88183 10.3782 8.24758C10.6124 8.50626 10.8232 8.84586 10.7337 8.93536C10.9409 7.52057 8.92664 6.12907 8.27196 5.06245C7.75337 4.21652 7.65038 3.71754 7.43951 3.20508Z"
        fill="url(#paint11_linear_2596_5958)"
      />
      <path
        d="M7.29728 8.32845C7.52409 8.55525 9.44767 10.6738 9.39986 10.7866C9.82405 10.4837 9.53472 10.0117 8.87881 9.35583C7.5339 8.01092 5.66181 6.2038 4.90905 4.71545C4.83795 4.57446 4.70554 4.47025 4.54984 4.45064C4.14404 4.39792 4.44563 4.97781 4.58171 5.1752C4.75826 5.43388 7.0717 8.10286 7.29728 8.32845Z"
        fill="url(#paint12_linear_2596_5958)"
      />
      <path
        d="M4.15997 8.60415C4.87594 9.40472 5.69981 10.2212 6.48812 11.0059C7.1048 11.6189 8.5012 12.9147 8.34795 13.068C9.2135 12.3397 5.19348 9.70754 3.55923 7.15625C3.45257 7.5596 3.72596 8.11743 4.15997 8.60415Z"
        fill="url(#paint13_linear_2596_5958)"
      />
      <path
        d="M8.17138 13.2123C8.25352 13.1805 8.29643 13.1425 8.30992 13.1008C8.16157 13.2148 7.96664 13.1743 7.58045 12.8323C7.15994 12.4608 6.16566 11.4016 5.51834 10.883C5.23391 10.6562 4.96541 10.5299 4.72021 10.5078C5.07085 10.7273 4.90779 10.8057 5.68752 11.404C6.10681 11.7252 7.63685 13.422 8.17138 13.2123Z"
        fill="url(#paint14_linear_2596_5958)"
      />
      <path
        d="M7.15252 9.33098C7.72015 10.0224 8.82845 11.1638 9.3262 10.8488C9.18889 10.8953 8.87626 10.8193 8.36625 10.264C7.86482 9.71839 6.52236 8.15035 5.73037 7.43927C5.08918 6.86306 4.56323 6.56269 4.11206 6.6669C5.4251 7.09845 6.61431 8.67753 7.15252 9.33098Z"
        fill="url(#paint15_linear_2596_5958)"
      />
      <path
        d="M7.76682 6.35513C8.67283 7.34083 10.0177 9.29628 10.6994 8.95423C10.5878 8.98856 10.3475 8.84021 10.16 8.65141C9.95031 8.44177 8.09048 6.23743 7.66997 5.7642C7.12318 5.15121 6.47708 4.05272 5.73413 3.7168C6.48321 4.30772 7.03245 5.55701 7.76682 6.35513Z"
        fill="url(#paint16_linear_2596_5958)"
      />
      <path
        d="M13.0914 8.58584C13.0926 8.58584 13.0926 8.58584 13.0914 8.58584C12.6684 8.24011 12.2712 7.71171 11.9868 7.26177C11.5184 6.5176 9.59362 4.12446 9.12529 3.44526C8.98185 3.23807 8.82492 3.09463 8.66187 3.00391C8.88009 3.31776 9.00392 3.76525 9.49799 4.31939C10.29 5.20824 11.3259 6.80938 11.6815 7.35985C12.0358 7.90787 12.6096 9.06766 13.0914 8.58584Z"
        fill="url(#paint17_linear_2596_5958)"
      />
      <path
        d="M15.6855 16.4781C15.3913 15.7622 14.961 15.3257 14.3394 15.7352C12.6439 16.8521 10.6933 16.4745 8.24745 14.4982C6.06641 12.734 4.9385 11.4736 3.87679 11.0176C3.62179 11.5975 4.67123 12.528 4.91888 12.7756C5.31488 13.1716 6.94054 14.6735 7.44197 15.0241C8.29894 15.6224 10.0129 17.9996 12.2773 17.9996C13.5437 17.9984 14.7624 17.3621 15.6855 16.4781Z"
        fill="url(#paint18_linear_2596_5958)"
      />
      <path
        d="M16.7742 8.8135C16.8061 7.39012 17.842 6.71092 17.842 5.99985C17.842 5.7902 17.7329 5.63696 17.5784 5.52539C17.7672 6.07954 17.3173 6.57116 16.6455 7.40729C15.9172 8.31329 15.7566 9.71951 16.2568 11.1269C16.7583 12.5344 16.1269 14.4751 15.1081 15.0893C14.0893 15.7036 12.7358 18.4044 8.86042 15.3529C8.85919 15.3517 8.85919 15.3517 8.85796 15.3505C8.62135 15.131 8.38718 14.9361 8.15057 14.789C6.51387 13.7751 5.58089 12.8629 4.87595 12.0967C4.73741 11.9459 4.36471 11.3685 3.9209 11.5585C4.1428 12.0611 4.73986 12.5969 4.91886 12.7759C5.31485 13.1719 6.94051 14.6737 7.44195 15.0244C7.50692 15.0697 7.57803 15.1261 7.65282 15.1911C7.67243 15.2083 7.69205 15.2254 7.71166 15.2426C7.71657 15.2475 7.7227 15.2524 7.7276 15.2561C7.73986 15.2671 7.75335 15.2794 7.76561 15.2904C7.76806 15.2929 7.77174 15.2953 7.77419 15.299C8.28297 15.7612 8.98302 16.5262 9.84121 17.1233C9.84121 17.1233 9.84244 17.1233 9.84244 17.1245C9.91722 17.1772 9.99446 17.2287 10.0717 17.2777C10.0729 17.279 10.0741 17.279 10.0754 17.2802C10.1526 17.3292 10.2311 17.377 10.3095 17.4224C10.3108 17.4224 10.3108 17.4236 10.312 17.4236C10.9054 17.7645 11.5637 17.9998 12.2748 17.9998C14.8947 17.9998 17.321 15.2671 17.321 13.2614C17.321 11.2557 16.7362 10.4956 16.7742 8.8135Z"
        fill="url(#paint19_radial_2596_5958)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2596_5958"
          x1="16.0509"
          y1="18.5177"
          x2="13.892"
          y2="13.4596"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#FFD748" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2596_5958"
          x1="16.4807"
          y1="14.9998"
          x2="11.9525"
          y2="9.59833"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#FFD748" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2596_5958"
          x1="4.37746"
          y1="11.404"
          x2="7.04583"
          y2="13.3689"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#FFD748" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2596_5958"
          x1="3.40577"
          y1="6.20299"
          x2="8.08754"
          y2="11.1031"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#FFD748" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2596_5958"
          x1="6.9998"
          y1="9.11308"
          x2="5.45217"
          y2="10.571"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FFA754" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2596_5958"
          x1="8.46256"
          y1="3.89138"
          x2="11.1794"
          y2="8.16076"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#FFD748" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2596_5958"
          x1="5.65808"
          y1="4.6008"
          x2="9.34527"
          y2="9.58175"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00132565" stopColor="#FFCB4B" />
          <stop offset="1" stopColor="#FFD748" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2596_5958"
          x1="10.0432"
          y1="5.20124"
          x2="7.71447"
          y2="7.30358"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FFA754" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_2596_5958"
          x1="7.9012"
          y1="6.69906"
          x2="4.69916"
          y2="9.03994"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FFA754" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_2596_5958"
          x1="10.2866"
          y1="9.96228"
          x2="7.08451"
          y2="12.3032"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FFA754" />
        </linearGradient>
        <radialGradient
          id="paint10_radial_2596_5958"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.4881 6.16172) scale(3.84415 3.84415)"
        >
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FFA754" />
        </radialGradient>
        <linearGradient
          id="paint11_linear_2596_5958"
          x1="10.4694"
          y1="4.7889"
          x2="8.48029"
          y2="6.6325"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3118" stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FF8900" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_2596_5958"
          x1="8.94623"
          y1="5.7881"
          x2="6.32638"
          y2="8.1492"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3118" stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FF8900" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_2596_5958"
          x1="8.06082"
          y1="8.34174"
          x2="4.82644"
          y2="11.0586"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3118" stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FF8900" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_2596_5958"
          x1="4.53159"
          y1="10.3151"
          x2="8.29157"
          y2="13.3716"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FFBC47" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_2596_5958"
          x1="5.2512"
          y1="6.52663"
          x2="8.1864"
          y2="10.9901"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FFBC47" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_2596_5958"
          x1="7.55042"
          y1="6.84511"
          x2="8.37519"
          y2="6.2144"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FFA754" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_2596_5958"
          x1="11.6205"
          y1="9.33753"
          x2="10.5208"
          y2="4.09783"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FFBC47" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_2596_5958"
          x1="9.11007"
          y1="14.3657"
          x2="7.46054"
          y2="15.8818"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.3118" stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FF8900" />
        </linearGradient>
        <radialGradient
          id="paint19_radial_2596_5958"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.2026 9.35529) rotate(48.14) scale(11.1425 10.1139)"
        >
          <stop offset="0.6134" stopColor="#FFBC47" stopOpacity="0" />
          <stop offset="1" stopColor="#FF8900" />
        </radialGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
