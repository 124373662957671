// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textSecond", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 57 67" {...props} color="transparent">
      <path
        d="M13.9062 22.3333C13.9062 23.7324 13.9062 25.125 13.9062 26.5208"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.73438 29.3125C10.1993 29.3125 10.6608 29.3125 11.1246 29.3125"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6797 29.3125C17.6087 29.3125 18.5345 29.3125 19.4602 29.3125"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2891 32.1042C15.2891 33.4718 15.2891 34.9204 15.2891 36.2917"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.0234 53.0417C25.9392 53.0417 26.8977 53.0417 27.8039 53.0417"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.5859 44.6667C30.5859 46.0645 30.5859 47.4554 30.5859 48.8542"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.5859 55.8333C30.5859 57.1625 30.5859 58.7062 30.5859 60.0208"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.7578 51.6458C35.7007 51.6458 36.606 51.6458 37.5383 51.6458"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.875 6.97925C45.875 8.37643 45.875 9.76689 45.875 11.1667"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.9297 15.3542C40.316 15.3542 41.7201 15.3542 43.1004 15.3542"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.875 18.1458C46.3118 19.4918 46.3911 21.0204 47.2652 22.3333"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.0469 13.9583C50.973 13.9583 51.9004 13.9583 52.8274 13.9583"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
