import { NavigationMenuAccordion } from "../";
import { StyledNavItem, StyledNavList, NavItemGeneralSettings } from "components/header/components";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { NAV_LIST, headerThemes } from "components/header/constants";
// Types
import { NavigationMenuMobileProps } from "./types";

const NavigationMenuMobile: React.FC<NavigationMenuMobileProps> = ({
  isMobileMenuOpen,
  headerTheme,
  setIsMenuOpen,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const handleClickLink = () => {
    handleCloseMobileMenu();
  };

  const handleCloseMobileMenu = () => {
    if (!isDesktop) {
      setIsMenuOpen(false);
    }
  };

  const isHeaderThemeLight = headerTheme === headerThemes.light;

  const navItemGeneralSettings: NavItemGeneralSettings = {
    textScale: "p24Regular",
    color: isHeaderThemeLight ? "bgDark" : "textSecond",
  };

  return (
    <StyledNavList>
      {NAV_LIST.map(({ label, DropdownComponent, link }) => {
        return DropdownComponent ? (
          <div key={label}>
            <NavigationMenuAccordion
              DropdownComponent={DropdownComponent}
              label={label}
              handleCloseMobileMenu={handleCloseMobileMenu}
              navItemGeneralSettings={navItemGeneralSettings}
              isMobileMenuOpen={isMobileMenuOpen}
            />
          </div>
        ) : (
          <StyledNavItem
            {...navItemGeneralSettings}
            external={!link}
            p="1rem"
            href={link}
            color="white"
            key={label}
            onClick={handleClickLink}
          >
            {t(label)}
          </StyledNavItem>
        );
      })}
    </StyledNavList>
  );
};

export default NavigationMenuMobile;
