import { useThemeContext } from "context";
// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 17 16" width="17" height="16" {...props} color="transparent">
      <path
        d="M0.833008 5.99992H10.1663M10.1663 5.99992L5.49967 1.33325M10.1663 5.99992L5.49967 10.6666"
        stroke="url(#paint0_linear_12208_81157)"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12208_81157"
          x1="10.1663"
          y1="1.33325"
          x2="-0.392165"
          y2="8.97343"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={theme.colors.accentYellow} />
          <stop offset="1" stopColor={theme.colors.accentYellow} />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
