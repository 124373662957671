// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 60 60" {...props} color="transparent">
      <g clipPath="url(#clip0_6926_8874)">
        <path
          d="M42.5863 32.5209C42.5802 32.5038 42.5736 32.4871 42.5668 32.4703C42.3383 31.9146 41.8026 31.5557 41.2019 31.5557C41.2014 31.5557 41.2008 31.5557 41.2003 31.5557C40.5989 31.5564 40.0633 31.9164 39.8359 32.473C39.8297 32.4879 39.8239 32.5029 39.8185 32.518L34.7818 46.449C34.5042 47.2171 35.0775 48.0197 35.8837 48.0197C36.3634 48.0197 36.8135 47.723 36.9859 47.2459L38.1525 44.019H44.2157L45.3704 47.2426C45.5886 47.852 46.2602 48.1691 46.8688 47.9507C47.4782 47.7325 47.795 47.0616 47.5768 46.4523L42.5863 32.5209ZM39 41.6754L41.1981 35.5954L43.3762 41.6754H39Z"
          fill={theme.colors[color]}
        />
        <path
          d="M25.4109 23.6875C26.4109 22.3235 27.0033 20.641 27.0033 18.8218C27.0033 14.2828 23.3227 10.5898 18.7985 10.5898C14.2745 10.5898 10.5938 14.2828 10.5938 18.8218C10.5938 23.3607 14.2744 27.0535 18.7985 27.0535C20.6671 27.0535 22.3907 26.4223 23.7717 25.363L25.0554 26.6517C25.5123 27.1102 26.2542 27.1115 26.7128 26.6548C27.1712 26.198 27.1727 25.456 26.7159 24.9975L25.4109 23.6875ZM18.7985 24.7096C15.5668 24.7096 12.9375 22.0684 12.9375 18.8217C12.9375 15.5749 15.5668 12.9335 18.7985 12.9335C22.0302 12.9335 24.6595 15.5749 24.6595 18.8217C24.6595 19.9914 24.3169 21.0816 23.7291 21.999L22.2162 20.4802C21.7595 20.0218 21.0175 20.0204 20.5588 20.4771C20.1004 20.9339 20.0988 21.6758 20.5556 22.1345L22.0993 23.6841C21.1586 24.3307 20.0218 24.7096 18.7985 24.7096Z"
          fill={theme.colors[color]}
        />
        <path
          d="M60 38.3966C60 26.6739 49.2994 17.6707 37.6109 19.9158C38.2341 9.08016 29.5943 0 18.8217 0C8.44348 0 0 8.44348 0 18.8217C0 24.3261 2.36836 29.4813 6.52031 33.0661L5.88961 39.1321C5.84648 39.5463 6.0266 39.9524 6.36258 40.1986C6.69949 40.4455 7.14141 40.4937 7.52215 40.3281L14.7316 37.1961C17.2424 37.7528 19.8527 37.7919 22.3914 37.3024C21.7634 48.1778 30.4444 57.2183 41.1783 57.2183C42.5562 57.2183 43.9302 57.068 45.2686 56.7709L52.478 59.903C52.858 60.0682 53.2999 60.0209 53.6375 59.7735C53.9735 59.5273 54.1536 59.1213 54.1105 58.707L53.4796 52.6406C57.6315 49.0563 60 43.9012 60 38.3966ZM14.8978 34.8285C14.6494 34.7679 14.3876 34.79 14.153 34.8921L8.42824 37.3791L8.91598 32.6878C8.95711 32.2928 8.79527 31.9038 8.48602 31.6545C4.58262 28.5062 2.34375 23.8289 2.34375 18.8217C2.34375 9.7357 9.7357 2.34375 18.8217 2.34375C27.9077 2.34375 35.2997 9.7357 35.2997 18.8217C35.2997 27.9077 27.9077 35.2997 18.8217 35.2997C17.4983 35.2998 16.1782 35.1413 14.8978 34.8285ZM51.5138 51.2289C51.2045 51.4782 51.0427 51.8672 51.0838 52.2622L51.5718 56.9538L45.8469 54.4668C45.6122 54.3648 45.3504 54.3424 45.1017 54.4032C43.823 54.716 42.5029 54.8746 41.1783 54.8746C31.4702 54.8746 23.7724 46.4592 24.7915 36.6725C31.1586 34.5376 36.017 29.0852 37.305 22.3784C38.5659 22.0743 39.8653 21.9185 41.1783 21.9185C50.2643 21.9185 57.6562 29.3105 57.6562 38.3965C57.6562 43.4039 55.4175 48.0811 51.5138 51.2289Z"
          fill={theme.colors[color]}
        />
      </g>
      <defs>
        <clipPath id="clip0_6926_8874">
          <rect width="60" height="60" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
