import { useAnimation } from "framer-motion";
// Hooks
import { useMatchBreakpoints } from "hooks";

const useTeamMemberAnimation = () => {
  const { isDesktop } = useMatchBreakpoints();

  const controls = useAnimation();
  const [isActive, setIsActive] = React.useState(false);

  const handleShowAnimations = () => {
    if (isDesktop) {
      controls.start("visible");
    }

    setIsActive(true);
  };

  const handleHideAnimations = () => {
    if (isDesktop) {
      controls.start("hidden");
    }

    setIsActive(false);
  };

  return { isActive, controls, handleShowAnimations, handleHideAnimations };
};

export default useTeamMemberAnimation;
