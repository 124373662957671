// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ ...props }) => {
  return (
    <Svg viewBox="0 0 16 17" {...props} color="transparent">
      <path
        d="M2.66797 13.8327L13.3346 3.16602"
        stroke="url(#paint0_linear_759_23117)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66797 3.16602H13.3346V13.8327"
        stroke="#E1E1E2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_759_23117"
          x1="13.3346"
          y1="3.16602"
          x2="1.26777"
          y2="11.8977"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E1E1E2" />
          <stop offset="1" stopColor="#EAEAEA" />
        </linearGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
