import { useTranslation } from "context";
import { useMatchBreakpoints } from "hooks";

import { Accordion, Text } from "components";
import { ArrowLeftIcon } from "components/svg";
import { StyledComingSoonContainer } from "components/header/components/dropdown-menu-items/components/dropdown-item/styled";
import { StyledDisableLinkContainer, StyledFooterLink, StyledFooterLinksWrapper } from "components/footer/styled";
import { StyledFooterAccordionFlexGap } from "./styled";

import { FooterAccordionProps } from "./types";

const FooterAccordion: React.FC<FooterAccordionProps> = ({ title, navItems }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { isMobile } = useMatchBreakpoints();

  const { t } = useTranslation();

  const handleClickContent = () => {
    setIsOpen(false);
  };

  return (
    <Accordion
      isOpenFromParent={isOpen}
      setIsOpenFromParent={setIsOpen}
      $backgroundColor="transparent"
      headerProps={{ padding: "0", maxWidth: "fit-content" }}
      rotateIcon={<ArrowLeftIcon color="navItemFourthForDarkBg" width="1.25rem" />}
      iconRotateInitialDeg={270}
      iconRotateOpenDeg={90}
      handleOnContentClick={handleClickContent}
      title={
        <Text textScale="p14Regular" color="navItemFourthForDarkBg">
          {t(title)}
        </Text>
      }
    >
      <StyledFooterAccordionFlexGap>
        {navItems.map(({ link, title }, index) => {
          return (
            <div key={index}>
              {link ? (
                <StyledFooterLinksWrapper>
                  <StyledFooterLink color={isMobile ? "navItemFourthForDarkBg" : "white"} href={link}>
                    {t(title)}
                  </StyledFooterLink>
                </StyledFooterLinksWrapper>
              ) : (
                <StyledDisableLinkContainer>
                  <Text color="textFourth" textScale="p14Regular">
                    {title}
                  </Text>

                  <StyledComingSoonContainer>
                    <Text textScale="p14Regular" color="disabledLight">
                      {t("Soon")}
                    </Text>
                  </StyledComingSoonContainer>
                </StyledDisableLinkContainer>
              )}
            </div>
          );
        })}
      </StyledFooterAccordionFlexGap>
    </Accordion>
  );
};

export default FooterAccordion;
