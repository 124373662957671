// Components + styling
import { VideoPlayerModal } from "components";
import TestimonialItem from "../testimonial-item";
import TestimonialsSlider from "../testimonials-slider";
import { StyledTestimonialsListWrapper } from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Context
import { useVideoPlayerContext } from "context";
// Constants
import { TESTIMONIALS_LIST, TESTIMONIALS_LIST_GAP, TESTIMONIAL_ITEMS_PER_ROW_DEFAULT } from "../../constants";

const TestimonialsList: React.FC = () => {
  const { isTablet, isMobile } = useMatchBreakpoints();

  const { isVideoPlayerOpen, videoSource } = useVideoPlayerContext();

  const itemsPerRow = isMobile ? 1 : isTablet ? 2 : TESTIMONIAL_ITEMS_PER_ROW_DEFAULT;

  const remainsItems = TESTIMONIALS_LIST.length % itemsPerRow;

  return (
    <>
      {isMobile ? (
        <TestimonialsSlider />
      ) : (
        <StyledTestimonialsListWrapper remainsItems={remainsItems} testimonialsListGap={TESTIMONIALS_LIST_GAP}>
          {TESTIMONIALS_LIST.map(testimonialItem => (
            <TestimonialItem key={testimonialItem.name} {...testimonialItem} />
          ))}
        </StyledTestimonialsListWrapper>
      )}
      {videoSource.length && <VideoPlayerModal isOpen={isVideoPlayerOpen} videoSources={videoSource} />}
    </>
  );
};

export default TestimonialsList;
