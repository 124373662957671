// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 21 21" {...props} color={color}>
      <path
        d="M5.43895 9.0877L5.18222 9.34442L5.34687 9.668C6.65525 12.2393 8.76202 14.3364 11.3308 15.6525L11.6548 15.8185L11.9123 15.5611L13.8373 13.6361C13.939 13.5344 14.089 13.5026 14.2141 13.5455L14.2141 13.5455L14.2194 13.5473C15.2499 13.8877 16.3616 14.0712 17.5 14.0712C17.7051 14.0712 17.875 14.2411 17.875 14.4462V17.5C17.875 17.7051 17.7051 17.875 17.5 17.875C9.55989 17.875 3.125 11.4401 3.125 3.5C3.125 3.29489 3.29489 3.125 3.5 3.125H6.5625C6.76761 3.125 6.9375 3.29489 6.9375 3.5C6.9375 4.64584 7.12063 5.74735 7.46011 6.77643C7.50172 6.91206 7.47074 7.0559 7.36395 7.1627L5.43895 9.0877Z"
        stroke={theme.colors[color]}
      />
    </Svg>
  );
};

export default Icon;
