import styled from "styled-components";

export const StyledCopyrightsWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  z-index: ${({ theme }) => theme.zIndices.dropdown};

  border-top: 1px solid ${({ theme }) => theme.colors.lineDark};
  padding-top: 1.5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin-top: 2.5rem;
    padding-top: 2rem;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

export const StyledLinkWrapper = styled.div`
  display: flex;
  margin-bottom: 1.5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin-bottom: 0;
  }
`;
