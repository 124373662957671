// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "white", ...props }) => {
  return (
    <Svg viewBox="0 0 75 20" width="75" height="20" {...props} color={color}>
      <path d="M19.687 0h3.382v20h-3.382V0Zm15.217 13.62c0 3.06-2.72 3.305-3.528 3.305-2.022 0-2.39-1.79-2.39-2.87V6.4h-3.381v7.638c0 1.896.629 3.461 1.731 4.504.974.923 2.433 1.427 4.014 1.427 1.121 0 2.709-.33 3.554-1.061V20h3.382V6.4h-3.382v7.22ZM45.05 1.6h-3.381v4.8H39.13v3.2h2.537V20h3.381V9.6h2.537V6.4h-2.537V1.6Zm13.186 14.334c-.736.626-1.709.973-2.775.973-2.352 0-4.08-1.634-4.08-3.878s1.673-3.81 4.08-3.81c1.048 0 2.039.33 2.794.957l.513.418 2.28-2.157-.57-.487c-1.342-1.147-3.124-1.79-5.018-1.79-4.227 0-7.297 2.886-7.297 6.852 0 3.949 3.143 6.938 7.297 6.938 1.93 0 3.73-.643 5.054-1.808l.552-.487-2.316-2.155-.514.434Zm14.882-8.348c-.974-.922-2.115-1.426-3.696-1.426-1.121 0-2.391.33-3.237 1.06V0h-3.381v20h3.382v-7.492c0-3.06 2.297-3.304 3.106-3.304 2.022 0 1.966 1.791 1.966 2.87V20h3.381v-7.91c0-1.896-.418-3.46-1.521-4.504Z" />
      <path
        d="M55.35 10.75c.649 0 1.27.244 1.73.678.458.434.715 1.022.715 1.636 0 .614-.257 1.202-.716 1.636a2.518 2.518 0 0 1-1.729.678c-.648 0-1.27-.244-1.728-.678a2.253 2.253 0 0 1-.716-1.636c0-.614.257-1.202.716-1.636a2.517 2.517 0 0 1 1.728-.678Z"
        fill="#FF3D2E"
      />
      <path d="M14.953 14.977c-1.285 1.252-3.032 1.93-4.87 1.93-3.767 0-6.506-2.8-6.506-6.643 0-3.86 2.739-6.66 6.506-6.66a7.03 7.03 0 0 1 4.853 1.912l.514.487 2.26-2.139-.496-.487C15.34 1.55 12.804.56 10.083.56 4.533.56.361 4.734.361 10.28c0 5.531 4.19 9.688 9.722 9.688 2.74 0 5.275-1.008 7.15-2.834l.496-.488-2.242-2.173-.534.504Z" />
    </Svg>
  );
};

export default Icon;
