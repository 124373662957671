// Components + styling
import { Button, Column, Image, Text } from "components";
import { StyledBoxWrapper, StyledContainerWrapper, StyledColumn, StyledHeading, StyledButtonWrapper } from "./styled";
import { StyledImageAbsolute } from "../../styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Utils
import { scrollToElementById } from "utils";
// Constants
import { BLOCK_ID } from "configs";
// Types
import { BannerCenterContentProps } from "./types";

const BannerCenterContent: React.FC<BannerCenterContentProps> = ({
  title,
  isWhite,
  subTitle,
  imageSrc,
  imageRatio,
  buttonLabel = "Get a free estimation today",
  buttonVariant,
  buttonBgColor,
  buttonTheme,
  description,
  isImageAbsolute,
  isFullContainer,
  isImageFullContainerMobile,
}) => {
  const { t } = useTranslation();

  const { isDesktop, isMobile } = useMatchBreakpoints();

  return (
    <StyledBoxWrapper isFullContainer={isFullContainer} imageSrc={imageSrc} isWhite={isWhite}>
      <StyledContainerWrapper>
        <Column flexDirection="column" alignItems="center" height="100%">
          <StyledColumn>
            <div>
              <StyledHeading
                scale="h1"
                as="h1"
                color={isWhite ? "bgDark" : "white"}
                lineHeight={{ mobile: "2.125rem" }}
              >
                {t(title).toUpperCase()}
              </StyledHeading>

              {subTitle && (
                <StyledHeading
                  scale="h5"
                  as="h2"
                  color={isWhite ? "bgDark" : "white"}
                  lineHeight={{ mobile: "2.125rem" }}
                >
                  {t(title).toUpperCase()}
                </StyledHeading>
              )}
            </div>
            <Column alignItems="center" px={{ mobile: "1rem" }}>
              <Text
                textScale={isDesktop ? "p22Regular" : "p16Regular"}
                color={isWhite ? "textFifth" : "textSecond"}
                mb={{ _: "1.5rem", laptop: "3rem" }}
                whiteSpace="pre-line"
              >
                {t(description)}
              </Text>

              <StyledButtonWrapper>
                <Button
                  width="100%"
                  onClick={() => scrollToElementById(BLOCK_ID.contactUs)}
                  hoverLinesPosition={isMobile ? "mobileTopLeft" : "topRight"}
                  variant={buttonVariant}
                  buttonTheme={buttonTheme}
                  bgColor={buttonBgColor}
                >
                  {t(buttonLabel)}
                </Button>
              </StyledButtonWrapper>
            </Column>
          </StyledColumn>
          {!isImageAbsolute && !isFullContainer && imageSrc && (
            <Image
              zIndex="-2"
              width={isMobile ? (isImageFullContainerMobile ? "110%" : "100%") : !isMobile ? "100%" : "50%"}
              mx={isImageFullContainerMobile ? "-1rem" : "0"}
              src={imageSrc}
              aspectRatio={isMobile ? 1.48 : imageRatio}
            />
          )}
        </Column>
      </StyledContainerWrapper>

      {isImageAbsolute && imageSrc && <StyledImageAbsolute width="100%" src={imageSrc} aspectRatio={imageRatio} />}
    </StyledBoxWrapper>
  );
};

export default BannerCenterContent;
