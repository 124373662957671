// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", ...props }) => {
  return (
    <Svg width="6" height="5" viewBox="0 0 6 5" {...props} color={color}>
      <path d="M5.17506 2.352C5.17506 2.64 5.12173 2.91733 5.01506 3.184C4.91906 3.44 4.77506 3.66933 4.58306 3.872C4.39106 4.064 4.16173 4.21867 3.89506 4.336C3.6284 4.45333 3.32973 4.512 2.99906 4.512C2.65773 4.512 2.35373 4.45333 2.08706 4.336C1.8204 4.21867 1.59106 4.064 1.39906 3.872C1.21773 3.66933 1.07373 3.44 0.967063 3.184C0.871063 2.91733 0.823063 2.64 0.823063 2.352C0.823063 2.064 0.871063 1.792 0.967063 1.536C1.07373 1.26933 1.21773 1.04 1.39906 0.848C1.59106 0.656 1.8204 0.501333 2.08706 0.384C2.35373 0.266666 2.65773 0.208 2.99906 0.208C3.32973 0.208 3.6284 0.266666 3.89506 0.384C4.16173 0.501333 4.39106 0.656 4.58306 0.848C4.77506 1.04 4.91906 1.26933 5.01506 1.536C5.12173 1.792 5.17506 2.064 5.17506 2.352Z" />
    </Svg>
  );
};

export default Icon;
