// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { ROUTES } from "navigation/routes";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { OTHER_INDUSTRIES_TABS_LIST } from "../components/industry-info/constants";
// Types
import { IndustriesVariants, IndustryPageContent } from "../types";

const OTHER_INDUSTRIES_CONTENT: IndustryPageContent = {
  banner: {
    title: "Software development for other industries",
    description:
      "Custom software development tailors specialized solutions to meet the specific business needs of different industries, like healthcare, gaming, etc.",
    imageSrc: "/images/industries-banner-other.webp",
    imageRatio: 0.86,
  },
  industryInfo: {
    title: "Tech solutions integration for other industries",
    description:
      "We are ready to provide software solutions for your craziest ideas and make sure they work to drive your business success.",
    industryInfoTabsList: OTHER_INDUSTRIES_TABS_LIST,
  },
  portfolio: {
    portfolioList: [
      CaseStudiesSlugEnums.astrocat,
      CaseStudiesSlugEnums.addmed,
      CaseStudiesSlugEnums.revolve,
      CaseStudiesSlugEnums.aiBook,
    ],
    title: "Other industries case studies",
    TitleIcon: WavingHandIcon,
    description: "Diverse and tech-savvy — check out how we managed to deliver our most unusual projects.",
  },
  coveredIndustries: {
    coveredIndustriesList: [
      { label: "Fintech", link: `/${ROUTES.industries}/${IndustriesVariants.fintech}` },
      { label: "Logistics", link: `/${ROUTES.industries}/${IndustriesVariants.logistics}` },
      { label: "Real Estate", link: `/${ROUTES.industries}/${IndustriesVariants.realEstate}` },
      { label: "Aviation", link: `/${ROUTES.industries}/${IndustriesVariants.aviation}` },
      { label: "Media", link: `/${ROUTES.industries}/${IndustriesVariants.media}` },
    ],
  },
};

export default OTHER_INDUSTRIES_CONTENT;
