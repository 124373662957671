import styled from "styled-components";
// Components
import { Link } from "components";

export const StyledPortfolioWrapper = styled.div<{ componentOnTop?: React.ReactNode }>`
  background-color: ${({ theme }) => theme.colors.bgDark};

  ${({ theme }) => theme.mediaQueries.tablet} {
    background-image: ${({ componentOnTop }) => (componentOnTop ? "none" : `url("/images/portfolio-bg.webp")`)};
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 40% auto;
  }
`;

export const StyledPortfolioTitleWrapper = styled.div`
  margin-bottom: 1.5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 70%;
    margin-bottom: 0;
  }
  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: 65%;
  }
`;

export const StyledPortfolioTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledPortfolioTitleIconWrapper = styled.div`
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

export const StyledPortfolioLink = styled(Link).attrs({ color: "white" })`
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.textAccent};
  }
`;

export const StyledPortfolioItemWrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: calc(50% - 0.5rem);
    margin-bottom: 4rem;

    &:nth-of-type(2) {
      transform: translateY(3.5rem);
    }

    &:nth-last-of-type(2) {
      margin-bottom: 0;
    }
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    &:nth-of-type(2) {
      transform: translateY(2rem);
    }
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    width: calc(47% - 1rem);
  }
`;
