import { css, keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

export const spinAnimation = css`
  animation: ${rotate} 2s linear infinite;
`;

// TODO In header button & how we work button 3rd line is weird dashed, need to fix
export const defaultButtonHoverLinesAnimation = css`
  path {
    stroke-dasharray: 50;
    stroke-dashoffset: 50;

    &:nth-child(1) {
      animation: ${dash} 0.25s linear forwards;
    }
    &:nth-child(2) {
      animation: ${dash} 0.25s linear 0.1s forwards;
    }
    &:nth-child(3) {
      animation: ${dash} 0.25s linear 0.2s forwards;
    }
  }
`;

export const bannerButtonLeftLinesAnimation = css`
  path {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;

    &:nth-of-type(1) {
      animation: ${dash} 0.3s linear 1s forwards;
    }

    &:nth-of-type(2) {
      animation: ${dash} 0.3s linear 1.2s forwards;
    }

    &:nth-of-type(3) {
      animation: ${dash} 0.3s linear 1.4s forwards;
    }
  }
`;

export const bannerButtonRightLinesAnimation = css`
  path {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;

    &:nth-of-type(1) {
      animation: ${dash} 0.3s linear 1.6s forwards;
    }

    &:nth-of-type(2) {
      animation: ${dash} 0.3s linear 1.8s forwards;
    }

    &:nth-of-type(3) {
      animation: ${dash} 0.3s linear 2s forwards;
    }
  }
`;

export const titleStraightLinesAnimation = css`
  path {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;

    &:nth-of-type(1) {
      animation: ${dash} 0.3s linear 0.3s forwards;
    }

    &:nth-of-type(2) {
      animation: ${dash} 0.3s linear 0.6s forwards;
    }

    &:nth-of-type(3) {
      animation: ${dash} 0.3s linear 0.9s forwards;
    }
  }
`;

export const inputLabelUnderlineAnimation = css`
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
  animation: ${dash} 1s linear 0.25s forwards;
`;

export const curlyArrowAnimation = css`
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  animation: ${dash} 1s linear 0.5s forwards;
`;

export const landingBannerStarsSmAnimation = css`
  path {
    stroke-dasharray: 10;
    stroke-dashoffset: 10;

    &:nth-of-type(1) {
      animation: ${dash} 0.5s linear 0.5s forwards;
    }

    &:nth-of-type(2) {
      animation: ${dash} 0.5s linear 1s forwards;
    }

    &:nth-of-type(3) {
      animation: ${dash} 0.5s linear 1.5s forwards;
    }

    &:nth-of-type(4) {
      animation: ${dash} 0.5s linear 2s forwards;
    }
  }
`;

export const landingBannerStarsAnimation = css`
  path {
    stroke-dasharray: 20;
    stroke-dashoffset: 20;

    &:nth-of-type(1) {
      animation: ${dash} 0.5s linear 0.5s forwards;
    }

    &:nth-of-type(2) {
      animation: ${dash} 0.5s linear 1s forwards;
    }

    &:nth-of-type(3) {
      animation: ${dash} 0.5s linear 1.5s forwards;
    }

    &:nth-of-type(4) {
      animation: ${dash} 0.5s linear 2s forwards;
    }
  }
`;

export const ellipseWrapperAnimation = css`
  path {
    stroke-dasharray: 1200;
    stroke-dashoffset: 1200;
    animation: ${dash} 0.5s linear forwards;
  }
`;

export const chooseProjectArrowAnimation = css`
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  animation: ${dash} 1s linear 0.5s forwards;
`;

export const circleProgressAnimation = (animationDuration: number) => {
  return css`
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    animation: ${dash} ${animationDuration}s linear infinite reverse;
  `;
};
