import { StyledNavList, StyledNavItem, StyledDropdownTitle } from "./styled";

import { Dropdown } from "components";
// Context
import { useBlur, useTranslation } from "context";
// Constants
import { NAV_LIST, headerThemes } from "components/header/constants";
// Types
import { NavItemGeneralSettings, NavigationMenuProps } from "./types";
import { DropdownComponentProps } from "components/dropdown/types";

export const NavigationMenu: React.FC<NavigationMenuProps> = ({ headerTheme }) => {
  const [activeDropdown, setActiveDropdown] = React.useState(-1);

  const { t } = useTranslation();
  const { setIsVisible } = useBlur();

  const handleChangeActiveDropdown = (index: number) => {
    setActiveDropdown(index);
  };

  const isHeaderThemeLight = headerTheme === headerThemes.light;

  const navItemGeneralSettings: NavItemGeneralSettings = {
    textScale: "p16Regular",
    color: isHeaderThemeLight ? "bgDark" : "white",
  };

  return (
    <StyledNavList onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
      {NAV_LIST.map(({ label, DropdownComponent, link }, index) => {
        const isActive = activeDropdown === index;

        return DropdownComponent ? (
          <div key={label}>
            <Dropdown<DropdownComponentProps>
              title={
                <StyledDropdownTitle
                  onMouseEnter={() => handleChangeActiveDropdown(index)}
                  key={label}
                  {...navItemGeneralSettings}
                >
                  {t(label)}
                </StyledDropdownTitle>
              }
              dropdownComponent={DropdownComponent}
              onMouseLeave={() => handleChangeActiveDropdown(index)}
              isUnderline={isActive}
              isHeaderThemeLight={isHeaderThemeLight}
              dropdownProps={{}}
            />
          </div>
        ) : (
          <StyledNavItem
            {...navItemGeneralSettings}
            px={{ _: "1rem", laptop: "0" }}
            href={link}
            external={!link}
            disabled={!link}
            key={label}
            isUnderline={isActive}
            isHeaderThemeLight={isHeaderThemeLight}
          >
            {t(label)}
          </StyledNavItem>
        );
      })}
    </StyledNavList>
  );
};
