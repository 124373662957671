// Constants
import { ROUTES, ROUTE_PARAMS } from "navigation/routes";
// Utils
import { removeTrailingSlashIfExists } from "utils";
import { findMatchingRoute } from "./find-machine-route";

export const getBasePath = (path: string) => {
  let basePath = removeTrailingSlashIfExists(path);
  const matchingRoute = findMatchingRoute(basePath);

  if (basePath.startsWith(`/${ROUTES.industries}/`)) {
    basePath = `/:${ROUTE_PARAMS.industryType}`;
  } else if (basePath.startsWith(`/${ROUTES.services}/`)) {
    basePath = `/:${ROUTE_PARAMS.serviceType}`;
  } else if (basePath.startsWith(`/${ROUTES.caseStudies}/`)) {
    basePath = `/:${ROUTES.caseStudies}`;
  } else if (basePath.startsWith(`/${ROUTES.careers}/`)) {
    basePath = `/:${ROUTE_PARAMS.careersType}`;
  } else if (basePath && !matchingRoute) {
    basePath = "/404";
  }

  return basePath;
};
