import React from "react";
// Components + styling
import { ContentWrapper, ResponsiveBoxWrapper } from "./styled";
// Types
import { ResponsiveBoxProps } from "./types";

export const ResponsiveBox = React.forwardRef<HTMLDivElement, ResponsiveBoxProps>(
  ({ width, aspectRatio, children, ...props }, ref) => {
    return (
      <ResponsiveBoxWrapper width={width} aspectRatio={aspectRatio} {...props} ref={ref}>
        <ContentWrapper>{children}</ContentWrapper>
      </ResponsiveBoxWrapper>
    );
  },
);

ResponsiveBox.displayName = "ResponsiveBox";
