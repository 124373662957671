import { useLocation } from "react-router-dom";
// Components + styling
import { Button, Column, CompanyRateItem, Heading, Image, Text } from "components";
import { StyledImageAbsolute } from "../../styled";
import {
  StyledBoxWrapper,
  StyledCompanyRateItemWrapper,
  FlexibleBannerContentWrapper,
  StyledFlexibleBannerTitleWrapper,
  StyledFlexibleBannerButtonWrapper,
  StyledFlexibleBannerDescriptionWrapper,
} from "./styled";
// Context
import { useThemeContext, useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Utils
import { scrollToElementById } from "utils";
// Constants
import { ROUTES } from "navigation/routes";
import { BLOCK_ID } from "configs";
import { COMPANIES_RATE_LIST } from "components/testimonials/constants";
// Types
import { FlexibleBannerContentProps } from "./types";
import { IndustriesVariants } from "pages/industries/types";
import { ServicesVariants } from "pages/services/types";

const FlexibleBannerContent: React.FC<FlexibleBannerContentProps> = ({
  title,
  isWhite,
  subTitle,
  imageSrc,
  isColumn,
  bgPosition,
  imageRatio,
  description,
  imageMobileSrc,
  isShowRateItem,
  isImageAbsolute,
  isContentCenter,
  isFullContainer,
  maxWidthForButtonRem,
  minHeightStyledBoxRem,
  isImageFullContainerMobile,
  buttonLabel = "Get a free estimation today",
  buttonVariant,
  buttonBgColor,
  buttonTheme,
  gradient,
  widthForBannerContentProps,
  titleWidth,
}) => {
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const { pathname } = useLocation();

  const { isDesktop, isMobile } = useMatchBreakpoints();

  const isOtherPage = pathname.startsWith(`/${ROUTES.industries}/${IndustriesVariants.other}`);
  const isLogisticsPage = pathname.startsWith(`/${ROUTES.industries}/${IndustriesVariants.logistics}`);
  const isAiMobileDevPage = pathname.startsWith(`/${ROUTES.services}/${ServicesVariants.aiMobileDevelopment}`);
  const isAiMlPage = pathname.startsWith(`/${ROUTES.services}/${ServicesVariants.aiMlSolutions}`);
  const isBlockchainPage = pathname.startsWith(`/${ROUTES.services}/${ServicesVariants.blockchain}`);
  const isCloudSolutionsPage = pathname.startsWith(`/${ROUTES.services}/${ServicesVariants.cloudSolutions}`);

  const staticBlockWidth =
    isOtherPage || isLogisticsPage || isAiMobileDevPage || isAiMlPage || isBlockchainPage || isCloudSolutionsPage;

  return (
    <StyledBoxWrapper
      bgPosition={bgPosition}
      isWhite={isWhite}
      imageMobileSrc={imageMobileSrc}
      isShowRateItem={isShowRateItem}
      isFullContainer={isFullContainer}
      imageSrc={isMobile ? imageMobileSrc ?? imageSrc : imageSrc}
      gradient={gradient}
      minHeightStyledBoxRem={minHeightStyledBoxRem}
    >
      <Column
        pb={{ _: "1.75rem", laptop: imageSrc ? "9rem" : "0" }}
        pt={{ _: "1rem", tablet: "4rem", laptop: "5.25rem" }}
        px={{ _: "1rem", tablet: "2rem", laptopL: "4.5rem" }}
        mx="auto"
        maxWidth={theme.siteWidth}
        justifyContent="center"
        minHeight="100%"
      >
        <FlexibleBannerContentWrapper
          flexDirection={{ tablet: isColumn ? "column" : "row" }}
          justifyContent={{ tablet: "space-between" }}
          alignItems={isContentCenter ? "center" : "normal"}
          minHeight="100%"
          position="relative"
          zIndex="2"
          staticBlockWidth={staticBlockWidth}
        >
          <Column
            flexDirection={{ _: "column", tablet: isColumn ? "row" : "column" }}
            justifyContent={"space-between"}
            width={
              widthForBannerContentProps?.width
                ? widthForBannerContentProps.width
                : { tablet: isColumn ? "100%" : "60%", laptop: isColumn ? "100%" : "55%" }
            }
            mb={{ _: "1.5rem", tablet: isColumn ? "1.5rem" : "0", laptopL: isColumn ? "0" : "12.5rem" }}
          >
            <StyledFlexibleBannerTitleWrapper isColumn={isColumn} titleWidth={titleWidth}>
              <Heading scale="h1" as="h1" mb="1rem" color={isWhite ? "bgDark" : "white"}>
                {t(title)}
              </Heading>

              {subTitle && (
                <Heading scale="h5" as="h2" mb="1rem" color={isWhite ? "bgDark" : "white"}>
                  {t(subTitle)}
                </Heading>
              )}

              {isColumn && isDesktop && (
                <StyledFlexibleBannerButtonWrapper maxWidthForButtonRem={maxWidthForButtonRem}>
                  <Button
                    width="100%"
                    height={{ _: "3.125rem", laptop: "3.75rem" }}
                    scale={isDesktop ? "lg" : "md"}
                    onClick={() => scrollToElementById(BLOCK_ID.contactUs)}
                    hoverLinesPosition={isMobile ? "mobileTopLeft" : "topRight"}
                    variant={buttonVariant}
                    buttonTheme={buttonTheme}
                    bgColor={buttonBgColor ? buttonBgColor : gradient ? "white" : undefined}
                  >
                    {t(buttonLabel)}
                  </Button>
                </StyledFlexibleBannerButtonWrapper>
              )}
            </StyledFlexibleBannerTitleWrapper>
            <StyledFlexibleBannerDescriptionWrapper isColumn={isColumn}>
              <Text
                textScale={isDesktop ? "p22Regular" : "p16Regular"}
                mb={{ _: "1.5rem", laptop: "3rem" }}
                color={isWhite ? "textFifth" : "textSecond"}
                whiteSpace="pre-line"
              >
                {t(description)}
              </Text>

              {!isColumn || !isDesktop ? (
                <StyledFlexibleBannerButtonWrapper maxWidthForButtonRem={maxWidthForButtonRem}>
                  <Button
                    width="100%"
                    height={{ _: "3.125rem", laptop: "3.75rem" }}
                    scale={isDesktop ? "lg" : "md"}
                    onClick={() => scrollToElementById(BLOCK_ID.contactUs)}
                    hoverLinesPosition={isMobile ? "mobileTopLeft" : "topRight"}
                    variant={buttonVariant}
                    buttonTheme={buttonTheme}
                    bgColor={buttonBgColor ? buttonBgColor : gradient ? "white" : undefined}
                  >
                    {t(buttonLabel)}
                  </Button>
                </StyledFlexibleBannerButtonWrapper>
              ) : null}

              {isShowRateItem && (
                <StyledCompanyRateItemWrapper>
                  {COMPANIES_RATE_LIST.map(({ rate, LogoIcon, MobileLogoIcon, link }, index) => (
                    <CompanyRateItem
                      key={index}
                      rate={rate}
                      LogoIcon={LogoIcon}
                      MobileLogoIcon={MobileLogoIcon}
                      isWhite={isWhite}
                      link={link}
                    />
                  ))}
                </StyledCompanyRateItemWrapper>
              )}
            </StyledFlexibleBannerDescriptionWrapper>
          </Column>
          {!isImageAbsolute && !isFullContainer && imageSrc ? (
            <Image
              width={isMobile ? (isImageFullContainerMobile ? "110%" : "100%") : isColumn && !isMobile ? "100%" : "50%"}
              mx={isImageFullContainerMobile ? "-1rem" : "0"}
              src={imageSrc}
              aspectRatio={imageRatio}
            />
          ) : null}
        </FlexibleBannerContentWrapper>
      </Column>
      {isImageAbsolute && imageSrc && <StyledImageAbsolute width="100%" src={imageSrc} aspectRatio={imageRatio} />}
    </StyledBoxWrapper>
  );
};

export default FlexibleBannerContent;
