export const useHideOverflow = ({ trigger }: { trigger: boolean }) => {
  React.useEffect(() => {
    if (trigger) {
      document.body.style.setProperty("overflow-y", "hidden", "important");
    } else {
      document.body.style.setProperty("overflow-y", "auto", "important");
    }

    return () => {
      document.body.style.setProperty("overflow-y", "auto", "important");
    };
  }, [trigger]);
};
