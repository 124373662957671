import {
  spinAnimation,
  titleStraightLinesAnimation,
  inputLabelUnderlineAnimation,
  defaultButtonHoverLinesAnimation,
  bannerButtonLeftLinesAnimation,
  bannerButtonRightLinesAnimation,
  curlyArrowAnimation,
  landingBannerStarsSmAnimation,
  landingBannerStarsAnimation,
  ellipseWrapperAnimation,
  chooseProjectArrowAnimation,
  circleProgressAnimation,
} from "./animations";

export const animationTypeMapper = {
  spin: spinAnimation,
  defaultButtonHoverLines: defaultButtonHoverLinesAnimation,
  bannerButtonLeftLines: bannerButtonLeftLinesAnimation,
  bannerButtonRightLines: bannerButtonRightLinesAnimation,
  inputLabelUnderline: inputLabelUnderlineAnimation,
  titleStraightLines: titleStraightLinesAnimation,
  curlyArrow: curlyArrowAnimation,
  landingBannerStarsSm: landingBannerStarsSmAnimation,
  landingBannerStars: landingBannerStarsAnimation,
  ellipseWrapper: ellipseWrapperAnimation,
  chooseProjectArrow: chooseProjectArrowAnimation,
};

export const animationWithDurationTypeMapper = {
  circleProgress: circleProgressAnimation,
};
