// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { EXTERNAL_LINKS } from "configs";
import { ROUTES } from "navigation/routes";
// Types
import { ServicesVariants } from "pages/services/types";
import { BenefitsItemProps } from "pages/services/components/benefits/components/benefits-item/types";
import { WhoWeServeItemType } from "../components/who-we-serve/types";
import { IndustryPageContent } from "../types";
import { ServicesListItemType } from "../components/our-services-fintech/types";
import { AccordionContentItemType } from "../components/our-capabilities/types";

export const FINTECH_INDUSTRY_TOP_SERVICES_LIST: ServicesListItemType[] = [
  {
    title: "Legacy Software Transformation",
    description: "We ensure your financial software solutions are up-to-date and operating at maximum capacity.",
    bgImageSrc: "/images/industries/fintech/our-services/our-services-fintech-legacy-software-transformation.webp",
    gradient: "buttonAccent",
    width: "40%",
    isBlackText: true,
    backgroundSize: "100%",
    backgroundSizeMobile: "100% 12.5rem",
  },
  {
    title: "Custom Software Development",
    description:
      "We learn your business needs and deliver tailored fintech solutions to ensure your business’ sustainability and growth.",
    imageSrc: "/images/industries/fintech/our-services/our-services-fintech-custom-software-development.webp",
    imageSrcMobile:
      "/images/industries/fintech/our-services/mobile-image/our-services-fintech-custom-software-development-mobile.webp",
    link: `/${ROUTES.services}/${ServicesVariants.webDevelopment}`,
    aspectRatio: 1.69,
    mobileAspectRatio: 1.02,
    width: "60%",
  },
];

export const FINTECH_INDUSTRY_BOTTOM_SERVICES_LIST: ServicesListItemType[] = [
  {
    title: "Maintenance & support",
    description: "Our credo is to forecast and prevent any obstacle.",
    imageSrc: "/images/industries/fintech/our-services/our-services-fintech-maintence-and-support.webp",
    imageSrcMobile: "/images/industries/fintech/our-services/our-services-fintech-maintence-and-support.webp",
    link: `/${ROUTES.services}/${ServicesVariants.maintenanceSupport}`,
    aspectRatio: 0.82,
    width: "calc(33% - 0.75rem)",
  },
  {
    title: "Devops Implementation",
    description: "We focus on continuous delivery with high software quality for you to compete more effectively.",
    imageSrc: "/images/industries/fintech/our-services/our-services-fintech-devops-implementaion.webp",
    imageSrcMobile: "/images/industries/fintech/our-services/our-services-fintech-devops-implementaion.webp",
    aspectRatio: 0.82,
    width: "calc(33% - 0.75rem)",
  },
  {
    title: "Mobile Development",
    description: "We provide accessible and agile mobile financial technology solutions.",
    imageSrc: "/images/industries/fintech/our-services/our-services-fintech-mobile-development.webp",
    imageSrcMobile:
      "/images/industries/fintech/our-services/mobile-image/our-services-fintech-mobile-development-mobile.webp",
    link: `/${ROUTES.services}/${ServicesVariants.mobileDevelopment}`,
    height: "56.5625rem",
    isBlackText: true,
    aspectRatio: 0.58,
    mobileAspectRatio: 1.02,
    width: "calc(33% - 0.75rem)",
  },
  {
    title: "Cloud Transformation",
    description: "Ensure scalability and efficiency",
    imageSrc: "/images/industries/fintech/our-services/our-services-fintech-cloud-transformation.webp",
    imageSrcMobile: "/images/industries/fintech/our-services/our-services-fintech-cloud-transformation.webp",
    link: `/${ROUTES.services}/${ServicesVariants.cloudSolutions}`,
    aspectRatio: 0.82,
    width: "calc(33% - 0.75rem)",
  },
  {
    title: "Software Quality Assurance",
    description: "We detect bugs and ensure your financial services software satisfies all parties involved.",
    bgImageSrc: "/images/industries/fintech/our-services/our-services-fintech-software-quality-assurance.webp",
    gradient: "grayGradient",
    backgroundSize: "20.0625rem",
    backgroundSizeMobile: "12.5rem 9.375rem",
    width: "calc(33% - 0.75rem)",
  },
];

export const FINTECH_INDUSTRY_BENEFITS_LIST: BenefitsItemProps[] = [
  {
    title: "Blockchain software services",
    description: "Enhance security and increase transparency using blockchain for financial technology solutions.",
    linkForButton: `/${ROUTES.services}/${ServicesVariants.blockchain}`,
  },
  {
    title: "Artificial Intelligence for scoring",
    description: "Get financial innovation solutions and strategies for your business using our AI expertise.",
    linkForButton: `/${ROUTES.services}/${ServicesVariants.aiMlSolutions}`,
  },
  {
    title: "AI for customer support",
    description: "Bring your customer support to another level with AI assistance.",
    linkForButton: `/${ROUTES.services}/${ServicesVariants.aiMlSolutions}`,
  },
];

export const FINTECH_INDUSTRY_WHO_WE_SERVE_LIST: WhoWeServeItemType[] = [
  { text: "Retail & Commercial banks" },
  { text: "Payment provider" },
  { text: "Technology provider" },
  { text: "Crypto exchange solutions" },
  { text: "Neo banks" },
];

export const FINTECH_INDUSTRY_ACCORDION_CONTENT_LIST: AccordionContentItemType[] = [
  {
    title: "Payment Processing",
    description:
      "We create reliable and secure online payment gateways that allow your business to accept payments instantly. Our solutions integrate with various payment systems, providing a wide range of traditional, like Stripe, PayPal, MangoPay, etc., and on/off ramp payment options such as on-ramper, ramp, moonpay, and alchemy pay. We also provide real-time analytics to track payments and ensure PCI DSS compliance.",
  },
  {
    title: "KYC and AML Services",
    description:
      "Our Know Your Customer (KYC) and Anti-Money Laundering (AML) tools provide robust customer and transaction verification to avoid sensitive data leaks and alert potential threats of fraudulent activity. We assist your company in complying with up-to-date regulatory requirements and help to mitigate any risk of legal problems, ensuring the security and transparency of financial operations.",
  },
  {
    title: "Digital Banking",
    description:
      "Our digital banking solutions help banks and financial institutions improve customer service and streamline operations. We offer mobile and web applications with full integration of banking services, focusing on security and regulatory compliance, providing customers with easy and transparent access to their accounts and transactions, and guaranteeing the safety of their financial data.",
  },
  {
    title: "Trading Platforms",
    description:
      "We develop robust trading platforms for various markets and assets, accessible to experienced traders and beginners as well. Our solutions include market analysis tools that allow your customers to make informed decisions, portfolio management with strategic adjustment options, and rapid order execution to capitalize on market opportunities in real time. We support your journey to successful trading.",
  },
  {
    title: "Asset & Wealth Management",
    description:
      "Our asset and wealth management tools help your clients shape and achieve their long-term financial goals, whether it's about capital growth, income generation, or risk mitigation. We provide solutions for creating long-term investment strategies, asset tracking, and portfolio monitoring, using a tailored approach that evolves with changing market dynamics.",
  },
  {
    title: "Data and Analytics",
    description:
      "We offer our suite of tools for risk management and enhancing financial operations security. Our solutions help to identify potential threats, using cutting-edge analytics to detect patterns and emerging risks in real time and ensure data protection that sets industry standards. We use a multi-layered approach that prevents unauthorized access and guarantees the integrity of sensitive financial data.",
  },
  {
    title: "Risk Management and Security",
    description:
      "Our innovative lending and financing solutions help to simplify, accelerate, and optimize the loan approval process and attract investments. We assist your company in expanding financial opportunities and acquiring new clients by projecting a seamless infrastructure with transparent investment options that connect all the parties involved.",
  },
  {
    title: "Lending and Financing",
    description:
      "We provide a suite of tools for data collection, whether it's about customer interactions, market trends, or operational metrics; for analysis that presents raw data as insights with clear call-to-actions; and for utilization with automated processes to streamline your operations and drive sustainable growth.",
  },
];

const FINTECH_CONTENT: IndustryPageContent = {
  banner: {
    title: "Financial software development",
    description:
      "Transform your financial services with an advanced strategy by Idealogic. We focus on crafting and delivering tailored FinTech software solutions to drive innovation and enhance your business efficiency.",
    imageSrc: "/images/industries/fintech/fintech-banner-bg.webp",
    imageRatio: 1.24,
  },
  whoWeServe: {
    title: "Who We Serve",
    description:
      "Idealogic financial services include business efficiency software of various complexity for different industries. Get your custom financial solutions with us.",
    whoWeServeListLayoutProps: {
      maxHeight: { _: "100%", tablet: "12.1875rem" },
    },
    whoWeServeList: FINTECH_INDUSTRY_WHO_WE_SERVE_LIST,
  },
  ourCapabilities: {
    title: "Our Capabilities",
    description:
      "Our team works on advanced financial strategies and delivers financial services software to match your business objectives.",
    accordionContent: FINTECH_INDUSTRY_ACCORDION_CONTENT_LIST,
  },
  bookACallWithExpert: {
    bgSrc: "/images/industries/fintech/book-a-call-with-expert/Bg-light.webp",
    bgMobileSrc: "/images/industries/fintech/book-a-call-with-expert/Bg-light-mobile.webp",
    isReverse: false,
    title: {
      beforeKeyWord: "Our dedicated",
      keyWord: "Fintech",
      afterKeyWord: "expert",
    },
    description: "Our expert will be happy to discuss the details  of your project",
    linkedin: {
      name: "Alex Saiko",
      href: EXTERNAL_LINKS.linkedInAlexander,
    },
    jobTitle: "Co-founder and visionary at Idealogic",
    buttonText: "Book a call",
    imageSrc: "/images/industries/fintech/book-a-call-with-expert/book-a-call-with-expert-alex-bg.webp",
    mobileImageSrc: "/images/industries/fintech/book-a-call-with-expert/book-a-call-with-expert-alex-bg.webp",
    imageAspectRatio: 1.08,
  },
  ourServicesFintech: {
    title: "Our Services for Financial sector",
    description:
      "Our team crafts solutions that power up your business and simultaneously are flexible and scalable enough to meet future needs.",
    topServicesList: FINTECH_INDUSTRY_TOP_SERVICES_LIST,
    bottomServicesList: FINTECH_INDUSTRY_BOTTOM_SERVICES_LIST,
  },
  benefits: {
    title: "New Technologies in fintech",
    description: "We follow the latest industry trends and implement them in our approach for maximum efficiency.",
    benefitsList: FINTECH_INDUSTRY_BENEFITS_LIST,
    isBackgroundImageNeed: false,
    isNeedCurveLine: false,
    buttonText: "Let`s talk!",
    isButtonPositionStart: true,
  },
  portfolio: {
    bigPortfolioItem: {
      bgImage: "/images/industries/fintech/portfolio/fintech-portfolio-acm-bg.webp",
      bgImageMobile: "/images/industries/fintech/portfolio/fintech-portfolio-acm-bg-mobile.webp",
      aspectRatio: 1.99,
      aspectRatioMobile: 0.79,
      logo: "/images/industries/fintech/portfolio/acm-logo.svg",
      logoWidth: "14.4375rem",
      description:
        "ACM Exchange is a centralized exchange where investing is prioritized over trading, designed to be a place of digital assets research and profit.",
      itemLabel: {
        title: "All Crypto Mechanics",
        isComingSoon: true,
        subtitle: "Cryptocurrency platform",
      },
      owner: {
        image: "/images/industries/fintech/portfolio/acm-owner-logo.svg",
        name: "Alexander Maslo",
        jobTitle: "CTO at ACM",
        link: EXTERNAL_LINKS.linkedInAlexMaslo,
      },
    },
    portfolioList: [CaseStudiesSlugEnums.kansoWallet, CaseStudiesSlugEnums.zert],
    title: "Fintech case studies",
    TitleIcon: WavingHandIcon,
    description:
      "Explore real-world examples of how our fintech solutions have transformed businesses, from mobile apps to blockchain-based payments.",
  },
  ourCustomerReview: {
    title: "Our customer review",
    description: "We've worked with startups and enterprises – look what they have to say about us!",
    bgImageSrc: "/images/industries/fintech/our-customer-review/our-customer-review-bg.webp",
    topRightImageSrc: "/images/industries/fintech/our-customer-review/our-customer-review-top-right-image.webp",
    bgGradient: "customerReviewAlexGradient",
    linkedInLink: EXTERNAL_LINKS.linkedInAlexMaslo,
    isTextClamp: false,
    owner: {
      name: "Alexander Maslo",
      logo: "/images/industries/fintech/portfolio/acm-logo.svg",
      logoSize: "11rem",
      imageAspectRatio: 0.92,
      logoAspectRatio: 2.85,
      jobTitle: "CTO at ACM",
      image: "/images/industries/fintech/our-customer-review/our-customer-review-alex-maslo.webp",
      reviewText:
        "“Idealogic delivered on-time updates on the app's progress weekly and provided prompt solutions for new requirements. The team showed impressive commitment to the project's success and was helpful in aspects beyond technical. They were deeply interested in the product from the beginning.”",
    },
  },
  relatedArticlesFilterID: "cll6k3phwgm9m0bw36necx1rz",
};
export default FINTECH_CONTENT;
