// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { EXTERNAL_LINKS } from "configs";
import { ROUTES } from "navigation/routes";
// Types
import { IndustriesVariants, IndustryPageContent } from "../../industries/types";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { BenefitsItemProps } from "pages/services/components/benefits/components/benefits-item/types";
import { TickerLinkProps } from "components/ticker/components/ticker-link/types";

export const AI_SOFTWARE_DEVELOPMENT_BENEFITS_LIST: BenefitsItemProps[] = [
  {
    title: "Custom AI Application Development",
    iconSrc: "/images/expertise/ai-software-development/benefits/custom-ai-application-development-icon.svg",
    description: "We build tailored AI applications that solve your specific business challenges.",
  },
  {
    title: "AI-powered Customer Support",
    iconSrc: "/images/expertise/ai-software-development/benefits/ai-powered-customer-support-icon.svg",
    description: "With our chatbot, your team can handle customers’ inquiries effortlessly.",
  },
  {
    title: "Data Management",
    iconSrc: "/images/expertise/ai-software-development/benefits/data-managment-icon.svg",
    description: "Our AI models provide predictive insights and help you process large amounts of data efficiently.",
  },
  {
    title: "Integration of AI into Existing Software",
    iconSrc: "/images/expertise/ai-software-development/benefits/integration-of-ai-into-existing-software-icon.svg",
    description:
      "Integrate AI functionalities into your existing software infrastructure to enhance your workflow and operate at full capacity.",
  },
  {
    title: "AI-powered Chatbots and Virtual Assistants",
    iconSrc: "/images/expertise/ai-software-development/benefits/ai-powered-chatbots-and-virtual-assistants-icon.svg",
    description:
      "Improve customer engagement and streamline support with intelligent chatbots that understand natural language.",
  },
  {
    title: "Machine Learning Algorithms for Data Analysis and Prediction",
    iconSrc: "/images/expertise/ai-software-development/benefits/machine-learning-algorithns-icon.svg",
    description:
      "Machine learning solutions enhance your data processing capabilities, providing valuable insights to make informed decisions.",
  },
];

export const AI_SOFTWARE_DEVELOPMENT_COVERED_INDUSTRIES_LIST: TickerLinkProps[] = [
  { label: "Finance", link: `/${ROUTES.industries}/${IndustriesVariants.fintech}` },
  { label: "Aviation", link: `/${ROUTES.industries}/${IndustriesVariants.aviation}` },
  { label: "Real estate", link: `/${ROUTES.industries}/${IndustriesVariants.realEstate}` },
  { label: "Media", link: `/${ROUTES.industries}/${IndustriesVariants.media}` },
  { label: "Logistics", link: `/${ROUTES.industries}/${IndustriesVariants.logistics}` },
  { label: "Other", link: `/${ROUTES.industries}/${IndustriesVariants.other}` },
];

export const AI_SOFTWARE_DEVELOPMENT_BENEFITS_LIST_ADDITIONAL: BenefitsItemProps[] = [
  {
    title: "Discover & Estimate",
    description:
      "We start by closely learning your business requirements, defining what technical solutions suit you best, and providing you with a detailed plan of work scope.",
  },
  {
    title: "Design and Prototype",
    description:
      "We present you with a vision of your future product with basic features so that you can make adjustments and define the course of future development.",
  },
  {
    title: "Development",
    description:
      "Our team of relevant specialists works on the technical implementation of the project, using cutting-edge technologies.",
  },
  {
    title: "Testing",
    description:
      "We do automation and manual testing to ensure every feature works properly and the product can perform to its full potential.",
  },
  {
    title: "Deployment",
    description:
      "We are ready to release the product only after we are satisfied with its usability, security compliance, and reliability.",
  },
  {
    title: "Maintenance",
    description:
      "Our team is keeping up with the product’s performance after the launch to forecast and prevent any technical issues and urgently react if something goes wrong.",
  },
];

const AI_SOFTWARE_DEVELOPMENT_CONTENT: IndustryPageContent = {
  banner: {
    title: "AI-Powered Custom Software Solutions for Your Business",
    description:
      "We design, develop, and deploy high-impact AI applications to transform your operations and achieve desired results.",
    imageSrc: "/images/expertise/ai-software-development/ai-software-development-banner.webp",
    imageRatio: 1.75,
    widthForBannerContentProps: { width: { _: "100%", tablet: "60%", laptop: "65%" } },
    buttonLabel: "Get a free estimation today",
    maxWidthForButtonRem: 17.8125,
  },
  titleWithBackground: {
    title: "What is AI Software Development?",
    description:
      "We build digital solutions and software applications that leverage the power of machine learning and intelligent algorithms. AI software can automate tasks, analyze vast amounts of data, and learn and adapt over time, bringing significant advantages to businesses across industries.",
  },
  benefits: {
    title: "How we can help",
    description:
      "We embrace the power of AI in transforming the digital landscape and use it for various businesses across different industries.",
    benefitsList: AI_SOFTWARE_DEVELOPMENT_BENEFITS_LIST,
    isBackgroundImageNeed: false,
    isNeedCurveLine: false,
    buttonText: "Schedule a call",
    isButtonPositionStart: true,
  },
  bookACallWithExpert: {
    bgSrc: "/images/expertise/blockchain/book-call-with-a-expert/bg.webp",
    bgMobileSrc: "/images/expertise/blockchain/book-call-with-a-expert/bg.webp",
    title: {
      beforeKeyWord: "",
      keyWord: "Our expert",
      afterKeyWord: "will be happy to discuss the details of your project!",
    },
    description: "",
    linkedin: {
      name: "Artem Zaitsev",
      href: EXTERNAL_LINKS.linkedInArtem,
    },
    jobTitle: "VP of engineering",
    buttonText: "Book a call",
    imageSrc: "/images/expertise/blockchain/book-call-with-a-expert/book-a-call-with-expert-artem-bg.webp",
    mobileImageSrc: "/images/expertise/blockchain/book-call-with-a-expert/book-a-call-with-expert-artem-mobile-bg.webp",
    isReverse: true,
    imageAspectRatio: 1.24,
  },
  coveredIndustries: {
    coveredIndustriesList: AI_SOFTWARE_DEVELOPMENT_COVERED_INDUSTRIES_LIST,
  },
  stagesBlocks: [
    {
      title: "Increased Efficiency and Productivity",
      description: "Automate repetitive tasks and free your human workforce to focus on higher-level cognitive tasks.",
      iconSrc: "/images/expertise/ai-software-development/stages-block/increased-efficiency-icon.svg",
    },
    {
      title: "Improved Decision-Making",
      description:
        "Gain data-driven insights and predictive analytics to make informed business decisions that optimize outcomes.",
      iconSrc: "/images/expertise/ai-software-development/stages-block/improved-decision-icon.svg",
    },
    {
      title: "Enhanced Customer Experience",
      description: "Offer personalized experiences and 24/7 support with AI-powered chatbots and virtual assistants.",
      iconSrc: "/images/expertise/ai-software-development/stages-block/enhanced-customer-icon.svg",
    },
    {
      title: "Cost Reduction",
      description:
        "Automate processes, optimize resource allocation, and minimize human error to achieve significant cost savings.",
      iconSrc: "/images/expertise/ai-software-development/stages-block/cost-reduction-icon.svg",
    },
  ],
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.eplane, CaseStudiesSlugEnums.aiBook, CaseStudiesSlugEnums.mindHorizon],
    title: "AI/ML solutions case studies",
    TitleIcon: WavingHandIcon,
    description:
      "Our team is excited to adopt AI/ML solutions in our projects. Look at the completed works and wait till we present what’s coming soon.",
  },
  additionalBenefits: {
    title: "Our AI/ML solutions process",
    description: "We follow the latest industry trends and implement them in our approach for maximum efficiency.",
    benefitsList: AI_SOFTWARE_DEVELOPMENT_BENEFITS_LIST_ADDITIONAL,
    isBackgroundImageNeed: false,
    isNeedCurveLine: false,
    buttonText: "Let`s talk!",
    isShowCall: false,
  },
  ourCustomerReview: {
    title: "Our customer review",
    description: "We've worked with startups and enterprises – look what they have to say about us!",
    bgImageSrc: "/images/industries/aviation/our-customer-review/our-customer-review-bg.webp",
    linkedInLink: EXTERNAL_LINKS.linkedInAngel,
    bgGradient: "customerReviewAngelGradient",
    isTextClamp: true,
    owner: {
      name: "Angel Marinov",
      logo: "/images/industries/aviation/our-customer-review/eplane-ai-logo.svg",
      logoAspectRatio: 6.25,
      logoSize: "11.625rem",
      jobTitle: "Head of Innovation at ePlaneAI",
      image: "/images/industries/aviation/our-customer-review/our-customer-review-angel-marinov.webp",
      imageAspectRatio: 1,
      reviewText:
        "“Idealogic delivered on-time updates on the app's progress weekly and provided prompt solutions for new requirements. The team showed impressive commitment to the project's success and was helpful in aspects beyond technical. They were deeply interested in the product from the beginning.”",
    },
  },
};

export default AI_SOFTWARE_DEVELOPMENT_CONTENT;
