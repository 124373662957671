import { transitions } from "theme/base";

export const slideTestimonialLabelVariants = {
  hidden: {
    x: "calc(100% - 6.75rem)",
    transition: transitions.defaultMotion,
  },
  visible: {
    x: "0",
    transition: transitions.defaultMotion,
  },
};
