import styled from "styled-components";

export const StyledBigPortfolioItem = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 26.9375rem;
  overflow: hidden;
  border-radius: 1.25rem;

  #portfolioBigItemBg img {
    width: 100%;
    height: -webkit-fill-available;
    object-fit: cover;
    border-radius: 1.25rem;
    transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    max-height: 40.625rem;
    &:hover {
      #portfolioBigItemBg img {
        transform: scale(1.2);
      }
    }
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    height: 40.625rem;
  }
`;

export const StyledOwnerContainer = styled.div`
  display: flex;
  padding: 1.375rem 0.5rem;
  background: ${({ theme }) => theme.gradients.bigPortfolioItemGradient};
  border-radius: 1.25rem;
  ${({ theme }) => theme.mediaQueries.mobileL} {
    padding: 1.375rem;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    background: transparent;
    padding: 0;
  }
`;

export const StyledBigPortfolioItemInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  z-index: 3;
  padding: 1.5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 4.625rem;
    justify-content: center;
  }
`;

export const StyledBigPortfolioBox = styled.div`
  padding: 0 0.75rem;
`;
