import { AnimatedCharacterText, Box, Text } from "components";
import { StyledTeamMemberItem, StyledTeamMemberImage } from "./styled";
// Context
import { useTranslation } from "context";
import { useMatchBreakpoints } from "hooks";
import { useTeamMemberAnimation } from "components/our-team/hooks";

import { BLOCK_ID } from "configs";

import { OurTeamItemType } from "components/our-team/types";

const TeamMemberItem: React.FC<OurTeamItemType> = ({ image, name, position, description, isTitleWhite }) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const { isActive, controls, handleShowAnimations, handleHideAnimations } = useTeamMemberAnimation();

  return (
    <StyledTeamMemberItem
      onMouseEnter={() => {
        handleShowAnimations();
      }}
      onMouseLeave={() => {
        handleHideAnimations();
      }}
    >
      <Box mb={{ _: "0.5rem", laptop: "1.5rem" }} id={BLOCK_ID.idMemberContent}>
        <StyledTeamMemberImage width="100%" src={image} aspectRatio={0.832} />
      </Box>

      <div>
        <Text
          textScale={isDesktop ? "p24Bold" : "p16Bold"}
          color={isTitleWhite ? "white" : "textMain"}
          mb={{ _: "0.25rem", laptop: "0.5rem" }}
        >
          {t(name)}
        </Text>
        <Text
          textScale={isDesktop ? "p20Regular" : "p14Regular"}
          mb={{ _: "0.25rem", laptop: "0.5rem" }}
          color="textFifth"
        >
          {t(position)}
        </Text>
        <Text textScale={isDesktop ? "decorative22Light" : "decorative14Light"} color="textFifth">
          {isDesktop ? (
            <AnimatedCharacterText text={t(description)} initial={isActive ? "visible" : "hidden"} animate={controls} />
          ) : (
            <>{t(description)}</>
          )}
        </Text>
      </div>
    </StyledTeamMemberItem>
  );
};

export default TeamMemberItem;
