// Components + styling
import { CompanyRateItem, Container, Flex, Heading, Image, Text } from "components";
import { TestimonialsList } from "./components";
import { StyledClientLogoWrapper, StyledClientsList, StyledTestimonialsWrapper } from "./styled";
// Context
import { useTranslation, VideoPlayerProvider } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { COMPANIES_RATE_LIST, CLIENTS_LOGO_LIST } from "./constants";

const Testimonials: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useMatchBreakpoints();

  return (
    <VideoPlayerProvider>
      <StyledTestimonialsWrapper>
        <Container
          pt={{ _: "3rem", laptop: "5rem" }}
          pb={{ _: "3rem", laptop: "3.75rem" }}
          px={{ _: "1rem", tablet: "1rem", laptopL: "4.5rem" }}
        >
          <Heading
            scale="h2"
            as="h3"
            maxWidth={{ _: "100%", tablet: "50%" }}
            mb={{ _: "0.25rem", laptop: "1rem" }}
            color="white"
          >
            {t("Over 1,000 Happy Clients")}
          </Heading>
          <Text
            textScale={isDesktop ? "p22Regular" : "p16Regular"}
            maxWidth={{ _: "100%", tablet: "50%" }}
            mb={{ _: "1.5rem", laptop: "3rem" }}
            color="textThird"
          >
            {t("Explore reviews from startups and enterprises ")}
            <br />
            {t("alike to see why our clients trust us.")}
          </Text>
          <Flex justifyContent={isMobile ? "space-between" : "flex-start"} mb={{ _: "1.5rem", tablet: "3.75rem" }}>
            {COMPANIES_RATE_LIST.map(({ rate, LogoIcon, MobileLogoIcon, link }, index) => (
              <CompanyRateItem
                key={index}
                rate={rate}
                LogoIcon={LogoIcon}
                MobileLogoIcon={MobileLogoIcon}
                link={link}
              />
            ))}
          </Flex>

          <TestimonialsList />

          <StyledClientsList>
            {CLIENTS_LOGO_LIST.map(({ iconSrc, ratio }) => (
              <StyledClientLogoWrapper key={iconSrc}>
                <Image src={iconSrc} aspectRatio={ratio} width="10.125rem" height="2.125rem" mt="1.375rem" />
              </StyledClientLogoWrapper>
            ))}
          </StyledClientsList>
        </Container>
      </StyledTestimonialsWrapper>
    </VideoPlayerProvider>
  );
};

export default Testimonials;
