import { useTranslation } from "context";
import { useMatchBreakpoints } from "hooks";
// Components + styling
import { DropdownMenuItems } from "../dropdown-menu-items";
import { StyledDefaultDropdownList, StyledDefaultDropdownWrapper } from "components/header/styled";
import { IndustriesCrownIcon } from "components/svg";
import { Text } from "components";
import { StyledDropdownTitleContainer } from "./styled";
// Constants
import { INDUSTRIES_DROPDOWN_LIST } from "components/header/constants/industries-dropdown-list";
// Types
import { DropdownComponentProps } from "components/dropdown/types";

export const IndustriesDropdown: React.FC<DropdownComponentProps> = ({ onDropdownMenuClick }) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <StyledDefaultDropdownWrapper>
      {isDesktop && (
        <StyledDropdownTitleContainer>
          <IndustriesCrownIcon width="1.8125rem" height="1.75rem" />

          <Text textScale="p20Bold" color="white">
            {t("Our industries")}
          </Text>
        </StyledDropdownTitleContainer>
      )}

      <StyledDefaultDropdownList>
        <DropdownMenuItems navSections={INDUSTRIES_DROPDOWN_LIST} onDropdownMenuClick={onDropdownMenuClick} />
      </StyledDefaultDropdownList>
    </StyledDefaultDropdownWrapper>
  );
};
