// Components + styling
import { Box, Container, Heading, SliderNavButtons, Text } from "components";
import { OurTeamSlider } from "./components";
import { StyledSliderTitle } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints, useSliderController } from "hooks";
// Constants
import { DEFAULT_OUR_TEAM_LIST } from "./constants";
// Types
import { OurTeamProps } from "./types";

const OurTeam: React.FC<OurTeamProps> = ({
  title = "Our team",
  isTitleWhite = false,
  description = "We take your project and create something amazing. Meet our team ready to turn your idea into reality.",
  teamMembersList = DEFAULT_OUR_TEAM_LIST,
  ...props
}) => {
  const { t } = useTranslation();
  const { isMobile, isTablet, isDesktop } = useMatchBreakpoints();

  const { sliderRef, handleClickPrevious, handleClickNext } = useSliderController();

  return (
    <Box px="1rem" pt={{ _: "2rem", laptop: "3.125rem" }} pb={{ _: "1.25rem", laptop: "3.75rem" }} {...props}>
      <StyledSliderTitle>
        <Box maxWidth="34.4rem">
          <Heading color={isTitleWhite ? "white" : "textMain"} scale="h2" as="h2" mb={{ _: "0.25rem", laptop: "1rem" }}>
            {t(title)}
          </Heading>
          <Text textScale={isDesktop ? "p22Regular" : "p16Regular"} color="textFifth">
            {t(description)}
          </Text>
        </Box>
        {isTablet && (
          <SliderNavButtons
            onClickLeftBtn={handleClickPrevious}
            onClickRightBtn={handleClickNext}
            bgColor="textMain"
            iconColor="textMain"
          />
        )}
      </StyledSliderTitle>

      <OurTeamSlider ref={sliderRef} isTitleWhite={isTitleWhite} teamMembersList={teamMembersList} />

      {isMobile && (
        <Container mt="1rem">
          <Box width="fit-content" mx="auto">
            <SliderNavButtons
              onClickLeftBtn={handleClickPrevious}
              onClickRightBtn={handleClickNext}
              bgColor="textMain"
              iconColor="textMain"
            />
          </Box>
        </Container>
      )}
    </Box>
  );
};

export default OurTeam;
