// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { ROUTES } from "navigation/routes";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { REAL_ESTATE_INDUSTRY_TABS_LIST } from "../components/industry-info/constants";
// Types
import { IndustriesVariants, IndustryPageContent } from "../types";

const REAL_ESTATE_CONTENT: IndustryPageContent = {
  banner: {
    title: "Real Estate software development",
    description:
      "Idealogic designs and builds software for the real estate industry, improving operational efficiency for property management, marketing, and transactions.",
    imageSrc: "/images/industries-banner-real-estate.webp",
    imageRatio: 1.96,
  },
  industryInfo: {
    title: "Real estate tech innovation",
    description:
      "We cater to the specific needs of the real estate industry, considering the business objectives and market fluctuations.",
    industryInfoTabsList: REAL_ESTATE_INDUSTRY_TABS_LIST,
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.eState, CaseStudiesSlugEnums.secondFloor],
    title: "Real Estate case studies",
    TitleIcon: WavingHandIcon,
    description:
      "View how technologies change the real estate industry and check our innovative solutions, such as mobile rental apps or tokenized platforms.",
  },
  coveredIndustries: {
    coveredIndustriesList: [
      { label: "Fintech", link: `/${ROUTES.industries}/${IndustriesVariants.fintech}` },
      { label: "Logistics", link: `/${ROUTES.industries}/${IndustriesVariants.logistics}` },
      { label: "Aviation", link: `/${ROUTES.industries}/${IndustriesVariants.aviation}` },
      { label: "Media", link: `/${ROUTES.industries}/${IndustriesVariants.media}` },
      { label: "Other industries", link: `/${ROUTES.industries}/${IndustriesVariants.other}` },
    ],
  },
};

export default REAL_ESTATE_CONTENT;
