import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import { HygraphImage } from "components";

import { StyledPortfolioItemLabelText } from "../portfolio-item-label/styled";

export const StyledPortfolioItemLogo = styled(HygraphImage)`
  position: absolute;
  top: 2rem;
  left: 1.5rem;
  z-index: ${({ theme }) => theme.zIndices.buttonContent};

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 2.5rem;
    left: 2.5rem;
  }

  img {
    height: inherit;
  }
`;

export const StyledPortfolioItemImageWrapper = styled(motion.div)`
  position: relative;
  display: flex;
`;

export const StyledPortfolioItemImage = styled(HygraphImage)`
  img {
    border-radius: ${({ theme }) => theme.radii.medium};
    transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};
  }
`;

export const StyledPortfolioTestimonialLabel = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};

  ${({ theme }) => theme.mediaQueries.tablet} {
    top: 2.5rem;
  }
`;

export const StyledPortfolioImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.radii.medium};
  transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};
`;

export const StyledPortfolioImageOuterWrapper = styled(motion.div)`
  transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};
`;

export const StyledPortfolioMagneticWrapper = styled.div`
  justify-content: center;
  align-items: center;
  transition: ${({ theme }) => theme.transitions.easeInOut_0_5s};

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 1rem;
  }

  &:hover {
    ${StyledPortfolioImageOuterWrapper} {
      transform: scale(1.05);
    }

    ${StyledPortfolioItemImage} {
      img {
        transform: scale(1.2);
      }
    }
  }
`;

export const StyledPortfolioComingSoonText = styled(StyledPortfolioItemLabelText)`
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.white};
  z-index: ${({ theme }) => theme.zIndices.header};

  text-align: center;
`;

export const StyledPortfolioOverlay = styled.div`
  position: absolute;
  top: -4%;
  left: -5%;
  width: 110%;
  height: 130%;
  background: ${({ theme }) => `${theme.colors.bgDark}A6`}; /* A6 used for 65% opacity in hex */

  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;

  z-index: ${({ theme }) => theme.zIndices.buttonContent};

  ${({ theme }) => theme.mediaQueries.tablet} {
    height: 105%;
  }
`;

export const StyledPortfolioMagneticMotion = styled(motion.div)`
  width: 100%;
`;

export const StyledPortfolioItemRelative = styled.div`
  position: relative;
  max-width: 45rem;
`;
