// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFourth", animationType = "defaultButtonHoverLines", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 32 39" {...props} animationType={animationType} color="transparent">
      <path
        d="M2.34393 18.3452C4.27403 13.3737 6.3365 8.48719 8.46817 3.60461"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9907 26.2977C14.8905 21.7357 19.475 17.9157 23.7852 13.7905"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3818 36.5516C21.0326 34.7727 26.0026 33.4607 30.9708 33.3696"
        stroke={theme.colors[color]}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
