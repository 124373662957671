export const baseColors = {
  transparent: "transparent",
  white: "#FFFFFF",
  ratingYellow: "#FACE1B",
  postRelatedLinkBg: "#F9FAFF",
  decorativeLogistic: "#E1E1E2",
  showMore: "#e5b800",
  linkedIn: "#2867B2",
  blueBlur: "#404BDA54",
  yellowBlur: "#A76710",
  dark: "#000000",
  ethereumBlue: "#5F7AE3",
  bnbYellow: "#FFC51C",
  blueWhiteBlur: "#799BE9",
  trendsAndPredictionBlur: "#1640B199",
  accentYellow: "#FFDC4C",
  yellowStopColor: "#FFAC4C",
  blueStopColor: "#4DB3FB",
  blueLinkColor: "#228AE9",
  redBlur: "#C51350",
} as const;

export const lightColors = {
  ...baseColors,

  bgDark: "#0D0D0D",
  bgDarkSecond: "#1C1C1C",
  bgSecond: "#F9FAFF",
  bgThird: "#E9EDF5",
  bgFourth: "#FCFCFC",
  bgFifth: "#FFF7EF",
  bgSixth: "#FFFFF9",

  textMain: "#333333",
  textSecond: "#E7E8E9",

  textThird: "#C7C9CD",

  textFourth: "#525766",
  textFourthForDarkBg: "#BFBFBF",
  navItemFourthForDarkBg: "#9098A7",

  textFifth: "#55575D",
  textFifthContrast: "#3C4650",

  textSixth: "#5F6676",

  textAccent: "#FFD21C",
  textAccentSecond: "#FF951C",
  textAccentThird: "#FFA31D",
  textLink: "#228AE9",

  buttonDarkFill: "#1D1D1D",
  buttonDarkHover: "#363636",
  buttonDarkActive: "#020202",

  disabledLight: "#BEC6DA",
  disabledFillDark: "#1D2230",
  disabledStrokeDark: "#232939",
  disabledTextDark: "#3D455C",
  disabledTextForDarkBg: "#8DA0B4",

  lineDark: "#333541",
  lineMain: "#D5D6D8", // in Idealogic.Design project it called lineLight: "#C7C9CD",
  lineSecond: "#E9EAEC",

  success: "#3AE474",
  error: "#F02C2C",
  warning: "#F97A52",

  findSolutionGreyBg: "#D9D9D9",
  caseStudiesListBg: "#E9EDF533",
  corporateValueBg: "#FFFBDB",
  corporateValueSmallBg: "#FFE5CD",
  corporateValueImageLight: "#D3DAFF",
  corporateValueImage: "#FFB067",
  corporateValueImageGold: "#FFD748",
  tabsBorderColor: "#d1d5db",

  linkedInLink: "#6C85A3",
  linkedInLinkHover: "#2867B2",

  gray: "#9C9C9C",
  // Put light colors here
} as const;

export const darkColors = {
  ...baseColors,

  bgDark: "#0D0D0D",
  bgDarkSecond: "#1C1C1C",
  bgSecond: "#F9FAFF",
  bgThird: "#E9EDF5",
  bgFourth: "#FCFCFC",
  bgFifth: "#FFF7EF",
  bgSixth: "#FFFFF9",

  textMain: "#333333",
  textSecond: "#E7E8E9",

  textThird: "#C7C9CD",

  textFourth: "#525766",
  textFourthForDarkBg: "#BFBFBF",
  navItemFourthForDarkBg: "#9098A7",

  textFifth: "#55575D",
  textFifthContrast: "#3C4650",

  textSixth: "#5F6676",

  textAccent: "#FFD21C",
  textAccentSecond: "#FF951C",
  textAccentThird: "#FFA31D",
  textLink: "#228AE9",

  buttonDarkFill: "#1D1D1D",
  buttonDarkHover: "#363636",
  buttonDarkActive: "#020202",

  disabledLight: "#BEC6DA",
  disabledFillDark: "#1D2230",
  disabledStrokeDark: "#232939",
  disabledTextDark: "#3D455C",
  disabledTextForDarkBg: "#8DA0B4",

  lineDark: "#333541",
  lineMain: "#D5D6D8", // in previous project it called lineLight: "#C7C9CD",
  lineSecond: "#E9EAEC",

  success: "#3AE474",
  error: "#F02C2C",
  warning: "#F97A52",

  findSolutionGreyBg: "#D9D9D9",
  caseStudiesListBg: "#E9EDF533",
  corporateValueBg: "#FFFBDB",
  corporateValueSmallBg: "#FFE5CD",
  corporateValueImageLight: "#D3DAFF",
  corporateValueImage: "#FFB067",
  corporateValueImageGold: "#FFD748",
  tabsBorderColor: "#d1d5db",

  linkedInLink: "#6C85A3",
  linkedInLinkHover: "#2867B2",

  gray: "#9C9C9C",
  // Put dark colors here
} as const;
