import styled from "styled-components";

export const StyledStaticFeedbackWrapper = styled.div`
  position: absolute;
  bottom: 2.625rem;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;

  width: 95%;
  background: ${({ theme }) => theme.gradients.bigPortfolioItemGradient};

  padding: 1.2rem 0.625rem;
  border-radius: ${({ theme }) => theme.radii.semiLarge};

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 0.875rem 0.75rem;
    width: max-content;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    background: none;
    left: 2.5rem;
    transform: none;
  }
`;

export const StyledInformationWrapper = styled.div`
  margin-left: 1.25rem;
  margin-right: 1.25rem;
`;

export const StyledInformation = styled.div`
  display: flex;
`;

export const StyledPositionWrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.tablet} {
    align-items: flex-start;
    flex-direction: column;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
`;

export const StyledLinkedInMobileWrapper = styled(StyledPositionWrapper)``;

export const StyledLinkedInWrapper = styled(StyledPositionWrapper)`
  ${({ theme }) => theme.mediaQueries.tablet} {
    flex-direction: row-reverse;
  }
  ${({ theme }) => theme.mediaQueries.laptop} {
    flex-direction: row;
  }
`;
