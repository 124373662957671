import React, { useContext, PropsWithChildren, useState } from "react";
import { useQuery } from "@apollo/client";

import {
  CaseStudiesFilterType,
  CaseStudyContextData,
  Filters,
  HandleFiltersChangeArgs,
  InitialFiltersType,
  SelectedState,
  allCategories,
  allIndustries,
  allTechnologies,
} from "./types";

import { GetCaseStudiesResponse, GetCaseStudiesVars, getCaseStudies, CaseStudyItem, Category } from "services";

const initialFilters: InitialFiltersType = {
  industry: [],
  category: [],
};

const initialSelectedAll: SelectedState = {
  industry: false,
  category: false,
};

export const CaseStudyContext = React.createContext<CaseStudyContextData | null>(null);

export const CaseStudyProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [page, setPage] = React.useState(1);
  const [caseStudies, setCaseStudies] = React.useState<CaseStudyItem[]>([]);

  const [selectedFilters, setSelectedFilters] = useState(initialFilters);

  const [selectedAll, setSelectedAll] = useState<SelectedState>(initialSelectedAll);

  const whereParameter = Object.entries(selectedFilters).reduce((acc, curr) => {
    if (curr[1].length > 0) {
      return { ...acc, [`${curr[0]}_some`]: { id_in: curr[1].map(item => item.id) } };
    } else {
      return acc;
    }
  }, {});

  const { data, loading } = useQuery<GetCaseStudiesResponse, GetCaseStudiesVars>(getCaseStudies, {
    variables: { where: whereParameter },
  });
  const { caseStudies: caseStudiesItems = [] } = data || {};

  React.useEffect(() => {
    if (loading) {
      return;
    }

    if (page !== 1) {
      setCaseStudies(prev => [...prev, ...caseStudiesItems]);
    } else {
      setCaseStudies(caseStudiesItems);
    }
  }, [caseStudiesItems]);

  const handleFilterChange = React.useCallback(
    ({ filters, type, selectedOption, isMobileFilter, selectedOptions }: HandleFiltersChangeArgs) => {
      setSelectedFilters(prevFilters => {
        if (isMobileFilter && typeof selectedOption !== "string") {
          const currentFilterOptions = selectedOptions ? selectedOptions[type] : [];
          if (currentFilterOptions.includes(selectedOption)) {
            return selectedOptions || initialFilters;
          }
        }

        if (filters) {
          if (
            selectedOption === allIndustries ||
            selectedOption === allTechnologies ||
            selectedOption === allCategories
          ) {
            const allTypeFilters = filters[type].options;
            const isAllFiltersSelected = selectedAll[type];
            setSelectedAll(prevSelectedAll => ({
              ...prevSelectedAll,
              [type]: !isAllFiltersSelected,
            }));
            return {
              ...prevFilters,
              [type]: isAllFiltersSelected ? [] : allTypeFilters,
            };
          } else {
            if (typeof selectedOption !== "string") {
              const isFilterSelected = prevFilters[type].includes(selectedOption as Category);
              const updatedFilters = isFilterSelected
                ? prevFilters[type].filter(option => option.id !== selectedOption.id)
                : [...prevFilters[type], selectedOption];
              setSelectedAll(prevSelectedAll => ({
                ...prevSelectedAll,
                [type]: updatedFilters.length === filters[type].options.length,
              }));

              return {
                ...prevFilters,
                [type]: updatedFilters,
              };
            } else {
              return prevFilters;
            }
          }
        } else {
          return prevFilters;
        }
      });
    },
    [selectedFilters, selectedAll],
  );

  const handleRemoveFilter = React.useCallback(
    (filters: Filters, type: CaseStudiesFilterType, option: Category) => {
      setSelectedFilters(prevFilters => {
        const updatedFilters = prevFilters[type].filter(filteredOption => filteredOption.id !== option.id);
        setSelectedAll(prevSelectedAll => ({
          ...prevSelectedAll,
          [type]: updatedFilters.length === filters[type].options.length,
        }));

        return {
          ...prevFilters,
          [type]: updatedFilters,
        };
      });
    },
    [selectedFilters],
  );

  const resetAllFilters = () => {
    setSelectedFilters(initialFilters);
    setSelectedAll({
      industry: false,
      category: false,
    });
  };

  const loadMoreItems = () => {
    setPage(page + 1);
  };

  return (
    <CaseStudyContext.Provider
      value={{
        caseStudies,
        selectedAll,
        selectedFilters,
        initialFilters,
        isLoading: loading,
        handleRemoveFilter,
        resetAllFilters,
        loadMoreItems,
        handleFilterChange,
      }}
    >
      {children}
    </CaseStudyContext.Provider>
  );
};

export const useCaseStudyContext = () => {
  const context = useContext(CaseStudyContext);

  if (!context) {
    throw new Error("Case study context is not found");
  }

  return context;
};
