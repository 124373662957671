import { StyledBox } from "components/banner-default/styled";
import { Column } from "components/layout";
import styled, { css } from "styled-components";

export const StyledBoxWrapper = styled(StyledBox)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => theme.mediaQueries.tablet} {
    min-height: ${({ minHeightStyledBoxRem }) => `${minHeightStyledBoxRem}rem`};
    justify-content: ${({ minHeightStyledBoxRem }) => (minHeightStyledBoxRem ? "flex-start" : "center")};
  }
`;

export const StyledFlexibleBannerTitleWrapper = styled.div<{ isColumn?: boolean; titleWidth?: string }>`
  max-width: 100%;

  ${({ theme }) => theme.mediaQueries.tablet} {
    max-width: ${({ isColumn, titleWidth }) => (isColumn ? (titleWidth ? titleWidth : "40%") : "100%")};
  }
`;

export const StyledFlexibleBannerButtonWrapper = styled.div<{ maxWidthForButtonRem?: number }>`
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    max-width: ${({ maxWidthForButtonRem }) => `${maxWidthForButtonRem}rem`};
    margin-left: 0;
    margin-right: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-bottom: 3rem;
  }
`;

export const StyledFlexibleBannerDescriptionWrapper = styled.div<{ isColumn?: boolean }>`
  max-width: 100%;

  ${({ theme }) => theme.mediaQueries.tablet} {
    max-width: ${({ isColumn }) => (isColumn ? "50%" : "100%")};
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    max-width: ${({ isColumn }) => (isColumn ? "38%" : "100%")};
  }
`;

export const StyledCompanyRateItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.mediaQueries.tablet} {
    justify-content: flex-start;
  }
`;

export const FlexibleBannerContentWrapper = styled(Column)<{ staticBlockWidth?: boolean }>`
  ${({ staticBlockWidth }) =>
    staticBlockWidth &&
    css`
      @media screen and (min-width: 1600px) {
        width: 96rem;
      }
    `}
`;
