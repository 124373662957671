// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "white", ...props }) => {
  return (
    <Svg viewBox="0 0 27 28" {...props} color={color}>
      <path d="M11.102 9.973s-1.509-.151-1.509 1.176v5.943h9.895v1.78s.03.875-.905.875h-8.99v7.723h10.8s6.547-.996 6.607-7.723V9.973H11.102ZM7.21.5C.15 2.16 0 7.951 0 7.951V27.5h7.482V9.701c0-1.508 1.508-1.78 1.508-1.78h17.678L26.698.5H7.21Z" />
    </Svg>
  );
};

export default Icon;
