// Components
import { ClutchLogoIcon,  GoodfirmsLogoSmIcon, TopDevelopersLogoIcon } from "components/svg";
// Constants
import { EXTERNAL_LINKS } from "configs";
// Types
import { CompanyRateItemProps } from "components/company-rate-item/types";
import { TestimonialItemProps } from "./components/testimonial-item/types";
import { ClientsLogoItem } from "./types";

export const COMPANIES_RATE_LIST: CompanyRateItemProps[] = [
  {
    rate: 4.9,
    LogoIcon: ClutchLogoIcon,
    link: EXTERNAL_LINKS.idealogicClutch,
  },
  {
    rate: 5,
    LogoIcon: GoodfirmsLogoSmIcon,
    MobileLogoIcon: GoodfirmsLogoSmIcon,
    link: EXTERNAL_LINKS.idealogicGoodfirms,
  },
];

export const TESTIMONIAL_ITEMS_PER_ROW_DEFAULT = 3;
export const TESTIMONIALS_LIST_GAP = 1.5; // rem

export const TESTIMONIALS_LIST: TestimonialItemProps[] = [
  {
    avatarSrc: "/images/testimonial/matthew-feedback.webp",
    gifDuration: 21,
    videoSources: [EXTERNAL_LINKS.youtubeMatthewReview],
    name: "Matthew Schneider",
    position: "CEO at eStates PropTech Inc",
    review:
      "Idealogic has done all of that and so much more - from their sales team to developers to c-suite, they have restored my faith in the software development industry and have helped my company so much.",
    rating: 5,
    RatingCompanyIcon: TopDevelopersLogoIcon,
  },
  {
    avatarSrc: "/images/testimonial/dan-jensen.webp",
    name: "Dan Jensen",
    position: "Founder at Revolve Games",
    review:
      "We have been working in partnership with Idealogic for a few months. They have so far been instrumental in turning our vision into a reality. Have been impressed with the quality of development and the team are great to work with.",
    rating: 5,
    RatingCompanyIcon: GoodfirmsLogoSmIcon,
  },

  {
    avatarSrc: "/images/testimonial/javier-sanchez-mesa.webp",
    name: "Javier Sánchez Mesa",
    position: "Co-Founder & CEO at BeHappy",
    review:
      "Our team at Idealogic is always very flexible and they take into account our recommendations. They delivered even beyond our expectations. If they keep this standards, I am sure we will keep using their services.",
    rating: 5,
    RatingCompanyIcon: GoodfirmsLogoSmIcon,
  },

  {
    avatarSrc: "/images/testimonial/philipp-tanglmayer.webp",
    name: "Philipp Tanglmayer",
    position: "CEO, THARSESIS AG",
    review:
      "Idealogic delivered on-time updates on the app's progress weekly and provided prompt solutions for new requirements. The team showed impressive commitment to the project's success and was helpful in aspects beyond technical. They were deeply interested in the product from the beginning.",
    rating: 5,
    RatingCompanyIcon: ClutchLogoIcon,
  },
  {
    avatarSrc: "/images/testimonial/jon-pierowicz.webp",
    name: "Jon Pierowicz",
    position: "Counsel at VeriTX",
    review:
      "The quality of the work was very high, and the Idealogic team kept our larger, strategic picture in mind the entire time. Excellent communication with the managers and coders. Delivered quality product on-time and within budget.",
    rating: 5,
    RatingCompanyIcon: GoodfirmsLogoSmIcon,
  },
];

export const CLIENTS_LOGO_LIST: ClientsLogoItem[] = [
  {
    iconSrc: "/images/sense-bank-logo.svg",
    ratio: 3.86,
  },
  {
    iconSrc: "/images/aibook-art-logo.svg",
    ratio: 3.24,
  },
  {
    iconSrc: "/images/seedbox-logo.svg",
    ratio: 3.48,
  },
  {
    iconSrc: "/images/revolve-logo.svg",
    ratio: 4.08,
  },
  {
    iconSrc: "/images/swissy-logo.svg",
    ratio: 4.04,
  },
  {
    iconSrc: "/images/brand-nudge-logo.svg",
    ratio: 2.04,
  },
];
