import styled, { css } from "styled-components";
// Components
import { Svg } from "components";
import { StyledFooterLink } from "components/footer/styled";

export const StyledFooterSocialWrapper = styled.div`
  max-width: auto;

  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;

  flex-direction: row;

  margin-top: 1rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    gap: 0.75rem;
    max-width: 11.875rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-top: 0;
  }
`;

export const StyledFooterSocialItem = styled.div`
  display: flex;
  align-items: center;
  width: 8.1875rem;

  ${({ theme }) => theme.mediaQueries.mobileM} {
    width: 9.1875rem;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: auto;
    &:hover {
      ${Svg} {
        ${css`
          rect {
            fill: url("#hoveredAboutUs");
          }
        `}
      }
    }
  }

  &:hover,
  &:focus {
    ${StyledFooterLink} {
      border-bottom-color: ${({ theme }) => theme.colors.textFourth};
    }
  }
`;

export const StyledFooterSocialContainer = styled.div`
  padding: 0 1rem;
`;
