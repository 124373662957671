import styled from "styled-components";
// Components
import { Heading, Text } from "components";

export const StyledPortfolioItemLabelHeading = styled(Heading)`
  cursor: pointer;
`;

export const StyledPortfolioItemLabelText = styled(Text)`
  opacity: 0.5;
  border: 1px solid ${({ theme }) => theme.colors.disabledLight};
  padding: 0.25rem 0.75rem;
  border-radius: ${({ theme }) => theme.radii.semiLarge};
`;
