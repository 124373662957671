import styled, { css } from "styled-components";
import { Container, Image } from "components";

export const StyledBookACallWithExpertWrapper = styled.div<{ bgSrc?: string }>`
  width: 100%;
  height: 100%;
  padding: 0;
  background-size: cover;
  margin: 4rem 0 0;

  overflow-y: clip;

  img {
    height: -webkit-fill-available;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 0 2rem;
    margin: 6.5rem 0 0;
    background-image: ${({ bgSrc }) => `url(${bgSrc})`};
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const StyledBookACallWithExpertContainer = styled(Container)<{ bgSrc?: string }>`
  background-image: ${({ bgSrc }) => (bgSrc ? `url(${bgSrc})` : null)};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 16px;

  ${({ theme }) => theme.mediaQueries.laptop} {
    background-image: none;
    border-radius: 0px;
  }
`;

export const StyledBookACallTitleWrapper = styled.div<{ isReverse?: boolean }>`
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column-reverse;
  padding: 0 1rem;

  align-items: center;

  ${({ isReverse }) =>
    isReverse &&
    css`
      align-items: center;

      ${({ theme }) => theme.mediaQueries.laptop} {
        align-items: flex-end;
      }

      ${({ theme }) => theme.mediaQueries.laptopL} {
        align-items: center;
      }
    `}

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 0;
    flex-direction: ${({ isReverse }) => (isReverse ? "row-reverse" : "row")};
  }
`;

export const StyledDividerLinkedinBlock = styled.div`
  width: 1px;
  height: 1.25rem;
  margin: 0px 0.75rem;
  background-color: ${({ theme }) => theme.colors.textSixth};
  opacity: 0.6;
`;

export const StyledExpertInfoContainer = styled.div`
  max-width: 34.1875rem;
`;

export const StyledLinkedInWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
`;

export const StyledExpertImage = styled(Image)<{ isBoxShadow?: boolean; isMainPageVariation?: boolean }>`
  width: 100vw;
  height: 100%;

  ${({ theme }) => theme.mediaQueries.tablet} {
    height: 100%;
    width: 100%;
  }

  ${({ isBoxShadow, isMainPageVariation }) =>
    isBoxShadow &&
    css`
      ::after {
        content: "";
        position: absolute;
        bottom: 12%;
        right: 5rem;
        z-index: ${({ theme }) => -theme.zIndices.buttonGradient};

        width: 40%;
        height: 25%;

        box-shadow: ${({ theme }) => (isMainPageVariation ? theme.colors.blueWhiteBlur : theme.colors.yellowBlur)}
          ${({ theme }) => theme.shadows.boostBusiness};
        background-color: ${({ theme }) =>
          isMainPageVariation ? theme.colors.blueWhiteBlur : theme.colors.yellowBlur};
        border-radius: ${({ theme }) => theme.radii.circle};

        opacity: 0.3;
        filter: blur(0.75rem);
      }
    `}
`;
