// Types
import { DutiesProps } from "./components/duties/types";
import { RequirementsProps } from "./components/requirements/types";

export const CareersVariants = {
  middlePlusQa: "middle-plus-qa",
  middlePlusBusinessAnalyst: "middle-plus-business-analyst",
  reactNativeEngineerReactJs: "react-native-engineer-react-js",
  middleBackEndEngineerNodeJs: "middle-back-end-engineer-node-js",
  devOpsEngineer: "dev-ops-engineer",
} as const;

export type CareersVariantsType = (typeof CareersVariants)[keyof typeof CareersVariants];

export type BaseCareersProps = {
  title: string;
  list: string[];
};

export type CareersVariantsContent = {
  requirements: RequirementsProps;
  wouldBeAPlus: DutiesProps;
  duties: DutiesProps;
  weOffer: DutiesProps;
};
