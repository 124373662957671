import { useMatchBreakpoints } from "hooks";

import { Image, Link, Text } from "components";
import { StyledArticleDescription, StyledRelatedArticleItem } from "./styled";

import { formateDate } from "utils";

import { RelatedArticlesItemType } from "./types";

const RelatedArticlesItem: React.FC<RelatedArticlesItemType> = ({ articlesItem, isBigArticle }) => {
  const [textLengthCount, setTextLengthCount] = React.useState(0);

  const { isMobile } = useMatchBreakpoints();

  const paragraphContainer = document.getElementById(articlesItem.title)?.getElementsByTagName("p")[0];

  React.useEffect(() => {
    if (paragraphContainer) {
      const text = paragraphContainer.textContent || paragraphContainer.innerText;

      setTextLengthCount(text.length);
    }
  }, [paragraphContainer]);

  return (
    <StyledRelatedArticleItem isBigArticle={isBigArticle}>
      {articlesItem.coverImage?.url && (
        <Image
          src={articlesItem.coverImage?.url || ""}
          aspectRatio={(articlesItem.coverImage?.width || 1) / (articlesItem.coverImage?.height || 1)}
          mb="1rem"
          minWidth="12.5rem"
          mr={{ _: "0", tablet: isBigArticle ? "0" : "1.25rem" }}
          height={{ _: "14.25rem", tablet: isBigArticle ? "100%" : "10rem" }}
        />
      )}

      <div>
        <Text textScale={isMobile ? "p12Medium" : "p16Bold"} color="textFourth" opacity={isMobile ? 1 : 0.7}>
          {formateDate(articlesItem.date)}
        </Text>

        <Link
          external
          textScale="p22Medium"
          color="textMain"
          py={{ _: "0.25rem", tablet: isBigArticle ? "1.25rem" : "0.5rem" }}
        >
          {articlesItem.title}
        </Link>

        {articlesItem.content?.html && !isMobile && (
          <StyledArticleDescription
            id={articlesItem.title}
            dangerouslySetInnerHTML={{
              __html:
                textLengthCount > 0 ? articlesItem.content.html.slice(0, textLengthCount) : articlesItem.content.html,
            }}
          />
        )}
      </div>
    </StyledRelatedArticleItem>
  );
};

export default RelatedArticlesItem;
