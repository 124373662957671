import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledAnimatedCharacterTextWrapper = styled(motion.span)`
  opacity: 0;
`;

export const StyledAnimatedCharacterText = styled(motion.span)`
  opacity: 0;
`;
