import styled from "styled-components";

export const StyledFooterContactsColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & :first-child {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const StyledFooterContactsContainer = styled.div`
  margin: 1rem 0 2.5rem;
  padding: 0 1rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin: 0;
    padding: 0;
  }
`;

export const StyledFooterContactsFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const StyledFooterContactsFlex = styled.div`
  display: flex;
`;

export const StyledFooterContactsFlexGap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin: 3.5rem 0;
  }
`;

export const StyledPhoneListContainer = styled.div`
  margin: 0 0.5rem;
`;
