import styled from "styled-components";
import { clearfix } from "styles";
// Components + styling
import { Image } from "components";
import {
  styles as headingStyles,
  fontSizes as headingFontSizes,
  lineHeights as headingLineHeights,
} from "components/heading/theme";
import { fillTextWithGradient } from "components/text";
import { styles as textStyles } from "components/text/theme";

export const StyledBlogImage = styled(Image)`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-height: 25.7rem;
  border-radius: ${({ theme }) => theme.radii.semiLarge};
  margin-bottom: 1.5rem;

  & > img {
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-bottom: 3rem;
  }
`;

export const StyledContent = styled.div`
  h1 {
    ${headingStyles.h1}
    font-size: ${headingFontSizes.h1.mobile};
    line-height: ${headingLineHeights.h1.mobile};
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }

  h2 {
    ${headingStyles.h2};
    font-size: ${headingFontSizes.h2.mobile};
    line-height: ${headingLineHeights.h2.mobile};
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }

  h3 {
    ${headingStyles.h3};
    font-size: ${headingFontSizes.h3.mobile};
    line-height: ${headingLineHeights.h3.mobile};
    margin-top: 1.25rem;
    margin-bottom: 0.5rem;
  }

  h4 {
    ${headingStyles.h4};
    font-size: ${headingFontSizes.h4.mobile};
    line-height: ${headingLineHeights.h4.mobile};
    margin-top: 1.25rem;
    margin-bottom: 0.5rem;
  }

  h5 {
    ${headingStyles.h5};
    font-size: ${headingFontSizes.h5.mobile};
    line-height: ${headingLineHeights.h5.mobile};
    margin-top: 1rem;
    margin-bottom: 0.25rem;
  }

  h6 {
    ${headingStyles.h6};
    font-size: ${headingFontSizes.h6.mobile};
    line-height: ${headingLineHeights.h6.mobile};
    margin-top: 1rem;
    margin-bottom: 0.25rem;
  }

  li,
  p {
    ${textStyles.p16Regular}

    color: ${({ theme }) => theme.colors.textMain};
    margin-bottom: 1rem;
  }

  li {
    margin-bottom: 0;
  }

  p > a {
    display: inline-block;
    ${fillTextWithGradient("buttonAccent")}
  }

  p > code {
    background-color: ${({ theme }) => theme.colors.bgSecond};
  }

  blockquote {
    border-left: 2px solid ${({ theme }) => theme.colors.textMain};
    padding-left: 1rem;
    margin-left: -1rem;
    font-style: italic;
    margin-bottom: 1rem;
  }

  pre {
    display: block;
    padding: 1em;
    margin: 0 0 2em;
    font-size: 1em;
    line-height: 1.4;
    word-break: break-all;
    word-wrap: break-word;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.bgSecond};
  }

  table {
    margin-bottom: 2em;
    color: ${({ theme }) => theme.colors.textMain};
  }

  table,
  th,
  td {
    border: 1px solid ${({ theme }) => theme.colors.textFourth};
  }

  iframe {
    z-index: 99 !important;
    width: 100%;
    height: 12.5rem;
    margin-bottom: 1rem;

    ${({ theme }) => theme.mediaQueries.mobileL} {
      height: 21.875rem;
    }

    ${({ theme }) => theme.mediaQueries.laptop} {
      height: 31.25rem;
    }
  }

  img {
    width: 100%;
    margin: 0.5rem 0 1rem;

    ${({ theme }) => theme.mediaQueries.laptop} {
      margin: 0.5rem 0 2rem;
    }
  }

  ul,
  ol {
    margin: -1rem 0 1rem;
    padding-left: 1.25rem;
    color: ${({ theme }) => theme.colors.textFifth};

    ${({ theme }) => theme.mediaQueries.laptop} {
      padding-left: 1.875rem;
      margin: -1.5rem 0 1.5rem;
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: auto;
  }

  .related-link {
    background-color: ${({ theme }) => theme.colors.postRelatedLinkBg};
    padding: 1.5rem;
    position: relative;
    margin: 1.5rem 0;

    & p,
    a {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      margin: 0;
    }

    & a {
      color: #228ae9;
    }

    &::before {
      ${clearfix}
      position: absolute;
      background-color: ${({ theme }) => theme.colors.textAccent};
      width: 0.25rem;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: ${({ theme }) => theme.radii.semiSmall};
    }

    ${({ theme }) => theme.mediaQueries.laptop} {
      margin: 3rem 0;
      & p,
      a {
        font-size: 1.375rem;
        font-weight: 400;
        line-height: 2.125rem;
        margin: 0;
      }
    }
  }

  .img-desc {
    text-align: center;
  }

  .dots-separator ul {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    padding: 0;
    gap: 1.25rem;
    color: ${({ theme }) => theme.colors.textMain};
  }

  .call-to-action {
    width: 100%;
    position: relative;
    margin: 1.5rem 0;
    padding: 2rem;
    border-radius: ${({ theme }) => theme.radii.semiLarge};
    background-color: ${({ theme }) => theme.colors.bgDark};

    ${({ theme }) => theme.mediaQueries.tablet} {
      margin: 3rem 0;
      padding: 3rem;
    }
  }

  .call-to-action h4,
  .call-to-action h5,
  .call-to-action h6 {
    color: ${({ theme }) => theme.colors.bgSecond};
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  .call-to-action p:first-of-type,
  .call-to-action h4,
  .call-to-action h5,
  .call-to-action h6 {
    ${({ theme }) => theme.mediaQueries.tablet} {
      max-width: 50%;
    }
  }

  .call-to-action p:first-of-type {
    color: ${({ theme }) => theme.colors.textFifth};
  }

  .call-to-action p:last-of-type {
    width: 100%;
    height: 3.12rem;
    border-radius: 2.8125rem;
    background: ${({ theme }) => theme.gradients.buttonAccent};
    cursor: pointer;
    box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.5) inset;
    margin: 1rem 0 0;

    ${({ theme }) => theme.mediaQueries.tablet} {
      position: absolute;
      top: 50%;
      right: 3rem;
      transform: translateY(-50%);
      width: 16.5rem;
      height: 3.75rem;
      margin: 0;
    }
  }

  .call-to-action p:last-of-type a {
    color: ${({ theme }) => theme.colors.white};
    -webkit-text-fill-color: white;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    h1 {
      font-size: ${headingFontSizes.h1.laptop};
      line-height: ${headingLineHeights.h1.laptop};
      margin-top: 3rem;
      margin-bottom: 1rem;
    }

    h2 {
      font-size: ${headingFontSizes.h2.laptop};
      line-height: ${headingLineHeights.h2.laptop};
      margin-top: 3rem;
      margin-bottom: 1rem;
    }

    h3 {
      font-size: ${headingFontSizes.h3.laptop};
      line-height: ${headingLineHeights.h3.laptop};
      margin-top: 2.5rem;
      margin-bottom: 0.75rem;
    }

    h4 {
      font-weight: 700;
      font-size: ${headingFontSizes.h4.laptop};
      line-height: ${headingLineHeights.h4.laptop};
    }

    h5 {
      font-size: ${headingFontSizes.h5.laptop};
      line-height: ${headingLineHeights.h5.laptop};
    }

    h6 {
      font-size: ${headingFontSizes.h6.laptop};
      line-height: ${headingLineHeights.h6.laptop};
    }

    li,
    p {
      ${textStyles.p22Regular}
      line-height: 1.7;
      margin-bottom: 1.5rem;
    }

    li {
      margin-bottom: 0;
    }
  }

  .table-of-content {
    background: ${({ theme }) => theme.gradients.buttonAccent};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .table-of-content-item p {
    width: fit-content;
    margin-bottom: 0;
    cursor: pointer;
  }
`;
