import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledCustomCursor = styled(motion.div)`
  width: 6.25rem;
  height: 6.25rem;
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndices.dropdown + 1};
  /* Need 50% of width for negative margin left for centralize custom cursor block  */
  margin: -3.125rem 0 0 -3.125rem;
  border-radius: ${({ theme }) => theme.radii.circle};
  text-align: center;
  user-select: none;
  pointer-events: none;
  transform-origin: center;
  background: ${({ theme }) => theme.gradients.buttonAccent};

  ${({ theme }) => theme.mediaQueries.tablet} {
    display: flex;
  }
`;
