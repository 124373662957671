import styled, { css } from "styled-components";

import { Container } from "components/layout";

import { Colors } from "theme/types";

export const StyledCoveredIndustriesContainer = styled(Container)<{ blur?: keyof Colors }>`
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.buttonContent};

  ${({ theme }) => theme.mediaQueries.laptopL} {
    ${({ blur }) =>
      blur &&
      css`
        &::before {
          content: "";
          position: absolute;
          right: -20%;
          top: 0;
          z-index: ${({ theme }) => -theme.zIndices.buttonGradient};
          width: 28.25rem;
          height: 19.875rem;
          box-shadow: ${({ theme }) => theme.colors[blur]} ${({ theme }) => theme.shadows.boostBusiness};
          background-color: ${({ theme }) => theme.colors[blur]};
          border-radius: ${({ theme }) => theme.radii.circle};
          opacity: 0.1;
          filter: blur(0.75rem);
        }
      `}
  }
`;
