// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { ROUTES } from "navigation/routes";
import { EXTERNAL_LINKS } from "configs";
// Types
import { ServicesPageContent, ServicesVariants } from "../types";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";

export const DEDICATED_TEAM_CONTENT: ServicesPageContent = {
  banner: {
    title: "Dedicated Team",
    description:
      "Idealogic provides Dedicated team services if you need experienced developers or designers fully immersed in your project. Our specialists are ready to fulfill your product’s requirements in the shortest possible terms to help you release faster. A dedicated team focuses solely on your project to ensure high-quality end results.",
    imageSrc: "/images/services-banner-dedicated-team.webp",
    imageMobileSrc: "/images/services-banner-dedicated-team-mobile.webp",
    imageRatio: 0.83,
  },
  needs: {
    title: "Why you might need dedicated team?",
    description: "Win from working with experienced developers and designers, cutting costs on internal hires.",
    needsList: [
      {
        label: "Reduce workload for the internal team",
        description:
          "You can delegate specific technical tasks to the professionals in a dedicated team and let your internal team work on business-oriented projects, concentrating on additional research, marketing, etc. With our specialists, you can surely meet your deadlines with large-scale projects.",
        iconSrc: "/images/mvp-development-workload-icon.svg",
      },
      {
        label: "No need to worry about specific requirements",
        description:
          "You can choose every specialist, carefully estimating their knowledge and skills. Our dedicated team can address specific development or compliance needs, solve them using up-to-date technologies, and scale up or down based on the project’s scope.",
        iconSrc: "/images/mvp-development-notes-icon.svg",
      },
      {
        label: "Scale your project faster",
        description:
          "You don’t need to waste your time and resources on a hiring process and training, as Idealogic’s dedicated team is ready to start at once. Don’t worry about the lack of specialists in your area — you can outsource our professionals, cutting costs on social benefits or technical supplies, and optimizing your budget.",
        iconSrc: "/images/mvp-development-time-icon.svg",
      },
    ],
  },
  benefits: {
    description: "Discover the unusual advantages of choosing Idealogic’s dedicated team for your product development.",
    benefitsList: [
      {
        title: "Up-to-date tech expertise",
        description:
          "Idealogic focuses on learning and using relevant techno stack to deliver products with a strong backbone and pleasant interfaces. Our specialists constantly improve their skills and follow the latest trends in different industries to stay ahead of the competition.",
      },
      {
        title: "Regular updates",
        description:
          "We also pay attention to soft skills to ensure clear communication within the project. Our team effortlessly manages such obstacles as different time zones and agrees on a comfortable update format to stay in touch with every change made.",
      },
      {
        title: "Reasonable rates",
        description:
          "We agree on the preferable type of collaboration, be it time & material or fixed price. You can choose team members that correspond the best to your requirements or have the most experience in your industry. ",
      },
      {
        title: "Reliable partner",
        description:
          "When you choose Idealogic, you receive a partner who truly cares about your sustained growth. We help you with the technical implementation of your ideas and cheer for overall recognition and success.",
      },
    ],
    isButtonPositionStart: true,
  },
  pipeline: {
    description: "In our MVP Development workflow, we use design thinking methodology",
    pipelineList: [
      {
        label: "Empathize/Define",
        description:
          "It is the crucial initial phase in the design thinking process. It involves understanding and connecting with the end users' needs, feelings, and challenges to define the problem statement. This step sets the foundation for innovative and user-centric design solutions by fostering empathy and uncovering the core issues that need solving.",
        iconSrc: "/images/services-pipeline-empathize-icon.svg",
        deliverablesList: [
          { label: "Figma Layout", iconSrc: "/images/services-pipeline-deliverables-figma-icon.svg" },
          { label: "Interactive Prototype", iconSrc: "/images/services-pipeline-deliverables-interactive-icon.svg" },
          { label: "Deep Research", iconSrc: "/images/services-pipeline-deliverables-research-icon.svg" },
          { label: "Other", iconSrc: "/images/services-pipeline-deliverables-other-icon.svg" },
        ],
      },
      {
        label: "Analyse/Ideate",
        description:
          "Analyze/Ideate represents the creative heart of the design thinking process. During this phase, designers delve into the insights gathered from empathy and define stages to brainstorm, explore, and generate a wide range of ideas and concepts.",
        iconSrc: "/images/services-pipeline-empathize-icon.svg",
        deliverablesList: [
          { label: "Figma Layout", iconSrc: "/images/services-pipeline-deliverables-figma-icon.svg" },
          { label: "Interactive Prototype", iconSrc: "/images/services-pipeline-deliverables-interactive-icon.svg" },
          { label: "Deep Research", iconSrc: "/images/services-pipeline-deliverables-research-icon.svg" },
          { label: "Other", iconSrc: "/images/services-pipeline-deliverables-other-icon.svg" },
        ],
      },
      {
        label: "Design/Prototype",
        description:
          "This is tangible or digital representation of a product, service, or concept created during the design and development process. It serves as a visual and often interactive model that helps designers and stakeholders test and define designs.",
        iconSrc: "/images/services-pipeline-empathize-icon.svg",
        deliverablesList: [
          { label: "Figma Layout", iconSrc: "/images/services-pipeline-deliverables-figma-icon.svg" },
          { label: "Interactive Prototype", iconSrc: "/images/services-pipeline-deliverables-interactive-icon.svg" },
          { label: "Deep Research", iconSrc: "/images/services-pipeline-deliverables-research-icon.svg" },
          { label: "Other", iconSrc: "/images/services-pipeline-deliverables-other-icon.svg" },
        ],
      },
      {
        label: "Test",
        description:
          "It's the critical phase where prototypes or designs are evaluated for functionality, usability, and overall effectiveness. It involves gathering feedback from users, stakeholders, or target audiences to identify strengths and weaknesses. Testing helps designers refine their work, ensuring it aligns with user needs and expectations",
        iconSrc: "/images/services-pipeline-empathize-icon.svg",
        deliverablesList: [
          { label: "Figma Layout", iconSrc: "/images/services-pipeline-deliverables-figma-icon.svg" },
          { label: "Interactive Prototype", iconSrc: "/images/services-pipeline-deliverables-interactive-icon.svg" },
          { label: "Deep Research", iconSrc: "/images/services-pipeline-deliverables-research-icon.svg" },
          { label: "Other", iconSrc: "/images/services-pipeline-deliverables-other-icon.svg" },
        ],
      },
    ],
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.planetcoin, CaseStudiesSlugEnums.glue],
    title: "Dedicated team case studies",
    TitleIcon: WavingHandIcon,
    description:
      "We believe in blockchain technology versatility and prove that with our completed projects. Aviation, gaming, finance, and more — check for yourself.",
  },
  coveredIndustries: {
    coveredIndustriesList: [
      { label: "Product design", link: EXTERNAL_LINKS.idealogicDesign, isExternal: true },
      { label: "Technical consulting", link: `/${ROUTES.services}/${ServicesVariants.technicalConsulting}` },
      { label: "MVP Development", link: `/${ROUTES.services}/${ServicesVariants.mvpDevelopment}` },
      { label: "Maintenance & Support", link: `/${ROUTES.services}/${ServicesVariants.maintenanceSupport}` },
    ],
  },
  stagesBlocks: [
    {
      title: "Analysis",
      description:
        "First, our business analysts learn your project’s requirements to define its required skills and tech stack. We assess the scope of work to choose the most relevant candidates, preferably with experience in your industry.",
      iconSrc: "/images/keys-stages/analysis-icon.svg",
    },
    {
      title: "Team creation",
      description:
        "At this stage, we select the most suitable candidates for the project implementation and unite them into one cohesive team. For the team to be successful and efficient, we balance expertise, communication dynamics, and collaboration potential.",
      iconSrc: "/images/keys-stages/team-creation-icon.svg",
    },
    {
      title: "Onboarding",
      description:
        "Finally, we ensure a smooth transition of dedicated team members to the project environment. The onboarding process includes orientation, training, and providing the necessary information to the team members so they can contribute effectively to the project's success.",
      iconSrc: "/images/keys-stages/onboarding-icon.svg",
    },
  ],
};
export default DEDICATED_TEAM_CONTENT;
