export const textContainerVariants = (delay: number) => {
  return {
    hidden: {},
    visible: (i = 1) => ({
      opacity: 1,
      transition: { staggerChildren: 0.02, delayChildren: 0.02 * i + delay },
    }),
  };
};

export const textCharacterVariants = {
  hidden: {},
  visible: {
    opacity: 1,
    transition: {
      type: "linear",
      duration: 0.3,
    },
  },
};
