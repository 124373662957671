// Components + Styling
import { Image, Link, Text } from "components";
import { LinkedInIcon } from "components/svg";
import {
  StyledInformation,
  StyledLinkedInWrapper,
  StyledPositionWrapper,
  StyledInformationWrapper,
  StyledStaticFeedbackWrapper,
  StyledLinkedInMobileWrapper,
} from "./styled";
import { StyledDividerLinkedinBlock } from "pages/industries/components/book-a-call-with-expert/styled";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Context
import { useTranslation } from "context";
// Types
import { StaticFeedbackBlockProps } from "./types";

const StaticFeedbackBlock: React.FC<StaticFeedbackBlockProps> = ({ personName, imgUrl, position, linkedInProfile }) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useMatchBreakpoints();

  return (
    <StyledStaticFeedbackWrapper>
      <Image width="4.375rem" src={imgUrl} variant="circle" aspectRatio={1} />

      <StyledInformationWrapper>
        <StyledInformation>
          <Text textScale={isMobile ? "p18Bold" : "p20Bold"} color="white">
            {t(personName)}
          </Text>
          {isMobile && (
            <StyledLinkedInMobileWrapper>
              <StyledDividerLinkedinBlock />
              <Link href={linkedInProfile} external color="linkedIn">
                <LinkedInIcon color="linkedInLinkHover" width={isMobile ? "1rem" : "1.5rem"} />
              </Link>
            </StyledLinkedInMobileWrapper>
          )}
        </StyledInformation>

        <StyledPositionWrapper>
          <Text textScale="p16Regular" color="textFourthForDarkBg">
            {t(position)}
          </Text>

          <StyledLinkedInWrapper>
            {!isTablet && !isMobile && <StyledDividerLinkedinBlock />}

            <Link href={linkedInProfile} external color="linkedIn">
              {!isMobile && <LinkedInIcon color="linkedInLinkHover" width={isMobile ? "1rem" : "1.5rem"} />}

              {!isMobile && (
                <Text mx="0.5rem" color="linkedInLinkHover" textScale={isMobile ? "p16Regular" : "p20Regular"}>
                  {t("LinkedIn profile")}
                </Text>
              )}
            </Link>
          </StyledLinkedInWrapper>
        </StyledPositionWrapper>
      </StyledInformationWrapper>
    </StyledStaticFeedbackWrapper>
  );
};

export default StaticFeedbackBlock;
