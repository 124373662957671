import React from "react";
import Cookies from "js-cookie";
// Components + styling
import { Text } from "components/text";
import { StyledCookiesBar, StyledCookiesButton, StyledCookiesTitle, StyledPrivacyPolicy } from "./styled";
// Animations
import { cookiesShow } from "./animations";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Utils
import { consentAnalytics } from "utils";
// Constants
import { ROUTES } from "navigation/routes";
import { COOKIES_KEYS } from "configs";

const CookiesBar: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const [isHideCookies, setIsHideCookies] = React.useState(() => {
    return Cookies.get(COOKIES_KEYS.setCookies) === "true";
  });

  const handleSetCookies = () => {
    Cookies.set(COOKIES_KEYS.setCookies, "true", { expires: 365 });
    setIsHideCookies(true);
    consentAnalytics();
  };

  const variants = cookiesShow(isDesktop);

  return (
    <StyledCookiesBar initial="hidden" animate={isHideCookies ? "hidden" : "visible"} variants={variants}>
      <StyledCookiesTitle>
        <Text textScale="p12Regular" width="16.5rem">
          <Text as="span" $fontWeight="bold">
            {t("We use cookies")}
          </Text>

          <Text px="0.25rem" as="span">
            {t(
              "to improve analytics and ensure your best experience. By continuing to use this website, you accept our use of cookies. Please check our",
            )}
          </Text>

          <StyledPrivacyPolicy href={`/${ROUTES.privacyPolicy}`} color="textAccentSecond">
            {t("Privacy Policy")}
          </StyledPrivacyPolicy>

          <Text px="0.25rem" as="span">
            {t("for more information.")}
          </Text>
        </Text>
      </StyledCookiesTitle>

      <StyledCookiesButton scale={isDesktop ? "xs" : "md"} isShowHoverLines={false} onClick={handleSetCookies}>
        <Text textScale="p12Regular" fontWeight="bold">
          {t("Got it")}
        </Text>
      </StyledCookiesButton>
    </StyledCookiesBar>
  );
};

export default CookiesBar;
