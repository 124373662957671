import { useNavigate } from "react-router-dom";

import { useTranslation } from "context";
import { useMatchBreakpoints } from "hooks";

import { Button, Flex, FlexGap, Heading, Text } from "components";
import { LinkArrowShortIcon } from "components/svg";
import { RelatedArticlesItem } from "./components";
import { StyledArticlesColumn, StyledArticlesContainer, StyledArticlesHeadingContainer } from "./styled";

import { ROUTES } from "navigation/routes";

import { RelatedArticlesType } from "./types";

const RelatedArticles: React.FC<RelatedArticlesType> = ({ description, posts, blur }) => {
  const { t } = useTranslation();
  const { isMobile } = useMatchBreakpoints();

  const navigate = useNavigate();

  const firstArticle = posts[0];

  const otherArticle = posts.slice(1);

  const blogClickHandler = (slug: string) => {
    navigate(`/${ROUTES.blog}/${slug}`);
  };

  const onButtonClick = () => {
    navigate(`/${ROUTES.blog}`);
  };

  return (
    <StyledArticlesContainer
      pt={{ _: "2.5rem", laptop: "7rem" }}
      pb={{ _: "1rem", laptop: "1.5rem" }}
      px={{ _: "1rem", tablet: "2rem", laptopL: "4.5rem" }}
      position="relative"
      blur={blur}
    >
      <Flex justifyContent="space-between" flexDirection={{ _: "column", tablet: "row" }}>
        <div>
          <Heading as="h2" scale="h2" color="textMain" $fontWeight="bold" pb="0.625rem">
            {t("Related articles")}
          </Heading>

          <Text textScale={isMobile ? "p16Regular" : "p22Regular"} color="textFifth" mt={{ _: "0.75rem", tablet: "0" }}>
            {description}
          </Text>
        </div>

        <div>
          {isMobile ? (
            <Button
              height="3.125rem"
              width="100%"
              filledType="bordered"
              color="textMain"
              onClick={onButtonClick}
              mt="1rem"
            >
              {t("Read all articles")}
            </Button>
          ) : (
            <StyledArticlesHeadingContainer external href={`/${ROUTES.blog}`}>
              <Heading as="h5" scale="h5" color="textMain">
                {t("Read all articles")}
              </Heading>

              <LinkArrowShortIcon width="1.25rem" color="textMain" mx="0.5rem" />
            </StyledArticlesHeadingContainer>
          )}
        </div>
      </Flex>

      <FlexGap
        gap={isMobile ? "1.5rem" : "2rem"}
        pt={{ _: "1.5rem", tablet: "2.5rem" }}
        flexDirection={{ _: "column", laptopL: "row" }}
      >
        <StyledArticlesColumn onClick={() => blogClickHandler(firstArticle.slug)}>
          <RelatedArticlesItem articlesItem={firstArticle} isBigArticle={!isMobile} />
        </StyledArticlesColumn>

        <StyledArticlesColumn>
          {otherArticle.map((item, index) => (
            <div onClick={() => blogClickHandler(item.slug)} key={index}>
              <RelatedArticlesItem articlesItem={item} isBigArticle={false} />
            </div>
          ))}
        </StyledArticlesColumn>
      </FlexGap>
    </StyledArticlesContainer>
  );
};

export default RelatedArticles;
