import { Svg } from "components";

import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ ...props }) => {
  return (
    <Svg width="31" height="30" viewBox="0 0 31 30" {...props} color="transparent">
      <path
        d="M16.25 14L25.25 5M25.25 5H19.9062M25.25 5V10.3438"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.25 15C25.25 19.714 25.25 22.0711 23.7855 23.5355C22.3211 25 19.964 25 15.25 25C10.536 25 8.17893 25 6.71447 23.5355C5.25 22.0711 5.25 19.714 5.25 15C5.25 10.286 5.25 7.92893 6.71447 6.46447C8.17893 5 10.536 5 15.25 5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Svg>
  );
};

export default Icon;
