import { gql } from "@apollo/client";

export const getCaseStudies = gql`
  query GetCaseStudies($stage: Stage! = PUBLISHED, $where: CaseStudyWhereInput) {
    caseStudies(stage: $stage, where: $where, orderBy: order_ASC) {
      id
      slug
      subtitle
      createdAt
      title
      cardDescription
      redirectedLink
      isComingSoon
      imgSrc {
        id
        height
        url
        width
      }
      logo {
        logoSrc {
          id
          height
          url
          width
        }
        width
        height
        aspectRatio
      }

      category {
        id
        title
      }
    }
    caseStudiesConnection(stage: $stage, where: $where, orderBy: order_ASC) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      aggregate {
        count
      }
    }
  }
`;

export const geCaseStudyBySlug = gql`
  query GetCaseStudySlug($slug: String!) {
    caseStudy(where: { slug: $slug }) {
      id
      slug
      subtitle
      title
      isDark
      isHeaderContentDark
      description
      seo {
        description
        title
        keywords
        image {
          url
        }
      }
      isComingSoon
      containerColor {
        hex
      }
      coverImage {
        id
        height
        url
        width
      }
      imgSrc {
        id
        height
        url
        width
      }
      category {
        id
        title
        link
      }
      industry {
        id
        title
        link
      }
      contentSection {
        id
        contentName
        bgPosition
        onlyDesktopView
        isDark
        coveredImage {
          id
          height
          url
          width
        }
        content {
          ... on Slider {
            id
            sliderItem {
              id
              height
              url
              width
            }
            items {
              id
              height
              url
              width
              altText
            }
          }
          ... on ContentHtml {
            innerHtml {
              html
            }
            showContactButton
          }
          ... on VideoInfo {
            id
            name
            description
            position
            rating
            videoSources
            isShowedAvatar
            gifSrc {
              id
              height
              url
              width
            }
            imageSource {
              id
              height
              url
              width
            }
          }
          ... on AdditionalInfo {
            id
            links {
              name
              link
            }
            techStack {
              size
              url
              width
            }
          }
          ... on DesignCase {
            id
            coveredBannerImage {
              size
              url
              width
            }
            infoImage {
              size
              url
              width
            }
          }
        }
      }
    }
  }
`;

export const getCaseStudiesFilters = gql`
  query GetFilters($stage: Stage! = PUBLISHED) {
    industries(stage: $stage) {
      id
      title
    }
    categories(stage: $stage) {
      id
      title
    }
  }
`;

export const getCaseStudiesBySlugs = gql`
  query GetCaseStudies($stage: Stage! = PUBLISHED, $where: CaseStudyWhereInput) {
    caseStudies(stage: $stage, where: $where) {
      id
      slug
      subtitle
      title
      shortTitle
      redirectedLink
      isComingSoon
      portfolioImage {
        id
        height
        url
        width
        fileName
      }
      logo {
        logoSrc {
          id
          height
          url
          width
        }
        width
        height
        aspectRatio
      }

      videoInfo {
        id
        videoSources
        name
        position
        gifDuration
        gifAspectRation
        linkedInProfile
        gifSrc {
          id
          height
          url
          width
        }
      }
    }
  }
`;
