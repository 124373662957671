import { Suspense, lazy } from "react";
import { Loader } from "components";

interface Opts {
  fallback?: React.ReactNode;
  delay?: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const lazyLoad = <T extends Promise<{ default: U }>, U extends React.ComponentType<any>>(
  importFunc: () => T,
  selectorFunc?: (s: { default: U }) => U,
  opts: Opts = {
    fallback: <Loader position="absolute" top="0" $backgroundColor="bgDark" left="0" width="100%" zIndex="100" />,
  },
) => {
  let lazyFactory: () => Promise<{ default: U }> = importFunc;

  if (selectorFunc) {
    lazyFactory = () => importFunc().then(module => ({ default: selectorFunc(module) }));
  }

  const LazyComponent = lazy(
    () =>
      new Promise<{ default: U }>(resolve => {
        setTimeout(() => {
          resolve(lazyFactory());
        }, opts.delay ?? 0);
      }),
  );

  const LazyLoader = (props: React.ComponentProps<U>): JSX.Element => {
    if (opts.fallback) {
      return (
        <Suspense fallback={opts.fallback}>
          <LazyComponent {...props} />
        </Suspense>
      );
    } else {
      return <LazyComponent {...props} />;
    }
  };

  return LazyLoader;
};
