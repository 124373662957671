import styled from "styled-components";
import { clearfix } from "styles";
// Components
import { Image } from "components";

export const StyledMainPostBox = styled(Image)`
  width: 100%;
  max-height: 25.75rem;
  position: relative;
  margin-bottom: 2rem;
  border-radius: ${({ theme }) => theme.radii.semiLarge};
  overflow: hidden;
  cursor: pointer;

  ::after {
    ${clearfix};
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: ${({ theme }) => theme.zIndices.dropdown - 1};
    border-radius: 0px 0px ${({ theme }) => theme.radii.semiLarge} ${({ theme }) => theme.radii.semiLarge};
    background: ${({ theme }) => theme.gradients.blogMainPost};
  }
`;

export const StyledMainPostInfoWrapper = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`;

export const StyledPostPreviewImage = styled(Image)`
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: -webkit-fill-available;
    object-fit: cover;
  }
`;

export const StyledPostPreviewWrapper = styled.div`
  cursor: pointer;
`;

export const StyledPostImageWrapper = styled.div`
  border-radius: ${({ theme }) => theme.radii.medium};
  overflow: hidden;
  margin-bottom: 1rem;
`;
