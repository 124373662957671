import styled from "styled-components";
// Components
import { Text } from "components";

export const StyledSearchResultsWrapper = styled.div`
  width: 100%;
  max-height: 30vh;
  position: absolute;
  top: 4.375rem;
  left: 0;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  border: 1px solid ${({ theme }) => theme.colors.lineMain};
  border-radius: ${({ theme }) => theme.radii.large};
  box-shadow: ${({ theme }) => theme.shadows.card};
  background: ${({ theme }) => theme.colors.white};
  overflow-y: scroll;

  ${({ theme }) => theme.mediaQueries.tablet} {
    top: 0;
  }
`;

export const StyledSearchResultItem = styled(Text)<{ disabled?: boolean }>`
  padding: 0.75rem 1rem;
  background: ${({ theme }) => theme.colors.transparent};
  transition: ${({ theme }) => theme.transitions.default};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  &:hover:not([disabled]) {
    background: ${({ theme }) => `${theme.colors.lineMain}80`}; /* 80 used for 50% opacity in hex */
  }
`;
