// Components + Styling;
import { Flex, Text } from "components";
import { StarIcon } from "components/svg";
// Types
import { RatingStarsProps } from "./types";

const RatingStars: React.FC<RatingStarsProps> = ({
  rating,
  fillColor = "ratingYellow",
  textColor = "textMain",
  width = "1rem",
}) => {
  return (
    <Flex alignItems="center">
      <Flex alignItems="center">
        <StarIcon width={width} color={fillColor} />
      </Flex>

      <Text textScale="p16Medium" mx="0.25rem" mt={{ _: "0", tablet: "0.25rem" }} color={textColor}>
        {rating.toFixed(1)}
      </Text>
    </Flex>
  );
};

export default RatingStars;
