import styled, { css } from "styled-components";
import { clearfix } from "styles";
// Components + styling
import { Box } from "components/layout";
// Types
import { TagProps } from "./types";
import { Text } from "components/text";

export const StyledTag = styled(Box)<TagProps>`
  border-radius: ${({ theme }) => theme.radii.semiLarge};
  position: relative;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;

      ${({ theme }) => theme.mediaQueries.laptop} {
        &:hover:before {
          opacity: 1;
        }
      }
    `}

  &::before {
    ${clearfix}
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    opacity: 0;
    transition: ${({ theme }) => theme.transitions.default};
    background: ${({ theme }) => theme.gradients.buttonAccentHover};
    z-index: ${({ theme }) => theme.zIndices.buttonGradient};
  }

  ${({ selected }) =>
    selected &&
    css`
      &::before {
        opacity: 1;
      }
    `}
`;

export const StyledTagContent = styled.div<TagProps>`
  padding: 0.25rem 0.75rem;
  background: ${({ theme }) => theme.colors.bgSixth};
  border-radius: ${({ theme }) => theme.radii.semiLarge};
  z-index: ${({ theme }) => theme.zIndices.buttonContent};
  transition: ${({ theme }) => theme.transitions.default};
  position: relative;

  ${({ onClick, theme, isShowMore }) =>
    onClick &&
    css`
      ${({ theme }) => theme.mediaQueries.laptop} {
        &:hover {
          background: transparent;
        }
        &:hover > p {
          color: ${isShowMore ? theme.colors.white : theme.colors.textMain};
        }
      }
    `}

  ${({ selected }) =>
    selected &&
    css`
      background: transparent;
    `}
`;

export const StyledTagText = styled(Text)<{ isShowMore: boolean }>`
  color: ${({ theme, isShowMore }) => (isShowMore ? theme.colors.textAccentSecond : theme.colors.textMain)};

  ${({ isShowMore }) =>
    isShowMore &&
    css`
      background-color: ${({ theme }) => theme.colors.showMore};
      background-image: ${({ theme }) => theme.gradients.blogShowMoreGradient};
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      -webkit-box-decoration-break: clone;
    `}
`;
