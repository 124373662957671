// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 80 80" {...props} color="transparent">
      <path
        d="M19.4342 73.8381C19.7105 71.7521 21.8386 68.2605 20.0855 67.0787C12.7024 62.1025 4.32129 55.533 4.71659 45.6942C4.87616 41.7064 6.04684 36.7732 8.89447 33.801C10.8217 31.7905 14.4091 31.4143 16.2732 33.8326C18.2432 36.3905 16.6032 40.5042 16.4117 43.3635C16.2696 45.4977 16.8963 47.3509 19.2789 47.6537C20.0014 47.7458 20.7434 47.7203 21.4658 47.6362C22.4631 47.5204 23.4162 47.2342 24.2764 46.7149C27.14 44.9849 28.6857 40.3403 28.7698 37.1861C28.8228 35.1799 28.0967 33.1191 26.0172 32.4955C22.7859 31.5273 19.5596 35.5132 16.4937 33.7005C15.9867 33.4006 15.5239 33.0176 15.175 32.5422C12.7924 29.2952 15.5805 25.8698 18.2548 23.955C20.821 22.1172 24.1618 20.4614 27.3816 20.3563C34.5731 20.1233 38.8446 27.3574 39.7998 33.5765C40.3177 27.5257 38.2541 21.459 37.8117 15.4564C37.6173 12.8122 37.3373 9.78837 38.6545 7.35638C39.8034 5.23656 42.1252 4.6811 44.1264 6.17871C46.1914 7.7238 47.4738 10.3513 48.3615 12.6891C51.0075 19.6549 51.3484 27.2332 49.7302 34.4198C51.7996 29.5141 53.8051 24.5678 55.8229 19.6405C56.7586 17.3582 57.5072 14.622 59.0768 13.2805C61.2513 11.4204 64.0503 12.0699 65.5031 14.5119C66.7659 16.6346 66.8457 19.1388 66.5846 21.5319C66.0609 26.3384 63.9582 30.9267 61.6995 35.1462C59.1892 39.8368 56.52 44.5391 52.8803 48.4479C55.968 45.959 58.907 43.4263 61.7467 40.6576C64.4362 38.035 67.6232 33.0294 71.5335 32.0389C74.4108 31.3102 75.7491 33.7177 75.8122 36.3857C75.8927 39.77 73.597 42.9724 71.4892 45.4361C67.2192 50.4281 62.155 54.7387 56.6694 58.3655C54.8205 59.5883 52.9195 60.7334 50.9734 61.798C50.1197 62.2648 48.606 62.7015 47.9481 63.3978C47.1938 64.1963 46.9015 65.7184 46.5751 66.7204C45.7301 69.3107 44.8858 71.9821 44.4034 74.6673"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
