// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 80 80" {...props} color="transparent">
      <path
        d="M63.5975 15.1797C65.3283 28.5747 63.6725 42.3037 54.6009 53.1335C46.4128 62.9085 34.5004 65.6272 27.7568 52.8002C24.5644 46.7283 24.8381 37.3952 30.5935 32.8754C37.0055 27.8401 42.3506 37.0961 42.541 42.5034C42.9859 55.1329 33.6181 67.8281 19.8634 69.4216C3.57469 71.3087 4.24794 54.4529 4.3522 54.4941"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.7553 24.0531C74.29 23.7624 73.106 22.8981 71.8802 22.0963C67.855 19.464 64.3614 15.3422 63.23 10.5586C62.7728 15.8639 60.3911 22.5976 55.375 25.077"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
