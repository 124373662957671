import styled from "styled-components";

export const StyledFooterContentItemFlexGap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    gap: 0.75rem;
  }
`;
