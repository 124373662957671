// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  return (
    <Svg viewBox="0 0 48 48" {...props} fill={color}>
      <path d="m37 33v-9a1 1 0 0 0 -2 0v9a2 2 0 0 1 -2 2h-18a2 2 0 0 1 -2-2v-18a2 2 0 0 1 2-2h9a1 1 0 0 0 0-2h-9a4 4 0 0 0 -4 4v18a4 4 0 0 0 4 4h18a4 4 0 0 0 4-4z" />
      <path d="m37 12a1 1 0 0 0 -1-1h-7a1 1 0 0 0 0 2h4.59l-10.79 10.75a1 1 0 0 0 0 1.42 1 1 0 0 0 .71.29 1 1 0 0 0 .71-.29l10.78-10.77v4.6a1 1 0 1 0 2 0z" />
    </Svg>
  );
};

export default Icon;
