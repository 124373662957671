import { StyledKeyWordText } from "components/text/components/keyword-text/styled";

import { Gradients } from "theme/types";

export const parseAndFormatText = (text: string, gradient?: keyof Gradients) => {
  const parts = text.split(/(%.*?%)/);

  const formattedText = parts.map(part => {
    if (part.startsWith("%") && part.endsWith("%")) {
      const value = part.slice(1, -1);

      return (
        <StyledKeyWordText
          as="span"
          fontSize="inherit"
          $fontWeight="bold"
          color="textAccent"
          key={value}
          gradient={gradient}
        >
          {value}
        </StyledKeyWordText>
      );
    } else {
      return part;
    }
  });

  return formattedText;
};
