import React from "react";
import { BlurContextProviderProps, BlurContextType } from "./types";

export const BlurContext = React.createContext<BlurContextType | null>(null);

const BlurContextProvider: React.FC<BlurContextProviderProps> = ({ children }) => {
  const [isVisible, setIsVisible] = React.useState(false);

  return <BlurContext.Provider value={{ isVisible, setIsVisible }}>{children}</BlurContext.Provider>;
};

export const useBlur = () => {
  const blurContext = React.useContext(BlurContext);

  if (blurContext === null) {
    throw new Error("Blur context is not found");
  }

  return blurContext;
};

export default BlurContextProvider;
