// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 80 80" {...props} color="transparent">
      <path
        d="M59.4229 14.8071C49.0221 6.62153 34.0169 7.58061 23.9984 13.697C6.73211 24.239 4.4923 50.5546 16.8253 62.4019C30.4242 75.4642 48.9851 72.3161 58.2349 63.959C70.2232 53.1275 75.3477 35.2935 65.0282 22.0097"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.8693 31.8887C31.5996 33.5503 31.4882 35.2613 31.4453 36.9448"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.7514 31.4219C46.7631 33.4288 46.8878 35.5238 46.6875 37.5284"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.9766 45.9766C24.4746 47.8447 25.5871 49.4796 26.9482 50.8243C33.6234 57.4199 46.0884 55.3591 50.8462 47.3983"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
