import { Language } from "context/language-context/types";

// TODO: uncomment DE when we will used translations
export const EN: Language = { locale: "en-US", language: "English", code: "en" };
// export const DE: Language = { locale: "de-DE", language: "German", code: "de" };

export const languages = {
  "en-US": EN,
  // "de-DE": DE,
};

export const languageList = Object.values(languages);
