export const SearchEngines = {
  GOOGLE: "google",
  BING: "bing",
  YAHOO: "yahoo",
  YANDEX: "yandex",
  YAM: "Yam",
  CN360: "360.cn",
  ALICE: "alice",
  ALLTHEWEB: "alltheweb",
  ALTAVISTA: "altavista",
  AOL: "aol",
  ASK: "ask",
  AUONE: "auone",
  AVG: "avg",
  BABYLON: "babylon",
  BAIDU: "baidu",
  BIGLOBE: "biglobe",
  CENTRUM: "centrum.cz",
  COMCAST: "comcast",
  CONDUIT: "conduit",
  CNN: "cnn",
  DAUM: "daum",
  DUCKDUCKGO: "duckduckgo",
  ECOSIA: "ecosia",
  EKOLAY: "ekolay",
  ENIRO: "eniro",
  GLOBO: "globo",
  GO_MAILRU: "go.mail.ru",
  HAOSOU: "haosou.com",
  INCREDIMAIL: "incredimail",
  KVASIR: "kvasir",
  LIVE: "live",
  LYCOS: "lycos",
  MAMMA: "mamma",
  MSN: "msn",
  MYNET: "mynet",
  NAJDI: "najdi",
  NAVER: "naver",
  NETSCAPE: "netscape",
  ONET: "onet",
  OZU: "ozu",
  QWANT: "qwant",
  RAKUTEN: "rakuten",
  RAMBLER: "rambler",
  SEARCH_RESULTS: "search-results",
  SESAM: "sesam",
  SEZNAM: "seznam",
  SO: "so.com",
  SOGOU: "sogou",
  STARTSIDEN: "startsiden",
  SZUKACZ: "szukacz",
  TERRA: "terra",
  TUT_BY: "tut.by",
  UKR: "ukr",
  VIRGILIO: "virgilio",
  VOILA: "voila",
  WIRTUALNA_POLSKA: "wirtualna polska",
  YANDEX_MOBILE: "yandex mobile",
};

export const UtmDefault = {
  direct: "(direct)",
  none: "none",
  organic: "organic",
  referral: "referral",
  cpc: "cpc",
};
