// Components + styling
import { Flex } from "components";
import { ArrowLeftIcon } from "components/svg";
import { StyledArrowRightIcon, StyledIconButton } from "./styled";
// Types
import { SliderNavButtonsProps } from "./types";
// Hooks
import { useMatchBreakpoints } from "hooks";

const SliderNavButtons: React.FC<SliderNavButtonsProps> = ({ onClickLeftBtn, onClickRightBtn, ...props }) => {
  const { isLaptopL } = useMatchBreakpoints();

  return (
    <Flex>
      <StyledIconButton
        variant="primary"
        filledType="bordered"
        Icon={ArrowLeftIcon}
        hoverLinesPosition="bottomLeft"
        mx={{ _: "0.5rem", tablet: "1rem" }}
        onClick={onClickLeftBtn}
        ariaLabel="back"
        {...props}
      />
      <StyledIconButton
        variant="primary"
        filledType="bordered"
        Icon={StyledArrowRightIcon}
        mx={{ _: "0.5rem", tablet: "0" }}
        onClick={onClickRightBtn}
        hoverLinesPosition={isLaptopL ? "topRight" : "topRightMobile"}
        ariaLabel="next"
        {...props}
      />
    </Flex>
  );
};

export default SliderNavButtons;
