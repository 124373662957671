import { Link as RouterLink } from "react-router-dom";
// Components + styling
import { StyledLink } from "./styled";
// Helpers
import { getExternalLinkProps } from "components/button";
// Types
import { LinkProps } from "./types";

const Link: React.FC<LinkProps> = ({ external, href, disabled, ...props }) => {
  const internalProps = external ? getExternalLinkProps() : {};
  const ariaLabel = props.children && typeof props.children === "string" ? props.children : href || "link";
  const DOMAIN = process.env.REACT_APP_URL ?? "";

  if (external) {
    return (
      <StyledLink
        as={disabled ? "span" : "a"}
        href={href}
        {...internalProps}
        disabled={disabled}
        aria-label={ariaLabel}
        {...props}
      />
    );
  } else {
    return (
      <RouterLink to={DOMAIN + (href ?? "/")} aria-label={ariaLabel}>
        <StyledLink as="span" disabled={disabled} {...internalProps} {...props} />
      </RouterLink>
    );
  }
};

Link.defaultProps = {
  underline: false,
};

export default Link;
