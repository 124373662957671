import { lazyLoad } from "utils";

export const MVPDevelopmentPage = lazyLoad(
  () => import("./mvp-development-page"),
  module => module.default,
);

export const AiSoftwareDevelopmentPage = lazyLoad(
  () => import("./ai-software-development-page"),
  module => module.default,
);

export const DataSciencePage = lazyLoad(
  () => import("./data-science"),
  module => module.default,
);

export const AiMobileDevelopmentPage = lazyLoad(
  () => import("./ai-mobile-development-page"),
  module => module.default,
);

export const AiConsultingPage = lazyLoad(
  () => import("./ai-consulting-page"),
  module => module.default,
);

export const MlDevelopmentPage = lazyLoad(
  () => import("./ml-development"),
  module => module.default,
);

export const MlConsultingPage = lazyLoad(
  () => import("./ml-consulting"),
  module => module.default,
);

export const CustomerExperienceConsultingPage = lazyLoad(
  () => import("./customer-experience-consulting"),
  module => module.default,
);

export const AiMlSolutionsMainPage = lazyLoad(
  () => import("./ai-ml-solution-main-page"),
  module => module.default,
);

export const DefaultForOldExpertisePage = lazyLoad(
  () => import("./default-for-old-expertise-page"),
  module => module.default,
);

export const DefaultPageServices = lazyLoad(
  () => import("./default-page-services"),
  module => module.default,
);
