import { TranslateFunction } from "context/language-context/types";
import { IndustriesVariants } from "pages/industries/types";

import { getDefaultMeta } from "./get-default-meta";
import { capitalizeFirstLetter } from "utils/capitalize-first-letter";

export const getMetaForIndustries = (path: string, t: TranslateFunction) => {
  const pathSplit = path.split("/");
  const industryType = pathSplit[pathSplit.length - 1];

  switch (industryType) {
    case IndustriesVariants.fintech:
      return {
        ...getDefaultMeta(t),
        title: t("Financial software development"),
        description: t(
          "Transform your financial services with an advanced strategy by Idealogic. We focus on crafting and delivering tailored FinTech software solutions to drive innovation and enhance your business efficiency.",
        ),
        keywords: t(
          "FinTech software development, Financial services software, Custom financial software, Financial technology solutions, Business efficiency software, Financial innovation solutions, Tailored FinTech solutions, Advanced financial strategies, Financial software solutions, Idealogic financial services",
        ),
      };

    case IndustriesVariants.logistics:
      return {
        ...getDefaultMeta(t),
        title: t("Logistics software development"),
        description: t(
          "Enhance supply chain, transportation operations, and tracking capabilities with custom-made logistics software development by Idealogic.",
        ),
        keywords:
          "Custom logistics software development, Supply chain software solutions, Transportation management software, Logistics tracking software, Idealogic logistics solutions, Custom-made supply chain software, Transportation operations software, Supply chain enhancement software, Logistics software solutions, Advanced logistics software",
      };

    case IndustriesVariants.aviation:
      return {
        ...getDefaultMeta(t),
        title: t("Aviation Industry"),
        description: t(
          "Idealogic creates specialized software solutions for the aviation industry, addressing flight management, aircraft maintenance, passenger services, and aviation analytics.",
        ),
        keywords:
          "Aviation software solutions, Flight management software, Aircraft maintenance software, Passenger service software, Aviation analytics solutions, Custom aviation software, Idealogic aviation services, Advanced aviation technology, Airline management softwareAviation industry software",
      };

    case IndustriesVariants.media:
      return {
        ...getDefaultMeta(t),
        title: t("Media software development"),
        description: t(
          "Idealogic delivers software applications and tools for the media and entertainment industry, changing the content creation and distribution. We present video editing software, content management systems, and streaming platforms.",
        ),
        keywords:
          "Media software development, Entertainment industry software, Video editing software, Content management systems, Streaming platform development, Idealogic media solutions, Custom media applications, Content creation tools, Digital content distribution, Media and entertainment technology",
      };

    case IndustriesVariants.realEstate:
      return {
        ...getDefaultMeta(t),
        title: t("Real Estate software development"),
        description: t(
          "Idealogic designs and builds software for the real estate industry, improving operational efficiency for property management, marketing, and transactions.",
        ),
        keywords:
          "Real estate software development, Property management software, Real estate marketing tools, Real estate transaction software, Idealogic real estate solutions, Custom real estate software, Property management solutions, Real estate industry software, Real estate operational efficiency, Real estate technology solutions",
      };
    case IndustriesVariants.banking:
      return {
        ...getDefaultMeta(t),
        title: t("Cutting-Edge Banking IT Solutions by Idealogic | Get a Free Estimation"),
        description: t(
          "Idealogic offers custom banking IT solutions for startups and scaling companies. Explore our tailored services for various industries, including retail & commercial banks, neo banking, consumer lending, and more. Get a free estimation today!",
        ),
        keywords:
          "Mobile Banking Solutions, Online Banking Services, Integration Solutions, Credit Scoring Services, Cloud Banking Solutions, Blockchain Consulting Services, Financial Technology Consulting Services, Legacy Modernization",
      };
    case IndustriesVariants.other:
      return {
        ...getDefaultMeta(t),
        title: t("Software development for other industries"),
        description: t(
          "Custom software development tailors specialized solutions to meet the specific business needs of different industries, like healthcare, gaming, etc.",
        ),
        keywords:
          "Custom software development, Industry-specific software solutions, Healthcare software development, Gaming software development, Business software solutions, Specialized software services, Custom application development, Software development services, Enterprise software solutions, Technology solutions for businesses",
      };
    case IndustriesVariants.digitalBanking:
      return {
        ...getDefaultMeta(t),
        title: t("Comprehensive Digital Banking Software Development"),
        description: t(
          "Custom software development tailors specialized solutions to meet the specific business needs of different industries, like healthcare, gaming, etc.",
        ),
        keywords: "digital banking solutions, banking, financial data, support and maintenance services",
      };
    case IndustriesVariants.sparePartsMarketplace:
      return {
        ...getDefaultMeta(t),
        title: t("Spare Parts Marketplace Development Solutions"),
        description: t(
          "Maximize your aviation business efficiency with our customized spare parts marketplace solutions. We specialize in developing, optimizing, and integrating e-commerce platforms tailored to streamline inventory management effortlessly.",
        ),
      };
    case IndustriesVariants.blockchainBasedAviation:
      return {
        ...getDefaultMeta(t),
        title: t("Blockchain-based Aviation Supply Chain Solutions"),
        description: t(
          "Elevate your aviation supply chain traceability and transparency with innovative blockchain solutions. Stay ahead of the curve in the aviation industry by incorporating cutting-edge blockchain technology into your operations. Companies like Boeing and Honeywell are already reaping the benefits – it's time for you to join them.",
        ),
      };
    case IndustriesVariants.aiBasedAviation:
      return {
        ...getDefaultMeta(t),
        title: t("AI-Based Customer Support Solutions for Enhanced Engagement"),
        description: t(
          "Elevate your customer service with Idealogic's AI-based support solutions. Experience 24/7 automated assistance across multiple channels including WhatsApp, Viber, and more. Tailored for the aviation industry, our tools enhance interaction, streamline operations, and boost customer satisfaction. Request a free estimate today!",
        ),
        keywords:
          "AI-based customer support, AI-based Customer Support, Engagement Solutions, Transformative capabilities of AI, Elevate customer service, Operational efficiency, Tailored AI tools, Seamless integration, Exceptional customer experiences, AI-driven solutions, Aviation Companies/Airlines, 24/7 automated assistance solutions, Personalized, real-time interaction, Online Aviation Marketplaces, AI-driven customer engagement tools, Real-time analytics, Predictive stock management, Spare Parts Trading Companies, Intelligent automation, Multilingual capabilities, Support solutions, Customized solutions, Operational capacity, Customer service automation,Custom Chatbot Integration, Blockchain Software Services, AI Software Development, Machine Learning Development.",
      };
    case IndustriesVariants.customRealEstate:
      return {
        ...getDefaultMeta(t),
        title: t("Tailored Real Estate Software Solutions | Streamlined Workflow by Idealogic"),
        description: t(
          "Elevate your real estate business with our tailored software solutions. Explore our streamlined workflow for transparent, efficient, and exceptional results at every stage of development. Schedule a call today!",
        ),
        keywords:
          "Custom Real Estate Software Solutions, real estate software solutions, real estate tech solutions, custom real estate software solutions, property management software, real estate software development, real estate tech companies",
      };
    case IndustriesVariants.propertyManagementSolutions:
      return {
        ...getDefaultMeta(t),
        title: t("Advanced Property Management Software Solutions"),
        description: t(
          "Discover Idealogic’s advanced property management software solutions, tailored for landlords, property managers, and real estate investment firms. Enhance operational efficiency, streamline processes, and drive growth with our innovative technology. Explore custom software development, AI integration, and more to transform your real estate operations. Schedule a free consultation today!",
        ),
        keywords:
          "Property Management Software Solutions, Tenant and Lease Management Software, Custom Property Management Systems, Real Estate Investment Technology, Legacy System Modernization, Custom Software Development, Digital Transformation Enablement, Tenant Management, Rent Collection, Maintenance Requests, Financial Reporting, AI/ML Integration, Agile Methodologies, White-Label Property Management Platforms, Cutting-Edge Technologies, Operational Efficiency, Client Satisfaction, Revenue Growth",
      };
    case IndustriesVariants.agentBrokersSolutions:
      return {
        ...getDefaultMeta(t),
        title: t("Real Estate Software Solutions for Agents & Brokers"),
        description: t(
          "Discover comprehensive real estate software solutions tailored for agents, brokers, and property managers. Streamline operations, enhance client satisfaction, and drive innovation with cutting-edge technologies from Idealogic. Contact us for a free consultation!",
        ),
        keywords:
          "Real Estate Software Solutions, Real Estate Software Solutions, Client Management, Marketing Automation, Property Management, Lead Management, Transaction Processing, CRM, AI-Powered Lead Generation, Marketing Automation Tools, Property Listing Management, Data-Driven Insights, Digital Transformation, White-Label Solutions, AI-Driven Analytics, Virtual Reality Tours, Blockchain Security, Real Estate Case Studies, Cutting-Edge Technology",
      };

    default: {
      return {
        ...getDefaultMeta(t),
        title: t(`${capitalizeFirstLetter(industryType)} Industry`),
      };
    }
  }
};
