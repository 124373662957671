import styled from "styled-components";
import { space, typography, layout, opacity, border, shadow, variant } from "styled-system";
import { clearfix } from "styles";
// Components
import { Svg } from "components/svg";
// Theme
import { getFontSettings } from "components/text";
import { hoverLinesPositions, scaleVariants, variantStyles } from "./theme";
// Animations
import { scaleAnimation } from "theme/animations";
// Types
import { ButtonProps, StyledButtonHoverLinesProps, scalesToTextScale } from "./types";

export const StyledButtonContent = styled.div<ButtonProps>`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: sticky;
  position: -webkit-sticky;

  padding: ${({ filledType, scale }) => scale && filledType === "bordered" && scaleVariants[scale].padding};
  border-radius: inherit;
  transition: ${({ theme }) => theme.transitions.default};
  z-index: ${({ theme }) => theme.zIndices.buttonContent};
`;

export const StyledButtonHoverLines = styled.div<StyledButtonHoverLinesProps>`
  display: none;
  position: absolute;
  ${variant({
    prop: "hoverLinesPosition",
    variants: hoverLinesPositions,
  })};
`;

export const StyledButton = styled.button<ButtonProps>`
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-family: ${({ theme }) => theme.fonts.ubuntu};
  line-height: 1.5;
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.bgSecond)};

  width: fit-content;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 0;
  outline: 0;
  cursor: pointer;
  ${scaleAnimation}

  ${variant({
    prop: "scale",
    variants: scaleVariants,
  })};
  padding: ${({ filledType }) => filledType === "bordered" && "2px"};

  ${({ variant, filledType, buttonTheme, bgColor, bg, contentBg, color }) =>
    variantStyles(variant, filledType, buttonTheme, bgColor, bg, contentBg, color)};

  ${({ theme, scale }) => scale && getFontSettings({ theme, textScale: scalesToTextScale[scale] })};

  /* ::before need for transition background gradient */
  ::before {
    ${clearfix}
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    opacity: 0;
    transition: ${({ theme }) => theme.transitions.default};
    background: ${({ theme, hoverBg }) => (hoverBg ? theme.gradients[hoverBg] : theme.gradients.buttonAccentHover)};
    box-shadow: ${({ theme, hoverBg, filledType }) =>
      (!hoverBg || hoverBg === "buttonAccentHover") && filledType !== "bordered" ? theme.shadows.button : "none"};
  }

  /* ::after need for transition background gradient */
  ::after {
    ${clearfix}
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    opacity: 0;
    background: ${({ hoverBg }) =>
      !hoverBg || hoverBg === "buttonAccentHover"
        ? "linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0.26%, rgba(255, 255, 255, 0) 98.2%);"
        : "none"};
    transition: ${({ theme }) => theme.transitions.default};
  }

  &:not([disabled], :active):hover {
    color: ${({ theme, hoverBg }) => !hoverBg && theme.colors.white};

    &::before,
    &::after,
    & > span {
      opacity: 1;
    }

    ${StyledButtonContent} {
      background: ${({ theme, hoverContentBg }) =>
        hoverContentBg ? theme.gradients[hoverContentBg] : theme.colors.transparent};
      color: ${({ theme, hoverBg }) => !hoverBg && theme.colors.textMain};
    }

    ${Svg} {
      fill: ${({ theme, hoverBg }) => !hoverBg && theme.colors.white};
    }

    ${StyledButtonHoverLines} {
      display: ${({ scale, isShowHoverLines }) => (!isShowHoverLines || scale === "sm" ? "none" : "block")};
    }
  }

  &:disabled {
    background: ${({ theme, buttonTheme }) =>
      buttonTheme === "light" ? theme.colors.disabledLight : theme.colors.disabledFillDark};
    color: ${({ theme, filledType, buttonTheme }) => {
      if (filledType === "bordered") {
        return buttonTheme === "light" ? theme.colors.disabledLight : theme.colors.disabledTextDark;
      } else {
        return buttonTheme === "light" ? theme.colors.white : theme.colors.disabledLight;
      }
    }};
    cursor: not-allowed;
  }

  ${space}
  ${typography}
  ${layout}
  ${opacity}
  ${border}
  ${shadow}
`;

export const StyledButtonGradientLayer = styled.span<ButtonProps>`
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  background: ${({ theme, hoverBg }) =>
    !hoverBg || hoverBg === "buttonAccentHover" ? theme.gradients.buttonAccentHover : "none"};
  box-shadow: ${({ theme, hoverBg }) => (!hoverBg || hoverBg === "buttonAccentHover" ? theme.shadows.button : "none")};
  z-index: ${({ theme }) => theme.zIndices.buttonGradient};
  border-radius: inherit;
  opacity: 0;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: ${({ theme }) => theme.transitions.default};
`;
