import React from "react";
// Constants
import { BLOCK_ID } from "configs";
import { MOBILE_MENU_ANIMATION_DURATION } from "components/header/constants";
// Types
import { UseElementDimensionsProps, BlockIdType } from "./types";

const useElementDimensions = ({ id }: UseElementDimensionsProps) => {
  const initialState = { offsetWidth: 0, offsetHeight: 0 };
  const [elementDimensions, setElementDimensions] = React.useState(initialState);

  React.useEffect(() => {
    if (!BLOCK_ID[id as BlockIdType]) {
      console.error(`ID "${id}" is not defined in the configuration.`);
    }

    updateElementDimensions();
    window.addEventListener("resize", () => handleResize());

    return () => {
      window.removeEventListener("resize", () => handleResize());
    };
  }, [id]);

  const handleResize = (timeOutDuration?: number) => {
    setTimeout(() => {
      updateElementDimensions();
    }, timeOutDuration ?? MOBILE_MENU_ANIMATION_DURATION);
  };

  const updateElementDimensions = () => {
    const element = document.getElementById(BLOCK_ID[id as BlockIdType]);

    const { offsetWidth, offsetHeight } = element ?? initialState;

    if (!element) {
      console.error(`Element with ID "${id}" not found.`);
      return;
    }

    setElementDimensions({ offsetWidth, offsetHeight });
  };

  return { elementDimensions, handleResize };
};

export default useElementDimensions;
