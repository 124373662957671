// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { ROUTES } from "navigation/routes";
// Types
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { ServicesPageContent, ServicesVariants } from "pages/services/types";

const WEB_DEVELOPMENT_CONTENT: ServicesPageContent = {
  banner: {
    title: "Web Development",
    description:
      "We put all our efforts into creating and maintaining websites, web applications, and services that meet your expectations and satisfy your business needs.",
    imageSrc: "/images/expertise/expertise-banner-web-development.webp",
    imageMobileSrc: "/images/expertise/expertise-banner-web-development-mobile.webp",
    imageRatio: 1.69,
  },
  customizedApplications: {
    title: "Get customized solutions with our web development expertise",
    description:
      "Idealogic provides various comprehensive web development services and supports you along the process. Check the most popular requests.",
    customizedApplicationsList: [
      {
        label: "Custom Web Application",
        description:
          "Tailored specifically to your objectives, custom web applications drive your business growth and provide a personalized and seamless user experience.",
        iconSrc: "/images/expertise/web-development/web-development-custom-web-application-icon.svg",
      },
      {
        label: "Software as a Service",
        description:
          "We craft and deliver powerful tools and functionalities to the end users, ensuring your business scalability and flexibility.",
        iconSrc: "/images/expertise/web-development/web-development-software-as-a-service-icon.svg",
      },
      {
        label: "E-Commerce Solutions",
        description:
          "Enhance your business online presence and efficiency. We guarantee seamless transactions, unique customer experiences, and easy management.",
        iconSrc: "/images/expertise/web-development/web-development-e-commerce-solutions-icon.svg",
      },
      {
        label: "Content Management Systems",
        description:
          "We offer user-friendly interfaces for content creation, publication, and maintenance, easy to use even for non-technical users.",
        iconSrc: "/images/expertise/web-development/web-development-content-management-systems-icon.svg",
      },
      {
        label: "Progressive Web Apps",
        description:
          "We combine the best of both worlds — web and mobile — ensuring an engaging experience, including push notifications and offline functionality.",
        iconSrc: "/images/expertise/web-development/web-development-progressive-web-apps-icon.svg",
      },
      {
        label: "Business Intelligent & Enterprise Resource Planning",
        description:
          "We help you integrate Business Intelligence systems and develop Enterprise Resource Planning to streamline data management, decision-making, and overall organization efficiency.",
        iconSrc: "/images/expertise/web-development/web-development-business-intelligent-icon.svg",
      },
    ],
  },
  chooseOurExperience: {
    title: "Why choose our expertise of web development?",
    description:
      "We focus on continuous improvement and learning. That, combined with years of experience, makes our services stand out.",
    imageSrc: "/images/expertise/expertise-banner-web-development-choose-our-experience.webp",
    imageRatio: 1.46,
    chooseOurExperienceList: [
      {
        label: "Adaptability and Innovation",
        description:
          "Our team follows the market trends to incorporate the best features and technologies into our projects.",
        iconSrc: "/images/expertise/choose-out-experience/web-development-lightbulb-icon.svg",
      },
      {
        label: "Training and Certifications",
        description:
          "We value education and encourage our employees to take the necessary courses by providing compensation.",
        iconSrc: "/images/expertise/choose-out-experience/web-development-square-academic-cap-icon.svg",
      },
      {
        label: "Various Complexity",
        description:
          "Our team does not hesitate to take on projects that require a unique and complex approach and specific knowledge.",
        iconSrc: "/images/expertise/choose-out-experience/web-development-fire-icon.svg",
      },
      {
        label: "Security and Data Protection",
        description:
          "At Idealogic, we make a great deal of complying with all the necessary security requirements and guidelines.",
        iconSrc: "/images/expertise/choose-out-experience/web-development-shield-icon.svg",
      },
    ],
  },
  digitalExcellence: {
    title: "Crafting Digital Excellence",
    description:
      "With every completed project, we strive to give an unforgettable experience with our unique features and appealing designs.",
    digitalExcellenceList: {
      digitalExcellenceListFirst: [
        {
          label: "50+",
          description: "Projects Completed",
          isBigBorder: false,
        },
        {
          label: "99.9%",
          description: "Security Protocols",
          isBigBorder: true,
        },
      ],
      digitalExcellenceListSecond: [
        {
          label: "90%+",
          description: "Code Quality Metrics",
          isBigBorder: true,
        },

        {
          label: "5+",
          description: "Experience years",
          isBigBorder: false,
        },
      ],
    },
  },
  portfolio: {
    portfolioList: [
      CaseStudiesSlugEnums.eState,
      CaseStudiesSlugEnums.eplane,
      CaseStudiesSlugEnums.seedbox,
      CaseStudiesSlugEnums.aiBook,
    ],
    title: "Web development case studies",
    TitleIcon: WavingHandIcon,
    description:
      "View the successful implementation of various innovative web solutions for the real estate industry, aviation, financial services, and more.",
  },
  developmentProcess: {
    title: "Our web development process",
    description:
      "We’ve enhanced our workflow with the years passing. Learn more about our step-by-step approach to web development.",
    benefitsList: [
      {
        title: "Discover & Estimate",
        description:
          "We start by closely learning your business requirements, defining what technical solutions suit you best, and providing you with a detailed plan of work scope.",
      },
      {
        title: "Design and Prototype",
        description:
          "We present you with a vision of your future product with basic features so that you can make adjustments and define the course of future development.",
      },
      {
        title: "Development",
        description:
          "Our team of relevant specialists works on the technical implementation of the project, using cutting-edge technologies.",
      },
      {
        title: "Testing",
        description:
          "We do automation and manual testing to ensure every feature works properly and the product can perform to its full potential.",
      },
      {
        title: "Deployment",
        description:
          "We are ready to release the product only after we are satisfied with its usability, security compliance, and reliability.",
      },
      {
        title: "Maintenance",
        description:
          "Our team is keeping up with the product’s performance after the launch to forecast and prevent any technical issues and urgently react if something goes wrong.",
      },
    ],
  },
  boostBusiness: {
    title: "Our expert will be happy to discuss the details of your project!",
    description: "We will take your project and create something incredible for you.",
    buttonTitle: "Let’s talk!",
    personName: "Evgeniy Ochkalov",
    personPosition: "Head of front end department",
    personImageSrc: "/images/expertise/expertise-banner-web-development-evgen-ochkalov.webp",
    imageRatio: 0.99,
    bgColor: "bgDark",
  },
  coveredIndustries: {
    coveredIndustriesList: [
      { label: "Mobile Development", link: `/${ROUTES.services}/${ServicesVariants.mobileDevelopment}` },
      { label: "UI/UX Design", link: `/${ROUTES.services}/${ServicesVariants.uiUxDesign}` },
      { label: "AI/ML Solutions", link: `/${ROUTES.services}/${ServicesVariants.aiMlSolutions}` },
      { label: "Blockchain", link: `/${ROUTES.services}/${ServicesVariants.blockchain}` },
      { label: "Big Data", link: `/${ROUTES.services}/${ServicesVariants.bigData}` },
      { label: "Cloud Solutions", link: `/${ROUTES.services}/${ServicesVariants.cloudSolutions}` },
    ],
  },
};

export default WEB_DEVELOPMENT_CONTENT;
