// Constants
import { ROUTES } from "navigation/routes";
import { EXTERNAL_LINKS } from "configs";
// Types
import { ServicesVariants } from "pages/services/types";
import { BenefitsItemProps } from "pages/services/components/benefits/components/benefits-item/types";
import { WhoWeServeItemType } from "../who-we-serve/types";
import { IndustryInfoTabItem } from "./types";
import { ServicesListItemType } from "../our-services-fintech/types";
import { AccordionContentItemType } from "../our-capabilities/types";

export const BANKING_INDUSTRY_WHO_WE_SERVE_LIST: WhoWeServeItemType[] = [
  { text: "Retail & Commercial Banks" },
  { text: "Neo Banking Solutions" },
  { text: "Consumer Lending" },
  { text: "Payment Gateway Integration" },
];

export const CRYPTO_CURRENCY_INDUSTRY_WHO_WE_SERVE_LIST: WhoWeServeItemType[] = [
  {
    text: "Exchange Platform Development",
    subText: "Captivate users with intuitive and secure exchange platforms designed to attract and retain customers.",
  },
  {
    text: "Cryptocurrency Wallet Development",
    subText:
      "Manage your finances with ease using our advanced wallet development solutions powered by the latest technology.",
  },
  {
    text: "Hardware Wallet Solutions",
    subText:
      "Enhance security with our robust hardware wallet solutions, offering additional layers of protection for your crypto assets.",
  },
  {
    text: "DeFi and NFT Development",
    subText:
      "Explore the possibilities of decentralized finance (DeFi) and non-fungible token (NFT) development with our expert team.",
  },
  {
    text: "Custom Blockchain and Smart Contract Development",
    subText: "Bring your boldest ideas to life with our custom blockchain and smart contract development services.",
  },
  {
    text: "Altcoin Creation and Token Development",
    subText: "Craft unique coins and tokens using specific algorithms tailored to your mining or staking needs.",
  },
  {
    text: "Cryptocurrency Consulting and White Paper Writing",
    subText:
      "Gain insights and expertise from our experienced professionals to navigate the complex world of cryptocurrency.",
  },
];

export const CRYPTO_CURRENCY_INDUSTRY_DEVELOPMENT_SOLUTIONS_LIST: WhoWeServeItemType[] = [
  { text: "Cryptocurrency creation" },
  { text: "Smart contract creation & auditing" },
  { text: "Lightning network development" },
  { text: "Sidechain development" },
  { text: "Consensus & algorithm development" },
  { text: "Chatbot development" },
  { text: "Micro-payment developent" },
  { text: "ICO implementation" },
];

export const BANKING_INDUSTRY_BENEFITS_LIST: BenefitsItemProps[] = [
  {
    title: "Design Thinking Solutions",
    description:
      "Utilizing design thinking principles, we craft user-centered solutions that not only meet but exceed your business objectives. Our design-focused approach ensures that every aspect of your project is tailored to enhance user experience and drive engagement.",
  },
  {
    title: "Cutting-Edge Software Engineering",
    description:
      "Our team of skilled professionals harnesses the latest technology advancements to develop innovative solutions that set your project apart in the competitive market landscape. With expertise in a wide range of programming languages and frameworks, we ensure your project stands out and succeeds.",
  },
  {
    title: "Rigorous Quality Assurance",
    description:
      "We leave no stone unturned when it comes to ensuring the quality and reliability of your product. Our rigorous quality assurance processes involve testing every possible scenario to ensure your product is flawless and operates at its full capacity, delivering a seamless user experience.",
  },
  {
    title: "Seamless Legacy Modernization",
    description:
      "We specialize in legacy modernization services to ensure your software solutions remain up-to-date and compatible with the latest technologies. Our team facilitates the smooth integration of necessary updates, allowing you to maintain your competitive edge and adapt to evolving industry standards seamlessly.",
  },
];

export const CRYPTO_CURRENCY_INDUSTRY_BENEFITS_LIST: BenefitsItemProps[] = [
  {
    title: "Discovery Meeting",
    description:
      "Dive deep into your vision and requirements as we align our strategies with your business goals. Together, we'll explore potential challenges, analyze market trends, and identify key opportunities.",
  },
  {
    title: "Discovery Stage",
    description:
      "Our seasoned team conducts extensive research and analysis, evaluating the competitive landscape, regulatory considerations, and technological feasibilities. We then deliver a comprehensive roadmap tailored to your project's needs.",
  },
  {
    title: "Design Application",
    description:
      "Craft an intuitive, user-friendly interface with a focus on security. Our designers create prototypes and wireframes, providing you with a visual representation of the end product to ensure alignment with your vision.",
  },
  {
    title: "Development",
    description:
      "Our developers work tirelessly to build robust, secure, and scalable cryptocurrency solutions. Leveraging agile methodologies, we regularly update and iterate based on your feedback, ensuring a tailored approach to meet your evolving needs.",
  },
  {
    title: "Security & Quality Assurance",
    description:
      "Prioritize the safety and reliability of your solution with thorough vulnerability assessments and penetration testing. Our commitment to quality assurance guarantees flawless operation, safeguarding your project against potential threats.",
  },
  {
    title: "Maintenance & Support",
    description:
      "Our dedication to your success extends beyond the launch of your cryptocurrency solution. Benefit from ongoing technical support, feature upgrades, and security patches, ensuring the longevity and effectiveness of your project.",
  },
];

export const BANKING_INDUSTRY_ACCORDION_CONTENT_LIST: AccordionContentItemType[] = [
  {
    title: "Mobile Banking Solutions",
    description:
      "Our mobile banking solutions redefine the way people interact with finance, offering seamless, secure, and intuitive experiences. We empower users with instant access to banking services, including transactions, balance checks, loan applications, payment scheduling, etc. Idealogic’s team combines our expertise in design thinking and safety measures, ensuring adaptive, user-friendly interfaces and robust security protocols to provide convenience and confidence.",
  },
  {
    title: "Redefining Banking on the Go",
    actionButtonText: "Explore Mobile Banking Solutions",
    description:
      "Experience finance like never before with our mobile banking solutions. We offer seamless, secure, and intuitive experiences, empowering users with instant access to banking services right at their fingertips. Whether it's transactions, balance checks, loan applications, or payment scheduling, we've got you covered.",
  },
  {
    title: "Banking in the Digital Age",
    actionButtonText: "Discover Online Banking Services",
    description:
      "Bridge the gap between traditional banking and the digital era with our online banking services. Our comprehensive, secure, and user-centric platform provides real-time access to personal and business financial management. Manage accounts, transfer funds, pay bills, and plan finances effortlessly with our intuitive tools.",
  },
  {
    title: "Seamless Connectivity, Streamlined Operations",
    actionButtonText: "Learn More About Integration Solutions",
    description:
      "Achieve connectivity and efficiency with our integration solutions. We specialize in creating seamless bridges between disparate banking systems, applications, and third-party services. Our expertise includes API development, middleware solutions, and custom integrations tailored to meet your unique business needs.",
  },
  {
    title: "Empowering Informed Lending Decisions",
    actionButtonText: "Explore Credit Scoring Solutions",
    description:
      "Leverage advanced algorithms and machine learning for accurate, real-time credit assessments with our credit scoring services. Our comprehensive solutions enhance risk assessment, reduce defaults, and open new lending opportunities. Make informed lending decisions swiftly and fairly with our technology.",
  },
  {
    title: "Agile, Scalable, Secure",
    actionButtonText: "Discover Cloud Banking Solutions",
    description:
      "Stay ahead of market demands with our cloud banking solutions. Designed for agility, scalability, and security, our solutions enable rapid delivery of innovative services. From core banking functions to advanced analytics and compliance management, our cloud solutions ensure your financial services are always secure, efficient, and cost-effective.",
  },
];

export const INTELLIGENT_INVENTORY_MANAGEMENT_ACCORDION_CONTENT_LIST: AccordionContentItemType[] = [
  {
    title: "PAR-Level Configuration",
    description:
      "Maintain optimal stock levels with our PAR-level configuration tool, leveraging historical data, demand forecasts, and real-time sales trends. Set minimum and maximum thresholds to prevent overstocking and stockouts, and strike the perfect balance between inventory investment and sales opportunities.",
  },
  {
    title: "Real-Time Warehouse Analytics",
    description:
      "Unlock unparalleled insights into your warehouse operations with our state-of-the-art analytics platform. Instantly visualize stock levels, monitor order statuses, and optimize logistics operations to improve efficiency and reduce costs.",
  },
  {
    title: "Flexible Data Visualization",
    description:
      "Transform complex inventory data into actionable insights with our customizable data visualization tools. From interactive dashboards to detailed reports, our solutions empower stakeholders to make informed decisions and drive business success.",
  },
  {
    title: "Proactive Inventory Forecasts",
    description:
      "Stay ahead of demand with our advanced forecasting algorithms, predicting future inventory needs based on historical sales data, market trends, and promotional activities. Ensure optimal inventory levels, minimize carrying costs, and enhance customer satisfaction with timely order fulfillment.",
  },
  {
    title: "Automated Replenishment Algorithms",
    description:
      "Revolutionize your restocking processes with our automated replenishment algorithms. Calculate optimal reorder quantities and timings based on real-time sales data and supplier lead times, minimizing manual intervention and ensuring efficient inventory management.",
  },
];

export const LOGISTICS_INDUSTRY_TABS_LIST: IndustryInfoTabItem[] = [
  {
    label: "Supply Chain Management",
    tabTitle: "Optimize your business processes",
    industryTabDescription:
      "Streamline your supply chain from procurement to delivery with our efficient solutions. Our technology-driven approach reduces costs and enhances operational efficiency, giving you a competitive advantage. By choosing our solutions, you get an agile and responsive infrastructure ready to thrive in an evolving business landscape.",
    imageSrc: "/images/logistics-supply-chain-management-bg.svg",
  },
  {
    label: "Warehouse Automation",
    tabTitle: "Transform your warehouse",
    industryTabDescription:
      "Automate your warehouse operations, such as order fulfillment, restocking, and inventory tracking, to ensure real-time visibility and control, reduce errors in inventory management, and boost operational efficiency with our personalized approach. We aim to meet your warehouse's current needs and have in mind your potential to scale and grow.",
    imageSrc: "/images/logistics-warehouse-automation-bg.svg",
  },
  {
    label: "Route Optimization",
    tabTitle: "Maximize efficiency and sustainability",
    industryTabDescription:
      "We are dedicated to optimizing your delivery routes by analyzing traffic patterns, delivery windows, and vehicle capacity to achieve enhanced efficiency and consider environmental sustainability. Our solutions save time and resources, minimize the carbon footprint, and adapt to dynamic variables to ensure agility and responsiveness.",
    imageSrc: "/images/logistics-route-optimization-bg.svg",
  },
  {
    label: "Inventory Tracking",
    tabTitle: "Efficient deliveries",
    industryTabDescription:
      "Implement our real-time inventory tracking solution to prevent losses and ensure reliable inventory management. It's an investment that minimizes the risk of stockouts or overstock situations and streamlines supply chain management. Our solution seamlessly integrates into any existing workflow to bring benefits and reduce disruptions.",
    imageSrc: "/images/logistics-inventory-tracking-bg.svg",
  },
  {
    label: "Last-Mile Delivery",
    tabTitle: "Add personalization to end customers",
    industryTabDescription:
      "Improve last-mile delivery services with our comprehensive solution that ensures efficient delivery and provides a personalized experience to end consumers. We consider every detail, like specific delivery time windows, secure drop-off locations, or real-time delivery updates, to meet customers' expectations and help you to build a user-centric business.",
    imageSrc: "/images/logistics-last-mile-delivery-bg.svg",
  },
];

export const REAL_ESTATE_INDUSTRY_TABS_LIST: IndustryInfoTabItem[] = [
  {
    label: "Management platform",
    tabTitle: "Access everything in one place",
    industryTabDescription:
      "Our solutions ensure transparency and efficiency in property sales and rentals. Our systems allow tracking property statuses, managing deal conditions, monitoring financial transactions, and providing maintenance services. This simplifies the work of property owners and real estate agencies while enhancing customer service.",
    imageSrc: "/images/industries-real-estate-bg-1.svg",
  },
  {
    label: "Digitalization Platform",
    tabTitle: "Revolutionizing real estate",
    industryTabDescription:
      "We propose robust solutions to enable more efficient property management and redefine real estate digitalization. Our technologies allow users to create digital property profiles, including plans and specifications. Our goal is to help people improve marketing, sales, and rental processes, providing them with detailed property information.",
    imageSrc: "/images/industries-real-estate-bg-2.svg",
  },
  {
    label: "P2P Rental platform",
    tabTitle: "Simplifying the processes",
    industryTabDescription:
      "Our focus is on the accessibility of property rental solutions and optimizing the whole process. We present online platforms that allow individuals to rent or lease property without intermediaries. Our solutions are built on mutual trust between the parties to save time and resources, making the rental process straightforward and more transparent.",
    imageSrc: "/images/industries-real-estate-bg-3.svg",
  },
  {
    label: "Digital Access and Security Management",
    tabTitle: "Safety first",
    industryTabDescription:
      "We develop and provide innovative systems for access control and property security. Our technologies include biometric identification, video surveillance, access control systems, and more. We aim to make property management easy and fast to respond to ensure property protection and the safety of its occupants.",
    imageSrc: "/images/industries-real-estate-bg-4.svg",
  },
];

export const AVIATION_INDUSTRY_TABS_LIST: IndustryInfoTabItem[] = [
  {
    label: "Flight Management ",
    tabTitle: "Enhance aviation capacities",
    industryTabDescription:
      "We offer features of real-time aircraft tracking, control of flights, weather monitoring, and communication between ground control and aircraft, allowing you to optimize routes and resource allocation and enhance aviation safety and operational effectiveness.",
    imageSrc: "/images/aviation-flight-management.svg",
  },
  {
    label: "Aircraft Maintenance",
    tabTitle: "Keep up the highest performance",
    industryTabDescription:
      "Our software systems enable airlines and maintenance teams to efficiently manage maintenance schedules, track aircraft conditions, and perform timely inspections and repairs. We go beyond usual services and use analytical tools to detect potential issues before they become critical. Our suite of tools also includes help with compliance with aviation regulations.",
    imageSrc: "/images/aviation-aircraft-maintenance.svg",
  },
  {
    label: "Passenger Experience",
    tabTitle: "Elevate air travel enjoyment",
    industryTabDescription:
      "Our robust software solutions, among other things, cover user-friendly mobile apps for booking and check-in and in-flight entertainment systems. We focus on providing passengers with convenient travel experiences, meaning paperless boarding, mobile boarding passes, and personalized services, to grant them satisfaction and joy.",
    imageSrc: "/images/aviation-passenger-experience.svg",
  },
  {
    label: "Revenue Management",
    tabTitle: "Gain financial benefits",
    industryTabDescription:
      "Optimize pricing and revenue strategies with our software systems. We utilize advanced algorithms and data analytics to determine optimal pricing for flights and ancillary services while maintaining competitive fares. Our solutions also include loyalty program management and dynamic pricing strategies to drive revenue growth.",
    imageSrc: "/images/aviation-revenue-management.svg",
  },
  {
    label: "Aviation Analytics",
    tabTitle: "Learn and progress",
    industryTabDescription:
      "Check out our analytical solutions for the aviation industry. Our software systems enable data collection, analysis, and visualization to support informed decision-making that enhances overall performance. We offer tools for route optimization, inventory management, and security analytics to elevate operational efficiency, safety, and profitability.",
    imageSrc: "/images/aviation-analytics.svg",
  },
];

export const MEDIA_INDUSTRY_TABS_LIST: IndustryInfoTabItem[] = [
  {
    label: "Content Management",
    tabTitle: "Empowering content spreading the word",
    industryTabDescription:
      "We design and develop advanced Content Management Systems (CMS), considering industry trends and the dynamic nature of media. Our CMS solutions enable efficient content creation, publication, and distribution across digital platforms for the content to make a real impact. They streamline editorial processes and help to build a seamless collaborative workflow.",
    imageSrc: "/images/media-content-management-bg.svg",
  },
  {
    label: "Video Streaming",
    tabTitle: "High-quality delivery",
    industryTabDescription:
      "Our expertise lies in building robust video streaming and delivery platforms that ensure smooth playback of high-quality video content. We provide end-to-end solutions for live streaming, on-demand video distribution, and monetization, enhancing the viewer's experience and fulfilling business purposes.",
    imageSrc: "/images/media-video-streaming-bg.svg",
  },
  {
    label: "Audience Engagement",
    tabTitle: "Know your customers",
    industryTabDescription:
      "We offer innovative tools to engage with audiences, analyze user behavior, and make data-driven decisions about future product development. Our solutions include audience segmentation, personalized content recommendations, and comprehensive analytics dashboards, helping media companies reach diverse clients.",
    imageSrc: "/images/media-audience-engagement-bg.svg",
  },
  {
    label: "Advertising and Monetization",
    tabTitle: "Grow your audience",
    industryTabDescription:
      "We provide ad management platforms, programmatic advertising integrations, targeted ad delivery capabilities, and more to generate ad revenue and optimize audience targeting for your company. Using our expertise, we help you adapt your advertising strategy to the dynamic digital marketing landscape.",
    imageSrc: "/images/media-advertising-and-monetization-bg.svg",
  },
  {
    label: "Social Media",
    tabTitle: "Be visible at every platform",
    industryTabDescription:
      "We develop integrations with social media platforms and create automation tools to streamline content sharing and management. Our solutions help media publish content on social networks more efficiently, reach bigger audiences, and analyze social engagement metrics. With our tools and integrations, social media will be a powerful instrument for your business growth.",
    imageSrc: "/images/media-social-media-bg.svg",
  },
];

export const OTHER_INDUSTRIES_TABS_LIST: IndustryInfoTabItem[] = [
  {
    label: "Gaming",
    tabTitle: "Elevating gaming excellence",
    industryTabDescription:
      "Our team leads in creating immersive and high-performance gaming experiences. Whether you need a mobile game, console game, or VR experience, we deliver engaging gameplay, stunning graphics, and seamless multiplayer functionality. We use the latest technologies to present captivating solutions that keep players returning for more.",
    imageSrc: "/images/other-gamepad-minimalistic-bg.svg",
  },
  {
    label: "Healthcare",
    tabTitle: "Taking off the load",
    industryTabDescription:
      "Our software development team creates healthcare solutions for medics' and patients' convenience, understanding the crucial role of precision and reliability in this industry. We present tools and services that enhance patient care, streamline administrative tasks, and improve medical research, such as Electronic Health Records (EHR) systems or telemedicine platforms.",
    imageSrc: "/images/other-healthcare-bg.svg",
  },
  {
    label: "Sport & Fitness",
    tabTitle: "Game-changing training tech",
    industryTabDescription:
      "We catch the growing demand for sports and fitness-related software. Our team develops apps that make life easier for athletes, coaches, fitness enthusiasts, sports organizations, etc. Whether it's a sports analytics tool, workout app, or sports management platform, we create software that enhances training, performance analysis, and overall fitness goals.",
    imageSrc: "/images/other-sport-fitness-bg.svg",
  },
  {
    label: "Other Innovation Solutions",
    tabTitle: "Creativity",
    industryTabDescription:
      "We don't limit ourselves to the mentioned industries. We love a unique challenge that allows us to present an innovative solution. Being in software development for 5+ years, we can adjust to various domains and create custom solutions that address specific needs. If you have something special in mind, we're here to turn it into reality.",
    imageSrc: "/images/other-innovation-solutions-bg.svg",
  },
];

export const DIGITAL_BANKING_INDUSTRY_WHO_WE_SERVE_LIST: WhoWeServeItemType[] = [
  { text: "Third-Party Integrations" },
  { text: "KYC Verification" },
  { text: "KYB Flows" },
  { text: "Payment System Integration" },
  { text: "Payment and Transfers" },
  { text: "Card Management" },
];

export const DIGITAL_BANKING_INDUSTRY_TOP_SERVICES_LIST: ServicesListItemType[] = [
  {
    title: "Competitor Analysis",
    description:
      "Achieve a competitive advantage with our comprehensive competitor analysis services. Our in-depth market analysis delves into industry trends, consumer behavior, and competitor strategies to provide actionable insights. We identify market gaps and opportunities and equip your product with the strategic advantage needed to stand out and succeed in a crowded marketplace.",
    bgImageSrc: "/images/industries/fintech/our-services/our-services-fintech-legacy-software-transformation.webp",
    gradient: "buttonAccent",
    width: "50%",
    isBlackText: true,
    backgroundSize: "100%",
    backgroundSizeMobile: "100% 12.5rem",
    isContactUs: true,
    isButtonShow: true,
    adaptiveHeight: { height: { _: "28rem", mobileM: "26rem", tablet: "30rem" } },
  },
  {
    title: "Product Design",
    description:
      "Elevate your product with our innovative product design solutions. Using cutting-edge design thinking methodologies, we go beyond meeting user expectations to create captivating and user-centric experiences. Our team focuses on optimizing usability, functionality, and aesthetics to ensure your product not only meets but exceeds user demands. With meticulous attention to detail, we craft visually appealing designs that resonate with your target audience, driving engagement and loyalty.",
    imageSrc: "/images/industries/digital-banking/our-services/our-services-digital-banking-product-design.webp",
    imageSrcMobile:
      "/images/industries/digital-banking/our-services/mobile-image/our-services-digital-banking-product-design-mobile.webp",
    link: EXTERNAL_LINKS.idealogicDesign,
    aspectRatio: 1.69,
    mobileAspectRatio: 1.02,
    adaptiveHeight: { height: { _: "36rem", mobileM: "30rem" } },
    width: "50%",
  },
];

export const DIGITAL_BANKING_INDUSTRY_BOTTOM_SERVICES_LIST: ServicesListItemType[] = [
  {
    title: "Banking UI/UX",
    description:
      "Transform your banking interface with our expert UI/UX design services. Our team specializes in crafting intuitive and immersive interfaces that enhance the overall user experience. By leveraging industry best practices and user-centered design principles, we create seamless navigation paths and visually engaging layouts. From account management to transactional processes, we optimize every interaction to streamline user journeys and maximize engagement. With our tailored UI/UX solutions, your banking platform will captivate users and foster long-term relationships.",
    imageSrc: "/images/industries/digital-banking/our-services/our-services-digital-banking-banking-ui-ux.webp",
    imageSrcMobile: "/images/industries/digital-banking/our-services/our-services-digital-banking-banking-ui-ux.webp",
    link: `/${ROUTES.services}/${ServicesVariants.uiUxDesign}`,
    aspectRatio: 0.82,
    isBlackText: true,
    width: "calc(33% - 0.625rem)",
    adaptiveHeight: { height: { _: "40rem", mobileM: "33rem", tablet: "57rem", laptopL: "48rem" } },
  },
  {
    title: "Security Audit",
    description:
      "Ensure the utmost security and compliance of your project with our thorough security audit services. Our team conducts comprehensive assessments to evaluate the robustness of your system architecture and identify potential vulnerabilities. From data encryption to access control mechanisms, we scrutinize every aspect of your project to ensure adherence to the latest rules and regulations. With a focus on safeguarding your users' data and protecting against cyber threats, we provide peace of mind knowing your project is fortified against potential risks.",
    imageSrc: "/images/industries/digital-banking/our-services/our-services-digital-banking-security-audit.webp",
    imageSrcMobile: "/images/industries/digital-banking/our-services/our-services-digital-banking-security-audit.webp",
    aspectRatio: 0.82,
    width: "calc(33% - 0.625rem)",
    isContactUs: true,
    isButtonShow: true,
    adaptiveHeight: { height: { _: "39rem", mobileM: "34rem", tablet: "57rem", laptopL: "48rem" } },
  },
  {
    title: "Integration with Core Banking Systems and Third-Party Providers - KYC and AML",
    description:
      "Achieve seamless integration of your systems with core banking platforms and third-party services with our expert solutions. Our team specializes in connecting your digital infrastructure with essential banking systems and external service providers, ensuring secure data exchange and smooth operational workflows. With a focus on compliance and security, we facilitate Know Your Customer (KYC) and Anti-Money Laundering (AML) integrations, enabling your platform to meet regulatory requirements while delivering exceptional user experiences.",
    imageSrc: "/images/industries/digital-banking/our-services/our-services-digital-banking-kyc-aml.webp",
    imageSrcMobile:
      "/images/industries/digital-banking/our-services/mobile-image/our-services-digital-banking-kyc-aml-mobile.webp",
    isContactUs: true,
    isButtonShow: true,
    adaptiveHeight: { height: { _: "41rem", mobileM: "37rem", tablet: "57rem", laptopL: "48rem" } },
    isBlackText: true,
    aspectRatio: 0.58,
    mobileAspectRatio: 1.02,
    width: "calc(33% - 0.625rem)",
  },
];

export const DIGITAL_BANKING_INDUSTRY_ADDITIONAL_SERVICES_LIST: ServicesListItemType[] = [
  {
    title: "Development & Customization",
    description:
      "We help you unlock the full potential of your digital project with our development and customization services. Our tailored solutions give your project a distinctive online presence and competitive edge. From conceptualization to execution, our team works closely with you to understand your vision and bring it to life through custom development and meticulous customization. Whether you need a bespoke online banking platform or a white-label solution, we have the expertise and flexibility to exceed your expectations.",
    imageSrc:
      "/images/industries/digital-banking/our-services/our-services-digital-banking-development-customisation.webp",
    imageSrcMobile:
      "/images/industries/digital-banking/our-services/our-services-digital-banking-development-customisation.webp",
    link: `/${ROUTES.services}/${ServicesVariants.webDevelopment}`,
    aspectRatio: 0.82,
    width: "50%",
    adaptiveHeight: { height: { _: "37rem", mobileM: "31rem", tablet: "34rem", laptop: "30rem" } },
  },
  {
    title: "Maintenance & Support",
    description:
      "Ensure the continuous performance and reliability of your digital product with our dedicated maintenance and support services. We are committed to providing immediate assistance and proactive maintenance to address any issues and ensure smooth operation. Our experienced support team is available round-the-clock to troubleshoot technical issues, implement updates, and optimize performance, allowing you to focus on your core business activities with confidence. With our comprehensive maintenance and support services, your product remains secure, stable, and responsive to user needs.",
    bgImageSrc: "/images/industries/fintech/our-services/our-services-fintech-software-quality-assurance.webp",
    gradient: "grayGradient",
    backgroundSize: "20.0625rem",
    backgroundSizeMobile: "12.5rem 9.375rem",
    width: "50%",
    adaptiveHeight: { height: { _: "40rem", mobileM: "35rem", tablet: "34rem", laptop: "30rem" } },
    link: `/${ROUTES.services}/${ServicesVariants.maintenanceSupport}`,
  },
];

export const DIGITAL_BANKING_INDUSTRY_ACCORDION_CONTENT_LIST: AccordionContentItemType[] = [
  {
    title: "How long does it take to develop a digital banking solution?",
    description:
      "The timeline for developing a digital banking solution varies depending on the project's scope and complexity. Generally, it ranges from a few months to a year. Our process begins with a comprehensive analysis of your requirements, followed by design, development, testing, and deployment phases.",
  },
  {
    title: "Can you help enhance my team's skills and competencies",
    description:
      "We provide guidance on technology adoption and security best practices to enhance your team's skills and competencies. Our experts ensure that your team is well-equipped to manage and evolve your digital banking platform for long-term success in a rapidly changing digital landscape.",
  },
  {
    title: "What security measures are in place to protect my financial data?",
    description:
      "Our digital banking solutions employ advanced encryption techniques and continuous monitoring to protect sensitive financial data. We prioritize security at every stage of development, implementing robust security measures to safeguard against potential threats and breaches.",
  },
  {
    title: "How do you ensure compliance with financial regulations and standards?",
    description:
      "We follow the strongest regulatory frameworks such as GDPR (General Data Protection Regulation) and PSD2 (Payment Services Directive 2) to ensure legal integrity and compliance with industry standards. Our solutions are designed to meet the regulatory requirements of your region and industry.",
  },

  {
    title: "How scalable are your digital banking solutions? Do they accommodate future growth?",
    description:
      "Our digital banking solutions are built with scalability in mind to accommodate your business growth seamlessly. Whether you're expanding your customer base, introducing new services, or scaling your operations, our platforms can scale effectively to meet your evolving needs.",
  },
  {
    title: "What kind of support and maintenance services do you offer post-deployment?",
    description:
      "We offer 24/7 support to address any emergency issues and ensure the smooth operation of your digital banking platform. Additionally, we provide regular updates and maintenance checks to enhance features, improve security, and optimize performance.",
  },
];

export const SPARE_PARTS_MARKETPLACE_ACCORDION_CONTENT_LIST: AccordionContentItemType[] = [
  {
    title: "Bulk Inventory Import",
    description:
      "Effortlessly integrate vast quantities of inventory data with our precise bulk inventory import feature. Save time and ensure accurate stock level updates in real time, eliminating potential human errors. Our streamlined process allows you to efficiently manage large volumes of inventory data, ensuring seamless operations and enhanced productivity.",
  },
  {
    title: "SPEC2000 and SITA Communication",
    description:
      "Ensure secure and effective communication channels across the industry with our utilization of SPEC2000 and SITA protocols. Facilitate seamless information exchange and stay ahead of technological advancements. Our implementation of industry-standard protocols ensures reliable and efficient communication, enabling you to maintain strong connections with partners and stakeholders.",
  },
  {
    title: "Suppliers White/Blacklists",
    description:
      "Analyze your supplier base, identify trusted partners, and flag potential risks with our precise solution. Personalize your business interactions based on historical performance and reliability to gain a strategic advantage in supply chain management. Our comprehensive analysis tools empower you to make informed decisions, optimizing your supplier relationships and minimizing risks in your supply chain.",
  },
  {
    title: "Automated RFQs",
    description:
      "Redefine the procurement process with our automated RFQs feature. Generate and send out requests for quotations with unmatched speed and efficiency, leading to faster response times from suppliers and reduced procurement cycle time. Our automated system streamlines the RFQ process, allowing you to quickly obtain competitive quotes and make informed purchasing decisions.",
  },
  {
    title: "Notifications Management",
    description:
      "Stay informed and proactive with our comprehensive notifications management system. Customize your notification preferences to receive timely alerts relevant to your operations, ensuring you are always ahead of your competitors. Our customizable notification system keeps you informed of critical events and updates, empowering you to respond promptly to changing circumstances and maintain a competitive edge.",
  },
  {
    title: "Access Management",
    description:
      "Safeguard your data and operations with our access management solution. Customize access levels to ensure sensitive information and critical functionalities are only accessible to authorized personnel, mitigating the risks of potential security breaches. Our robust access management system ensures that your data remains secure and protected, allowing you to control access and maintain confidentiality.",
  },
  {
    title: "Sales and Purchases Analytical Charts",
    description:
      "Leverage deep insights into your business performance and market dynamics with our feature-rich analytical charts. Understand trends, patterns, and opportunities to make data-driven strategic decisions and enhance your competitive edge. Our analytical charts provide valuable insights into your sales and purchasing data, enabling you to identify opportunities for growth and optimization.",
  },
  {
    title: "Lending and Financing",
    description:
      "Access innovative financial software services tailored to the unique challenges of the aviation industry. Keep records of your financial needs, analyze your budget, and comply with necessary regulatory requirements to support your strategic goals. Our financial software solutions empower you to manage your finances effectively, ensuring compliance and supporting your business objectives.",
  },
];

export const SPARE_PARTS_MARKETPLACE_BOTTOM_SERVICES_LIST: ServicesListItemType[] = [
  {
    title: "Aviation Companies/Airlines",
    description:
      "Boost efficiency, reduce manual tasks, and enhance competitiveness by leveraging innovative technologies. Let us guide you in selecting and seamlessly integrating new technologies to streamline operations.",
    imageSrc: "/images/industries/aviation/our-services/our-services-spare-parts-marketplace-aviation-companies.webp",
    imageSrcMobile:
      "/images/industries/aviation/our-services/our-services-spare-parts-marketplace-aviation-companies.webp",
    aspectRatio: 1.4,
    width: "55%",
    height: "20rem",
  },
  {
    title: "Spare Parts Trading Companies",
    description:
      "Revamp your outdated systems and infrastructure to regain momentum and stay ahead in the market. Continuing with legacy systems can incur financial losses and diminish market share.",
    bgImageSrc: "/images/industries/fintech/our-services/our-services-fintech-legacy-software-transformation.webp",
    gradient: "buttonAccent",
    width: "55%",
    height: "20rem",
    backgroundSize: "100%",
    backgroundSizeMobile: "100% 12.5rem",
    isBlackText: true,
  },
  {
    title: "Software Development & Integration",
    description:
      "Regardless of your software needs, our team is equipped to integrate any solution seamlessly. From custom software development to tailored integrations, we have the expertise to handle diverse requirements.",
    imageSrc: "/images/industries/aviation/our-services/our-services-spare-parts-marketplace-software-development.webp",
    imageSrcMobile:
      "/images/industries/aviation/our-services/our-services-spare-parts-marketplace-software-development.webp",
    height: "41.5rem",
    width: "calc(41% - 0.6875rem)",
    aspectRatio: 0.79,
    mobileAspectRatio: 0.78,
    isObjectFitFill: true,
  },
];

export const INTELLIGENT_INVENTORY_MANAGEMENT_BOTTOM_SERVICES_LIST: ServicesListItemType[] = [
  {
    title: "Digital Transformation Enablement",
    description:
      "Embrace digital transformation and unlock new levels of productivity and competitiveness with our innovative technologies. From streamlining manual processes to implementing cutting-edge solutions, we help you stay ahead of the curve in today's digital age.",
    imageSrc:
      "/images/industries/intelligent-inventory-management/our-services/our-services-spare-intelligent-inventory-management-digital-transformation.webp",
    imageSrcMobile:
      "/images/industries/intelligent-inventory-management/our-services/our-services-spare-intelligent-inventory-management-digital-transformation.webp",
    aspectRatio: 2.33,
    width: "55%",
  },
  {
    title: "Legacy Modernization & Migrations",
    description:
      "Transition from legacy systems to modern, scalable solutions with Idealogic's expertise in legacy modernization and migrations. Avoid the pitfalls of outdated systems and unlock new opportunities for growth and innovation.",
    bgImageSrc: "/images/industries/fintech/our-services/our-services-fintech-legacy-software-transformation.webp",
    gradient: "buttonAccent",
    width: "55%",
    backgroundSize: "100%",
    backgroundSizeMobile: "100% 12.5rem",
    isBlackText: true,
  },
  {
    title: "Software Development & Integration",
    description:
      "From custom software development to seamless integration with existing systems, Idealogic offers end-to-end solutions to meet your business needs. Our team of experts handles everything from concept to deployment, ensuring a smooth and successful implementation.",
    imageSrc: "/images/industries/aviation/our-services/our-services-spare-parts-marketplace-software-development.webp",
    imageSrcMobile:
      "/images/industries/aviation/our-services/our-services-spare-parts-marketplace-software-development.webp",
    height: "56.5rem",
    aspectRatio: 0.79,
    mobileAspectRatio: 0.78,
    width: "calc(41% - 0.6875rem)",
  },
];

export const BLOCKCHAIN_BASED_AVIATION_ACCORDION_CONTENT_LIST: AccordionContentItemType[] = [
  {
    title: "Private, Public, Consortium Blockchains",
    description:
      "Choose from private, public, or consortium blockchain solutions that align with your unique business requirements. Ensure the integrity, security, and seamless interoperability of data across various aviation supply chains.",
  },
  {
    title: "Spare Part Data Hashing and Timestamping",
    description:
      "Record and timestamp component histories securely to furnish stakeholders with verifiable proof of authenticity, origin, and maintenance records. This enhances trust and transparency throughout the aviation ecosystem.",
  },
  {
    title: "API for Spare Part Data Validation",
    description:
      "Integrate our robust API solutions effortlessly with your existing systems to validate the authenticity and history of aviation components in real-time. Ensure compliance and reliability with every transaction.",
  },
  {
    title: "Real-time Analytics",
    description:
      "Harness real-time analytics to gain immediate insights into your operations, inventory levels, and logistical processes. Identify trends, optimize workflows, and make data-driven decisions to drive efficiency and sustainability.",
  },
  {
    title: "Multi-tier Supply Chain Tracking",
    description:
      "Achieve transparency, compliance, and reliability across every tier of your supply chain. Identify and address bottlenecks, optimize inventory management, and guarantee the authenticity and quality of parts at each stage of the process.",
  },
];

export const BLOCKCHAIN_BASED_AVIATION_BENEFITS_LIST: BenefitsItemProps[] = [
  {
    title: "Smart Contract Development",
    iconSrc: "/images/industries/blockchain-based-aviation/blockchain-based-aviation-document-text-icon.svg",
    description:
      "Rely on our expertise for the seamless development of smart contracts tailored to augment the quality and efficiency of your aviation supply chain. Our solutions are engineered to streamline processes and enhance operational efficacy across the board.",
  },
  {
    title: "Blockchain Consulting and R&D",
    iconSrc: "/images/industries/blockchain-based-aviation/blockchain-based-aviation-dialog-icon.svg",
    description:
      "Harness the collective experience of our expert specialists with over a decade of experience in the field. Navigate the complex terrain of blockchain adoption and integration with confidence, armed with data-driven insights and strategic guidance tailored to your unique business objectives.",
  },
  {
    title: "Blockchain Integration",
    iconSrc: "/images/industries/blockchain-based-aviation/blockchain-based-aviation-settings-icon.svg",
    description:
      "Whether it's a public, private, or consortium blockchain solution you seek, we stand ready to seamlessly integrate the blockchain framework that best aligns with your organizational requirements. Empower your aviation enterprise with enhanced security, efficiency, and transparency.",
  },
];
