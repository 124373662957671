import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledTestimonialLabelWrapper = styled.div`
  position: relative;

  display: flex;
  align-items: center;

  padding: 0.75rem;

  border-radius: ${({ theme }) =>
    `${theme.radii.videoTestimonialDecorLabel} 0 0 ${theme.radii.videoTestimonialDecorLabel}`};
  background: ${({ theme }) => theme.colors.white};
`;

export const StyledDecorLabelWrapper = styled(motion.div)`
  width: max-content;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  padding: 0.5rem 0.75rem;
  border-radius: ${({ theme }) => theme.radii.videoTestimonialDecorLabel};
  background-color: ${({ theme }) => theme.colors.textLink};
  cursor: pointer;
`;
