// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 80 80" {...props} color="transparent">
      <path
        d="M58.3865 50.5532C59.8494 55.5195 62.8828 62.2186 61.5976 67.3386C59.5467 75.5114 49.2409 70.4139 45.2577 67.4314C44.2003 66.6397 43.1508 65.8345 42.1404 64.9842C40.9372 63.9731 39.7503 62.9386 38.6051 61.8617C35.3191 65.7896 30.6581 70.1002 25.7777 71.8496C21.6883 73.3152 18.2852 71.5171 17.526 67.1634C16.6191 61.9659 18.1055 56.0357 19.4291 51.0323C15.4765 47.275 11.3734 43.4643 8.44231 38.8632C5.62514 34.4392 7.73133 30.2978 12.6853 29.1636C14.2856 28.7971 15.925 28.6155 17.5631 28.5361C19.7688 28.4292 24.2816 28.6031 26.419 28.6031C26.5681 28.5172 26.8864 27.2051 26.9665 26.9818C27.5076 25.4648 28.0753 23.9682 28.7062 22.4857C29.1828 21.3672 29.6685 20.2517 30.1665 19.1429C31.9388 15.1961 35.8764 4.57676 41.8037 8.6225C42.4066 9.03398 42.9132 9.5516 43.3728 10.1135C44.1333 11.0419 44.7296 12.1374 45.2765 13.2013C46.3052 15.1981 47.1087 17.2984 47.9257 19.3877C49.1459 22.507 50.1687 25.7331 51.5203 28.8002C51.5372 28.838 51.5496 28.8778 51.5594 28.9175C53.5692 28.967 55.5843 29.0023 57.5922 29.1026C61.9726 29.32 68.8218 28.1806 70.7438 32.548C71.7829 34.9094 70.6331 37.4081 69.2222 39.3229C66.3556 43.2137 62.4784 46.2157 58.7198 49.1872"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.0938 15.0223C47.6201 13.5152 49.2773 12.0695 50.9215 10.6934"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.3125 22.98C51.4065 20.9914 53.4751 18.9978 55.6064 17.0508"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.4688 29.0514C59.1179 27.1162 60.7826 25.1784 62.5584 23.3555"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.4062 30.6595C70.5932 29.6171 71.7522 28.5388 72.8845 27.4375"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.7031 54.5959C60.7455 53.6211 61.7897 52.6527 62.8941 51.748"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.8438 65.8138C62.9959 64.7746 64.145 63.7179 65.3593 62.75"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.0572 66.4961C42.4546 68.3866 40.6112 69.9377 38.6797 71.4746"
        stroke={theme.colors[color]}
        strokeWidth="4"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
