// Components + styling
import { FlexGap } from "components";
import { DropdownItem, SectionTitle } from "./components";
import { StyledDropDownsWrapper, StyledDropdownContainer } from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";

// Types
import { DropdownMenuItemProps } from "./types";

export const DropdownMenuItems: React.FC<DropdownMenuItemProps> = ({
  navSections,
  isFixedWidth,
  wrapperProps,
  onDropdownMenuClick,
  maxHeight,
  gap,
  ...props
}) => {
  const { isDesktop } = useMatchBreakpoints();

  return (
    <StyledDropDownsWrapper maxHeight={maxHeight} gap={gap}>
      {navSections &&
        navSections.map(({ navItems, title, titleLink, isExternal }) => (
          <div key={title}>
            <StyledDropdownContainer key={title} itemsLength={navSections.length} isFixedWidth={isFixedWidth}>
              <SectionTitle
                titleLink={titleLink}
                title={title}
                isExternal={isExternal}
                onDropdownMenuClick={onDropdownMenuClick}
              />

              <FlexGap
                flexDirection="column"
                mt={{ _: "0.75rem", laptop: !title ? "2.5rem" : "1rem" }}
                gap={isDesktop ? "0.25rem" : "0.75rem"}
                {...wrapperProps}
              >
                {navItems.map(navItem => (
                  <DropdownItem key={navItem.title} {...navItem} onDropdownMenuClick={onDropdownMenuClick} {...props} />
                ))}
              </FlexGap>
            </StyledDropdownContainer>
          </div>
        ))}
    </StyledDropDownsWrapper>
  );
};
