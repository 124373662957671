import { BaseCaseStudiesCardProps } from "pages/case-studies/components/case-studies-filter/types";
import { CaseStudyItem, Category } from "services";

export interface CaseStudyContextData {
  caseStudies: CaseStudyItem[];
  selectedFilters: InitialFiltersType;
  selectedAll: SelectedState;
  initialFilters: InitialFiltersType;
  isLoading: boolean;
  handleRemoveFilter: (filters: Filters, type: CaseStudiesFilterType, option: Category) => void;
  loadMoreItems: () => void;
  handleFilterChange: (args: HandleFiltersChangeArgs) => void;
  resetAllFilters: () => void;
}

export type CaseStudiesFilterType = "industry" | "category";

export type CaseStudiesFiltersType = {
  name: string;
  options: string[];
};

export type FiltersType = {
  [key in CaseStudiesFilterType]: CaseStudiesFiltersType;
};

export type InitialFiltersType = {
  [key in CaseStudiesFilterType]: Category[];
};

export enum CaseStudiesFilterOptions {
  allIndustries = "All industry",
  allTechnologies = "All technology",
  allCategories = "All category",
  industryCategoryTitle = "Industry",
  technologyCategoryTitle = "Technology",
  categoryCategoryTitle = "Category",
}

export const {
  allIndustries,
  allTechnologies,
  allCategories,
  industryCategoryTitle,
  technologyCategoryTitle,
  categoryCategoryTitle,
} = CaseStudiesFilterOptions;

export interface SelectedState {
  industry: boolean;
  category: boolean;
}

export type FilterItem = {
  name: string;
  options: Category[];
};

export type Filters = { [key in CaseStudiesFilterType]: FilterItem };

export type HandleFiltersChangeArgs = {
  filters?: Filters;
  type: CaseStudiesFilterType;
  selectedOption: Category | string;
  isMobileFilter?: boolean;
  selectedOptions?: InitialFiltersType;
};

export interface CaseStudiesCardListType extends BaseCaseStudiesCardProps {
  cardTitle: string;
  categories: string[];
  subTitle: string;
  description: string;
  link: string;
}

export enum CaseStudiesSlugEnums {
  seedbox = "seed-box",
  revolve = "revolve-games",
  cBank = "c-bank",
  planetcoin = "planetcoin",
  kansoWallet = "kanso-wallet",
  aiBook = "ai-book-platform",
  mindHorizon = "mind-horizon",
  airpartChain = "airpart-chain",
  eplane = "eplane",
  ugreator = "ugreator",
  addmed = "addmed",
  swissy = "swissy-platform",
  conveya = "conveya",
  haulBreeze = "haul-breeze",
  eState = "e-states",
  secondFloor = "second-floor",
  astrocat = "astrocat-platform",
  iGames = "i-games",
  zert = "zert",
  matest = "matest-marketplace",
  nextparq = "nextparq",
  bitholder = "bitholder-platform",
  everse = "everse-platform",
  plancy = "plancy-platform",
  pikkUp = "pikk-up-platform",
  scraps = "scraps-platform",
  ubeat = "ubeat-platform",
  glue = "glue-platform",
  healthLife = "health-life-platform",
  inder = "inder-platform",
  allCryptoMechanics = "all-crypto-mechanics",
  fese = "fese",
}
