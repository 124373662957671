// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { ROUTES } from "navigation/routes";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { AVIATION_INDUSTRY_TABS_LIST } from "../components/industry-info/constants";
// Types
import { IndustriesVariants, IndustryPageContent } from "../types";

const AVIATION_CONTENT: IndustryPageContent = {
  banner: {
    title: "Aviation software development",
    description:
      "Idealogic creates specialized software solutions for the aviation industry, addressing flight management, aircraft maintenance, passenger services, and aviation analytics.",
    imageSrc: "/images/industries-banner-aviation.webp",
    imageRatio: 1.24,
  },
  industryInfo: {
    title: "Aviation digital upgrade",
    description:
      "We introduce innovative solutions to make aviation safer, more efficient, and more comfortable for all stakeholders.",
    industryInfoTabsList: AVIATION_INDUSTRY_TABS_LIST,
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.airpartChain, CaseStudiesSlugEnums.eplane],
    title: "Aviation case studies",
    TitleIcon: WavingHandIcon,
    description:
      "Check out our successful examples of innovative aviation software solutions for aircraft maintenance and aerospace trading.",
  },
  coveredIndustries: {
    coveredIndustriesList: [
      { label: "Fintech", link: `/${ROUTES.industries}/${IndustriesVariants.fintech}` },
      { label: "Logistics", link: `/${ROUTES.industries}/${IndustriesVariants.logistics}` },
      { label: "Real Estate", link: `/${ROUTES.industries}/${IndustriesVariants.realEstate}` },
      { label: "Media", link: `/${ROUTES.industries}/${IndustriesVariants.media}` },
      { label: "Other industries", link: `/${ROUTES.industries}/${IndustriesVariants.other}` },
    ],
  },
};

export default AVIATION_CONTENT;
