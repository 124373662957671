// Types
import { OurTeamItemType } from "./types";

export const ALEX_SAIKO_SLIDE: OurTeamItemType = {
  image: "/images/team/alexander-saiko.webp",
  name: "Alex Saiko",
  position: "CEO",
  description:
    "Co-founder and visionary at Idealogic. Helps new businesses and startups find their markets and execute their go-to-market strategies.",
};

export const ARTEM_ZAITSEV_SLIDE: OurTeamItemType = {
  image: "/images/team/artem-zaitsev.webp",
  name: "Artem Zaitsev",
  position: "VP of Engineering",
  description:
    "Co-founder with over 10 years of experience in B2B blockchain integration services. Responsible for driving innovation and harnessing cutting-edge technologies.",
};

export const OLEH_HENALIUK_SLIDE: OurTeamItemType = {
  image: "/images/team/oleh-henaliuk.webp",
  name: "Oleh Henaliuk",
  position: "Deputy CEO",
  description:
    "Experienced entrepreneur known for launching and scaling startups, specializing in project implementation for process development and IT system integration in businesses.",
};

export const DEFAULT_OUR_TEAM_LIST: OurTeamItemType[] = [ALEX_SAIKO_SLIDE, ARTEM_ZAITSEV_SLIDE, OLEH_HENALIUK_SLIDE];
