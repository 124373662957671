// Components
import { OurCapabilitiesDarkYellowArrow, WavingHandIcon } from "components/svg";
// Constants
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
// Types
import { buttonThemeTypes, variants } from "components/button/types";
import { IndustryPageContent } from "../types";

const AGENT_BROKERS_SOLUTIONS_CONTENT: IndustryPageContent = {
  banner: {
    title: "Real Estate Software Solutions for Agents & Brokers",
    description:
      "Elevate your real estate business with Idealogic's comprehensive real estate software solutions. Our tailored tools cover every aspect of your operations, from client management to marketing automation. With our cutting-edge technology, you can streamline your processes and stay ahead in the competitive real estate market.",
    buttonLabel: "Get a Free Estimation Today",
    buttonVariant: variants.SECONDARY,
    buttonTheme: buttonThemeTypes.DARK,
    buttonBgColor: "bgDark",
    maxWidthForButtonRem: 23.875,
    gradient: {
      color: "bannerYellowWhiteGradient",
      backgroundSize: "cover",
    },
    imageRatio: 2.44,
    isColumn: true,
    isWhite: true,
  },
  whoWeServe: {
    title: "Who We Serve",
    description:
      "Idealogic provides services and instruments for all the parties involved in the real estate industry. Check how you can win with our expertise:",
    whoWeServeListLayoutProps: {
      flexDirection: { laptop: "row" },
    },
    whoWeServeContainerBoxProps: {
      paddingTop: { _: "3rem", laptop: "1rem" },
      paddingBottom: { _: "1.5rem", laptop: "2.25rem" },
    },
    whoWeServeList: [
      {
        text: "Real Estate Agents",
        subText:
          "Supercharge your business with our state-of-the-art tools designed to efficiently manage client relationships, automate marketing efforts, and accelerate deal closures.",
      },
      {
        text: "Brokers",
        subText:
          "Unlock a competitive advantage with our comprehensive property management solutions tailored to meet the specific needs of brokers. From lead management to transaction processing, we've got you covered.",
      },
      {
        text: "Property Managers",
        subText:
          "Optimize property oversight, automate rent collection, and streamline operations with our customizable tools built to enhance efficiency and maximize profitability for property managers.",
      },
    ],
    isSubText: true,
    beforeGradientColor: "bannerYellowGradient",
  },
  ourCapabilities: {
    title: "Our Advanced Solutions",
    description:
      "Explore our cutting-edge solutions tailored for the real estate industry, aimed at optimizing operations and enhancing client satisfaction.",
    ArrowIcon: OurCapabilitiesDarkYellowArrow,
    buttonText: "Talk To Us!",
    buttonVariant: variants.SECONDARY,
    buttonTheme: buttonThemeTypes.DARK,
    hoverBgColor: "bgDark",
    hoverTextColor: "white",
    accordionContent: [
      {
        title: "Centralized CRM Solution",
        description:
          "At Idealogic, we offer an intuitive CRM system that streamlines your real estate operations by consolidating leads, contacts, and transactions into one centralized platform. Our highly secured solution is designed to enhance your efficiency and productivity, automating routine tasks and minimizing the risk of human errors. By integrating our CRM into your workflow, you'll unlock unparalleled ease of management, allowing you to focus on strategic decision-making and business growth.",
      },
      {
        title: "AI-Powered Lead Generation",
        description:
          "Experience the power of AI with our automated lead generation solution. Powered by sophisticated algorithms, our AI technology continuously generates high-quality leads with minimal effort on your part. By analyzing market trends and consumer behavior, our system precisely targets potential clients, ensuring a steady flow of prospects to keep your sales pipeline robust. With Idealogic's AI-driven lead generation, you'll witness increased conversion rates and sustained business growth.",
      },
      {
        title: "Marketing Automation Excellence",
        description:
          "At Idealogic, we provide comprehensive marketing automation tools to elevate your real estate marketing efforts. Our platform empowers you to automate communication across various channels, including email, social media, and more. With personalized messaging at scale, you'll effectively engage with your audience and nurture leads without the time investment. Track engagement analytics in real-time and adjust strategies accordingly, ensuring your marketing campaigns are not just effective but also efficient in driving results.",
      },
      {
        title: "Effortless Property Listing Management",
        description:
          "Simplify your property listing process with Idealogic's seamless platform. Our centralized solution facilitates easy creation, modification, and distribution of listings to major real estate platforms. By streamlining these operations, you'll enhance visibility, reach potential buyers more effectively, and ensure your listings are always up-to-date and appealing. Experience increased interest and quicker sales with Idealogic's intuitive property listing management tools.",
      },
    ],
  },
  boostBusiness: {
    title: "White-Label for Real Estate Agents & Brokers",
    description: "We offer a simple-to-use property management solutions which can cut your time-to-market.",
    buttonTitle: "Schedule a call",
    buttonVariant: variants.SECONDARY,
    buttonTheme: buttonThemeTypes.DARK,
    personImageSrc: "/images/industries/agent-brokers-solutions/white-label-img.webp",
    imageRatio: 0.98,
    bgColor: "bgThird",
    isAbsolute: true,
    isWhite: true,
    isWhiteLabel: true,
  },
  ourServicesFintech: {
    title: "Cutting-Edge Solutions for Today's Real Estate Professionals",
    description:
      "At Idealogic, we fully understand the challenges facing the modern real estate industry, and we've developed specialized services to address them head-on. Here's how we can empower your business:",
    buttonLabel: "Start Today!",
    isEstimationButton: true,
    topServicesList: [
      {
        title: "Data-Driven Insights for Informed Decision-Making",
        description:
          "Gain a competitive edge with our data-driven insights, leveraging real-time market data and analytics to inform strategic decisions. By understanding market trends and forecasting future developments, you'll be equipped to make informed choices that drive success in the dynamic real estate landscape.",
        gradient: "bannerYellowGradient",
        width: "calc(33% - 1rem)",
        height: "100%",
      },
      {
        title: "Digital Transformation Leadership",
        description:
          "Stay ahead of the competition by embracing digital transformation with Idealogic. Our expertise in cutting-edge technologies, including AI and machine learning, allows us to revolutionize your operations and deliver superior client experiences. From automated processes to personalized interactions, we'll help you unlock new opportunities and stay ahead of the curve in the digital age.",
        gradient: "bannerYellowGradient",
        width: "calc(33% - 1rem)",
        height: "100%",
      },
      {
        title: "Custom White-Label Solutions",
        description:
          "Tailor our platform to perfectly align with your brand identity and business objectives. With our white-label solutions, you can foster loyalty among clients and stakeholders while streamlining your operations with a platform that feels uniquely yours. Enjoy the flexibility to customize features and functionalities to meet the specific needs of your business and clients.",
        gradient: "bannerYellowGradient",
        width: "calc(33% - 1rem)",
        height: "100%",
      },
    ],
    bottomServicesList: [],
    isWrap: false,
    isWithoutImages: true,
  },
  benefits: {
    title: "Revolutionize Your Real Estate Operations with Cutting-Edge Technology",
    description:
      "At Idealogic, we're pioneering the next wave of real estate technology, constantly pushing the boundaries to deliver unparalleled solutions tailored for your success.",
    buttonText: "Schedule a call",
    benefitsList: [
      {
        title: "AI-Driven Analytics",
        description:
          "Harness the power of our AI-empowered systems to extract valuable insights and drive strategic, informed decisions. Our advanced analytics tools delve deep into market trends and consumer behavior, empowering you to stay ahead of the competition.",
        iconSrc: "/images/industries/agent-brokers-solutions/innovating-technologies-icon-1.svg",
      },
      {
        title: "Virtual Reality for Immersive Tours",
        description:
          "Elevate customer satisfaction and engagement with our immersive virtual tour tool. Embrace innovation and bring properties to life, allowing clients to explore spaces from the comfort of their homes. Our VR solutions set you apart, leaving a lasting impression on potential buyers.",
        iconSrc: "/images/industries/agent-brokers-solutions/innovating-technologies-icon-2.svg",
      },
      {
        title: "Blockchain for Secure Transactions",
        description:
          "Safeguard your financial operations with our blockchain-based solutions. Ensure the integrity and security of transactions, protecting sensitive data and providing peace of mind to all parties involved. With Idealogic, trust and transparency are guaranteed.",
        iconSrc: "/images/industries/agent-brokers-solutions/innovating-technologies-icon-3.svg",
      },
    ],
    isBackgroundImageNeed: false,
    isNeedCurveLine: false,
    isBlackStyleForButton: true,
    isButtonPositionStart: true,
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.eState, CaseStudiesSlugEnums.secondFloor],
    title: "Real Estate Case Studies",
    TitleIcon: WavingHandIcon,
    description:
      "Explore how our innovative solutions have transformed the real estate industry, from mobile rental apps to tokenized platforms.",
    actionButtonText: "See More of Our Work",
  },
  technologiesWeUse: {
    description: "Empower your real estate business with cutting-edge technologies tailored to your specific needs.",
    buttonVariant: variants.SECONDARY,
    buttonBgColor: "bgDark",
  },
};

export default AGENT_BROKERS_SOLUTIONS_CONTENT;
