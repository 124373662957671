const BLOCK_ID = {
  info: "info",
  root: "root",
  header: "header",
  footer: "footer",
  positions: "positions",
  contactUs: "contact-us",
  errorBoundaryHeader: "error-boundary-header",
  idMemberContent: "idMemberContent",
  portfolioBigItemBg: "portfolioBigItemBg",
  technologiesWrapper: "technologiesWrapper",
  tooltip: "tooltip",
  backgroundBlur: "backgroundBlur",
  customCursor: "customCursor",
} as const;

export default BLOCK_ID;
