import { css, keyframes } from "styled-components";

export const appearanceAnimation = keyframes`
  to {
    opacity: 1;
  }
`;

export const elementLevitation = keyframes`
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
`;

export const appearanceAnimationVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const appearanceAnimationMap = {
  initial: "initial",
  animate: "animate",
  exit: "exit",
};

export const scaleAnimation = css`
  transition: ${({ theme }) => theme.transitions.default};

  &:not([disabled]):active {
    transform: scale(0.95);
  }
`;

export const moveUpTextAnimation = css`
  transition: ${({ theme }) => theme.transitions.cubic_bezier_0_3s};

  &:hover {
    transform: translate3d(0, -2px, 0);
  }
`;

export const ElementLevitationAnimation = (delay: string = "") => css`
  animation: 2.5s cubic-bezier(0.42, 0.97, 0.52, 1.49) ${elementLevitation} ${delay} infinite;
`;

export const linesMovingFromDifferentDirectionsAnimation = css`
  transform: translate3d(0, 2px, 0) scale3d(1.08, 3, 1);
`;

export const servicesItemShow = {
  hidden: {
    top: "3.125rem",
    opacity: 0,
    transition: { duration: 0.3, type: "easyInOut" },
  },
  visible: {
    top: 0,
    opacity: 1,
    transition: { duration: 0.3, type: "easyInOut" },
  },
};
