import styled from "styled-components";
// Utils
import { calculateElementsWidthByGap } from "utils";
// Constants
import { TESTIMONIALS_LIST_GAP } from "../../constants";

export const StyledTestimonialsListWrapper = styled.div<{ remainsItems: number; testimonialsListGap: number }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ testimonialsListGap }) => `${testimonialsListGap}rem`};

  /* For remainsItems in last row need to calculate specific width */
  > div:nth-last-of-type(-n + ${({ remainsItems }) => remainsItems}) {
    width: ${({ remainsItems }) => calculateElementsWidthByGap(remainsItems, TESTIMONIALS_LIST_GAP)};
  }
`;
