import { ROUTES } from "navigation/routes";
import { EXTERNAL_LINKS } from "configs";

import { DropdownNavSection } from "../dropdown-menu-items/types";

export const OUR_COMPANY_DROPDOWN_LIST: DropdownNavSection[] = [
  {
    title: "About us",
    titleLink: `/${ROUTES.aboutUs}`,
    navItems: [],
  },
  {
    title: "Partnership",
    titleLink: "",
    navItems: [],
  },
  {
    title: "Investing for Startups",
    titleLink: "",
    navItems: [],
  },
  {
    title: "Careers",
    titleLink: `/${ROUTES.careers}`,
    navItems: [],
  },
  {
    title: "Support Ukraine",
    titleLink: EXTERNAL_LINKS.supportUkraine,
    isExternal: true,
    navItems: [],
  },
];

export const OUR_COMPANY_MEMBERS_LIST = [
  {
    imageSrc: "/images/alex-saiko-round.webp",
    name: "Alex Saiko",
    position: "CEO",
    linkedinLink: EXTERNAL_LINKS.linkedInAlexander,
    description:
      "Co-founder with over 20 years of experience in IT solution implementations within fintech and logistics. Responsible for managing and advancing Idealogic Group.",
  },
  {
    imageSrc: "/images/artem-zaitsev-round.webp",
    name: "Artem Zaitsev",
    position: "VP of Engineering",
    linkedinLink: EXTERNAL_LINKS.linkedInArtem,
    description:
      "Co-founder with over 10 years of experience in B2B blockchain integration services. Responsible for driving innovation and harnessing cutting-edge technologies.",
  },
];
