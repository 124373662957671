// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { ROUTES } from "navigation/routes";
// Types
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { ServicesPageContent, ServicesVariants } from "pages/services/types";

const BIG_DATA_CONTENT: ServicesPageContent = {
  banner: {
    title: "Big data",
    description:
      "We provide advanced solutions based on big data processing and analysis technologies to help your business make informed decisions and unlock the potential of data.",
    imageSrc: "/images/expertise/expertise-banner-big-data.webp",
    imageMobileSrc: "/images/expertise/expertise-banner-big-data-mobile.webp",
    imageRatio: 1.58,
  },
  customizedApplications: {
    title: "Big data expertise to transform your business",
    description:
      "Learn more about our approach to handling big data and how they take business operational capabilities to the next level.",
    customizedApplicationsList: [
      {
        label: "Data Architecture",
        description:
          "Our team provides database design, data modeling, and the overall framework for managing and processing information to ensure efficient storage, retrieval, and analysis.",
        iconSrc: "/images/expertise/big-data/big-data-data-architecture-icon.svg",
      },
      {
        label: "Real-time Streams",
        description:
          "We present real-time stream solutions so you can process the continuous data flows as soon as they are generated to react to events and provide analytics.",
        iconSrc: "/images/expertise/big-data/big-data-real-time-streamers-icon.svg",
      },
      {
        label: "Cloud Data Architecture",
        description:
          "We may store your data systems within cloud environments to leverage the scalability, flexibility, and accessibility of cloud services for efficient data management and processing.",
        iconSrc: "/images/expertise/big-data/big-data-cloud-architecture-icon.svg",
      },
      {
        label: "Data Lakes and Warehousing",
        description:
          "We manage unstructured data using data lakes, while data warehousing organizes and processes structured data for analysis.",
        iconSrc: "/images/expertise/big-data/big-data-data-lakes-and-warehouse-icon.svg",
      },
      {
        label: "Data Analytics and Compliance",
        description:
          "We provide data analytics tools so you can extract valuable insights from data interpretation, simultaneously ensuring adherence to regulatory standards.",
        iconSrc: "/images/expertise/big-data/big-data-data-analytick-and-complience-icon.svg",
      },
      {
        label: "Data Pipelines",
        description:
          "We create data pipelines to facilitate the movement and transformation of data from source to destination, automating the flow of information across various stages.",
        iconSrc: "/images/expertise/big-data/big-data-data-pipelines-icon.svg",
      },
    ],
  },
  chooseOurExperience: {
    title: "Why choose our expertise of big data?",
    description:
      "We focus on continuous improvement and learning. That, combined with years of experience, makes our services stand out.",
    imageSrc: "/images/expertise/expertise-banner-big-data-choose-our-experience.webp",
    imageRatio: 1.46,
    chooseOurExperienceList: [
      {
        label: "Adaptability and Innovation",
        description:
          "Our team follows the market trends to incorporate the best features and technologies into our projects.",
        iconSrc: "/images/expertise/choose-out-experience/web-development-lightbulb-icon.svg",
      },
      {
        label: "Training and Certifications",
        description:
          "We value education and encourage our employees to take the necessary courses by providing compensation.",
        iconSrc: "/images/expertise/choose-out-experience/web-development-square-academic-cap-icon.svg",
      },
      {
        label: "Various Complexity",
        description:
          "Our team does not hesitate to take on projects that require a unique and complex approach and specific knowledge.",
        iconSrc: "/images/expertise/choose-out-experience/web-development-square-academic-cap-icon.svg",
      },
      {
        label: "Security and Data Protection",
        description:
          "At Idealogic, we make a great deal of complying with all the necessary security requirements and guidelines.",
        iconSrc: "/images/expertise/choose-out-experience/web-development-fire-icon.svg",
      },
    ],
  },
  digitalExcellence: {
    title: "Crafting Digital Excellence",
    description:
      "With every completed project, we strive to give an unforgettable experience with our unique features and appealing designs.",

    digitalExcellenceList: {
      digitalExcellenceListFirst: [
        {
          label: "50+",
          description: "Projects Completed",
          isBigBorder: false,
        },
        {
          label: "99.9%",
          description: "Security Protocols",
          isBigBorder: true,
        },
      ],
      digitalExcellenceListSecond: [
        {
          label: "90%+",
          description: "Code Quality Metrics",
          isBigBorder: true,
        },

        {
          label: "5+",
          description: "Experience years",
          isBigBorder: false,
        },
      ],
    },
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.airpartChain, CaseStudiesSlugEnums.eplane],
    title: "Big data case studies",
    TitleIcon: WavingHandIcon,
    description:
      "Our big data solutions already proved themselves in the aviation industry – and there are even more to come. See for yourself.",
  },
  developmentProcess: {
    title: "Our big data process",
    description:
      "We’ve enhanced our workflow with the years passing. Learn more about our step-by-step approach to big data handling.",
    benefitsList: [
      {
        title: "Discover & Estimate",
        description:
          "We start by closely learning your business requirements, defining what technical solutions suit you best, and providing you with a detailed plan of work scope.",
      },
      {
        title: "Design and Prototype",
        description:
          "We present you with a vision of your future product with basic features so that you can make adjustments and define the course of future development.",
      },
      {
        title: "Development",
        description:
          "Our team of relevant specialists works on the technical implementation of the project, using cutting-edge technologies.",
      },
      {
        title: "Testing",
        description:
          "We do automation and manual testing to ensure every feature works properly and the product can perform to its full potential.",
      },
      {
        title: "Deployment",
        description:
          "We are ready to release the product only after we are satisfied with its usability, security compliance, and reliability.",
      },
      {
        title: "Maintenance",
        description:
          "Our team is keeping up with the product’s performance after the launch to forecast and prevent any technical issues and urgently react if something goes wrong.",
      },
    ],
  },
  boostBusiness: {
    title: "Our expert will be happy to discuss the details of your project!",
    description: "We will take your project and create something incredible for you.",
    buttonTitle: "Let’s talk!",
    personName: "Alex Sotnikov",
    personPosition: "Head of backend department",
    personImageSrc: "/images/expertise/expertise-banner-big-data-alex-sotnikov.webp",
    imageRatio: 1.16,
    bgColor: "bgDark",
  },
  coveredIndustries: {
    coveredIndustriesList: [
      { label: "Web Development", link: `/${ROUTES.services}/${ServicesVariants.webDevelopment}` },
      { label: "Mobile Development", link: `/${ROUTES.services}/${ServicesVariants.mobileDevelopment}` },
      { label: "UI/UX Design", link: `/${ROUTES.services}/${ServicesVariants.uiUxDesign}` },
      { label: "Blockchain", link: `/${ROUTES.services}/${ServicesVariants.blockchain}` },
      { label: "AI/ML Solutions", link: `/${ROUTES.services}/${ServicesVariants.aiMlSolutions}` },
      { label: "Cloud Solutions", link: `/${ROUTES.services}/${ServicesVariants.cloudSolutions}` },
    ],
  },
};

export default BIG_DATA_CONTENT;
