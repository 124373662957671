import React from "react";
import Slider from "react-slick";
// Components + styling
import TeamMemberItem from "../team-member-item";
import { StyledOurTeamContainer, StyledOurTeamSlider } from "./styled";
// Configs
import { sliderSettings } from "./configs";
// Types
import { OurTeamItemType } from "components/our-team/types";

const OurTeamSlider = React.forwardRef<Slider | null, { teamMembersList: OurTeamItemType[]; isTitleWhite?: boolean }>(
  ({ teamMembersList, isTitleWhite }, ref) => {
    return (
      <StyledOurTeamContainer>
        <StyledOurTeamSlider ref={ref} {...sliderSettings}>
          {teamMembersList.map(({ image, name, position, description }, index) => {
            return (
              <TeamMemberItem
                key={index}
                image={image}
                name={name}
                position={position}
                description={description}
                isTitleWhite={isTitleWhite}
              />
            );
          })}
        </StyledOurTeamSlider>
      </StyledOurTeamContainer>
    );
  },
);

OurTeamSlider.displayName = "OurTeamSlider";

export default OurTeamSlider;
