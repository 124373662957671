import { useTranslation } from "context";
import { useMatchBreakpoints } from "hooks";

import { Box, Column, Flex, Heading, Image, Link, Text } from "components";
import PortfolioItemLabel from "../portfolio-item-label";
import { StyledBigPortfolioBox, StyledBigPortfolioItem, StyledBigPortfolioItemInnerWrapper, StyledOwnerContainer } from "./styled";
import { StyledDividerLinkedinBlock } from "pages/industries/components/book-a-call-with-expert/styled";
import { LinkedInIcon } from "components/svg";
import { StyledPortfolioItemLabelText } from "../portfolio-item-label/styled";

import { BLOCK_ID } from "configs";

import { BigPortfolioItemType } from "components/portfolio/types";

const BigPortfolioItem: React.FC<BigPortfolioItemType> = ({
  itemLabel,
  bgImage,
  bgImageMobile,
  aspectRatio,
  aspectRatioMobile,
  logoWidth,
  logo,
  description,
  owner,
}) => {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useMatchBreakpoints();

  return (
    <>
      {isMobile && (
        <Column position="relative" zIndex="2">
          <PortfolioItemLabel {...itemLabel} isComingSoon={false} />
        </Column>
      )}

      <StyledBigPortfolioItem>
        <Box position="absolute" width="100%" height="100%">
          <Image
            src={isMobile ? bgImageMobile : bgImage}
            aspectRatio={isMobile ? aspectRatioMobile : aspectRatio}
            height="100%"
            id={BLOCK_ID.portfolioBigItemBg}
          />
        </Box>

        <StyledBigPortfolioItemInnerWrapper>
          <Image src={logo} aspectRatio={2.93} width={{ _: "7.1875rem", tablet: logoWidth }} />

          <Text
            textScale={isMobile ? "p14Regular" : "p24Regular"}
            color="white"
            pt={{ _: "0.5rem", tablet: "2rem" }}
            pb={{ _: "0", tablet: "4rem" }}
            maxWidth="29rem"
          >
            {description}
          </Text>

          <StyledOwnerContainer>
            <Image src={owner.image} aspectRatio={1} width="4rem" />

            <StyledBigPortfolioBox>
              <div>
                {isMobile ? (
                  <Heading as="h4" scale="h4" color="white" $fontWeight="bold" pb="0.25rem" whiteSpace="nowrap">
                    {owner.name}
                  </Heading>
                ) : (
                  <Text textScale="p20Regular" color="white" $fontWeight="bold" pb="0.25rem">
                    {owner.name}
                  </Text>
                )}
              </div>

              <Flex alignItems="center">
                <Text textScale="p16Regular" color="textFourthForDarkBg">
                  {owner.jobTitle}
                </Text>

                <Flex alignItems="center">
                  <StyledDividerLinkedinBlock />

                  <Link href={owner.link} external color="linkedIn">
                    <LinkedInIcon width={isMobile ? "1rem" : "1.5rem"} />

                    {!isMobile && (
                      <Text mx="0.5rem" color="linkedIn" textScale={isMobile ? "p16Bold" : "p20Bold"}>
                        {t("LinkedIn profile")}
                      </Text>
                    )}
                  </Link>
                </Flex>
              </Flex>
            </StyledBigPortfolioBox>
          </StyledOwnerContainer>
        </StyledBigPortfolioItemInnerWrapper>
      </StyledBigPortfolioItem>

      {isMobile && itemLabel.isComingSoon && (
        <StyledPortfolioItemLabelText
          color="disabledLight"
          textAlign="center"
          my="1rem"
          textScale={isDesktop ? "p16Regular" : "p14Regular"}
        >
          {t("Coming soon")}
        </StyledPortfolioItemLabelText>
      )}

      {!isMobile && (
        <Column position="relative" zIndex="2">
          <PortfolioItemLabel {...itemLabel} />
        </Column>
      )}
    </>
  );
};

export default BigPortfolioItem;
