import { useInView } from "framer-motion";
// Components + styling
import { AnimatedCharacterText, Button, Flex, Text } from "components";
import { StyledArrowIcon, StyledCurlyArrowIcon, StyledScheduleCallContainer } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Utils
import { scrollToElementById } from "utils";
// Constants
import { BLOCK_ID } from "configs";

import { ScheduleCallType } from "./types";

const ScheduleCall: React.FC<ScheduleCallType> = ({
  isWhite,
  buttonText,
  isNeedCurveLine = true,
  isBlackStyleForButton = false,
  isButtonPositionStart,
}) => {
  const { t } = useTranslation();
  const { isMobile, isDesktop, isLaptopL } = useMatchBreakpoints();

  const letsTalkRef = React.useRef<HTMLDivElement | null>(null);

  const isLetsTalkInView = useInView(letsTalkRef, { once: true, amount: 1 });

  return (
    <StyledScheduleCallContainer isButtonPositionStart={isButtonPositionStart}>
      <Button
        minWidth={{ _: "100%", laptop: "14.625rem" }}
        scale="lg"
        height={{ _: "3.125rem", laptop: "3.75rem" }}
        hoverLinesPosition={isMobile || isLaptopL ? "bottomLeft" : "topRight"}
        onClick={() => scrollToElementById(BLOCK_ID.contactUs)}
        bgColor={isBlackStyleForButton ? "bgDarkSecond" : undefined}
        color={isBlackStyleForButton ? "white" : "bgDark"}
      >
        {t(buttonText ? buttonText : "Schedule a call")}
      </Button>

      {isDesktop && isNeedCurveLine && (
        <Flex ref={letsTalkRef} mx="2rem" alignItems="center">
          {isLetsTalkInView &&
            (isBlackStyleForButton ? (
              <StyledArrowIcon animationType="curlyArrow" width={isLaptopL ? "6.6875rem" : "4rem"} />
            ) : (
              <StyledCurlyArrowIcon width={isLaptopL ? "2rem" : "1.5rem"} animationType="curlyArrow" />
            ))}

          <Text
            textScale={isLaptopL ? "decorative22Light" : "decorative16Light"}
            mx="1.5rem"
            color={isWhite ? "textMain" : "white"}
            whiteSpace={isBlackStyleForButton ? "nowrap" : "wrap"}
          >
            <AnimatedCharacterText text="Let's talk more" animate={isLetsTalkInView ? "visible" : "hidden"} />
          </Text>
        </Flex>
      )}
    </StyledScheduleCallContainer>
  );
};

export default ScheduleCall;
