import { useMatchBreakpoints } from "hooks";
// Components + styling
import { Column, FlexGap, Text } from "components";
import { StyledSocialsItem } from "./styled";
import { StyledDropdownTitleContainer } from "components/header/components/industries-dropdown/styled";
import { ServicesSocialLinksIcon } from "components/svg";
// Context
import { useTranslation } from "context";
// Constants
import { SOCIAL_LINKS_LIST } from "./constants";

const CompanyDropdownDescription: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <Column mb={{ _: "1.25rem", laptop: "0" }} mt="3.375rem">
      <StyledDropdownTitleContainer>
        <ServicesSocialLinksIcon width="1.8125rem" height="1.75rem" />

        <Text textScale="p20Bold" color={isDesktop ? "white" : "textFourth"}>
          {t("Social links")}
        </Text>
      </StyledDropdownTitleContainer>

      <FlexGap gap="1.5rem" flexWrap="wrap">
        {SOCIAL_LINKS_LIST.map(({ Icon, label, link }) => (
          <StyledSocialsItem key={label} href={link} external>
            <Icon width="1.75rem" />

            <Text px="0.5rem" textScale="p16Medium" color="white">
              {t(label)}
            </Text>
          </StyledSocialsItem>
        ))}
      </FlexGap>
    </Column>
  );
};

export default CompanyDropdownDescription;
