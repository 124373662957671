// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { ROUTES } from "navigation/routes";
// Types
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
import { ServicesPageContent, ServicesVariants } from "pages/services/types";

const UI_UX_DESIGN_CONTENT: ServicesPageContent = {
  banner: {
    title: "UI/UX Design",
    description:
      "We work on designs for software applications, tools, platforms, etc., ensuring immersive experiences and responsive interfaces that align with functionality.\n\n We also have a separate design studio for product design development! Check it out.",
    imageSrc: "/images/expertise/expertise-banner-ui_ux_design.webp",
    imageMobileSrc: "/images/expertise/expertise-banner-ui_ux_design-mobile.webp",
    imageRatio: 1.33,
  },
  customizedApplications: {
    title: "Enhance your product with our UI/UX expertise",
    description:
      "We work with various design requests to take your digital presence to the next level. Learn more about our capabilities.",
    customizedApplicationsList: [
      {
        label: "Mobile UI/UX Design",
        description:
          "We design and present visually appealing and intuitive solutions for mobile applications that align with various screen types and devices.",
        iconSrc: "/images/expertise/ui-ux/ui-ux-mobile-expertise-icon.svg",
      },
      {
        label: "Web UI/UX Design",
        description:
          "Our team delivers user-centric web interfaces to enhance your digital presence and ensure accessibility and increased online engagement.",
        iconSrc: "/images/expertise/ui-ux/ui-ux-web-expertise-icon.svg",
      },
      {
        label: "UX Audit & CRO",
        description:
          "We conduct a comprehensive UX Audit to detect weak moments in usability and improve user journeys, increasing conversion rates and overall performance.",
        iconSrc: "/images/expertise/ui-ux/ui-ux-audit-icon.svg",
      },
      {
        label: "Prototyping",
        description:
          "With our designed prototypes, you can get a tangible preview of your product’s functionality and user experience to streamline the development process.",
        iconSrc: "/images/expertise/ui-ux/ui-ux-prototyping-icon.svg",
      },
      {
        label: "Product Videos",
        description:
          "We provide comprehensible videos that effectively communicate features, benefits, and value propositions to engage users with your product or service.",
        iconSrc: "/images/expertise/ui-ux/ui-ux-product-videos-icon.svg",
      },
      {
        label: "Dashboard Visualization",
        description:
          "We transform complex data into visually compelling narratives, enabling you to clearly communicate key messages and make informed decisions.",
        iconSrc: "/images/expertise/ui-ux/ui-ux-dahsboard-visualization-icon.svg",
      },
    ],
  },
  chooseOurExperience: {
    title: "Why choose our expertise of UI/UX design?",
    description:
      "We focus on continuous improvement and learning. That, combined with years of experience, makes our services stand out.",
    imageSrc: "/images/expertise/expertise-banner-ui_ux_design-choose-our-experience.webp",
    imageRatio: 1.46,
    chooseOurExperienceList: [
      {
        label: "Adaptability and Innovation",
        description:
          "Our team follows the market trends to incorporate the best features and technologies into our projects.",
        iconSrc: "/images/expertise/choose-out-experience/web-development-lightbulb-icon.svg",
      },
      {
        label: "Training and Certifications",
        description:
          "We value education and encourage our employees to take the necessary courses by providing compensation.",
        iconSrc: "/images/expertise/choose-out-experience/web-development-square-academic-cap-icon.svg",
      },
      {
        label: "Various Complexity",
        description:
          "Our team does not hesitate to take on projects that require a unique and complex approach and specific knowledge.",
        iconSrc: "/images/expertise/choose-out-experience/web-development-fire-icon.svg",
      },
      {
        label: "Security and Data Protection",
        description:
          "At Idealogic, we make a great deal of complying with all the necessary security requirements and guidelines.",
        iconSrc: "/images/expertise/choose-out-experience/web-development-shield-icon.svg",
      },
    ],
  },
  digitalExcellence: {
    title: "Crafting Digital Excellence",
    description:
      "With every completed project, we strive to give an unforgettable experience with our unique features and appealing designs.",
    digitalExcellenceList: {
      digitalExcellenceListFirst: [
        {
          label: "50+",
          description: "Projects Completed",
          isBigBorder: false,
        },
        {
          label: "99.9%",
          description: "Security Protocols",
          isBigBorder: true,
        },
      ],
      digitalExcellenceListSecond: [
        {
          label: "90%+",
          description: "Code Quality Metrics",
          isBigBorder: true,
        },

        {
          label: "5+",
          description: "Experience years",
          isBigBorder: false,
        },
      ],
    },
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.ugreator, CaseStudiesSlugEnums.aiBook],
    title: "UI/UX case studies",
    TitleIcon: WavingHandIcon,
    description:
      "Take a glance at some of our design works. We deliver designs that fulfil business objectives and make the product unique.",
  },
  developmentProcess: {
    title: "Our UI/UX design process",
    description:
      "We’ve enhanced our workflow with the years passing. Learn more about our step-by-step approach to UI/UX design.",
    benefitsList: [
      {
        title: "Research and Discovery",
        description:
          "We start by gathering information about target audiences' preferences and behavior to enable a user-centric approach to the design process.",
      },
      {
        title: "Wireframing",
        description:
          "Our team creates simplified, structural blueprints of a design to outline the layout and functionality, providing a visual guide before moving into detailed visual design.",
      },
      {
        title: "Design Ideation",
        description:
          "We develop concepts and visual elements, ensuring they align with the business objectives and users needs discovered during the research phase.",
      },
      {
        title: "Prototyping and Testing",
        description:
          "Prototyping is the iterative process to test the usability of the product and design effectiveness before finally implementing it.",
      },
      {
        title: "Finalization and Handoff",
        description:
          "We refine our designs and prepare detailed specifications for the development team to ensure a smooth transition from design to development.",
      },
    ],
  },
  boostBusiness: {
    title: "Our expert will be happy to discuss the details of your project!",
    description: "We will take your project and create something incredible for you.",
    buttonTitle: "Let’s talk!",
    personName: "Victor Zhytomyrskyi",
    personPosition: "Head of design department",
    personImageSrc: "/images/expertise/expertise-banner-ui-ux-design-victor-zhytomyrskyi.webp",
    imageRatio: 1.12,
    bgColor: "bgDark",
  },
  coveredIndustries: {
    coveredIndustriesList: [
      { label: "Web Development", link: `/${ROUTES.services}/${ServicesVariants.webDevelopment}` },
      { label: "Mobile Development", link: `/${ROUTES.services}/${ServicesVariants.mobileDevelopment}` },
      { label: "AI/ML Solutions", link: `/${ROUTES.services}/${ServicesVariants.aiMlSolutions}` },
      { label: "Blockchain", link: `/${ROUTES.services}/${ServicesVariants.blockchain}` },
      { label: "Big Data", link: `/${ROUTES.services}/${ServicesVariants.bigData}` },
      { label: "Cloud Solutions", link: `/${ROUTES.services}/${ServicesVariants.cloudSolutions}` },
    ],
  },
};

export default UI_UX_DESIGN_CONTENT;
