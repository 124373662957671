import { Navigate, Route, Routes } from "react-router-dom";
// Components
import { NotFoundPage } from "components";
import { MainOutlet } from "./components";
// Pages
import {
  AboutUsPage,
  BlogsPage,
  CalendlyPage,
  CareersPage,
  CasePages,
  CaseStudiesPage,
  FindSolutionPage,
  HealthLifePage,
  IndustriesOutlet,
  ServicesOutlet,
  LandingPage,
  NextparqPage,
  PikkUpPage,
  PositionPage,
  PrivacyPolicyPage,
  SingleBlogPage,
  SingleCaseStudy,
  UbeatPage,
  SitemapPage,
} from "pages";

import {
  DefaultPageIndustries,
  BankingPage,
  FinTechPage,
  BlockchainPage,
  CryptoCurrencyPage,
  DigitalBankingPage,
  AiBasedAviationPage,
  SparePartsMarketplacePage,
  IntelligentInventoryManagementPage,
  BlockchainBasedAviationPage,
  CustomRealEstatePage,
  PropertyManagementSolutionsPage,
  AgentBrokersSolutionsPage,
} from "pages/industries/components/page-structure";

import {
  AiConsultingPage,
  AiMlSolutionsMainPage,
  AiMobileDevelopmentPage,
  AiSoftwareDevelopmentPage,
  CustomerExperienceConsultingPage,
  DataSciencePage,
  DefaultPageServices,
  DefaultForOldExpertisePage,
  MlConsultingPage,
  MlDevelopmentPage,
  MVPDevelopmentPage,
} from "pages/services/components/page-structure";

// Constants
import { ROUTES, ROUTE_PARAMS } from "./routes";
import { IndustryContentMapper } from "pages/industries/constants";
import { ServiceContentMapper } from "pages/services/constants";

const Navigation: React.FC = () => {
  return (
    <Routes>
      <Route path={ROUTES.landing} element={<MainOutlet />}>
        <Route index element={<LandingPage />} />
        <Route path={ROUTES.aboutUs} element={<AboutUsPage />} />

        <Route path={ROUTES.calendly} element={<CalendlyPage />} />

        <Route path={ROUTES.careers}>
          <Route index element={<CareersPage />} />

          <Route path={`:${ROUTE_PARAMS.careersType}`}>
            <Route index element={<PositionPage />} />
          </Route>
        </Route>

        <Route path={ROUTES.industries}>
          <Route index element={<Navigate replace to={ROUTES.fintech} />} />

          <Route path={ROUTES.fintech} element={<IndustriesOutlet content={IndustryContentMapper.fintech} />}>
            <Route index element={<FinTechPage content={IndustryContentMapper.fintech} />} />
          </Route>

          <Route path={ROUTES.banking} element={<IndustriesOutlet content={IndustryContentMapper.banking} />}>
            <Route index element={<BankingPage content={IndustryContentMapper.banking} />} />
          </Route>

          <Route
            path={ROUTES.cryptoCurrency}
            element={<IndustriesOutlet content={IndustryContentMapper.cryptoCurrency} />}
          >
            <Route index element={<CryptoCurrencyPage content={IndustryContentMapper.cryptoCurrency} />} />
          </Route>

          <Route path={ROUTES.logistics} element={<IndustriesOutlet content={IndustryContentMapper.logistics} />}>
            <Route index element={<DefaultPageIndustries content={IndustryContentMapper.logistics} />} />
          </Route>

          <Route
            path={ROUTES.digitalBanking}
            element={<IndustriesOutlet content={IndustryContentMapper.digitalBanking} />}
          >
            <Route index element={<DigitalBankingPage content={IndustryContentMapper.digitalBanking} />} />
          </Route>

          <Route
            path={ROUTES.aiBasedAviation}
            element={<IndustriesOutlet content={IndustryContentMapper.aiBasedAviation} />}
          >
            <Route index element={<AiBasedAviationPage content={IndustryContentMapper.aiBasedAviation} />} />
          </Route>

          <Route
            path={ROUTES.blockchainBasedAviation}
            element={<IndustriesOutlet content={IndustryContentMapper.blockchainBasedAviation} />}
          >
            <Route
              index
              element={<BlockchainBasedAviationPage content={IndustryContentMapper.blockchainBasedAviation} />}
            />
          </Route>

          <Route
            path={ROUTES.intelligentInventoryManagement}
            element={<IndustriesOutlet content={IndustryContentMapper.intelligentInventoryManagement} />}
          >
            <Route
              index
              element={
                <IntelligentInventoryManagementPage content={IndustryContentMapper.intelligentInventoryManagement} />
              }
            />
          </Route>

          <Route path={ROUTES.aviation} element={<IndustriesOutlet content={IndustryContentMapper.aviation} />}>
            <Route index element={<DefaultPageIndustries content={IndustryContentMapper.aviation} />} />
          </Route>

          <Route
            path={ROUTES.sparePartsMarketplace}
            element={<IndustriesOutlet content={IndustryContentMapper.sparePartsMarketplace} />}
          >
            <Route
              index
              element={<SparePartsMarketplacePage content={IndustryContentMapper.sparePartsMarketplace} />}
            />
          </Route>

          <Route path={ROUTES.realEstate} element={<IndustriesOutlet content={IndustryContentMapper.realEstate} />}>
            <Route index element={<DefaultPageIndustries content={IndustryContentMapper.realEstate} />} />
          </Route>

          <Route
            path={ROUTES.customRealEstate}
            element={<IndustriesOutlet content={IndustryContentMapper.customRealEstate} />}
          >
            <Route index element={<CustomRealEstatePage content={IndustryContentMapper.customRealEstate} />} />
          </Route>

          <Route
            path={ROUTES.propertyManagementSolutions}
            element={<IndustriesOutlet content={IndustryContentMapper.propertyManagementSolutions} />}
          >
            <Route
              index
              element={<PropertyManagementSolutionsPage content={IndustryContentMapper.propertyManagementSolutions} />}
            />
          </Route>

          <Route
            path={ROUTES.agentBrokersSolutions}
            element={<IndustriesOutlet content={IndustryContentMapper.agentBrokersSolutions} />}
          >
            <Route
              index
              element={<AgentBrokersSolutionsPage content={IndustryContentMapper.agentBrokersSolutions} />}
            />
          </Route>

          <Route path={ROUTES.media} element={<IndustriesOutlet content={IndustryContentMapper.media} />}>
            <Route index element={<DefaultPageIndustries content={IndustryContentMapper.media} />} />
          </Route>

          <Route path={ROUTES.other} element={<IndustriesOutlet content={IndustryContentMapper.other} />}>
            <Route index element={<DefaultPageIndustries content={IndustryContentMapper.other} />} />
          </Route>
        </Route>

        <Route path={ROUTES.services}>
          <Route index element={<Navigate replace to={ROUTES.mvpDevelopment} />} />

          <Route
            path={ROUTES.mvpDevelopment}
            element={<ServicesOutlet content={ServiceContentMapper.mvpDevelopment} />}
          >
            <Route index element={<MVPDevelopmentPage content={ServiceContentMapper.mvpDevelopment} />} />
          </Route>

          <Route
            path={ROUTES.technicalConsulting}
            element={<ServicesOutlet content={ServiceContentMapper.technicalConsulting} />}
          >
            <Route index element={<DefaultPageServices content={ServiceContentMapper.technicalConsulting} />} />
          </Route>

          <Route
            path={ROUTES.maintenanceSupport}
            element={<ServicesOutlet content={ServiceContentMapper.maintenanceSupport} />}
          >
            <Route index element={<DefaultPageServices content={ServiceContentMapper.maintenanceSupport} />} />
          </Route>

          <Route path={ROUTES.dedicatedTeam} element={<ServicesOutlet content={ServiceContentMapper.dedicatedTeam} />}>
            <Route index element={<DefaultPageServices content={ServiceContentMapper.dedicatedTeam} />} />
          </Route>

          <Route path={ROUTES.uiUxDesign} element={<ServicesOutlet content={ServiceContentMapper.uiUxDesign} />}>
            <Route index element={<DefaultForOldExpertisePage content={ServiceContentMapper.uiUxDesign} />} />
          </Route>

          <Route
            path={ROUTES.webDevelopment}
            element={<ServicesOutlet content={ServiceContentMapper.webDevelopment} />}
          >
            <Route index element={<DefaultForOldExpertisePage content={ServiceContentMapper.webDevelopment} />} />
          </Route>

          <Route
            path={ROUTES.mobileDevelopment}
            element={<ServicesOutlet content={ServiceContentMapper.mobileDevelopment} />}
          >
            <Route index element={<DefaultForOldExpertisePage content={ServiceContentMapper.mobileDevelopment} />} />
          </Route>

          <Route path={ROUTES.aiMlSolutions} element={<ServicesOutlet content={ServiceContentMapper.aiMlSolutions} />}>
            <Route index element={<AiMlSolutionsMainPage content={ServiceContentMapper.aiMlSolutions} />} />
          </Route>

          <Route path={ROUTES.aiConsulting} element={<ServicesOutlet content={ServiceContentMapper.aiConsulting} />}>
            <Route index element={<AiConsultingPage content={ServiceContentMapper.aiConsulting} />} />
          </Route>

          <Route
            path={ROUTES.aiSoftwareDevelopment}
            element={<ServicesOutlet content={ServiceContentMapper.aiSoftwareDevelopment} />}
          >
            <Route index element={<AiSoftwareDevelopmentPage content={ServiceContentMapper.aiSoftwareDevelopment} />} />
          </Route>

          <Route
            path={ROUTES.aiMobileDevelopment}
            element={<ServicesOutlet content={ServiceContentMapper.aiMobileDevelopment} />}
          >
            <Route index element={<AiMobileDevelopmentPage content={ServiceContentMapper.aiMobileDevelopment} />} />
          </Route>

          <Route path={ROUTES.bigData} element={<ServicesOutlet content={ServiceContentMapper.bigData} />}>
            <Route index element={<DefaultForOldExpertisePage content={ServiceContentMapper.bigData} />} />
          </Route>

          <Route
            path={ROUTES.cloudSolutions}
            element={<ServicesOutlet content={ServiceContentMapper.cloudSolutions} />}
          >
            <Route index element={<DefaultForOldExpertisePage content={ServiceContentMapper.cloudSolutions} />} />
          </Route>

          <Route path={ROUTES.blockchain} element={<ServicesOutlet content={ServiceContentMapper.blockchain} />}>
            <Route index element={<BlockchainPage content={ServiceContentMapper.blockchain} />} />
          </Route>

          <Route path={ROUTES.dataScience} element={<ServicesOutlet content={ServiceContentMapper.dataScience} />}>
            <Route index element={<DataSciencePage content={ServiceContentMapper.dataScience} />} />
          </Route>

          <Route path={ROUTES.mlConsulting} element={<ServicesOutlet content={ServiceContentMapper.mlConsulting} />}>
            <Route index element={<MlConsultingPage content={ServiceContentMapper.mlConsulting} />} />
          </Route>

          <Route path={ROUTES.mlDevelopment} element={<ServicesOutlet content={ServiceContentMapper.mlDevelopment} />}>
            <Route index element={<MlDevelopmentPage content={ServiceContentMapper.mlDevelopment} />} />
          </Route>

          <Route
            path={ROUTES.customerExperienceConsulting}
            element={<ServicesOutlet content={ServiceContentMapper.customerExperienceConsulting} />}
          >
            <Route
              index
              element={<CustomerExperienceConsultingPage content={ServiceContentMapper.customerExperienceConsulting} />}
            />
          </Route>
        </Route>

        <Route path={ROUTES.findSolution} element={<FindSolutionPage />} />

        <Route path={ROUTES.caseStudies}>
          <Route index element={<CaseStudiesPage />} />

          <Route path={`:${ROUTE_PARAMS.caseStudy}?`}>
            <Route index element={<SingleCaseStudy />} />

            {/* TODO: remove planetcoin folder lastly */}
            <Route
              path={ROUTES.nextparq}
              element={
                <CasePages>
                  <NextparqPage />
                </CasePages>
              }
            />

            <Route
              path={ROUTES.pikkUp}
              element={
                <CasePages>
                  <PikkUpPage />
                </CasePages>
              }
            />

            <Route
              path={ROUTES.ubeat}
              element={
                <CasePages>
                  <UbeatPage />
                </CasePages>
              }
            />

            <Route
              path={ROUTES.healthLife}
              element={
                <CasePages>
                  <HealthLifePage />
                </CasePages>
              }
            />
          </Route>
        </Route>

        <Route path={ROUTES.blog}>
          <Route index element={<BlogsPage />} />

          <Route path={`:${ROUTE_PARAMS.slug}`}>
            <Route index element={<SingleBlogPage />} />
          </Route>
        </Route>

        <Route path={ROUTES.sitemap} element={<SitemapPage />} />

        <Route path={ROUTES.privacyPolicy} element={<PrivacyPolicyPage />} />
      </Route>

      <Route path={ROUTES.notFoundPage} element={<NotFoundPage />} />

      <Route path="*" element={<Navigate to={ROUTES.notFoundPage} />} />
    </Routes>
  );
};

export default Navigation;
