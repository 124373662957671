import styled from "styled-components";
// Components
import { Text } from "components/text";
// Constants
import { headerThemes, headerThemesType } from "components/header/constants";
import { linesMovingFromDifferentDirectionsAnimation } from "theme/animations";
// Types
import { Colors } from "theme/types";

export const StyledLanguageBox = styled(Text)<{ isActive: boolean; headerTheme: headerThemesType }>`
  margin: 0rem 0.375rem;
  border-bottom: 1px solid
    ${({ theme, isActive, headerTheme }) =>
      theme.colors[isActive ? (headerTheme === headerThemes.light ? "textMain" : "white") : "transparent"]};
  transition: ${({ theme }) => theme.transitions.default};
  cursor: pointer;

  -webkit-text-fill-color: initial;

  &:hover {
    color: ${({ theme, headerTheme }) =>
      headerTheme === headerThemes.light ? theme.colors.textMain : theme.colors.white};
  }
`;

export const StyledLanguageWrapper = styled.div<{ isHeaderThemeLight: boolean; svgColor: keyof Colors }>`
  display: flex;
  position: relative;

  &::before {
    content: "";
    clear: both;
    width: 100%;
    height: 0.5px;
    position: absolute;
    top: 100%;
    left: 0;
    transition:
      clip-path 0.3s,
      transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
    clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%);
  }

  svg path {
    fill: none;
    stroke: ${({ svgColor, theme }) => theme.colors[svgColor]};
  }

  &:hover {
    &::before {
      background: ${({ theme, isHeaderThemeLight }) =>
        isHeaderThemeLight ? theme.colors.bgDark : theme.gradients.buttonAccentHover};
      clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 50% 100%, 0 100%, 100% 100%, 100% 0%);
      ${linesMovingFromDifferentDirectionsAnimation}
    }

    svg path {
      stroke: url("#hoveredAboutUs");
    }

    background-image: ${({ theme }) => theme.gradients.blogShowMoreGradient};
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
`;

export const StyledDropdownComponentWrapper = styled.div`
  background: ${({ theme }) => theme.colors.bgDark};
  border-radius: ${({ theme }) => theme.radii.semiLarge};

  padding: 1.5rem;
  box-shadow: 0px 2rem 8.125rem 0px #ffffff33;

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 1.5rem 2rem;
  }
`;
