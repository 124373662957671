// Components + styling
import { Heading, Image, Text } from "components";
import { StyledStagesBlockTechnologiesListContainer, StyledStagesBlocksItemWrapper } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Utils
import { scrollToElementById } from "utils";
// Constants
import { BLOCK_ID } from "configs";
// Types
import { StagesBlocksItemProps } from "./types";

const StagesBlocksItem: React.FC<StagesBlocksItemProps> = ({
  title,
  description,
  iconSrc,
  itemsLength,
  hoverBorderColor,
  isTwoColumn = false,
  isDarkVariant = false,
  technologiesList,
}) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <StyledStagesBlocksItemWrapper
      onClick={() => scrollToElementById(BLOCK_ID.contactUs)}
      itemsLength={itemsLength!}
      isTwoColumn={isTwoColumn}
      isDarkVariant={isDarkVariant}
      hoverBorderColor={hoverBorderColor}
    >
      <Image width={isDesktop ? "3rem" : "2rem"} src={iconSrc} aspectRatio={1} />

      <Heading
        scale="h5"
        as="h3"
        mt={{ _: "0.75rem", laptop: "1.5rem" }}
        mb={{ _: "1rem", laptop: "2.625rem" }}
        $fontWeight="bold"
        color={isDarkVariant ? "white" : "textMain"}
      >
        {t(title)}
      </Heading>

      <Text textScale={isDesktop ? "p18Regular" : "p14Regular"} color={isDarkVariant ? "textThird" : "textFourth"}>
        {t(description)}
      </Text>

      {technologiesList?.length && (
        <StyledStagesBlockTechnologiesListContainer>
          {technologiesList.map(({ iconSrc, width, aspectRatio, mobileWidth }, index) => (
            <Image
              key={index}
              width={{ _: mobileWidth ?? "2rem", laptop: width ?? "2.5rem" }}
              src={iconSrc}
              aspectRatio={aspectRatio ?? 1}
            />
          ))}
        </StyledStagesBlockTechnologiesListContainer>
      )}
    </StyledStagesBlocksItemWrapper>
  );
};

export default StagesBlocksItem;
