import React from "react";
import { useInView } from "framer-motion";
// Components + styling
import { Box, Heading, Text } from "components";
import { TitleStraightLinesIcon } from "components/svg";
import { InputError, InputIcon, StyledInputGroup } from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { InputGroupProps } from "./types";
import { scales as inputScales, scales } from "components/inputs/input/types";

export const InputGroup: React.FC<InputGroupProps> = ({
  scale = inputScales.MD,
  startIcon,
  endIcon,
  children,
  error,
  label,
  isTouched,
  isShowError = true,
  isShowInputLines = false,
  labelColor = "textMain",
  ...props
}) => {
  const { isDesktop } = useMatchBreakpoints();

  const ref = React.useRef<HTMLDivElement | null>(null);
  const isInView = useInView(ref, { once: true });

  return (
    <StyledInputGroup scale={scale} hasStartIcon={!!startIcon} hasEndIcon={!!endIcon} ref={ref} {...props}>
      {label && (
        <Box minWidth="fit-content" position="relative">
          {scale === scales.SM || scale === scales.MD ? (
            <Text textScale={scale === scales.SM ? "p20Regular" : "p24Regular"} color={labelColor}>
              {label}
            </Text>
          ) : (
            <Heading scale="h4" as="h4" $fontWeight="regular" color={labelColor}>
              {label}
            </Heading>
          )}
        </Box>
      )}

      <Box width="100%">
        <Box position="relative">
          {isShowInputLines && isInView && (
            <Box
              position="absolute"
              top={isDesktop ? "-1.375rem" : "-1.125rem"}
              left={isDesktop ? "-1.375rem" : "-1.125rem"}
            >
              <TitleStraightLinesIcon width="2rem" />
            </Box>
          )}
          {startIcon && <InputIcon scale={scale}>{startIcon}</InputIcon>}

          {React.cloneElement(children, { scale, error, isTouched })}

          {endIcon && (
            <InputIcon scale={scale} isEndIcon>
              {endIcon}
            </InputIcon>
          )}
        </Box>
        {isShowError && <InputError>{error && isTouched ? error : " "}</InputError>}
      </Box>
    </StyledInputGroup>
  );
};
