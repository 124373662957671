import ScrollContainer from "react-indiana-drag-scroll";
// Components + styling
import { Tab } from "components";
import { StyledTabPanelWrapper } from "./styled";
// Types
import { TabsPanelProps } from "./types";

const TabsPanel: React.FC<TabsPanelProps> = ({
  titles,
  activeTab,
  onTabClick,
  textScale,
  activeTextScale,
  defaultTextColor,
}) => {
  return (
    <ScrollContainer>
      <StyledTabPanelWrapper>
        {titles.map((title, index) => (
          <Tab
            key={title}
            title={title}
            onClick={() => onTabClick(index)}
            isActive={activeTab === index}
            textScale={textScale}
            activeTextScale={activeTextScale}
            defaultTextColor={defaultTextColor}
          />
        ))}
      </StyledTabPanelWrapper>
    </ScrollContainer>
  );
};

export default TabsPanel;
