// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "white", ...props }) => {
  return (
    <Svg viewBox="0 0 107 107" width="107" height="107" {...props} color={color}>
      <g clipPath="url(#clip0_53_32686)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M62.7188 61.5752C63.4709 61.6847 64.227 61.7878 64.8685 61.8383C76.3061 62.7989 88.8754 60.7239 98.9123 55.1455C99.2748 54.9402 99.4261 54.49 99.2216 54.1228C99.0172 53.7556 98.5723 53.6349 98.2095 53.8407C88.3691 59.2377 76.1366 61.2583 64.9847 60.3193C64.6257 60.2952 64.211 60.2419 63.795 60.1908C64.2582 59.3866 64.4234 58.5795 64.4027 57.8258C64.3398 56.6867 63.8034 55.5826 62.9582 54.6034C61.7712 53.2379 60.0323 52.1369 58.2364 51.4571C56.851 50.9484 55.4318 50.7143 54.35 50.8733C53.4508 50.9836 52.7557 51.3497 52.2988 51.9202C51.8203 52.5229 51.6053 53.4039 51.9067 54.6351C52.3763 56.7361 53.8932 58.2819 55.9152 59.4087C57.392 60.2291 59.1139 60.795 60.7983 61.1931C60.3612 61.5098 59.8497 61.8254 59.2625 62.1422C54.7247 64.4375 47.428 64.5777 39.6476 63.5342C27.8117 61.9357 14.845 57.5423 8.71069 53.479C8.39874 53.2724 7.89767 53.3482 7.66458 53.6965C7.4315 54.0447 7.55417 54.5342 7.86612 54.7408C14.1562 58.9076 27.3549 63.4023 39.4592 65.0484C47.5871 66.1331 55.2383 65.9128 59.9417 63.4823C61.1084 62.8601 62.0497 62.2386 62.7188 61.5752ZM62.166 59.9348C62.6926 59.2601 62.9198 58.5846 62.9114 57.9246C62.8628 57.1004 62.4208 56.3034 61.8331 55.6121C60.8184 54.4377 59.2818 53.4819 57.725 52.8931C56.6421 52.4932 55.5221 52.2612 54.641 52.3445C54.1542 52.3991 53.7555 52.5456 53.4831 52.8405C53.258 53.1768 53.2367 53.6578 53.3483 54.2757C53.7747 55.9928 55.0666 57.2012 56.6533 58.0817C58.2952 58.9916 60.3597 59.6067 62.166 59.9348Z"
          fill="url(#paint0_linear_53_32686)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.5088 53.6288C94.9349 56.1656 92.002 59.9861 90.0578 63.8996C89.8783 64.2799 90.0247 64.7336 90.3647 64.8982C90.7562 65.098 91.2269 64.956 91.406 64.5762C93.2693 60.8959 96.0033 57.2597 99.3998 54.8758C99.7213 54.6197 99.8099 54.1508 99.5822 53.8183C99.3024 53.4517 98.8303 53.3728 98.5088 53.6288Z"
          fill="url(#paint1_linear_53_32686)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.5467 53.7895C94.5191 54.9864 90.0004 53.5532 86.3499 51.7205C86.0099 51.556 85.5392 51.6986 85.3597 52.0789C85.1805 52.4587 85.3275 52.9121 85.7194 53.1113C89.6546 55.0791 94.5971 56.5506 98.9954 55.2484C99.383 55.1177 99.5971 54.6855 99.4858 54.2913C99.3748 53.8966 98.934 53.6593 98.5467 53.7895Z"
          fill="url(#paint2_linear_53_32686)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_53_32686"
          x1="71.3142"
          y1="94.1169"
          x2="14.8216"
          y2="34.2364"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B3B3B" />
          <stop offset="0.402783" stopColor="#483219" />
          <stop offset="0.75" stopColor="#543912" />
          <stop offset="1" stopColor="#5C3400" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_53_32686"
          x1="92.356"
          y1="65.3725"
          x2="89.6371"
          y2="63.4223"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B3B3B" />
          <stop offset="0.402783" stopColor="#483219" />
          <stop offset="0.75" stopColor="#543912" />
          <stop offset="1" stopColor="#5C3400" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_53_32686"
          x1="95.9979"
          y1="59.5861"
          x2="87.0595"
          y2="48.8231"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B3B3B" />
          <stop offset="0.402783" stopColor="#483219" />
          <stop offset="0.75" stopColor="#543912" />
          <stop offset="1" stopColor="#5C3400" />
        </linearGradient>
        <clipPath id="clip0_53_32686">
          <rect width="76.8317" height="76.8317" fill="white" transform="translate(64 107) rotate(-146.203)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
