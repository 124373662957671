// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textFifth", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 61 47" {...props} animationType="chooseProjectArrow" color="transparent">
      <path
        d="M.813 6.208c22.197 3.96 40.557 12.174 58.513 33.553"
        stroke={theme.colors[color]}
        strokeWidth=".976"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.022 35.414c-.182 1.189.136 2.454.476 3.577.2.665.398 1.355.777 1.943-1.767-.947-3.457-1.528-5.486-1.462"
        stroke={theme.colors[color]}
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
