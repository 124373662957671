import styled from "styled-components";
// Components
import { Link, Text } from "components";
// Types
import { ContactUsTheme } from "./types";
// Constants
import { contactUsColorsSchema } from "./constants";

export const StyledSubmitInfo = styled(Text)`
  margin-top: 1.125rem;
  text-align: center;
`;

export const StyledInlinedLink = styled(Link)`
  display: inline;
  cursor: pointer;
`;

export const StyledContactUsContainer = styled.div<{ isShowCommentInput?: boolean }>`
  padding: 2.5rem 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: ${({ isShowCommentInput }) => (isShowCommentInput ? "6rem 0 0rem" : "6rem 0 2rem")};
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    padding: ${({ isShowCommentInput }) => (isShowCommentInput ? "6rem 0 0rem" : "6rem 0 3.625rem")};
    align-items: center;
    gap: 2rem;
  }
`;

export const StyledContainerWrapper = styled.div<{ contactUsTheme: ContactUsTheme }>`
  background: ${({ theme, contactUsTheme }) => theme.colors[contactUsColorsSchema[contactUsTheme].bgDark ?? "white"]};

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    background: ${({ theme, contactUsTheme }) => theme.colors[contactUsColorsSchema[contactUsTheme].bgDark ?? "white"]};
    width: 100vw;
    border-radius: 0 0 ${({ theme }) => theme.radii.contactForm} ${({ theme }) => theme.radii.contactForm};
    height: 2.75rem;
    bottom: -2.75rem;
    ${({ theme }) => theme.mediaQueries.laptop} {
      height: 4.5rem;
      bottom: -4.5rem;
    }
  }
`;

export const StyledContactUs = styled.div`
  position: relative;
`;
