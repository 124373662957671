// Types
import { NeedsProps } from "./components/needs/types";
import { PortfolioProps } from "components/portfolio/types";
import { CoveredIndustriesProps } from "components/covered-industries/types";
import { BenefitsProps } from "./components/benefits/types";
import { PipelineProps } from "./components/pipeline/types";
import { StagesBlocksItemProps } from "components/stages-blocks/components/stages-blocks-item/types";
import { CorporateValueProps } from "pages/about-us/components/corporate-values/types";
import { IndustryPageContent } from "pages/industries/types";
import { DigitalExcellenceProps } from "pages/services/components/digital-excellence/types";

export const ServicesVariants = {
  technicalConsulting: "technical-consulting",
  mvpDevelopment: "mvp-development",
  maintenanceSupport: "maintenance-support",
  dedicatedTeam: "dedicated-team",

  webDevelopment: "web-development",
  mobileDevelopment: "mobile-development",
  uiUxDesign: "ui-ux-design",
  aiMlSolutions: "ai-ml-solutions",
  bigData: "big-data",
  cloudSolutions: "cloud-solutions",
  aiSoftwareDevelopment: "ai-software-development",
  dataScience: "data-science",
  aiMobileDevelopment: "ai-mobile-development",
  aiConsulting: "ai-consulting",
  mlDevelopment: "machine-learning-development",
  blockchain: "blockchain",
  mlConsulting: "machine-learning-consulting",
  customerExperienceConsulting: "customer-experience-consulting",
} as const;

export type ServicesPageProps = {
  content: ServicesPageContent;
};

export type ServiceVariantContent = {
  [key in keyof typeof ServicesVariants]: ServicesPageContent;
};

export interface ServicesPageContent extends IndustryPageContent {
  needs?: NeedsProps;
  solutionsForSystems?: CorporateValueProps;
  benefits?: BenefitsProps;
  pipeline?: PipelineProps;
  portfolio?: PortfolioProps;
  coveredIndustries?: CoveredIndustriesProps;
  stagesBlocks?: StagesBlocksItemProps[];
  digitalExcellence?: DigitalExcellenceProps;
  developmentProcess?: BenefitsProps;
}
