import {
  AI_CONSULTING_CONTENT,
  AI_ML_SOLUTIONS_CONTENT,
  AI_MOBILE_DEVELOPMENT_CONTENT,
  AI_SOFTWARE_DEVELOPMENT_CONTENT,
  CLOUD_SOLUTIONS_CONTENT,
  BIG_DATA_CONTENT,
  BLOCKCHAIN_CONTENT,
  CUSTOMER_EXPERIENCE_CONSULTING_CONTENT,
  DATA_SCIENCE_CONTENT,
  DEDICATED_TEAM_CONTENT,
  MAINTENANCE_SUPPORT_CONTENT,
  MOBILE_DEVELOPMENT_CONTENT,
  ML_CONSULTING_CONTENT,
  ML_DEVELOPMENT_CONTENT,
  MVP_DEVELOPMENT_CONTENT,
  TECHNICAL_CONSULTING_CONTENT,
  UI_UX_DESIGN_CONTENT,
  WEB_DEVELOPMENT_CONTENT,
} from ".";
// Types
import { ServiceVariantContent } from "../types";

const ServiceContentMapper: ServiceVariantContent = {
  technicalConsulting: TECHNICAL_CONSULTING_CONTENT,
  mvpDevelopment: MVP_DEVELOPMENT_CONTENT,
  maintenanceSupport: MAINTENANCE_SUPPORT_CONTENT,
  dedicatedTeam: DEDICATED_TEAM_CONTENT,
  webDevelopment: WEB_DEVELOPMENT_CONTENT,
  mobileDevelopment: MOBILE_DEVELOPMENT_CONTENT,
  uiUxDesign: UI_UX_DESIGN_CONTENT,
  aiMlSolutions: AI_ML_SOLUTIONS_CONTENT,
  bigData: BIG_DATA_CONTENT,
  cloudSolutions: CLOUD_SOLUTIONS_CONTENT,
  aiSoftwareDevelopment: AI_SOFTWARE_DEVELOPMENT_CONTENT,
  dataScience: DATA_SCIENCE_CONTENT,
  aiMobileDevelopment: AI_MOBILE_DEVELOPMENT_CONTENT,
  aiConsulting: AI_CONSULTING_CONTENT,
  mlDevelopment: ML_DEVELOPMENT_CONTENT,
  blockchain: BLOCKCHAIN_CONTENT,
  mlConsulting: ML_CONSULTING_CONTENT,
  customerExperienceConsulting: CUSTOMER_EXPERIENCE_CONSULTING_CONTENT,
};

export default ServiceContentMapper;
