import Slider from "react-slick";

const useSliderController = () => {
  const sliderRef = React.useRef<Slider | null>(null);

  const handleClickPrevious = () => {
    sliderRef?.current?.slickPrev();
  };

  const handleClickNext = () => {
    sliderRef?.current?.slickNext();
  };

  return { sliderRef, handleClickPrevious, handleClickNext };
};

export default useSliderController;
