// Components + styling
import { FlexGap, RatingStars } from "components";
import { StyledCompanyRateItemWrapper } from "./styled";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Types
import { CompanyRateItemProps } from "./types";

const CompanyRateItem: React.FC<CompanyRateItemProps> = ({ rate, LogoIcon, MobileLogoIcon, isWhite, link }) => {
  const { isMobile } = useMatchBreakpoints();

  const SelectedLogoIcon = MobileLogoIcon && isMobile ? MobileLogoIcon : LogoIcon;

  return (
    <StyledCompanyRateItemWrapper external isWhite={isWhite} href={link}>
      <FlexGap gap="0.75rem">
        <SelectedLogoIcon height="1.5rem" color={isWhite ? "bgDark" : "white"} />

        <RatingStars
          rating={rate}
          width="1.25rem"
          textColor={isWhite ? "bgDark" : "white"}
          fillColor={isWhite ? "ratingYellow" : "white"}
        />
      </FlexGap>
    </StyledCompanyRateItemWrapper>
  );
};

export default CompanyRateItem;
