// Components
import { Svg } from "components";
// Context
import { useThemeContext } from "context";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "textMain", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 40 40" {...props} color="transparent">
      <rect width="40" height="40" rx="20" fill={theme.colors.bgSecond} />
      <g clipPath="url(#clip0_899_31830)">
        <path
          d="M23.5666 24.1735C22.6943 24.961 21.5425 25.3975 20.2785 25.3975C17.4921 25.3975 15.4458 23.3428 15.4458 20.5218C15.4458 17.7007 17.427 15.7326 20.2785 15.7326C21.5204 15.7326 22.6943 16.1479 23.5887 16.9354L24.1966 17.4604L26.8979 14.749L26.2218 14.1365C24.6323 12.6953 22.522 11.8867 20.2775 11.8867C15.2696 11.8867 11.6328 15.5153 11.6328 20.5007C11.6328 25.4648 15.3567 29.2232 20.2775 29.2232C22.5641 29.2232 24.6964 28.4146 26.2659 26.9503L26.9189 26.3378L24.1756 23.6284L23.5666 24.1735Z"
          fill={theme.colors[color]}
        />
        <path
          d="M20.1466 17.6562C20.9148 17.6562 21.6515 17.9627 22.1948 18.5081C22.738 19.0536 23.0431 19.7934 23.0431 20.5648C23.0431 21.3362 22.738 22.076 22.1948 22.6214C21.6515 23.1669 20.9148 23.4733 20.1466 23.4733C19.3784 23.4733 18.6416 23.1669 18.0984 22.6214C17.5552 22.076 17.25 21.3362 17.25 20.5648C17.25 19.7934 17.5552 19.0536 18.0984 18.5081C18.6416 17.9627 19.3784 17.6563 20.1466 17.6562Z"
          fill={theme.colors[color]}
        />
      </g>
      <defs>
        <clipPath id="clip0_899_31830">
          <rect width="16" height="18.2857" fill="white" transform="translate(11 11)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
