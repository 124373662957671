// Components
import { Svg } from "components";
// Types
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "white", ...props }) => {
  return (
    <Svg viewBox="0 0 107 107" width="107" height="107" {...props} color={color}>
      <g clipPath="url(#clip0_1_10744)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M62.9688 61.5747C63.7209 61.6842 64.477 61.7873 65.1185 61.8378C76.5561 62.7984 89.1254 60.7234 99.1623 55.145C99.5248 54.9397 99.6761 54.4895 99.4716 54.1223C99.2672 53.7551 98.8223 53.6344 98.4595 53.8402C88.6191 59.2372 76.3866 61.2578 65.2347 60.3189C64.8757 60.2947 64.461 60.2414 64.045 60.1903C64.5082 59.3861 64.6734 58.5791 64.6527 57.8253C64.5898 56.6862 64.0534 55.5821 63.2082 54.6029C62.0212 53.2374 60.2823 52.1365 58.4864 51.4566C57.101 50.948 55.6818 50.7139 54.6 50.8728C53.7008 50.9831 53.0057 51.3492 52.5488 51.9197C52.0703 52.5224 51.8553 53.4034 52.1567 54.6347C52.6263 56.7356 54.1432 58.2814 56.1652 59.4082C57.642 60.2287 59.3639 60.7945 61.0483 61.1926C60.6112 61.5093 60.0997 61.825 59.5125 62.1417C54.9747 64.437 47.678 64.5772 39.8976 63.5338C28.0617 61.9352 15.095 57.5418 8.96069 53.4785C8.64874 53.272 8.14767 53.3478 7.91458 53.696C7.6815 54.0442 7.80417 54.5337 8.11612 54.7403C14.4062 58.9071 27.6049 63.4018 39.7092 65.0479C47.8371 66.1327 55.4883 65.9124 60.1917 63.4818C61.3584 62.8596 62.2997 62.2381 62.9688 61.5747ZM62.416 59.9343C62.9426 59.2596 63.1698 58.5841 63.1614 57.9241C63.1128 57.1 62.6708 56.3029 62.0831 55.6116C61.0684 54.4372 59.5318 53.4814 57.975 52.8926C56.8921 52.4927 55.7721 52.2607 54.891 52.344C54.4042 52.3986 54.0055 52.5452 53.7331 52.84C53.508 53.1763 53.4867 53.6573 53.5983 54.2752C54.0247 55.9923 55.3166 57.2007 56.9033 58.0812C58.5452 58.9911 60.6097 59.6062 62.416 59.9343Z"
          fill="url(#paint0_linear_1_10744)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.7588 53.6283C95.1849 56.1651 92.252 59.9856 90.3078 63.8991C90.1283 64.2794 90.2747 64.7331 90.6147 64.8977C91.0062 65.0975 91.4769 64.9555 91.656 64.5757C93.5193 60.8954 96.2533 57.2592 99.6498 54.8753C99.9713 54.6192 100.06 54.1503 99.8322 53.8178C99.5524 53.4512 99.0803 53.3723 98.7588 53.6283Z"
          fill="url(#paint1_linear_1_10744)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.7967 53.789C94.7691 54.9859 90.2504 53.5528 86.5999 51.72C86.2599 51.5555 85.7892 51.6981 85.6097 52.0784C85.4305 52.4582 85.5775 52.9116 85.9694 53.1108C89.9046 55.0786 94.8471 56.5501 99.2454 55.2479C99.633 55.1172 99.8471 54.685 99.7358 54.2908C99.6248 53.8961 99.184 53.6588 98.7967 53.789Z"
          fill="url(#paint2_linear_1_10744)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1_10744"
          x1="7.4942"
          y1="54.324"
          x2="94.9002"
          y2="33.5013"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD21C" />
          <stop offset="1" stopColor="#FF951C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_10744"
          x1="89.7224"
          y1="64.3077"
          x2="94.4152"
          y2="66.506"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD21C" />
          <stop offset="1" stopColor="#FF951C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_10744"
          x1="85.2337"
          y1="52.6257"
          x2="97.6652"
          y2="47.9402"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD21C" />
          <stop offset="1" stopColor="#FF951C" />
        </linearGradient>
        <clipPath id="clip0_1_10744">
          <rect width="76.8317" height="76.8317" fill="white" transform="translate(64.25 107) rotate(-146.203)" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
