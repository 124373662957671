import styled, { css } from "styled-components";
import { clearfixDisplayNone } from "styles";
// Types
import { StyledStagesBlocksListProps } from "./types";

const getStagesBlocksStyles = (isMoreItemsThenDefaultCount: boolean) => {
  if (isMoreItemsThenDefaultCount) {
    return css`
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 1rem;
    `;
  } else {
    return css`
      justify-content: space-between;
      flex-wrap: nowrap;
      gap: 0;
    `;
  }
};

export const StyledStagesBlockContainer = styled.div<{ isBgDark: boolean }>`
  background-color: ${({ isBgDark, theme }) => (isBgDark ? theme.colors.bgDark : theme.colors.white)};
`;

export const StyledStagesBlockInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    align-items: flex-end;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-bottom: 3rem;
  }
`;

export const StyledParallaxImagesContainer = styled.div`
  position: relative;
  top: -4.0625rem;
`;

export const StyledStagesBlocksList = styled.div<StyledStagesBlocksListProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  position: relative;
  margin-bottom: 1.5rem;
  z-index: ${({ theme }) => theme.zIndices.buttonContent};

  ${({ theme }) => theme.mediaQueries.tablet} {
    flex-direction: row;
    margin-bottom: 0;
    ${({ isMoreItemsThenDefaultCount }) => getStagesBlocksStyles(isMoreItemsThenDefaultCount)};
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    ${({ blur }) =>
      blur &&
      css`
        &::before {
          content: "";
          position: absolute;
          right: 0;
          z-index: ${({ theme }) => -theme.zIndices.buttonGradient};
          width: 50%;
          height: 50%;
          box-shadow: ${({ theme }) => theme.colors[blur]} ${({ theme }) => theme.shadows.boostBusiness};
          background-color: ${({ theme }) => theme.colors[blur]};
          border-radius: ${({ theme }) => theme.radii.circle};
          opacity: 0.1;
          filter: blur(12px);
          transform: rotate(-22deg);
        }
      `}
  }

  &::after {
    ${clearfixDisplayNone};
    width: 5.75rem;
    height: 8.5625rem;
    position: absolute;
    top: -4.625rem;
    right: -4.375rem;
    background-image: url("/images/how-start-arrow-icon.svg");

    ${({ theme }) => theme.mediaQueries.laptopL} {
      display: ${({ isNeedFreeEstimation, isNeedCurveLine }) =>
        isNeedFreeEstimation && isNeedCurveLine ? "block" : "none"};
    }
  }
`;

export const StyledButtonContainer = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.buttonContent + 1};
`;

export const StyledMobileButtonContainer = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
`;
