// Components + styling
import { ArrowLeftIcon } from "components/svg";
import { StyledIconButton, StyledIconWrapper } from "./styled";
// Types
import { IconButtonProps } from "./types";

const IconButton: React.FC<IconButtonProps> = ({ Icon = ArrowLeftIcon, iconColor = "textAccent", ...props }) => {
  return (
    <StyledIconButton iconColor={iconColor} {...props}>
      <StyledIconWrapper>
        <Icon width="2rem" />
      </StyledIconWrapper>
    </StyledIconButton>
  );
};

IconButton.defaultProps = {
  scale: "xs",
  filledType: "bordered",
  isStrokeIcon: true,
};

export default IconButton;
