// Components
import { OurCapabilitiesDarkYellowArrow, WavingHandIcon } from "components/svg";
// Constants
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";
// Types
import { buttonThemeTypes, variants } from "components/button/types";
import { IndustryPageContent } from "../types";

const PROPERTY_MANAGEMENT_SOLUTIONS_CONTENT: IndustryPageContent = {
  banner: {
    title: "Property Management Software Solutions",
    description:
      "Unlock the potential of your property management business with Idealogic's innovative software solutions. From automated real estate management tools to financial reporting software, we bring efficiency and scalability to your operations.",
    buttonLabel: "Get a Free Estimation Today",
    buttonVariant: variants.SECONDARY,
    buttonTheme: buttonThemeTypes.DARK,
    buttonBgColor: "bgDark",
    maxWidthForButtonRem: 23.875,
    gradient: {
      color: "bannerYellowWhiteGradient",
      backgroundSize: "cover",
    },
    imageRatio: 2.44,
    isColumn: true,
    isWhite: true,
  },
  whoWeServe: {
    title: "Who We Serve",
    description:
      "Idealogic provides tailored real estate software solutions to drive digital transformation for various stakeholders",
    whoWeServeListLayoutProps: {
      flexDirection: { laptop: "row" },
    },
    whoWeServeContainerBoxProps: {
      paddingTop: { _: "3rem", laptop: "1rem" },
      paddingBottom: { _: "2rem", laptop: "4.625rem" },
    },
    whoWeServeList: [
      {
        text: "Landlords",
        subText: "Simplify your operations and enhance efficiency with our tenant and lease management software.",
      },
      {
        text: "Property Managers",
        subText: "Streamline routine processes and increase capacity with our custom property management systems.",
      },
      {
        text: "Real Estate Investment Firms",
        subText:
          "Gain deep portfolio insights and make better investment decisions with our property management tech innovations.",
      },
    ],
    isSubText: true,
    beforeGradientColor: "bannerGreenGradient",
  },
  ourServicesFintech: {
    title: "Our Services for Property Management Software Development",
    description: "We prioritize your success and offer scalable property software development solutions",
    isEstimationButton: true,
    topServicesList: [
      {
        title: "Legacy System Modernization & Migrations",
        description:
          "Is your property management system stuck in the past? Our legacy system modernization and migration services offer a seamless transition from outdated systems to cutting-edge platforms. By upgrading your software, you can modernize your operations, improve efficiency, and avoid the costs associated with maintaining legacy systems. Our expert team will ensure a smooth migration process, minimizing disruption to your business and maximizing the benefits of modern technology.",
        gradient: "bannerYellowGradient",
        width: "calc(33% - 1rem)",
        height: "100%",
      },
      {
        title: "Custom Property Management Software Development",
        description:
          "Off-the-shelf software may not always meet your unique business requirements. That's where our custom property management software development services come in. We work closely with you to understand your specific needs and challenges, crafting tailored solutions that enhance operational efficiency and workflow. Whether you need a comprehensive management platform or specialized tools for specific tasks, we'll build software that fits like a glove and empowers your business to thrive.",
        gradient: "bannerYellowGradient",
        width: "calc(33% - 1rem)",
        height: "100%",
      },
      {
        title: "Digital Transformation Enablement",
        description:
          "Manual processes these days can hold your business back. Our digital transformation enablement services empower you to overcome these challenges and gain a competitive edge. We leverage the latest technologies to automate tasks, streamline workflows, and optimize processes, freeing up your team to focus on strategic initiatives. With our expertise guiding the way, you can embrace digital transformation with confidence and propel your business forward into the future.",
        gradient: "bannerYellowGradient",
        width: "calc(33% - 1rem)",
        height: "100%",
      },
    ],
    bottomServicesList: [],
    isWrap: false,
    isWithoutImages: true,
  },
  ourCapabilities: {
    title: "Our Capabilities",
    description: "Explore our comprehensive services designed for the real estate industry's convenience",
    ArrowIcon: OurCapabilitiesDarkYellowArrow,
    buttonText: "Talk To Us!",
    buttonVariant: variants.SECONDARY,
    buttonTheme: buttonThemeTypes.DARK,
    hoverBgColor: "bgDark",
    hoverTextColor: "white",
    accordionContent: [
      {
        title: "Tenant Management",
        description:
          "Efficiently handle tenant interactions and lease agreements with our intuitive tenant and lease management software. Simplify application processes, streamline screening, and optimize lease management to improve tenant acquisition and retention.",
      },
      {
        title: "Rent Collection",
        description:
          "Automate rent payments and fee collections with our secure online payment solutions. Say goodbye to late payments and administrative hassles, ensuring timely payments and improved cash flow.",
      },
      {
        title: "Maintenance Requests",
        description:
          "Track and manage maintenance requests effortlessly with our versatile tools. Simplify the process for tenants to report issues and streamline your maintenance workflows for faster resolution.",
      },
      {
        title: "Financial Reporting",
        description:
          "Gain valuable insights into your property portfolio's financial performance. Generate detailed reports quickly and easily, empowering informed decision-making and strategic planning.",
      },
    ],
  },
  optimizeOperations: {
    title: "Optimize Your Real Estate Operations for Success",
    description:
      "At Idealogic, we combine deep industry expertise with cutting-edge technology to enhance operational efficiency, boost client satisfaction, and drive revenue growth in the real estate sector.",
    buttonLabel: "Get a Free Consultation Today",
    imageSrc: "/images/industries/property-management-solutions/optimize-operations-img.webp",
    pointIconSrc: "/images/industries/property-management-solutions/optimize-operations-icon.svg",
    optimizeOperationPointsList: ["Operational Efficiency", "Client Satisfaction", "Revenue Growth"],
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.eState, CaseStudiesSlugEnums.secondFloor],
    title: "Real Estate Case Studies",
    TitleIcon: WavingHandIcon,
    description:
      "Explore how our innovative solutions have transformed the real estate industry, from mobile rental apps to tokenized platforms.",
    actionButtonText: "See More of Our Work",
  },
  stagesBlocks: [
    {
      title: "Advanced Technology Integration",
      description:
        "We employ the most advanced technological stacks to meet and exceed the current demands for innovation in the real estate sector. Our commitment to using cutting-edge technologies ensures that our solutions are both modern and efficient.",
      iconSrc: "/images/industries/property-management-solutions/innovation-real-state-icon-1.svg",
    },
    {
      title: "AI and Machine Learning",
      description:
        "The integration of AI and machine learning is transforming real estate processes. We harness the power of AI/ML to analyze data, optimize operations, and enhance decision-making, delivering tangible benefits and value to every project.",
      iconSrc: "/images/industries/property-management-solutions/innovation-real-state-icon-2.svg",
    },
    {
      title: "Agile Methodologies",
      description:
        "Our agile approach underpins our project management and execution strategies. By embracing agility, we ensure that we can swiftly respond to your feedback and evolving business objectives, making adjustments that align perfectly with your needs.",
      iconSrc: "/images/industries/property-management-solutions/innovation-real-state-icon-3.svg",
    },
  ],
};

export default PROPERTY_MANAGEMENT_SOLUTIONS_CONTENT;
