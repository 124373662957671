import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledMotion = styled(motion.div)<{ offsetHeight: number }>`
  position: fixed;
  top: ${({ offsetHeight }) => offsetHeight - 3}px;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndices.tooltip - 1};
  background-color: ${({ theme }) => theme.colors.bgDark};
`;

export const StyledMobileMenu = styled.div<{ offsetHeight: number }>`
  width: 100%;
  height: 100%;
  min-height: ${({ offsetHeight }) => `calc(100dvh - ${offsetHeight - 2}px)`};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  z-index: ${({ theme }) => theme.zIndices.tooltip - 1};
  background-color: ${({ theme }) => theme.colors.bgDark};
  overflow: auto;
`;

export const StyledMobileMenuFooter = styled.div`
  width: 100%;
  height: 6rem;

  display: flex;

  justify-content: center;
  align-items: center;

  padding: 1.5rem 1rem;
  border-top: 1px solid ${({ theme }) => theme.colors.lineDark};
`;
