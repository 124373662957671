// Components
import { WavingHandIcon } from "components/svg";
// Constants
import { EXTERNAL_LINKS } from "configs";
import {
  SPARE_PARTS_MARKETPLACE_ACCORDION_CONTENT_LIST,
  SPARE_PARTS_MARKETPLACE_BOTTOM_SERVICES_LIST,
} from "../components/industry-info/constants";
// Types
import { ROUTES } from "navigation/routes";
import { ServicesVariants } from "pages/services/types";
import { IndustryPageContent } from "../types";
import { CaseStudiesSlugEnums } from "context/case-studies-context/types";

export const SPARE_PARTS_MARKETPLACE_BENEFITS_LIST = [
  {
    title: "Blockchain Software Services",
    description:
      "Enhance security and increase transparency with blockchain technology solutions tailored for the aviation industry.",
    linkForButton: `/${ROUTES.services}/${ServicesVariants.blockchain}`,
  },
  {
    title: "AI Software Development",
    description:
      "Embrace the power of AI-driven software solutions to implement your unique ideas and optimize business processes.",
    linkForButton: `/${ROUTES.services}/${ServicesVariants.aiSoftwareDevelopment}`,
  },
  {
    title: "Machine Learning Development",
    description: "Utilize machine learning algorithms to enable insightful decisions and drive business growth.",
    linkForButton: `/${ROUTES.services}/${ServicesVariants.mlDevelopment}`,
  },
];

const SPARE_PARTS_MARKETPLACE_CONTENT: IndustryPageContent = {
  banner: {
    title: "Spare Parts Marketplace Development Solutions",
    description:
      "Maximize your aviation business efficiency with our customized spare parts marketplace solutions. We specialize in developing, optimizing, and integrating e-commerce platforms tailored to streamline inventory management effortlessly.",
    imageSrc: "/images/industries/aviation/aviation-banner-bg.webp",
    imageRatio: 1.75,
    buttonLabel: "Get a Free Estimation Today",
  },
  customizedApplications: {
    title: "Who We Serve",
    description:
      "We leverage our technical expertise to empower individuals and organizations in the aviation industry with innovative digital solutions:",
    customizedApplicationsList: [
      {
        label: "Aviation Companies/Airlines",
        description:
          "Optimize flight operations, enhance customer service, and boost overall efficiency with our custom software solutions designed specifically for the aviation industry.",
        iconSrc: "/images/industries/aviation/spare-parts-marketplace-hangar-icon.svg",
      },
      {
        label: "Online Aviation Marketplaces",
        description:
          "Enhance customer experience and facilitate transactions with our secure and scalable digital platforms tailored for online aviation marketplaces.",
        iconSrc: "/images/industries/aviation/spare-parts-marketplace-shopping-icon.svg",
      },
      {
        label: "Spare Parts Trading Companies",
        description:
          "Streamline inventory management, simplify procurement processes, and improve supply chain visibility with our tailored solutions.",
        iconSrc: "/images/industries/aviation/spare-parts-marketplace-needs-notes-icon.svg",
      },
    ],
  },
  ourCapabilities: {
    title: "Our Capabilities",
    description: "Discover how Idealogic can transform your aviation business with our specialized capabilities",
    accordionContent: SPARE_PARTS_MARKETPLACE_ACCORDION_CONTENT_LIST,
  },
  boostBusiness: {
    title: "White-Label Inventory Management Solution",
    description:
      "Enable multi language support on your website effortlessly. Our white-label inventory management solution lets you customize and localize your site, reaching diverse audiences and improving user experience. With seamless multi language support, attract and engage customers globally, driving business growth and success.",
    buttonTitle: "Get Started",
    personImageSrc: "/images/industries/aviation/white-label/white-label-banner-spare-parts-marketplace.webp",
    imageRatio: 1.04,
    bgColor: "bgThird",
    isWhite: true,
    isWhiteLabel: true,
    isAbsolute: true,
    titleWidth: "65%",
    textWidth: "50%",
  },
  ourServicesFintech: {
    title: "Our Aviation Services",
    isEstimationButton: true,
    description:
      "Idealogic specializes in delivering cutting-edge, multi-purpose software solutions tailored for the aviation industry.",
    topServicesList: [],
    bottomServicesList: SPARE_PARTS_MARKETPLACE_BOTTOM_SERVICES_LIST,
  },
  benefits: {
    title: "New Technologies in the Aviation Industry",
    description: "Stay ahead of the curve with our implementation of the latest industry trends:",
    benefitsList: SPARE_PARTS_MARKETPLACE_BENEFITS_LIST,
    isBackgroundImageNeed: false,
    isNeedCurveLine: false,
    buttonText: "Let’s Talk!",
  },
  portfolio: {
    portfolioList: [CaseStudiesSlugEnums.airpartChain, CaseStudiesSlugEnums.eplane],
    title: "Aviation Case Studies",
    TitleIcon: WavingHandIcon,
    actionButtonText: "See More of Our Work",
    description:
      "Explore our successful examples of innovative aviation software solutions for aircraft maintenance and aerospace trading.",
  },
  bookACallWithExpert: {
    bgSrc: "/images/industries/aviation/book-a-call-with-expert/book-a-call-with-expert-bg.webp",
    imageAspectRatio: 1.15,
    isShowLinkedInTitle: false,
    bgMobileSrc: "/images/industries/aviation/book-a-call-with-expert-bg-mobile.webp",
    title: {
      beforeKeyWord: "",
      keyWord: "Our expert",
      afterKeyWord: "will be happy to discuss the details of your project!",
    },
    description: "",
    linkedin: {
      name: "Oleh Henaliuk",
      href: EXTERNAL_LINKS.linkedInOleh,
    },
    jobTitle: "Deputy CEO",
    buttonText: "Talk To Our Experts!",
    imageSrc: "/images/industries/aviation/book-a-call-with-expert/book-a-call-with-expert-oleh-bg.webp",
    mobileImageSrc: "/images/industries/aviation/book-a-call-with-expert/book-a-call-with-expert-oleh-mobile-bg.webp",
    isBoxShadow: true,
  },
  ourCustomerReview: {
    title: "Our customer review",
    description: "We've worked with startups and enterprises – look what they have to say about us!",
    bgImageSrc: "/images/industries/aviation/our-customer-review/our-customer-review-bg.webp",
    linkedInLink: EXTERNAL_LINKS.linkedInAngel,
    bgGradient: "customerReviewAngelGradient",
    isTextClamp: false,
    owner: {
      name: "Angel Marinov",
      logo: "/images/industries/aviation/our-customer-review/eplane-ai-logo.svg",
      logoAspectRatio: 6.23,
      logoSize: "186px",
      jobTitle: "Head of Innovation at ePlaneAI",
      image: "/images/industries/aviation/our-customer-review/our-customer-review-angel-marinov.webp",
      imageAspectRatio: 1,
      reviewText:
        "“Idealogic delivered on-time updates on the app's progress weekly and provided prompt solutions for new requirements. The team showed impressive commitment to the project's success and was helpful in aspects beyond technical. They were deeply interested in the product from the beginning.”",
    },
  },
};

export default SPARE_PARTS_MARKETPLACE_CONTENT;
