import { useLocation, useNavigate } from "react-router-dom";
// Components + styling
import { ClickItem, Text } from "components";
import { FullLogoIcon } from "components/svg";
import { FooterSocial, FooterContacts } from "../";
import { StyledFooterDescription, StyledFooterDescriptionFlex } from "./styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useMatchBreakpoints } from "hooks";
// Constants
import { ROUTES } from "navigation/routes";

const FooterDescription: React.FC = () => {
  const navigate = useNavigate();
  const { pathname, hash } = useLocation();

  const { t } = useTranslation();
  const { isMobile, isTablet, isDesktop } = useMatchBreakpoints();

  const handleClickLogo = () => {
    if (pathname === ROUTES.landing && !hash) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      navigate(ROUTES.landing);
    }
  };

  return (
    <StyledFooterDescriptionFlex>
      <StyledFooterDescription>
        <ClickItem mb={{ _: "0.75rem", tablet: "1.25rem" }} onClick={handleClickLogo}>
          <FullLogoIcon width={isDesktop ? "11.125rem" : "6.875rem"} color="white" />
        </ClickItem>

        <Text
          textScale={isDesktop ? "p14Regular" : "p12Regular"}
          color="textFourthForDarkBg"
          maxWidth={{ _: "15.43rem", tablet: "auto" }}
        >
          {t("Custom software development company with focus on Startups and Scaling Companies.")}
        </Text>

        {isTablet && <FooterSocial />}
      </StyledFooterDescription>

      {!isMobile && <FooterContacts />}

      {isDesktop && <FooterSocial />}
    </StyledFooterDescriptionFlex>
  );
};

export default FooterDescription;
