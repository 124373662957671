import { lazyLoad } from "utils";

export const FinTechPage = lazyLoad(
  () => import("./fintech-page"),
  module => module.default,
);

export const BankingPage = lazyLoad(
  () => import("./banking-page"),
  module => module.default,
);

export const CryptoCurrencyPage = lazyLoad(
  () => import("./cryptocurrency-page"),
  module => module.default,
);

export const DigitalBankingPage = lazyLoad(
  () => import("./digital-banking-page"),
  module => module.default,
);

export const SparePartsMarketplacePage = lazyLoad(
  () => import("./spare-parts-marketplace-page"),
  module => module.default,
);

export const IntelligentInventoryManagementPage = lazyLoad(
  () => import("./intelligent-inventory-management"),
  module => module.default,
);

export const AiBasedAviationPage = lazyLoad(
  () => import("./ai-based-aviation-page"),
  module => module.default,
);

export const BlockchainBasedAviationPage = lazyLoad(
  () => import("./blockchain-based-aviation-page"),
  module => module.default,
);

export const CustomRealEstatePage = lazyLoad(
  () => import("./custom-real-estate-page"),
  module => module.default,
);

export const PropertyManagementSolutionsPage = lazyLoad(
  () => import("./property-management-solutions-page"),
  module => module.default,
);

export const AgentBrokersSolutionsPage = lazyLoad(
  () => import("./agent-brokers-solutions"),
  module => module.default,
);

export const BlockchainPage = lazyLoad(
  () => import("./blockchain"),
  module => module.default,
);

export const DefaultPageIndustries = lazyLoad(
  () => import("./default-page"),
  module => module.default,
);
