import { siteWidth } from "theme/base";

import { AnimationStatusCheckTypes, animationStatusCheckVariants } from "./types";

const useAnimationStatus = (type: AnimationStatusCheckTypes) => {
  const [isAnimationActive, setIsAnimationActive] = React.useState(getAnimationStatus(type));

  React.useEffect(() => {
    window.addEventListener("resize", updateAnimationStatus);

    return () => {
      window.removeEventListener("resize", updateAnimationStatus);
    };
  }, []);

  const updateAnimationStatus = () => {
    setIsAnimationActive(getAnimationStatus(type));
  };

  function getAnimationStatus(type: AnimationStatusCheckTypes) {
    switch (type) {
      case animationStatusCheckVariants.banner:
        return window.innerHeight <= siteWidth;

      default:
        return window.innerHeight <= siteWidth;
    }
  }

  return { isAnimationActive };
};

export default useAnimationStatus;
