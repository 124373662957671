import { CareersVariants } from "pages/position/types";

import { capitalizeFirstLetter } from "utils/capitalize-first-letter";

export const getMetaLabelForCareers = (path: string) => {
  const pathSplit = path.split("/");
  const careersType = pathSplit[pathSplit.length - 1];

  switch (careersType) {
    case CareersVariants.middlePlusQa:
      return "Middle+ QA";
    case CareersVariants.middlePlusBusinessAnalyst:
      return "Middle+ Business Analyst";
    case CareersVariants.reactNativeEngineerReactJs:
      return "React Native Engineer + React JS";
    case CareersVariants.middleBackEndEngineerNodeJs:
      return "Middle Back-end Engineer (Node.js)";
    case CareersVariants.devOpsEngineer:
      return "DevOps Engineer";

    default: {
      return `${capitalizeFirstLetter(careersType)}`;
    }
  }
};
