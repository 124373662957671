import Slider from "react-slick";
// Components + styling
import { SliderNavButtons } from "components";
import TestimonialItem from "../testimonial-item";
import { StyledTestimonialsSliderWrapper, SliderNavButtonsWrapper } from "./styled";
// Hooks
import { useSliderController } from "hooks";
// Configs
import { sliderSettings } from "./config";
// Constants
import { TESTIMONIALS_LIST } from "../../constants";

const TestimonialsSlider: React.FC = () => {
  const { sliderRef, handleClickPrevious, handleClickNext } = useSliderController();

  return (
    <StyledTestimonialsSliderWrapper>
      <Slider ref={sliderRef} {...sliderSettings}>
        {TESTIMONIALS_LIST.map(testimonialItem => (
          <TestimonialItem key={testimonialItem.name} {...testimonialItem} />
        ))}
      </Slider>
      <SliderNavButtonsWrapper>
        <SliderNavButtons onClickLeftBtn={handleClickPrevious} onClickRightBtn={handleClickNext} buttonTheme="dark" />
      </SliderNavButtonsWrapper>
    </StyledTestimonialsSliderWrapper>
  );
};

export default TestimonialsSlider;
