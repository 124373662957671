import { useTranslation } from "context";
import { useMatchBreakpoints } from "hooks";

import { Heading, Text } from "components";
import { WavingHandIcon } from "components/svg";

import { contactUsColorsSchema } from "components/contact-us/constants";

import { ContactUsTitleProps } from "./types";

const ContactUsTitle: React.FC<ContactUsTitleProps> = ({ theme, title, isHideTitleIcon = true, description }) => {
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  return (
    <>
      <Heading
        color={contactUsColorsSchema[theme].textMain}
        scale="h1"
        as="h3"
        mb={{ _: title ? "0.3125rem" : "2rem", laptop: title ? "2rem" : "3.375rem" }}
      >
        {t(title ?? "Let’s Discuss Your Idea Now")}

        {!isHideTitleIcon && (
          <WavingHandIcon width={isDesktop ? "3rem" : "1.5rem"} mx={{ _: "0.25rem", laptop: "0.75rem" }} />
        )}
      </Heading>

      {description && (
        <Text
          textScale={isDesktop ? "p22Regular" : "p16Regular"}
          color={contactUsColorsSchema[theme].textThird}
          mb="2rem"
        >
          {t(description)}
        </Text>
      )}
    </>
  );
};

export default ContactUsTitle;
