import { useNavigate } from "react-router-dom";
// Components
import { Flex, Heading, Text, Button, Image, HeaderLogo, Container } from "components";
// Hooks
import { useElementDimensions, useMatchBreakpoints } from "hooks";
import { useHeaderTheme } from "components/header/hooks";
// Constants
import { ROUTES } from "navigation/routes";
import { BLOCK_ID } from "configs";
// Types
import { ErrorBoundaryFallbackProps } from "./types";

const ErrorBoundaryFallback: React.FC<ErrorBoundaryFallbackProps> = ({ resetError }) => {
  const navigate = useNavigate();
  const { isDesktop } = useMatchBreakpoints();

  const { headerTheme } = useHeaderTheme();

  const {
    elementDimensions: { offsetHeight },
  } = useElementDimensions({ id: BLOCK_ID.errorBoundaryHeader });

  const onPressHandler = () => {
    resetError();
    navigate(ROUTES.landing);
  };

  return (
    <Container $backgroundColor="bgDark">
      <Flex id="error-boundary-header" padding="0.88rem 0.88rem 0.88rem 0">
        <HeaderLogo headerTheme={headerTheme} isMobileMenuOpen={false} setIsMenuOpen={() => null} />
      </Flex>
      <Flex
        alignItems="center"
        justifyContent={{ _: "center", laptop: "space-evenly" }}
        flexDirection={{ _: "column", laptop: "row" }}
        height={`calc(100vh - ${offsetHeight}px)`}
        minHeight="36.75rem"
      >
        <Flex justifyContent="center" flexDirection="column">
          <Flex flexDirection="column" alignItems={{ _: "center", laptop: "flex-start" }} mt={"2rem"}>
            <Heading scale="h2" as="h2" color="white" mb={{ _: "1rem", laptop: "0.5rem" }} textAlign="center">
              Error!
            </Heading>
            <Text textScale={isDesktop ? "p24Regular" : "p16Regular"} color="textFourth">
              Something went wrong!
            </Text>
          </Flex>
          <Button
            mt={{ _: "2.5rem", mobileM: "1.5rem" }}
            mb={{ _: "auto", mobileM: "5rem" }}
            width={isDesktop ? "13.8rem" : "20rem"}
            scale="lg"
            ariaLabel="back-to-home"
            onClick={onPressHandler}
          >
            Back to home
          </Button>
        </Flex>
        <Flex position="relative">
          {isDesktop ? (
            <Image
              width={isDesktop ? "39.9rem" : "21rem"}
              src="/images/error-img-orange-dots.webp"
              alt="Error"
              aspectRatio={0.99}
            />
          ) : (
            <Image width="21rem" src="/images/error-img-blue-dots.webp" alt="Error" aspectRatio={0.99} />
          )}
        </Flex>
      </Flex>
    </Container>
  );
};

export default ErrorBoundaryFallback;
