import React from "react";
import { motion } from "framer-motion";
// Components + Styling
import { StyledParallaxImageWrapper } from "./styled";
// Hooks
import { useParallaxEffect } from "hooks";
// Constants
import { PARALLAX_IMAGES_LIST } from "./constants";
// Types
import { UseScrollOffsetOptions } from "types/styled-components-types";

const ContactUsParallaxImage = React.forwardRef<HTMLDivElement | null, {}>((_, ref) => {
  const parallaxOffset: UseScrollOffsetOptions[] = ["end start", "end start"];

  const parallaxImagesEffects = [
    useParallaxEffect(ref, 10, { offset: parallaxOffset }),
    useParallaxEffect(ref, 10, { offset: parallaxOffset }),
    useParallaxEffect(ref, 15, { offset: parallaxOffset }),
    useParallaxEffect(ref, 55, { offset: parallaxOffset }),
  ];

  return (
    <StyledParallaxImageWrapper>
      {PARALLAX_IMAGES_LIST.map(({ StyledComponent, imageSrc, zIndex, ratio }, index) => {
        const { y, selectedTransition } = parallaxImagesEffects[index];

        return (
          <motion.div key={imageSrc + index} style={{ width: "100%", zIndex, y }} transition={selectedTransition}>
            <StyledComponent src={imageSrc} aspectRatio={ratio} />
          </motion.div>
        );
      })}
    </StyledParallaxImageWrapper>
  );
});

ContactUsParallaxImage.displayName = "DesignSectionParallaxImage";

export default ContactUsParallaxImage;
