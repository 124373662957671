// Components + styling
import { Button } from "components";
import { NavigationMenuMobile } from "./components";
// TODO: uncomment LanguageSelector when we will used translations
// import LanguageSelector from "../language-selector";
import { StyledMotion, StyledMobileMenu, StyledMobileMenuFooter } from "./styled";
// Animations
import { slideVariants } from "./animations";
// Context
import { useTranslation } from "context";
// Hooks
import { useElementDimensions, useHideOverflow } from "hooks";
import { useHeaderTheme } from "components/header/hooks";
// Constants
import { BLOCK_ID } from "configs";
// Types
import { MobileMenuProps } from "./types";

export const MobileMenu: React.FC<MobileMenuProps> = ({ isMenuOpen, setIsMenuOpen, onClickLetsTalk }) => {
  const { t } = useTranslation();

  const {
    elementDimensions: { offsetHeight },
  } = useElementDimensions({ id: BLOCK_ID.header });
  const { headerTheme } = useHeaderTheme();

  useHideOverflow({ trigger: isMenuOpen });

  return (
    <StyledMotion
      initial="hidden"
      animate={isMenuOpen ? "visible" : "hidden"}
      variants={slideVariants}
      offsetHeight={offsetHeight}
    >
      <StyledMobileMenu offsetHeight={offsetHeight}>
        <NavigationMenuMobile headerTheme={headerTheme} isMobileMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />

        <StyledMobileMenuFooter>
          {/* TODO: uncomment LanguageSelector when we will used translations */}
          {/* <LanguageSelector /> */}

          <Button
            scale="md"
            height="2.75rem"
            hoverLinesPosition="mobileTopLeft"
            ariaLabel="lets-talk"
            onClick={() => {
              setIsMenuOpen(false);
              onClickLetsTalk();
            }}
          >
            {t("Contact us")}
          </Button>
        </StyledMobileMenuFooter>
      </StyledMobileMenu>
    </StyledMotion>
  );
};
