import styled, { css } from "styled-components";
import { linesMovingFromDifferentDirectionsAnimation } from "theme/animations";

export const StyledMainDropdownTitle = styled.div<{ isUnderline?: boolean; isHeaderThemeLight?: boolean }>`
  ${({ theme }) => theme.mediaQueries.laptop} {
    &::before {
      content: "";
      clear: both;
      width: 100%;
      height: 0.5px;
      position: absolute;
      top: 65.5%;
      left: 0;
      transition:
        clip-path 0.3s,
        transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
      clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%);
    }

    ${({ isUnderline, isHeaderThemeLight }) =>
      isUnderline &&
      css`
        &::before {
          background: ${({ theme }) => (isHeaderThemeLight ? theme.colors.bgDark : theme.gradients.buttonAccentHover)};
          clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 50% 100%, 0 100%, 100% 100%, 100% 0%);
          ${linesMovingFromDifferentDirectionsAnimation}
        }

        width: initial;
        background-image: ${({ theme }) => theme.gradients.blogShowMoreGradient};
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;

        padding: 0;

        transition: ${({ theme }) => theme.transitions.cubic_bezier_0_3s};
        transform: translate3d(0, 0, 0);
      `}
  }
`;
