import Cookies from "js-cookie";
// Constants
import { SearchEngines, UtmDefault } from "../constants";
// Types
import { UtmSetUp } from "./types";

const getUtmFromUrl = (search: string) => {
  const searchParams = new URLSearchParams(search);
  const gclid = searchParams.get(UtmSetUp.GCLID);
  const utm_term = searchParams.get(UtmSetUp.UTM_TERM) || UtmDefault.none;
  const utm_source = searchParams.get(UtmSetUp.UTM_SOURCE);
  const utm_medium = searchParams.get(UtmSetUp.UTM_MEDIUM);
  const utm_content = searchParams.get(UtmSetUp.UTM_CONTENT) || UtmDefault.none;
  const utm_campaign = searchParams.get(UtmSetUp.UTM_CAMPAIGN) || UtmDefault.none;

  return { gclid, utm_source, utm_medium, utm_campaign, utm_term, utm_content };
};

const getDirectSource = () => {
  return {
    utm_source: UtmDefault.direct,
    utm_medium: UtmDefault.none,
    utm_campaign: UtmDefault.none,
    utm_term: UtmDefault.none,
    utm_content: UtmDefault.none,
  };
};

const getReferrerSource = () => {
  const referrer = document.referrer;
  if (referrer) {
    const parsedReferrer = new URL(referrer);
    const hostname = parsedReferrer.hostname.toLowerCase();
    const searchEngines = Object.values(SearchEngines).map(engine => engine.toLowerCase());
    if (searchEngines.includes(hostname)) {
      return { utm_source: hostname, utm_medium: UtmDefault.organic };
    } else {
      return { utm_source: hostname, utm_medium: UtmDefault.referral };
    }
  }
  return { utm_source: null, utm_medium: null };
};

const determineTrafficSource = (search: string) => {
  const { gclid, utm_source, utm_medium, utm_campaign, utm_term, utm_content } = getUtmFromUrl(search);

  if (gclid) {
    return {
      utm_source: SearchEngines.GOOGLE,
      utm_medium: UtmDefault.cpc,
      utm_campaign,
      utm_term,
      utm_content,
    };
  } else if (utm_source || utm_medium || utm_campaign || utm_term || utm_content) {
    return {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
      gclid: null,
    };
  } else {
    const referrerSource = getReferrerSource();
    if (referrerSource.utm_source) {
      return referrerSource;
    } else {
      return getDirectSource();
    }
  }
};

const saveTrafficSource = (trafficSource: object) => {
  Object.entries(trafficSource).forEach(([key, value]) => {
    if (value) {
      localStorage.setItem(key, value);
      Cookies.set(key, value, { expires: 365 });
    }
  });
};

const loadTrafficSource = () => {
  const gclid = localStorage.getItem(UtmSetUp.GCLID) || Cookies.get(UtmSetUp.GCLID) || null;
  const utmSource = localStorage.getItem(UtmSetUp.UTM_SOURCE) || Cookies.get(UtmSetUp.UTM_SOURCE) || null;
  const utmMedium = localStorage.getItem(UtmSetUp.UTM_MEDIUM) || Cookies.get(UtmSetUp.UTM_MEDIUM) || null;
  const utmCampaign = localStorage.getItem(UtmSetUp.UTM_CAMPAIGN) || Cookies.get(UtmSetUp.UTM_CAMPAIGN) || null;
  const utmTerm = localStorage.getItem(UtmSetUp.UTM_TERM) || Cookies.get(UtmSetUp.UTM_TERM) || null;
  const utmContent = localStorage.getItem(UtmSetUp.UTM_CONTENT) || Cookies.get(UtmSetUp.UTM_CONTENT) || null;

  return {
    gclid,
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmContent,
  };
};

export { loadTrafficSource, determineTrafficSource, saveTrafficSource };
