import styled from "styled-components";
// Components
import { Container, Heading } from "components";
import { StyledBox } from "components/banner-default/styled";

export const StyledBoxWrapper = styled(StyledBox)`
  ${({ theme }) => theme.mediaQueries.laptopL} {
    height: calc(100vh + 7rem);
    padding-top: 6%;
  }
`;

export const StyledContainerWrapper = styled(Container)`
  padding-top: 3rem;
  min-height: 31rem;
  position: relative;
  text-align: center;
  background: url("/images/industries-banner-aviation-plain.webp") top / contain no-repeat;
  background-position-y: 24%;

  ${({ theme }) => theme.mediaQueries.tablet} {
    min-height: 32.125rem;
    background-position-y: top;
    background-position-y: 15%;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    height: 42rem;
    min-height: 56.125rem;
    background-position-y: 24%;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    background-position-y: 5%;
  }
`;

export const StyledColumn = styled.div`
  width: 100%;
  height: 80%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: absolute;

  padding-top: 0.5rem;

  ${({ theme }) => theme.mediaQueries.mobileL} {
    width: 80%;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 85%;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding-top: 5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    padding-top: 2.5rem;
    width: 60%;
    height: 90%;
  }
`;

export const StyledHeading = styled(Heading)`
  position: absolute;
  z-index: -1;
  left: -1.5%;
  line-height: 2.125rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    line-height: inherit;
  }
  ${({ theme }) => theme.mediaQueries.laptopL} {
    top: -2%;
  }
`;

export const StyledButtonWrapper = styled.div`
  width: 100%;
  max-width: 23.75rem;
  margin: 0 auto 1.5rem auto;

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin: 0 0 1.5rem 0;
  }
  ${({ theme }) => theme.mediaQueries.laptop} {
    margin: 0 0 3rem -3rem;
  }
`;
