import styled from "styled-components";
import { clearfix } from "styles";
// Components
import { Container, Link, Text } from "components";

import { linesMovingFromDifferentDirectionsAnimation } from "theme/animations";

export const StyledFooterWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 0;

  &::after {
    ${clearfix}
    width: 31.5625rem;
    height: 15.125rem;
    position: absolute;
    bottom: 0;
    left: 25%;
    transform: translate(-25%, -25%);
    mix-blend-mode: hard-light;
    filter: blur(250px);
    background: ${({ theme }) => theme.gradients.decorForDarkBg};

    ${({ theme }) => theme.mediaQueries.laptop} {
      width: 75.6875rem;
      height: 15.125rem;
      top: 150%;
      bottom: initial;
      left: 50%;
      transform: translate(-50%, -25%);
    }
  }
`;

export const StyledFooterContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const StyledFooterMainContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    flex-direction: row;
    gap: 3.8rem;
  }
  ${({ theme }) => theme.mediaQueries.laptopL} {
    gap: 8.8rem;
  }
`;

export const StyledSectionTitle = styled(Text)`
  margin-bottom: 1rem;

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin-bottom: 0.75rem;
  }
`;

export const StyledFooterLink = styled(Link).attrs({
  textScale: "p16Medium",
})`
  cursor: pointer;
  position: relative;

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 0;
  }
`;

export const StyledFooterInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.laptop} {
    max-width: 17rem;
  }
`;

export const StyledContentWrapper = styled.div`
  margin-top: 1.5rem;
`;

export const StyledFooterContentContainer = styled.div<{ isHaveLabel?: boolean }>`
  margin: ${({ isHaveLabel }) => (isHaveLabel ? "0" : "0 0.5rem")};
`;

export const StyledFooterNavigationWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1.5rem;
  margin-top: 1.5rem;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;

  ${({ theme }) => theme.mediaQueries.tablet} {
    gap: 1rem;
    flex-wrap: nowrap;
  }

  ${({ theme }) => theme.mediaQueries.laptopL} {
    margin-top: 0;
    gap: 4rem;
  }
`;

export const StyledFooterLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.laptop} {
    position: relative;

    a {
      width: fit-content;

      &::before {
        content: "";
        clear: both;
        width: 100%;
        height: 0.5px;
        position: absolute;
        top: 100%;
        left: 0;
        transition:
          clip-path 0.3s,
          transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
        clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%);
      }
    }

    &:hover {
      a {
        transition: ${({ theme }) => theme.transitions.cubic_bezier_0_3s};
        transform: translate3d(0, -2px, 0);

        &::before {
          background: ${({ theme }) => theme.colors.white};
          clip-path: polygon(0% 0%, 0% 100%, 50% 100%, 50% 0, 50% 0, 50% 100%, 50% 100%, 0 100%, 100% 100%, 100% 0%);
          ${linesMovingFromDifferentDirectionsAnimation}
        }
      }
    }
  }
`;

export const StyledDisableLinkContainer = styled.div`
  display: flex;
`;
